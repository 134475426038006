import { VProgessIndicatorProps } from "../types";
import {
  StyledVProgressIndicator,
  StyledVProgressIndicatorContainer,
} from "./styles";

export const VProgressIndicator = ({
  progress = 50,
}: VProgessIndicatorProps) => {
  return (
    <StyledVProgressIndicatorContainer>
      <StyledVProgressIndicator style={{ width: `${progress}%` }} />
    </StyledVProgressIndicatorContainer>
  );
};
