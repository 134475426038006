import { VButton, VCheckBox, VForm } from "components/common";
import { useBusinessRegistrationHook } from "hooks";
import {
  StyledChooseBusinessHeader,
  StyledChooseBusinessWrapper,
  StyledSignOuterWrapper,
  StyledSignupContent,
  StyledSignupWrapper,
} from "./styles";

export const ProvideBusinessDetails = ({
  customHook,
  paymentLoading = false,
}: {
  customHook: ReturnType<typeof useBusinessRegistrationHook>;
  paymentLoading?: boolean;
}) => {
  const {
    hookForm,
    handleFormSubmit,
    terms: { hasAcceptedTerms, setHasAcceptedTerms },
    provideDetailsInputList,
    isLoading,
  } = customHook;

  const submitAction = async () => {
    await handleFormSubmit();
  };

  return (
    <StyledSignOuterWrapper>
      <StyledSignupWrapper>
        <StyledSignupContent maxWidth={900}>
          <StyledChooseBusinessWrapper>
            <StyledChooseBusinessHeader>
              <h3>Before you continue,</h3>
              <h3>tell us about your business</h3>
              <p>Business Information</p>
            </StyledChooseBusinessHeader>
            <form>
              <VForm
                vFormClassName="grid__form"
                inputLists={provideDetailsInputList}
                hookForm={hookForm}
                children={
                  <VCheckBox
                    style={{
                      marginTop: "20px",
                    }}
                    checked={hasAcceptedTerms}
                    className="col__spanfull"
                    onChange={({ target: { checked } }) => {
                      setHasAcceptedTerms(!hasAcceptedTerms);
                    }}
                    label={
                      <p>
                        I accept
                        <a
                          href="https://drive.google.com/file/d/1oJJSREyylQV7yyfAGIBh-nJaZFuDA8Yw/view?usp=drivesdk"
                          target="_blank"
                          rel="noreferrer"
                          className="lightblue__text underline"
                        >
                          {" "}
                          Veedez Terms & Conditions{" "}
                        </a>
                        and I’ve read the
                        <a
                          className="lightblue__text underline"
                          href="https://drive.google.com/file/d/1lW7d7J_IaryKz6U8kgcRLMqZ7CEU_tLt/view?usp=drivesdk"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {" "}
                          Privacy Policy
                        </a>
                      </p>
                    }
                  />
                }
              />
            </form>
          </StyledChooseBusinessWrapper>
        </StyledSignupContent>
      </StyledSignupWrapper>
      <VButton
        text="Submit"
        disabled={!hasAcceptedTerms}
        isLoading={isLoading || paymentLoading}
        onClick={submitAction}
        style={{
          maxWidth: "400px",
          width: "100%",
        }}
      />
    </StyledSignOuterWrapper>
  );
};
