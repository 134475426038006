import { useRef } from "react";
import PosCard from "../card/pos-card";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { settings, renderArrows, IPosCard } from "../constants";
import { Cards } from "./styles";
import EmptyPosCard from "../card/empty-pos-cards";
import { usePosCardsHook } from "hooks";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "routes";

const PosDetailsCards = () => {
  const sliderRef = useRef(null);
  const { cardDetails } = usePosCardsHook();
  const navigate = useNavigate();

  return (
    <>
      {cardDetails?.length > 0 ? (
        <Cards>
          {renderArrows(sliderRef)}
          <Slider {...settings} ref={sliderRef}>
            {cardDetails?.map((detail: IPosCard, index: number) => (
              <PosCard
                key={detail._id}
                _id={detail._id}
                terminalName={detail.terminalName}
                index={index + 1}
                tid={detail.tid}
                amount={detail?.spec?.price}
                action={() =>
                  navigate(`${APP_ROUTES.POS_DETAILS}/${detail._id}`)
                }
              />
            ))}
          </Slider>
        </Cards>
      ) : (
        <EmptyPosCard />
      )}
    </>
  );
};

export default PosDetailsCards;
