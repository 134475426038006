import {
  CardProps,
  CustomTableProps,
  InputListType,
  OptionType,
  VChipProps,
  VDropdownProps,
} from "components/common/types";
import {
  addAllToOptionsList,
  CUSTOM_VALIDATIONS,
  formatNumberToCurrency,
  formatUnderScore,
} from "utils";
import VerticalDropdown from "assets/icons/vertical-dot.svg";

import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "routes";
import {
  StyledDashboardIconWrapper,
  StyledFrequentCustomerContact,
  StyledFrequentCustomerContactButton,
} from "components/dashboard";
import MessageIcon from "assets/images/svgs/MessageIcon";
import CallIcon from "assets/images/svgs/CallIcon";
import { VBadge, VDropdown } from "components/common";
import {
  StyledStaffImgAvater,
  StyledStaffNameAvatar,
  StyledStaffNameWrapper,
} from "./styles";
import TotalStaffIcon from "assets/images/svgs/TotalStaffIcon";
import UserRolesIcon from "assets/images/svgs/UserRolesIcon";
import InactiveStaffIcon from "assets/images/svgs/InactiveStaffIcon";
import ActiveStaffIcon from "assets/images/svgs/ActiveStaffIcon";
import { IBusinessStaffNode } from "api/staff";
import { IStaffSummaryCard } from "api";
import { ESTAFFSTATUS } from "hooks/components/useStaffDetailPageHook";
import theme from "styles/theme";

export const STAFF_TABLE_COLUMNS = ({
  handleEditUser,
  handleDeleteUserModal,
}: {
  handleEditUser: (user: IBusinessStaffNode) => void;
  handleDeleteUserModal: (user: IBusinessStaffNode) => void;
}): CustomTableProps["columns"] => {
  const navigate = useNavigate();

  const goToStaffPage = (staff: IBusinessStaffNode) =>
    navigate(`${APP_ROUTES.STAFF}/${staff._id}`, { state: staff });

  return [
    {
      headerName: "Staff ID",
      key: "employeeId",
      width: 250,
      renderCell: (id: string, data: IBusinessStaffNode) => {
        if (!data) return <p>N/A</p>;
        const { profilePicture = "", lastName = "", firstName = "" } = data;
        const mockAvatar = `${lastName[0]}${firstName[0]}`.toUpperCase();
        return (
          <StyledStaffNameWrapper>
            {profilePicture ? (
              <StyledStaffImgAvater src={profilePicture} alt={lastName} />
            ) : (
              <StyledStaffNameAvatar>{mockAvatar}</StyledStaffNameAvatar>
            )}
            <p>#{id}</p>
          </StyledStaffNameWrapper>
        );
      },
    },
    {
      headerName: "Name",
      key: "firstName",
      formatter: (firstName, value) =>
        `${formatUnderScore(value?.lastName)} ${formatUnderScore(firstName)}`,
    },
    {
      headerName: "Role",
      key: "role",
      useZero: false,
      renderCell: (role: string) => {
        if (!role || role.includes("N/A")) return "N/A";
        const parsedRole = JSON.parse(role)?.roleName;
        return parsedRole;
      },
    },
    {
      headerName: "Status",
      key: "staffStatus",
      useZero: false,
      renderCell: (value) => <VBadge status={value || ""} />,
    },
    {
      headerName: "Contact",
      key: "contact",
      renderCell: (value: string, data: Record<string, any>) => {
        if (!data) return <p>N/A</p>;
        const { phoneNumber, email } = data;
        return (
          <StyledFrequentCustomerContact>
            {email && (
              <StyledFrequentCustomerContactButton>
                <a href={`mailto:${email}`} target="_blank" rel="noreferrer">
                  <MessageIcon width={12} height={12} />
                </a>
              </StyledFrequentCustomerContactButton>
            )}
            {phoneNumber && (
              <StyledFrequentCustomerContactButton>
                <a href={`tel:${phoneNumber}`} target="_blank" rel="noreferrer">
                  <CallIcon width={12} height={12} />
                </a>
              </StyledFrequentCustomerContactButton>
            )}
          </StyledFrequentCustomerContact>
        );
      },
    },
    {
      headerName: "",
      key: "dropdown",
      renderCell: (_: string, tableData: IBusinessStaffNode) => (
        <VDropdown
          dropdownButton={
            <div className="vertical__drop--down">
              <img src={VerticalDropdown} alt="vertical-dropdown" />
            </div>
          }
          dropdownItems={STAFF_TABLE_ACTIONS_DROPDOWN({
            handleView: () => goToStaffPage(tableData),
            handleEdit: () => handleEditUser(tableData),
            handleDelete: () => handleDeleteUserModal(tableData),
          }).map((item) => ({
            ...item,
          }))}
          isLeft={true}
        />
      ),
    },
  ];
};

export const STAFF_TABLE_ACTIONS_DROPDOWN = ({
  handleView,
  handleEdit,
  handleDelete,
}: {
  handleEdit: () => void;
  handleView: () => void;
  handleDelete: () => void;
}): VDropdownProps["dropdownItems"] => {
  return [
    {
      key: "view",
      label: "View",
      styles: { color: theme.colors.green },
      onClick: () => handleView(),
    },
    {
      key: "edit",
      label: "Edit",
      styles: { color: theme.colors.lightBlue },
      onClick: () => handleEdit(),
    },
    {
      key: "deactivate",
      label: "Deactivate",
      styles: { color: theme.colors.toastRed },
      onClick: () => handleDelete(),
    },
  ] as VDropdownProps["dropdownItems"];
};

export const STAFF_SUMMARY_CARDS_DATA = (
  staffSummaryCards: IStaffSummaryCard
): CardProps[] => {
  const {
    activeStaffCount = 0,
    inactiveStaffCount = 0,
    totalStaff = 0,
    userRoles = 0,
  } = staffSummaryCards;

  return [
    {
      title: "Total Staff",
      icon: () => (
        <StyledDashboardIconWrapper color="#FBECDB">
          <TotalStaffIcon />
        </StyledDashboardIconWrapper>
      ),
      amount: formatNumberToCurrency({
        number: totalStaff,
        isShort: true,
        removeCurrency: true,
      }),
    },
    {
      title: `User Roles`,
      icon: () => (
        <StyledDashboardIconWrapper color="#E8F5F1">
          <UserRolesIcon />
        </StyledDashboardIconWrapper>
      ),
      amount: formatNumberToCurrency({
        number: userRoles,
        isShort: true,
        removeCurrency: true,
      }),
    },
    {
      title: `Inactive Staff`,
      icon: () => (
        <StyledDashboardIconWrapper color="#FFE0DE">
          <InactiveStaffIcon />
        </StyledDashboardIconWrapper>
      ),
      amount: formatNumberToCurrency({
        number: inactiveStaffCount,
        isShort: true,
        removeCurrency: true,
      }),
    },
    {
      title: `Active Staff`,
      icon: () => (
        <StyledDashboardIconWrapper color="#E0EEFF">
          <ActiveStaffIcon />
        </StyledDashboardIconWrapper>
      ),
      amount: formatNumberToCurrency({
        number: activeStaffCount,
        isShort: true,
        removeCurrency: true,
      }),
    },
  ];
};

export const ADD_OR_EDIT_STAFF_INPUT_LIST = ({
  roles,
  outlets,
  isEdit,
}: {
  roles: OptionType[];
  outlets: OptionType[];
  isEdit: boolean;
}): InputListType[] => {
  return [
    {
      name: "profilePicture",
      label: "User Avatar",
      inputType: "upload",
      fileUploadType: "avatar",
      required: true,
      className: "col__spanfull",
    },
    {
      name: "firstName",
      label: "First Name",
      required: true,

      validation: {
        required: "Enter first name",
      },
      disabled: isEdit,
    },
    {
      name: "lastName",
      label: "Last Name",
      required: true,

      validation: {
        required: "Enter last name",
      },
      disabled: isEdit,
    },
    {
      name: "email",
      label: "Email",
      type: "email",
      validation: {
        ...CUSTOM_VALIDATIONS.email,
      },
      disabled: isEdit,
    },
    {
      name: "phoneNumber",
      label: "Phone Number",
      type: "tel",
      required: true,
      validation: {
        required: "Enter your phone number",
        ...CUSTOM_VALIDATIONS.phoneNumber,
      },
      disabled: isEdit,
    },
    {
      name: "roleId",
      label: "Role",
      required: true,
      options: roles,
      inputType: "select",
      validation: { required: "Pick select a role" },
    },
    {
      name: "assignedOutlet",
      label: "Assign to Outlet",
      required: true,
      disabled: true,
      options: outlets,
      ...(!isEdit && { inputType: "select" }),
    },
  ];
};

export const STAFF_FILTER_CHIPS: VChipProps[] = addAllToOptionsList([
  {
    key: ESTAFFSTATUS.ACTIVE,
    label: "Active Staff",
  },
  {
    key: ESTAFFSTATUS.INACTIVE,
    label: "Inactive Staff",
  },
]);
