import { useEffect, useState } from "react";

export enum E_DOT_TYPE {
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}

export const DOTS = "...";

const range = (start: number, end: number) => {
  let length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

const usePagination = ({
  siblingCount = 1,
  totalPageCount,
  currentPage,
  customItemCount = 4,
}: {
  siblingCount?: number;
  totalPageCount: number;
  currentPage: number;
  customItemCount?: number;
}) => {
  const [pagination, setPagination] = useState<any[]>([]);

  useEffect(() => {
    // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
    const totalPageNumbers = siblingCount + 5;

    const itemCount = customItemCount * siblingCount;
    const leftPageBound = totalPageCount - itemCount + 1;

    /*
      Case 1:
      If the number of pages is less than the page numbers we want to show in our
      paginationComponent, we return the range [1..totalPageCount]
    */
    if (totalPageNumbers >= totalPageCount) {
      setPagination(range(1, totalPageCount));
    }

    /*
    	Calculate left and right sibling index and make sure they are within range 1 and totalPageCount
    */
    const leftSiblingIndex = Math.max(currentPage - 1, 1);
    const rightSiblingIndex = Math.min(currentPage + 1, leftPageBound - 1);

    /*
      We do not show dots just when there is just one page number to be inserted between the extremes 
      of sibling and the page limits i.e 1 and totalPageCount. Hence we are using leftSiblingIndex > 2 
      and rightSiblingIndex < totalPageCount - 2
    */
    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < leftPageBound - 1;

    const firstPageIndex = 1;

    const rightPageRange = range(leftPageBound, totalPageCount);

    /*
    	Case 2: No left dots to show, but rights dots to be shown
    */
    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftRange = range(1, itemCount);
      setPagination([
        ...leftRange,
        { number: DOTS, type: E_DOT_TYPE.RIGHT },
        ...rightPageRange,
      ]);
    }

    /*
    	Case 3: No right dots to show, but left dots to be shown
    */
    if (shouldShowLeftDots && !shouldShowRightDots) {
      let leftRange = range(
        leftSiblingIndex,
        rightSiblingIndex >= leftPageBound
          ? leftPageBound - 1
          : rightSiblingIndex
      );

      const showBeginningPages =
        currentPage >= leftPageBound ? range(1, itemCount) : [1];

      setPagination([
        ...showBeginningPages,
        { number: DOTS, type: E_DOT_TYPE.LEFT },
        ...leftRange,
        ...rightPageRange,
      ]);
    }

    /*
    	Case 4: Both left and right dots to be shown
    */
    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = range(currentPage - 1, currentPage + 1);
      setPagination([
        firstPageIndex,
        { number: DOTS, type: E_DOT_TYPE?.LEFT },
        ...middleRange,
        { number: DOTS, type: E_DOT_TYPE.RIGHT },

        ...rightPageRange,
      ]);

      return () => {
        setPagination([]);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPageCount, currentPage]);

  return { currentPage, pagination };
};

export default usePagination;
