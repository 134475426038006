import { VEmptyState, VPagination, VTable } from "components/common";
import { useTerminalTransactionsHook } from "hooks";
import { TERMINAL_TRANSACTIONS_COLUMN } from "../constants";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "routes";

const PosDetailsTable = () => {
  const navigate = useNavigate();
  const { extractedData, error, fetching, pageNumber, handleChangePage } =
    useTerminalTransactionsHook();

  if (error)
    return (
      <VEmptyState
        title="Error Fetching Data!"
        subText="An error occur getting data"
      />
    );

  const { nodes: terminalTransactions, pageInfo } = extractedData;

  return (
    <div>
      <VTable
        isLoading={fetching}
        columns={TERMINAL_TRANSACTIONS_COLUMN()}
        data={terminalTransactions}
        collapsible
        useId
        emptyContent={{
          title: "No POS Terminal transaction yet",
          actionText: "Order a POS Terminal now",
          action: () => navigate(APP_ROUTES.POS_SURVEY),
        }}
      />
      <VPagination
        pageInfo={pageInfo}
        pageNumber={pageNumber}
        getPageNumber={(pageNumber) => handleChangePage(pageNumber)}
      />
    </div>
  );
};

export default PosDetailsTable;
