import {
  IGetBusinessPlans,
  useGetBusinessPlans,
} from "api/manage-business/billing";
import { BUSINESS_PLANS } from "components/auth/signup/constants";
import { useCurrencyConverterHook } from "hooks/billing";
import { useMemo } from "react";
import { useAuthUserConstants } from "store";
import { extractFetchedData } from "utils";

type IBusinessPlanHash = {
  [key in BUSINESS_PLANS]: IGetBusinessPlans;
};

export const useBillingOverviewPageHook = () => {
  const currencyconverter = useCurrencyConverterHook();

  const [result, refetchAll] = useGetBusinessPlans();
  const { business } = useAuthUserConstants();

  const { fetching } = result;

  const { extractedData } = extractFetchedData<IGetBusinessPlans[]>({
    key: "getBusinessPlans",
    result,
    defaultReturn: [],
  });

  const buinessPlansHash = useMemo(() => {
    if (extractedData) {
      return (extractedData || []).reduce((acc, curr) => {
        acc[curr.codeName as BUSINESS_PLANS] = curr;
        return acc;
      }, {} as IBusinessPlanHash);
    }
    return {} as IBusinessPlanHash;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetching]);

  const userBusinessPlan = useMemo(() => {
    return business?.businessPlan
      ? buinessPlansHash[business?.businessPlan]
      : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetching]);

  return {
    ...result,
    refetchAll,
    userBusinessPlan,
    buinessPlansHash,
    currencyconverter,
  };
};
