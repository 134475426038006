import {
  VEmptyState,
  VPagination,
  VTable,
  VChipGroup,
  VButton,
} from "components/common";
import { StyledCustomersTable } from "./styles";
import { useCustomersPageHook } from "hooks";
import { useModal } from "hooks";
import { AddCustomerModal } from "../add-customer-modal";
import { ReactComponent as PlusIcon } from "assets/images/svgs/plus-icon.svg";
import { CUSTOMER_FILTER_CHIPS } from "../constants";
import { EditCustomerModal } from "../edit-customer-modal";
import { useAnalytics } from "hooks/useAnalytics";

const CustomersTable = () => {
  const { open, toggleModal } = useModal();

  const [logAppEvent, { open_create_customer }] = useAnalytics();

  const {
    fetching,
    customerColumns,
    extractedData,
    pageNumber,
    handleChangePage,
    handleStatusFilter,
    selectedCustomerStatus,
    error,
    modal,
  } = useCustomersPageHook();

  const { openEditCustomerModal, toggleEditCustomerModal, selectedCustomer } =
    modal;

  if (error) {
    return (
      <VEmptyState
        title="Error Fetching Data!"
        subText="An error occur getting data"
      />
    );
  }

  const { nodes: businessCustomers, pageInfo } = extractedData;

  return (
    <>
      <StyledCustomersTable>
        <VChipGroup
          activeKey={selectedCustomerStatus || ""}
          getCurrentChipKey={(status) => handleStatusFilter(status)}
          chips={CUSTOMER_FILTER_CHIPS}
        >
          <>
            <VButton
              text="Add Customer"
              height="30px"
              icon={<PlusIcon />}
              isSlim
              onClick={() => {
                logAppEvent(open_create_customer, {});
                toggleModal();
              }}
            />
          </>
        </VChipGroup>
        <VTable
          columns={customerColumns}
          data={businessCustomers}
          collapsible
          emptyContent={{
            title: "No Customer Added Yet!",
            subText: "Your history will appear here when you have one",
          }}
          isLoading={fetching}
        />
        <VPagination
          pageInfo={pageInfo}
          pageNumber={pageNumber}
          getPageNumber={(pageNumber) => handleChangePage(pageNumber)}
        />
        <AddCustomerModal open={open} close={toggleModal} />
      </StyledCustomersTable>
      <EditCustomerModal
        open={openEditCustomerModal}
        close={toggleEditCustomerModal}
        selectedCustomer={selectedCustomer}
      />
    </>
  );
};

export default CustomersTable;
