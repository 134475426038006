import {
  createClient,
  dedupExchange,
  cacheExchange,
  fetchExchange,
} from "urql";
import { requestPolicyExchange } from "@urql/exchange-request-policy";
import { AppStorage, decodeToken, getEnvKey, isTokenExpired } from "utils";
import { getRefreshToken } from "utils/firebase";
import { IUserDetails } from "./user";
import { fetchOptionsExchange } from "./user/promiseExchange";

const { getFromStore, addToStore } = new AppStorage();

const BASE_URL = getEnvKey("REACT_APP_API_BASE_URL");

export const fetchParams: Record<string, any> = {
  requestPolicy: "cache-and-network",
  exchanges: [
    dedupExchange,
    requestPolicyExchange({
      // The amount of time in ms that has to go by before upgrading, default is 5 minutes.
      ttl: 60 * 1000, // 1 minute.
      // An optional function that allows you to specify whether an operation should be upgraded.
      shouldUpgrade: (operation) =>
        operation.context.requestPolicy !== "cache-only",
    }),

    cacheExchange,
    // Must be called before fetchExchange and after all others sync exchanges, respecting the rule Synchronous first, Asynchronous last
    fetchOptionsExchange(async (fetchOptions: any) => {
      let token = getFromStore("token");

      const tokenHasExpired = isTokenExpired(token);

      if (tokenHasExpired) {
        // Re assign token
        const user = getFromStore("user");
        const newToken = await getRefreshToken();

        if (newToken) {
          token = newToken;
          const decodedToken = decodeToken(token ?? "");
          const newUser: IUserDetails = {
            ...(decodedToken ?? {}),
            ...(user ?? {}),
          };

          // Set the token to store
          addToStore("token", token);
          addToStore("user", newUser);
        }
      }

      return {
        ...fetchOptions,
        headers: { Authorization: token ? `Bearer ${token}` : "" },
      };
    }),
    fetchExchange,
  ],
};

export const urqlClient = createClient({
  url: BASE_URL,
  ...fetchParams,
});
