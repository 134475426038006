import { ThemeProps } from "./types";

const theme: ThemeProps = {
  dark: false,

  font: {
    weights: {
      light: 300,
      regular: 400,
      medium: 500,
      bold: 700,
      black: 900,
    },
  },

  colors: {
    white: "#FFFFFF",
    white01: "#fefdfc",
    black: "#0B0500",
    black01: "#001928",
    black02: "#434343",
    transparent: "transparent",
    current: "currentColor",
    ashText: "#374B58",
    ash01: "#F4F4F4",
    ash02: "#F6F6F6",
    ash03: "#798892",
    ash04: "#FAFAFA",
    ash05: "#F6F7FF",
    ash06: "#EBEBEB",
    ash07: "#DEE2E6",
    ash08: "#F1F1F1",
    ash09: "#374B58",
    ash10: "#515F68",
    ash11: "#F8F8F8",
    ash12: "#69778C",
    ash13: "#F2F2F2",
    ashInput: "#DCE3E780",
    ashRadio: "#DCE3E7",
    ashLabel: "#666666",
    grey01: "#B0B0B0",
    grey02: "#a5b0b7",
    blue01: "#515f68",
    lightBlue: "#0077FF",
    darkBlue: "#001833",
    transparentBlue: "#DDEAFF",
    transparentBlue2: "#EFF5FF",
    transparentBlue3: "#E6F1FF",
    toastBlue: "#E9F1FF",
    toastRed: "#EA4336",
    toastPink: "#FAECEC",
    faintRed: "#f9f4ef",
    red: "#CC2E2E",
    faintRed2: "#FFE7E5",
    pink: "#FDECEB",
    inputBorderHover: "rgba(55, 75, 88, 0.5)",
    inputBorder: "rgba(55, 75, 88, 0.18)",
    ashBlue: "#475E80",
    labelBlue: "#5976A1",
    cyanBlue: "#80BCFF",

    darkGrey: "#19181a",
    lightGrey: "#6F6E70",

    green: "#34A853",
    lightGreen: "#EFFCF4",
    successGreen: "#EBF6EE",
    orange: "#F1A85A",
    orange01: "#E57A1A",
  },

  breakpoints: {
    desktop: 1024,
    tablet: 768,
    mobile: 424,
  },
};

export default theme;
