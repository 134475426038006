import styled from "styled-components/macro";
import { FlexColumn, StyledVCardContainer, stylingUtils } from "styles";

const { rem, color } = stylingUtils;

export const StyledBillingPaymentMethod = styled(StyledVCardContainer)`
  ${FlexColumn};
  justify-content: space-between;
`;

export const StyledBillingPaymentMethodHeader = styled("section")`
  h3 {
    margin-bottom: ${rem(8)};
  }
`;

export const StyledBillPaymentDebitCard = styled(StyledVCardContainer)`
  border: 1px solid ${color("ash01")};
  margin-top: ${rem(20)};
`;

export const StyledPaymentDebitCardDetails = styled("section")`
  display: flex;
  gap: ${rem(20)};
  align-items: flex-start;
  margin-bottom: ${rem(20)};

  .master__cardicon {
    background-color: ${color("ash01")};
    padding: ${rem(5)} ${rem(8)};
    border-radius: 5px;
  }

  .detail__items {
    display: flex;
    flex-direction: column;
    gap: ${rem(12)};

    p {
      display: flex;
      align-items: center;
      gap: ${rem(10)};

      &:nth-child(2) {
        color: ${color("ash03")};
      }
    }
  }
`;
