import { VButton, VForm, VModal } from "components/common";

import { VModalProps } from "components/common/types";
import { ADD_STORE_INPUT_LIST } from "components/manage-business/constants";
import { useState } from "react";
import { useForm } from "react-hook-form";

export interface IAddStore {
  storeName: string;
  address: string;
  city: string;
  state: string;
}

export const AddStoreModal = ({ open, close }: VModalProps) => {
  const hookForm = useForm<IAddStore>();

  const { handleSubmit } = hookForm;
  const onSubmit = async (data: any) => {
    // console.log({ data });
  };

  const [storeInputLists] = useState([...ADD_STORE_INPUT_LIST]);

  // const handleAddStore = () => {
  //   const lastIndex = storeInputLists.length;
  //   setStoreInputLists([
  //     ...storeInputLists,
  //     ...ADD_STORE_INPUT_LIST.map((item) => ({
  //       ...item,
  //       name: `${item.name}-${lastIndex}`,
  //     })),
  //   ]);
  // };

  return (
    <VModal open={open} close={close} title="Add Store">
      <form onSubmit={handleSubmit(onSubmit)}>
        <VForm
          inputLists={storeInputLists}
          hookForm={hookForm}
          vFormClassName="grid__form"
        >
          <div className="form__button">
            <VButton isLoading={false} text="Add Store" />
            <VButton isOutline text="Add Another Store" />
          </div>
        </VForm>
      </form>
    </VModal>
  );
};
