export const ImportFilesIcon = (props: any) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_15600_161097)">
        <path
          d="M11.9997 15.1756C11.8871 15.1757 11.7756 15.1536 11.6716 15.1105C11.5676 15.0675 11.473 15.0044 11.3934 14.9248C11.3138 14.8451 11.2507 14.7506 11.2076 14.6466C11.1646 14.5426 11.1425 14.4311 11.1426 14.3185V1.49972C11.1426 1.27239 11.2329 1.05437 11.3936 0.893629C11.5544 0.732884 11.7724 0.642578 11.9997 0.642578C12.227 0.642578 12.4451 0.732884 12.6058 0.893629C12.7666 1.05437 12.8569 1.27239 12.8569 1.49972V14.3185C12.8569 14.5458 12.7666 14.7638 12.6058 14.9246C12.4451 15.0853 12.227 15.1756 11.9997 15.1756Z"
          fill="#798892"
        />
        <path
          d="M11.9996 15.5608C11.7116 15.5613 11.4262 15.5048 11.1601 15.3947C10.8939 15.2845 10.6521 15.1229 10.4486 14.919L5.8193 10.2897C5.65863 10.1291 5.56836 9.91115 5.56836 9.68392C5.56836 9.45669 5.65863 9.23877 5.8193 9.07809C5.97998 8.91742 6.1979 8.82715 6.42513 8.82715C6.65236 8.82715 6.87028 8.91742 7.03096 9.07809L11.6602 13.7073C11.7048 13.7519 11.7577 13.7873 11.8159 13.8114C11.8742 13.8355 11.9366 13.848 11.9996 13.848C12.0627 13.848 12.1251 13.8355 12.1833 13.8114C12.2416 13.7873 12.2945 13.7519 12.3391 13.7073L16.9683 9.07809C17.1299 8.92191 17.3464 8.83545 17.5712 8.83734C17.7959 8.83923 18.0109 8.92931 18.1699 9.08819C18.3289 9.24707 18.4191 9.46202 18.4211 9.68676C18.4231 9.9115 18.3368 10.128 18.1807 10.2897L13.5507 14.919C13.3472 15.1228 13.1054 15.2844 12.8392 15.3946C12.573 15.5047 12.2877 15.5612 11.9996 15.5608Z"
          fill="#798892"
        />
        <path
          d="M21.5566 23.3577H2.44433C1.84186 23.357 1.26428 23.1173 0.838264 22.6913C0.412253 22.2653 0.172601 21.6877 0.171875 21.0852V15.0935C0.171875 14.8661 0.262181 14.6481 0.422926 14.4874C0.583672 14.3266 0.801689 14.2363 1.02902 14.2363C1.25635 14.2363 1.47436 14.3266 1.63511 14.4874C1.79585 14.6481 1.88616 14.8661 1.88616 15.0935V21.0852C1.88634 21.2332 1.94521 21.3751 2.04985 21.4797C2.15448 21.5844 2.29635 21.6432 2.44433 21.6434H21.5566C21.7045 21.6432 21.8464 21.5844 21.951 21.4797C22.0557 21.3751 22.1146 21.2332 22.1147 21.0852V15.0935C22.1147 14.8661 22.205 14.6481 22.3658 14.4874C22.5265 14.3266 22.7445 14.2363 22.9719 14.2363C23.1992 14.2363 23.4172 14.3266 23.578 14.4874C23.7387 14.6481 23.829 14.8661 23.829 15.0935V21.0852C23.8283 21.6877 23.5886 22.2653 23.1626 22.6913C22.7366 23.1173 22.159 23.357 21.5566 23.3577Z"
          fill="#798892"
        />
      </g>
      <defs>
        <clipPath id="clip0_15600_161097">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
