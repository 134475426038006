import * as localforage from "localforage";

export interface IuseAppStorage {
  addToStore: (key: string, value: any) => Promise<boolean | null>;
  getFromStore: (key: string) => Promise<any>;
  removeFromStore: (key: string) => Promise<boolean | null>;
  clearStore: () => Promise<void | null>;
}
// Create Config
localforage.config({
  name: "Veedez PWA",
  storeName: "veedez-pwa", // Should be alphanumeric, with underscores.
  description: "Storage site for veedez pwa information",
});

/** Use IndexDB for storage */
const useIndexDb = (): IuseAppStorage => {
  // Add Item to store
  const addToStore = async (key: string, value: unknown) => {
    try {
      const result = await localforage.setItem(key, value);
      // This code runs once the value has been loaded from the offline store.
      return !!result;
    } catch (err) {
      // This code runs if there were any errors.
      return null;
    }
  };

  // Get item from store
  const getFromStore = async (key: string) => {
    try {
      const result = await localforage.getItem(key);
      // This code runs once the value has been loaded
      // from the offline store.
      return result;
    } catch (err) {
      // This code runs if there were any errors.
      return null;
    }
  };

  // Remove item from store
  const removeFromStore = async (key: string) => {
    try {
      await localforage.removeItem(key);
      // This code runs once the value has been loaded
      // from the offline store.
      return true;
    } catch (err) {
      // This code runs if there were any errors.
      return null;
    }
  };

  // Empty store
  const clearStore = async () => {
    try {
      await localforage.clear();
    } catch (error) {
      return null;
    }
  };

  return {
    addToStore,
    getFromStore,
    clearStore,
    removeFromStore,
  };
};

export default useIndexDb;
