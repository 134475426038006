import { VPagination, VTable } from "components/common";
import { useBillingTableBHook } from "hooks";
import { StyledBillingTable } from "./styles";

export const BillingTable = () => {
  const {
    billingColumns,
    billingSubscriptions,
    pageInfo,
    handleChangePage,
    pageNumber,
  } = useBillingTableBHook();

  return (
    <StyledBillingTable>
      <VTable
        columns={billingColumns}
        data={billingSubscriptions}
        emptyContent={{
          title: "No Billing yet!",
          subText: "Your history will appear here when you have one",
        }}
      />
      <VPagination
        pageInfo={pageInfo}
        pageNumber={pageNumber}
        getPageNumber={(pageNumber) => handleChangePage(pageNumber)}
      />
    </StyledBillingTable>
  );
};
