import styled from "styled-components/macro";
import { stylingUtils } from "styles";

const {
  rem,
  mediaQuery: { down },
} = stylingUtils;

export const StyledExpenseDetailsContainer = styled("div")`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${rem(40)};
`;

export const StyledExpenseItem = styled("div")`
  font-size: ${rem(12)};
  ${down("laptop")} {
    font-size: ${rem(11)};
  }
  p {
    font-weight: 400;
  }
  .expense__item--header {
    font-weight: 500;
    margin-bottom: ${rem(10)};
    ${down("laptop")} {
      font-weight: 600;
    }
  }
`;
