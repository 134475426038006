import { gql, useQuery } from "urql";

const getAppConfigQuery = gql`
  {
    getAppConfig {
      transactionFee {
        transactionFeeAmount
        transactionFeeType
        transactionFeeCap
      }
      importInventoryTemplate
      pinLength
    }
  }
`;

export const useGetAppConfig = () => {
  return useQuery({
    query: getAppConfigQuery,
  });
};

export const useGetAppConfigHook = ({ lazy }: { lazy?: boolean } = {}) => {
  const [fetchAppConfig, makeRequest] = useQuery({
    query: getAppConfigQuery,
  });

  const transactionFeeAmount =
    fetchAppConfig?.data?.getAppConfig?.transactionFee?.transactionFeeAmount;

  const transactionFeeType =
    fetchAppConfig?.data?.getAppConfig?.transactionFee?.transactionFeeType;

  const transactionFeeCap =
    fetchAppConfig?.data?.getAppConfig?.transactionFee?.transactionFeeCap;
  const importInventoryTemplate =
    fetchAppConfig?.data?.getAppConfig?.importInventoryTemplate;

  const pinLength = fetchAppConfig?.data?.getAppConfig?.pinLength;

  console.log(fetchAppConfig);

  return {
    data: {
      transactionFeeAmount,
      transactionFeeType,
      transactionFeeCap,
      importInventoryTemplate,
      pinLength,
    },
    fetchAppConfig,

    makeRequest,
  };
};
