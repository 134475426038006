import styled from "styled-components/macro";
import { stylingUtils } from "styles";

const { color, rem } = stylingUtils;

export const RequestDetailsStyles = styled("div")`
  justify-content: space-between;
  background-color: ${color("white")};
  padding: 2rem;
  border-radius: ${rem(20)};

  h4 {
    font-family: "Gilmer";
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: ${color("ashBlue")};
  }
`;

export const StatusStyles = styled("div")`
  display: flex;
  justify-content: space-between;
  padding: ${rem(30)} 0;
  overflow-x: scroll;

  .pin {
    display: grid;
    place-content: center;
    margin: 0 ${rem(2)};
  }
`;

export const StatusCardStyles = styled("div")<{
  done: boolean;
  rejected: boolean;
}>`
  width: 204px;
  height: 52px;
  background-color: ${({ done, rejected }) =>
    done
      ? color("transparentBlue3")
      : rejected
      ? color("faintRed2")
      : color("ash13")};
  color: ${({ done, rejected }) =>
    done
      ? color("lightBlue")
      : rejected
      ? color("toastRed")
      : color("ashText")};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: ${({ done, rejected }) =>
    done || rejected ? "space-between" : "center"};
  gap: 1rem;
  padding: 15px;
  white-space: nowrap;

  h5 {
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
  }

  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }
`;

export const OptionStyles = styled("div")`
  display: flex;
  align-items: center;
  gap: 10rem;
  margin-top: ${rem(20)};
  margin-bottom: ${rem(50)};
  flex-wrap: wrap;

  .left {
    display: flex;
    /* gap: 1rem; */
    align-items: flex-start;
    margin-bottom: ${rem(20)};
    margin-left: ${rem(-16)};
  }
  h3 {
    font-weight: 700;
    font-size: ${rem(18)};
    line-height: ${rem(24)};
    color: ${color("black01")};
    margin-top: ${rem(-4)};
  }
  p {
    color: ${color("black01")};
    font-weight: 600;
    line-height: ${rem(22)};
    font-size: ${rem(16)};
  }
  .bottom-flex {
    display: flex;
    gap: ${rem(60)};
    align-items: center;
    margin-top: ${rem(25)};
  }
  ul {
    margin-bottom: ${rem(20)};
  }
  li {
    font-weight: 400;
    font-size: 14px;
    line-height: 27px;
    color: ${color("ashText")};
  }
  img {
    height: 100%;
  }
  .features {
    display: flex;
    align-items: center;
    gap: 0.8rem;

    & > img {
      width: 19.26px;
    }
    & > img:last-of-type {
      width: 30px;
    }
  }

  @media (max-width: 1200px) {
    gap: 3rem;
    margin-top: ${rem(40)};
  }
  @media (max-width: 400px) {
    .bottom-flex {
      flex-direction: column;
    }
  }
`;
export const ButtonStyles = styled("div")`
  display: flex;
  justify-content: center;
  margin-top: ${rem(80)};
`;
