import styled from "styled-components/macro";
import { stylingUtils } from "styles";

const { rem, color } = stylingUtils;

export const StyledVSwitchWrapper = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

export const StyledVSwitch = styled.div`
  position: relative;
  width: ${rem(40)};
  height: ${rem(22)};
  background: ${color("inputBorder")};
  border-radius: ${rem(32)};
  padding: 4px;
  transition: 300ms all;

  &:before {
    transition: 300ms all;
    content: "";
    position: absolute;
    width: ${rem(17)};
    height: ${rem(17)};
    border-radius: 9999%;
    top: 50%;
    left: 2px;
    background: white;
    transform: translate(0, -50%);
  }
`;

export const StyledVSwitchInput = styled.input`
  opacity: 0;
  position: absolute;

  &:checked + ${StyledVSwitch} {
    background: ${color("lightBlue")};

    &:before {
      transform: translate(19px, -50%);
    }
  }
`;
