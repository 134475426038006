import styled, { createGlobalStyle, css } from "styled-components";

// fonts
import GlimmerBold from "../assets/fonts/GilmerFont2/Gilmer-Bold.otf";
import GlimmerHeavy from "../assets/fonts/GilmerFont2/Gilmer-Heavy.otf";
import GlimmerLight from "../assets/fonts/GilmerFont2/Gilmer-light.otf";
import GlimmerMedium from "../assets/fonts/GilmerFont2/Gilmer-Medium.otf";
import GlimmerRegular from "../assets/fonts/GilmerFont2/Gilmer-Regular.otf";
import { DRIVERJS_OVERRIDE } from "./driverjs";

import stylingUtils from "./styling.utils";
import { REACT_TOAST, TOAST_OVERRIDE } from "./toast";

const {
  mediaQuery: { up, down },
  rem,
  color,
} = stylingUtils;

const GlobalStyle = createGlobalStyle`
@font-face {
  font-family: "Gilmer";
  font-display: swap;
  src: url(${GlimmerLight}) format("opentype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Gilmer";
  font-display: swap;
  src: url(${GlimmerRegular}) format("opentype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Gilmer";
  font-display: swap;
  src: url(${GlimmerMedium}) format("opentype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Gilmer";
  font-display: swap;
  src: url(${GlimmerBold}) format("opentype");
  font-weight: 700;
  font-style: normal;
}


@font-face {
  font-family: "Gilmer";
  font-display: swap;
  src: url(${GlimmerHeavy}) format("opentype");
  font-weight: 900;
  font-style: normal;
}

  :root {
     --tooltip-text-color: white;
     --tooltip-background-color: black;
     --tooltip-margin: 30px;
     --tooltip-arrow-size: 6px;
  }

  ${REACT_TOAST};
  ${TOAST_OVERRIDE};
  ${DRIVERJS_OVERRIDE};

  // Calculates the grid of one one column in a 12 column grid
  --col-width: calc(100%/12);
  html {
    // Used for scaling spacing/widths back up to ensure a near pixel perfect
    // match with the design, while still respecting user font
    // sizes
    --rem-scaler: 1.143;
    font-size: 87.5%;

    @media (max-width: 600px) {
      --rem-scaler: 1;
    }


	${stylingUtils.mediaQuery.up("desktop")} {

		font-size: 100%;
		--rem-scaler: 1;
	}


	color: ${stylingUtils.color("ashText")}!important;
  }

  html,
  body {
    padding: 0;
    margin: 0;
      font-family: Gilmer, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif!important;
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  .container {
 

    ${stylingUtils.allExceptLastChild} {
      margin-bottom: 2rem;
    }
  
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  a, button {
    cursor: pointer;
    font-family: inherit;
	background: transparent;
	border: none;
  }

  .hide-scrollbar {
    scrollbar-width: none; /* Firefox, Recent Chrome family */
    -ms-overflow-style: none; // IE10+
    &::-webkit-scrollbar { // Older Chrome family
      width: 0;
      height: 0;
      background-color: transparent;
    }
  }

  .overflow__table {
	max-width: 100%;
	overflow-x: auto;
  }

  .show__below--tablet {
	display: none;

	${down("tablet")} {
		display: block
	}
  }
  .show__above--tablet {

	display: none;
	${up("tablet")} {
		display: block
	}
  }

  .w-full {
	width: 100%
  }

  .underline {
    text-decoration: underline;
  }
  .success__text {
    color: ${stylingUtils.color("green")}
  }
  .lightblue__text {
    color: ${stylingUtils.color("lightBlue")}
  }
  .error__text {
    color: ${stylingUtils.color("red")}
  }

  .float__right {
    margin-left: auto;
  }

  .bold__text {
    font-weight: 500;
  }

  .recharts-wrapper .recharts-cartesian-grid-vertical line,
  .recharts-wrapper .recharts-cartesian-grid-vertical line {
      stroke-opacity: 0;
  }

  /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.text-link {
  text-decoration: underline;
      color: ${color("lightBlue")};
      cursor: pointer;
}
`;

export const ContainerMixin = css`
  max-width: calc(${stylingUtils.rem(1400)} - ${stylingUtils.rem(235)});
`;

export const StyledVCardContainer = styled("div")`
  background-color: ${color("white")};
  padding: ${rem(20)} ${rem(20)} ${rem(20)};
  border-radius: ${rem(10)};
`;

export const ShadowMixin = css`
  box-shadow: -1px 4px 21px rgba(0, 0, 0, 0.04);
`;

export const FlexJAMixin = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const FlexJMixin = css`
  display: flex;
  justify-content: center;
`;
export const FlexColumn = css`
  display: flex;
  flex-direction: column;
`;
export const FlexAMixin = css`
  display: flex;
  align-items: center;
`;

export default GlobalStyle;
