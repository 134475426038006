import styled from "styled-components";
import { FlexColumn, stylingUtils } from "styles";

const {
  rem,
  mediaQuery: { down },
} = stylingUtils;

export const StyledDashboardWrapper = styled("section")`
  ${FlexColumn};
  gap: ${rem(20)};
`;

export const StyledDashboardTableWrapper = styled("section")`
  display: flex;
  gap: ${rem(20)};

  ${down("tablet")} {
    flex-wrap: wrap;
  }
`;

export const StyledDashboardMiddleWrapper = styled("section")<{
  shouldBeFirst: boolean;
}>`
  display: flex;
  gap: 1rem;
  width: 100%;
  flex-wrap: ${({ shouldBeFirst }) => (shouldBeFirst ? "no-wrap" : "wrap")};

  ${down("tablet")} {
    flex-wrap: wrap;
  }
`;
