import {
  IAddCustomerBusiness,
  IBusinessSectors,
  IStateAndCities,
  useAddCustomerBusiness,
  useGetBusinessSectors,
  useGetStateAndCities,
  useUpgradeBusinessPlan,
} from "api";
import { BUSINESS_INFORMATION_INPUT_LISTS } from "components/auth/signup/constants";
import { showToast } from "components/common";
import { usePasscodeContext } from "context";
import { IBillingData } from "hooks/billing";
import useIndexDb from "hooks/useIndexDb";
import useLocalSearchParams from "hooks/useLocalSearchParams";
import useSteps from "hooks/useSteps";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useAuthUserConstants } from "store";
import { AppStorage, extractFetchedData, STORGAGE_KEYS } from "utils";
import {
  convertToOptions,
  generateTransactionRef,
  getUrlQueryEntries,
} from "./../../utils/libs";
import { useAnalytics } from "hooks/useAnalytics";

const { getFromStore } = new AppStorage();

const BusinessRegistrationParams = {
  "1": "select-organization",
  "2": "provide-business-details",
};

export const useBusinessRegistrationHook = () => {
  const [businessType, setBusinessType] = useState("");
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
  const hookForm = useForm<IAddCustomerBusiness>();
  const { isLoading, handleAddCustomerBusiness } = useAddCustomerBusiness();
  // const { isLoading: updatingBusiness, handleUpdateBusiness } =
  //   useUpdateBusiness();
  const { isLoading: updgradingBusiness, handleUpgradeBusinessPlan } =
    useUpgradeBusinessPlan();
  const { user: userData } = useAuthUserConstants();
  const [appLoading] = useState(false);
  const userPlan = getFromStore(STORGAGE_KEYS.BUSINESS_PLAN);
  const [paymentLoading, toggleMakingPayment] = useState(false);
  const hasBilling = !!userPlan;
  const steps = useSteps(3);
  const location = useLocation();

  const [
    logAppEvent,
    { businessregistrationbegins, businessregistrationcomplete },
  ] = useAnalytics();

  logAppEvent(businessregistrationbegins, {}).catch(console.log);

  const { hasVerifyPasscode, isLoading: passcodeLoading } =
    usePasscodeContext();

  useEffect(() => {
    if (hasVerifyPasscode) {
      steps.jumpToStep(2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasVerifyPasscode, passcodeLoading]);

  const { payment_successful = "" } = getUrlQueryEntries();

  const [openPaymentModal, togglePaymentModal] = useState(false);

  const [openPaymentSuccessful, togglePaymentSuccessful] = useState(false);

  const [billingData, setBillingData] = useState<IBillingData | null>(null);

  const {
    addToStore: addToIndexDb,
    getFromStore: getFromIndexDb,
    removeFromStore: removeFromIndexDb,
  } = useIndexDb();

  const { currentStep } = steps;

  const { addSearchParams } = useLocalSearchParams();

  //Manage params
  useEffect(() => {
    const registeredBusinessPage = location?.pathname?.includes(
      "business-registration"
    );

    if (registeredBusinessPage) {
      addSearchParams(
        "step",
        (BusinessRegistrationParams as Record<string, any>)[currentStep ?? "1"]
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  useEffect(() => {
    const hasFinishedPaying = payment_successful === "true";
    if (hasFinishedPaying) {
      togglePaymentSuccessful(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payment_successful]);

  //Sectors
  const [result] = useGetBusinessSectors({});

  const { extractedData } = extractFetchedData<IBusinessSectors>({
    result,
    key: "getBusinessSectors",
  });

  //State and cities
  const [statesAndCitiesResult] = useGetStateAndCities();

  const { extractedData: statesAndCities } = extractFetchedData<
    IStateAndCities[]
  >({
    result: statesAndCitiesResult,
    key: "getStateAndCities",
    defaultReturn: [],
  });

  const { watch } = hookForm;

  const stateId = (watch("stateId") || "") as string;

  const provideDetailsInputList = useMemo(() => {
    const businessSector = convertToOptions({
      records: extractedData?.nodes || [],
      label: "sectorName",
      value: "_id",
    });

    return BUSINESS_INFORMATION_INPUT_LISTS({
      businessSector,
      statesAndCities,
      stateId,
      userPlan,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extractedData?.nodes, statesAndCities, stateId]);

  const { handleSubmit } = hookForm;

  const makeBillingPlanPayment = () => {
    setBillingData({
      amount: 10,
      customer: {
        email: userData?.email || "",
        name: `${userData?.firstName} ${userData?.lastName}`,
        phone_number: userData?.phoneNumber ?? "",
      },
      tx_ref: generateTransactionRef(userData?._id ?? ""),
    });

    togglePaymentModal(true);
  };

  const onSubmit = async (data: IAddCustomerBusiness) => {
    const state = statesAndCities[Number(stateId)];
    const city = state?.cities[Number(data.cityId)];

    const newData = {
      ...data,
      businessType,
      customerId: userData?._id as string,
      stateId: state?._id,
      cityId: city?._id,
      stateName: state?.stateName,
      cityName: city?.cityName,
    };

    try {
      if (hasBilling) {
        // jumpToStep(3);
        await addToIndexDb(STORGAGE_KEYS.BUSINESS_STORE_DETAILS, newData);
        makeBillingPlanPayment();
        // insantiate payment
      } else {
        await handleAddCustomerBusiness(newData);
      }
    } catch (e) {}
  };

  const handleFormSubmit = handleSubmit(onSubmit);

  const handleAddBusinessCallback = async () => {
    const bussinessDetails = await getFromIndexDb(
      STORGAGE_KEYS.BUSINESS_STORE_DETAILS
    );
    const canRegisterBusiness = !!bussinessDetails;

    // can register business
    if (canRegisterBusiness) {
      logAppEvent(businessregistrationcomplete, {}).catch(console.log);
      await handleAddCustomerBusiness({
        ...bussinessDetails,
        isSubscribed: true,
      });
      removeFromIndexDb(STORGAGE_KEYS.BUSINESS_STORE_DETAILS);
    }

    togglePaymentSuccessful(true);
  };

  const handleUpgradeBusinessPlanCallback = async ({
    data,
    callback,
    shouldShowModal = true,
  }: {
    data: {
      businessPlan: string;
      businessId: string;
    };
    callback?: (response: Record<string, any>) => void;
    shouldShowModal?: boolean;
  }) => {
    let success = false;
    // can register business
    await handleUpgradeBusinessPlan({
      data,
      callBack: (data) => {
        const extractedData = data?.upgradeBusinessPlan;
        if (extractedData?.status?.toLowerCase().includes("success")) {
          if (shouldShowModal) {
            togglePaymentSuccessful(true);
          }
          // refetchUserProfile();
          callback?.(extractedData);
          success = true;
        } else {
          showToast({
            type: "error",
            title: extractedData?.message,
          });
        }
      },
    });
    return success;
  };

  const handleClosePayment = () => {
    setBillingData(null);
    togglePaymentModal(false);
    toggleMakingPayment(false);
  };

  return {
    hookForm,
    steps,
    businessType: { businessType, setBusinessType },
    handleFormSubmit,
    terms: { hasAcceptedTerms, setHasAcceptedTerms },
    provideDetailsInputList,
    isLoading,
    userPlan,
    appLoading: appLoading || passcodeLoading,
    billingData,
    openPaymentModal,
    togglePaymentModal,
    handleAddBusinessCallback,
    togglePaymentSuccessful,
    openPaymentSuccessful,
    paymentLoading,
    toggleMakingPayment,
    handleClosePayment,
    statesAndCities,
    stateId,
    extractedData,
    handleUpgradeBusinessPlanCallback,
    updgradingBusiness,
    makeBillingPlanPayment,
    payment_successful,
    passcodeLoading,
  };
};
