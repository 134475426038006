import { VChipGroupProps, VChipProps } from "components/common/types";
import {
  StyledVChip,
  StyledVChipContainerChildren,
  StyledVChipsContainer,
  StyledVChipVerticalDivider,
} from "./styles";
import { useCallback, useEffect, useState, memo } from "react";
import { useLocation } from "react-router-dom";

export const VChipContainer = ({
  children,
  noBackgroundColor,
}: {
  children: JSX.Element | JSX.Element[];
  noBackgroundColor?: boolean;
}) => {
  const location = useLocation();
  const currentPage = location.pathname.split("/").at(-1);
  const pageTitle = location.pathname.split("/").at(-2);

  return (
    <StyledVChipsContainer
      noBackgroundColor={noBackgroundColor}
      currentPage={currentPage}
      pageTitle={pageTitle}
    >
      {children}
    </StyledVChipsContainer>
  );
};

export const VChip = ({
  onClick,
  isActive = false,
  label,
  isSlim = false,
  type,
  chipClassName,
}: VChipProps) => {
  return (
    <StyledVChip
      isSlim={isSlim}
      onClick={() => onClick?.()}
      isActive={isActive}
      className={type || chipClassName}
    >
      {label}
    </StyledVChip>
  );
};

export const VChipGroup = memo(
  ({
    noBackgroundColor = false,
    noDefaultChip = false,
    chips,
    activeKey = "",
    getCurrentChipKey,
    children,
  }: VChipGroupProps) => {
    const [selectedChip, setSelectedChip] = useState<string>(activeKey);

    useEffect(() => {
      setSelectedChip(activeKey || noDefaultChip ? "" : chips[0]?.key);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chips.length, activeKey]);

    const handleChipClick = useCallback(
      (chipKey: string) => {
        setSelectedChip(chipKey);
        getCurrentChipKey?.(chipKey);
      },

      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    );

    return (
      <VChipContainer noBackgroundColor={noBackgroundColor}>
        <StyledVChipContainerChildren className="flex__child">
          {chips.map(({ key, label }) => {
            const isActive = selectedChip === key;
            return (
              <VChip
                isActive={isActive}
                key={key}
                label={label}
                onClick={() => handleChipClick(key)}
              />
            );
          })}
        </StyledVChipContainerChildren>
        {children ? <StyledVChipVerticalDivider /> : <></>}
        <StyledVChipContainerChildren className="action-buttons">
          {children}
        </StyledVChipContainerChildren>
      </VChipContainer>
    );
  }
);
