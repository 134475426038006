import { CustomTableProps } from "components/common/types";
import { VCheckBox, VInput, VSelect } from "components/common";
import { IMAGE_PLACE_HOLDER } from "components/constants";
import { useState } from "react";
import { formatNumberToCurrency } from "utils";

export const PRODUCT_TABLE_COLUMNS = ({
  changeSellingPrice,
  updateCheckedValue,
  toggleAllSelect,
  allSelected,
  productCategories,
  changeCategoryId,
}: {
  changeSellingPrice: ({ id, value }: { id: string; value: string }) => void;
  changeCategoryId: ({ id, value }: { id: string; value: string }) => void;
  updateCheckedValue: ({ id, value }: { id: string; value: boolean }) => void;
  toggleAllSelect: () => void;
  allSelected: boolean;
  productCategories: { label: string; value: string; key: string }[];
}): CustomTableProps["columns"] => {
  const [selectedCategory, setSelectedCategory] = useState<{
    [value: string]: string;
  }>({});

  return [
    {
      headerName: (
        <VCheckBox checked={allSelected} onChange={toggleAllSelect} />
      ),
      key: "_id",
      width: 100,
      renderCell: (_id: string, data: any) => {
        return (
          <VCheckBox
            name={_id}
            checked={data.checked}
            onChange={(e) =>
              updateCheckedValue({
                id: data._id,
                value: !data.checked,
              })
            }
          />
        );
      },
    },
    {
      headerName: "",
      key: "productImage",
      width: 50,
      renderCell: (value: string) => {
        return (
          <div className="flex_productId--img">
            <img src={value ? value : IMAGE_PLACE_HOLDER} alt="" />
          </div>
        );
      },
    },
    {
      headerName: "Name",
      key: "productName",
      renderCell: (value) => (
        <p
          style={{
            textTransform: "capitalize",
          }}
        >
          {value}
        </p>
      ),
    },
    {
      headerName: "Category",
      key: "categoryId",
      width: 300,

      renderCell: (value: string, data: any) => (
        <>
          <VSelect
            name={data._id}
            // value={value || ''}
            onChange={(value: string) => {
              changeCategoryId({
                id: data._id,
                value,
              });

              setSelectedCategory({
                ...selectedCategory,
                [value]: value,
              });
            }}
            optionValue={selectedCategory[value]}
            options={productCategories}
            inputWrapperStyle={{
              top: 10,
              width: 250,
            }}
          />
        </>
      ),
    },
    {
      headerName: "Cost Price",
      key: "purchasePrice",
      renderCell: (value) => <>{value}</>,
      formatter: (amount) => {
        const Amount = formatNumberToCurrency({
          number: amount || 0,
        });
        return Amount;
      },
    },
    {
      headerName: "Selling Price",
      key: "sellingPrice",
      width: 300,

      renderCell: (value: string, data: any) => (
        <>
          <VInput
            name={data._id}
            value={value || ""}
            style={{
              top: 10,
              width: 250,
            }}
            type="amount"
            onChange={(e) =>
              changeSellingPrice({
                id: data._id,
                value: e.target.value,
              })
            }
          />
        </>
      ),
    },
    {
      headerName: "Quantity",
      key: "quantity",

      renderCell: (value) => <>{value}</>,
    },
  ];
};
