import { useCallback, useEffect } from "react";
import { useLocalSearchParams, useModal } from "hooks";
import {
  IBusinessCustomers,
  useGetBusinessCustomers,
  useGetCustomerSummaryCards,
} from "api";
import { useMemo, useState } from "react";
import { extractFetchedData } from "utils";
import { useAuthUser } from "store";
import {
  CUSTOMERS_TABLE_COLUMN,
  CUSTOMERS_CARDS_DATA,
} from "components/customers";
import { showToast } from "components/common";
import { IBusinessCustomer } from "components/customers/customers/edit-customer-modal";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "routes";
import { useAnalytics } from "hooks/useAnalytics";

export const useCustomersPageHook = () => {
  const { searchParams, addSearchParams } = useLocalSearchParams();
  const [customerId, setCustomerId] = useState<string>("");

  const [logAppEvent, { goto_customers }] = useAnalytics();
  logAppEvent(goto_customers, {});

  const { userData } = useAuthUser();
  const navigate = useNavigate();
  const { open, toggleModal } = useModal();

  // customer details
  const handleViewCustomerDetails = (id: string) => {
    setCustomerId(id);
    navigate(`${APP_ROUTES.CUSTOMERS}/${id}`);
  };

  // customer listings
  const [selectedCustomer, setSelectedCustomer] =
    useState<IBusinessCustomer | null>(null);

  const handleEditCustomer = (customer: IBusinessCustomer) => {
    setSelectedCustomer(customer);
    toggleModal();
  };

  const customerColumns = CUSTOMERS_TABLE_COLUMN({
    handleViewCustomerDetails: (id: string) => handleViewCustomerDetails(id),
    handleEditCustomer,
  });

  const [pageNumber, setPageNumber] = useState(
    parseInt(searchParams.get("page") || "1")
  );

  const selectedCustomerStatus = searchParams.get("status");

  const [result, refreshBusinessCustomers] = useGetBusinessCustomers({
    businessId: userData?.businessId as string,
    page: pageNumber,
    ...(selectedCustomerStatus &&
      selectedCustomerStatus?.toLowerCase() !== "all" && {
        status: selectedCustomerStatus?.toUpperCase() || "",
      }),
  });

  const extract = extractFetchedData<IBusinessCustomers>({
    result,
    key: "getBusinessCustomers",
  });

  const handleChangePage = (pageNumber: number) => {
    setPageNumber(pageNumber);
  };

  const handleStatusFilter = useCallback((status: string) => {
    addSearchParams("status", status);
    addSearchParams("page", "1");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // customer summary cards
  const [fetchSummaryData] = useGetCustomerSummaryCards();

  const { data: customerSummaryData } = extractFetchedData<any>({
    result: fetchSummaryData,
    key: "getCustomerSummaryCards",
  });

  useEffect(() => {
    if (fetchSummaryData.error) {
      showToast({
        subText: "Could not get summary data. Please try again",
        type: "error",
      });
    }
  }, [fetchSummaryData.error]);

  const newData = {
    totalCustomers:
      customerSummaryData?.getCustomerSummaryCards?.totalCustomers || 0,
    totalPurchases:
      customerSummaryData?.getCustomerSummaryCards?.totalPurchases || 0,
    totalInactiveCustomers:
      customerSummaryData?.getCustomerSummaryCards?.totalInactiveCustomers || 0,
    totalActiveCustomers:
      customerSummaryData?.getCustomerSummaryCards?.totalActiveCustomers || 0,
  };

  const cardDataRef = JSON.stringify(newData);

  const cardData = useMemo(() => {
    return CUSTOMERS_CARDS_DATA(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardDataRef]);

  return {
    ...extract,
    handleChangePage,
    pageNumber,
    handleStatusFilter,
    customerColumns,
    cardData,
    customerId,
    setCustomerId,
    modal: {
      openEditCustomerModal: open,
      toggleEditCustomerModal: toggleModal,
      selectedCustomer,
      setSelectedCustomer,
    },
    selectedCustomerStatus,
    refreshBusinessCustomers,
  };
};
