import styled from "styled-components/macro";
import { stylingUtils } from "styles";

const { color, rem } = stylingUtils;

export const Cards = styled("div")`
  margin: ${rem(20)} 0;
  position: relative;
  margin-top: 2rem;
`;

export const UserDetailsStyles = styled("div")`
  padding: 1rem;
  border-radius: ${rem(12)};
  background-color: ${color("white")};
  color: ${color("ash03")};
  margin: 2rem 0;
  display: flex;
  justify-content: space-between;
  gap: ${rem(20)};
  font-size: ${rem(13)};
  overflow-x: scroll;

  & > div {
    white-space: nowrap;
  }

  & > :last-child {
    text-align: right;
  }

  p {
    font-weight: 400;
    margin-bottom: 0.5rem;
  }
  h3 {
    font-weight: 500;
  }
`;
