import { gql, useQuery } from "urql";

export const useGetLossAndProfit = ({ customerId }: { customerId: string }) => {
  return useQuery({
    query: getLossAndProfitQuery,
    variables: { customerId },
  });
};

const getLossAndProfitQuery = gql`
  query ($customerId: String) {
    getGainLossAndNetProfit(
      customerId: $customerId
      transactionHistory: MONTHLY
    ) {
      gain
      loss
      netProfit
      dataLog {
        _id
        saleId
        quantity
        customerProductName
        total
      }
    }
  }
`;
