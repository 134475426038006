import { showToast } from "./../../components/common/VToast/index";
import { gql, useMutation } from "urql";
import { handleRequestCall } from "utils/handleRequestCall";

const updateBusinessStaffStatusMutation = gql`
  mutation ($businessStaffId: String!) {
    updateBusinessStaffStatus(businessStaffId: $businessStaffId) {
      _id
      status
    }
  }
`;

export const useUpdateBusinessStaffStatus = () => {
  const [businessStaffStatus, updateBusinessStatffStatus] = useMutation(
    updateBusinessStaffStatusMutation
  );

  const handleUpdateBusinessStatffStatus = async (
    data: { businessStaffId: string },
    callBack?: (data: Record<string, any>) => void
  ) => {
    try {
      const req = await updateBusinessStatffStatus(data);

      await handleRequestCall({ request: req, callBack });
    } catch (e) {
      showToast({
        type: "error",
        title: "An error ocucured",
        subText: "Please try again",
      });
      console.error(e);
    }
  };

  return {
    handleUpdateBusinessStatffStatus,
    businessStaffStatus,
  };
};
