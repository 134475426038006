import styled from "styled-components/macro";
import { FlexAMixin, StyledVCardContainer, stylingUtils } from "styles";

const { rem, color } = stylingUtils;

export const StyledStoreOverviewCard = styled(StyledVCardContainer)`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: ${rem(50)};
  padding-bottom: ${rem(50)};
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
`;

export const StyledStoresHeader = styled("section")``;

export const StyledStoreBusinessTitleAndLogo = styled("div")`
  display: flex;
  gap: ${rem(12)};
  align-items: flex-start;
`;

export const StyledStoreBusinessTitle = styled("div")`
  padding-top: ${rem(20)};
  h2 {
    display: flex;
    gap: ${rem(10)};
  }
`;

export const StyledStorePlanChip = styled("button")`
  display: flex;
  justify-content: center;
  font-size: ${rem(12)};
  margin-top: ${rem(10)};
  background-color: ${color("transparentBlue")};
  padding: ${rem(8)} ${rem(10)};
  gap: ${rem(8)};
  border-radius: ${rem(20)};
`;

export const StyledPlanDetailsUpgraded = styled("div")`
  ${FlexAMixin};
  justify-content: space-between;
  gap: ${rem(10)};
  background-color: ${color("ash04")};
  padding: ${rem(10)} ${rem(15)};
  border-radius: ${rem(10)};

  p {
    max-width: ${rem(350)};
    width: 100%;
    font-size: ${rem(12)};
    font-weight: 200;
    color: ${color("toastRed")};
  }
`;
