import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import PosOption from "./pos-option";
import { useGetTerminalSpecsHook, useBusinessRegistrationHook } from "hooks";
import { VButton, VCheckBox, VForm } from "components/common";
import { APP_ROUTES } from "routes";
import { ITerminalSpecs } from "api";
import { RequestPosFrame, ButtonDiv, Terms, OptionFlex } from "./styles";

const RequestPos = () => {
  const location = useLocation();
  const addressData = location.state;

  const { TERMINAL_SPECS } = useGetTerminalSpecsHook();
  const { terms } = useBusinessRegistrationHook();

  const hookForm = useForm();

  const { hasAcceptedTerms, setHasAcceptedTerms } = terms;

  const [selectedPos, setSelectedPos] = useState<ITerminalSpecs | any>(null);

  const navigate = useNavigate();

  const posState = {
    selectedPos: selectedPos,
    addressData: addressData,
  };

  const availableTerminals = TERMINAL_SPECS?.nodes?.filter(
    (terminal: any) =>
      terminal.name === "Veedez Pay Maxi" || terminal.name === "Veedez Pay Mini"
  );

  return (
    <>
      <RequestPosFrame>
        <h3>Let’s get started with your Terminal request</h3>
        <OptionFlex>
          {availableTerminals?.map((option: any) => (
            <PosOption
              key={option?._id}
              option={option}
              selectedPos={selectedPos}
              setSelectedPos={setSelectedPos}
              name={option?.name}
              price={option?.price}
              imageUrl={option?.imageUrl}
              benefits={option?.benefits}
              features={option?.features}
            />
          ))}
        </OptionFlex>
        <Terms>
          <form>
            <VForm
              inputLists={[]}
              hookForm={hookForm}
              children={
                <VCheckBox
                  style={{
                    marginTop: "20px",
                  }}
                  checked={hasAcceptedTerms}
                  className="col__spanfull"
                  onChange={({ target: { checked } }) => {
                    setHasAcceptedTerms(!hasAcceptedTerms);
                  }}
                  label={
                    <p>
                      I agree to Veedezpro
                      <a
                        href="https://drive.google.com/file/d/1G-iKLUX4rtkR4_wQxeTsADwebAuQJXsv/view"
                        target="_blank"
                        rel="noreferrer"
                        className="lightblue__text"
                      >
                        {" "}
                        Terms & Conditions{" "}
                      </a>
                    </p>
                  }
                />
              }
            />
          </form>
        </Terms>
        <ButtonDiv>
          <VButton
            text="Continue to Payment"
            height="50px"
            width="426px"
            disabled={!selectedPos || !hasAcceptedTerms}
            onClick={() => navigate(APP_ROUTES.BUY_POS, { state: posState })}
          />
        </ButtonDiv>
      </RequestPosFrame>
    </>
  );
};

export default RequestPos;
