const BasicPlanIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 55 61.11"
      {...props}
    >
      <mask
        id="mask0_3535_121944"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="55"
        height="55"
      >
        <circle cx="27.5" cy="27.5" r="27.5" fill="#E4E9FF" />
      </mask>
      <g mask="url(#mask0_3535_121944)">
        <circle cx="27.5" cy="27.5" r="27.5" fill="#E4E9FF" />
        <mask
          id="mask1_3535_121944"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="10"
          y="17"
          width="34"
          height="45"
        >
          <rect
            x="10.9998"
            y="17.7227"
            width="33"
            height="43.3889"
            rx="14"
            fill="#4791FF"
          />
        </mask>
        <g mask="url(#mask1_3535_121944)">
          <rect
            x="10.9998"
            y="17.7227"
            width="33"
            height="43.3889"
            rx="14"
            fill="#C1C1C1"
          />
          <circle
            opacity="0.1"
            cx="27.5002"
            cy="20.1667"
            r="9.16666"
            fill="#0077FF"
          />
        </g>
        <circle cx="27.5002" cy="18.3346" r="9.16666" fill="#4791FF" />
        <rect
          x="15.8889"
          y="32.3906"
          width="23.2222"
          height="7.94444"
          rx="3.97222"
          fill="#7E7E7E"
        />
        <path
          d="M20.4722 34.8359H31.1666"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          opacity="0.5"
          d="M20.4722 37.8906H26.2777"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <rect
          x="15.8889"
          y="43.3867"
          width="23.2222"
          height="3.05556"
          rx="1.52778"
          fill="#666666"
        />
        <circle
          cx="27.4997"
          cy="18.3364"
          r="3.27778"
          stroke="white"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};

export default BasicPlanIcon;
