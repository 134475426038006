const MinusIcon = (props: any) => {
  return (
    <svg
      width="7"
      height="2"
      viewBox="0 0 7 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M6.86253 0.44V1.976H0.286531V0.44H6.86253Z" fill="inherit" />
    </svg>
  );
};

export default MinusIcon;
