import { useChangeBillingPlanPageHook } from "hooks";
import PricingPlanCard from "./pricing-plan-card";
import {
  StyledOtherPricingPlan,
  StyledPricingPlanCardContainer,
} from "./styles";

export const OtherPricingPlan = ({
  pricingPlans,
}: {
  pricingPlans: ReturnType<
    typeof useChangeBillingPlanPageHook
  >["memoizedPricingPlan"];
}) => {
  return (
    <StyledOtherPricingPlan>
      <h4>Other plans</h4>
      <StyledPricingPlanCardContainer>
        {pricingPlans.map((plan) => (
          <PricingPlanCard key={plan.id} {...plan} />
        ))}
      </StyledPricingPlanCardContainer>
    </StyledOtherPricingPlan>
  );
};
