import { useAuthUser } from "store";

const useCheckPermissions = (validPermissions: string[]): boolean => {
  const { userData } = useAuthUser();
  const { permissions } = userData ?? {};
  const permissionsObj: any = {};

  permissions?.forEach(
    (permission) =>
      (permissionsObj[permission.permission] = permission.permission)
  );

  return validPermissions.every(
    (validPermission) => permissionsObj[validPermission]
  );
};

export default useCheckPermissions;
