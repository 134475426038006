// Core
import { gql, useMutation } from "urql";

// Custom Components
import { showToast } from "components/common";

// Assets
import { handleRequestCall } from "utils/handleRequestCall";
export type IGenerateUssdCode = {
  bankCode: string;
  amount: number;
};

// GraphQL
const generateUssdCodeMutation = gql`
  mutation ($bankCode: String, $amount: Int) {
    generateUssdCode(bankCode: $bankCode, amount: $amount)
  }
`;

// Functionality
export const useGenerateUssdCode = () => {
  const [UssdCode, generateUssdCode] = useMutation(generateUssdCodeMutation);

  const handleGenerateUssdCode = async (
    data: IGenerateUssdCode,
    callBack?: () => void
  ) => {
    try {
      const req = await generateUssdCode(data);
      // call refetch tranlisting here
      await handleRequestCall({ request: req, callBack });
    } catch (e) {
      console.error(e);
      showToast({
        type: "error",
        title: "An error occurred",
        subText: "Please try again",
      });
    }
  };

  return {
    handleGenerateUssdCode,
    UssdCode,
  };
};
