import styled from "styled-components/macro";
import { stylingUtils } from "styles";
import bgImg from "assets/images/svgs/card_bg.svg";

export const Container = styled.div`
  width: 100%;
  position: relative;
  margin: 3rem 0;
`;

export const SideNav = styled.div<{
  isSideBarMinimzed?: boolean;
}>`
  transition: all 0.5s ease-in-out;
  background: ${stylingUtils.color("white")};
  padding-top: ${stylingUtils.rem(14)};
  color: #222;
  height: 100vh;
  min-height: fit-content;
  min-width: 19.5rem;
  min-width: ${({ isSideBarMinimzed }) =>
    isSideBarMinimzed ? "2rem" : "19.5rem"};
  margin-left: 0px;
  z-index: 0;
  padding-right: ${stylingUtils.rem(15)};
  padding-left: ${stylingUtils.rem(15)};
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-bottom: 3%;
  align-items: flex-start;
  overflow-y: auto;
  overflow-x: auto;
`;

export const NavbarsectionTop = styled.div<{
  isSideBarMinimzed?: boolean;
}>`
  width: 100%;
  padding-left: ${({ isSideBarMinimzed }) =>
    isSideBarMinimzed ? "0" : `${stylingUtils.rem(12)}`};
`;

export const CompanyWrapper = styled.div<{
  isSideBarMinimzed?: boolean;
}>`
  display: grid;
  min-height: ${stylingUtils.rem(55)};
  justify-content: ${({ isSideBarMinimzed }) =>
    isSideBarMinimzed ? "center" : `flex-start`};
  align-items: center;
  cursor: pointer;
`;

export const NavListWrapper = styled.div<{
  isSideBarMinimzed?: boolean;
}>`
  margin-top: ${stylingUtils.rem(20.5)};
  min-height: 30rem;
  display: flex;
  flex-direction: column;
  align-items: ${({ isSideBarMinimzed }) =>
    isSideBarMinimzed ? "center" : "flex-start"};
`;

export const Line = styled.div`
  border: 1px solid #eaeaea;
`;

export const NotActive = styled.div`
  color: #a5b0b7;
  margin-top: 5%;
  margin-bottom: 5%;
  cursor: pointer;
  height: 3rem;
  padding: 1%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

export const Dash = styled.div<{
  isSideBarMinimzed?: boolean;
}>`
  display: flex;
  width: 100%;
  justify-content: ${({ isSideBarMinimzed }) =>
    isSideBarMinimzed ? "center" : "flex-start"};
  align-items: center;
  padding: 5%;
  gap: 1rem;
`;

export const NavbarsectionBottom = styled.div`
  width: 100%;
`;

export const Navbarsection = styled.div`
  display: block;
  justify-content: center;
  padding: 1px 0;
  background-size: 200% 200%;
  transition: background-position 0.5s;
  min-height: ${stylingUtils.rem(14)};
`;

export const Card = styled.div`
  width: ${stylingUtils.rem(260)};
  height: ${stylingUtils.rem(99)};
  background-color: #001928;
  border-radius: ${stylingUtils.rem(16)};
  background-image: url(${bgImg});
  display: grid;
  place-content: center;
  gap: ${stylingUtils.rem(12)};
  margin-top: ${stylingUtils.rem(130)};
  margin-bottom: ${stylingUtils.rem(20)};
`;

export const H1 = styled.h1`
  font-family: Gilmer;
  font-style: normal;
  font-weight: 600;
  font-size: ${stylingUtils.rem(14)};
  line-height: ${stylingUtils.rem(17)};
  text-align: center;
  color: ${stylingUtils.color("ashText")};
`;

export const H2 = styled.h2`
  color: ${stylingUtils.color("white")};
  font-family: Gilmer;
  font-style: normal;
  font-weight: 700;
  font-size: ${stylingUtils.rem(14)};
  line-height: ${stylingUtils.rem(17)};
  text-align: center;
`;

export const H3 = styled.h3`
  font-family: Gilmer;
  font-style: normal;
  font-weight: 700;
  font-size: ${stylingUtils.rem(14)};
  line-height: ${stylingUtils.rem(17)};
  text-align: center;
  cursor: pointer;
  color: ${stylingUtils.color("orange")};
`;

export const H4 = styled.h4`
  font-family: "Gilmer";
  font-weight: 500;
  font-size: ${stylingUtils.rem(14)};
  line-height: ${stylingUtils.rem(19)};
  color: ${stylingUtils.color("ashText")};
  margin-top: 0;
  margin-bottom: 0;
  white-space: nowrap;
`;

export const Download = styled.div`
  width: 100%;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  gap: ${stylingUtils.rem(16)};
  margin-top: ${stylingUtils.rem(16)};
`;

export const ButtonImg = styled.img`
  cursor: pointer;
`;
