import { CreatedAtType } from "../types";
import { gql, useQuery } from "urql";
import { useAuthUser } from "store";
import { ITopSellingProducts } from "api/dashboard";

type UseGetSalesSummaryCards = {
  businessId: string;
  createdAt: CreatedAtType["createdAt"];
  profitLossType: string;
};

export const useGetSalesReport = ({
  createdAt,
  businessId,
  profitLossType,
}: UseGetSalesSummaryCards) => {
  return useQuery({
    query: combinedQuery,
    variables: {
      createdAt,
      rCreatedAt: createdAt,
      type: "REPORT",
      businessId,
      dateRange: createdAt,
      profitLossType,
    },
  });
};

const combinedQuery = gql`
  query (
    $createdAt: CreatedAtInputSale
    $rCreatedAt: RangeInput
    $type: SALESUMMARYCARDTYPE
    $businessId: String
    $profitLossType: InvoiceGroupingTypeInput
  ) {
    getSalesByProduct(createdAt: $rCreatedAt) {
      customerProductId
      totalSale
      totalItemSold
      customerProduct {
        _id
        referenceId
        productName
      }
    }
    getSaleStat(filters: { createdAt: $rCreatedAt }, type: $type) {
      totalProfit
      totalCost
      totalSale
      totalGain
      totalLoss
      totalItemSold
      profitMargin
      saleCount
      BANK
      CASH
      POS
    }
    getTopSellingProducts(
      businessId: $businessId
      type: $type
      createdAt: $createdAt
    )
    getNewAndReturningBusinessCustomers(createdAt: $rCreatedAt)
    getProfitAndLoss(type: $profitLossType) {
      identifier
      totalLoss
      totalSaleProfit
    }
  }
`;

export const useGetSalesReportHoook = ({
  createdAt,
  profitLossType,
}: { profitLossType: string } & CreatedAtType) => {
  const { userData } = useAuthUser();
  const [result, refetchAll] = useGetSalesReport({
    createdAt: createdAt,
    businessId: userData?.businessId as string,
    profitLossType,
  });

  return {
    result,
    refetchAll,
    data: (result?.data || {
      getSaleStat: {},
      getTopSellingProducts: {
        count: 0,
        soldItemData: [],
      },
      getNewAndReturningBusinessCustomers: {},
      getSalesByProduct: [],
      getProfitAndLoss: [],
    }) as ISaleReport,
    fetching: result?.fetching,
  };
};

export interface INewAndReturningCustomers {
  returningCustomers: number;
  newCustomers: number;
}

export interface ISaleSummaryCards {
  totalProfit: number;
  totalCost: number;
  totalSale: number;
  totalGain: number;
  totalLoss: number;
  saleCount: number;
  totalItemSold: number;
  profitMargin: String;
  BANK: string;
  CASH: string;
  POS: string;
}

export interface ISalesProduct {
  customerProductId: string;
  totalSale: number;
  totalItemSold: number;
  customerProduct: {
    _id: string;
    referenceId: string;
    productName: string;
  };
}
export interface IProfitAndLoss {
  identifier: string;
  totalLoss: number;
  totalSaleProfit: number;
}

export interface ISaleReport {
  getNewAndReturningBusinessCustomers: INewAndReturningCustomers;
  getTopSellingProducts: ITopSellingProducts;
  getSaleStat: ISaleSummaryCards;
  getSalesByProduct: ISalesProduct[];
  getProfitAndLoss: IProfitAndLoss[];
}
