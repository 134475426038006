import ImagePlaceholder from "./image-placeholder.svg";

const ImagePlaceholderSvg = (props: any) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0.29 0.62 71.69 58.09"
    >
      <path
        d="M58.0827 29.2551C53.8516 25.1081 47.1381 25.0987 42.8956 29.2338L37.1967 34.9703C35.7932 36.3834 35.7865 38.6813 37.1816 40.1028C37.1832 40.1044 37.1847 40.106 37.1862 40.1075L53.9434 57.1149L54.5695 58.7105H61.2315C67.1679 58.7039 71.9787 53.8315 71.9852 47.8189V44.8053C71.9848 43.8408 71.6057 42.916 70.9315 42.2349L58.0827 29.2551Z"
        fill="#F2F2F2"
      />
      <path
        d="M11.0476 58.7107H61.2314C62.504 58.7006 63.7644 58.4593 64.9528 57.9985L29.4062 21.9943C25.1742 17.8447 18.4571 17.8369 14.2156 21.9765L1.34781 34.974C0.673478 35.6551 0.294304 36.5799 0.293945 37.5444V47.8191C0.300433 53.8316 5.1112 58.7041 11.0476 58.7107Z"
        fill="#B3B9C2"
      />
      <path
        d="M61.2314 0.62207H11.0476C5.11113 0.628714 0.300505 5.50101 0.293945 11.5136V37.5441C0.294375 36.5795 0.67355 35.6548 1.34784 34.9737L14.2156 21.9761C18.4572 17.8364 25.1741 17.8444 29.4062 21.9939L39.7149 32.4354L42.8956 29.2336C47.138 25.0985 53.8516 25.1079 58.0827 29.2549L70.9314 42.2347C71.6057 42.9159 71.9849 43.8405 71.9851 44.8051V11.5136C71.9786 5.50101 67.1679 0.628714 61.2314 0.62207Z"
        fill="#DADADA"
      />
    </svg>
  );
};

export { ImagePlaceholder, ImagePlaceholderSvg };
