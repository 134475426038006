const CloseIcon = (props: any) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.7829 7.08679L12.954 2.85485C13.1891 2.61335 13.3204 2.28731 13.3193 1.94791C13.3183 1.60851 13.1849 1.28332 12.9484 1.04334C12.7118 0.803351 12.3913 0.668059 12.0568 0.666998C11.7223 0.665938 11.4009 0.799195 11.1629 1.03768L6.99175 5.26954L2.82062 1.03768C2.58238 0.800299 2.26138 0.667983 1.92746 0.669517C1.59355 0.671051 1.27374 0.806312 1.03763 1.04587C0.801514 1.28543 0.668197 1.60989 0.666685 1.94867C0.665173 2.28746 0.795588 2.61313 1.02955 2.85485L5.20061 7.08679L1.02955 11.3187C0.795588 11.5604 0.665173 11.8861 0.666685 12.2249C0.668197 12.5637 0.801514 12.8882 1.03763 13.1277C1.27374 13.3673 1.59355 13.5025 1.92746 13.5041C2.26138 13.5056 2.58238 13.3733 2.82062 13.1359L6.99175 8.90404L11.1629 13.136C11.4009 13.3744 11.7223 13.5077 12.0568 13.5066C12.3913 13.5055 12.7118 13.3702 12.9484 13.1302C13.1849 12.8902 13.3183 12.565 13.3193 12.2257C13.3204 11.8863 13.189 11.5602 12.954 11.3187L8.7829 7.08679Z"
        fill="#69778C"
      />
    </svg>
  );
};
export default CloseIcon;
