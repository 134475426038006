import { IBusinessExpenseNode } from "api";
import { useState, useEffect } from "react";
import { useAuthUser } from "store";

export const useExpenseDetailsHook = ({
  expense,
}: {
  expense: IBusinessExpenseNode;
}) => {
  const [newExpense, setNewExpense] = useState(expense);

  const { userData } = useAuthUser();
  let createdBy = "";
  if (!!userData) {
    createdBy = `${userData?.firstName} ${userData?.lastName}`;
  }

  useEffect(() => {
    setNewExpense(expense);
  }, [expense]);

  return {
    newExpense,
    createdBy,
  };
};
