import { useLocalSearchParams } from "hooks";
import { useState, useMemo, useEffect } from "react";
import { extractFetchedData } from "utils";
import {
  IAddBusinessExpense,
  IBusinessExpense,
  IBusinessExpenseNode,
  useGetBusinessExpense,
} from "api";
import useModal from "hooks/useModal";
import {
  EXPENSE_CARDS_DATA,
  EXPENSE_TABLE_COLUMN,
} from "components/expense-sales";
import { useAuthUser } from "store";
import { useGetExpenseStat } from "api/expense-sales/get-expenses-breakdown";
import { showToast } from "components/common";
import { useSelectedPeriodHook } from "./useSelectedPeriodHook";
import { toggleViewExpenseModal, setCreatedExpense } from "store";
import { useVAppSelector, useVAppDispatch } from "hooks/useStore";

export const useExpensePageHook = () => {
  // Handle search params for pagination
  const { searchParams } = useLocalSearchParams();
  const selectedPeriod = searchParams.get("period");
  const { createdAt, handleFilter: handleExpensesFilter } =
    useSelectedPeriodHook();

  const { userData } = useAuthUser();

  // view modal state after submitting
  const { open: shouldOpenViewExpenseModal, createdExpense } = useVAppSelector(
    (state) => state.viewExpense
  );
  const dispatch = useVAppDispatch();

  // View modal state
  const { open: viewModalState, toggleModal: toggleViewModalState } =
    useModal();

  // Edit modal state
  const { open: editModalState, toggleModal: toggleEditModalState } =
    useModal();

  // Delete modal state
  const { open: deleteModalState, toggleModal: toggleDeleteModalState } =
    useModal();

  // controls page, use params page if present
  const [pageNumber, setPageNumber] = useState(
    parseInt(searchParams.get("page") || "1")
  );

  // Handle selected expense by user
  const [selectedExpense, setSelectedExpense] = useState<
    IBusinessExpenseNode | IAddBusinessExpense | null
  >();

  // Fetch user businesses expenses
  const [result, refechBusinessExpenses] = useGetBusinessExpense({
    businessId: userData?.businessId as string,
    page: pageNumber,
    ...(selectedPeriod && { createdAt }),
  });

  // Extract necessary data from the results
  const extract = extractFetchedData<IBusinessExpense>({
    result,
    key: "getBusinessExpenses",
  });

  // Handle pagination change
  const handleChangePage = (pageNumber: number) => {
    setPageNumber(pageNumber);
  };

  // Handle when user clicks view
  const handleOpenOnSubmit = (expense?: IBusinessExpenseNode) => {
    dispatch(setCreatedExpense(expense));
    dispatch(toggleViewExpenseModal());
  };

  const handleCloseOnSubmit = () => {
    dispatch(toggleViewExpenseModal());
    dispatch(
      setCreatedExpense({
        createdBy: "",
        item: "",
        title: "",
        amount: "",
      })
    );
  };

  // Handle when user clicks view
  const handleOpenViewModal = (expense: IBusinessExpenseNode) => {
    setSelectedExpense(expense);
    toggleViewModalState();
  };

  // Remove the expense from selected state and close modal
  const handleCloseViewModal = () => {
    toggleViewModalState();
    setSelectedExpense(null);
  };

  // Handle when user clicks edit
  const handleOpenEditModal = (expense: IBusinessExpenseNode) => {
    setSelectedExpense(expense);
    toggleEditModalState();
  };

  const handleCloseEditModal = () => {
    toggleEditModalState();
    setSelectedExpense(null);
  };

  // Handle when user clicks delete
  const handleOpenDeleteModal = (expense: IBusinessExpenseNode) => {
    setSelectedExpense(expense);
    toggleDeleteModalState();
  };

  const handleCloseDeleteModal = () => {
    toggleDeleteModalState();
    setSelectedExpense(null);
  };

  // Memoize the expense column
  const expenseColumn = EXPENSE_TABLE_COLUMN(
    handleOpenViewModal,
    handleOpenEditModal,
    handleOpenDeleteModal
  );

  // expenses summary
  const businessId = userData?.businessId as string;
  const [fetchSummaryData, refetchSummaryData] = useGetExpenseStat({
    businessId,
    ...(selectedPeriod && { createdAt }),
  });

  const { data } = extractFetchedData<any>({
    result: fetchSummaryData,
    key: "getExpenseStat",
  });

  useEffect(() => {
    if (fetchSummaryData.error) {
      showToast({
        subText: "Could not get summary data. Please try again",
        type: "error",
      });
    }
  }, [fetchSummaryData.error]);

  const expenseSummaryData = data?.getExpenseStat;

  const newData = {
    totalExpenseValue: expenseSummaryData?.totalExpense || 0,
    expenseCount: expenseSummaryData?.total || 0,
  };

  const cardRef = JSON.stringify(expenseSummaryData);

  const cardData = useMemo(() => {
    return EXPENSE_CARDS_DATA(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardRef]);

  const EXPENSES_FILTERS = [
    { value: "all", label: "All", key: "all" },
    { value: "daily", label: "Daily", key: "daily" },
    { value: "weekly", label: "Weekly", key: "weekly" },
    { value: "monthly", label: "Monthly", key: "monthly" },
    { value: "yearly", label: "Yearly", key: "yearly" },
  ];

  return {
    ...extract,
    handleChangePage,
    pageNumber,
    expenseColumn,
    selectedExpense,
    handleCloseViewModal,
    refechBusinessExpenses,
    viewModalState,
    cardData,
    handleExpensesFilter,
    EXPENSES_FILTERS,
    selectedPeriod,
    createdAt,
    handleCloseEditModal,
    editModalState,
    deleteModalState,
    handleCloseDeleteModal,
    refetchSummaryData,
    handleOpenViewModal,
    handleOpenOnSubmit,
    handleCloseOnSubmit,
    createdExpense,
    shouldOpenViewExpenseModal,
  };
};
