import { gql, useQuery } from "urql";
import { useAuthUser } from "store";

export interface IDebtorsTopCards {
  getBusinessCustomersTotalDebt: number;
  getTotalBusinessCustomersCount: number;
}

const getDebtorsTopCardsQuery = gql`
  query ($businessId: String) {
    getBusinessCustomersTotalDebt(businessId: $businessId)
    getTotalBusinessCustomersCount(
      businessId: $businessId
      customerStatus: DEBTOR
    )
  }
`;

export const useGetDebtorsTopCards = () => {
  const { userData } = useAuthUser();
  const businessId = userData?.businessId;

  const [result, refetchCard] = useQuery<IDebtorsTopCards>({
    query: getDebtorsTopCardsQuery,
    variables: {
      businessId,
    },
  });

  return {
    result,
    refetchCard,
    data: result?.data || {
      getTotalBusinessCustomersCount: 0,
      getBusinessCustomersTotalDebt: 0,
    },
  };
};
