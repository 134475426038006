import { createSlice } from "@reduxjs/toolkit";

type TSearch = {
  url: string;
  page: string;
  term: string;
};

const initialState: TSearch = {
  url: "",
  page: "",
  term: "",
};

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setSearchURL: (state, action) => {
      state.url = action.payload.search;
    },
    setSearchTerm: (state, action) => {
      state.url = action.payload.url;
      state.page = action.payload.page;
      state.term = action.payload.term;
    },
  },
});

const searchReducer = searchSlice.reducer;
const { setSearchURL, setSearchTerm } = searchSlice.actions;

export { searchReducer, setSearchURL, setSearchTerm };
