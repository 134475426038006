import * as firebase from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signInWithCustomToken, signOut } from "firebase/auth";
import { getEnvKey } from "./libs";

const app = firebase.initializeApp({
  apiKey: getEnvKey("REACT_APP_FIREBASE_API_KEY"),
  authDomain: getEnvKey("REACT_APP_FIREBASE_AUTH_DOMAIN"),
  projectId: getEnvKey("REACT_APP_FIREBASE_PROJECT_ID"),
  storageBucket: getEnvKey("REACT_APP_FIREBASE_STORAGE_BUCKET"),
  messagingSenderId: getEnvKey("REACT_APP_FIREBASE_MESSAGING_SENDER_ID"),
  appId: getEnvKey("REACT_APP_FIREBASE_APP_ID"),
  measurementId: getEnvKey("REACT_APP_FIREBASE_MEASUREMENT_ID"),
});

export const auth = getAuth(app);

// Initialize Analytics and get a reference to the service
export const analytics = getAnalytics(app);

export const loginWithCustomToken = (customToken: string) => {
  try {
    if (customToken) {
      return new Promise((resolve, reject) => {
        signInWithCustomToken(auth, customToken)
          .then(({ user }) => user.getIdToken())
          .then((token) => resolve(token.toString()))
          .catch(reject);
      });
    }
    return null;
  } catch (err) {
    console.error(err);
  }
  return null;
};

export const firebaseLogout = async () => {
  await signOut(auth);
};

export const getCurrentUser = () => {
  return auth.currentUser;
};

export const getRefreshToken = async () => {
  try {
    const token = await auth.currentUser?.getIdToken(true);
    if (token) {
      return token;
    }
    return null;
  } catch (e) {
    return null;
  }
};

export const getUserStatus = function () {
  return new Promise(function (resolve, reject) {
    auth.onAuthStateChanged(async function (user) {
      if (user) {
        const token = await getRefreshToken();
        resolve(token);
      } else {
        reject(null);
      }
    });
  });
};
