const COOKIE_STORAGE_NAME = `veedex-pwa`;

export class AppCookies {
  setCookie = (cname: string, cvalue: string, exdays: number) => {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie =
      COOKIE_STORAGE_NAME +
      "-" +
      cname +
      "=" +
      cvalue +
      ";" +
      expires +
      ";path=/";
  };

  getCookie = (cname: string) => {
    let name = COOKIE_STORAGE_NAME + "-" + cname + "=";
    let ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };
}
