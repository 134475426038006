import styled from "styled-components";
import { stylingUtils } from "styles";

const {
  color,
  rem,
  mediaQuery: { down },
} = stylingUtils;

export const AddProduct = styled.div`
  display: flex;
  gap: ${rem(5)};
  cursor: pointer;
  margin-bottom: ${rem(20)};

  .text {
    font-weight: 500;
    font-size: ${rem(15)};
    line-height: ${rem(22)};
    display: flex;
    align-items: center;
    text-decoration-line: underline;
    color: #0077ff;
  }
`;

export const FormTitle = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: ${rem(10)};
  padding-right: ${rem(10)};
  padding-left: 0;

  .title {
    font-family: "Gilmer";
    font-weight: 500;
    font-size: ${rem(15)};
    line-height: ${rem(22)};
    display: flex;
    align-items: center;
    color: #434343;
  }
`;
export const Payment = styled.div`
  border: 1px solid rgba(220, 227, 231, 0.5);
  border-radius: 8px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
  margin-bottom: 12px;

  .pay {
    display: flex;
    gap: 12px;
  }

  .details {
    font-family: "Gilmer";
    h6 {
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      color: #001833;
    }
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #6c757d;
    }
  }
`;

export const InvoiceFrame = styled.div`
  justify-content: space-between;
  background-color: ${color("white")};
  padding: 2rem;
  border-radius: ${rem(20)};
  max-height: 100vh;
  overflow-y: scroll;
  ${down("laptop")} {
    padding: 1.3rem 1rem;
  }

  h4 {
    font-family: "Gilmer";
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #434343;
    margin-bottom: 1rem;
  }

  .expense__form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 ${rem(20)};
    transition: 0.5s all ease;
    ${down("tablet")} {
      grid-template-columns: 1fr;
    }
  }

  .accordion_header {
    background-color: transparent;
    padding: ${rem(10)} ${rem(0)};
    border-top: none;
    border-bottom: none;
  }
  .accordion_title {
    font-family: "Gilmer";
    font-weight: 500;
    font-size: ${rem(15)};
    line-height: ${rem(22)};
    color: #434343;
  }
  .accordion_body {
    background-color: transparent;
    padding: 0;
  }
`;

export const Nav = styled.div`
  display: flex;
  align-items: center;
`;

export const Tab = styled.div`
  margin: 2rem 0;
`;

export const ProductList = styled.div`
  border: 1px solid rgba(220, 227, 231, 0.5);
  border-radius: 8px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
  margin-bottom: 12px;

  p {
    font-family: Gilmer;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #001833;
    text-transform: capitalize;
  }
  h5 {
    font-family: Gilmer;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #001833;
  }

  div {
    display: flex;
    gap: 2rem;
  }

  h6 {
    font-family: Gilmer;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    cursor: pointer;
  }

  h6:nth-child(1) {
    color: #0077ff;
  }
  h6:nth-child(2) {
    color: #ea4336;
  }
`;
export const SubmitButton = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3rem;
`;
export const Banks = styled("div")`
  max-height: ${rem(300)};
  overflow-y: scroll;
  margin-bottom: ${rem(20)};
`;
