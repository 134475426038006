import { useGetCustomerTerminals } from "api";

export const usePosCardsHook = () => {
  const [result, refetchPosCards] = useGetCustomerTerminals({
    status: "DELIVERED",
  });

  const cardDetails = result?.data?.getCustomerTerminals?.nodes;

  return {
    refetchPosCards,
    cardDetails,
  };
};
