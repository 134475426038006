import { useLocation, useNavigate } from "react-router-dom";
import { VPageTabProps } from "components/common/types";
import {
  StyledVPageTabButton,
  StyledVPageTabChildren,
  StyledVPageTabContainer,
} from "./styles";

export const VPageTab = ({
  pageTabs,
  children,
  isOval = false,
}: VPageTabProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <StyledVPageTabContainer isOval={isOval}>
      {pageTabs.map(({ key, label, link, secondaryLink, disabled }) => {
        const isActive = pathname === link || pathname === secondaryLink;
        return (
          <StyledVPageTabButton
            hasChildren={!!children}
            key={key}
            disabled={disabled}
            onClick={() => {
              if (link) {
                navigate(link);
              }
            }}
            isActive={isActive}
          >
            {label}
          </StyledVPageTabButton>
        );
      })}
      {children && <StyledVPageTabChildren>{children}</StyledVPageTabChildren>}
    </StyledVPageTabContainer>
  );
};

export default VPageTab;
