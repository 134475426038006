import { useState } from "react";
import { useModal } from "hooks";
import { useForm } from "react-hook-form";
import { useAuthUser } from "store";
import { useRequestTerminal } from "api";

export const useRequestPosHook = (
  terminalSpecId: string,
  quantity: number,
  passcode: string
) => {
  const { userData } = useAuthUser();
  const hookForm = useForm();
  const { open: openPosDetailsModal, toggleModal: togglePosDetailsModal } =
    useModal();
  const [orderId, setOrderId] = useState("");

  const { handleSubmit } = hookForm;

  const { handleRequestTerminal, isLoading } = useRequestTerminal();

  const onTerminalRequest = async (data: any) => {
    const newData = {
      ...data,
      userId: userData?.userId as string,
      terminalSpecId,
      quantity,
      passcode,
    };

    const response = await handleRequestTerminal(newData, () => {
      togglePosDetailsModal();
    });

    setOrderId(response);

    return response;
  };

  return {
    openPosDetailsModal,
    togglePosDetailsModal,
    hookForm,
    handleSubmit,
    isLoading,
    onTerminalRequest,
    orderId,
  };
};
