import { API_ROUTES } from "api/api.routes";
import { useState } from "react";
import { throwError } from "utils";
import { useAlerzoPayApiRequest } from "../useApiRequest";

export type CallbackProps = (response: Record<string, any>) => void;

export const useAlerzoPayBilling = () => {
  const makeRequest = useAlerzoPayApiRequest();

  const [isLoading, toggleLoading] = useState(false);

  const initiateCharge = async ({
    data,
    callback,
  }: {
    data: Record<string, any>;
    callback?: CallbackProps;
  }) => {
    toggleLoading(true);
    try {
      const request = await makeRequest.post(API_ROUTES.INITIATE_CHARGE, {
        ...data,
      });
      callback?.(request?.data);
    } catch (e) {
      throwError("Error initiating charge");
      callback?.({ error: true });
    } finally {
      toggleLoading(false);
    }
  };

  // Verify otp
  const verifyOtp = async ({
    data,
    callback,
  }: {
    data: Record<string, any>;
    callback?: CallbackProps;
  }) => {
    toggleLoading(true);
    try {
      const request = await makeRequest.post(API_ROUTES.VALIDATE_OTP, {
        ...data,
      });
      callback?.(request?.data);
    } catch (e) {
      throwError("Error validating otp");
      callback?.({ error: true });
    } finally {
      toggleLoading(false);
    }
  };

  // VErifyt Charge
  const verifyCharge = async ({
    data,
    callback,
  }: {
    data: Record<string, any>;
    callback?: CallbackProps;
  }) => {
    toggleLoading(true);
    try {
      const request = await makeRequest.post(API_ROUTES.VERIFY_CHARGE, {
        ...data,
      });
      callback?.(request?.data);
    } catch (e) {
      throwError("Error verifying charge");
      callback?.({ error: true });
    } finally {
      toggleLoading(false);
    }
  };

  return {
    initiateCharge,
    isLoading,
    verifyOtp,
    verifyCharge,
  };
};
