import React from "react";
import {
  StyledVCheckBox,
  StyledVCheckBoxInput,
  StyledVCheckBoxWrapper,
} from "./styles";

type VCheckBoxProps = {
  label?: string | JSX.Element;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const VCheckBox = ({
  type = "checkbox",
  label,
  name,
  style,
  className,
  ...rest
}: VCheckBoxProps) => {
  const otherProps = { style, className };
  return (
    <StyledVCheckBox {...otherProps}>
      <StyledVCheckBoxWrapper>
        <StyledVCheckBoxInput type={type} name={name} id={name} {...rest} />
      </StyledVCheckBoxWrapper>
      {label && <label htmlFor={name}>{label}</label>}
    </StyledVCheckBox>
  );
};
