import { useRef } from "react";
import PosCard from "../card/pos-card";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { settings, renderArrows, IPosCard } from "../constants";
import { Cards } from "./styles";
import EmptyPosCard from "../card/empty-pos-cards";
import { usePosSettingsHook } from "hooks";

const PosDetailsCards = () => {
  const sliderRef = useRef(null);
  const { cardDetails, active, setActive } = usePosSettingsHook();

  return (
    <>
      {cardDetails?.nodes?.length > 0 ? (
        <Cards>
          {renderArrows(sliderRef)}
          <Slider {...settings} ref={sliderRef}>
            {cardDetails?.nodes?.map((detail: IPosCard, index: number) => (
              <PosCard
                key={detail._id}
                _id={detail._id}
                terminalName={detail.terminalName}
                index={index + 1}
                tid={detail.tid}
                amount={detail?.spec?.price}
                active={active?._id === detail._id}
                action={() => setActive(detail)}
              />
            ))}
          </Slider>
        </Cards>
      ) : (
        <EmptyPosCard />
      )}
    </>
  );
};

export default PosDetailsCards;
