const GreyPin = (props: any) => {
  return (
    <svg
      width="40"
      height="12"
      viewBox="0 0 40 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="3"
        y="7"
        width="2"
        height="37"
        transform="rotate(-90 3 7)"
        fill="#C1CACF"
      />
      <circle cx="6" cy="6" r="5.5" fill="#C1CACF" stroke="white" />
    </svg>
  );
};

export default GreyPin;
