import { useGetInventoryReportHook } from "api";
import { INVENTORY_CARD_DATA } from "components/reports";
import { useMemo } from "react";
import { FinalDateMapperProps, getMappedDate } from "utils/dateMapper";
import useReportFilterHook from "./report/useReportFilterHook";

export const useReportInventoryPageHook = () => {
  const { dateFilter, date } = useReportFilterHook();
  const { data } = useGetInventoryReportHook({
    inventoryGraphType: date,
    createdAt: dateFilter.createdAt,
  });

  const { getTopSellingProducts, getInventoryStatus, getInventoryByTime } =
    data;

  const cardDataRef =
    JSON.stringify(getTopSellingProducts) && JSON.stringify(getInventoryStatus);

  const invoiceCardRef = JSON.stringify(getInventoryByTime);

  const cardData = useMemo(() => {
    return INVENTORY_CARD_DATA({
      data: { ...getInventoryStatus, ...getTopSellingProducts },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardDataRef]);

  const aggregateInventoryGraph = useMemo(() => {
    const currentDate = new Date();
    const currentDay = currentDate?.getDate();
    const currentHour = currentDate?.getHours();
    const mappedDate = getMappedDate(date, {
      hourEnd: currentHour + 1,
      hourStart: currentHour - 7 + 2,
      dayEnd: currentDay,
      dayStart: currentDay - 7 + 1,
    });

    if (!getInventoryByTime.length) {
      return Object.values(mappedDate) as FinalDateMapperProps[];
    }
    const newData = { ...mappedDate };

    getInventoryByTime.forEach((data) => {
      const { identifier } = data;
      const value = (newData as Record<string, any>)[identifier];

      if (value) {
        (newData as Record<string, any>)[identifier] = {
          ...value,
          total: data?.totalInventory || 0,
        };
      }
    });

    return Object.values(newData) as FinalDateMapperProps[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceCardRef]);

  return {
    cardData,
    inventoryGraph: aggregateInventoryGraph,
    data,
    inventoryStatus: getInventoryStatus,
    revenueCategories: getInventoryStatus?.productCategoryBreakdown || [],
  };
};
