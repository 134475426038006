const DownArrow = (props: any) => {
  return (
    <svg
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.53332 5.3332L9.26666 1.5332C9.53332 1.26654 9.53332 0.866536 9.26666 0.599869C8.99999 0.333202 8.59999 0.333202 8.33332 0.599869L4.99999 3.86654L1.66666 0.599869C1.39999 0.333202 0.999991 0.333202 0.733325 0.599869C0.599991 0.733202 0.533325 0.866537 0.533325 1.06654C0.533325 1.26654 0.599991 1.39987 0.733325 1.5332L4.46666 5.3332C4.79999 5.59987 5.19999 5.59987 5.53332 5.3332C5.46666 5.3332 5.46666 5.3332 5.53332 5.3332Z"
        fill="#798892"
      />
    </svg>
  );
};

export default DownArrow;
