import styled from "styled-components";
import { stylingUtils } from "styles";

const {
  rem,
  color,
  mediaQuery: { down },
} = stylingUtils;

export const VStyledSummaryCard = styled.div`
  min-height: ${rem(140)};
  min-width: ${rem(240)};
  ${down("laptop")} {
    min-width: fit-content;
    border-radius: ${rem(20)};
    min-height: ${rem(125)};
  }

  p {
    color: ${color("blue01")};
  }

  h4 {
    font-weight: 400;
    font-size: ${rem(14)};
    line-height: ${rem(17)};
    color: ${(props) => props.theme.colors.ash03};
  }

  h3 {
    color: ${color("ashText")};
    padding-left: 1rem;
  }

  svg {
    width: ${rem(48)};
    height: ${rem(48)};
  }

  background-color: ${color("white01")};
  width: 100%;
  height: 100%;
  border-radius: 0.8rem;
  box-shadow: ${rem(2)};
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: ${rem(20)};

  .space {
    height: 2rem;
  }
`;

export const CardSection = styled.div<{
  jspace?: string;
}>`
  .metric svg {
    width: ${rem(20)};
    height: ${rem(20)};
  }

  display: flex;
  align-items: center;
  justify-content: ${({ jspace }) => jspace || "start"};
  width: 100%;

  gap: ${rem(10)};
`;
