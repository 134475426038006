import { useNavigate } from "react-router-dom";
import { useVeedezApiRequest } from "../../hooks/useApiRequest";

// Custom Components
import { showToast } from "components/common";

// Utils
import { VALUE_ADDED_SERVICES_ROUTES } from "api/api.routes";
import { APP_ROUTES } from "routes";
// import transaction listing hooks here when api available

export type IBuyData = {
  network: string;
  dataPlan: string;
  phoneNumber: string;
  remark: string;
  saveBeneficiary: boolean;
};
// Functionality
export const useBuyData = () => {
  const makeRequest = useVeedezApiRequest();
  const navigate = useNavigate();

  const buyData = async (data: IBuyData, pin: string) => {
    try {
      await makeRequest.post(
        VALUE_ADDED_SERVICES_ROUTES.BUY_DATA,
        {
          bundleId: data.dataPlan,
          phoneNumber: data.phoneNumber,
        },
        {
          headers: {
            "x-transaction-pin": pin,
          },
        }
      );

      showToast({
        title: "Successful!",
        subText: "Your transaction was successful",
      });
      navigate(`${APP_ROUTES.ACCOUNT}`);
    } catch (e: any) {
      showToast({
        type: "error",
        title: "An error occurred",
        subText: e?.response?.data?.message || "Please try again",
      });
    }
  };

  return {
    buyData,
  };
};
