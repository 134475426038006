import { InputList } from "components/common/types";
import { CUSTOM_VALIDATIONS } from "utils";

export const RESET_PASSCODE_OTP: InputList = [
  {
    name: "otp",
    inputType: "otp",
    label: "Enter the reset code",
    required: true,
    numInputs: 6,
    shouldAutoFocus: false,
    validation: {
      required: "Enter reset code",
      ...CUSTOM_VALIDATIONS.minLengthCustom(6, "Passcode must be 6 numbers"),
    },
    className: "col__spanfull",
  },
];
