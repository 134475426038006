import { REGEX_UTILS } from "./regex";

export const CUSTOM_VALIDATIONS = {
  email: {
    pattern: {
      value: REGEX_UTILS.MAIL_REGEX,
      message: "Invalid email address",
    },
  },
  phoneNumber: {
    minLength: {
      value: 10,
      message: "Phone number must be 10 numbers without the first zero(0)",
    },
    maxLength: {
      value: 10,
      message: "Phone number must be 10 numbers without the first zero(0)",
    },
    pattern: {
      value: REGEX_UTILS.PHONE_NUMBER,
      message: "Phone number must not start with zero(0)",
    },
  },
  accountNumber: {
    minLength: {
      value: 10,
      message: "Account number must be 10 numbers",
    },
    maxLength: {
      value: 10,
      message: "Account number must be 10 numbers",
    },
  },
  bvn: {
    minLength: {
      value: 11,
      message: "BVN must be 11 numbers",
    },
    maxLength: {
      value: 11,
      message: "BVN must be 11 numbers",
    },
  },
  nin: {
    minLength: {
      value: 16,
      message: "VNIN must be 16 characters",
    },
    maxLength: {
      value: 16,
      message: "VNIN must be 16 characters",
    },
  },
  accountName: {
    pattern: {
      value: REGEX_UTILS.LETTERS_ONLY,
      message: "Enter letters only",
    },
  },
  minLength: (value: number, name = "Business name") => {
    return {
      minLength: {
        value: value,
        message: `${name} must be greater than ${value} characters`,
      },
    };
  },
  minLengthCustom: (value: number, message: string) => {
    return {
      minLength: {
        value: value,
        message,
      },
    };
  },
  maxFileSize: (file: any, size = 1) => {
    const isLessThanSize = file?.size <= size * 1000000;
    return isLessThanSize || `Maximum file size ${size}MB`;
  },
  acceptedFormats: ({
    file,
    fileTypes,
    errorMessage,
  }: {
    file: any;
    fileTypes: string[];
    errorMessage?: string;
  }) => {
    return (
      fileTypes.some(
        (type) => file?.type?.includes(type) || type.includes(file?.type)
      ) ||
      (errorMessage ?? "Invalid file format")
    );
  },
};
