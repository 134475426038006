import { TRANSACTION_COLUMNS } from "components/account";
import { useMemo, useState, useCallback, useEffect } from "react";
import { useLocalSearchParams, useModal } from "hooks";
import { CustomerTransaction, useGetCustomerTransactions } from "api";
import { AppStorage, extractFetchedData } from "utils";
const { getFromStore, addToStore } = new AppStorage();

export const useAccountTransactionsHook = () => {
  const { searchParams, addSearchParams } = useLocalSearchParams();

  // states
  const [hasNextPage, setHasNextPage] = useState(false);
  const [cursor, setCursor] = useState("");
  const { open: openTransactionModal, toggleModal: toggleTransactionModal } =
    useModal();
  const [pageNumber, setPageNumber] = useState(
    parseInt(searchParams.get("page") || "1")
  );
  const [cursorList, setCursorList] = useState<
    { id: string; pageNumber: string }[]
  >([]);

  const selectedTransactionType = searchParams.get("type");
  const selectedTransactionStatus = searchParams.get("status");
  const [transactionDetails, setTransactionDetails] = useState<null | any>(
    null
  );

  // open transaction details modal
  const handleTransactionModal = (details: any) => {
    setTransactionDetails(details);
    toggleTransactionModal();
  };

  // close transaction details modal
  const closeTransactionModal = () => {
    setTransactionDetails(null);
    toggleTransactionModal();
  };

  // Utils
  const transactionColumns = useMemo(() => {
    return TRANSACTION_COLUMNS(handleTransactionModal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [result] = useGetCustomerTransactions({
    cursor: cursor,
    ...(selectedTransactionType &&
      selectedTransactionType?.toLowerCase() !== "all" && {
        type: selectedTransactionType,
      }),
    ...(selectedTransactionStatus && {
      status: selectedTransactionStatus,
    }),
  });

  const extract = extractFetchedData<CustomerTransaction>({
    result,
    key: "getCustomerTransaction",
    defaultReturn: [],
  });
  const { extractedData } = extract;

  // functionality
  const handleChangePage = (pageNumber: number) => {
    setPageNumber(pageNumber);
  };

  const handleFilter = useCallback((type: string) => {
    if (type === "credit" || type === "debit" || type === "all") {
      addSearchParams("type", type);
      searchParams.delete("status");
    } else {
      searchParams.delete("type");

      addSearchParams("status", type);
    }
    addSearchParams("page", "1");
    setPageNumber(1);
    setCursorList([{ id: "", pageNumber: "1" }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // set local storage for page reload consistencies
  useEffect(() => {
    const data = getFromStore("pagination");
    if (data !== null) setCursorList(data);
  }, []);

  useEffect(() => {
    if (cursorList) {
      addToStore("pagination", cursorList);
    }
  }, [cursorList]);

  // queried 11 displayed 10 to know having a next page beforehand
  useEffect(() => {
    if (extractedData.length === 11) {
      setHasNextPage(true);
      const key = cursorList.find(
        (cursor) => +cursor.pageNumber === pageNumber
      );

      // pageNumber and 10th item's id  are tied in as an object
      if (!key && pageNumber !== 1) {
        setCursorList((prev) => [
          ...prev,
          { pageNumber: JSON.stringify(pageNumber), id: extractedData[9].id },
        ]);
      }
    } else {
      setHasNextPage(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extractedData, pageNumber]);

  useEffect(() => {
    if (pageNumber && pageNumber > 1) {
      // this id is then used to set the cursor and when it changes api fires
      const id = cursorList.find(
        (cursor) => +cursor.pageNumber === pageNumber
      )?.id;
      if (!!id) setCursor(id);
    } else {
      setCursor("");
    }
  }, [pageNumber, cursorList]);

  return {
    ...extract,
    transactionColumns,
    selectedTransactionType,
    openTransactionModal,
    hasNextPage,
    handleChangePage,
    handleFilter,
    handleTransactionModal,
    transactionDetails,
    closeTransactionModal,
  };
};
