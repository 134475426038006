import { useUpdateUserPayment } from "api";
import { showToast, VLoader } from "components/common";
import { useAlerzoPayBilling } from "hooks";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "routes";
import { AppStorage, getUrlQueryEntries, STORGAGE_KEYS } from "utils";

const { getFromStore, addToStore } = new AppStorage();

const VerifyPayment = () => {
  const { response } = getUrlQueryEntries();
  const navigate = useNavigate();
  const [validatedPayment, setValidatedPayment] = useState(false);

  const parsedJSON = JSON.parse(decodeURIComponent(response));

  const { handleUpdateUserPayment } = useUpdateUserPayment();

  const { verifyCharge } = useAlerzoPayBilling();

  useEffect(() => {
    if (parsedJSON?.txRef && !validatedPayment) {
      (async () => {
        await verifyCharge({
          data: { reference: parsedJSON?.txRef },
          callback: async (response) => {
            const fromRegistrationPage = !!getFromStore(
              STORGAGE_KEYS.REDIRECT_BILLING_REGISTRATION
            );

            if (response?.message === "Charge completed") {
              await handleUpdateUserPayment(response?.data?.tx_ref);
              if (fromRegistrationPage) {
                navigate(
                  `${APP_ROUTES.BUSINESS_REGISTRATION}?step=billing&payment_successful=true`
                );
              } else {
                navigate(
                  `${APP_ROUTES.CHECKOUT_BILLING_PLAN}?payment_successful=true`
                );
              }
              addToStore(STORGAGE_KEYS.BILLING_REDIRECT_SUCESSFUL, true);
            } else {
              showToast({
                title: "Billing unsuccessful",
                type: "error",
                actionText: "",
              });
            }
            setValidatedPayment(true);
          },
        });
      })();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <VLoader />
    </div>
  );
};

export default VerifyPayment;
