import styled from "styled-components/macro";
import { StyledVCardContainer, stylingUtils } from "styles";

const { rem } = stylingUtils;

export const StyledOtherPricingPlan = styled(StyledVCardContainer)`
  margin-top: ${rem(20)};
  min-height: ${rem(600)};

  h4 {
    text-align: center;
    margin-bottom: ${rem(20)};
  }
`;

export const StyledPricingPlanCardContainer = styled("section")`
  display: flex;
  flex-wrap: wrap;
  gap: ${rem(30)};
  padding: 0 ${rem(30)};
  margin-bottom: ${rem(30)};
  margin-top: ${rem(40)};
  justify-content: center;
`;
