import { gql, useQuery } from "urql";

export interface IPaymentDetails {
  _id: string;
  bankName: string;
  bankAccountName: string;
  bankAccountNumber: number;
}

const getBusinessPaymentsQuery = gql`
  query ($businessId: String) {
    getBusinessPayments(businessId: $businessId) {
      _id
      bankName
      bankAccountName
      bankAccountNumber
    }
  }
`;

export const useGetBusinessPayments = ({
  businessId,
}: {
  businessId: string;
}) => {
  return useQuery({
    query: getBusinessPaymentsQuery,
    variables: { businessId },
  });
};
