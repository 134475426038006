import { showToast } from "./../../components/common/VToast/index";
import { gql, useMutation } from "urql";
import { handleRequestCall } from "utils/handleRequestCall";

interface Invoice {
  invoiceId?: string;
  items?: any;
  services?: any;
  discount?: number;
  businessPaymentId?: string;
  businessCustomerId?: string;
}

const updateBusinessInvoiceMutation = gql`
  mutation (
    $invoiceId: String
    $items: [UpdateInvoiceItemInput]
    $services: [UpdateInvoiceServiceInput]
    $discount: Int
    $businessPaymentId: String
    $businessCustomerId: String
  ) {
    updateBusinessInvoice(
      invoiceId: $invoiceId
      items: $items
      services: $services
      discount: $discount
      businessPaymentId: $businessPaymentId
      businessCustomerId: $businessCustomerId
    ) {
      success
      message
    }
  }
`;

export const useUpdateBusinessInvoice = () => {
  const [businessInvoice, updateBusinessInvoice] = useMutation(
    updateBusinessInvoiceMutation
  );

  const handleUpdateBusinessInvoice = async (
    data: Invoice,
    invoiceId: string | any,
    callBack?: (data: Record<string, any>) => void
  ) => {
    try {
      const req = await updateBusinessInvoice(data, invoiceId);

      await handleRequestCall({ request: req, callBack });
    } catch (e) {
      showToast({
        type: "error",
        title: "An error occurred",
        subText: "Please try again",
      });
      console.error(e);
    }
  };

  return {
    handleUpdateBusinessInvoice,
    businessInvoice,
    isLoading: businessInvoice?.fetching,
  };
};
