const MenuIcon = (props: any) => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 11.5C1 9.62485 1.02008 9 3.5 9C5.97992 9 6 9.62485 6 11.5C6 13.3752 6.00791 14 3.5 14C0.992091 14 1 13.3752 1 11.5Z"
        stroke="#798892"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 3.5C1 1.62485 1.02008 1 3.5 1C5.97992 1 6 1.62485 6 3.5C6 5.37515 6.00791 6 3.5 6C0.992091 6 1 5.37515 1 3.5Z"
        stroke="#798892"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 11.5C9 9.62485 9.02008 9 11.5 9C13.9799 9 14 9.62485 14 11.5C14 13.3752 14.0079 14 11.5 14C8.99209 14 9 13.3752 9 11.5Z"
        stroke="#798892"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 11.5C17 9.62485 17.0201 9 19.5 9C21.9799 9 22 9.62485 22 11.5C22 13.3752 22.0079 14 19.5 14C16.9921 14 17 13.3752 17 11.5Z"
        stroke="#798892"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 3.5C9 1.62485 9.02008 1 11.5 1C13.9799 1 14 1.62485 14 3.5C14 5.37515 14.0079 6 11.5 6C8.99209 6 9 5.37515 9 3.5Z"
        stroke="#798892"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 3.5C17 1.62485 17.0201 1 19.5 1C21.9799 1 22 1.62485 22 3.5C22 5.37515 22.0079 6 19.5 6C16.9921 6 17 5.37515 17 3.5Z"
        stroke="#798892"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 19.5C1 17.6248 1.02008 17 3.5 17C5.97992 17 6 17.6248 6 19.5C6 21.3752 6.00791 22 3.5 22C0.992091 22 1 21.3752 1 19.5Z"
        stroke="#798892"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 19.5C9 17.6248 9.02008 17 11.5 17C13.9799 17 14 17.6248 14 19.5C14 21.3752 14.0079 22 11.5 22C8.99209 22 9 21.3752 9 19.5Z"
        stroke="#798892"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 19.5C17 17.6248 17.0201 17 19.5 17C21.9799 17 22 17.6248 22 19.5C22 21.3752 22.0079 22 19.5 22C16.9921 22 17 21.3752 17 19.5Z"
        stroke="#798892"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default MenuIcon;
