const CameraIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0.1 0.04 19.81 19.43"
      {...props}
    >
      <g clipPath="url(#clip0_3225_120334)">
        <path
          d="M18.9047 15.425C18.9047 15.8544 18.7342 16.2662 18.4305 16.5699C18.1269 16.8735 17.7151 17.0441 17.2857 17.0441H2.71426C2.28486 17.0441 1.87305 16.8735 1.56942 16.5699C1.26579 16.2662 1.09521 15.8544 1.09521 15.425V6.52028C1.09521 6.09088 1.26579 5.67907 1.56942 5.37544C1.87305 5.07181 2.28486 4.90123 2.71426 4.90123H5.95236L7.57141 2.47266H12.4285L14.0476 4.90123H17.2857C17.7151 4.90123 18.1269 5.07181 18.4305 5.37544C18.7342 5.67907 18.9047 6.09088 18.9047 6.52028V15.425Z"
          stroke="#0077FF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 13.8043C11.7883 13.8043 13.2381 12.3546 13.2381 10.5662C13.2381 8.77787 11.7883 7.32812 10 7.32812C8.21165 7.32812 6.7619 8.77787 6.7619 10.5662C6.7619 12.3546 8.21165 13.8043 10 13.8043Z"
          stroke="#0077FF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3225_120334">
          <rect
            width="19.4286"
            height="19.4286"
            fill="white"
            transform="translate(0.285706 0.0429688)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CameraIcon;
