import { REPORT_FILTER_DATE_ENUM } from "./../../components/reports/constants";
import { CreatedAtType } from "../types";
import { gql, useQuery } from "urql";
import { useAuthUser } from "store";
import { ITopSellingProducts } from "api/dashboard";

type GetInventoryReportType = {
  businessId: string;
  createdAt: CreatedAtType["createdAt"];
  inventoryGraphType: string;
};

export const useGetInventoryReport = ({
  createdAt,
  businessId,
  inventoryGraphType,
}: GetInventoryReportType) => {
  return useQuery({
    query: combinedQuery,
    variables: {
      createdAt,
      reportType: "REPORT",
      businessId,
      inventoryGraphType,
      businessIdReq: businessId,
      createdAtSec: createdAt,
    },
  });
};

const combinedQuery = gql`
  query (
    $createdAt: CreatedAtInputSale
    $reportType: SALESUMMARYCARDTYPE
    $inventoryGraphType: InventoryGroupingInput
    $businessId: String
    $businessIdReq: String!
    $createdAtSec: CreatedAtInputSaleInventory
  ) {
    getInventoryByTime(type: $inventoryGraphType) {
      identifier
      type
      totalInventory
    }
    getTopSellingProducts(
      businessId: $businessId
      type: $reportType
      createdAt: $createdAt
    )
    getInventoryStatus(
      businessId: $businessIdReq
      filter: { createdAt: $createdAtSec }
    ) {
      totalStock
      currentStockValue
      currentStockCostPrice
      netProfit
      currentActiveUniqueProducts
      allTimeUniqueProducts
      lowStockCount
      countOfCurrentServices
      totalServiceRevenue
      productCategoryBreakdown {
        _id
        count
      }
    }
  }
`;

export const useGetInventoryReportHook = ({
  createdAt,
  inventoryGraphType,
}: {
  createdAt?: CreatedAtType["createdAt"];
  inventoryGraphType: REPORT_FILTER_DATE_ENUM;
}) => {
  const { userData } = useAuthUser();
  const [result, refetchAll] = useGetInventoryReport({
    createdAt: createdAt,
    businessId: userData?.businessId as string,
    inventoryGraphType,
  });

  return {
    result,
    refetchAll,
    data: (result?.data || {
      getInventoryByTime: [],
      getTopSellingProducts: {
        count: 0,
        soldItemData: [],
      },
      getInventoryStatus: {},
    }) as IInventoryReport,
  };
};

export interface IInventoryByTime {
  identifier: string;
  type: string;
  totalInventory: number;
}

export interface IInventoryCards {
  totalStock: number;
  currentStockValue: number;
  currentStockCostPrice: number;
  netProfit: number;
  currentActiveUniqueProducts: number;
  allTimeUniqueProducts: number;
  lowStockCount: number;
  countOfCurrentServices: number;
  totalServiceRevenue: number;
  productCategoryBreakdown: {
    _id: string;
    count: number;
  }[];
}

export interface IInventoryReport {
  getInventoryByTime: IInventoryByTime[];
  getTopSellingProducts: ITopSellingProducts;
  getInventoryStatus: IInventoryCards;
}
