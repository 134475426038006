import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { VInputProps } from "components/common/types";
import {
  StyledRequiredDecorator,
  StyledVFlagWrapper,
  StyledVFormError,
  StyledVFormInput,
  StyledVFormLabel,
  StyledVInput,
  StyledVInputIcons,
  StyledVInputWrapper,
} from "./styles";
import { VSelect } from "../VSelect";
import { CountryDialJson } from "assets/json";
import { REGEX_UTILS } from "utils";
import { DIAL_CODE } from "../constants";
import clsx from "clsx";

const mappedCountryJson = CountryDialJson.map((data) => ({
  ...data,
  value: data?.dial_code,
  label: (
    <StyledVFlagWrapper>
      <div className="flag-container">{data?.flag}</div>
      <div>{data?.dial_code}</div>
    </StyledVFlagWrapper>
  ),
}));

const InputField = ({
  onChange,
  value,
  onBlur,
  showPassword = false,
  ...rest
}: {
  onChange?: any;
  value: any;
  onBlur?: any;
  showPassword?: boolean;
} & VInputProps) => {
  const {
    name,
    label,
    className,
    type = "text",
    preIcon,
    postIcon,
    register,
    error,
    validation,
    formInputStyle,
    ...others
  } = rest;

  const isPassword = type === "password";
  const isAmount = type === "amount";
  const isTelephone = type === "tel";

  const telephoneProps = {
    type: "number",
  };

  // This removes the comma used to format amount
  const transformValue = (value: any) => {
    return isAmount
      ? value &&
          Number(value).toFixed().replace(REGEX_UTILS.FORMAT_WITH_COMMA, "$1,")
      : value;
  };

  return (
    <StyledVInputWrapper>
      {isTelephone && (
        <VSelect
          optionValue={mappedCountryJson.filter(
            (item) => item.value === DIAL_CODE.NIGERIA
          )}
          className="country-selector"
          name="mobile-telephone"
          options={mappedCountryJson}
          disabled
        />
      )}
      <StyledVInput
        className={clsx(isTelephone && "telephone-input", className)}
        name={name}
        type={!isPassword ? type : showPassword ? "text" : "password"}
        {...(type === "number" && {
          step: "any",
          inputMode: "numeric",
        })}
        {...others}
        {...(isTelephone && telephoneProps)}
        id={rest?.id ?? name}
        preIcon={!!preIcon}
        postIcon={!!postIcon}
        onChange={(event) => {
          const {
            target: { value: inputValue },
          } = event;

          let newValue = inputValue.replace(REGEX_UTILS.SANITIZE_INPUT, "");
          newValue = inputValue.replace(
            REGEX_UTILS.REMOVE_FORMAT_WITH_COMMA,
            ""
          );

          if (rest.maxLength) {
            if (newValue.length > Number(rest?.maxLength)) {
              return;
            }
          }
          if (isAmount) {
            // eslint-disable-next-line no-param-reassign
            // This removes the comma used to format => 3,000 => 3000
            event.target.value = newValue.replace(
              REGEX_UTILS.REMOVE_FORMAT_WITH_COMMA,
              ""
            );
          }
          onChange && onChange?.(event);
        }}
        value={transformValue(value)}
        onBlur={() => {
          onBlur?.();
        }}
      />
    </StyledVInputWrapper>
  );
};

export const VInput = React.forwardRef((props: VInputProps, ref) => {
  const {
    name,
    label,
    className,
    type = "text",
    preIcon,
    postIcon,
    value,
    control,
    register,
    error,
    validation,
    formInputStyle,
    ...rest
  } = props;
  const [showPassword, setShowPassword] = useState(false);

  const isPassword = type === "password";

  return (
    <StyledVFormInput type={type} style={formInputStyle}>
      {label && (
        <StyledVFormLabel htmlFor={name}>
          {label}{" "}
          {rest?.required && (
            <StyledRequiredDecorator>*</StyledRequiredDecorator>
          )}
        </StyledVFormLabel>
      )}

      {control ? (
        <Controller
          name={name}
          rules={validation}
          key={name}
          control={control}
          render={({ field }) => {
            return (
              <InputField
                showPassword={showPassword}
                onBlur={field.onBlur}
                onChange={field.onChange}
                value={field.value}
                {...props}
              />
            );
          }}
        />
      ) : (
        <InputField
          showPassword={showPassword}
          onChange={rest?.onChange}
          value={value}
          {...props}
        />
      )}
      <StyledVInputIcons>
        {preIcon && <button className="pre__icon">{preIcon}</button>}
        {postIcon && (
          <button
            className="post__icon"
            onClick={() => {
              if (isPassword) {
                setShowPassword(!showPassword);
              }
            }}
          >
            {postIcon}
          </button>
        )}
      </StyledVInputIcons>
      {type !== "file" && (
        <StyledVFormError>{error?.message} </StyledVFormError>
      )}
    </StyledVFormInput>
  );
});
