import { VButton, VSelect, VTable } from "components/common";
import { BillingCheckoutCard } from "components/manage-business";
import { useDashboardHook } from "hooks";
import { DURATION_INPUT_LISTS, TOP_SELLING_PRODUCTS_COLUMN } from "./constants";

const TopSellingProductsTable = ({
  topSellingProduct,
}: {
  topSellingProduct: ReturnType<typeof useDashboardHook>["topSellingProduct"];
}) => {
  const {
    extractedData,
    setTopSellingDateRange,
    topSellingDateRange,
    fetching,
  } = topSellingProduct;

  return (
    <BillingCheckoutCard
      title={"Top Selling Products"}
      titleFontSize="14px"
      action={
        <VSelect
          isSlim
          inputWrapperStyle={{
            height: "35px",
          }}
          optionValue={topSellingDateRange}
          name="duration"
          options={DURATION_INPUT_LISTS}
          onChange={(value: number) => {
            setTopSellingDateRange(value);
          }}
        />
      }
    >
      <VTable
        smallPadding
        isLoading={!!fetching}
        columns={TOP_SELLING_PRODUCTS_COLUMN}
        data={extractedData.slice(0, 10) || []}
        emptyContent={{
          title: "No Top Selling Products Yet!",
          style: {
            height: "200px",
          },
          children: (
            <VButton
              text="Add Product to Inventory"
              isOutline
              disabled
              noborder={false}
            />
          ),
        }}
      />
    </BillingCheckoutCard>
  );
};

export default TopSellingProductsTable;
