import { VButton, VRadio, VSwitch } from "components/common";
import {
  SettingsContainer,
  Charges,
  ChargeItem,
  Disable,
  SaveButton,
} from "./styles";
import { POS_CHARGES } from "../constants";
import { usePosSettingsHook } from "hooks";

const PosSettings = () => {
  const { onSubmit, handleSubmit, selectedCharge, setSelectedCharge, control } =
    usePosSettingsHook();

  return (
    <>
      <SettingsContainer>
        <form id="settings_form" onSubmit={handleSubmit(onSubmit)}>
          <Disable>
            <h3>DISABLE POS TERMINAL</h3>
            <VSwitch
              labelStyles={{
                color: "#7890B5",
              }}
              name="isActive"
              control={control}
            />
          </Disable>
          <hr />
          <Charges>
            <h3>POS Terminal Charges</h3>
            <div className="radio">
              {POS_CHARGES.map((charge) => (
                <ChargeItem key={charge.id}>
                  <div className="header">
                    <VRadio
                      id={charge.id}
                      isSelected={selectedCharge === charge}
                      value={charge}
                      changed={() => setSelectedCharge(charge)}
                    />
                    <h4>{charge.header}</h4>
                  </div>
                  <p>{charge.text}</p>
                </ChargeItem>
              ))}
            </div>
          </Charges>
        </form>
      </SettingsContainer>
      <SaveButton>
        <VButton
          text="Save Changes"
          width="320px"
          height="45px"
          form="settings_form"
        />
      </SaveButton>
    </>
  );
};

export default PosSettings;
