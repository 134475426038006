import { configureStore } from "@reduxjs/toolkit";
import {
  authReducer,
  dashboardReducer,
  searchReducer,
  navbarReducer,
  viewExpenseReducer,
  transactionPinReducer,
} from "./slices";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    dashboard: dashboardReducer,
    search: searchReducer,
    navbar: navbarReducer,
    transactionPin: transactionPinReducer,
    viewExpense: viewExpenseReducer,
  },
});

export type IRootState = ReturnType<typeof store.getState>;
export type VAppDispatch = typeof store.dispatch;
