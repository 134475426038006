import CloseIcon from "assets/icons/close-icon.svg";
import {
  ClearIcon,
  Container,
  Icon,
  SearchInput,
  TopNavButton,
} from "./TopNav.styled";
import { ISearch } from "./types";

const TopNavSearch = ({
  inputPlaceholder,
  isSearchable,
  handleChange,
  handleClear,
  handleEnterKeyPress,
  value,
}: ISearch) => {
  return (
    <Container hidden={!isSearchable}>
      <TopNavButton>
        {value !== "" && (
          <ClearIcon onClick={handleClear}>
            <img src={CloseIcon} alt="clear-search-term" />
          </ClearIcon>
        )}
      </TopNavButton>
      <SearchInput
        onChange={handleChange}
        placeholder={inputPlaceholder}
        disabled={!isSearchable}
        value={value}
        onKeyDown={handleEnterKeyPress}
      />
      <Icon />
    </Container>
  );
};

export default TopNavSearch;
