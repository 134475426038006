import { useCallback, useEffect, useRef, useState } from "react";
import { useQuery, UseQueryArgs, UseQueryResponse } from "urql";

const useLazyQuery: (
  args: Omit<UseQueryArgs, "variables" | "pause">
) => UseQueryResponse = (args) => {
  const firstUpdate = useRef(true);
  const [variables, setVariables] = useState<any>();

  const [result, refetch] = useQuery({
    ...args,
    variables,
    pause: true,
  });

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variables]);

  const makeRequest = useCallback((reqVariables: any) => {
    setVariables(reqVariables);
  }, []);

  return [result, makeRequest];
};

export default useLazyQuery;
