import { CHANGE_PASSCODE_INPUT_LIST } from "components/auth/signup/constants";
import { showToast } from "components/common";
import {
  useCompleteKycLevelOne,
  useUpdateBusiness,
  useUpdateuser,
  useChangeUserPasscode,
} from "api";
import { InputList } from "components/common/types";
import { KYC_FIELDS, VNinInstructions } from "components/manage-business";
import useModal from "hooks/useModal";
import { useCallback, useMemo, useState } from "react";
import { FieldValues, useForm, UseFormGetValues } from "react-hook-form";
import { CUSTOM_VALIDATIONS, hardRefresh } from "utils";
import { useUserProfileHook } from "./useUserProfileHook";
import { togglepinModal, useAuthUser } from "store";
import InfoIcon from "assets/images/svgs/InfoIcon";
import { useVAppDispatch } from "hooks/useStore";

type KYC_FIELD_PROPS = {
  [key in KYC_FIELDS]?: {
    fields: InputList;
    validations?: any;
    header?: string;
    buttonText?: string;
  };
};

const KYC_FIELDS_INPUT_LIST = (
  getValues: UseFormGetValues<FieldValues>
): KYC_FIELD_PROPS => ({
  [KYC_FIELDS.BVN]: {
    header: "Complete your KYC to carry out transactions",
    buttonText: "Validate",
    fields: [
      {
        name: "bvn",
        type: "number",
        label: "BVN",
        required: true,
        autoFocus: true,
        validation: {
          required: "Enter BVN",
          ...CUSTOM_VALIDATIONS.bvn,
        },
      },
    ],
  },
  [KYC_FIELDS.REGISTRATION_NUMBER]: {
    header: "Complete your KYC to carry out transactions",
    buttonText: "Validate",
    fields: [
      {
        name: "CACNumber",
        label: "Registration Number",
        required: true,
        validation: {
          required: "Enter registration number",
        },
      },
    ],
  },
  [KYC_FIELDS.UTILITY_BILL]: {
    header: "Complete your KYC to carry out transactions",
    buttonText: "Validate",
    fields: [
      {
        name: "utilityBill",
        inputType: "upload",
        label: "Utility Bill",
        required: true,
        validation: {
          required: "Upload an utility bill",
        },
      },
    ],
  },
  [KYC_FIELDS.PASSCODE]: {
    header: "Change Passcode",
    buttonText: "Change",
    fields: CHANGE_PASSCODE_INPUT_LIST(getValues),
  },
  [KYC_FIELDS.NIN]: {
    header: "Complete your KYC to carry out transactions",
    buttonText: "Validate",
    fields: [
      {
        name: "NIN",
        label: "VNIN",
        required: true,
        autoFocus: true,
        validation: {
          required: "Enter NIN",
          ...CUSTOM_VALIDATIONS.nin,
        },
      },
      {
        name: "instructions",
        inputType: "element",
        children: (
          <VNinInstructions>
            <InfoIcon style={{ fill: "#F5A346", width: "60px" }} />
            <div>
              <h4>Option 1</h4>
              <p>To generate your VNIN by USSD</p>
              <p>
                Please dial <b> *346*3*Your NIN*715461#</b> <br /> By “your NIN”
                we mean the NIN given to you by the Federal Government.
              </p>
              <h4>Option 2</h4>
              <p>
                Download the NIMC app, login and then use 715461 as the agent
                code to generate a VNIN token.
              </p>
            </div>
          </VNinInstructions>
        ),
      },
    ],
  },
});

export const useKycView = () => {
  const dispatch = useVAppDispatch();
  const modal = useModal();
  const [kycType, setKycType] = useState<KYC_FIELDS | null>(null);

  const { open: openKycLevelOneModal, toggleModal: toggleKyclevelOneModal } =
    useModal();
  const { open: openKycLevelTwoModal, toggleModal: toggleKycLevelTwoModal } =
    useModal();
  const {
    open: openKycLevelThreeModal,
    toggleModal: toggleKycLevelThreeModal,
  } = useModal();

  const { userData } = useAuthUser();

  const hookForm = useForm();
  const { handleSubmit, getValues } = hookForm;
  const toggleTransactionPinModal = () => dispatch(togglepinModal());

  // Submision hooks
  const {
    handleCompleteKycLevelOne,
    completeKycLevelOne: { fetching },
  } = useCompleteKycLevelOne();
  const { handleUpdateBusiness, isLoading } = useUpdateBusiness();
  const {
    handleChangeUserPasscode,
    changePasscodeResponse: { fetching: isChangingPasscode },
  } = useChangeUserPasscode();
  const { handleUpdateUser, isLoading: updateUserLoading } = useUpdateuser();
  // const { refetchUser } = useOauthCallBackHook();
  const { refetchUserProfile } = useUserProfileHook();

  const kycFieldInputList = useMemo(() => {
    return KYC_FIELDS_INPUT_LIST(getValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues]);

  const kycFields = useMemo(() => {
    if (kycType) return kycFieldInputList[kycType];
    else return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kycType]);

  const mappedEndpoints = useCallback(
    (
      data: Record<string, any>
    ): {
      [key in KYC_FIELDS]?: any;
    } => {
      hookForm.reset();
      return {
        [KYC_FIELDS.BVN]: () =>
          handleCompleteKycLevelOne(data as any, async () => {
            await refetchUserProfile();
            hardRefresh();
            handleCloseModal();
          }),
        [KYC_FIELDS.REGISTRATION_NUMBER]: () =>
          handleUpdateBusiness(data as any, handleCloseModal),
        [KYC_FIELDS.UTILITY_BILL]: () =>
          handleUpdateBusiness(data as any, handleCloseModal),
        [KYC_FIELDS.PASSCODE]: () =>
          handleChangeUserPasscode(data as any, () => {
            handleCloseModal();
            showToast({
              title: "Passcode changed successfully",
              subText: "Your passcode has been changed",
            });
          }),
        [KYC_FIELDS.NIN]: () =>
          handleUpdateUser(
            {
              ...data,
              userId: userData?._id as string,
            } as any,
            handleCloseModal
          ),
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleOpenKycField = (kyc: KYC_FIELDS) => {
    if (kyc === KYC_FIELDS.TRANSACTION_PIN) {
      return toggleTransactionPinModal();
    }

    setKycType(kyc);
    modal.handleOpen();
  };
  const handleCloseModal = () => {
    setKycType(null);
    modal.handleClose();
  };

  const submitForm = handleSubmit(
    async (values) => {
      if (kycType) {
        await mappedEndpoints(values)?.[kycType]?.();
      }
    },
    (error) => {
      console.error({ error });
    }
  );

  return {
    kycType,
    setKycType,
    modal: {
      open: !!kycFields && modal.open,
      handleOpen: handleOpenKycField,
      handleClose: handleCloseModal,
    },
    kycFields,
    hookForm,
    submitForm,
    isSubmitting:
      fetching || isLoading || isChangingPasscode || updateUserLoading,
    openKycLevelOneModal,
    toggleKyclevelOneModal,
    openKycLevelTwoModal,
    toggleKycLevelTwoModal,
    openKycLevelThreeModal,
    toggleKycLevelThreeModal,
  };
};
