import React from "react";

export const ColorCheckMark = () => {
  return (
    <svg
      width="80"
      height="89"
      viewBox="0 0 91 99"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M46.4232 80.9205C65.9809 80.9205 81.8356 65.0659 81.8356 45.5081C81.8356 25.9504 65.9809 10.0957 46.4232 10.0957C26.8654 10.0957 11.0107 25.9504 11.0107 45.5081C11.0107 65.0659 26.8654 80.9205 46.4232 80.9205Z"
        fill="#007BFF"
      />
      <path
        d="M41.8613 57.2731C41.4268 57.2982 40.9919 57.2303 40.5857 57.0741C40.1795 56.9178 39.8113 56.6768 39.5056 56.3671C36.3662 53.2322 33.2298 50.0943 30.0964 46.9534C28.7985 45.6441 28.8075 43.7369 30.0623 42.4775C31.3172 41.2182 33.2335 41.2476 34.5382 42.541C36.8229 44.797 39.0926 47.0689 41.3471 49.3566C41.7276 49.744 41.9224 49.7485 42.321 49.3815C47.6198 44.498 52.927 39.6243 58.2424 34.7604C59.0034 34.0605 59.8574 33.6437 60.8993 33.7751C62.2085 33.9404 63.1259 34.6607 63.5427 35.911C63.9594 37.1614 63.6446 38.2985 62.6865 39.1977C61.0081 40.7833 59.2889 42.3167 57.5991 43.8751C53.1006 47.9976 48.6029 52.1215 44.1059 56.247C43.4332 56.8586 42.6994 57.3003 41.8613 57.2731Z"
        fill="#A6D0FF"
      />
      <path
        d="M8.48498 16.5832C11.5536 16.5832 14.0412 14.0956 14.0412 11.027C14.0412 7.95833 11.5536 5.4707 8.48498 5.4707C5.41634 5.4707 2.92871 7.95833 2.92871 11.027C2.92871 14.0956 5.41634 16.5832 8.48498 16.5832Z"
        fill="#F0A75A"
      />
      <path
        d="M85.444 69.9387C88.5126 69.9387 91.0002 67.4511 91.0002 64.3824C91.0002 61.3138 88.5126 58.8262 85.444 58.8262C82.3753 58.8262 79.8877 61.3138 79.8877 64.3824C79.8877 67.4511 82.3753 69.9387 85.444 69.9387Z"
        fill="#F0A75A"
      />
      <path
        d="M45.1237 98.4338C48.1923 98.4338 50.6799 95.9462 50.6799 92.8776C50.6799 89.8089 48.1923 87.3213 45.1237 87.3213C42.055 87.3213 39.5674 89.8089 39.5674 92.8776C39.5674 95.9462 42.055 98.4338 45.1237 98.4338Z"
        fill="#A6D0FF"
      />
      <path
        d="M81.5437 23.1674C82.7409 23.1674 83.7114 22.1969 83.7114 20.9997C83.7114 19.8025 82.7409 18.832 81.5437 18.832C80.3465 18.832 79.376 19.8025 79.376 20.9997C79.376 22.1969 80.3465 23.1674 81.5437 23.1674Z"
        fill="#E57A1A"
      />
      <path
        d="M76.8464 89.6977C78.0436 89.6977 79.0141 88.7272 79.0141 87.53C79.0141 86.3328 78.0436 85.3623 76.8464 85.3623C75.6492 85.3623 74.6787 86.3328 74.6787 87.53C74.6787 88.7272 75.6492 89.6977 76.8464 89.6977Z"
        fill="#E57A1A"
      />
      <path
        d="M3.26399 65.5221C5.06665 65.5221 6.52799 64.0608 6.52799 62.2581C6.52799 60.4555 5.06665 58.9941 3.26399 58.9941C1.46134 58.9941 0 60.4555 0 62.2581C0 64.0608 1.46134 65.5221 3.26399 65.5221Z"
        fill="#E57A1A"
      />
      <path
        d="M58.2708 6.52799C60.0735 6.52799 61.5348 5.06665 61.5348 3.26399C61.5348 1.46134 60.0735 0 58.2708 0C56.4682 0 55.0068 1.46134 55.0068 3.26399C55.0068 5.06665 56.4682 6.52799 58.2708 6.52799Z"
        fill="#A6D0FF"
      />
    </svg>
  );
};
