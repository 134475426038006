import React from "react";
import { useResetPasscodeContext } from "./context";
import { EnterResetPassscodeOtp } from "./enter-reset-passcode-otp";
import { EnterResetPassscodPassword } from "./enter-reset-passcode-passwords";

export const EnterResetPasscode = () => {
  const {
    steps: { currentIndex },
  } = useResetPasscodeContext();

  const views = [
    <EnterResetPassscodeOtp key="reset-passcode-otp" />,
    <EnterResetPassscodPassword key="reset-passcode-password" />,
  ];

  return <>{views[currentIndex]}</>;
};
