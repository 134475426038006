import { FlexColumn } from "./../../../../../styles/global";
import styled from "styled-components/macro";
import {
  FlexAMixin,
  FlexJAMixin,
  StyledVCardContainer,
  stylingUtils,
} from "styles";

const { rem, color } = stylingUtils;
export const StyledChoosePricingPlanContainer = styled(StyledVCardContainer)``;

export const StyledChoosePricingPlan = styled("div")`
  ${FlexJAMixin};
  padding: ${rem(20)} 0 ${rem(30)};
  flex-direction: column;
  gap: ${rem(20)};
  text-align: center;

  h3 {
    font-size: ${rem(30)};
    margin-bottom: ${rem(10)};
  }
`;

export const StyledCurrentPlanDetails = styled(StyledVCardContainer)`
  display: flex;
  gap: ${rem(10)};
  flex-wrap: wrap;
  border: 1px solid ${color("lightBlue")};
  justify-content: space-between;
  align-items: flex-start;
`;

export const StyledCurrentBillignLeftDetails = styled("div")`
  ${FlexColumn};
  gap: ${rem(20)};
`;

export const StyledCurrentPlanBillingAmount = styled("div")`
  ${FlexAMixin};
  gap: ${rem(10)};
`;
