import { VEmptyState, VPagination, VTable } from "components/common";
import { POS_TRANSACTION_COLUMN } from "../../constants";
import { useTerminalTransactionHook } from "hooks";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

const PosTransactionTable = () => {
  const { posId } = useParams();

  const {
    extractedData,
    error,
    fetching,
    pageNumber,
    handleChangePage,
    setTerminalId,
  } = useTerminalTransactionHook();

  useEffect(() => {
    setTerminalId(posId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [posId]);

  if (error)
    return (
      <VEmptyState
        title="Error Fetching Data!"
        subText="An error occur getting data"
      />
    );

  const { nodes: terminalTransactions, pageInfo } = extractedData;

  return (
    <div>
      <VTable
        isLoading={fetching}
        columns={POS_TRANSACTION_COLUMN()}
        data={terminalTransactions}
        collapsible
        useId
        emptyContent={{
          title: "No Pos Transaction Yet!",
          subText: "Your history will appear here when you have one",
        }}
      />
      <VPagination
        pageInfo={pageInfo}
        pageNumber={pageNumber}
        getPageNumber={(pageNumber) => handleChangePage(pageNumber)}
      />
    </div>
  );
};

export default PosTransactionTable;
