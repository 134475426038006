import {
  useGetCustomerTerminals,
  useUpdateCustomerTerminal,
  ICustomerTerminalSettings,
} from "api";
import { showToast } from "components/common";
import { POS_CHARGES } from "components/pos";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useAuthUser } from "store";

export const usePosSettingsHook = () => {
  const [result, refetchPosCards] = useGetCustomerTerminals({
    status: "DELIVERED",
  });

  const cardDetails = result?.data?.getCustomerTerminals;
  const { userData } = useAuthUser();

  const [active, setActive] = useState<any>(cardDetails?.nodes[0]);
  const [selectedCharge, setSelectedCharge] = useState<any>(POS_CHARGES[0]);

  const isTerminalActive = active?.isActive;

  var charges = POS_CHARGES.find((charge) => {
    return charge.value === active?.terminalChargeBy;
  });

  useEffect(() => {
    setActive(cardDetails?.nodes[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardDetails]);

  useEffect(() => {
    setSelectedCharge(charges);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [charges]);

  const hookForm = useForm<ICustomerTerminalSettings>({
    defaultValues: {
      isActive: !isTerminalActive,
    },
  });

  const { handleSubmit, control, reset } = hookForm;

  useEffect(() => {
    reset({ isActive: !isTerminalActive });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTerminalActive]);

  const { handleUpdateCustomerTerminal, isLoading } =
    useUpdateCustomerTerminal();

  const onSubmit = async (data: any) => {
    const newData = {
      ...data,
      isActive: !data?.isActive,
      terminalRequestId: active?._id,
      terminalChargeBy: selectedCharge.value,
      staffId: userData?._id as string,
    };
    await handleUpdateCustomerTerminal(newData, () => {
      showToast({
        subText: `Submitted successfully`,
      });
    });
  };

  return {
    refetchPosCards,
    cardDetails,
    active,
    setActive,
    handleSubmit,
    isLoading,
    onSubmit,
    setSelectedCharge,
    selectedCharge,
    control,
  };
};
