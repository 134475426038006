import {
  MAPPED_REPORT_FILTER_DATE_ENUM,
  REPORT_FILTER_DATE_ENUM,
} from "components/reports/constants";
import { useLocalSearchParams } from "hooks";
import { useMemo } from "react";

const useReportFilterHook = () => {
  const { searchParams } = useLocalSearchParams();

  const date = (searchParams.get("date") ??
    REPORT_FILTER_DATE_ENUM.TODAY) as REPORT_FILTER_DATE_ENUM;

  const dateFilter = useMemo(() => {
    const newDate = MAPPED_REPORT_FILTER_DATE_ENUM[date];
    return { createdAt: { gte: newDate?.substring(0, 10) } };
  }, [date]);

  return { dateFilter, date };
};

export default useReportFilterHook;
