import { gql, useQuery } from "urql";

const getInvoiceBreakdownQuery = gql`
  query {
    getInvoicesStat
  }
`;

export const useGetInvoiceBreakdown = () => {
  return useQuery({
    query: getInvoiceBreakdownQuery,
  });
};
