import { StyledSignInPage } from "components/auth";
import { VButton } from "components/common";
import backgroundImage from "assets/images/pngs/background-image.png";
import { useEffect } from "react";
import { getEnvKey } from "utils";

const BluSalt = () => {
  const routeParams = new URLSearchParams(window.location.search);

  // Extract parameters
  const bvn = routeParams.get("bvn");
  const phoneNumber = routeParams.get("phoneNumber");
  const addressLandmark = routeParams.get("addressLandmark");
  const addressCountry = routeParams.get("addressCountry");
  const addressState = routeParams.get("addressState");
  const addressCity = routeParams.get("addressCity");
  const addressLine1 = routeParams.get("addressLine1");
  const flatNumber = routeParams.get("flatNumber");
  const buildingName = routeParams.get("buildingName");
  const buildingNumber = routeParams.get("buildingNumber");
  const alias = routeParams.get("alias");
  const postalCode = routeParams.get("postalCode");
  const addressLine2 = routeParams.get("addressLine2");

  useEffect(() => {
    // Dynamically load SDK script
    if (typeof window === "undefined") {
      return;
    }
    const script = document.createElement("script");
    script.src = "https://widgets-sdk.blusalt.net/WidgetSDk.js";
    script.type = "text/javascript";
    script.async = true;
    document.body.appendChild(script);

    // Initialize SDK when script is loaded

    script.onload = () => {
      const params = {
        apikey: getEnvKey("REACT_APP_BLUSALT_API_KEY"), // Required
        clientid: getEnvKey("REACT_APP_BLUSALT_CLIENT_ID"), // Required
        appname: getEnvKey("REACT_APP_BLUSALT_APP_NAME"), // Required
        serviceId: getEnvKey("REACT_APP_BLUSALT_SERVICE_ID"), // Required
        callback: () => {
          return true;
        },
        onClose: () => {
          // Function to call when closing the SDK if any
        },
        redirectUrl:
          "url to be redirected to on successful use of the service if any", // optional
        primaryColor: "rgba(56, 12, 1, 1)",
        otherParams: {
          country: "Nigeria",
          id_number: bvn,
          id_type: "BVN",
          phone_number: phoneNumber,
          addressLandmark,
          addressCountry,
          addressState,
          addressCity,
          addressLine1,
          addressLine2,
          flatNumber,
          buildingName,
          buildingNumber,
          alias,
          postalCode,
        },
      };
      if (window?.BlusaltWidgets) {
        window.BlusaltWidgets.showWidget(params); // This will initialize the web SDK
      }
    };

    return () => {
      // Cleanup: remove script from DOM
      document.body.removeChild(script);
    };
  }, []);

  return (
    <StyledSignInPage style={{ backgroundImage: `url(${backgroundImage})` }}>
      <VButton text="BluSalt" onClick={() => null} />
    </StyledSignInPage>
  );
};

export default BluSalt;
