import styled from "styled-components";
import { stylingUtils } from "styles";

const { rem, color } = stylingUtils;
export const StyledVGuageItem = styled("div")`
  display: flex;
  align-items: center;
  gap: ${rem(10)};

  .vg-label {
    font-size: ${rem(16)};
    margin-bottom: ${rem(9)};
  }

  .vg-value {
    font-size: ${rem(18)};
  }

  .vg-desc {
    display: flex;
    gap: ${rem(5)};
    align-items: center;
  }

  .vg-increase {
    color: ${color("green")};
  }

  .vg-decrease {
    color: ${color("toastRed")};
  }
`;
