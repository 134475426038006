import {
  VModalOverlay,
  VModdalContainer,
} from "components/common/VModal/styles";
import styled from "styled-components";
import { stylingUtils } from "styles";

const {
  rem,
  color,
  mediaQuery: { up, down },
} = stylingUtils;

export const StyledNotificationOverlay = styled(VModalOverlay)`
  right: 500;
  justify-content: end;
`;

export const StyledNotificationModalContainer = styled(VModdalContainer)`
  border-radius: ${rem(0)};
  height: 100vh;
  margin: 0;
  overflow-y: scroll;
  opacity: 1;
  visibility: visible;
  transition: 0.5s all ease-in-out;

  ${up("laptop")} {
    max-width: ${rem(760)};
    max-height: 100vh;
  }
  ${down("tablet")} {
    max-width: ${rem(400)};
    padding: ${rem(20)};
  }
`;

export const StyledNotificationArea = styled.div`
  .list {
    padding: 0 5rem 0 1.5rem;
    overflow-y: scroll;
  }

  .list > div:not(last-child) {
    margin-bottom: 1rem;
  }
`;

export const StyledIcon = styled.div<{ icon: string }>`
  display: absolute;
  width: ${rem(40)};
  height: ${rem(40)};
  border: ${rem(4)} solid white;
  outline: ${rem(2)} solid #f4f4f4;
  border-radius: ${rem(22.5)};
  background-image: ${(props) => `url(${props.icon})`};
  backdrop-filter: blur(2px);
  background-size: cover;
`;

export const StyledItem = styled.div`
  .item {
    position: relative;
    margin: 2rem 0;
    display: flex;
    justify-content: start;
    gap: 1rem;
    max-height: ${rem(90)};
    cursor: pointer;
  }

  .item:hover {
    background-color: ${(props) => props.theme.colors.ash11};
  }

  .title {
    font-size: ${rem(15)};
    line-height: ${rem(19)};
    color: #001928;
  }

  .time {
    font-size: ${rem(11)};
    color: #a5b0b7;
  }

  .body {
    margin: 0.1rem 0;
    font-weight: 400;
  }

  .textarea {
    color: #374b58;
    font-size: ${rem(13)};
  }
`;

export const StyledNotificationHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #eee;

  .container {
    padding: 10rem;
  }

  h3 {
    color: #001928;
  }

  .left {
    display: flex;
    padding-left: 1rem;
  }

  .right {
    cursor: not-allowed;
  }

  .modal__close--icon {
    position: relative;
    background-color: ${color("ash01")};
    height: ${rem(30)};
    width: ${rem(30)};
    border-radius: 50%;
    cursor: pointer;
    margin-top: -1.5rem;
    margin-right: 1rem;
  }

  .modal__close--icon > img {
    display: inline-block;
  }
`;
