import { useLocalSearchParams } from "hooks";
import { ITerminalTransactions, useGetTerminalTransactions } from "api";
import { useState } from "react";
import { extractFetchedData } from "utils";

export const useTerminalTransactionHook = () => {
  const { searchParams } = useLocalSearchParams();

  const [terminalId, setTerminalId] = useState<string | any>();

  const [pageNumber, setPageNumber] = useState(
    parseInt(searchParams.get("page") || "1")
  );

  const [result, refetchTransactionResult] = useGetTerminalTransactions({
    size: 10,
    page: pageNumber,
    terminalId: terminalId,
  });

  // Handle pagination change
  const handleChangePage = (pageNumber: number) => {
    setPageNumber(pageNumber);
  };

  const extract = extractFetchedData<ITerminalTransactions>({
    result,
    key: "getTerminalTransactions",
  });

  return {
    ...extract,
    pageNumber,
    handleChangePage,
    refetchTransactionResult,
    setTerminalId,
  };
};
