import React, { Component, ErrorInfo, ReactNode } from "react";
import VCrashScreen from "./VCrashScreen";
// import { Navigate } from "react-router-dom";

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  state = { hasError: false };
  static getDerivedStateFromError() {
    return { hasError: true };
  }
  componentDidCatch(error: Error, info: ErrorInfo): void {
    console.error("ErrorBoundary caught an error", error, info);
  }

  render() {
    if (this.state.hasError) {
      return <VCrashScreen />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
