/* eslint-disable react-hooks/exhaustive-deps */

// core
import { useEffect, useState } from "react";

// Custom Components
import { showToast, VButton, VModal } from "components/common";
import VSecretPin from "components/common/VSecretPin";

// assets
import { VModalProps } from "components/common/types";

// styles
import { StyledValidatePinModal } from "./styles";
import { usePasscodeForVerification } from "components/auth/hooks";
import { usePasscodeContext } from "context";

const ValidateTransactionPinModal = ({
  open,
  close,
  callback,
  btnText,
  subtitle,
  onTransactionPinChange,
  onSubmitWithPasscode,
  resetStateOnClose,
}: VModalProps & {
  callback?: (value: string) => void;
  btnText?: string;
  subtitle?: string;
  onTransactionPinChange?: any;
  onSubmitWithPasscode?: any;
  resetStateOnClose?: () => void;
}) => {
  //states

  // let { fetching, data, error } = validatedTransactionPin;
  const [transactionPin, setTransactionPin] = useState("");

  const { hasSetPasscode } = usePasscodeContext();

  const { isLoading, onSubmit, hasError, isValid } = usePasscodeForVerification(
    {
      hasSetPassCode: hasSetPasscode,
      callback: callback
        ? () => callback(transactionPin)
        : () => {
            if (!onSubmitWithPasscode) {
              showToast({
                title: "Passcode Verified",
                subText: "Click on send to complete transaction",
              });
            }
          },
    }
  );
  const [errorIndicator, setErrorIndiactor] = useState(!!hasError);

  // functionality
  // const handleChange = (pin: any) => setTransactionPin(pin);

  const handleChange = (pin: any) => {
    setTransactionPin(pin);

    if (onTransactionPinChange) {
      onTransactionPinChange(pin); // Invoke the onTransactionPinChange function passed as a prop
    }
  };

  const handleClick = async () => {
    await onSubmit({
      pin: transactionPin,
      isAction: true,
    });
    setTransactionPin("");
    // if(onSubmitWithPasscode) {
    onSubmitWithPasscode();
    // }
  };

  useEffect(() => {
    if (!!isValid) {
      close();
    }
  }, [isValid]);

  // set error from the api response
  useEffect(() => {
    if (!!hasError) {
      setErrorIndiactor(!!hasError);
    }
  }, [hasError]);

  // clear on pin change
  useEffect(() => {
    if (!!errorIndicator) {
      setErrorIndiactor(false);
    }
  }, [transactionPin]);

  const handleClose = () => {
    close();
    setTransactionPin("");

    if (resetStateOnClose) {
      resetStateOnClose();
    }
  };

  return (
    <VModal
      open={open}
      close={handleClose}
      title="Passcode"
      width={500}
      alignModalTitle="center"
      subtitle={subtitle || "Enter your passcode to complete this payment"}
      alignModalSubTitle="center"
      modalTitleColor="#000"
    >
      <StyledValidatePinModal>
        <p className="passcode-text">Enter your passcode</p>
        <VSecretPin
          value={transactionPin}
          onChange={handleChange}
          numInputs={4}
          hasErrored={!!errorIndicator && transactionPin.length === 4}
        />
        {!!errorIndicator && (
          <p className="errorText">Incorrect PIN, please try again</p>
        )}
        <VButton
          text={btnText || "Confirm"}
          className="button"
          type="button"
          isSlim
          isLoading={isLoading}
          onClick={handleClick}
          disabled={transactionPin.length !== 4 || !!errorIndicator}
        />
      </StyledValidatePinModal>
    </VModal>
  );
};
export default ValidateTransactionPinModal;
