import { StyledDashboardWrapper } from "components/dashboard";
import styled from "styled-components";
import { FlexColumn } from "styles";

export const StyledStaffWrapper = styled(StyledDashboardWrapper)``;

export const StyledStaffDeleteWrapper = styled("div")`
  ${FlexColumn};
  gap: 4rem;
  text-align: center;
  justify-content: center;
  align-items: center;

  .button-group {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
`;
