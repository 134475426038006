import { ACCOUNT_CARDS_DATA, KOBO_NAIRA_UNIT } from "components/account";
import { useEffect, useMemo, useState } from "react";
import { useGetKycDetailsHook, useModal, useToggle } from "hooks";
import { useGetAccountDetail } from "api/account/get-account-details";

import { useAuthUser } from "store";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "routes";
import { IAccountCardsData, AccountDetail } from "components/account/types";
import { extractFetchedData } from "utils";

export const useAccountSummaryHook = () => {
  // states
  const [eyeOpen, toggleEye] = useToggle(false);
  const { toggleModal: toggleKycModal } = useModal();
  useModal();
  const { open: openAddMoneyModal, toggleModal: toggleAddMoneyModal } =
    useModal();

  const { kycDetailsResult } = useGetKycDetailsHook();

  const kycLevel = kycDetailsResult?.data?.getKycDetail?.kycLevel;

  // Utils
  let navigate = useNavigate();
  const { userData } = useAuthUser();
  const fullName = `${userData?.lastName} ${userData?.firstName}`;
  const [result] = useGetAccountDetail();

  // retrieve the main account balance
  const [accountBalance, setAccountBalance] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const extract = extractFetchedData<AccountDetail>({
    result,
    key: "getAccountDetail",
  });
  const { extractedData } = extract;

  useEffect(() => {
    if (extractedData && extractedData?.wallets) {
      const accountBalance = extractedData?.wallets?.find(
        (wallet) => wallet.type === "main"
      )?.balance;
      const balanceInNaira = `${Number(accountBalance) / KOBO_NAIRA_UNIT}`;
      setAccountBalance(balanceInNaira);
    }

    if (extractedData && extractedData?.virtualAccounts) {
      setAccountNumber(extractedData?.virtualAccounts?.[0]?.accountNumber);
    }
  }, [extractedData]);

  const routeToSendMoney = () => {
    navigate(APP_ROUTES.SEND_MONEY);
  };

  const accountSummary = {
    values: {
      balance: accountBalance,
      isKycCompleted: userData?.isKycCompleted,
      kycStatus: userData?.kycStatus,
      accountNumber: accountNumber,
      accountName: fullName,
      bank: userData?.bank,
      eyeOpen,
      kycLevel,
    },
    actions: {
      toggleEye,
      toggleAddMoneyModal,
      routeToSendMoney,
      toggleKycModal,
    },
  } as IAccountCardsData;
  const cardDataRef = JSON.stringify(accountSummary);

  const ACCOUNT_CARDS = useMemo(() => {
    return ACCOUNT_CARDS_DATA(accountSummary);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardDataRef]);

  return {
    accountSummary,
    ACCOUNT_CARDS,
    openAddMoneyModal,
    toggleAddMoneyModal,
    kycLevel,
  };
};
