import { VButton, VModal } from "components/common";
import { VModalProps } from "components/common/types";
import { ModalContainer } from "./styles";
import { IErrorModal } from "../constants";
import { Link } from "react-router-dom";

const PosErrorModal = ({
  open,
  close,
  imageSrc,
  imageAlt,
  title,
  subTitle,
  buttonText,
  buttonLink,
  buttonDisabled,
}: VModalProps & IErrorModal) => {
  return (
    <VModal open={open} close={close} width={500}>
      <ModalContainer>
        <div className="item">
          <img src={imageSrc} alt={imageAlt} />
        </div>
        <div className="item">
          <h2>{title}</h2>
        </div>
        <div className="item">
          <p>{subTitle}</p>
        </div>
        <div className="button">
          {buttonDisabled ? (
            <VButton
              text={buttonText}
              width="426px"
              height="50px"
              disabled={buttonDisabled}
            />
          ) : (
            <Link to={buttonLink}>
              <VButton
                text={buttonText}
                width="426px"
                height="50px"
                disabled={buttonDisabled}
              />
            </Link>
          )}
        </div>
      </ModalContainer>
    </VModal>
  );
};

export default PosErrorModal;
