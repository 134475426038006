const RightArrow = (props: any) => {
  return (
    <svg
      width="12"
      height="18"
      viewBox="0 0 12 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.6669 8.06641L3.20026 0.466406C2.66693 -0.0669271 1.86693 -0.0669271 1.33359 0.466406C0.800261 0.99974 0.800261 1.79974 1.33359 2.33307L7.86693 8.86641L1.33359 15.3997C1.06693 15.6664 0.933594 15.9331 0.933594 16.3331C0.933594 17.1331 1.46693 17.6664 2.26693 17.6664C2.66693 17.6664 2.93359 17.5331 3.20026 17.2664L10.8003 9.66641C11.2003 9.39974 11.2003 8.59974 10.6669 8.06641Z"
        fill="inherit"
      />
    </svg>
  );
};

export default RightArrow;
