import { VPagination, VTable, VEmptyState } from "components/common";
import { usePosRequestedHook } from "hooks";

const PosRequested = () => {
  const {
    extractedData,
    error,
    posRequestedColumns,
    fetching,
    pageNumber,
    handleChangePage,
  } = usePosRequestedHook();

  if (error)
    return (
      <VEmptyState
        title="Error Fetching Data!"
        subText="An error occur getting data"
      />
    );

  const { nodes: customerPos, pageInfo } = extractedData;

  return (
    <>
      <VTable
        isLoading={fetching}
        columns={posRequestedColumns}
        data={customerPos}
        collapsible
        useId
        emptyContent={{
          title: "No POS Request Yet!",
          subText: "Your history will appear here when you have requested one",
        }}
      />
      <VPagination
        pageInfo={pageInfo}
        pageNumber={pageNumber}
        getPageNumber={(pageNumber) => handleChangePage(pageNumber)}
      />
    </>
  );
};

export default PosRequested;
