import React from "react";

const NotFound = () => {
  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <h3>NotFound</h3>
    </div>
  );
};

export default NotFound;
