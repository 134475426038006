import { LoaderIcon } from "assets/icons";
import styled, { keyframes } from "styled-components";
import { stylingUtils } from "styles";

const {
  rem,
  mediaQuery: { down },
} = stylingUtils;

export const rotate = keyframes`
from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
`;

export const VStyledButtonLoader = styled(LoaderIcon)`
  animation: ${rotate} 2s linear infinite;
`;

export const StyledVButton = styled("button")<{
  textColor: string;
  width?: string;
  height: string;
  align: string;
  bgColor: string;
  event: string;
  noborder: boolean;
  isOutline: boolean;
  showOutline: boolean;
  isSlim: boolean;
  minWidth?: string;
  mobWidth?: string;
  useOutlineTextColor?: boolean;
  disabled?: boolean;
}>`
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 5px;
  flex: none;
  box-sizing: border-box;
  border-radius: ${({ isSlim }) => (isSlim ? "10px" : "16px")};
  color: ${({ textColor, isOutline, bgColor, useOutlineTextColor }) =>
    isOutline ? (useOutlineTextColor ? textColor : bgColor) : textColor};
  width: ${({ width }) => (width ? width : "fit-content")};
  min-width: ${({ minWidth }) => (minWidth ? minWidth : "fit-content")};
  height: ${({ height }) => (height ? height : " 40px")};
  outline: none;
  font-weight: 500;
  background: ${({ bgColor, isOutline, disabled }) =>
    isOutline
      ? "white"
      : disabled
      ? "#eee"
      : bgColor
      ? bgColor
      : "transparent"};
  cursor: pointer;
  pointer-events: ${({ event }) => event};
  transition: background-color 0.3s linear;
  align-self: ${({ align }) => align};
  box-shadow: ${({ noborder, isOutline }) =>
    noborder || isOutline ? "none" : "0px 4.62128px 18.4851px #3537510f"};
  padding: ${({ isSlim }) => (isSlim ? `0 ${rem(10)}` : "0 20px")};

  border: ${({ noborder, bgColor, disabled }) =>
    noborder ? "none" : disabled ? "1px solid #eee" : `1px solid ${bgColor}`};

  ${down("laptop")} {
    width: ${({ mobWidth }) => (mobWidth ? mobWidth : `fit-content`)};
  }

  /* box-shadow: 2px 5px 20px rgba(0, 119, 255, 0.5),
        8px 5px 20px rgba(0, 119, 255, 0.5); */

  svg path {
    /* stroke:${({ color }) => color}; */
  }

  &:disabled {
    background-color: ${({ isOutline }) => !isOutline && "#f0f5f8"};
    cursor: not-allowed;
    color: #a1a8b7;
    opacity: 0.9;

    &:hover {
      outline: 0;
    }
    /* svg path {
    stroke: #A1A8B7 || #fff;
    } */
  }

  &:hover {
    /* background: ${({ color }) => color};
    color:#fff; */
    outline: ${({
      bgColor,
      isSlim,
      isOutline,
      textColor,
      useOutlineTextColor,
    }) =>
      isSlim
        ? `1.5px solid ${useOutlineTextColor ? textColor : bgColor}`
        : `3px solid ${useOutlineTextColor ? textColor : bgColor}`};
    outline-offset: ${({ isSlim }) => (isSlim ? "2px" : "3px")};
    box-shadow: ${({ isOutline }) =>
      isOutline ? "none" : `5px 5px 20px rgba(0, 0, 0, 0.1)`};
    /* opacity: 0.8; */
  }
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
`;
