import styled from "styled-components/macro";
import { stylingUtils } from "styles";

const { color, rem } = stylingUtils;

export const Cards = styled("div")`
  margin: ${rem(20)} 0;
  position: relative;
  margin-top: 2rem;
`;
export const SliderArrow = styled("div")`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 40%;

  .arrow-btn {
    // top: 55%;
    position: absolute;
    // top: 50%;
    z-index: 1;
    background: rgba(0, 119, 255, 0.5);
    width: 40px;
    height: 40px;
    border-radius: 20px;

    &:hover {
      background: ${color("lightBlue")};
    }
  }
  .next {
    float: right;
    right: 2rem;
  }
  .prev {
    left: 2rem;
  }
`;
