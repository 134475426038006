import { gql, useQuery } from "urql";

export interface ITerminalSpecs {
  _id: string;
  name: string;
  variant: string;
  model: string;
  price: number;
  imageUrl: string;
  benefits: string;
  features: string;
}

const getTerminalSpecs = gql`
  query {
    getTerminalSpecs {
      nodes {
        _id
        name
        variant
        model
        price
        imageUrl
        benefits
        features
      }
    }
  }
`;

export const useGetTerminalSpecs = () => {
  return useQuery({
    query: getTerminalSpecs,
  });
};
