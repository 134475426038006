import { useLocalSearchParams, useModal } from "hooks";
import { useState, useCallback } from "react";
import { STAFF_TABLE_COLUMNS } from "components/staff/constants";
import { useAuthUser } from "store";
import { IBusinessStaff, useGetBusinessStaffs } from "api";
import { extractFetchedData } from "utils";
import { IBusinessStaffNode } from "api/staff";
import useStaffSummaryCardData from "./staffs/useStaffSummaryCardData";

export const useStaffPageHook = () => {
  const { open, toggleModal } = useModal();
  const { open: openDeleteModal, toggleModal: toggleDeleteModal } = useModal();

  const [selectedUser, setSelectedUser] = useState<IBusinessStaffNode | null>(
    null
  );

  const handleCloseModal = () => {
    setSelectedUser(null);
    toggleModal();
  };

  const handleEditUser = (user: IBusinessStaffNode) => {
    setSelectedUser(user);
    toggleModal();
  };
  const handleDeleteUserModal = (user: IBusinessStaffNode) => {
    setSelectedUser(user);
    toggleDeleteModal();
  };

  // Memoize the expense column
  const { searchParams, addSearchParams } = useLocalSearchParams();

  const [pageNumber, setPageNumber] = useState(
    parseInt(searchParams.get("page") || "1")
  );

  const status = searchParams.get("status");

  const { userData } = useAuthUser();

  const { cardData } = useStaffSummaryCardData();

  const [result, refetchBusinessStaffs] = useGetBusinessStaffs({
    businessId: userData?.businessId as string,
    page: pageNumber,
    ...(status &&
      status?.toLowerCase() !== "all" && {
        status: status || "",
      }),
  });

  const extract = extractFetchedData<IBusinessStaff>({
    result,
    key: "getBusinessStaffs",
  });

  const staffColumns = STAFF_TABLE_COLUMNS({
    handleEditUser,
    handleDeleteUserModal,
  });

  // Handle pagination change
  const handleChangePage = (pageNumber: number) => {
    setPageNumber(pageNumber);
  };

  const handleStatusFilter = useCallback((status: string) => {
    addSearchParams("status", status);
    addSearchParams("page", "1");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    staffTable: {
      ...extract,
      handleChangePage,
      pageNumber,
      staffColumns,
      status,
      handleStatusFilter,
      refetchBusinessStaffs,
      modal: {
        open,
        toggleModal,
        handleCloseModal,
        handleEditUser,
        selectedUser,
        openDeleteModal,
        toggleDeleteModal,
      },
    },
    cardData,
  };
};
