import { useUpdateUserPayment } from "api/business-registration/update-user-payment";
import { closePaymentModal, useFlutterwave } from "flutterwave-react-v3";
import useLocalSearchParams from "hooks/useLocalSearchParams";
import { useEffect } from "react";
import { getEnvKey } from "utils";

export type IBillingData = {
  tx_ref: string;
  amount: number;
  customer: {
    email: string;
    phone_number: string;
    name: string;
  };
};

export default function FluttewavePayment({
  billingData,
  callBack,
  openModal = false,
  toggleLoading,
  handleClosePayment,
  afterCallBack,
}: {
  billingData: IBillingData | null;
  callBack?: (response: any) => void;
  afterCallBack?: (response: any) => void;
  toggleLoading?: (value: boolean) => void;
  openModal: boolean;
  handleClosePayment: () => void;
}) {
  const { handleUpdateUserPayment, isLoading } = useUpdateUserPayment();

  const { addSearchParams } = useLocalSearchParams();

  const config = {
    public_key: getEnvKey("REACT_APP_FLUTTERWAVE_PUBLICK_KEY"),
    ...(billingData ? billingData : ({} as IBillingData)),
    currency: "NGN",
    payment_options: "card, ",
    customizations: {
      title: "Veedez",
      description: "Veedez",
      logo: "https://res.cloudinary.com/alerzo/image/upload/v1671120012/Veedez/veedez_lwkp58.svg",
    },
  };

  const handleFlutterPayment = useFlutterwave(config);

  useEffect(() => {
    if (billingData && openModal) {
      toggleLoading?.(true);
      handlePayment();
    } else {
      closePaymentModal();
      handleClosePayment?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billingData, openModal]);

  const handlePayment = () =>
    handleFlutterPayment({
      callback: async (response) => {
        const { status, transaction_id } = response;

        if (status?.includes("cancelled")) {
          handleClosePayment();
          closePaymentModal();
        }
        if (status?.includes("success") && !isLoading) {
          try {
            await handleUpdateUserPayment({
              transactionRef: transaction_id?.toString(),

              callBack: async (response) => {
                const canProceed = !!response?.updateUserPayment?.success;
                if (canProceed) {
                  const successResponse = await callBack?.(response);
                  closePaymentModal();
                  handleClosePayment();
                  addSearchParams("payment_successful", "true");
                  toggleLoading?.(false);

                  if (successResponse) {
                    await afterCallBack?.(response);
                  }
                }
              },
              erroCallback: () => {
                closePaymentModal();
                handleClosePayment();
                toggleLoading?.(false);
              },
            });
          } catch (e) {
            handleClosePayment?.();
          }
        }
      },
      onClose: () => {
        handleClosePayment?.();
        closePaymentModal();
        toggleLoading?.(false);
      },
    });

  return null;
}
