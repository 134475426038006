import { convertToNigeriaPhoneNumber } from "./../../utils/libs";
import { useUpdateuser } from "api";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useUserProfileHook } from "./useUserProfileHook";
import { useAuthUser } from "store";

export interface IEditUser {
  profilePicture: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  password: string;
}

export const useEditUserModalHook = (callback?: () => void) => {
  const hookForm = useForm<IEditUser>({});

  const { extractedData, fetching } = useUserProfileHook();

  const { userData } = useAuthUser();

  const { handleUpdateUser, isLoading } = useUpdateuser();

  const { handleSubmit, reset } = hookForm;
  const onSubmit = async (data: IEditUser) => {
    handleUpdateUser(
      {
        userId: userData?._id as string,
        phoneNumber: convertToNigeriaPhoneNumber(data.phoneNumber),
        profilePicture: data.profilePicture,
      },
      callback
    );
  };

  const handleFormSubmit = handleSubmit(onSubmit);

  useEffect(() => {
    if (extractedData && !fetching) {
      reset({
        profilePicture: extractedData?.profilePicture,
        fullName: extractedData.fullName,
        email: extractedData.email,
        phoneNumber: extractedData?.phoneNumber?.replace("+234", ""),
        password: extractedData.password,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetching]);

  return {
    isUpdatingUser: isLoading,
    handleFormSubmit,
    hookForm,
  };
};
