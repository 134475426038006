import { useLocalSearchParams } from "hooks";
import { addAllToOptionsList } from "utils/libs";
import { INVENTORY_PRODUCT_COLUMNS } from "components/Inventory/constants";
import {
  ICustomerCategories,
  ICustomerProducts,
  INVENTORY_TYPES,
  useCheckInvoiceOrSales,
  InvoiceSaleCheckType,
  useGetCustomerCategories,
  useGetCustomerProduct,
} from "api";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";
import { extractFetchedData } from "utils";
import { useAuthUser } from "store";
import { TProduct } from "components/Inventory/types";
import { useParams } from "react-router-dom";

export const useProductPageHook = ({
  pauseCategory = false,
}: {
  pauseCategory?: boolean;
}) => {
  const { searchParams, addSearchParams } = useLocalSearchParams();
  const [pageNumber, setPageNumber] = useState(
    parseInt(searchParams.get("page") || "1")
  );
  const selectedProductCategory = searchParams.get("category");

  const { productId } = useParams();

  const { userData } = useAuthUser();

  const [productCategoriesResult, refetchProductCategories] =
    useGetCustomerCategories({
      businessId: userData?.businessId as string,
      inventoryType: INVENTORY_TYPES.PRODUCT,
      pause: pauseCategory,
      size: -1,
    });

  const [result, refetchCustomerProducts] = useGetCustomerProduct({
    businessId: userData?.businessId as string,
    size: 10,
    page: pageNumber,
    ...(selectedProductCategory &&
      selectedProductCategory?.toLowerCase() !== "all" && {
        categoryId: selectedProductCategory || "",
      }),
  });

  const productCategories = useMemo(() => {
    if (productCategoriesResult.data) {
      const { extractedData } = extractFetchedData<ICustomerCategories>({
        key: "getCategories",
        result: productCategoriesResult,
      });
      const options = (extractedData?.nodes || []).map((data) => ({
        value: data?._id,
        label: data?.name,
        key: data?._id,
      }));

      return addAllToOptionsList(options);
    } else return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productCategoriesResult.fetching, productCategoriesResult.data]);

  const extract = extractFetchedData<ICustomerProducts>({
    result,
    key: "getCustomerProducts",
  });

  // Handle pagination change
  const handleChangePage = (pageNumber: number) => {
    setPageNumber(pageNumber);
  };

  const inventoryProductColumns = useMemo(() => {
    const fetchTableColumns = (
      setSelectedProduct: Dispatch<SetStateAction<TProduct>>,
      toggleEditFormModal: () => void
    ) => {
      return INVENTORY_PRODUCT_COLUMNS(setSelectedProduct, toggleEditFormModal);
    };
    return fetchTableColumns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCategoryFilter = useCallback((categoryId: string) => {
    addSearchParams("category", categoryId);
    addSearchParams("page", "1");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // check if there are invoices or sales associated with the product
  const [{ data, fetching }] = useCheckInvoiceOrSales({
    type: InvoiceSaleCheckType.INVENTORY,
    productId: productId,
  });

  const hasInvoiceOrSales = data?.hasInvoiceOrSales;

  const bigScreen = window.innerWidth >= 700;

  return {
    ...extract,
    pageNumber,
    handleChangePage,
    refetchCustomerProducts,
    inventoryProductColumns,
    productCategories,
    handleCategoryFilter,
    selectedProductCategory,
    refetchProductCategories,
    hasInvoiceOrSales,
    fetching,
    bigScreen,
  };
};
