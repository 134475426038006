import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { IRootState } from "store/store";

export type DashboardTabProps = {
  hasClosedWelcomeTabs: boolean;
};

const initialState: DashboardTabProps = {
  hasClosedWelcomeTabs: true,
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialState,
  reducers: {
    closeWelcomeTab: (state) => {
      state.hasClosedWelcomeTabs = true;
    },
    openWelcomeTab: (state) => {
      state.hasClosedWelcomeTabs = false;
    },
  },
});

const { closeWelcomeTab, openWelcomeTab } = dashboardSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
const useDashboardSlice = () =>
  useSelector((state: IRootState) => state.dashboard);

const dashboardReducer = dashboardSlice.reducer;
export { useDashboardSlice, closeWelcomeTab, dashboardReducer, openWelcomeTab };
