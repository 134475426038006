import { gql, useMutation } from "urql";
import { handleRequestCall } from "utils/handleRequestCall";

export const useUpgradeBusinessPlan = () => {
  const [hasUpgradeBusinessPlan, upgradeBusinessPlan] = useMutation(
    upgradeBusinessPlanMutation
  );

  const handleUpgradeBusinessPlan = async ({
    data,
    callBack,
    erroCallback,
  }: {
    data: {
      businessPlan: string;
      businessId: string;
    };
    callBack?: (data: any) => void;
    erroCallback?: (data: any) => void;
  }) => {
    try {
      const req = await upgradeBusinessPlan({ ...data });

      await handleRequestCall({
        request: req,
        callBack,
        erroCallback,
      });
    } catch (e) {}
  };

  return {
    handleUpgradeBusinessPlan,
    isLoading: hasUpgradeBusinessPlan?.fetching,
  };
};

const upgradeBusinessPlanMutation = gql`
  mutation ($businessPlan: String, $businessId: String) {
    upgradeBusinessPlan(businessPlan: $businessPlan, businessId: $businessId)
  }
`;
