import { VButton, VModal } from "components/common";
import { VModalProps } from "components/common/types";
import { StyledStaffDeleteWrapper } from "components/staff/styles";
import theme from "styles/theme";

export const DeleteInvoiceModal = ({
  open,
  close,
  handleDeleteInovice,
  isDeleteingInvoice,
}: VModalProps & {
  handleDeleteInovice: VoidFunction;
  isDeleteingInvoice: boolean;
}) => {
  return (
    <VModal
      alignModalTitle="center"
      open={open}
      close={close}
      title="Delete Invoice"
      width={600}
    >
      <StyledStaffDeleteWrapper>
        <p className="description">
          Are you sure you want to delete this invoice?
        </p>
        <div className="button-group">
          <VButton
            text="No, Don't Delete"
            onClick={close}
            style={{
              borderRadius: "5px",
            }}
            minWidth="150px"
            isOutline
            noborder={false}
          />
          <VButton
            bgColor={theme.colors.toastRed}
            text="Yes, Delete"
            style={{
              borderRadius: "5px",
            }}
            minWidth="150px"
            isLoading={isDeleteingInvoice}
            onClick={handleDeleteInovice}
          />
        </div>
      </StyledStaffDeleteWrapper>
    </VModal>
  );
};
