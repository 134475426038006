import { VTable } from "components/common";
import { useRolesAndPermissionSelectionHook } from "hooks";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "routes";
import { ROLES_AND_PERMISSION_COLUMNS } from "../../constants";
import { StyledRolesAndPermissionTable } from "./styles";

export const RolesAndPermissionTable = () => {
  const navigate = useNavigate();
  const { allRoles } = useRolesAndPermissionSelectionHook();

  return (
    <StyledRolesAndPermissionTable>
      <VTable
        handleClick={(row) => {
          navigate(
            `${APP_ROUTES.ROLES_AND_PERMISSIONS}/${row?.roleName}/${row?._id}`
          );
        }}
        transparentHeader
        columns={ROLES_AND_PERMISSION_COLUMNS}
        data={allRoles}
      />
    </StyledRolesAndPermissionTable>
  );
};
