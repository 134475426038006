import { useMemo } from "react";
import { useAuthUser } from "store";
import {
  useGetBanks,
  IBanks,
  useGetBusinessPayments,
  useAddBusinessPayment,
  useValidateBankInfo,
} from "api";
import { ADD_PAYMENT_INPUT_LIST } from "pages/invoices-page/create-invoice/constants";

export const useInvoiceBankHook = (bank?: string) => {
  const { userData } = useAuthUser();

  const [bankNameList] = useGetBanks();

  const [paymentList, refetchBusinessPayments] = useGetBusinessPayments({
    businessId: userData?.businessId as string,
  });

  const bankDetailsList = paymentList?.data?.getBusinessPayments;

  const { handleAddInvoicePayment } = useAddBusinessPayment();

  // add new invoice payment details
  const submitInvoicePayment = async (paymentData: any) => {
    await handleAddInvoicePayment({ ...paymentData });
    refetchBusinessPayments();
  };

  // populate bank name dropdown
  const {
    data: bankData,
    fetching: fetchingBank,
    error: bankError,
  } = bankNameList;

  const SELECT_BANKS_OPTIONS = useMemo(() => {
    return !fetchingBank && !bankError
      ? bankData?.getBanks?.nodes?.map(({ bankName, bankCode }: IBanks) => {
          return { value: bankName, label: bankName, bankCode };
        })
      : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankData?.getPaymentBanks, bankError, fetchingBank]);

  const bankInputList = useMemo(() => {
    return ADD_PAYMENT_INPUT_LIST({
      SELECT_BANKS_OPTIONS,
      bank,
    });
  }, [SELECT_BANKS_OPTIONS, bank]);

  const selectedBankCode = !!bank
    ? String(SELECT_BANKS_OPTIONS?.find((b: any) => b.value === bank)?.bankCode)
    : "";

  // bank validation
  const { validatedBankInfo, handleValidateBankInfo } = useValidateBankInfo();

  const {
    fetching: validating,
    data: validatedData,
    error: validationError,
  } = validatedBankInfo;

  return {
    SELECT_BANKS_OPTIONS,
    fetchingBank,
    bankDetailsList,
    submitInvoicePayment,
    refetchBusinessPayments,
    bankInputList,
    selectedBankCode,
    validating,
    validatedData,
    validationError,
    handleValidateBankInfo,
  };
};
