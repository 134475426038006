import { gql, useQuery } from "urql";
import { TProduct } from "components/Inventory/types";
import { formatNumberToCurrency } from "utils";
import { ProductDetail, ProductTableData } from "api/inventory/types";

export const useGetProductDetail = ({
  customerProductId,
}: {
  customerProductId: string;
}) => {
  return useQuery({
    query: getProductDetail,
    variables: { customerProductId },
  });
};

const getProductDetail = gql`
  query ($customerProductId: String!) {
    getCustomerProduct(customerProductId: $customerProductId) {
      _id
      productName
      sellingPrice
      productImage
      totalStock
      batches {
        purchasePrice
        availableStock
        productId
        updatedAt
      }
      category {
        name
      }
      referenceId
      createdAt
      thresholdLowStock
    }
  }
`;

export const mapFromProductDetailAPI = (data: ProductDetail): TProduct => {
  return {
    productID: data?.getCustomerProduct?._id,
    productName: data?.getCustomerProduct?.productName,
    category: data?.getCustomerProduct?.category?.name,
    costPrice: formatNumberToCurrency({
      number:
        data?.getCustomerProduct?.batches[
          data?.getCustomerProduct.batches.length - 1
        ]?.purchasePrice,
    }),
    sellingPrice: formatNumberToCurrency({
      number: data?.getCustomerProduct?.sellingPrice,
    }),
    quantity: data?.getCustomerProduct?.totalStock.toString(),
    thresholdLowStock: data?.getCustomerProduct?.thresholdLowStock?.toString(),
    productImage: data?.getCustomerProduct?.productImage,
    dateTime: new Date(
      data?.getCustomerProduct?.createdAt
    ).toLocaleDateString(),
    referenceId: data?.getCustomerProduct?.referenceId,
  };
};

export const mapFromTableData = (data: ProductTableData): TProduct => {
  return {
    productID: data?._id,
    productName: data?.productName,
    category: data?.category?.name,
    costPrice:
      data?.batches[data.batches.length - 1]?.purchasePrice?.toString(),
    sellingPrice: data?.sellingPrice,
    quantity: data?.totalStock?.toString(),
    thresholdLowStock: data?.thresholdLowStock?.toString(),
    productImage: data?.productImage,
    referenceId: data?.referenceId,
  };
};
