// Core
import { gql, useMutation } from "urql";

// Custom Components
import { showToast } from "components/common";

// Utils
import { handleRequestCall } from "utils/handleRequestCall";
// import transaction listing hooks here when api available

export type IInitiateBankTransfer = {
  bankName: string;
  bankCode: string;
  accountName: string;
  accountNumber: string;
  amount: number;
  remark: string;
  saveForLater: boolean;
};

// GraphQL
const initiateBankTransferMutation = gql`
  mutation (
    $accountName: String
    $accountNumber: String
    $amount: Int
    $bankCode: String
    $bankName: String
    $remark: String
    $saveForLater: Boolean
  ) {
    initiateBankTransfer(
      accountName: $accountName
      accountNumber: $accountNumber
      amount: $amount
      bankCode: $bankCode
      bankName: $bankName
      remark: $remark
      saveForLater: $saveForLater
    ) {
      success
      data
    }
  }
`;

// Functionality
export const useInitiateBankTransfer = () => {
  const [bankTransfer, initiateBankTransfer] = useMutation(
    initiateBankTransferMutation
  );

  // destructure refetch transaction listings here when api is ready

  const handleInitiateBankTransfer = async (
    data: IInitiateBankTransfer,
    callBack?: () => void
  ) => {
    try {
      const req = await initiateBankTransfer(data);
      // call refetch tranlisting here
      await handleRequestCall({ request: req, callBack });
    } catch (e) {
      console.error(e);
      showToast({
        type: "error",
        title: "An error occurred",
        subText: "Please try again",
      });
    }
  };

  return {
    handleInitiateBankTransfer,
    bankTransfer,
  };
};
