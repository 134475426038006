import styled from "styled-components";
import { FlexJAMixin, stylingUtils } from "styles";

const { rem, color } = stylingUtils;

export const StyledCreateCategoryForm = styled("div")``;

export const StyledCreateCategory = styled("div")`
  .form--button {
    width: 100%;
    margin: ${rem(30)} 0;
    ${FlexJAMixin}
  }
`;
export const AddCategoryButton = styled("div")`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  gap: ${rem(7)};

  .add_icon {
    background-color: ${color("lightBlue")};
    padding: ${rem(10)};
    width: ${rem(25)};
    height: ${rem(25)};
    display: grid;
    place-content: center;
    border-radius: 100%;
  }
  h4 {
    font-family: "Gilmer";
    font-weight: 500;
    font-size: ${rem(15)};
    line-height: ${rem(19)};
    color: ${color("lightBlue")};
  }
`;
export const InputGroup = styled("div")`
  display: grid;
  grid-template-columns: 12fr 1fr;
  align-items: center;
  gap: ${rem(7)};
  margin-bottom: ${rem(17)};
  margin-top: ${rem(8)};

  .delete_icon {
    width: ${rem(50)};
    height: ${rem(25)};
    border: 1px solid rgba(220, 227, 231, 0.5);
    border-radius: ${rem(10)};
    display: grid;
    place-content: center;
    padding: ${rem(20)};
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      transform: scale(1.01);
    }
  }
`;

export const StyledInput = styled("input")`
  position: relative;
  color: ${color("ashText")};
  font-size: ${rem(12)};
  padding: ${rem(10)} ${rem(12)};
  height: ${rem(38.5)}!important;
  border-radius: 12px;
  border: 1px solid ${color("inputBorder")};
  width: 100%;
  background-color: white;
  transition: 0.5s ease;

  &:hover {
    border: 1px solid ${color("inputBorderHover")};
  }

  &:focus {
    outline: 3px solid ${color("lightBlue")};
  }

  &:disabled {
    background-color: ${color("ash01")};
    cursor: not-allowed;
    opacity: 0.5;
  }

  &[type="file"] {
    opacity: 0; /* make transparent */
    z-index: -1; /* move under anything else */
    position: absolute; /* don't let it take up space */
  }
  &[type="file"]:focus + label {
    outline: 2px solid; /* example focus style */
  }
`;
