import React from "react";

const FileIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 9 11"
      {...props}
    >
      <path
        d="M5.99999 0.00165001V0H0.500999C0.225 0 0 0.25025 0 0.5456V10.4544C0.000132561 10.5992 0.0525007 10.7379 0.145598 10.8402C0.238695 10.9425 0.364906 11 0.496499 11H8.50349C8.56915 10.9995 8.63408 10.9848 8.69457 10.9567C8.75505 10.9286 8.80992 10.8876 8.85602 10.8362C8.90212 10.7848 8.93857 10.7238 8.96327 10.6569C8.98797 10.59 9.00045 10.5184 8.99999 10.4461V3.3L5.99999 0.00165001ZM7.58499 3.3H5.99999V1.5565L7.58499 3.3ZM4.99999 1.1V3.85C4.99999 3.99587 5.05267 4.13576 5.14644 4.23891C5.24021 4.34205 5.36738 4.4 5.49999 4.4H7.99999V9.9H0.999999V1.1H4.99999Z"
        fill="#0077FF"
      />
    </svg>
  );
};

export default FileIcon;
