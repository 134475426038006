import styled from "styled-components/macro";
import { stylingUtils } from "styles";

const {
  rem,
  color,
  mediaQuery: { down },
} = stylingUtils;

const INVENTORY = ["products", "services"];

export const StyledVChipsContainer = styled("div")<{
  currentPage?: string;
  pageTitle?: string;
  noBackgroundColor?: boolean;
}>`
  background-color: ${({ noBackgroundColor }) =>
    noBackgroundColor ? "transparent" : "white"}}
  backdrop-filter: blur(4px);
  display: flex;
  align-items: stretch;
  border-radius: 12px;
  width: 100%;
  gap: ${rem(8)};
  position: relative;
  padding: ${rem(0)} ${rem(10)};
  flex-wrap: wrap;
  align-items: center, @media (max-width: 500px) {
    flex-direction: ${({ currentPage }) =>
      currentPage === "expense" || currentPage === "invoices"
        ? "column"
        : "row"};
    overflow-x: ${({ currentPage, pageTitle }) =>
      currentPage === "report" || pageTitle === "report"
        ? "visible"
        : currentPage === "expense" || currentPage === "invoices"
        ? "hidden"
        : "scroll"};
    & .flex__child {
      display: ${({ currentPage, pageTitle }) =>
        INVENTORY.includes(currentPage as string) ||
        pageTitle === "report" ||
        currentPage === "report"
          ? "none"
          : "flex"};
      overflow-x: ${({ currentPage }) =>
        currentPage === "expense" ? "scroll" : "auto"};
      width: ${({ currentPage }) =>
        currentPage === "expense" || currentPage === "invoices"
          ? "100%"
          : "fit-content"};
    }

    &:last-child {
      display: ${({ currentPage }) =>
        currentPage === "account" ? "none" : "flex"};
    }

    & .action-buttons {
      width: ${({ currentPage, pageTitle }) =>
        INVENTORY.includes(currentPage as string) ||
        pageTitle === "report" ||
        currentPage === "report"
          ? "100%"
          : ""};
      justify-content: ${({ currentPage, pageTitle }) =>
        INVENTORY.includes(currentPage as string)
          ? "space-around"
          : pageTitle === "report" || currentPage === "report"
          ? "space-between"
          : ""};
      align-self: ${({ currentPage }) =>
        (currentPage === "expense" || currentPage === "invoices") &&
        "flex-end"};
    }
  }
`;

export const StyledVChipContainerChildren = styled("div")`
  gap: ${rem(6)};
  display: flex;
  align-items: stretch;
  padding: ${rem(10)} ${rem(0)};
  flex: none;
  width: fit-content;

  &.flex__child {
    overflow-x: auto;
    flex: 1;
    ${down("laptop")} {
      min-width: 10rem;
    }
  }

  // @media (max-width: 600px) {
  //   padding: 1rem;
  // }
`;

export const StyledVChip = styled("button")<{
  isActive: boolean;
  isSlim: boolean;
}>`
  color: ${({ isActive }) => (isActive ? color("lightBlue") : color("ash03"))};
  border: ${({ isActive }) =>
    !isActive ? "1px solid #f6f7ff" : "1px solid transparent"};
  background-color: ${({ isActive }) =>
    isActive ? color("transparentBlue") : "transparent"};
  padding: ${({ isSlim }) =>
    isSlim ? `${rem(2)} ${rem(10)}` : `${rem(8)} ${rem(20)}`};
  border-radius: 20px;
  font-size: ${rem(12)};
  transition: 0.5s all ease-in-out;
  font-weight: 500;
  white-space: nowrap;

  &:hover {
    background-color: ${({ isActive }) =>
      !isActive && color("transparentBlue")};
    color: ${color("lightBlue")};
    border: 1px solid transparent;
  }

  &.success {
    background-color: ${color("lightGreen")};
    color: ${color("green")};
  }
`;

export const StyledVChipVerticalDivider = styled("div")`
  border-left: 1px solid ${color("ash01")};
  min-height: 100%;

  @media (max-width: 600px) {
    display: none;
  }
`;

export const Transactionbutton = styled("div")`
  @media (max-width: 600px) {
    display: none;
  }
`;

export const Row = styled("div")`
  display: flex;
  justify-content: space-between;
  width: 98%;
  align-items: center;
`;

export const StyledTransactionButton = styled("button")``;
