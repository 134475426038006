import { useSearchParams } from "react-router-dom";

const useLocalSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const addSearchParams = (key: string, value: string) => {
    searchParams.set(key, value);
    setSearchParams(searchParams);
  };

  const clearSearchParams = () => {
    let search = undefined;
    setSearchParams(search, { replace: true });
  };

  return {
    searchParams,
    addSearchParams,
    clearSearchParams,
  };
};

export default useLocalSearchParams;
