// Core
import { gql, useMutation } from "urql";

// Custom Components
import { showToast } from "components/common";

// Assets
import { handleRequestCall } from "utils/handleRequestCall";

// GraphQL
const validateTransactionPinMutation = gql`
  mutation ($pin: String) {
    validateTransactionPin(pin: $pin) {
      success
      data
    }
  }
`;

// Functionality
export const useValidateTransactionPin = () => {
  const [validatedTransactionPin, getvalidatedTransactionPin] = useMutation(
    validateTransactionPinMutation
  );

  const handleValidateTransactionPin = async (
    variables: { pin: string },
    callBack?: (data: Record<string, any>) => void
  ) => {
    try {
      const req = await getvalidatedTransactionPin(variables);

      await handleRequestCall({ request: req, callBack });
    } catch (e) {
      showToast({
        type: "error",
        title: "An error ocucured",
        subText: "Please try again",
      });
      console.error(e);
    }
  };

  return {
    validatedTransactionPin,
    handleValidateTransactionPin,
  };
};
