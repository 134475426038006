import LockIcon from "assets/images/svgs/LockIcon";
import { VButton, VInput } from "components/common";
import { formatNumberToCurrency } from "utils";
import { BillingCheckoutCard } from "./billing-checkout-card";
import {
  StyledOrderSummaryItem,
  StyledOrderSummaryList,
  StyledOrderSummaryListContainer,
  StyledOrderSummarySecuredPayment,
} from "./styles";
import { BillingDetailsProps } from "./types";

const PlanDetailsMapper = ({
  planSummary,
  billingDetails,
}: {
  planSummary: Record<string, any>;
  billingDetails: Record<string, any>;
}) => {
  const { planName = "Basic", symbol = "$" } = billingDetails;

  return (
    <>
      {planSummary.map((summary: Record<string, any>) => {
        let { label, value, key, labelFormatter, isAmount } = summary;

        if (key) {
          value = (billingDetails as Record<string, any>)[key];
        }

        if (isAmount) {
          value = `${symbol} ${formatNumberToCurrency({
            number: Number(value),
            removeCurrency: true,
          })}`;
        }

        if (labelFormatter) {
          label = labelFormatter(planName);
        }

        return (
          <StyledOrderSummaryItem key={label}>
            <p>{label}</p>
            <p>{value}</p>
          </StyledOrderSummaryItem>
        );
      })}
    </>
  );
};

export const OrderSummary = ({
  isSubmitting,
  isDisabled,
  handleCheckout,
  billingDetails,
}: {
  isSubmitting: boolean;
  isDisabled: boolean;
  handleCheckout: () => void;
  billingDetails: BillingDetailsProps;
}) => {
  const { symbol = "$", planBilling = "0" } = billingDetails;

  const total = `${symbol} ${formatNumberToCurrency({
    number: Number(planBilling),
    removeCurrency: true,
  })}`;

  return (
    <BillingCheckoutCard title={"Order Summary"}>
      <StyledOrderSummaryListContainer>
        <StyledOrderSummaryList>
          <PlanDetailsMapper
            billingDetails={billingDetails}
            planSummary={PLAN_SUMMARY_ONE}
          />
          <VInput
            name="Discount Code"
            placeholder="Discount code"
            formInputStyle={{
              paddingBottom: "0px",
              height: "40px",
              marginTop: "20px",
            }}
          />
        </StyledOrderSummaryList>
        <StyledOrderSummaryList>
          <PlanDetailsMapper
            billingDetails={billingDetails}
            planSummary={PLAN_SUMMARY_TWO}
          />
        </StyledOrderSummaryList>
        <StyledOrderSummaryList>
          <StyledOrderSummaryItem>
            <h4>Total amount:</h4>
            <h1>{total}</h1>
          </StyledOrderSummaryItem>
        </StyledOrderSummaryList>
        <div>
          <VButton
            onClick={handleCheckout}
            text={`Pay ${total} now`}
            width="100%"
            isLoading={isSubmitting}
            disabled={isDisabled}
          />
          <StyledOrderSummarySecuredPayment>
            <LockIcon height={20} />
            <span>Payments are secure</span>
          </StyledOrderSummarySecuredPayment>
        </div>
      </StyledOrderSummaryListContainer>
    </BillingCheckoutCard>
  );
};

export const PLAN_SUMMARY_ONE = [
  {
    label: "Your Plan: ",
    value: "₦9.00",
    key: "planBilling",
    labelFormatter: (value: string) => `Your Plan: ${value}`,
    isAmount: true,
  },
  {
    label: "Period",
    value: "Monthly",
  },
];
export const PLAN_SUMMARY_TWO = [
  {
    label: "Subtotal",
    value: "₦108.00",
    key: "subtotal",
    isAmount: true,
  },
  {
    label: "Discount",
    value: "0.00",
    key: "discount",
    isAmount: true,
  },
];
