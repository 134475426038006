import { IPagination } from "./../types";
import { gql, useQuery } from "urql";

type UseGetBusinessSectorsProps = {
  size?: number;
};

export const useGetBusinessSectors = ({
  size = 50,
}: UseGetBusinessSectorsProps) => {
  return useQuery({
    query: getBusinessSecotrQuery,
    variables: { size },
  });
};

const getBusinessSecotrQuery = gql`
  query ($size: Int) {
    getBusinessSectors(metadata: { size: $size }) {
      nodes {
        _id
        sectorName
        createdAt
      }
    }
  }
`;

export interface IBusinessSectors extends IPagination {
  nodes: {
    _id: string;
    sectorName: string;
    createdAt: number;
  }[];
}
