const TodaySalesIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 1 24 22"
      {...props}
    >
      <path
        d="M0 7.80088H24V9.24088H22.9949L21.7834 19.876C21.6673 20.7397 21.2424 21.532 20.5871 22.1065C19.9319 22.681 19.0908 22.9987 18.2194 23.0008H5.78064C4.90927 22.9986 4.06818 22.6809 3.413 22.1064C2.75782 21.532 2.33291 20.7396 2.21688 19.876L1.0056 9.24064H0V7.80088ZM3.64464 19.6876C3.71414 20.2053 3.9688 20.6802 4.36149 21.0246C4.75419 21.369 5.25833 21.5595 5.78064 21.5608H18.2194C18.7417 21.5595 19.2459 21.3691 19.6386 21.0247C20.0313 20.6803 20.2859 20.2053 20.3554 19.6876L21.5422 9.24064H2.45784L3.64464 19.6876ZM18.2088 2.44H5.7912L4.848 6.8416H3.3744L4.6272 1H19.3728L20.6256 6.8416H19.152L18.2088 2.44Z"
        fill={props.fill || "#34A853"}
      />
      <path
        d="M11.2154 18.1297L8.34473 15.2588L9.36281 14.2408L11.2154 16.0933L14.637 12.6719L15.6551 13.69L11.2154 18.1297Z"
        fill={props.fill || "#34A853"}
      />
    </svg>
  );
};

export default TodaySalesIcon;
