import { FlexColumn } from "./../../../styles/global";
import styled from "styled-components";
import { stylingUtils } from "styles";

const { rem } = stylingUtils;

export const StyledTotalSalesGraphWrapper = styled("div")<{
  shouldBeFirst: boolean;
}>`
  order: ${({ shouldBeFirst }) => (shouldBeFirst ? "1!important" : "4")};
  flex: ${({ shouldBeFirst }) => shouldBeFirst && "auto!important"};
  width: ${({ shouldBeFirst }) => !shouldBeFirst && "100%"};
`;

export const StyledTotalSalesGraph = styled("section")<{ height: number }>`
  min-height: ${({ height }) => rem(height)};
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;

  &.paddingTop {
    padding-top: ${rem(20)};
  }

  &.flex-pie,
  .flex-pie {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: ${rem(300)};
  }
`;

export const StyledChartTooltipWrapper = styled("div")`
  background-color: white;
  padding: ${rem(20)};
  border-radius: ${rem(10)};
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  ${FlexColumn};
  gap: ${rem(6)};
  cursor: pointer;

  .label {
    color: black;
  }
`;
