import { toast } from "react-toastify";
import ToastSuccessIcon from "assets/icons/success-icon.svg";
import ToastErrorIcon from "assets/icons/error-icon.svg";
import ToastDeleteIcon from "assets/icons/delete-icon.svg";
import { ReactComponent as ToastCloseButton } from "assets/icons/toast-close-icon.svg";
import {
  StyledVToastActionText,
  StyledVToastContainer,
  StyledVToastSubText,
  StyledVToastTitle,
} from "./styles";
import { VToastProps } from "../types";

const toastIcons = {
  success: ToastSuccessIcon,
  delete: ToastDeleteIcon,
  error: ToastErrorIcon,
};

const Toastcomponent = ({
  title = "",
  subText = "",
  type = "success",
  actionText,
  action,
}: VToastProps) => {
  const isSuccess = type === "success";
  const isDeleted = type === "delete";

  const newSubText = subText
    ? subText
    : isSuccess
    ? "Action Sucessful"
    : "Action not successful, please try again";

  const newTitle = title
    ? title
    : isSuccess
    ? "Successful!"
    : isDeleted
    ? "Deleted"
    : "Error Alert!";

  return (
    <div>
      <StyledVToastTitle toastType={type}>{newTitle}</StyledVToastTitle>
      <StyledVToastContainer>
        <StyledVToastSubText toastType={type}>{newSubText}</StyledVToastSubText>
        {actionText && (
          <StyledVToastActionText toastType={type} onClick={action}>
            {actionText}
          </StyledVToastActionText>
        )}
      </StyledVToastContainer>
    </div>
  );
};

export const showToast = ({
  title,
  subText,
  type = "success",
  actionText,
  action,
}: VToastProps) => {
  // Map 'delete' type to 'warning' type
  const toastType = type === "delete" ? "warning" : type;
  const IconComponent = toastIcons[type];

  return toast(
    <Toastcomponent
      title={title}
      subText={subText}
      type={type}
      actionText={actionText}
      action={action}
    />,
    {
      type: toastType,
      icon: () => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "50px",
            height: "50px",
          }}
        >
          <img
            src={IconComponent}
            alt={type}
            style={{
              width: "40px",
              height: "40px",
            }}
          />
        </div>
      ),
      closeButton: () => (
        <ToastCloseButton
          width={25}
          height={25}
          className="Toastify__close-button"
        />
      ),
    }
  );
};
