import EditPenIcon from "assets/images/svgs/EditPenIcon";
import { VButton } from "components/common";
import { CUSTOMER_DETAILS_HEADER } from "../constants";
import {
  StyledStaffDetailsCardHeaderWrapper,
  StyledStaffDataWrapper,
  StyledStaffData,
  StyledStaffName,
  StyledStaffDivider,
  StyledStaffDetailsHeaderAction,
  StyledStaffLabelAndValue,
} from "components/staff/styles";
import { StyledCustomerInfo, User } from "./styles";
import { capitalizeWord, truncateWord } from "utils";
import { IDetails, IKeys } from "./types";
import DeleteIcon from "assets/icons/delete";

const CustomerDetailsHeader = ({
  customerName,
  customerDetails,
  customerStatus,
  handleOpenEditModal,
  handleOpenDeleteConfirmModal,
  hasInvoiceOrSales,
  isCheckingDeleteStatus,
}: IDetails) => {
  const renderStaffDetails = ({ key, formatter, label, className }: IKeys) => {
    let value =
      ({ ...customerDetails, status: customerStatus } as Record<string, any>)[
        key
      ] ?? "N/A";

    const canFormat = formatter && value && value !== "N/A";

    if (canFormat) {
      value = formatter(value);
    }

    return (
      <StyledStaffLabelAndValue key={key}>
        <h3 className={className}>{value ?? "N/A"}</h3>
        <p>{label}</p>
      </StyledStaffLabelAndValue>
    );
  };

  return (
    <StyledStaffDetailsCardHeaderWrapper>
      <StyledCustomerInfo customerStatus={customerStatus}>
        <User>
          <div className="name_placeholder">
            <h3>{!!customerName ? capitalizeWord(customerName[0]) : "N/A"}</h3>
            <div className="dot"></div>
          </div>
          <div className="MobileStaffName">
            <span>{truncateWord(customerName, { length: 20 }) || "N/A"}</span>
          </div>
        </User>

        <StyledStaffDataWrapper>
          <StyledStaffData>
            <StyledStaffName>
              <span>{truncateWord(customerName, { length: 20 }) || "N/A"}</span>
            </StyledStaffName>
            {CUSTOMER_DETAILS_HEADER.TOP.map((data) =>
              renderStaffDetails(data)
            )}
          </StyledStaffData>
          <StyledStaffDivider />
          <StyledStaffData>
            {CUSTOMER_DETAILS_HEADER.BOTTOM.map((data) =>
              renderStaffDetails(data)
            )}
            <div />
          </StyledStaffData>
        </StyledStaffDataWrapper>
      </StyledCustomerInfo>

      <StyledStaffDetailsHeaderAction>
        <VButton
          isOutline
          isSlim
          onClick={handleOpenEditModal}
          noborder={false}
          height="45px"
          width="155px"
          text="Edit Customer"
          icon={<EditPenIcon height={17} width={17} stroke="#0077FF" />}
        />
        {!isCheckingDeleteStatus && !hasInvoiceOrSales && (
          <VButton
            isOutline
            isSlim
            onClick={handleOpenDeleteConfirmModal}
            noborder={true}
            bgColor="#EA4336"
            height="45px"
            width="155px"
            text="Delete Customer"
            icon={<DeleteIcon />}
          />
        )}
      </StyledStaffDetailsHeaderAction>
      <StyledStaffDivider />
    </StyledStaffDetailsCardHeaderWrapper>
  );
};

export default CustomerDetailsHeader;
