import styled from "styled-components/macro";
import { stylingUtils } from "styles";

const {
  rem,
  color,
  mediaQuery: { down },
} = stylingUtils;

export const StyledVTableCardsWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: ${rem(20)};
  background-color: white;
  padding: ${rem(20)} ${rem(16)};
  border-radius: ${rem(10)};
`;

export const StyledVTableCard = styled("div")`
  border: 1px solid #eeeeee;
  border-radius: ${rem(10)};
  padding: ${rem(15)};
  background-color: white;

  .card-element {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: ${rem(20)} ${rem(10)};
    /* flex-direction: column; */
    /* gap: ${rem(7)}; */

    ${down("mobileM")} {
      grid-template-columns: 1fr 1fr;
    }

    ${down("mobileL")} {
      grid-template-columns: 1fr 1fr;
    }
  }
`;

export const StyledVTableCardsElement = styled("div")`
  display: flex;
  flex-direction: column;
  gap: ${rem(20)};

  &.col-span-full {
    width: 100%;
    grid-column: 1 / -1 !important;
    text-align: left !important;

    button {
      text-align: left;
    }
  }

  .grid-action {
    display: grid;
    width: 100%;
    gap: ${rem(20)} ${rem(10)};

    grid-template-columns: 1fr 1fr 1fr;

    ${down("mobileM")} {
      grid-template-columns: 1fr 1fr;
    }
  }

  .vtable-card {
    &-label {
      color: ${color("ashText")};
    }
    &-value {
      color: black;
      font-weight: 400;
      flex: auto;
      display: flex;
      align-items: center;
    }
  }
`;
