import styled from "styled-components/macro";
import { FlexJAMixin } from "styles";
import stylingUtils from "styles/styling.utils";

const { color } = stylingUtils;

export const StyledVTable = styled.table<{
  transparentHeader?: boolean;
  smallPadding: boolean;
}>`
  border-collapse: collapse;
  table-layout: auto;
  width: 100%;
  margin: 0 auto;
  position: relative;

  &.is-loading {
    opacity: 0.5;
  }

  .head__class {
    text-align: left;
    color: ${"ash01"};
    background-color: ${color("ash02")};
  }

  th {
    font-weight: 500;
  }

  th:first-child {
    border-top-left-radius: 12px;
  }
  th:last-child {
    border-top-right-radius: 12px;
  }

  th:first-child,
  td:first-child {
    padding-left: ${({ smallPadding }) => (smallPadding ? "15px" : "50px")};
  }
  th:last-child,
  td:last-child {
  }

  td,
  th {
    white-space: nowrap;
  }

  .head__row {
    background-color: ${({ transparentHeader }) =>
      transparentHeader ? "transparent" : `${stylingUtils.color("ash02")}`};
    border-top-left-radius: 20;
    border-top-right-radius: 20;
  }

  .head__body {
    color: ${stylingUtils.color("ash03")};
  }

  .head__body,
  .body__data {
    font-size: ${stylingUtils.rem(12)};
    padding: ${stylingUtils.rem(10)} ${stylingUtils.rem(10)}
      ${stylingUtils.rem(10)} 0;
    border: none;
    border-bottom: 1px solid ${stylingUtils.color("ash01")};
  }
  .bold__text {
    font-weight: 700 !important;
  }

  .body__row {
    background-color: ${stylingUtils.color("white")};
    cursor: pointer;

    &:hover {
      background-color: ${stylingUtils.color("ash01")};
    }
  }

  .table__children {
    &.collapsed__table--container {
      visibility: collapse;
      font-size: 0;
      margin: 0;
      padding: 0;
    }
    &.expanded__table--container {
      visibility: visible;
    }

    .body__row {
      background-color: ${stylingUtils.color("ash01")};
    }
  }

  .blue__text {
    color: ${stylingUtils.color("lightBlue")};
  }

  .table__radio {
    width: 16px;
    height: 16px;
  }

  .expandable__icon {
    fill: ${color("ash03")};
    transition: 0.5s all ease;
    ${FlexJAMixin}
    background-color: ${stylingUtils.color("ash02")};
    width: ${stylingUtils.rem(22)};
    height: ${stylingUtils.rem(22)};
    border-radius: 999%;
    transform: rotate(-90deg);

    &.isExpanded {
      transform: rotate(90deg);
    }

    img {
      width: 14px;
      height: 14px;
    }
  }

  @media (max-width: 600px) {
    display: none;
  }
`;
