import styled from "styled-components/macro";
import { stylingUtils } from "styles";

const {
  rem,
  color,
  mediaQuery: { down },
} = stylingUtils;

export const StyledVPageTabContainer = styled("div")<{
  isOval: boolean;
}>`
  background-color: ${color("white")};
  box-shadow: 0px 12px 30px rgba(0, 0, 0, 0.1);
  padding: ${rem(5)};
  border-radius: ${({ isOval }) => (isOval ? rem(12) : rem(30))};
  width: 100%;
  display: flex;
  gap: ${rem(10)};
  overflow: scroll;
`;

export const StyledVPageTabButton = styled("button")<{
  isActive: boolean;
  hasChildren: boolean;
}>`
  color: ${({ isActive }) => (isActive ? color("white") : color("ash03"))};
  background-color: ${({ isActive }) =>
    isActive ? color("lightBlue") : "transparent"};
  padding: ${rem(8)} ${rem(10)};
  width: ${({ hasChildren }) => (hasChildren ? "max-content" : "100%")};
  border-radius: ${rem(20)};
  font-size: ${rem(12)};
  transition: 0.5s all ease-in-out;
  ${down("laptop")} {
    white-space: nowrap;
    min-height: ${rem(40)};
    text-align: center;
  }

  &:disabled {
    background-color: ${color("ash02")};
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover {
    background-color: ${({ isActive }) =>
      !isActive && color("transparentBlue")};
  }
`;

export const StyledVPageTabChildren = styled("div")`
  margin-left: auto;
  margin-right: ${rem(4)};
`;
