import { useGetStaffReportHook } from "api";
import { STAFF_SUMMARY_CARDS_DATA } from "components/staff/constants";
import { useMemo } from "react";
import useReportFilterHook from "./useReportFilterHook";

const initialStaffActivitiesData: Record<string, any> = {
  invoice_recorded: {
    _id: "invoice_recorded",
    count: 0,
    value: 0,
  },
  sale_recorded: {
    _id: "sale_recorded",
    count: 0,
    value: 0,
  },
  inventory_recorded: {
    _id: "inventory_recorded",
    count: 0,
    value: 0,
  },
};

export const useReportStaffPageHook = () => {
  const { dateFilter } = useReportFilterHook();

  const { data } = useGetStaffReportHook(dateFilter);

  const { getStaffSummaryCards, getSalesByStaff } = data;

  const summaryCardRef = JSON.stringify(getStaffSummaryCards);
  const salesByStaffRef = JSON.stringify(getSalesByStaff);

  const cardData = useMemo(() => {
    return STAFF_SUMMARY_CARDS_DATA(getStaffSummaryCards);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [summaryCardRef]);
  const staffActivities = useMemo(() => {
    const mappedStaffActivities = getStaffSummaryCards?.staffActivities?.reduce(
      (acc, curr) => {
        if (curr.identifier) {
          let value = acc[curr?.identifier];
          acc[curr?.identifier ?? ""] = value
            ? {
                ...value,
                count: (curr?.count || 0) + value?.count,
                value: (value?.value || 0) + curr?.value,
              }
            : value;
        }
        return acc;
      },
      {
        ...initialStaffActivitiesData,
      }
    );

    const total = Object.values(mappedStaffActivities).reduce((acc, curr) => {
      acc += curr?.count || 0;
      return acc;
    }, 0);

    return { total, mappedStaffActivities };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [summaryCardRef]);

  const salesByStaff = useMemo(() => {
    return getSalesByStaff.map((staff) => ({
      ...staff,
      firstName: staff?.user?.firstName ?? "",
      lastName: staff?.user?.lastName ?? "",
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesByStaffRef]);

  return {
    cardData,
    data,
    getSalesByStaff,
    salesByStaff,
    staffActivities,
    performingStaff: getStaffSummaryCards?.performers,
  };
};
