import styled from "styled-components/macro";
import { FlexJAMixin, stylingUtils } from "styles";

const {
  rem,
  mediaQuery: { down },
} = stylingUtils;

export const StyledCustomerForm = styled("div")`
  .expense__form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 ${rem(20)};
    ${down("tablet")} {
      grid-template-columns: 1fr;
    }
  }
`;

export const StyledAddCustomer = styled("div")`
  .form--button {
    width: 100%;
    margin: ${rem(30)} 0;
    ${FlexJAMixin}
  }
`;
