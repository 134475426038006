export enum STORGAGE_KEYS {
  BUSINESS_PLAN = "BUSINESS_PLAN",
  BUSINESS_STORE_DETAILS = "BUSINESS_STORE_DETAILS",
  REDIRECT_BILLING_REGISTRATION = "REDIRECT_BILLING_REGISTRATION",
  BILLING_REDIRECT_SUCESSFUL = "BILLING_REDIRECT_SUCESSFUL",
  BILLING_PLAN = "BILLING_PLAN",
  HAS_VERIFY_PASSCODE = "HAS_VERIFY_PASSCODE",
  HAS_SET_PASSCODE = "HAS_SET_PASSCODE",
  USER_TOKEN = "token",
  RESET_TOKEN = "RESET_TOKEN",
  RESEND_COUNTDONW = "RESEND_COUNTDOWN",
}
