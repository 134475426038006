import { useLocalSearchParams } from "hooks";
import { ITerminalTransactions, useGetTerminalTransactions } from "api";
import { useState } from "react";
import { extractFetchedData } from "utils";

export const useTerminalTransactionsHook = () => {
  const { searchParams } = useLocalSearchParams();

  const [pageNumber, setPageNumber] = useState(
    parseInt(searchParams.get("page") || "1")
  );

  const [result, refetchTerminalTransactions] = useGetTerminalTransactions({
    size: 10,
    page: pageNumber,
  });

  // Handle pagination change
  const handleChangePage = (pageNumber: number) => {
    setPageNumber(pageNumber);
  };

  const extract = extractFetchedData<ITerminalTransactions>({
    result,
    key: "getTerminalTransactions",
  });

  return {
    ...extract,
    pageNumber,
    handleChangePage,
    refetchTerminalTransactions,
  };
};
