import styled from "styled-components/macro";
import { FlexJAMixin, stylingUtils } from "styles";

const {
  color,
  rem,
  mediaQuery: { down },
} = stylingUtils;

export const StyledCustomerForm = styled("div")`
  .expense__form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 ${rem(20)};
    ${down("tablet")} {
      grid-template-columns: 1fr;
    }
  }
`;

export const StyledAddCustomer = styled("div")`
  .form--button {
    width: 100%;
    margin: ${rem(30)} 0;
    ${FlexJAMixin}
  }
`;

export const NameInitial = styled("div")`
  display: flex;
  justify-content: center;

  .name_placeHolder {
    height: 90px;
    width: 90px;
    border-radius: 50%;
    background-color: ${color("transparentBlue2")};
    color: ${color("lightBlue")};
    display: grid;
    place-content: center;
    position: relative;
    margin-bottom: ${rem(30)};
  }
  h3 {
    font-size: ${rem(27)};
    color: ${color("lightBlue")};
    font-weight: 500;
  }
`;
