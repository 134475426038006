import { IUserDetails } from "api/user";
import { BUSINESS_PLANS } from "components/auth/signup/constants";
import { useLazyQuery } from "hooks";
import { gql, useQuery } from "urql";

type UseGetUserBusinessDetails = {
  businessId: string | null;
};

export const useGetUserBusinessDetails = ({
  businessId,
}: UseGetUserBusinessDetails) => {
  return useQuery({
    query: getUserBusinessDetails,
    variables: { businessId },
    pause: businessId === null || businessId === undefined,
  });
};
export const useLazyGetUserBusinessDetails = () => {
  return useLazyQuery({
    query: getUserBusinessDetails,
  });
};

const getUserBusinessDetails = gql`
  query ($businessId: String) {
    getBusinessDetail(businessId: $businessId) {
      _id
      businessCode
      businessName
      businessEmail
      businessPhoneNumber
      businessAddress
      businessEmblem
      stateName
      cityName
      invoiceTotal
      saleTotal
      expenseTotal
      referCode
      referredBy
      businessPlan
      isCacValid
      isBvnValid
      isNINValid
      CACNumber
      kycLevel
      utilityBill
      isSubscribed
      businessSector {
        _id
        sectorName
        createdAt
      }
      subscriptionDetails {
        lastPaymentDate
        upgradeDate
        nextBusinessPlan
        previousBusinessPlan
        nextPaymentDate
        freeTrial
      }
    }
  }
`;

export interface IUserBusinessDetails extends IUserDetails {
  _id: string;
  businessCode: string;
  businessName: string;
  businessEmail: string;
  businessPhoneNumber: string;
  businessAddress: string;
  businessEmblem: string;
  stateName: string;
  cityName: string;
  createdAt: string;
  invoiceTotal: string;
  saleTotal: string;
  expenseTotal: string;
  referCode: string;
  referredBy: string;
  businessSector: {
    _id: string;
    sectorName: string;
    createdAt: string;
  }[];
  isCacValid: DOCUMENT_VALIDATION;
  isBvnValid: DOCUMENT_VALIDATION;
  isNINValid: DOCUMENT_VALIDATION;
  businessPlan: BUSINESS_PLANS;

  CACNumber: string;
  kycLevel: string;
  utilityBill: string;
  isSubscribed: boolean;
  subscriptionDetails?: {
    daysUsedOnCurrentPlan: number;
    lastPaymentDate: string;
    upgradeDate: string;
    nextBusinessPlan: string;
    previousBusinessPlan: string;
    nextPaymentDate: string;
    freeTrial: boolean;
  };
}

export enum DOCUMENT_VALIDATION {
  START = "START",
  PROCESS = "PROCESS",
  FAILED = "FAILED",
  SUCCESS = "SUCCESS",
  NOT_FOUND = "NOT_FOUND",
}
