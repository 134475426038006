import styled from "styled-components";
import { FlexColumn, stylingUtils } from "styles";

const { rem, color } = stylingUtils;

export const StyledSignOuterWrapper = styled("div")`
  ${FlexColumn};
  justify-content: center;
  gap: ${rem(30)};
  max-width: ${rem(1000)};
  margin: 0 auto;
`;

export const StyledSignupWrapper = styled("div")`
  display: grid;
  place-items: center;
  background-color: white;
  padding: ${rem(60)} ${rem(10)};
  border-radius: ${rem(10)};
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.04);
  width: 100%;
`;

export const StyledSignupContent = styled("section")<{ maxWidth: number }>`
  max-width: ${({ maxWidth }) => rem(maxWidth || 700)};
  margin: 0 auto;
  width: 100%;
`;

export const StyledBillingButtonWraper = styled("div")`
  display: grid;
  place-items: center;
  margin-top: ${rem(30)};
  grid-gap: ${rem(10)};
`;

export const StyledOptionalBusinessDocuments = styled("p")`
  font-size: ${rem(20)};
  font-weight: 500;
  margin-bottom: ${rem(20)};
  grid-column: auto / span 2 !important;

  span {
    font-weight: 300;
  }
`;

export const StyledForgotPasscode = styled("div")`
  display: flex;
  justify-content: center;
  gap: 5px;

  button {
    color: ${color("lightBlue")};
    font-weight: bold;
  }
`;

export const StyledResendPasscode = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .resend {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    button {
      color: ${color("lightBlue")};
      font-weight: bold;
    }
  }
`;
