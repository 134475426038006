import { UINotification, Notification } from "api/notification/types";
import { gql, useQuery } from "urql";

export const mapToUINotifications = (
  notifications: Notification[]
): UINotification[] => {
  return !notifications
    ? []
    : notifications
        ?.map((notification) => ({
          id: notification._id,
          title: notification.title,
          body: notification.body,
          time: notification.createdAt,
          isSeen: notification.isSeen,
          extraData: notification?.extraData,
        }))
        .reverse();
};

export const useGetUserNotification = ({
  userId,
  includeSeen,
}: {
  userId: string;
  includeSeen?: boolean;
}) => {
  return useQuery({
    query: getUserNotification,
    variables: { userId, includeSeen },
  });
};

const getUserNotification = gql`
  query ($userId: String!, $includeSeen: Boolean) {
    getUserNotification(userId: $userId, includeSeen: $includeSeen) {
      _id
      title
      body
      isSeen
      status
      extraData
      createdAt
    }
  }
`;
