// Core
import { gql, useMutation } from "urql";

// Custom Components
import { showToast } from "components/common";

// Assets
import { handleRequestCall } from "utils/handleRequestCall";

// GraphQL
const changePasscodeMutation = gql`
  mutation ($oldPasscode: String!, $newPasscode: String!) {
    changePasscode(oldPasscode: $oldPasscode, newPasscode: $newPasscode) {
      success
      data
    }
  }
`;

// Functionality
export const useChangeUserPasscode = () => {
  const [changePasscodeResponse, setChangeUserPasscode] = useMutation(
    changePasscodeMutation
  );

  const handleChangeUserPasscode = async (
    variables: { oldPasscode: string; newPasscode: string },
    callBack?: (data: Record<string, any>) => void
  ) => {
    try {
      const req = await setChangeUserPasscode(variables);
      await handleRequestCall({
        request: req,
        callBack,
        error: {
          title: "Invalid credentials",
          subText: "Please verify your old passcode",
          type: "error",
        },
      });
    } catch (e) {
      showToast({
        type: "error",
        title: "An error ocucured",
        subText: "Please try again",
      });
      console.error(e);
    }
  };

  return {
    changePasscodeResponse,
    handleChangeUserPasscode,
  };
};
