const MasterCardIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 21.36 13"
      {...props}
    >
      <circle cx="6.5001" cy="6.5001" r="6.5001" fill="#EB001B" />
      <circle cx="14.8573" cy="6.5001" r="6.5001" fill="#F79E1B" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6787 11.4779C12.098 10.2856 13.0002 8.4975 13.0002 6.49873C13.0002 4.49996 12.098 2.7119 10.6787 1.51953C9.25934 2.7119 8.35718 4.49996 8.35718 6.49873C8.35718 8.4975 9.25934 10.2856 10.6787 11.4779Z"
        fill="#FF5F00"
      />
    </svg>
  );
};

export default MasterCardIcon;
