import styled from "styled-components";

export const StyledValidatePinModal = styled("div")`
  display: flex;
  flex-direction: column;
  font-family: "Gilmer";
  font-style: normal;
  .button {
    width: 7rem;
    height: 40px;
    margin-top: 2rem;
    border-radius: 10px;

    &:disabled {
      background-color: #0077ff;
      opacity: 0.6;
      cursor: not-allowed;
    }
  }
  .errorText {
    margin-top: 1rem;
    font-weight: 400;
    text-align: center;
    color: ${(props) => props.theme.colors.toastRed};
  }
  .passcode-text {
    margin: 1rem 0;
  }
`;
