// Core
import { gql, useMutation } from "urql";

// Custom Components
import { showToast } from "components/common";

// Assets
import { handleRequestCall } from "utils/handleRequestCall";
import { uploadMedia } from "utils";

// types
export type KycLevelOneProps = {
  bvn: string;
  selfie: string;
  passcode: string;
};

// GraphQL
const completeBusinessLevelOneMutation = gql`
  mutation ($bvn: String, $selfie: String, $passcode: String) {
    completeBusinessLevelOne(bvn: $bvn, selfie: $selfie, passcode: $passcode) {
      success
    }
  }
`;

// Functionality
export const useCompleteBusinessLevelOne = () => {
  const [completeBusinessLevelOne, setCompleteBusinessLevelOne] = useMutation(
    completeBusinessLevelOneMutation
  );

  const handleCompleteBusinessLevelOne = async (
    data: KycLevelOneProps,
    callBack?: (data: Record<string, any>) => void
  ) => {
    try {
      const { bvn, selfie, passcode } = data;

      let processedSelfie: string | null = null;

      if (selfie) {
        const uploadedSelfie = await uploadMedia(selfie);
        if (!uploadedSelfie) {
          showToast({
            type: "error",
            title: "Image upload failed",
            subText: "Please try again",
          });
          return;
        }
        processedSelfie = uploadedSelfie?.[0]?.url;
      }

      const requestData = {
        bvn,
        selfie: processedSelfie,
        passcode,
      };

      const req = await setCompleteBusinessLevelOne(requestData);
      await handleRequestCall({ request: req, callBack });
    } catch (e) {
      showToast({
        type: "error",
        title: "An error occured",
        subText: "Please try again",
      });
      console.error(e);
    }
  };

  return {
    completeBusinessLevelOne,
    handleCompleteBusinessLevelOne,
  };
};
