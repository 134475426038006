export const LOAN_QUALIFICATION_ENUM = {
  TRUE: true,
  FALSE: false,
  ONBOARDING_REQUIRED: "ONBOARDING_REQUIRED",
  REFER: "REFER",
};
export const LOAN_STATUS_ENUM = {
  PENDING_APPROVAL: "PENDING_APPROVAL", // when user submits the loan request
  UNRESOLVED_PROVIDER_STATUS: "UNRESOLVED_PROVIDER_STATUS",
  DECLINED: "DECLINED", // if its declined by admin
  APPROVED: "APPROVED", // if its approved by admin
  AWAITING_SIGNATURE: "AWAITING_SIGNATURE", // if loan request was sent but its waiting on customer to sign the loan so admin can check
  AWAITING_DISBURSEMENT: "AWAITING_DISBURSEMENT", //internal status which we would use for disbursement
  ACTIVE: "ACTIVE", // when loan is active
  CLOSED: "CLOSED", //When loan is closed
};

export const USE_DUMMY_CUSTOMER_ID = false;
// export const DUMMY_CUSTOMER_ID = '995343b4-8314-4a40-96bf-c18edb9ad981'; // Has a loan pending approval with ETA
// export const DUMMY_CUSTOMER_ID = '12381689910';

export const DUMMY_CUSTOMER_ID = "9276288282";
// export const DUMMY_CUSTOMER_ID = '7388389309645478654';
// export const DUMMY_CUSTOMER_ID = '1223663942cchd90';
// export const DUMMY_CUSTOMER_ID = '12345678'; // Has a loan pending e-signature
// export const DUMMY_CUSTOMER_ID = '70bdaf35-4e78-4070-92bf-ddad5e108519'; // Has an active loan
export const isServerSide = typeof window === "undefined";
