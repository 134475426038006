import { VButton } from "components/common";
import { ReactComponent as ChevronLeft } from "assets/images/svgs/chevron-left.svg";
import { ReactComponent as PlusIcon } from "assets/images/svgs/plus-icon.svg";
import { useModal } from "hooks";
import {
  StyledRolesAndActionBack,
  StyledRolesAndPermissionActionHeader,
} from "components/manage-business/roles-and-permissions";
import { RolesAndPermissionHeaderProps } from "components/manage-business/roles-and-permissions/types";
import UpgradePlanModal from "./updgrade-plan-modal";
import { AddStoreModal } from "./add-store-modal";

export const StoreActionHeader = ({
  backText,
  backAction,
}: RolesAndPermissionHeaderProps) => {
  const { open, toggleModal } = useModal();

  const hasUpgraded = true;

  return (
    <>
      <StyledRolesAndPermissionActionHeader>
        {backText && (
          <StyledRolesAndActionBack onClick={() => backAction?.()}>
            <ChevronLeft height={15} />
            <h5>{backText}</h5>
          </StyledRolesAndActionBack>
        )}
        <VButton
          text={`Add Store`}
          disabled
          height="30px"
          icon={<PlusIcon />}
          isSlim
          style={{ marginLeft: "auto" }}
          onClick={toggleModal}
        />
      </StyledRolesAndPermissionActionHeader>
      {hasUpgraded ? (
        <AddStoreModal open={open} close={toggleModal} />
      ) : (
        <UpgradePlanModal open={open} close={toggleModal} />
      )}
    </>
  );
};
