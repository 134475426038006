import styled from "styled-components";
import stylingUtils from "styles/styling.utils";

const { color, rem } = stylingUtils;

export const Wrapper = styled.div`
  display: flex;
  gap: ${rem(10)};
  align-items: center;
`;
export const Radio = styled.input`
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  width: ${rem(18)};
  height: ${rem(18)};
  border: 1px solid ${color("lightBlue")};
  border-radius: 50%;
  cursor: pointer;
  ::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 0.75em;
    height: 0.75em;
    margin: ${rem(3)};
  }
  :hover {
    ::after {
      background-color: ${color("lightBlue")};
    }
  }
  :checked {
    ::after {
      background-color: ${color("lightBlue")};
    }
    :hover {
      background-color: ${color("white")};
      border: 1px solid ${color("lightBlue")};
      ::after {
        background-color: ${color("lightBlue")};
      }
    }
  }
`;
export const Label = styled.label`
  font-family: "Gilmer";
  font-weight: 400;
  font-size: ${rem(14)};
  line-height: 19px;
  color: #575a65;
`;
