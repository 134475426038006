const VeedezIcon = () => {
  return (
    <svg
      width="27"
      height="29"
      viewBox="0 0 27 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9273 17.5843L10.0778 28.147C9.97976 28.0736 9.90435 27.974 9.8602 27.8597L0.0373481 0.876293C0.0027034 0.778318 -0.00805344 0.673489 0.00597652 0.57052C0.0200065 0.467552 0.0584273 0.36942 0.118022 0.284285C0.177616 0.19915 0.256673 0.129474 0.34862 0.0810475C0.440566 0.0326207 0.542734 0.00684245 0.646649 0.00585938H7.06176C7.1957 0.00713077 7.326 0.0495746 7.435 0.127431C7.544 0.205287 7.62641 0.314786 7.67106 0.441076L13.9273 17.5843Z"
        fill="#007BFF"
      />
      <path
        d="M24.8889 8.85961L17.9711 27.8634C17.9285 27.9773 17.8527 28.0758 17.7535 28.1462L13.9258 17.5857L15.0639 14.4369V14.426C15.7532 12.5369 17.0067 10.9053 18.6543 9.75239C20.302 8.59945 22.2643 7.98088 24.2752 7.98047C24.3806 7.98026 24.4844 8.00554 24.5778 8.05415C24.6713 8.10276 24.7516 8.17326 24.8119 8.25964C24.8722 8.34601 24.9107 8.44569 24.9241 8.55016C24.9375 8.65464 24.9254 8.76081 24.8889 8.85961Z"
        fill="#F0A75A"
      />
      <path
        d="M23.6628 12.2324L18.8928 25.339H18.8906L23.6628 12.2324Z"
        fill="#F0A75A"
      />
      <path
        d="M22.875 7.34183C24.8965 7.34183 26.5352 5.70312 26.5352 3.68166C26.5352 1.6602 24.8965 0.0214844 22.875 0.0214844C20.8536 0.0214844 19.2148 1.6602 19.2148 3.68166C19.2148 5.70312 20.8536 7.34183 22.875 7.34183Z"
        fill="#E57A1A"
      />
      <path
        d="M17.7602 28.1465C17.6473 28.2392 17.5058 28.29 17.3597 28.2901H10.4724C10.3279 28.289 10.1879 28.24 10.0742 28.1508L13.9237 17.5859L17.7602 28.1465Z"
        fill="#E57A1A"
      />
    </svg>
  );
};
export default VeedezIcon;
