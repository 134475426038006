import CallIcon from "assets/images/svgs/CallIcon";
import DownArrow from "assets/images/svgs/DownArrow";
import MessageIcon from "assets/images/svgs/MessageIcon";
import TodayCustomersIcon from "assets/images/svgs/TodayCustomersIcon";
import TodayExpenseIcon from "assets/images/svgs/TodayExpenseIcon";
import TodayInvoiceIcon from "assets/images/svgs/TodayInvoiceIcon";
import TodaySalesIcon from "assets/images/svgs/TodaySalesIcon";
import { VTooltip } from "components/common";
import {
  CardProps,
  CustomTableProps,
  OptionType,
} from "components/common/types";
import { Link } from "react-router-dom";
import { APP_ROUTES } from "routes";
import {
  capitalizeWord,
  formatNumberToCurrency,
  formatUnderScore,
  truncateWord,
} from "utils";
import {
  StyledDashboardCardAction,
  StyledDashboardIconWrapper,
  StyledFrequentCustomerContact,
  StyledFrequentCustomerContactButton,
} from "./styles";
import { DashboardTabCardProps } from "./types";

export const DASHBAORD_CARDS_DATA = ({
  totalExpenses,
  totalSales,
  totalCustomers,
  totalInvoices,
}: {
  totalExpenses: number;
  totalSales: number;
  totalCustomers: number;
  totalInvoices: number;
}): CardProps[] => {
  return [
    {
      title: "Today’s Sales",
      icon: () => (
        <StyledDashboardIconWrapper color="#E8F5F1">
          <TodaySalesIcon />
        </StyledDashboardIconWrapper>
      ),
      amount: formatNumberToCurrency({ number: Number(totalSales) }),
      action: (
        <Link to={APP_ROUTES.SALES}>
          <StyledDashboardCardAction>
            <p>Record New Sale</p>
            <DownArrow />
          </StyledDashboardCardAction>
        </Link>
      ),
    },
    {
      title: `Today's Customers`,
      icon: () => (
        <StyledDashboardIconWrapper color="#FBECDB">
          <TodayCustomersIcon />
        </StyledDashboardIconWrapper>
      ),
      amount: formatNumberToCurrency({
        number: Number(totalCustomers),
        removeCurrency: true,
      }),
      action: (
        <Link to={APP_ROUTES.CUSTOMERS}>
          <StyledDashboardCardAction>
            <p>Add Customer Information</p>
            <DownArrow />
          </StyledDashboardCardAction>
        </Link>
      ),
    },
    {
      title: `Today's Expenses`,
      icon: () => (
        <StyledDashboardIconWrapper color="rgba(234, 67, 54, 0.1)">
          <TodayExpenseIcon />
        </StyledDashboardIconWrapper>
      ),
      amount: formatNumberToCurrency({ number: Number(totalExpenses) }),
      action: (
        <Link to={APP_ROUTES.EXPENSE}>
          <StyledDashboardCardAction>
            <p>Record Expenses</p>
            <DownArrow />
          </StyledDashboardCardAction>
        </Link>
      ),
    },
    {
      title: `Today's Invoices`,
      icon: () => (
        <StyledDashboardIconWrapper color="#E0EEFF">
          <TodayInvoiceIcon />
        </StyledDashboardIconWrapper>
      ),
      amount: formatNumberToCurrency({
        number: Number(totalInvoices),
        removeCurrency: true,
      }),
      action: (
        <Link to={APP_ROUTES.INVOICES}>
          <StyledDashboardCardAction>
            <p>Record New Invoice</p>
            <DownArrow />
          </StyledDashboardCardAction>
        </Link>
      ),
    },
  ];
};

export const WELCOME_TABS_INFO: DashboardTabCardProps[] = [
  {
    id: "hasCompletedKyc",
    title: "Complete KYC and activate payment",
    number: 1,
    description: "Complete KYC to activate payment",
    isActive: true,
    link: APP_ROUTES.ACCOUNT,
  },
  {
    id: "hasAddedProduct",
    title: "Add a product to your inventory",
    number: 2,
    description: "Add a product or service to your inventory",
    link: APP_ROUTES.PRODUCTS,
  },
  {
    id: "hasAddedSalesOrExpense",
    title: "Record a sale or expense",
    number: 3,
    description: "Record to track your daily business activities",
    link: APP_ROUTES.SALES,
  },
];

export const TOP_SELLING_PRODUCTS_COLUMN: CustomTableProps["columns"] = [
  {
    headerName: "ID",
    key: "customerProductId",
    width: 120,
    renderCell: (value: string) =>
      !value ? (
        "N/A"
      ) : (
        <Link
          to={`${APP_ROUTES.PRODUCTS}/${value}`}
          className="lightblue__text underline"
        >
          <VTooltip content={value} children={truncateWord(value)} />
        </Link>
      ),
  },
  {
    headerName: "Name",
    key: "productName",
    formatter: (productName: string, product: Record<string, any>) => {
      const newProductName =
        product?.customerProduct?.productName ||
        product?.customerService?.serviceName;
      return formatUnderScore(newProductName || "");
    },
  },
  {
    headerName: "Category",
    key: "category.name",
    useZero: false,
  },
  {
    headerName: "Selling price",
    key: "sellingPrice",
    formatter: (value: number) => {
      const costPrice = formatNumberToCurrency({
        number: value,
      });
      return costPrice;
    },
  },
  {
    headerName: "Qty",
    key: "quantity",
  },
];

export const MOST_FREQUENT_CUSTOMERS_COLUMN = [
  {
    headerName: "ID",
    key: "_id",
    width: 120,
    renderCell: (value: string) => (
      <p className="lightblue__text underline">
        <VTooltip content={value} children={truncateWord(value)} />
      </p>
    ),
  },
  {
    headerName: "Name",
    key: "customerName",
    formatter: (name: string) => capitalizeWord(name || ""),
  },
  {
    headerName: "Selling price",
    key: "amountSpent",
    formatter: (value: number) => {
      const costPrice = formatNumberToCurrency({
        number: value,
      });
      return costPrice;
    },
  },
  {
    headerName: "Contact",
    key: "contact",
    renderCell: (value: string, data: Record<string, any>) => {
      const { phoneNumber, email } = data;
      return (
        <StyledFrequentCustomerContact>
          {email && (
            <StyledFrequentCustomerContactButton>
              <a href={`mailto:${email}`}>
                <MessageIcon width={12} height={12} />
              </a>
            </StyledFrequentCustomerContactButton>
          )}
          {phoneNumber && (
            <StyledFrequentCustomerContactButton>
              <a href={`tel:${phoneNumber}`}>
                <CallIcon width={12} height={12} />
              </a>
            </StyledFrequentCustomerContactButton>
          )}
        </StyledFrequentCustomerContact>
      );
    },
  },
];

export const DURATION_INPUT_LISTS: OptionType[] = [
  { label: "Daily", value: 1 },
  { label: "Weekly", value: 7 },
  { label: "Monthly", value: 30 },
];
export const SECONDARY_DURATION_INPUT_LISTS: OptionType[] = [
  { label: "Daily", value: "DAILY" },
  { label: "Weekly", value: "WEEKLY" },
  { label: "Monthly", value: "MONTHLY" },
];
