import { FlexJAMixin } from "./../../../styles/global";
import styled from "styled-components";
import { stylingUtils } from "styles";

const { rem, color } = stylingUtils;
export const StyledFrequentCustomersTable = styled("section")``;

export const StyledFrequentCustomerContact = styled("button")`
  display: flex;
  align-items: center;
  gap: ${rem(10)};
`;
export const StyledFrequentCustomerContactButton = styled("div")`
  cursor: pointer;
  width: ${rem(24)};
  height: ${rem(24)};
  border-radius: 99999%;
  background-color: ${color("ash02")};
  ${FlexJAMixin};
`;
