import { gql, useQuery } from "urql";

type UseGetBusinessRolesAndPermissionsQueryProps = {
  businessId: string;
};

export interface IBusinessRolesAndPermission {
  _id: string;
  roleName: string;
  permissions: {
    _id: string;
    permission: string;
    status: string;
    module: string;
  }[];
  usersAssociated: number;
}

const getBusinessRolesAndPermissionsQuery = gql`
  query ($businessId: String) {
    getRoleAndUserAssociated(businessId: $businessId) {
      _id
      roleName
      permissions {
        _id
        permission
        status
        module
      }
      usersAssociated
    }
  }
`;

export const useGetBusinessRolesAndPermissionsQuery = ({
  businessId,
}: UseGetBusinessRolesAndPermissionsQueryProps) => {
  return useQuery({
    query: getBusinessRolesAndPermissionsQuery,
    variables: { businessId },
  });
};
