import { gql, useQuery } from "urql";

type useGetCustomerTransaction = {
  cursor?: string;
  type?: string;
  status?: string;
  userId?: string;
};

export type CustomerTransaction = {
  id: string;
  reference: string;
  address: string;
  amount: string;
  createdAt: Date;
  updatedAt: Date;
  summary: string;
  type: string;
  narration: string;
  category: string;
  customerName: string;
  phoneNumber: string;
  product: {
    displayName: string;
  };
  productSlug: string;
  status: string;
  total: string;
}[];

const getCustomerTransactionQuery = gql`
  query (
    $cursor: String
    $type: CustomerTransactionType
    $status: CustomerTransactionStatus
    $userId: String
  ) {
    getCustomerTransaction(
      filters: { type: $type, status: $status, userId: $userId }
      metadata: { cursor: $cursor, count: 11 }
    ) {
      id
      reference
      amount
      createdAt
      summary
      type
      narration
      status
      category {
        name
      }
      product {
        displayName
      }
      metadata {
        label
        value
      }
    }
  }
`;

export const useGetCustomerTransactions = ({
  cursor,
  type,
  status,
  userId,
}: useGetCustomerTransaction) => {
  return useQuery({
    query: getCustomerTransactionQuery,
    variables: {
      cursor,
      type,
      status,
      userId,
    },
  });
};

type useGetCustomersTransactions = {
  pageNumber?: number;
  type?: string;
  status?: string;
  userId?: string;
  from?: string;
  to?: string;
  product?: string;
  categorySlugs?: string;
  downloadEmail?: string;
};

export type CustomersTransaction = {
  transactions: CustomerTransaction;
  paginationMeta: {
    currentPage: number;
    pages: number;
  };
};

const getCustomersTransactionsQuery = gql`
  query (
    $pageNumber: Int
    $type: CustomerTransactionType
    $status: CustomerTransactionStatus
    $userId: String
    $categorySlugs: String
    $from: String
    $to: String
    $product: String
    $downloadEmail: String
    $query: String
  ) {
    getCustomersTransactions(
      filters: {
        type: $type
        status: $status
        userId: $userId
        from: $from
        to: $to
        query: $query
        categorySlugs: $categorySlugs
        product: $product
        downloadEmail: $downloadEmail
      }
      metadata: { pageNumber: $pageNumber, count: 11 }
    ) {
      transactions {
        id
        reference
        amount
        createdAt
        summary
        type
        narration
        status
        total
        action
        productSlug
        category {
          name
        }
        product {
          displayName
        }
        metadata {
          label
          value
        }
      }
      paginationMeta {
        currentPage
        pages
      }
    }
  }
`;

export const useGetCustomersTransaction = ({
  pageNumber,
  type,
  status,
  userId,
  categorySlugs,
  from,
  to,
  product,
  downloadEmail,
}: useGetCustomersTransactions) => {
  return useQuery({
    query: getCustomersTransactionsQuery,
    variables: {
      pageNumber,
      type,
      status,
      userId,
      categorySlugs,
      from,
      to,
      product,
      downloadEmail,
    },
  });
};
