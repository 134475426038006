import { ThemeProps } from "./types";

export type StylingProps = { theme: ThemeProps };

export type QFontWeightProps = keyof ThemeProps["font"]["weights"];
export type QColorProps = keyof ThemeProps["colors"];
export type QBreakpointProps = keyof ThemeProps["breakpoints"];
export type QMediaSize = keyof typeof mediaSize;

const color =
  (colorName: QColorProps, opacity = 1) =>
  (props: StylingProps) => {
    const value = props.theme.colors[colorName];
    if (!value) return undefined;
    if (opacity < 1) {
      // Some special colors in the theme aren't in hex format
      if (value.startsWith("#")) {
        const opacityColorValue = Math.floor(opacity * 255);
        return `${value}${opacityColorValue.toString(16)}`;
      } else {
        throw new Error(
          `Attempt to change opacity for non-hex color "${colorName}"`
        );
      }
    }
    return value;
  };

const rem = (px: number, scaleForDeviceScreen = true) => {
  const remValue = `${px / 16}rem`;
  return scaleForDeviceScreen
    ? `calc(${remValue} * var(--rem-scaler, 1))`
    : remValue;
};

const breakpoint = (name: QBreakpointProps) => (props: StylingProps) =>
  props.theme.breakpoints[name];

const mediaSize = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
};

const mediaQuery = {
  up: (breakpoint: QMediaSize, vertical: boolean = false) =>
    `@media (min-${vertical ? "height" : "width"}: calc(${
      mediaSize[breakpoint]
    } + 0.02px))`,
  down: (breakpoint: QMediaSize, vertical: boolean = false) =>
    `@media (max-${vertical ? "height" : "width"}: ${mediaSize[breakpoint]})`,
  between: (
    breakpointMin: QMediaSize,
    breakpointMax: QMediaSize,
    vertical: boolean = false
  ) =>
    `@media (max-${vertical ? "height" : "width"}: ${
      mediaSize[breakpointMax]
    }) and (min-${vertical ? "height" : "width"}: calc(${
      mediaSize[breakpointMin]
    } + 0.02px))`,
};

const horizontalSpacing = (spacing: number = 24) => ` & > *:not(:last-child) {
	margin-bottom: ${stylingUtils.rem(spacing)};
  }`;

const allExceptLastChild = ` & > *:not(:last-child)`;

const stylingUtils = {
  color,
  rem,
  breakpoint,
  mediaQuery,
  horizontalSpacing,
  allExceptLastChild,
};

export default stylingUtils;
