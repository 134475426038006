import styled from "styled-components/macro";
import { stylingUtils } from "styles";
import zigzag from "assets/images/pngs/zigzagPattern.png";
import { VStyledSummaryCard } from "components/common/VSummaryCard/styles";
import { Content } from "components/pos/card/styles";

const { color } = stylingUtils;

export const PosCardContainer = styled(VStyledSummaryCard)`
  background-color: ${color("white")};
  background-image: url(${zigzag});
  background-repeat: no-repeat;
  background-size: 100% 60%;
`;
export const CardContent = styled(Content)`
  padding: 1rem;
  width: 100%;
`;
