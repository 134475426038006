import getActiveTab from "../../utils/getActiveTab";
import { Link, useLocation } from "react-router-dom";
import {
  TopnavWrapper,
  Topnavright,
  Topnavh1txt,
  MobileIconWrapper,
  ProfileWrapper,
  TextColumn,
  H3,
  Span,
  Topnavsearch,
  Dropdown,
  StyledAdminNameAvatar,
  StyledTopNavIcon,
} from "./TopNav.styled";
import VSearch from "components/top-nav/TopNavSearch";

import NotificationIcon from "../../assets/images/svgs/NotificationIcon";
import DownArrow from "assets/images/svgs/DownArrow";
import MenuIcon from "../../assets/images/svgs/MenuIcon";
import { useOauthCallBackHook, useWindowSize } from "hooks";
import { useAuthUser } from "store";
import { useTopNavSearchHook } from "hooks";
import { BUSINESS_REGISTRATION_ROUTES } from "app/constants";
import { CompanyWrapper } from "components/sidebar/Sidebar.styled";
import Logo from "assets/images/svgs/VeedezLogo";
import { useState } from "react";
import LogoutIcon from "assets/images/svgs/Logout";
import {
  StyledStaffNameWrapper,
  StyledStaffImgAvater,
} from "components/staff/styles";
import getIframeUrl from "utils/getIframeUrl";
import { toggleNavbarModal } from "store";
import { useVAppDispatch } from "hooks/useStore";
import { ImportFilesIcon } from "assets/images/svgs/ImportFilesIcon";
import { APP_ROUTES } from "routes";
import { getEnvKey } from "utils";
import { VerticalElipses } from "assets/icons/vertical-dot";

function TopNav({
  toggleNotificationModal,
}: {
  toggleNotificationModal?: Function;
}) {
  const ADMIN_ROLE_ID = getEnvKey("REACT_APP_ADMIN_ROLE_ID");
  const OWNER_ROLE_ID = getEnvKey("REACT_APP_OWNER_ROLE_ID");

  const { width: windowSize } = useWindowSize();
  const isMobile = (windowSize as number) <= 420;

  const { handleUserLogout } = useOauthCallBackHook();

  const { userData } = useAuthUser();
  const fullName = `${userData?.lastName ?? ""} ${
    userData?.firstName?.[0] ?? ""
  }.`;
  const mockAvatar = `${userData?.lastName?.[0] ?? ""}${
    userData?.firstName?.[0] ?? ""
  }`;

  const profilePicture = "";

  const router = useLocation();
  let location = router.pathname;

  const businessRegistrationRoutes = BUSINESS_REGISTRATION_ROUTES.some(
    (route) => location.includes(route)
  );

  const dispatch = useVAppDispatch();

  const [dropdown, setDropdown] = useState(false);

  const {
    search,
    isSearchable,
    searchPlaceholder,
    handleClear,
    handleChange,
    handleEnterKeyPress,
  } = useTopNavSearchHook({
    location,
  });

  let navText = getActiveTab(location);

  const navigateIframe = () => {
    if (navText === "MarketPlace") {
      let newSrc = getIframeUrl();
      const iframe = document.getElementById(
        "AlerzoshopIframe"
      ) as HTMLIFrameElement;
      if (iframe) {
        iframe.src = newSrc;
      }
    }
  };

  return (
    <TopnavWrapper>
      {!businessRegistrationRoutes ? (
        <>
          <Topnavh1txt $navText={navText} onClick={navigateIframe}>
            {navText}
          </Topnavh1txt>
          <Topnavsearch>
            <VSearch
              inputPlaceholder={searchPlaceholder}
              isSearchable={isSearchable}
              handleChange={handleChange}
              handleEnterKeyPress={handleEnterKeyPress}
              handleClear={handleClear}
              value={search.get("search") || ""}
            />
          </Topnavsearch>
        </>
      ) : (
        <CompanyWrapper>
          <Logo />
        </CompanyWrapper>
      )}

      <Topnavright>
        <StyledTopNavIcon>
          <Link to={APP_ROUTES.IMPORTED_FILES}>
            <ImportFilesIcon />
          </Link>
        </StyledTopNavIcon>
        <NotificationIcon width={20} onClick={toggleNotificationModal} />

        <ProfileWrapper onClick={() => setDropdown(!dropdown)}>
          <StyledStaffNameWrapper>
            {profilePicture ? (
              <StyledStaffImgAvater
                src={profilePicture}
                alt={userData?.lastName}
              />
            ) : (
              <StyledAdminNameAvatar>{mockAvatar}</StyledAdminNameAvatar>
            )}
          </StyledStaffNameWrapper>
          <TextColumn>
            <H3>{fullName}</H3>
            <Span>
              {userData?.roleId === ADMIN_ROLE_ID
                ? "Admin"
                : userData?.roleId === OWNER_ROLE_ID
                ? "Owner"
                : ""}
            </Span>
          </TextColumn>
          <DownArrow
            style={{
              transform: dropdown ? "rotate(180deg)" : "rotate(360deg)",
              transition: "0.5s all ease",
            }}
          />
        </ProfileWrapper>

        {!isMobile ? (
          <MobileIconWrapper onClick={() => dispatch(toggleNavbarModal())}>
            <MenuIcon width={20} />
          </MobileIconWrapper>
        ) : (
          <VerticalElipses
            width={35}
            height={35}
            style={{ fontWeight: "bold" }}
            onClick={() => dispatch(toggleNavbarModal())}
          />
        )}

        {dropdown && (
          <Dropdown>
            <div
              className="item"
              onClick={() => {
                handleUserLogout();
              }}
            >
              <LogoutIcon />
              <p>Log out</p>
            </div>
          </Dropdown>
        )}
      </Topnavright>
    </TopnavWrapper>
  );
}

export default TopNav;
