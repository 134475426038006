import { usePayForInvoice, Payment } from "api";
import { showToast } from "components/common";
import { useEffect } from "react";
import { useInvoicePageHook } from "./useInvoicePageHook";

export const usePayForInvoiceHook = (
  invoiceId: string,
  payments: Payment,
  callBack?: () => void,
  setSelectedCard?: any,
  hookForm?: any,
  reset?: any,
  selectedCard?: string,
  paymentAmount?: string
) => {
  const { handlePayForInvoice, isLoading } = usePayForInvoice(invoiceId);

  const { handleSubmit, setValue } = hookForm;

  const { refetchBusinessInvoices } = useInvoicePageHook();

  useEffect(() => {
    refetchBusinessInvoices();
  }, [handlePayForInvoice]);

  // submit form
  const onSubmit = async () => {
    if (selectedCard === "") {
      showToast({
        subText: "Please choose a payment channel",
        type: "error",
      });
    } else {
      await handlePayForInvoice({ invoiceId, payments }, () => {
        showToast({
          subText: "Payment was recorded successfully",
        });
        refetchBusinessInvoices();
        reset({
          paymentAmount: "",
        });
        setSelectedCard("");
        callBack?.();
      });
    }
  };

  return {
    handleSubmit,
    hookForm,
    setValue,
    onSubmit,
    isLoading,
  };
};
