import { gql, useMutation } from "urql";
import { TProduct, TEditedProduct } from "components/Inventory/types";
import { CreateProductAPIRequest } from "api/inventory/types";
import { omit } from "lodash";
import { uploadMedia } from "utils";
import { showToast } from "components/common";
import { useProductPageHook, useToggle } from "hooks";
import { useAnalytics } from "hooks/useAnalytics";

export const addCustomerProductMutation = gql`
  mutation (
    $productName: String!
    $sellingPrice: Int!
    $purchasePrice: Int!
    $availableStock: Int!
    $productImage: String
    $thresholdLowStock: Int
    $categoryId: String
  ) {
    addCustomerProduct(
      productName: $productName
      sellingPrice: $sellingPrice
      purchasePrice: $purchasePrice
      availableStock: $availableStock
      productImage: $productImage
      thresholdLowStock: $thresholdLowStock
      categoryId: $categoryId
    ) {
      _id
      businessId
    }
  }
`;

export const useAddCustomerProduct = () => {
  const [customerProduct, addCustomerProduct] = useMutation(
    addCustomerProductMutation
  );

  const [logAppEvent, { add_product_successfully }] = useAnalytics();

  const { refetchCustomerProducts } = useProductPageHook({});
  const [imageProcessing, toggleImageProcessing] = useToggle();

  const submitNewCustomerProduct = async (
    formData: TEditedProduct,
    handleClose: () => void
  ) => {
    const request = await mapToCreateProductParams(
      formData,
      toggleImageProcessing
    );

    if (request) {
      if (request === "Image failed to upload") {
        showToast({
          type: "error",
          title: "Error uploading image file",
          subText: "Please try again",
        });
      }

      if (typeof request === "object") {
        if (request?.purchasePrice > request?.sellingPrice) {
          showToast({
            type: "error",
            title: "Selling Price must be greater than Cost Price",
            subText: "Please try again",
          });
        } else if (request?.thresholdLowStock > request?.availableStock) {
          showToast({
            type: "error",
            title: "Quantity must be greater than Threshold for Low Stock",
            subText: "Please try again",
          });
        } else {
          addCustomerProduct(request).then((response) => {
            if (response.error) {
              showToast({
                type: "error",
                title: `${
                  response.error.message.includes("name already exists")
                    ? `Product with this ${formData.productName} name already exists for this business`
                    : "Error adding product"
                }`,
                subText: "Please try again",
              });
            } else {
              logAppEvent(add_product_successfully, {});
              showToast({
                type: "success",
                title: `Added Product with name - ${formData.productName}`,
                subText: `Successfully Added  a product!`,
              });

              refetchCustomerProducts();
              handleClose();
            }
          });
        }
      }
    } else {
      showToast({
        type: "error",
        title: "Error - Please fill all required fields",
        subText: "Please try again",
      });
    }
  };

  return {
    customerProduct,
    submitNewCustomerProduct,
    imageProcessing,
  };
};

export const mapToCreateProductParams = async (
  formData: TProduct,
  toggleImageProcessing: () => void
): Promise<CreateProductAPIRequest | string> => {
  toggleImageProcessing();

  const dataWithoutImage = omit(formData, ["productImage", "category"]);
  const rawProductImage = formData?.productImage as File | null;
  let processedFileImage: null | string = null;

  if (rawProductImage) {
    const uploadedFile = await uploadMedia(rawProductImage);
    if (!uploadedFile) {
      toggleImageProcessing();
      return "Image failed to upload";
    }
    processedFileImage = uploadedFile[0]?.url;
  }

  toggleImageProcessing();

  const newProduct = {
    ...dataWithoutImage,
    ...{ productImage: processedFileImage as string },
    sellingPrice: Number(formData.sellingPrice),
    purchasePrice: Number(formData.costPrice),
    availableStock: Number(formData.quantity),
    thresholdLowStock: Number(formData.thresholdLowStock),
    categoryId: formData.category,
  };

  return newProduct;
};
