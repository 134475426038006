import { gql, useQuery } from "urql";

export interface IBanks {
  bankName: string;
  bankCode: string;
}

const getBanksQuery = gql`
  query {
    getBanks(metadata: { size: 300, sortOrder: "asc", sortBy: "bankName" }) {
      nodes {
        bankName
        bankCode
      }
    }
  }
`;

export const useGetBanks = () => {
  return useQuery({
    query: getBanksQuery,
  });
};
