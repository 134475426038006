// cursor based pagination

import clsx from "clsx";
import { useLocalSearchParams } from "hooks";
import { StyledPagination } from "./styles";
import { ReactComponent as CheveronIcon } from "assets/icons/chevron-left.svg";

export const VPaginationAlt = ({
  hasNextPage,
  getPageNumber = () => {},
  disabled = false,
}: {
  getPageNumber?: (pageNumber: number) => void;
  hasNextPage: boolean;
  disabled: boolean;
}) => {
  const { searchParams, addSearchParams } = useLocalSearchParams();

  const pageNumber = parseInt(searchParams.get("page") ?? "1");
  const isFirstpage = pageNumber <= 1;
  const isLastPage = !hasNextPage;

  const handlePageChange = async (page: number) => {
    addSearchParams("page", page?.toString());
    getPageNumber(page);
  };
  return (
    <StyledPagination>
      {!isFirstpage && (
        <button
          onClick={() => handlePageChange(pageNumber - 1)}
          className={clsx("pagination__pill", "active__pill")}
          disabled={disabled}
        >
          <CheveronIcon height={10} />
        </button>
      )}

      {!isLastPage && (
        <button
          onClick={() => handlePageChange(pageNumber + 1)}
          className={clsx("pagination__pill", "active__pill", "next__icon")}
          disabled={disabled}
        >
          <CheveronIcon height={10} />
        </button>
      )}
    </StyledPagination>
  );
};
