import { useForm } from "react-hook-form";
import { useCompleteBusinessLevelThree, KycLevelThreeProps } from "api";
import { showToast } from "components/common";
import { useGetKycDetailsHook } from "./useGetKycDetailsHook";

export const useCompleteBusinessLevelThreeHook = (callBack?: Function) => {
  const hookForm = useForm<any>();
  const { handleSubmit, reset, watch } = hookForm;

  const { handleCompleteBusinessLevelThree } = useCompleteBusinessLevelThree();

  const { refetchKycDetails } = useGetKycDetailsHook();

  const submitKycLevelThree = async (data: KycLevelThreeProps) => {
    await handleCompleteBusinessLevelThree(data, () => {
      reset();
      showToast({
        subText: "Your KYC information has been submitted",
      });
    });
    refetchKycDetails();
  };

  return {
    hookForm,
    handleSubmit,
    reset,
    watch,
    submitKycLevelThree,
  };
};
