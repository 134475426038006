import {
  ABanks,
  BANK_PAYMENT_TYPES,
  IBeneficiery,
  useGetPaymentBanks,
  useGetAppConfig,
} from "api";
import { TRANSFER_INPUT_FIELDS } from "components/account";
import { useMemo } from "react";

export const useAccountPageBankHook = (
  payment_type: BANK_PAYMENT_TYPES,
  bank?: string,
  accountName?: string,
  selectedBeneficiary: IBeneficiery | null = null
) => {
  const [bankNameList] = useGetPaymentBanks(payment_type);

  // populate bank name dropdown for USSD or Bank Transfer
  const {
    data: bankData,
    fetching: fetchingBank,
    error: bankError,
  } = bankNameList;

  const SELECT_BANKS_OPTIONS = useMemo(() => {
    return !fetchingBank && !bankError
      ? bankData?.getPaymentBanks?.map(
          ({ bankName, logo, bankCode }: ABanks) => {
            return { value: bankName, label: bankName, logo, bankCode };
          }
        )
      : [];
  }, [bankData?.getPaymentBanks, bankError, fetchingBank]);

  const selectedBankCode = !!bank
    ? String(SELECT_BANKS_OPTIONS?.find((b: any) => b.value === bank)?.bankCode)
    : "";

  const transferInputList = useMemo(() => {
    return TRANSFER_INPUT_FIELDS({
      SELECT_BANKS_OPTIONS,
      bank,
      accountName,
      selectedBeneficiary,
    });
  }, [SELECT_BANKS_OPTIONS, bank, accountName, selectedBeneficiary]);

  // transaction fee
  const [fetchAppConfig] = useGetAppConfig();

  const transactionFeeAmount =
    fetchAppConfig?.data?.getAppConfig?.transactionFee?.transactionFeeAmount;

  const transactionFeeType =
    fetchAppConfig?.data?.getAppConfig?.transactionFee?.transactionFeeType;

  const transactionFeeCap =
    fetchAppConfig?.data?.getAppConfig?.transactionFee?.transactionFeeCap;

  return {
    SELECT_BANKS_OPTIONS,
    fetchingBank,
    bankError,
    selectedBankCode,
    transferInputList,
    transactionFeeAmount,
    transactionFeeType,
    transactionFeeCap,
  };
};
