import styled from "styled-components";
import {
  VModalOverlay,
  VModdalContainer,
} from "components/common/VModal/styles";
import { stylingUtils } from "styles";

const {
  rem,
  mediaQuery: { down },
} = stylingUtils;

export const StyledNavbarOverlay = styled(VModalOverlay)`
  left: 300;
  justify-content: start;
`;

export const StyledNavbarContainer = styled(VModdalContainer)`
  border-radius: ${rem(0)};
  height: 100vh;
  margin: 0;
  overflow-y: hidden;
  opacity: 1;
  visibility: visible;
  transition: 0.5s all ease-in-out;

  ${down("tablet")} {
    max-width: ${rem(400)};
    padding: ${rem(20)};
  }
`;
