import styled from "styled-components/macro";
import { stylingUtils } from "styles";

const { color, rem } = stylingUtils;

export const ModalContainer = styled("div")`
  display: grid;
  justify-content: center;

  h2 {
    font-family: "Gilmer";
    font-style: normal;
    font-weight: 700;
    font-size: ${rem(18)};
    line-height: ${rem(24)};
    color: ${color("black01")};
    margin-top: ${rem(30)};
  }

  p {
    font-family: "Gilmer";
    font-style: normal;
    font-weight: 400;
    font-size: ${rem(16)};
    line-height: ${rem(22)};
    color: ${color("ashText")};
    margin-top: ${rem(15)};
  }

  .item {
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .button {
    display: flex;
    justify-content: center;
    margin-top: ${rem(40)};
    margin-bottom: ${rem(20)};
  }
`;
