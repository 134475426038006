import MailIcon from "assets/images/svgs/MailIcon";
import MasterCardIcon from "assets/images/svgs/MasterCardIcon";
import { VEmptyState } from "components/common";
import { useAuthUserConstants } from "store";
import {
  StyledBillingPaymentMethod,
  StyledBillingPaymentMethodHeader,
  StyledBillPaymentDebitCard,
  StyledPaymentDebitCardDetails,
} from "./styles";

export const BillingPaymentMethod = () => {
  const {
    user,
    status: { hasCardDetails },
    cardDetails,
  } = useAuthUserConstants();

  return (
    <StyledBillingPaymentMethod>
      <StyledBillingPaymentMethodHeader>
        <h3>Payment Method</h3>
        <p>Your payment method</p>
      </StyledBillingPaymentMethodHeader>
      {!hasCardDetails ? (
        <VEmptyState
          title="No payment details"
          subText="Upgrade your plan to add payment details"
          style={{
            height: "150px",
          }}
        />
      ) : (
        <StyledBillPaymentDebitCard>
          <StyledPaymentDebitCardDetails>
            <div className="master__cardicon">
              <MasterCardIcon width={24} />
            </div>
            <div className="detail__items">
              <p>
                {cardDetails?.first_6digits} **** ****{" "}
                {cardDetails?.last_4digits}
              </p>
              <p>{cardDetails?.expiry}</p>
              <p>
                <MailIcon width={20} />
                <span>m{user?.email}</span>
              </p>
            </div>
          </StyledPaymentDebitCardDetails>
          {/* <VButton
            text="Edit"
            isSlim
            isOutline
            noborder={false}
            style={{ width: '100%' }}
          /> */}
        </StyledBillPaymentDebitCard>
      )}
    </StyledBillingPaymentMethod>
  );
};
