import { formatNumberToCurrency } from "utils";
import { CardProps } from "components/common/types";
import { StyledDashboardIconWrapper } from "components/dashboard";
import TotalSalesValueIcon from "assets/images/svgs/TotalSalesValueIcon";
import TotalProductsIcon from "assets/images/svgs/TotalProductsIcon";
import TotalServiceIcon from "assets/images/svgs/TotalServiceIcon";
import PurchasedIcon from "assets/images/svgs/PurchasedIcon";

export const INVENTORY_CARD_DATA = ({
  data,
}: {
  data: Record<string, any>;
}): CardProps[] => {
  const {
    currentStockValue = 0,
    totalStock = 0,
    countOfCurrentServices = 0,
    count = 0,
  } = data;
  return [
    {
      title: "Stock Value",
      icon: () => <TotalSalesValueIcon />,
      amount: formatNumberToCurrency({ number: Number(currentStockValue) }),
    },
    {
      title: `Total Products`,
      icon: () => (
        <StyledDashboardIconWrapper color="#E8F5F1">
          <TotalProductsIcon />
        </StyledDashboardIconWrapper>
      ),
      amount: formatNumberToCurrency({
        number: Number(totalStock),
        removeCurrency: true,
      }),
    },
    {
      title: `Total Services`,
      icon: () => (
        <StyledDashboardIconWrapper color="#FFE0DE">
          <TotalServiceIcon />
        </StyledDashboardIconWrapper>
      ),
      amount: formatNumberToCurrency({
        number: Number(countOfCurrentServices),
        removeCurrency: true,
      }),
    },
    {
      title: `Most Purchased`,
      icon: () => (
        <StyledDashboardIconWrapper color="#E0EEFF">
          <PurchasedIcon />
        </StyledDashboardIconWrapper>
      ),
      amount: formatNumberToCurrency({
        number: Number(count),
        removeCurrency: true,
      }),
    },
  ];
};
