import { StyledInventoryTable } from "components/Inventory";
import styled from "styled-components/macro";
import { FlexAMixin, FlexJAMixin, stylingUtils } from "styles";

const { color, rem, allExceptLastChild } = stylingUtils;

export const StyledSalesTable = styled(StyledInventoryTable)`
  .payment__method {
    ${FlexAMixin}
    gap: ${rem(5)};

    &--image {
      background-color: ${color("ash01")};
      ${FlexJAMixin};
      padding: ${rem(8)};
      border-radius: 999%;

      img {
        height: 15px;
        width: 15px;
      }
      flex: none;
    }

    p {
      text-transform: capitalize;
    }
  }

  .sales__expanded {
    ${allExceptLastChild} {
      margin-bottom: 4px;
    }

    &--header {
      font-weight: 600;
    }
  }
`;
