import VerticalDropdown from "assets/icons/vertical-dot.svg";
import {
  CustomTableProps,
  VDropdownProps,
  CardProps,
} from "components/common/types";
import { APP_ROUTES } from "routes";
import {
  capitalizeWord,
  formatNumberToCurrency,
  getFormattedDate,
  formatUnderScore,
} from "utils";
import { Status, StyledInvoiceIconWrapper } from "./styles";
import { InputListType } from "components/common/types";
import theme from "styles/theme";

import { VDropdown } from "components/common";

import { ReactComponent as UssdIcon } from "assets/images/svgs/ussd.svg";
import { ReactComponent as CardIcon } from "assets/images/svgs/card-payment.svg";
import { ReactComponent as BankIcon } from "assets/images/svgs/bank-icon.svg";
import ChartIcon from "../../assets/images/svgs/ChartIcon";

export interface ItemDetails {
  item?: string;
  customerProductName?: string;
  customerServiceName?: string;
  quantity: number;
  amount: string | number;
  sellingPrice: string | number;
}
export interface DetailsProps {
  discount?: string;
  vat?: string | number;
  deliveryFee?: string | number;
  subTotal: string | number;
  total: string | number;
  tableDetails: any;
  invoiceStatusBg: string;
  invoicePartlyPayments?: string;
}

export const INVOICE_PAGE_TABS = [
  {
    key: "products",
    label: "Products",
    link: `${APP_ROUTES.INVOICE_PRODUCTS}`,
    secondaryLink: `${APP_ROUTES.INVOICE_PRODUCTS}`,
  },
  {
    key: "services",
    label: "Services",
    link: `${APP_ROUTES.INVOICE_SERVICES}`,
  },
];

export const INVOICE_COLUMNS = ({
  handleInvoiceModalOpen,
  handleViewEditInvoice,
  handleInvoiceDeleteModal,
}: {
  handleInvoiceModalOpen: (_id: string) => void;
  handleViewEditInvoice: (_id: string) => void;
  handleInvoiceDeleteModal: (_id: string) => void;
}): CustomTableProps["columns"] => {
  return [
    {
      headerName: "Date",
      key: "createdAt",
      formatter: (createdAt) => getFormattedDate(createdAt),
    },
    {
      headerName: "Invoice ID",
      key: "invoiceNumber",
      isBold: false,
      useZero: false,
    },
    {
      headerName: "Name",
      key: "clientName",
      formatter: (clientName) => capitalizeWord(clientName || ""),
    },
    {
      headerName: "Amount",
      key: "invoiceTotal",
      formatter: (invoiceTotal, value) => {
        const Amount = formatNumberToCurrency({
          number:
            value?.invoiceStatus === "PAID"
              ? invoiceTotal
              : value?.amountDue || 0,
        });
        return Amount;
      },
    },
    {
      headerName: "Invoice Type",
      key: "transactionType",
      formatter: (transactionType) => capitalizeWord(transactionType || ""),
    },
    {
      headerName: "Status",
      key: "invoiceStatus",
      renderCell: (invoiceStatus: any, { _id }: { _id: string }) => {
        let customeStyles = {
          color:
            invoiceStatus === "OVERDUE"
              ? "#EA4336"
              : invoiceStatus === "PAID"
              ? "#34A853"
              : invoiceStatus === "UNPAID"
              ? "#F0A75A"
              : invoiceStatus === "PART_PAYMENT"
              ? "#374B58"
              : "",
          backgroundColor:
            invoiceStatus === "OVERDUE"
              ? "#FFF7EE"
              : invoiceStatus === "PAID"
              ? "#D2EEDF"
              : invoiceStatus === "UNPAID"
              ? "#FFF7EE"
              : invoiceStatus === "PART_PAYMENT"
              ? "#E8E8E8"
              : "",
          flex: "none",
        };
        return (
          <Status>
            <li style={customeStyles}>
              <span>
                {invoiceStatus === "PART_PAYMENT"
                  ? "Partly paid"
                  : formatUnderScore(invoiceStatus)}
              </span>
            </li>
          </Status>
        );
      },
    },
    {
      headerName: "Action",
      key: "dropdown",
      renderCell: (invoiceStatus: any, { _id }: { _id: string }) => (
        <VDropdown
          dropdownButton={
            <button className="vertical__drop--down">
              <img src={VerticalDropdown} alt="vertical-dropdown" />
            </button>
          }
          dropdownItems={[
            ...INVOICE_ACTIONS_DROPDOWNS({
              openInvoiceModal: () => {
                if (_id) {
                  handleInvoiceModalOpen(_id);
                }
              },
              viewEditInvoice: () => {
                if (_id) {
                  handleViewEditInvoice(_id);
                }
              },
              openDeleteModal: () => {
                if (_id) {
                  handleInvoiceDeleteModal(_id);
                }
              },
              invoiceStatus,
            }),
          ].map((item) => ({
            ...item,
          }))}
          isLeft={true}
        />
      ),
    },
  ];
};

export const INVOICE_ACTIONS_DROPDOWNS = ({
  openInvoiceModal,
  viewEditInvoice,
  openDeleteModal,
  invoiceStatus,
}: {
  openInvoiceModal: () => void;
  viewEditInvoice: () => void;
  openDeleteModal: () => void;
  invoiceStatus: string;
}): VDropdownProps["dropdownItems"] => {
  const noEdit = ["PART_PAYMENT", "PAID"].includes(invoiceStatus);

  if (noEdit) {
    return [
      {
        key: "view",
        label: "View",
        onClick: () => openInvoiceModal(),
        styles: { color: theme.colors.green },
      },
    ];
  }

  return [
    {
      key: "view",
      label: "View",
      onClick: () => openInvoiceModal(),
      styles: { color: theme.colors.green },
    },
    {
      key: "edit",
      label: "Edit",
      onClick: () => viewEditInvoice(),
      styles: { color: theme.colors.lightBlue },
    },
    {
      key: "delete",
      label: "Delete",
      onClick: () => openDeleteModal(),
      styles: { color: theme.colors.red },
    },
  ];
};

export const ITEM_TABLE_HEAD = ["S/N", "item", "Qty", "Amount"];

export const recordPayment: InputListType[] = [
  {
    name: "amount",
    label: "Amount to be paid (NGN)",
    disabled: true,
    type: "amount",
  },
  {
    name: "paymentAmount",
    label: "Amount paid (NGN)",
    placeholder: "Enter amount paid",
    type: "amount",
    required: true,
    validation: { required: "Please enter amount paid" },
  },
  {
    name: "amountRemaining",
    label: "Amount remaining (NGN)",
    disabled: true,
    type: "amount",
  },
];

// payment types
export const paymentCards = [
  {
    name: "USSD Code",
    icon: <UssdIcon />,
    value: "CASH",
  },
  {
    name: "Card Payment",
    icon: <CardIcon />,
    value: "POS",
  },
  {
    name: "Bank Transfer",
    icon: <BankIcon />,
    value: "BANK",
  },
];

// cards
export const INVOICE_CARDS_DATA = ({
  paidValue,
  unpaidValue,
  partlyPaidValue,
  overdueValue,
}: {
  paidValue: number;
  unpaidValue: number;
  partlyPaidValue: number;
  overdueValue: number;
}): CardProps[] => {
  const all =
    Number(paidValue || 0) +
    Number(unpaidValue || 0) +
    Number(partlyPaidValue || 0) +
    Number(overdueValue || 0);

  return [
    {
      title: `All`,
      icon: () => (
        <StyledInvoiceIconWrapper color="#E0EEFF">
          <ChartIcon style={{ stroke: "#0077FF" }} />
        </StyledInvoiceIconWrapper>
      ),
      amount: formatNumberToCurrency({
        number: Number(all),
        removeCurrency: true,
      }),
    },
    {
      title: "Paid",
      icon: () => (
        <StyledInvoiceIconWrapper color="#E8F5F1">
          <ChartIcon style={{ stroke: "#34A853" }} />
        </StyledInvoiceIconWrapper>
      ),
      amount: formatNumberToCurrency({
        number: Number(paidValue),
        removeCurrency: true,
      }),
    },
    {
      title: `Unpaid`,
      icon: () => (
        <StyledInvoiceIconWrapper color="#FBECDB">
          <ChartIcon style={{ stroke: "#F1A85A" }} />
        </StyledInvoiceIconWrapper>
      ),
      amount: formatNumberToCurrency({
        number: Number(unpaidValue),
        removeCurrency: true,
      }),
    },
    {
      title: `Partly Paid`,
      icon: () => (
        <StyledInvoiceIconWrapper color="#EBEBEB">
          <ChartIcon style={{ stroke: "#374B58" }} />
        </StyledInvoiceIconWrapper>
      ),
      amount: formatNumberToCurrency({
        number: Number(partlyPaidValue),
        removeCurrency: true,
      }),
    },
    {
      title: `Overdue`,
      icon: () => (
        <StyledInvoiceIconWrapper color="#FFE0DE">
          <ChartIcon style={{ stroke: "#EA4336" }} />
        </StyledInvoiceIconWrapper>
      ),
      amount: formatNumberToCurrency({
        number: Number(overdueValue),
        removeCurrency: true,
      }),
    },
  ];
};

export const INVOICE_BASIC_INFO = () => {
  return [
    {
      name: "clientName",
      label: "Customer name",
      type: "text",
      disabled: true,
    },
    {
      name: "clientPhone",
      label: "Phone number",
      type: "tel",
      disabled: true,
    },
    {
      name: "issueDate",
      label: "Issue date",
      type: "text",
      disabled: true,
    },
    {
      name: "dueDate",
      label: "Due date",
      type: "text",
      disabled: true,
    },
  ];
};

export const PRODUCT_INPUT_LIST = () => {
  return [
    {
      name: "productName",
      label: "Product name",
      type: "text",
      disabled: true,
    },
    {
      name: "quantity",
      label: "Quantity",
      type: "number",
      required: true,
      validation: { required: "Please enter quantity" },
    },
    {
      name: "purchasePrice",
      label: "Cost price (NGN)",
      type: "number",
      disabled: true,
    },
    {
      name: "sellingPrice",
      label: "Selling price (NGN)",
      type: "number",
      required: true,
      validation: { required: "Please enter selling price" },
    },
  ];
};

export const SERVICE_INPUT_LIST = () => {
  return [
    {
      name: "serviceName",
      label: "Service name",
      type: "text",
      disabled: true,
    },
    {
      name: "quantity",
      label: "Quantity",
      type: "number",
      required: true,
      validation: { required: "Please enter quantity" },
    },
    {
      name: "sellingPrice",
      label: "Service price (NGN)",
      type: "number",
      required: true,
      validation: { required: "Please enter service price" },
    },
  ];
};

export const ADD_INVOICE_VAT: InputListType[] = [
  {
    name: "includeTax",
    label: "VAT",
    disabled: true,
  },
  {
    name: "discount",
    label: "Discount (NGN)",
    type: "number",
  },
];

// modal status colors
export const INVOICE_COLOR: {
  [key: string]: {
    textColor: string;
    bgColor: string;
  };
} = {
  PART_PAYMENT: {
    textColor: "#374B58",
    bgColor: "#EBEBEB",
  },

  UNPAID: {
    textColor: "#E57A1A",
    bgColor: "#F9F4EF",
  },
  OVERDUE: {
    textColor: "#EA4336",
    bgColor: "#FDECEB",
  },

  PAID: {
    textColor: "#34A853",
    bgColor: "#EBF6EE",
  },
  POS: {
    textColor: "",
    bgColor: "rgba(128, 188, 255, 0.3)",
  },
};
