import { AppStorage } from "utils";

const { getFromStore } = new AppStorage();

const getIframeUrl = () => {
  const token = getFromStore("token");
  const env = process.env.NODE_ENV;

  if (env === "production") {
    return `https://shop.alerzo.com/exchangeToken?veedez=prod&token=${token}`;
  } else {
    return `https://shop-stg.alerzo.com/exchangeToken?veedez=stg&token=${token}`;
  }
};

export default getIframeUrl;
