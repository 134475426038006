import { gql, useMutation } from "urql";
import { handleRequestCall } from "utils/handleRequestCall";
import { showToast } from "components/common";

const deleteInventoryProductMutation = gql`
  mutation ($productId: String!, $status: String!, $reason: String!) {
    toggleProductStatus(productId: $productId, status: $status, reason: $reason)
  }
`;

export const useDeleteInventoryProduct = () => {
  const [inventoryProduct, deleteInventoryProduct] = useMutation(
    deleteInventoryProductMutation
  );

  const handleDeleteInventoryProduct = async (
    data: { productId: string; reason: String; status: String },
    callBack?: () => void
  ) => {
    try {
      const req = await deleteInventoryProduct(data);
      await handleRequestCall({
        request: req,
        callBack: () => {
          callBack?.();
        },
      });
    } catch (e) {
      showToast({
        type: "error",
        title: "An error occured",
        subText: "Please try again",
      });
      console.error(e);
    }
  };

  return {
    handleDeleteInventoryProduct,
    inventoryProduct,
  };
};
