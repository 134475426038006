import { VButton, VModal } from "components/common";
import {
  StyledCreateCategory,
  StyledCreateCategoryForm,
  AddCategoryButton,
} from "./styles";
import { VModalProps } from "components/common/types";
import { useAddCategoryHook } from "hooks/components/useAddCategoryHook";
import { ReactComponent as PlusIcon } from "assets/images/svgs/plus-icon.svg";
import { InputWithDel } from "./input-with-delete";

type InventoryTypes = "PRODUCT" | "SERVICE";

const NewCategoryModal = ({
  open,
  close,
  inventoryType = "PRODUCT",
}: VModalProps & { inventoryType?: InventoryTypes }) => {
  const {
    submitCategories,
    isLoading,
    inputArray,
    handleRemoveInput,
    handleAddInput,
    setInputArray,
  } = useAddCategoryHook(close);

  const closeModal = () => {
    close();
    setInputArray([(Math.random() * 500).toString(20)]);
  };

  return (
    <VModal open={open} close={closeModal} title="Add New Category" width={650}>
      <StyledCreateCategory>
        <StyledCreateCategoryForm>
          <form
            onSubmit={(e) => submitCategories(e, inventoryType)}
            className="category__form"
          >
            {inputArray.map((item, index) => (
              <InputWithDel
                onDelete={() => {
                  handleRemoveInput(item);
                }}
                id={index}
                name="categories[]"
                key={item}
                arraySize={inputArray.length - 1}
              />
            ))}

            <AddCategoryButton onClick={handleAddInput}>
              <div className="add_icon">
                <PlusIcon style={{ width: "11px" }} />
              </div>
              <h4>Add Another Category</h4>
            </AddCategoryButton>
            <div className="form--button">
              <VButton
                isLoading={isLoading}
                height="50px"
                text="Save"
                minWidth="295px"
                mobWidth="100%"
              />
            </div>
          </form>
        </StyledCreateCategoryForm>
      </StyledCreateCategory>
    </VModal>
  );
};

export default NewCategoryModal;
