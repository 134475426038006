import {
  VButton,
  VChipGroup,
  VEmptyState,
  VPagination,
  VTable,
} from "components/common";
import { useModal, useServicePageHook } from "hooks";
import { ReactComponent as PlusIcon } from "assets/images/svgs/plus-icon.svg";
import { INVENTORY_SERVICE_COLUMNS } from "../constants";
import { StyledInventoryTable } from "./styles";
import { AddServiceModal } from "../services";
import { ServiceDetailAPI } from "api/inventory/types";
import { NewCategoryModal } from "../categories";
import CategoryFilter from "assets/icons/category-filter";

const InventoryServiceTable = ({
  handleView,
  handleOpenEditModal,
}: {
  handleView: (customerService: ServiceDetailAPI) => void;
  handleOpenEditModal: (customerService: ServiceDetailAPI) => void;
}) => {
  const { open: openCategoryModal, toggleModal: toggleCategoryModal } =
    useModal();

  const {
    pageNumber,
    handleChangePage,
    fetching,
    error,
    extractedData,
    serviceCategories,
    handleCategoryFilter,
    selectedServiceCategory,
  } = useServicePageHook({});
  const { open, toggleModal } = useModal();

  if (error)
    return (
      <VEmptyState
        title="Error Fetching Data!"
        subText="An error occurred getting data"
      />
    );

  const { nodes: customerService, pageInfo } = extractedData;

  return (
    <>
      <StyledInventoryTable>
        <VChipGroup
          activeKey={selectedServiceCategory || ""}
          getCurrentChipKey={(categoryId) => handleCategoryFilter(categoryId)}
          chips={serviceCategories}
        >
          <>
            <VButton
              text="New Category"
              isOutline
              height="30px"
              isSlim
              noborder={false}
              onClick={toggleCategoryModal}
            />
            <VButton
              text="Add Service"
              height="30px"
              icon={<PlusIcon />}
              isSlim
              onClick={toggleModal}
            />
            <CategoryFilter
              activeKey={selectedServiceCategory || ""}
              getCurrentChipKey={(categoryId) =>
                handleCategoryFilter(categoryId)
              }
              chips={serviceCategories}
            />
          </>
        </VChipGroup>
        <VTable
          isLoading={fetching}
          columns={INVENTORY_SERVICE_COLUMNS(handleView, handleOpenEditModal)}
          data={customerService}
          emptyContent={{
            title: "No Service Added Yet!",
            subText: "Your history will appear here when you have one",
          }}
        />
        <VPagination
          pageInfo={pageInfo}
          pageNumber={pageNumber}
          getPageNumber={(pageNumber: number) => handleChangePage(pageNumber)}
        />
      </StyledInventoryTable>
      <AddServiceModal open={open} close={toggleModal} />
      <NewCategoryModal
        open={openCategoryModal}
        close={toggleCategoryModal}
        inventoryType="SERVICE"
      />
    </>
  );
};

export default InventoryServiceTable;
