import { useMemo } from "react";

import { EXPENSE_CARD_DATA } from "components/reports/report-invoices/constants";
import { useGetReportExpenseHook } from "api";
import useReportFilterHook from "./useReportFilterHook";

export const useExpenseReportPageHook = () => {
  const { dateFilter } = useReportFilterHook();

  const { data } = useGetReportExpenseHook({
    filters: dateFilter,
    createdAt: dateFilter?.createdAt,
  });

  const cardRef = JSON.stringify(data?.getExpenseStat);

  const EXPENSES_CARDS = useMemo(() => {
    return EXPENSE_CARD_DATA(data?.getExpenseStat);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardRef]);

  return {
    EXPENSES_CARDS,
    expenseRatio: data.getExpensesBreakdown,
    expenseByCategory: data?.getExpensesByCategory,
    data,
  };
};
