import styled from "styled-components";
import { FlexColumn, stylingUtils } from "styles";
import slantingPos from "assets/images/pngs/slanting-veedez-pos.png";

const {
  rem,
  color,
  mediaQuery: { down },
} = stylingUtils;

export const StyledDashboardWelcomeTabsWrapper = styled("section")`
  display: flex;
  gap: 1rem;
  width: 100%;

  ${down("tablet")} {
    flex-wrap: wrap;
  }
`;

export const StyledDashboardAdvert = styled("article")`
  order: 2;
  /* width: ${rem(250)}; */
  flex: none;

  img {
    width: ${rem(300)};
  }

  @media (max-width: 700px) {
    margin: 0 auto;
  }
`;

export const StyledDashboardWelcomeTabs = styled("div")`
  flex: auto;
  order: 1;

  .wrapper {
    ${FlexColumn};
    gap: ${rem(20)};
  }

  .subtitle {
    max-width: ${rem(550)};
  }
`;

export const StyledUserWelcomeProgress = styled("div")`
  ${FlexColumn};
  gap: ${rem(10)};
  p {
    margin-left: auto;
    text-align: right;
  }
`;

export const StyledDashboardTabCardWrapper = styled("section")`
  background-color: ${color("ash08")};
  padding: ${rem(20)};
  border-radius: ${rem(10)};
`;

export const StyledDashboardTabCard = styled("label")<{ isActive: boolean }>`
  display: flex;
  gap: ${rem(20)};
  background-color: ${({ isActive }) => isActive && "white"};
  padding: 0 ${rem(5)} 0 ${rem(15)};
  border-radius: ${rem(10)};
  border: ${({ isActive }) =>
    isActive ? `1px solid #0077FF` : "1px solid transparent"};
  cursor: ${({ isActive }) => (isActive ? "not-allowed" : "pointer")};

  .radio-checked {
    ${FlexColumn};
    align-items: center;
    position: relative;
    width: ${rem(30)};
    flex: none;

    .rod {
      display: ${({ isActive }) => isActive && "none"};
      width: 1px;
      height: 60%;
      background-color: ${color("ashRadio")};
      position: absolute;
    }

    .rod-end {
      bottom: 0 !important;
      transform: translateY(5px);
    }

    input[type="radio"] {
      width: ${rem(20)};
      height: ${rem(20)};
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 10;
    }
  }

  .content {
    padding: ${rem(15)} 0;
    flex: auto;

    h5 {
      font-size: ${rem(14)};
      margin-bottom: ${rem(5)};
      font-weight: 500;
    }

    p {
      color: ${color("ash03")};
    }
  }

  .right-arrow {
    transform: rotate(-90deg);
  }
`;

export const StyledWelcomeTabAction = styled("button")`
  width: ${rem(35)};
  height: ${rem(35)};
  border-radius: 99999%;
  background-color: ${color("ash08")};
`;

export const OrderPosStyles = styled("div")`
  width: ${rem(266)};
  height: ${rem(482)};
  background-color: ${color("darkBlue")};
  border-radius: ${rem(14)};
  background-image: url(${slantingPos});
  background-repeat: no-repeat;
  background-position: bottom right;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h1 {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #f8fcff;
    max-width: ${rem(150)};
  }

  .relative {
    position: relative;
    font-weight: 600;
    font-size: ${rem(14)};
    line-height: ${rem(17)};
    width: ${rem(170)};
    height: ${rem(40)};
  }

  .icon {
    position: absolute;
    right: ${rem(8)};
    align-self: center;
    fill: #f8fcff;
    width: ${rem(9)}!important;
    height: ${rem(18)}!important;
  }
`;
