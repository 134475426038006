const TodayExpenseIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0.86 0.53 20.64 20.7"
      {...props}
    >
      <path
        d="M6.07324 13.7815L9.06638 9.89137L12.4806 12.5733L15.4096 8.79297"
        stroke="#EA4336"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="18.8236"
        cy="3.20052"
        r="1.9222"
        stroke="#EA4336"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.7529 2.12012H6.48516C3.47372 2.12012 1.60645 4.25284 1.60645 7.26428V15.3467C1.60645 18.3581 3.43711 20.4817 6.48516 20.4817H15.0893C18.1007 20.4817 19.968 18.3581 19.968 15.3467V8.30776"
        stroke="#EA4336"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TodayExpenseIcon;
