import { IPagination } from "./../types";
import { gql, useQuery } from "urql";
import { PaginationFragment } from "api/general-queries";
import { useVAppSelector } from "hooks/useStore";
import { SEARCH_TYPE } from "store";

export const BusinessStaffNodeQuery = gql`
  fragment BusinessStaffNodeQuery on User {
    _id
    roleId
    customerId
    phoneNumber
    firstName
    lastName
    email
    staffStatus
    businessId
    business {
      _id
      businessName
    }
    businessRegistered
    userType
    status
    assignedOutlet {
      _id
      businessName
      businessAddress
    }
    employeeId
    staffActivities {
      _id
      businessId
      userId
      createdAt
      updatedAt
      activities {
        _id
        actionPerformed
        actionCompletedOn
        refId
      }
    }
    createdAt
    updatedAt
    updatedBy
    createdBy
    staffActivityId
    profilePicture
    role {
      _id
      roleName
      status
    }
  }
`;

type UseGetBusinessStatffsProps = {
  businessId: string;
  page?: number;
  search?: string;
  status?: string;
};

export const useGetBusinessStaffs = ({
  businessId,
  page = 1,
  search = "",
  status,
}: UseGetBusinessStatffsProps) => {
  const { page: staffPage, term: searchTerm } = useVAppSelector(
    (state) => state.search
  );
  return useQuery({
    query: getBusinssStaffQuery,
    variables: {
      businessId,
      page,
      status,
      searchQuery: staffPage === SEARCH_TYPE.STAFF ? searchTerm : search,
    },
  });
};

const getBusinssStaffQuery = gql`
  ${BusinessStaffNodeQuery},
  query ($businessId: String, $page: Int, $status: CustomerActivityStatus, $searchQuery: String ) {
    getBusinessStaffs(
    filters: {businessId: $businessId, status: $status, searchQuery: $searchQuery}

     metadata: {
        size: 10,
        page: $page,
        sortOrder: "desc"
    }
   ){
   nodes {
      ...BusinessStaffNodeQuery
   }
  , 
   ${PaginationFragment}
  }
  }
`;

export interface IBusinessStaff extends IPagination {
  nodes: IBusinessStaffNode[];
}

export interface IBusinessStaffNode {
  _id: string;
  roleId: string;
  customerId: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  email: string;
  businessId: string;
  business: {
    _id: string;
    businessName: string;
  };
  businessRegistered: boolean;
  userType: string;
  status: string;
  staffStatus: string;
  assignedOutlet: {
    _id: string;
    businessName: string;
    businessAddress: string;
  }[];
  employeeId: string;
  staffActivities: {
    _id: string;
    businessId: string;
    userId: string;
    createdAt: string;
    updatedAt: string;
    activities: {
      _id: string;
      actionPerformed: string;
      actionCompletedOn: string;
      refId: string;
    }[];
  }[];
  createdAt: string;
  updatedAt: string;
  updatedBy: string;
  createdBy: string;
  profilePicture: string;
  staffActivityId: string;

  role: {
    _id: string;
    roleName: string;
    status: string;
  };
}
