import { useForm } from "react-hook-form";
import { useCompleteBusinessLevelOne, KycLevelOneProps } from "api";
import { showToast } from "components/common";
import { useGetKycDetailsHook } from "./useGetKycDetailsHook";

export const useCompleteBusinessLevelOneHook = (callBack?: Function) => {
  const hookForm = useForm<any>();
  const { handleSubmit, reset, watch } = hookForm;

  const { handleCompleteBusinessLevelOne } = useCompleteBusinessLevelOne();

  const { refetchKycDetails } = useGetKycDetailsHook();

  const submitKycLevelOne = async (data: KycLevelOneProps) => {
    await handleCompleteBusinessLevelOne(data, () => {
      reset({ bvn: "", selfie: "" });
      showToast({
        subText: "Your KYC information has been submitted",
      });
    });
    refetchKycDetails();
  };

  return {
    hookForm,
    handleSubmit,
    reset,
    watch,
    submitKycLevelOne,
  };
};
