import { useState } from "react";
import { ReactComponent as TrashIcon } from "assets/images/svgs/trash-icon.svg";
import { InputGroup } from "./styles";
import { StyledInput } from "./styles";

export const InputWithDel = ({ onDelete, id, arraySize, ...rest }: any) => {
  const [show, setShow] = useState(true);

  return show ? (
    <>
      <label htmlFor="">Category</label>
      <InputGroup>
        <StyledInput {...rest} required />
        {arraySize !== id && (
          <button
            className="delete_icon"
            onClick={() => {
              setShow(false);
              if (onDelete) onDelete();
            }}
          >
            <TrashIcon />
          </button>
        )}
      </InputGroup>
    </>
  ) : null;
};
