import { omit } from "lodash";
import { useServicePageHook, useToggle } from "hooks";
import { uploadMedia } from "utils";
import { gql, useMutation } from "urql";
import { handleRequestCall } from "utils/handleRequestCall";
import { useAuthUser } from "store";

const addCustomerServiceMutation = gql`
  mutation (
    $businessId: String!
    $serviceName: String!
    $sellingPrice: Int!
    $categoryId: String
    $serviceImage: String
    $description: String
  ) {
    addCustomerService(
      businessId: $businessId
      serviceName: $serviceName
      sellingPrice: $sellingPrice
      categoryId: $categoryId
      serviceImage: $serviceImage
      description: $description
    ) {
      _id
      businessId
    }
  }
`;

export type IAddCustomerService = {
  businessId: string;
  serviceName: string;
  sellingPrice: string;
  categoryId: string;
  serviceImage: File | null;
  note: string;
};

export type IAddDebtor = {
  debtorName: string;
  phoneNumber: string;
  email: string;
  productBought: string;
  amountOwned: string;
  dueDate: string;
};

export const useAddCustomerService = () => {
  const [customerService, addCustomerService] = useMutation(
    addCustomerServiceMutation
  );
  const [imageProcessing, toggleImageProcessing] = useToggle();
  const { userData } = useAuthUser();

  const { refetchCustomerService } = useServicePageHook({});

  const handleAddCustomerService = async (
    data: IAddCustomerService,
    callBack?: () => void
  ) => {
    toggleImageProcessing();
    const dataWithoutImage = omit(data, ["serviceImage", "note"]);
    const rawServiceImage = data?.serviceImage;
    let processedFileImage: null | string = null;

    try {
      if (rawServiceImage) {
        const uploadedFile = await uploadMedia(rawServiceImage);
        if (!uploadedFile) {
          toggleImageProcessing();
          return;
        }
        processedFileImage = uploadedFile[0]?.url;
      }
      toggleImageProcessing();
      const values = {
        ...dataWithoutImage,
        ...(processedFileImage && { serviceImage: processedFileImage }),
        sellingPrice: parseFloat(data?.sellingPrice),
        businessId: userData?.businessId as string,
        description: data?.note,
      };
      const req = await addCustomerService(values);

      await handleRequestCall({
        request: req,
        callBack: () => {
          refetchCustomerService();
          callBack?.();
        },
      });
    } catch (e) {}
  };

  return {
    handleAddCustomerService,
    customerService,
    isLoading: imageProcessing || customerService?.fetching,
  };
};
