const TodayCustomersIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0.5 21.77 16.79"
      {...props}
    >
      <path
        d="M16.8877 7.89673C18.2827 7.70073 19.3567 6.50473 19.3597 5.05573C19.3597 3.62773 18.3187 2.44373 16.9537 2.21973"
        stroke="#F1A85A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.7285 11.25C20.0795 11.452 21.0225 11.925 21.0225 12.9C21.0225 13.571 20.5785 14.007 19.8605 14.281"
        stroke="#F1A85A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8867 11.6641C7.67273 11.6641 4.92773 12.1511 4.92773 14.0961C4.92773 16.0401 7.65573 16.5411 10.8867 16.5411C14.1007 16.5411 16.8447 16.0591 16.8447 14.1131C16.8447 12.1671 14.1177 11.6641 10.8867 11.6641Z"
        stroke="#F1A85A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8864 8.888C12.9954 8.888 14.7054 7.179 14.7054 5.069C14.7054 2.96 12.9954 1.25 10.8864 1.25C8.77741 1.25 7.06741 2.96 7.06741 5.069C7.05941 7.171 8.75641 8.881 10.8584 8.888H10.8864Z"
        stroke="#F1A85A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.88509 7.89673C3.48909 7.70073 2.41609 6.50473 2.41309 5.05573C2.41309 3.62773 3.45409 2.44373 4.81909 2.21973"
        stroke="#F1A85A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.044 11.25C1.693 11.452 0.75 11.925 0.75 12.9C0.75 13.571 1.194 14.007 1.912 14.281"
        stroke="#F1A85A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TodayCustomersIcon;
