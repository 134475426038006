import { IUserBusinessDetails } from "api";
import {
  BUSINESS_PLANS,
  BUSINESS_PLANS_PRICE_INDEX,
} from "components/auth/signup/constants";
import { OptionType } from "components/common/types";
import { ChangeEvent } from "react";
import { UseQueryState } from "urql";
import { BILLING_CONFIG } from "./config";
import dayjs from "./dayjs";

export const GEO_CODING_KEY = "ceb70d33e76d417f8155faf0565b1c26";
export const CURRENCY_CONVERTER_KEY =
  "fIOzlJoiGVGgspZWVT3y1A==JL6dgPIsLHWuL51w";

export const transformNonEventChange = (
  {
    name,
    value,
  }: { name: string; value?: string | number | File | Blob | any },
  domEvent = {}
): ChangeEvent<HTMLInputElement> => {
  const event = {
    ...domEvent,
    target: {
      ...domEvent,
      name,
      value,
    },
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return event as any;
};

export const accessDeepObject = ({
  key,
  data,
  useZero = true,
}: {
  key: string;
  data: Record<string, any>;
  useZero?: boolean;
}) => {
  const value = key.split(".").reduce((acc, curr) => {
    const returnZero = useZero ? 0 : "N/A";
    return acc[curr] ?? returnZero;
  }, data || {});

  if (typeof value === "object") {
    return JSON.stringify(value);
  }
  return value;
};

export const getEnvKey = (key: string, altKey?: string) => {
  const value = process.env[key] || altKey;

  if (!value) {
    console.error(`environment variable ${key} is not set`);
    return "";
  }

  return value;
};
/* 
  removeCurreny: to format without currency
  isShort: 700000 => 700k
  precision: number of decimal points you want 
*/
export const formatNumberToCurrency = ({
  number,
  currencyCode = "NGN",
  precision = 0,
  language = "en-NG",
  removeCurrency = false,
  isShort = false,
}: {
  number: string | number;
  currencyCode?: string;
  precision?: number;
  language?: string;
  removeCurrency?: boolean;
  isShort?: boolean;
}): string => {
  const formatter = new Intl.NumberFormat(language, {
    style: "currency",
    currency: currencyCode,
    minimumFractionDigits: precision,
    ...(isShort && { notation: "compact" }),
  });

  let value = Number(number);

  if (isNaN(value)) {
    value = 0;
  }

  let result = formatter.format(value);

  if (removeCurrency) {
    result = result.replace(/[^0-9.,]+/g, "");
  }

  return result;
};

export const capitalizeWord = (word: string = "") => {
  let newValue = word[0]?.toUpperCase() + word?.slice(1)?.toLowerCase();
  return newValue?.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
    letter?.toUpperCase()
  );
};

export const abbreviateText = (text: string, length: number): string => {
  return text?.length > length ? `${text?.substring(0, length)} ...` : text;
};

export const formatUnderScore = (value: string) => {
  if (!value) return "";
  let newvalue = value.replaceAll("_", " ");
  return capitalizeWord(newvalue);
};

export const extractFetchedData = <T>({
  result,
  key,
  defaultReturn = {},
}: {
  result: UseQueryState<any, any>;
  key: string;
  defaultReturn?: any;
}) => {
  const extractedData = result?.data?.[key] as T;

  return { ...result, extractedData: extractedData || (defaultReturn as T) };
};

export const getFormattedDate = (date: string) => {
  return dayjs(date).format("DD/MM/YY");
};
export const getFullFormattedDate = (date: string) => {
  return dayjs(date).format("DD/MM/YYYY hh:mm A");
};
export const getModernDateFormat = (date: string) => {
  return dayjs(date).format("D MMMM, YYYY");
};
export const getShortMonthDateFormat = (date: string) => {
  return dayjs(date).format("DD MMM, YYYY");
};
export const getShortDateFormat = (date: string) => {
  return dayjs(date).format("MMM DD, YYYY");
};
export const getReversedDate = (date: string) => {
  return dayjs(date).format("YYYY-MM-DD");
};
export const getHyphenatedDate = (date: string) => {
  return dayjs(date).format("DD-MM-YYYY");
};

export const convertArrayToOptions = (arrayData: string[]) =>
  arrayData.map((item) => ({
    label: capitalizeWord(item),
    value: item,
  }));

export const truncateWord = (
  input: string,
  { length = 10, shape = "..." }: { length?: number; shape?: string } = {}
) =>
  input?.length > length ? `${input?.substring(0, length)}${shape}` : input;

export const uploadMedia = async (uri: Blob | string, type = "business") => {
  const hasProfilePicture = typeof uri === "string";

  if (hasProfilePicture) {
    return [{ url: uri }];
  }

  const date = Date.now();

  const url = `${getEnvKey("REACT_APP_MEDIA_URL")}?id=${date}&type=${type}`;

  try {
    if (uri) {
      const data = new FormData();
      data.append("file", uri);
      const response = await fetch(url, {
        method: "POST",
        body: data,
        headers: {
          Accept: "*/*",
          "Accept-Encoding": "gzip, deflate, br",
        },
      });
      return await response.json();
    }
  } catch (e) {
    console.error(e);
  }
  return null;
};

export const makeId = (length: number) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

/** Get all query url parameters */
export const getUrlQueryEntries = (
  urlQuery = window.location.search as string
): Record<string, string> => {
  // Get the query end from the search url provided
  // Example: ?hi=payhippo&g=you
  const query = urlQuery.replace("?", "");

  // Split if multiple queries
  const queryGroups = query.split("&");

  // Construct pair empty object
  const urlPairs: Record<string, any> = {};

  // Loop through each group
  for (const pair of queryGroups) {
    // Split pair by '='
    const splitPair = pair.split("=");

    const [key, value, ...rest] = splitPair;

    // Check to make sure there is no extra bound '='
    if (splitPair?.length === 2) {
      // Add to object
      urlPairs[key] = value;
    } else {
      // Assign existing value to a new value variable
      let newValue = value;

      // For each extra item, append an '=' sign
      rest.forEach(() => {
        newValue = `${newValue}=`;
      });

      // Add to object
      urlPairs[key] = newValue;
    }
  }

  // Return result
  return urlPairs;
};

export const redirectUrl = `${window.location.origin}/auth`
  .replaceAll(":", "%3A")
  .replaceAll("/", "%2F");

export const sanitzeString = (data: string) =>
  data?.replaceAll(":", "%3A")?.replaceAll("/", "%2F").replaceAll("%20", " ");

export const extractNameFromUrl = (
  url: string,
  { match, replaceStr }: { match?: string; replaceStr?: string } = {}
) => {
  const matches = url.match(/([^/]+)(?=\.\w+$)/);
  let sanitizedUrl = matches ? matches[0] : "";
  if (match) {
    sanitizedUrl.replaceAll(match, replaceStr ?? "");
  }

  return decodeURI(sanitizedUrl);
};

export const constructOAuthRedirect = (
  oAuthUrl: string,
  redirectUri: string
) => {
  const [base, query] = oAuthUrl.split("?");
  const params: Record<string, string> = {};
  query.split("&").forEach((item) => {
    const itemAsArr = item.split("=");
    // eslint-disable-next-line prefer-destructuring
    params[itemAsArr[0]] = itemAsArr[1];
  });

  params.redirect_uri = redirectUri;

  let newUrl = `${base}?`;
  Object.keys(params).forEach((key, index) => {
    if (index === 0) {
      newUrl += `${key}=${params[key]}`;
    } else {
      newUrl += `&${key}=${params[key]}`;
    }
  });

  return newUrl;
};

export const decodeToken = (token: string) => {
  if (!token) return null;
  var base64Url = token.split(".")[1];
  var base64 = base64Url?.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const checkExpiredToken = (exp: number) => {
  const currentTime = Date.now() / 1000;

  return currentTime < exp;
};

export const isTokenExpired = (token: string) => {
  const expiry = decodeToken(token)?.exp;
  const currentTime = Math.floor(new Date().getTime() / 1000);

  if (currentTime < expiry) {
    var timeDifference = expiry - currentTime;
    var differenceDate = new Date(timeDifference * 1000);
    var diffHours = differenceDate.getUTCHours();
    var diffMinutes = differenceDate.getUTCMinutes();

    //Expire the token 5 minutes ahead, in order to get firebase user
    if (diffMinutes <= 5 && diffHours === 0) return true;
  }

  return currentTime >= expiry;
};

export const convertToNigeriaPhoneNumber = (value: string) => `+234${value}`;

export const addAllToOptionsList = <T>(list: T[]) => {
  return [{ label: "All", key: "all", value: "" }, ...list];
};

export const isJsonString = (str: string) => {
  try {
    var result = JSON.parse(str);

    // Handle non-exception-throwing cases:
    // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
    // but... JSON.parse(null) returns null, and typeof null === "object",
    // so we must check for that, too. Thankfully, null is falsey, so this suffices:
    if (result && typeof result === "object") {
      return true;
    }
  } catch (e) {
    return false;
  }

  return false;
};

export const convertToOptions = ({
  records,
  label,
  value,
  useIndex,
}: {
  records: Record<string, any>[];
  label: string;
  value: string;
  useIndex?: boolean;
}) => {
  return records.map((record, index) => ({
    label: record[label] || "",
    value: useIndex ? index : record[value] || "",
  })) as OptionType[];
};
export const calculatePercentage = (value: number, total: number) => {
  return (value / total) * 100;
};

export const numericInputFilter = (evt: any) => {
  var parseControl = false;
  var theEvent = evt || window.event;
  var key = theEvent.keyCode || theEvent.which;
  var keyCheck = String.fromCharCode(key);

  // alow paste
  var ctrlCmdKeyCheck =
    theEvent.ctrlKey || theEvent.metaKey
      ? theEvent.ctrlKey || theEvent.metaKey
      : key === 17 || key === 91
      ? true
      : false;

  // allow left right arrows
  const arrowCheck = key === 39 || key === 37;

  if (key === 86 && ctrlCmdKeyCheck) {
    parseControl = true;
  } else if (arrowCheck) {
    parseControl = true;
  }

  // allow only digits or backspace
  var regex = /[0-9\b]|\./;
  if (!regex.test(keyCheck) && !parseControl) {
    theEvent.returnValue = false;
    if (theEvent.preventDefault) theEvent.preventDefault();
  }
};

export const timeSince = (date: Date) => {
  let seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);

  const SECONDS = {
    YEAR: 31536000,
    MONTH: 2592000,
    DAY: 86400,
    HOUR: 3600,
    MINUTE: 60,
  };

  let interval = seconds / SECONDS.YEAR;

  if (interval > 1) {
    let timeAgo = Math.floor(interval);
    return timeAgo === 1 ? timeAgo + " yr" : timeAgo + " yrs";
  }

  interval = seconds / SECONDS.MONTH;

  if (interval > 1) {
    let timeAgo = Math.floor(interval);
    return timeAgo === 1 ? timeAgo + " month" : timeAgo + " months";
  }

  interval = seconds / SECONDS.DAY;

  if (interval > 1) {
    let timeAgo = Math.floor(interval);
    return timeAgo === 1 ? timeAgo + " day" : timeAgo + " days";
  }

  interval = seconds / SECONDS.HOUR;

  if (interval > 1) {
    let timeAgo = Math.floor(interval);
    return timeAgo === 1 ? timeAgo + " hr" : timeAgo + " hrs";
  }

  interval = seconds / SECONDS.MINUTE;

  if (interval > 1) {
    let timeAgo = Math.floor(interval);
    return timeAgo === 1 ? timeAgo + " min" : timeAgo + " mins";
  }

  let timeAgo = Math.floor(seconds);
  return timeAgo === 1 ? timeAgo + " sec" : timeAgo + " secs";
};

export const throwError = (word: string) => {
  new Promise((_, reject) => reject(word));
};

export const generateTransactionRef = (userId: string) => {
  return `subscribe_${userId}_${makeId(5)}`;
};

export const convertToLocalCurrency = ({
  symbol,
  amount,
  addCurrency = true,
}: {
  symbol: string;
  amount: number | string;
  addCurrency?: boolean;
}) => {
  let converted = `${formatNumberToCurrency({
    number: amount,
    removeCurrency: true,
  })}`;

  if (symbol && addCurrency) {
    converted = `${symbol} ${converted}`;
  }

  return converted;
};

export const hardRefresh = () => {
  window.location.reload();
  return false;
};

export const getBusinessConstants = (business?: IUserBusinessDetails) => {
  const isFreeTrial = !!business?.subscriptionDetails?.freeTrial;
  const isFreeBusinessPlan =
    business?.businessPlan === BUSINESS_PLANS.BASIC && !isFreeTrial;

  const hasNotSubscribed = !business?.subscriptionDetails?.nextPaymentDate;

  // const daysLeftOnPlan = isFreeBusinessPlan
  const daysLeftOnPlan = hasNotSubscribed
    ? 0
    : dayjs(business?.subscriptionDetails?.nextPaymentDate).diff(
        new Date(),
        "day"
      );
  const totalDays = isFreeTrial
    ? BILLING_CONFIG.FREE_TRIAL_PERIOD
    : BILLING_CONFIG.BILLING_CYCLE;

  const daysUsedOnPlan = totalDays - daysLeftOnPlan;

  const daysUsedPercentage = Math.floor((daysUsedOnPlan / totalDays) * 100);

  const hasBusinessPlan = !!business?.businessPlan;

  const hasPaidBusinessPlan =
    hasBusinessPlan &&
    [
      BUSINESS_PLANS.ESSENTIAL,
      BUSINESS_PLANS.PREMIUM,
      BUSINESS_PLANS.ENTERPRISE,
    ].includes(business?.businessPlan);

  const hasNextBusinessPlanHasFree =
    hasPaidBusinessPlan &&
    business?.subscriptionDetails?.nextBusinessPlan === BUSINESS_PLANS.BASIC;

  const userBusinessPlanIndex = BUSINESS_PLANS_PRICE_INDEX.indexOf(
    business?.businessPlan as BUSINESS_PLANS
  );
  const nextBusinessPlanIndex = BUSINESS_PLANS_PRICE_INDEX.indexOf(
    business?.subscriptionDetails?.nextBusinessPlan as BUSINESS_PLANS
  );

  const hasDowngraded = userBusinessPlanIndex > nextBusinessPlanIndex;

  const nextBusinessPlanCode = business?.subscriptionDetails?.nextBusinessPlan;
  const nextPaymentDate = business?.subscriptionDetails?.nextPaymentDate;
  const nextFormattedPaymentDate = !!nextPaymentDate
    ? getShortMonthDateFormat(nextPaymentDate)
    : "";

  return {
    isFreeBusinessPlan,
    isFreeTrial,
    daysLeftOnPlan,
    totalDays,
    daysUsedOnPlan,
    daysUsedPercentage,
    hasNextBusinessPlanHasFree,
    hasDowngraded,
    nextBusinessPlanCode,
    nextPaymentDate,
    nextFormattedPaymentDate,
    business,
  };
};

export const convertToKobo = (amount: number) => {
  return amount * 100;
};
