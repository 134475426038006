import {
  VButton,
  VChipGroup,
  VDropdown,
  VEmptyState,
  VPagination,
  VTable,
} from "components/common";
import { ReactComponent as PlusIcon } from "assets/images/svgs/plus-icon.svg";
import { useProductPageHook } from "hooks";
import { StyledInventoryTable } from "./styles";
import { useModal } from "hooks";
import {
  CREATE_INPUT_LIST,
  EDIT_INPUT_LIST,
} from "components/Inventory/constants";

import ProductModal from "components/Inventory/product/product";
import { NewCategoryModal } from "components/Inventory";
import { TProduct } from "components/Inventory/types";
import VConfirmModal from "components/common/VConfirmModal";
import { Dispatch, SetStateAction, useMemo } from "react";
import { useProductFormHook } from "hooks/useProductFormHook";
import CategoryFilter from "assets/icons/category-filter";
import { VDropdownProps } from "components/common/types";
import ImportProductModal from "../product/import-product-modal";

const InventoryProductTable = () => {
  const {
    shouldOpenConfirmModal,
    closeConfirmModal,
    openConfirmModal,
    setFormData,
    customerProduct,
    imageProcessing,
    changeQuantity,
    editProductHookForm,
    createProductHookForm,
    shouldOpenEditFormModal,
    toggleEditFormModal,
    handleEditProductSubmit,
    handleReset,
    selectedProduct,
    setSelectedProduct,
  } = useProductFormHook();

  const {
    extractedData,
    error,
    inventoryProductColumns,
    fetching,
    pageNumber,
    handleChangePage,
    productCategories,
    handleCategoryFilter,
    selectedProductCategory,
  } = useProductPageHook({});

  const { open, toggleModal } = useModal();
  const {
    open: openImportProductModal,
    toggleModal: toggleImportProductModal,
  } = useModal();
  const {
    open: shouldOpenCreateProductModal,
    toggleModal: toggleCreateProductModal,
  } = useModal();

  const addProductDropdownItems: VDropdownProps["dropdownItems"] = useMemo(
    () => [
      {
        key: "manual-addition",
        label: "Add Product Manually",
        onClick: toggleCreateProductModal,
      },
      {
        key: "import-file",
        label: "Import File",
        onClick: toggleImportProductModal,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const columns = inventoryProductColumns(
    setSelectedProduct as Dispatch<SetStateAction<TProduct>>,
    toggleEditFormModal
  );

  const createProductList = CREATE_INPUT_LIST(productCategories);
  const editProductList = EDIT_INPUT_LIST(changeQuantity);

  if (error)
    return (
      <VEmptyState
        title="Error Fetching Data!"
        subText="An error occur getting data"
      />
    );

  const { nodes: customerProducts, pageInfo } = extractedData;

  return (
    <>
      <StyledInventoryTable>
        <div
          style={{
            zIndex: 99,
          }}
        >
          <VChipGroup
            activeKey={selectedProductCategory || ""}
            getCurrentChipKey={(categoryId) => handleCategoryFilter(categoryId)}
            chips={productCategories}
          >
            <VButton
              text="New Category"
              isOutline
              height="30px"
              isSlim
              noborder={false}
              onClick={toggleModal}
            />
            <VDropdown
              styles={{
                left: "-70%",
                top: "40px",
              }}
              width={194}
              dropdownButton={
                <VButton
                  text="Add Product"
                  height="30px"
                  icon={<PlusIcon />}
                  isSlim
                />
              }
              dropdownItems={addProductDropdownItems}
            />
            <CategoryFilter
              activeKey={selectedProductCategory || ""}
              getCurrentChipKey={(categoryId) =>
                handleCategoryFilter(categoryId)
              }
              chips={productCategories}
            />
          </VChipGroup>
        </div>

        <VTable
          isLoading={fetching}
          columns={columns}
          data={customerProducts}
          emptyContent={{
            title: "No Product Added Yet!",
            subText: "Your history will appear here when you have one",
          }}
        />

        <VPagination
          pageInfo={pageInfo}
          pageNumber={pageNumber}
          getPageNumber={(pageNumber) => handleChangePage(pageNumber)}
        />
      </StyledInventoryTable>
      <NewCategoryModal open={open} close={toggleModal} />

      <ProductModal
        title="Add Product Manually"
        inputList={createProductList}
        open={shouldOpenCreateProductModal}
        handleClose={toggleCreateProductModal}
        hookForm={createProductHookForm}
        handleReset={handleReset}
        type="add"
      />

      <VConfirmModal
        name={(selectedProduct as TProduct)?.productName}
        title="Confirm Edit"
        open={shouldOpenConfirmModal}
        handleClose={closeConfirmModal}
        handleSubmit={handleEditProductSubmit}
        customerProduct={customerProduct}
        imageProcessing={imageProcessing}
      />

      <ProductModal
        title="Edit Product"
        inputList={editProductList}
        open={shouldOpenEditFormModal}
        handleClose={toggleEditFormModal}
        openConfirm={openConfirmModal}
        setFormData={setFormData}
        hookForm={editProductHookForm}
        handleReset={handleReset}
        type="edit"
      />
      <ImportProductModal
        open={openImportProductModal}
        close={toggleImportProductModal}
        title="Import File to Add Product"
        openCategoryModal={toggleModal}
      />
    </>
  );
};

export default InventoryProductTable;
