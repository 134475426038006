import { VButton, VForm, VModal } from "components/common";
import { ADD_CUSTOMER_INPUT_LIST } from "../constants";
import { StyledAddCustomer, StyledCustomerForm } from "./styles";
import { VModalProps } from "components/common/types";
import { useAddBusinessCustomerHook } from "hooks/components/useAddBusinessCustomerHook";

const AddCustomerModal = ({ open, close }: VModalProps) => {
  const { isLoading, hookForm, handleSubmit, onSubmit, reset } =
    useAddBusinessCustomerHook(close);

  const closeForm = () => {
    close();
    reset({
      customerName: "",
      phoneNumber: "",
      email: "",
    });
  };

  return (
    <VModal open={open} close={closeForm} title="Add Customer">
      <StyledAddCustomer>
        <StyledCustomerForm>
          <form onSubmit={handleSubmit(onSubmit)}>
            <VForm
              inputLists={ADD_CUSTOMER_INPUT_LIST}
              hookForm={hookForm}
              vFormClassName="expense__form"
            />
            <div className="form--button">
              <VButton
                text="Add Customer"
                isSlim
                height="45px"
                isLoading={isLoading}
                style={{ width: "300px" }}
              />
            </div>
          </form>
        </StyledCustomerForm>
      </StyledAddCustomer>
    </VModal>
  );
};

export default AddCustomerModal;
