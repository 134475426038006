import { useCallback } from "react";
import { useLocalSearchParams, useModal } from "hooks";
import {
  IBusinessInvoices,
  useGetBusinessCustomerDetailHook,
  useGetBusinessInvoices,
} from "api";
import { useState } from "react";
import { extractFetchedData } from "utils";
import { DEBTORS_DETAILS_INVOICE_TABLE_COLUMN } from "components/customers";
import { useParams } from "react-router-dom";

export const useDebtorsDetailsPagehook = () => {
  const { businessCustomerId = "" } = useParams();

  const { searchParams, addSearchParams } = useLocalSearchParams();

  const [invoiceId, setInvoiceId] = useState<string | null>("");

  const { open: openInvoiceModal, toggleModal: toggleInvoiceModal } =
    useModal();

  const customerDetails = useGetBusinessCustomerDetailHook({
    businessCustomerId,
  });

  const [businessInvoices, refetchBusinessInvoices] = useGetBusinessInvoices({
    businessCustomerId,
  });

  const extract = extractFetchedData<IBusinessInvoices>({
    result: businessInvoices,
    key: "getBusinessInvoices",
    defaultReturn: [],
  });

  const handleInvoiceModalOpen = async (id: string) => {
    setInvoiceId(id);
    toggleInvoiceModal();
  };

  const handleInvoiceModalClose = () => {
    setInvoiceId(null);
    toggleInvoiceModal();
  };

  const debtorInvoicesColumns = DEBTORS_DETAILS_INVOICE_TABLE_COLUMN({
    handleViewInvoiceModal: (id: string) => handleInvoiceModalOpen(id),
  });

  const [pageNumber, setPageNumber] = useState(
    parseInt(searchParams.get("page") || "1")
  );

  const handleChangePage = (pageNumber: number) => {
    setPageNumber(pageNumber);
  };

  const handleOrderFilter = useCallback((status: string) => {
    addSearchParams("order", status);
    addSearchParams("page", "1");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    ...extract,
    handleChangePage,
    pageNumber,
    handleOrderFilter,
    debtorInvoicesColumns,
    customerDetails: {
      ...customerDetails,
    },
    invoiceModal: {
      invoiceId,
      openInvoiceModal,
      toggleInvoiceModal,
      handleInvoiceModalClose,
      refetchBusinessInvoices,
    },
  };
};
