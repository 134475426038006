import { ImagePlaceholder } from "assets/images/svgs/ImagePlaceholder";
import { APP_ROUTES } from "routes";
import { SEARCH_TYPE } from "store";
export const IMAGE_PLACE_HOLDER = ImagePlaceholder;

export const PRODUCT_CATEGORY = [
  { label: "Furniture", value: "furniture" },
  { label: "Grocery", value: "grocery" },
  { label: "Food", value: "food" },
  { label: "Rent", value: "rent" },
  { label: "Ingredients", value: "ingredients" },
  { label: "Entertainment", value: "entertainment" },
  { label: "Taxes", value: "taxes" },
  { label: "Equipment", value: "equipment" },
  { label: "Delivery", value: "delivery" },
  { label: "Investment", value: "investment" },
  { label: "Loans", value: "loans" },
  { label: "Packaging Material", value: "packaging material" },
  { label: "Professional fees", value: "professional fees" },
  { label: "Sponsored Advertising", value: "sponsored advertising" },
  { label: "Training", value: "training" },
  { label: "Transport", value: "transport" },
  { label: "Daily Savings", value: "daily savings" },
  { label: "Repairs and Maintenance", value: "repairs and maintenance" },
  { label: "Utilities", value: "utilities" },
  { label: "Software", value: "software" },
  { label: "Others", value: "others" },
];

export const INVOICES_STATUS = [
  { label: "Paid", value: "paid" },
  { label: "Unpaid", value: "unpaid" },
  { label: "Overdue", value: "overdue" },
  { label: "Partly paid", value: "part_payment" },
];

export const TRANSACTION_TYPE = [
  { label: "Inflow", value: "credit" },
  { label: "Outflow", value: "debit" },
];

export const TRANSACTION_STATUS = [
  { label: "Pending", value: "pending" },
  { label: "Successful", value: "successful" },
  { label: "Failed", value: "failed" },
];
export const PRODUCT_CATEGORY_CHIPS = [
  { label: "All", key: "all" },
  ...PRODUCT_CATEGORY.slice(0, 11).map((product) => ({
    ...product,
    key: product.value,
  })),
];

export const INVOICES_STATUS_CHIPS = [
  { label: "All Invoices", key: "all" },
  ...INVOICES_STATUS.map((invoice) => ({
    ...invoice,
    key: invoice.value,
  })),
];
export const TRANSACTION_TYPE_CHIPS = [
  { label: "All Transactions", key: "all" },
  ...TRANSACTION_TYPE.map((transaction) => ({
    ...transaction,
    key: transaction.value,
  })),
];
export const TRANSACTION_STATUS_CHIPS = [
  ...TRANSACTION_STATUS.map((transaction) => ({
    ...transaction,
    key: transaction.value,
  })),
];

export const fetchSearchConfig = (shouldSearch: boolean, location: string) => {
  const SEARCH_CONFIG = {
    default: {
      isSearchable: false,
      searchPlaceholder: "Search for items",
      searchType: SEARCH_TYPE.DEFAULT,
    },
    [APP_ROUTES.EXPENSE]: {
      isSearchable: shouldSearch || true,
      searchPlaceholder: "Search by item name or category",
      searchType: SEARCH_TYPE.EXPENSE,
    },
    [APP_ROUTES.SALES]: {
      isSearchable: shouldSearch || true,
      searchPlaceholder: "Search by customer name or sales id",
      searchType: SEARCH_TYPE.SALES,
    },
    [APP_ROUTES.PRODUCTS]: {
      isSearchable: shouldSearch || true,
      searchPlaceholder: "Search by product name or product id",
      searchType: SEARCH_TYPE.INVENTORY_PRODUCT,
    },
    [APP_ROUTES.SERVICES]: {
      isSearchable: shouldSearch || true,
      searchPlaceholder: "Search by service name or service id",
      searchType: SEARCH_TYPE.INVENTORY_SERVICE,
    },
    [APP_ROUTES.CUSTOMERS]: {
      isSearchable: shouldSearch || true,
      searchPlaceholder: "Search by customer name or customer id",
      searchType: SEARCH_TYPE.CUSTOMERS,
    },
    [APP_ROUTES.STAFF]: {
      isSearchable: shouldSearch || true,
      searchPlaceholder: "Search by staff first name, last name or staff id",
      searchType: SEARCH_TYPE.STAFF,
    },
    [APP_ROUTES.INVOICES]: {
      isSearchable: shouldSearch || true,
      searchPlaceholder: "Search by invoice name or invoice id",
      searchType: SEARCH_TYPE.INVOICES,
    },
  };

  return SEARCH_CONFIG[location ?? "default"] || SEARCH_CONFIG.default;
};

export const STAFF_DETAILS_HEADER = {
  TOP: [
    {
      value: "Cashier",
      label: "Role",
      key: "role",
      formatter: (value: Record<string, any>) => value?.roleName || "N/A",
    },
    {
      value: "AHY 74938",
      label: "Staff I.D",
      key: "employeeId",
    },
    {
      value: "Active",
      label: "Status",
      key: "status",
    },
  ],
  BOTTOM: [
    {
      value: "0812346781",
      label: "Phone Number",
      key: "phoneNumber",
    },
    {
      value: "nancy.idris@me.com",
      label: "Email",
      key: "email",
    },
    {
      value: "Vintage Gloceries",
      label: "Assigned Outlet",
      key: "assignedOutlet",
      formatter: (value: Record<string, any>[]) =>
        ((value || [])[0] ?? {})?.businessName || "N/A",
    },
  ],
};
