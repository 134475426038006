import { ReactNode } from "react";
import { Route } from "react-router-dom";
import BaseRoute from "./BaseRoutes";
import { BaseRouteProps } from "./types";

// Dynamically render routes
export const generateRoute = (baseRoutes: BaseRouteProps[]): ReactNode => {
  const returnedRoutes = baseRoutes.map((route: BaseRouteProps) => {
    const {
      key,
      path,
      routes,
      element: Element,
      index = false,
      isAuthenticatedRoute = true,
      title = "Dashboard",
      wrapWithLayout = false,
      disabled = false,
    } = route;

    if (routes && routes.length) {
      const indexedroute = routes.find((route) => route.index);
      const IndexedElement = indexedroute?.element;
      return (
        <Route
          key={key}
          path={path}
          {...(Element && {
            element: (
              <BaseRoute
                title={title}
                isAuthenticatedRoute={isAuthenticatedRoute}
                wrapWithLayout={wrapWithLayout}
              >
                <Element />
              </BaseRoute>
            ),
          })}
        >
          {generateRoute(routes)}
          {IndexedElement && (
            <Route
              path={path}
              element={
                <BaseRoute
                  title={title}
                  isAuthenticatedRoute={isAuthenticatedRoute}
                  wrapWithLayout={wrapWithLayout}
                >
                  <IndexedElement />
                </BaseRoute>
              }
            />
          )}
        </Route>
      );
    }
    if (!disabled)
      return (
        <Route
          key={key}
          index={index}
          path={path}
          {...(Element && {
            element: (
              <BaseRoute
                title={title}
                isAuthenticatedRoute={isAuthenticatedRoute}
                wrapWithLayout={wrapWithLayout}
              >
                <Element />
              </BaseRoute>
            ),
          })}
        />
      );
    return null;
  });
  return returnedRoutes;
};

export const multiplePathSameRoute = ({
  paths,
  route,
}: {
  paths: { key: string; path: string; name?: string }[];
  route: BaseRouteProps;
}): BaseRouteProps[] => {
  return paths.map((path) => ({
    ...route,
    ...path,
  }));
};
