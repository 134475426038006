const SettingIcon = () => {
  return (
    <svg
      width="17"
      height="19"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.8064 6.62386L18.184 5.54377C17.6574 4.62985 16.4905 4.31456 15.5753 4.83896V4.83896C15.1397 5.09559 14.6198 5.1684 14.1305 5.04133C13.6411 4.91427 13.2224 4.59776 12.9666 4.16162C12.8021 3.88439 12.7137 3.56864 12.7103 3.24628V3.24628C12.7251 2.72947 12.5302 2.22865 12.1698 1.85791C11.8094 1.48718 11.3143 1.27811 10.7973 1.27832H9.54326C9.03672 1.27831 8.55107 1.48016 8.19376 1.83919C7.83644 2.19822 7.63693 2.68484 7.63937 3.19136V3.19136C7.62435 4.23717 6.77224 5.07706 5.72632 5.07695C5.40397 5.0736 5.08821 4.98519 4.81099 4.82065V4.82065C3.89582 4.29626 2.72887 4.61154 2.20229 5.52546L1.5341 6.62386C1.00817 7.53664 1.31916 8.70285 2.22975 9.23255V9.23255C2.82166 9.57428 3.18629 10.2058 3.18629 10.8893C3.18629 11.5728 2.82166 12.2043 2.22975 12.5461V12.5461C1.32031 13.0722 1.00898 14.2356 1.5341 15.1456V15.1456L2.16568 16.2348C2.4124 16.68 2.82636 17.0085 3.31595 17.1477C3.80554 17.2868 4.3304 17.2251 4.77438 16.9763V16.9763C5.21084 16.7216 5.73094 16.6518 6.2191 16.7824C6.70725 16.9131 7.12299 17.2333 7.37392 17.6719C7.53845 17.9491 7.62686 18.2649 7.63021 18.5872V18.5872C7.63021 19.6438 8.48671 20.5003 9.54326 20.5003H10.7973C11.8502 20.5003 12.7053 19.6494 12.7103 18.5964V18.5964C12.7079 18.0883 12.9086 17.6003 13.2679 17.241C13.6272 16.8817 14.1152 16.6809 14.6233 16.6834C14.9449 16.692 15.2594 16.78 15.5387 16.9396V16.9396C16.4515 17.4656 17.6177 17.1546 18.1474 16.244V16.244L18.8064 15.1456C19.0615 14.7077 19.1315 14.1863 19.001 13.6966C18.8704 13.207 18.55 12.7896 18.1108 12.5369V12.5369C17.6715 12.2842 17.3511 11.8668 17.2206 11.3772C17.09 10.8875 17.16 10.3661 17.4151 9.92821C17.581 9.63858 17.8211 9.39844 18.1108 9.23255V9.23255C19.0159 8.70314 19.3262 7.54374 18.8064 6.63301V6.63301V6.62386Z"
        stroke="#374B58"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="10.1747"
        cy="10.8891"
        r="2.63616"
        stroke="#374B58"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default SettingIcon;
