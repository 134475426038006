const VeedezLogoSm = (props: any) => {
  return (
    <svg
      width="59"
      height="13"
      viewBox="0 0 59 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.47436 7.89832L4.75989 12.642C4.71622 12.609 4.68264 12.5643 4.66297 12.513L0.288118 0.394816C0.272688 0.350815 0.267898 0.303737 0.274146 0.257494C0.280395 0.211251 0.297506 0.16718 0.324048 0.128946C0.35059 0.0907127 0.3858 0.0594213 0.426751 0.037673C0.467702 0.0159247 0.513205 0.00434774 0.559486 0.00390625H3.41662C3.47627 0.00447723 3.53431 0.0235387 3.58285 0.0585036C3.6314 0.0934685 3.6681 0.142644 3.68798 0.199361L6.47436 7.89832Z"
        fill="#007BFF"
      />
      <path
        d="M11.3573 3.9788L8.27631 12.5133C8.2573 12.5645 8.22355 12.6087 8.17939 12.6404L6.47461 7.89767L6.98149 6.48356V6.47867C7.28851 5.63026 7.84676 4.89753 8.58059 4.37975C9.31442 3.86197 10.1884 3.58417 11.084 3.58398C11.1309 3.58389 11.1772 3.59524 11.2188 3.61707C11.2604 3.6389 11.2962 3.67057 11.323 3.70936C11.3499 3.74815 11.367 3.79291 11.373 3.83983C11.3789 3.88675 11.3736 3.93443 11.3573 3.9788Z"
        fill="#F0A75A"
      />
      <path
        d="M10.8109 5.49414L8.68652 11.3803H8.68555L10.8109 5.49414Z"
        fill="#F0A75A"
      />
      <path
        d="M10.4583 3.29731C11.3586 3.29731 12.0884 2.56137 12.0884 1.65354C12.0884 0.745708 11.3586 0.00976562 10.4583 0.00976562C9.55797 0.00976562 8.82812 0.745708 8.82812 1.65354C8.82812 2.56137 9.55797 3.29731 10.4583 3.29731Z"
        fill="#E57A1A"
      />
      <path
        d="M8.18094 12.6431C8.13069 12.6848 8.06766 12.7076 8.00261 12.7076H4.93517C4.87082 12.7071 4.80844 12.6851 4.75781 12.6451L6.47228 7.90039L8.18094 12.6431Z"
        fill="#E57A1A"
      />
      <path
        d="M37.9698 1.87832V4.3215C37.2919 3.90264 36.5155 3.67355 35.7206 3.65783C34.9257 3.6421 34.141 3.84031 33.4474 4.23204C32.7537 4.62378 32.1761 5.19488 31.7741 5.88653C31.3721 6.57818 31.1602 7.36538 31.1602 8.16707C31.1602 8.96877 31.3721 9.75597 31.7741 10.4476C32.1761 11.1393 32.7537 11.7104 33.4474 12.1021C34.141 12.4938 34.9257 12.692 35.7206 12.6763C36.5155 12.6606 37.2919 12.4315 37.9698 12.0126V12.4172C37.9698 12.4908 37.9988 12.5614 38.0504 12.6135C38.102 12.6655 38.172 12.6948 38.245 12.6948H39.8335C39.9063 12.6945 39.9761 12.6652 40.0275 12.6131C40.0789 12.5611 40.1078 12.4907 40.1078 12.4172V0.276569C40.1078 0.203218 40.0789 0.132872 40.0275 0.0810053C39.976 0.0291386 39.9063 2.54259e-07 39.8335 2.54259e-07V2.54259e-07C39.5888 -0.000128126 39.3465 0.0483608 39.1204 0.142698C38.8943 0.237035 38.6888 0.375371 38.5157 0.549802C38.3427 0.724232 38.2054 0.931339 38.1117 1.15929C38.018 1.38724 37.9698 1.63157 37.9698 1.87832ZM35.6351 10.5203C35.1732 10.5203 34.7218 10.3823 34.3378 10.1235C33.9539 9.86479 33.6546 9.49704 33.4779 9.0668C33.3013 8.63656 33.2551 8.16315 33.3452 7.70645C33.4354 7.24974 33.6579 6.83025 33.9845 6.50104C34.3111 6.17183 34.7272 5.94769 35.1802 5.85696C35.6331 5.76623 36.1026 5.81299 36.5292 5.99133C36.9558 6.16967 37.3204 6.47157 37.5768 6.85886C37.8332 7.24614 37.97 7.70141 37.9698 8.16707C37.969 8.79147 37.7225 9.39003 37.2844 9.83127C36.8462 10.2725 36.2523 10.5203 35.6331 10.5203H35.6351Z"
        fill="#007BFF"
      />
      <path
        d="M44.9476 3.66451C44.2046 3.73101 43.4897 3.98309 42.8674 4.39797C42.2452 4.81286 41.7353 5.37749 41.3837 6.04087C41.0321 6.70425 40.8499 7.44549 40.8536 8.19766C40.8572 8.94983 41.0466 9.68923 41.4047 10.3491C41.7627 11.009 42.2781 11.5685 42.9043 11.9772C43.5305 12.386 44.2478 12.631 44.9915 12.6901C45.7351 12.7493 46.4816 12.6207 47.1637 12.3161C47.8457 12.0114 48.4417 11.5403 48.8979 10.9452C48.9204 10.915 48.9365 10.8806 48.9454 10.844C48.9542 10.8073 48.9556 10.7693 48.9495 10.7321C48.9433 10.6949 48.9297 10.6594 48.9095 10.6276C48.8893 10.5959 48.863 10.5686 48.832 10.5474V10.5474C48.4714 10.2969 48.0526 10.1446 47.6163 10.1054C47.18 10.0661 46.7412 10.1413 46.3422 10.3236C46.0535 10.4599 45.7404 10.5355 45.4219 10.5461C45.1033 10.5566 44.7859 10.5017 44.489 10.3848C44.1921 10.268 43.9219 10.0915 43.6947 9.86609C43.4674 9.64071 43.288 9.37114 43.1672 9.07372H49.5638C49.6366 9.07372 49.7064 9.04461 49.758 8.99277C49.8096 8.94093 49.8387 8.87059 49.839 8.79715V8.1756C49.8391 7.54682 49.7093 6.92493 49.4579 6.34951C49.2065 5.77409 48.839 5.25776 48.3789 4.8334C47.9188 4.40904 47.376 4.08595 46.7853 3.8847C46.1945 3.68346 45.5687 3.60847 44.9476 3.66451ZM43.1672 7.28042C43.3441 6.84478 43.6456 6.47208 44.0332 6.20976C44.4208 5.94743 44.8771 5.80736 45.344 5.80736C45.8108 5.80736 46.2671 5.94743 46.6547 6.20976C47.0424 6.47208 47.3438 6.84478 47.5207 7.28042H43.1672Z"
        fill="#007BFF"
      />
      <path
        d="M57.8934 3.63672H50.7137C50.6409 3.63698 50.5711 3.66633 50.5197 3.71835C50.4683 3.77038 50.4395 3.84082 50.4395 3.91426V5.51602C50.4395 5.58946 50.4683 5.6599 50.5197 5.71193C50.5711 5.76395 50.6409 5.7933 50.7137 5.79356H55.1428L50.5199 10.4561C50.4684 10.508 50.4395 10.5783 50.4395 10.6516V12.4156C50.4395 12.489 50.4683 12.5595 50.5197 12.6115C50.5711 12.6635 50.6409 12.6929 50.7137 12.6931H57.8934C57.9664 12.6931 58.0364 12.6639 58.088 12.6118C58.1396 12.5598 58.1686 12.4892 58.1686 12.4156V10.8148C58.1686 10.7412 58.1396 10.6706 58.088 10.6185C58.0364 10.5665 57.9664 10.5372 57.8934 10.5372H53.4633L58.0872 5.87468C58.1389 5.82295 58.1682 5.75267 58.1686 5.67922V3.92013C58.1694 3.88319 58.1628 3.84647 58.1494 3.81211C58.1359 3.77776 58.1157 3.74647 58.0901 3.72007C58.0645 3.69368 58.0338 3.67271 58.0001 3.65839C57.9663 3.64408 57.93 3.63671 57.8934 3.63672V3.63672Z"
        fill="#007BFF"
      />
      <path
        d="M15.8694 3.66416C15.1264 3.73066 14.4115 3.98274 13.7893 4.39763C13.1671 4.81251 12.6572 5.37714 12.3056 6.04052C11.954 6.7039 11.7718 7.44514 11.7754 8.19731C11.7791 8.94948 11.9685 9.68888 12.3265 10.3488C12.6846 11.0086 13.2 11.5682 13.8262 11.9769C14.4524 12.3856 15.1697 12.6306 15.9133 12.6898C16.657 12.7489 17.4035 12.6204 18.0855 12.3157C18.7676 12.0111 19.3636 11.5399 19.8198 10.9448C19.8423 10.9147 19.8584 10.8803 19.8673 10.8436C19.8761 10.807 19.8775 10.7689 19.8713 10.7317C19.8652 10.6945 19.8516 10.659 19.8314 10.6273C19.8112 10.5955 19.7849 10.5683 19.7539 10.5471V10.5471C19.3932 10.2965 18.9744 10.1442 18.5382 10.105C18.1019 10.0658 17.663 10.141 17.2641 10.3233C16.9754 10.4595 16.6623 10.5352 16.3437 10.5457C16.0252 10.5562 15.7078 10.5014 15.4109 10.3845C15.114 10.2676 14.8438 10.0911 14.6165 9.86574C14.3893 9.64036 14.2099 9.37079 14.0891 9.07337H20.4856C20.5585 9.07337 20.6283 9.04426 20.6799 8.99242C20.7315 8.94058 20.7606 8.87024 20.7609 8.7968V8.17525C20.7609 7.54684 20.6312 6.92529 20.38 6.35016C20.1289 5.77503 19.7618 5.2589 19.3021 4.8346C18.8424 4.4103 18.3001 4.08712 17.7098 3.88562C17.1195 3.68411 16.4941 3.60869 15.8733 3.66416H15.8694ZM14.0891 7.28007C14.266 6.84443 14.5674 6.47173 14.9551 6.20941C15.3427 5.94709 15.799 5.80701 16.2658 5.80701C16.7327 5.80701 17.189 5.94709 17.5766 6.20941C17.9642 6.47173 18.2657 6.84443 18.4426 7.28007H14.0891Z"
        fill="#007BFF"
      />
      <path
        d="M25.5628 3.66459C24.8198 3.7311 24.1049 3.98318 23.4827 4.39806C22.8605 4.81295 22.3505 5.37757 21.9989 6.04095C21.6473 6.70434 21.4651 7.44558 21.4688 8.19775C21.4725 8.94992 21.6619 9.68932 22.0199 10.3492C22.3779 11.0091 22.8933 11.5686 23.5195 11.9773C24.1457 12.386 24.8631 12.631 25.6067 12.6902C26.3503 12.7494 27.0969 12.6208 27.7789 12.3162C28.4609 12.0115 29.0569 11.5404 29.5132 10.9453C29.5359 10.9152 29.5523 10.8808 29.5613 10.8441C29.5703 10.8074 29.5718 10.7693 29.5656 10.732C29.5594 10.6948 29.5457 10.6592 29.5254 10.6274C29.505 10.5957 29.4784 10.5685 29.4473 10.5475V10.5475C29.0868 10.2969 28.6682 10.1446 28.232 10.1054C27.7959 10.0662 27.3572 10.1414 26.9584 10.3237C26.6697 10.46 26.3566 10.5356 26.0381 10.5461C25.7195 10.5567 25.4021 10.5018 25.1052 10.3849C24.8083 10.268 24.5381 10.0916 24.3109 9.86618C24.0836 9.6408 23.9042 9.37122 23.7834 9.0738H30.18C30.2528 9.0738 30.3227 9.0447 30.3742 8.99286C30.4258 8.94102 30.4549 8.87067 30.4552 8.79723V8.17569C30.4553 7.54682 30.3255 6.92483 30.074 6.34934C29.8226 5.77385 29.455 5.25747 28.9948 4.8331C28.5345 4.40872 27.9917 4.08565 27.4008 3.88447C26.8099 3.68329 26.184 3.60841 25.5628 3.66459V3.66459ZM23.7834 7.28051C23.9603 6.84487 24.2618 6.47217 24.6494 6.20984C25.037 5.94752 25.4933 5.80744 25.9602 5.80744C26.427 5.80744 26.8833 5.94752 27.2709 6.20984C27.6586 6.47217 27.96 6.84487 28.1369 7.28051H23.7834Z"
        fill="#007BFF"
      />
    </svg>
  );
};

export default VeedezLogoSm;
