import { gql, useQuery } from "urql";

export const useGetBusinessPlans = () => {
  return useQuery({
    query: getBusinessPlansQuery,
  });
};

const getBusinessPlansQuery = gql`
  query {
    getBusinessPlans {
      _id
      name
      price
      summary
      codeName
    }
  }
`;

export interface IGetBusinessPlans {
  _id: string;
  name: string;
  price: string;
  summary: string;
  codeName: string;
}
