import { PASSCODE_API_ROUTES } from "api/api.routes";
import { showToast } from "components/common";
import { useToggle } from "hooks";
import { useVeedezApiRequest } from "hooks/useApiRequest";
import { useMemo, useState } from "react";
import { FieldValues, useForm, UseFormGetValues } from "react-hook-form";

import { usePasscodeContext } from "context";
import {
  ENTER_PASSCODE_INPUT_LIST,
  LOGIN_PASS_CODE_INPUT_LIST,
} from "../signup/constants";

export type EnterPassCodeType = "LOGIN" | "SIGNUP";

export type EnterPasscodeProps = {
  hasSetPassCode?: boolean;
  type: EnterPassCodeType;
  callback?: VoidFunction;
};

const CONTENT = {
  LOGIN: {
    title: (name: string) => `👋🏾 Welcome Back, ${name} !`,
    subtitle: "Your passcode is required to give you access",
    formInputs: () => LOGIN_PASS_CODE_INPUT_LIST,
    buttonText: "Authorize",
    disabled: false,
    isShowButton: true,
  },
  SIGNUP: {
    title: () => "👋🏾 Welcome, let’s get you setup!",
    subtitle: "Create Passcode - Step 1 of 3",
    formInputs: (value: UseFormGetValues<FieldValues>) =>
      ENTER_PASSCODE_INPUT_LIST(value),
    buttonText: "Continue",
    disabled: false,
    isShowButton: true,
  },
};

export const useEnterPasscodeHook = ({
  type,
  hasSetPassCode,
  callback,
}: EnterPasscodeProps) => {
  const hookForm = useForm();

  const content = CONTENT[type];
  const { formInputs } = content;
  const [isLoading, toggleLoading] = useToggle();
  const { addVerifyPasscode, addSetPasscode, setPasscode } =
    usePasscodeContext();

  const url = hasSetPassCode
    ? PASSCODE_API_ROUTES.VERIFY_PASSCODE
    : PASSCODE_API_ROUTES.SET_PASS_CODE;

  const { getValues, handleSubmit } = hookForm;
  const makeRequest = useVeedezApiRequest();

  const onSubmit = async (data: any) => {
    setPasscode(data.pin);
    toggleLoading();
    try {
      const request = await makeRequest.post(url, {
        pin: data.pin,
      });
      const isPasscodeSet = !!request?.data?.isPasscodeSet;

      if (isPasscodeSet) {
        await addVerifyPasscode(true);
        await addSetPasscode(true);
        callback?.();
      } else {
        showToast({
          type: "error",
          title: "Invalid passcode",
          subText: "Please try again",
        });
      }
    } catch (e) {
      showToast({
        type: "error",
        title: "Invalid passcode",
        subText: "Please try again",
      });
    } finally {
      toggleLoading();
    }
  };

  const handleFormSbumit = handleSubmit(onSubmit);

  const inputLists = useMemo(() => {
    return formInputs(getValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues]);

  return {
    ...content,
    inputLists,
    handleFormSbumit,
    hookForm,
    isLoading,
    onSubmit,
  };
};

export const usePasscodeForVerification = ({
  hasSetPassCode,
  callback,
}: Omit<EnterPasscodeProps, "type">) => {
  const [hasError, setHasError] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [isLoading, toggleLoading] = useToggle();
  const makeRequest = useVeedezApiRequest();

  const url = hasSetPassCode
    ? PASSCODE_API_ROUTES.VERIFY_PASSCODE
    : PASSCODE_API_ROUTES.SET_PASS_CODE;

  const onSubmit = async (data: any) => {
    toggleLoading();

    setHasError(false);
    try {
      const request = await makeRequest.post(url, {
        pin: data.pin,
      });
      const isPasscodeSet = !!request?.data?.isPasscodeSet;
      if (isPasscodeSet) {
        setIsValid(true);
        callback?.();
      } else {
        setHasError(true);
        setIsValid(false);
        showToast({
          type: "error",
          title: "Invalid passcode",
          subText: "Please try again",
        });
      }
    } catch (e) {
      showToast({
        type: "error",
        title: "Invalid passcode",
        subText: "Please try again",
      });
      setHasError(true);
      setIsValid(false);
    } finally {
      toggleLoading();
    }
  };

  return {
    isLoading,
    isValid,
    onSubmit,
    hasError,
  };
};
