import { store } from "store";
import { analytics } from "utils/firebase";
import { logEvent } from "firebase/analytics";

const eventTypes = {
  signup: "signup",
  login: "login",

  createpasscode: "createpasscode",
  submitpasscode: "submitpasscode",

  businessregistrationbegins: "businessregistrationbegins",
  businessregistrationcomplete: "businessregistrationcomplete",

  // INVENTORY
  goto_inventory: "goto_inventory",
  add_product_screen_opened: "add_product_screen_opened",
  edit_product_screen_opened: "edit_product_screen_opened",
  submitted_added_product: "submitted_added_product",
  submitted_edited_product: "submitted_edited_product",
  add_product_successfully: "add_product_successfully",
  add_service_screen_opened: "add_service_screen_opened",
  edit_service_screen_opened: "edit_service_screen_opened",
  submitted_added_service: "submitted_added_service",
  add_service_successfully: "add_service_successfully",

  // INVOICES
  goto_invoices: "goto_invoices",
  open_create_invoice: "open_create_invoice",
  submit_created_invoice: "submit_created_invoice",
  invoice_created_successfully: "invoice_created_successfully",
  view_invoice_pdf: "view_invoice_pdf",

  // EXPENSES
  goto_expenses: "goto_expenses",
  open_create_expense: "open_create_expense",

  // CUSTOMERS
  goto_customers: "goto_customers",
  first_customer_added: "first_customer_added",
  open_create_customer: "open_create_customer",
  submit_created_customer: "submit_created_customer",
  customer_created_successfully: "customer_created_successfully",

  // STAFF
  goto_staff: "goto_staff",
  open_create_staff: "open_create_staff",
  submit_created_staff: "submit_created_staff",
  staff_created_successfully: "staff_created_successfully",
};

/**
 * Hook to handle application logging and analytics
 * @returns [logEvent: function, eventTypes: object]
 */
export const useAnalytics = (): [
  (type: string, details?: {}) => Promise<void>,
  typeof eventTypes
] => {
  const { userData } = store.getState().auth;

  const logAppEvent = async (type: string, details = {}) => {
    const eventData = {
      ...details,
      email: userData?.email ?? "",
      userId: userData?._id ?? "",
      lastName: userData?.lastName ?? "",
      firstName: userData?.firstName ?? "",
      businessId: userData?.businessId ?? "",
      phoneNumber: userData?.phoneNumber ?? "",
    };
    // Logs event to google analytics...
    await logEvent(analytics, type, eventData);
  };

  return [logAppEvent, eventTypes];
};
