const Plus = (props: any) => {
  return (
    <svg
      width="9"
      height="9"
      viewBox="0 0 9 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.65528 4.992H5.42328V8.304H3.72728V4.992H0.495281V3.456H3.72728V0.143999H5.42328V3.456H8.65528V4.992Z"
        fill="#1A51A3"
      />
    </svg>
  );
};

export default Plus;
