import { VButton, VForm, VModal } from "components/common";
import { StyledAddCustomer, StyledCustomerForm, NameInitial } from "./styles";
import { VModalProps } from "components/common/types";
import { useEditBusinessCustomerHook } from "hooks";
import { EDIT_CUSTOMER_INPUT_LIST } from "../constants";
import { capitalizeWord } from "utils";
import { IBusinessCustomer } from "./types";

const EditCustomerModal = ({
  open,
  close,
  selectedCustomer = null,
}: VModalProps & { selectedCustomer: IBusinessCustomer | null }) => {
  const { hookForm, isUpdatingCustomer, handleFormSubmit, resetForm } =
    useEditBusinessCustomerHook(selectedCustomer, close);

  const initial = capitalizeWord(selectedCustomer?.customerName?.charAt(0));

  const closeForm = () => {
    resetForm();
    close();
  };

  return (
    <VModal open={open} close={closeForm} title="Edit Customer">
      <NameInitial>
        <div className="name_placeHolder">
          <h3>{selectedCustomer?.customerName ? initial : "N/A"}</h3>
        </div>
      </NameInitial>
      <StyledAddCustomer>
        <StyledCustomerForm>
          <form onSubmit={handleFormSubmit}>
            <VForm
              inputLists={EDIT_CUSTOMER_INPUT_LIST}
              hookForm={hookForm}
              vFormClassName="grid__form"
            >
              <div className="form__button">
                <VButton
                  isLoading={isUpdatingCustomer}
                  text="Save Changes"
                  isSlim
                  height="41px"
                  style={{ width: "300px" }}
                />
              </div>
            </VForm>
          </form>
        </StyledCustomerForm>
      </StyledAddCustomer>
    </VModal>
  );
};

export default EditCustomerModal;
