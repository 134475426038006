import { useResetPasscodeApi } from "api";
import { VButton, VForm } from "components/common";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "routes";
import { useAuthUser } from "store";
import { AppStorage, STORGAGE_KEYS } from "utils";
import { EnterPasscodeProps, useEnterPasscodeHook } from "../hooks";

import SignupWrapper from "./signup-wrapper";
import { StyledForgotPasscode } from "./styles";

const { removeFromStore } = new AppStorage();

const EnterPasscode = ({
  hasSetPassCode = false,
  type,
  callback,
}: EnterPasscodeProps) => {
  const {
    title,
    subtitle,
    buttonText,
    handleFormSbumit,
    inputLists,
    isLoading,
    hookForm,
    disabled,
    isShowButton,
  } = useEnterPasscodeHook({ type, hasSetPassCode, callback });
  const { userData } = useAuthUser();
  const navigate = useNavigate();
  const { handleSendOtp, isLoading: isSendingOtp } = useResetPasscodeApi();

  const fullName = `${userData?.lastName ?? ""} ${userData?.firstName ?? ""}.`;

  const handleForgotPasscode = async () => {
    await handleSendOtp(async (data: Record<string, any>) => {
      if (data?.resetPasscode?.success) {
        await removeFromStore(STORGAGE_KEYS.RESEND_COUNTDONW);
        navigate(APP_ROUTES.RESET_PASSCODE, { replace: true });
      }
    });
  };

  return (
    <form onSubmit={handleFormSbumit}>
      <SignupWrapper
        header={title(fullName ?? "User")}
        subtitle={subtitle}
        footer={
          <>
            {isShowButton ? (
              <VButton
                text={buttonText}
                type="submit"
                isLoading={isLoading || isSendingOtp}
                style={{
                  maxWidth: "400px",
                  width: "100%",
                }}
                disabled={disabled}
              />
            ) : null}
            <StyledForgotPasscode>
              <p>Forgot passcode?</p>
              <button type="button" onClick={handleForgotPasscode}>
                Click to reset
              </button>
            </StyledForgotPasscode>
          </>
        }
      >
        <VForm
          vFormClassName="grid__form"
          inputLists={inputLists}
          hookForm={hookForm}
        />
      </SignupWrapper>
    </form>
  );
};

export default EnterPasscode;
