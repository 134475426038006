import { extractFetchedData } from "utils";
import {
  IBusinessInvoiceDetails,
  useGetBusinessInvoiceDetails,
  useGetInvoicePdf,
} from "api";
import { useAuthUser } from "store";

export const useInvoiceDetailsHook = (invoiceId: string) => {
  const { userData } = useAuthUser();

  const [result, refetchInvoiceDetails] = useGetBusinessInvoiceDetails({
    businessInvoiceId: invoiceId,
  });

  const fetchingData = result?.data;

  const extract = extractFetchedData<IBusinessInvoiceDetails>({
    result,
    key: "getBusinessInvoiceDetail",
  });

  const invoiceDetails = result?.data?.getBusinessInvoiceDetail;

  // invoice pdf
  const [url] = useGetInvoicePdf({
    businessId: userData?.businessId as string,
    invoiceId: invoiceId,
    pause: !fetchingData,
  });

  const pdfUrl = url?.data?.getInvoicePDF;

  return { ...extract, invoiceDetails, pdfUrl, refetchInvoiceDetails };
};
