import { useEffect, useState } from "react";
import { Option } from "./styles";
import { formatNumberToCurrency } from "utils";
import { VRadio } from "components/common";
import { ITerminalSpecs } from "api";

const PosOption = ({
  name,
  price,
  imageUrl,
  benefits,
  features,
  option,
  selectedPos,
  setSelectedPos,
}: ITerminalSpecs & any) => {
  const radioChangeHandler = () => {
    setSelectedPos(option);
  };

  const [terminalName, setTerminalName] = useState("");

  const getTerminalType = () => {
    if (name && name === "Veedez Mini") {
      return "MPOS Terminal";
    } else if (name && name === "Veedez Maxi") {
      return "Android POS Terminal";
    } else {
      return "";
    }
  };

  useEffect(() => {
    const terminalType = getTerminalType();
    setTerminalName(terminalType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const active = selectedPos?._id === option?._id;

  return (
    <>
      <Option selectedPos={active}>
        <VRadio
          id={option?._id}
          isSelected={selectedPos === option}
          value={option}
          changed={radioChangeHandler}
        />
        <div>
          <h3>{terminalName}</h3>
          <p>
            {formatNumberToCurrency({
              number: price,
            })}
          </p>
          <div className="bottom-flex">
            <img src={imageUrl} alt="" style={{ width: "70px" }} />
            <div>
              <ul>
                {benefits?.map((items: string, i: number) => (
                  <li key={i}>{items}</li>
                ))}
              </ul>
              <div className="features">
                {features?.map((picture: string, i: number) => (
                  <img src={picture} alt="" key={i} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </Option>
    </>
  );
};

export default PosOption;
