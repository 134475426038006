import { gql, useQuery } from "urql";

type UseGetTotalSales = {
  businessId: string;
  transactionHistory?: string;
};

const getTotalSalesQuery = gql`
  query ($businessId: String) {
    getSaleBreakdown(businessId: $businessId, year: 2022) {
      identifier
      type
      totalSales
      totalSalesCount
    }
  }
`;

export const useGetTotalSales = ({ businessId }: UseGetTotalSales) => {
  return useQuery({
    query: getTotalSalesQuery,
    variables: { businessId },
  });
};

export interface ITotalSales {
  identifier: string;
  type: string;
  totalSales: number;
  totalSalesCount: number;
  month?: string;
}
