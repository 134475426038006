export type IAddCustomerBusiness = {
  customerId: string;
  firstName: String;
  lastName: String;
  phoneNumber?: String;
  email: String;
  businessCode: String;
  businessName: String;
  businessEmail: String;
  businessPhoneNumber: String;
  businessAddress: String;
  businessEmblem?: File | null;
  stateId?: String;
  cityId?: String;
  stateName?: String;
  cityName?: String;
  deviceToken?: String;
  referredBy?: String;
  businessPlan?: String;
  CACDocument?: String;
  CACNumber?: string;
  utilityBill?: File | null;
  businessType?: String;
  businessSectorId?: String;
  NIN?: string;
};

export enum EBUSINESS_TYPE {
  SMALL = "SMALL",
  LARGE = "LARGE",
}
