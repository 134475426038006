const LogoutIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 25"
      width="25"
      height="25"
      {...props}
    >
      <path
        fill="#757575"
        d="M20,5.5v14A2.5,2.5,0,0,1,17.5,22h-8A2.5,2.5,0,0,1,7,19.5v-1a.5.5,0,0,1,1,0v1A1.5,1.5,0,0,0,9.5,21h8A1.5,1.5,0,0,0,19,19.5V5.5A1.5,1.5,0,0,0,17.5,4h-8A1.5,1.5,0,0,0,8,5.5v1a.5.5,0,0,1-1,0v-1A2.5,2.5,0,0,1,9.5,3h8A2.5,2.5,0,0,1,20,5.5ZM10.5,18a.433.433,0,0,0,.19-.04A.5.5,0,0,0,11,17.5V15h5.5a.5.5,0,0,0,.5-.5v-4a.5.5,0,0,0-.5-.5H11V7.5a.5.5,0,0,0-.31-.46.474.474,0,0,0-.54.11l-5,5a.483.483,0,0,0,0,.7l5,5A.469.469,0,0,0,10.5,18Z"
      ></path>
    </svg>
  );
};
export default LogoutIcon;
