import styled from "styled-components";
import { stylingUtils } from "styles";

const { rem } = stylingUtils;

export const VStyledBadge = styled("div")<{
  backgroundColor: string;
  color: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${rem(5)} ${rem(10)};
  background-color: ${({ backgroundColor }) =>
    backgroundColor && backgroundColor};
  border-radius: 30px;
  width: max-content;
  gap: ${rem(5)};
  cursor: pointer;
  p {
    color: ${({ color }) => color && color};
    padding-bottom: 2px;
  }
  span {
    background: ${({ color }) => color && color};
    border-radius: 9999%;
    width: 5px;
    height: 5px;
    flex: none;!importatn
  }
`;
