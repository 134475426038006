const MessageIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0.6 0.19 12 10.27"
      {...props}
    >
      <path
        d="M10.8035 0.186523H2.40352C1.92613 0.186523 1.46829 0.389495 1.13072 0.750785C0.793158 1.11208 0.603516 1.60209 0.603516 2.11303V8.53474C0.603516 9.04568 0.793158 9.5357 1.13072 9.89699C1.46829 10.2583 1.92613 10.4612 2.40352 10.4612H10.8035C11.2809 10.4612 11.7387 10.2583 12.0763 9.89699C12.4139 9.5357 12.6035 9.04568 12.6035 8.53474V2.11303C12.6035 1.60209 12.4139 1.11208 12.0763 0.750785C11.7387 0.389495 11.2809 0.186523 10.8035 0.186523ZM10.5575 1.47086L7.02952 5.24683C6.97374 5.30701 6.90738 5.35479 6.83426 5.38739C6.76115 5.41999 6.68272 5.43678 6.60352 5.43678C6.52431 5.43678 6.44589 5.41999 6.37277 5.38739C6.29965 5.35479 6.23329 5.30701 6.17752 5.24683L2.64952 1.47086H10.5575ZM11.4035 8.53474C11.4035 8.70505 11.3403 8.86839 11.2278 8.98882C11.1153 9.10925 10.9626 9.17691 10.8035 9.17691H2.40352C2.24439 9.17691 2.09177 9.10925 1.97925 8.98882C1.86673 8.86839 1.80352 8.70505 1.80352 8.53474V2.37632L5.33152 6.15229C5.66902 6.51306 6.12651 6.7157 6.60352 6.7157C7.08052 6.7157 7.53801 6.51306 7.87552 6.15229L11.4035 2.37632V8.53474Z"
        fill="#374B58"
      />
    </svg>
  );
};

export default MessageIcon;
