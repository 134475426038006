import { CustomerFrame } from "./styles";
import { useCustomerDetailsHook, useCustomersPageHook } from "hooks";
import { showToast, VEmptyState } from "components/common";
import CustomerDetailsHeader from "./customer-details-header";
import CustomerDetailsTransactions from "./customer-details-transactions";
import { EditCustomerModal, IBusinessCustomer } from "../edit-customer-modal";
import { useModal } from "hooks";
import { useState } from "react";
import VConfirmModal from "components/common/VConfirmModal";
import { APP_ROUTES } from "routes";
import { useNavigate } from "react-router-dom";

const CustomerDetails = () => {
  const {
    customerData,
    detailsError,
    salesData,
    salesError,
    businessAddress,
    handleDeleteBusinessCustomer,
    isLoading,
    shouldOpenDeleteConfirmModal,
    toggleConfirmModal,
    checkResult,
    isCheckingDeleteStatus,
  } = useCustomerDetailsHook();

  const { refreshBusinessCustomers } = useCustomersPageHook();

  const navigate = useNavigate();

  const customer = customerData?.getBusinessCustomerDetail;

  const customerSales = salesData?.getBusinessSales?.nodes;

  const { open, toggleModal } = useModal();

  const [selectedCustomerDetail, setSelectedCustomerDetail] =
    useState<IBusinessCustomer | null>(customer);

  const handleOpenEditModal = () => {
    setSelectedCustomerDetail(customer);
    toggleModal();
  };

  const handleDeleteCustomer = async () => {
    await handleDeleteBusinessCustomer(() => {
      showToast({
        type: "delete",
        title: "Successful",
        subText: `You have successfully deleted this customer`,
      });
      refreshBusinessCustomers();
      navigate(APP_ROUTES.CUSTOMERS);
    });
    toggleConfirmModal();
  };

  const handleCloseEditModal = () => {
    setSelectedCustomerDetail(null);
    toggleModal();
  };

  if (!!detailsError)
    return (
      <VEmptyState
        title="Error Fetching Data!"
        subText="An error occur getting customer's data"
      />
    );

  return (
    <>
      <CustomerFrame>
        {
          <CustomerDetailsHeader
            customerName={customer?.customerName}
            customerDetails={customer}
            customerStatus={customer?.status}
            handleOpenEditModal={handleOpenEditModal}
            handleOpenDeleteConfirmModal={toggleConfirmModal}
            hasInvoiceOrSales={checkResult?.hasInvoiceOrSales}
            isCheckingDeleteStatus={isCheckingDeleteStatus}
          />
        }
        <CustomerDetailsTransactions
          customerSales={customerSales}
          salesError={salesError}
          businessAddress={businessAddress}
        />
      </CustomerFrame>
      <EditCustomerModal
        open={open}
        close={handleCloseEditModal}
        selectedCustomer={selectedCustomerDetail}
      />
      <VConfirmModal
        title="Delete Customer"
        name={customer?.customerName}
        imageProcessing={isLoading}
        open={shouldOpenDeleteConfirmModal}
        handleClose={toggleConfirmModal}
        handleSubmit={handleDeleteCustomer}
        confirmText="Are you sure you want to delete the customer, "
        cancelText="No, Don't Delete"
        submitText="Yes, Delete"
        bgColor="#EA4336"
      />
    </>
  );
};

export default CustomerDetails;
