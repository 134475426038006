import * as React from "react";

/** A simple hook to toggle between boolean states */
const useToggle = (initialState = false): [boolean, () => void] => {
  const [value, setValue] = React.useState(initialState);

  // Function to handle the toggling
  const toggleValue: React.SetStateAction<any> = React.useCallback(
    () => setValue((prev: boolean) => !prev),
    []
  );

  return [value, toggleValue];
};

export default useToggle;
