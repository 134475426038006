import { IAddBusinessCustomer, useAddBusinessCustomer } from "api";
import { showToast } from "components/common";
import { useAnalytics } from "hooks/useAnalytics";
import { useForm } from "react-hook-form";

export const useAddBusinessCustomerHook = (callBack?: Function) => {
  const hookForm = useForm<IAddBusinessCustomer>();

  const [
    logAppEvent,
    { submit_created_customer, customer_created_successfully },
  ] = useAnalytics();

  const { handleSubmit, reset } = hookForm;

  const { handleAddBusinessCustomer, isLoading } = useAddBusinessCustomer();

  const onSubmit = async (data: IAddBusinessCustomer) => {
    logAppEvent(submit_created_customer, {});

    await handleAddBusinessCustomer(data, () => {
      reset({
        customerName: "",
        phoneNumber: "",
        email: "",
      });
      showToast({
        subText: `You have successfully added ${data?.customerName} as your Customer`,
      });
      logAppEvent(customer_created_successfully, {});
      callBack?.();
    });
  };

  return {
    handleSubmit,
    isLoading,
    onSubmit,
    hookForm,
    reset,
  };
};
