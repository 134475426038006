import styled from "styled-components/macro";
import { stylingUtils } from "styles";

const { rem, color } = stylingUtils;

export const StyledPermissionModuleCard = styled("button")`
  padding: ${rem(10)} ${rem(10)};
  display: block;
  width: 100%;
  border-bottom: 1px solid ${color("ash01")};
  text-align: left;
  color: ${color("ash03")};
  flex: none;
  font-size: ${rem(12)};
  min-width: ${rem(300)};

  &:hover {
    background-color: ${color("ash01")};
  }

  &.isActive__permission {
    color: ${color("lightBlue")};
  }

  &.header__card {
    color: ${color("black")};
    font-weight: 500;
    border-top: 1px solid ${color("ash01")};
  }
`;
