import { useParams } from "react-router-dom";
import { useAuthUser } from "store";
import { useGetBusinessCustomerDetail, useGetBusinessSalesApi } from "api";
import { useDeleteBusinessCustomer } from "api/customers/delete-business-customers";
import useModal from "hooks/useModal";
import {
  TYPE,
  useCheckCustomerHasSalesAndInvoices,
} from "api/customers/customer-has-sales-or-invoice";

export const useCustomerDetailsHook = () => {
  const { customerId } = useParams();
  const { userData } = useAuthUser();
  const {
    open: shouldOpenDeleteConfirmModal,
    toggleModal: toggleConfirmModal,
  } = useModal();

  const [customerDetailsResult] = useGetBusinessCustomerDetail({
    businessId: userData?.businessId as string,
    businessCustomerId: customerId as string,
  });

  const [salesResult] = useGetBusinessSalesApi({
    businessId: userData?.businessId as string,
    businessCustomerId: customerId as string,
  });

  const { handleDeleteBusinessCustomer, isLoading } = useDeleteBusinessCustomer(
    {
      businessCustomerId: customerId as string,
    }
  );

  const [{ data: checkResult, fetching: isCheckingDeleteStatus }] =
    useCheckCustomerHasSalesAndInvoices({
      businessCustomerId: customerId as string,
      type: TYPE.BUSINESS_CUSTOMER,
    });

  const {
    data: salesData,
    fetching: fetchingSales,
    error: salesError,
  } = salesResult;

  const {
    data: customerData,
    fetching: fetchingDetails,
    error: detailsError,
  } = customerDetailsResult;

  const businessAddress = userData?.business?.businessAddress;

  return {
    customerData,
    fetchingDetails,
    detailsError,
    salesData,
    fetchingSales,
    salesError,
    salesResult,
    businessAddress,
    handleDeleteBusinessCustomer,
    isLoading,
    shouldOpenDeleteConfirmModal,
    toggleConfirmModal,
    checkResult,
    isCheckingDeleteStatus,
  };
};
