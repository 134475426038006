import clsx from "clsx";
import { formatUnderScore } from "utils";
import { StyledPermissionModuleCard } from "./styles";

export type PermissionByModuleProps = {
  handleSelectedPermissionGroup: (permission: string) => void;
  permissionModule: string[];
  selectedPermissionGroup: string;
  setEditRole: React.Dispatch<React.SetStateAction<boolean>>;
};

export const PermissionByModule = ({
  handleSelectedPermissionGroup,
  permissionModule,
  selectedPermissionGroup,
  setEditRole,
}: PermissionByModuleProps) => {
  return (
    <div>
      <StyledPermissionModuleCard className="header__card">
        Permissions by Module
      </StyledPermissionModuleCard>
      {permissionModule.map((permission) => (
        <StyledPermissionModuleCard
          onClick={() => {
            setEditRole(true);
            handleSelectedPermissionGroup(permission);
          }}
          key={permission}
          className={clsx(
            selectedPermissionGroup === permission && "isActive__permission"
          )}
        >
          {formatUnderScore(permission)}
        </StyledPermissionModuleCard>
      ))}
    </div>
  );
};
