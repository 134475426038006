import { lazy } from "react";

export const ReportInvoiceSummaryChart = lazy(
  () => import("./report-invoice-summary-chart")
);
export const ReportInvoiceStatusPie = lazy(
  () => import("./report-invoice-status-pie")
);
export const ReportEstimatedInvoiceGraph = lazy(
  () => import("./report-estimated-invoice-graph")
);
