import { VButton, VForm, VModal } from "components/common";

import { VModalProps } from "components/common/types";
import { STORE_DETAILS_INPUT_LIST } from "components/manage-business/constants";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { IAddStore } from "./add-store-modal";

export const EditStoreModal = ({ open, close }: VModalProps) => {
  const hookForm = useForm<IAddStore>();

  const { handleSubmit } = hookForm;
  const onSubmit = async (data: any) => {
    // console.log({ data });
  };

  const disabledInputListFied = useMemo(() => {
    return [
      ...STORE_DETAILS_INPUT_LIST.map((fields) => {
        if (fields.name === "businessEmblem") {
          fields.name = "businessLogo";
        }

        return fields;
      }),
    ];
  }, []);

  return (
    <VModal open={open} close={close} title="Edit Store">
      <form onSubmit={handleSubmit(onSubmit)}>
        <VForm
          inputLists={disabledInputListFied}
          hookForm={hookForm}
          vFormClassName="grid__form"
        >
          <div className="form__button">
            <VButton isLoading={false} text="Save Changes" mobWidth="100%" />
          </div>
        </VForm>
      </form>
    </VModal>
  );
};
