import { IUserBusinessDetails, useUpgradeBusinessPlan } from "api";
import { IGetBusinessPlans } from "api/manage-business/billing";
import { useBillingOverviewPageHook } from "hooks/components";
import { useAuthUserConstants } from "store";
import { convertToLocalCurrency, getBusinessConstants } from "utils";

export const useBillingPlanCardHook = () => {
  const {
    userBusinessPlan,
    currencyconverter,
    fetching: fetchingBusinessPlan,
    buinessPlansHash,
  } = useBillingOverviewPageHook();

  const { isLoading: updgradingBusiness, handleUpgradeBusinessPlan } =
    useUpgradeBusinessPlan();

  const { symbol } = currencyconverter;

  const {
    status: { hasPaidBusinessPlan },
    business,
  } = useAuthUserConstants();

  const businessConstants = getBusinessConstants(
    business ?? ({} as IUserBusinessDetails)
  );

  const { price = "0" } = (userBusinessPlan || {}) as IGetBusinessPlans;

  const planSubtitle = businessConstants?.isFreeTrial
    ? "Free Trial"
    : hasPaidBusinessPlan
    ? "Paid"
    : "Free";

  const convertedPrice = convertToLocalCurrency({
    symbol,
    amount: Number(price),
  });

  return {
    businessConstants,
    userBusinessPlan,
    currencyconverter,
    updgradingBusiness,
    handleUpgradeBusinessPlan,
    planSubtitle,
    convertedPrice,
    business,
    fetchingBusinessPlan,
    nextBusinessPlan: (buinessPlansHash as Record<string, any>)?.[
      businessConstants?.nextBusinessPlanCode ?? ""
    ],
  };
};
