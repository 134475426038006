import { IPagination } from "./../types";
import { gql, useQuery } from "urql";
import { PaginationFragment } from "api/general-queries";
import { useVAppSelector } from "hooks/useStore";
import { SEARCH_TYPE } from "store";

type useGetBusinessSalesProps = {
  businessId: string;
  page?: number;
  search?: string;
  createdAt?: {
    lte: string;
    gte: string;
  };
};

export const useGetBusinessSales = ({
  businessId,
  page = 1,
  search = "",
  createdAt,
}: useGetBusinessSalesProps) => {
  const { page: salesPage, term: searchTerm } = useVAppSelector(
    (state) => state.search
  );

  return useQuery({
    query: getBusinessSalesQuery,
    variables: {
      businessId,
      page,
      ...(createdAt && { createdAt }),
      searchQuery: salesPage === SEARCH_TYPE.SALES ? searchTerm : search,
    },
  });
};

const getBusinessSalesQuery = gql`
  query ($businessId: String!, $page: Int, $searchQuery: String, $createdAt: RangeInput) {
    getBusinessSales(
    filters: {businessId: $businessId, searchQuery: $searchQuery, createdAt: $createdAt}
     metadata: {
        size: 10,
        page: $page,
        sortOrder: "desc"
    }
   ){
     nodes{
      _id
      saleDate
      businessCustomerId
      salePayments {
        _id
        saleId
        paymentType
         paidAmount
        createdAt
        customerId
      }
      clientName
      totalItems
      totalSale
      referenceId
      saleItems {
        	_id
          customerProductId
        	customerProductName
        	customerServiceName
          quantity
          sellingPrice
          customerProduct {
           _id
          productImage
          productName
        }
         
      }
    }, 
   ${PaginationFragment}
  }
  }
`;

export interface IBusinessSales extends IPagination {
  nodes: {
    _id: string;
    salesDate: string;
    businessCustomerId: string;
    salePayments: {
      _id: string;
      saleId: string;
      paymentType: String;
      paidAmount: number;
      createdAt: Date;
      customerId: string;
    };
    totalSale: number;
    clientName: string | null;
    totalItems: number;
    referenceId: string;
    saleItems: {
      _id: string;
      customerProductId: string;
      customerServiceName: string;
      quantity: number;
      customerProductName: string;
      sellingPrice: string;
      customerProduct: {
        _id: string;
        productName: string;
        productImage: string;
      };
    };
  }[];
}
