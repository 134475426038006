import { VModal } from "components/common";
import styled from "styled-components/macro";
import { stylingUtils } from "styles";

const { rem, color } = stylingUtils;

export const StyledKycVWrapper = styled("section")`
  margin-top: ${rem(20)};
  display: flex;
  flex-direction: column;
  gap: ${rem(20)};
`;

export const StyledKycValidationCardWrapper = styled(`div`)`
  background-color: white;
  border-radius: ${rem(10)};
  padding: ${rem(20)};

  h4 {
    margin-bottom: ${rem(15)};
  }

  .header {
    font-size: ${rem(18)};
    font-weight: 500;
    margin-bottom: ${rem(20)};
  }

  & > .link {
    color: ${color("lightBlue")};
    text-decoration: underline;
    display: inline;
    margin: 1rem 0;
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    gap: ${rem(20)};
    margin-top: ${rem(20)};
    @media (max-width: 500px) {
      flex-direction: column;
    }
  }
`;

export const StyledKycValidationCard = styled(`div`)`
  background-color: ${color("ash04")};
  max-width: ${rem(500)};
  width: 100%;
  border-radius: ${rem(10)};
  padding: ${rem(20)};

  .kyc-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h4 {
      font-size: ${rem(14)};
    }
  }

  .kyc-content {
    margin-top: ${rem(10)};
    color: ${color("ashText")};
    display: flex;

    .link {
      color: ${color("lightBlue")};
      text-decoration: underline;
      display: inline;
    }
  }
`;

export const StyledKycViewModal = styled(VModal)`
  color: black;
  max-width: ${rem(240)};
  margin: 0 auto;
  margin-bottom: ${rem(30)};
`;

export const StyledKycViewModalContent = styled("form")`
  .validate-btn {
    display: grid;
    place-items: center;
    margin-top: ${rem(20)};
  }
`;

export const VNinInstructions = styled("div")`
  display: flex;
  justify-content: flex-start;
  gap: ${rem(10)};
  border-radius: ${rem(20)};
  background: #faf3eb;
  width: 100%;
  height: 100%;
  padding: ${rem(10)};
  font-size: ${rem(14)};

  h4 {
    font-weight: 500;
    margin-bottom: ${rem(6)};
  }
  p {
    margin-bottom: ${rem(6)};

    &:nth-child(3) {
      margin-bottom: ${rem(10)};
    }
  }
`;

export const StyledLimit = styled("div")`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${rem(14)};

  h5 {
    font-size: ${rem(14)};
  }
`;

export const StyledIndemnityHeader = styled("h4")`
  margin-bottom: ${rem(14)};
`;
