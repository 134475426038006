export const APP_ROUTES = {
  SIGNIN: "/auth/signin",
  OAUTH_PAGE: "/auth/auth-page",
  OAUTH_CALLBACK_PAGE: "/auth",

  BUSINESS_REGISTRATION: "/business-registration",

  //Dashboard
  DASHBOARD: "/dashboard",

  //Expense
  EXPENSE_AND_SALES: "/books/expense&sales",
  SALES: "/books/expense&sales/sales",
  SALES_DETAIL: "/books/expense&sales/sales/:salesId",
  EXPENSE: "/books/expense&sales/expense",

  // Inventory
  INVENTORY: "/books/inventory",
  PRODUCTS: "/books/inventory/products",
  CREATE_PRODUCT: "/books/inventory/create-product",
  PRODUCT_DETAILS: "/books/inventory/products/:productId",
  SERVICES: "/books/inventory/services",
  CHECKOUT_BOOKS: "/books/checkout",

  //Staff
  STAFF: "/store/staff",
  STAFF_DETAILS: "/store/staff/:businessStaffId",

  // Business
  BUSINESS: "/business",
  STORES: "/business/stores",
  ROLES_AND_PERMISSIONS: "/business/roles-and-permissions",
  ROLES_AND_PERMISSIONS_SELECTION:
    "/business/roles-and-permissions/:role/:roleId",
  NEW_ROLES_AND_PERMISSIONS: "/business/roles-and-permissions/add",
  EDIT_ROLES_AND_PERMISSIONS: "/business/roles-and-permissions/edit",
  USER_PROFILE: "/business/user-profile",

  BILLING: "/business/billing",
  CHANGE_BILLING_PLAN: "/business/billing/change-billing-plan",
  CHECKOUT_BILLING_PLAN: "/business/billing/checkout",

  // Money
  MONEY: "/money",
  ACCOUNT: "/money/account",
  SEND_MONEY: "/money/account/send-money",
  BUY_AIRTIME: "/money/account/buy-airtime",
  BUY_DATA: "/money/account/buy-data",
  ELECTRICITY: "/money/account/electricity",
  INTERNET_SUB: "/money/account/internet-sub",
  BETTING: "/money/account/betting",
  TV_SUB: "/money/account/tv-sub",

  // Invoices
  INVOICES: "/money/invoices",
  CREATE_INVOICE: "/money/invoices/create-invoice",
  INVOICE_PRODUCTS: "/money/invoices/create-invoice/products",
  INVOICE_SERVICES: "/money/invoices/create-invoice/services",
  EDIT_INVOICE: "/money/invoices/edit-invoice/:invoiceId",

  // Pos
  POS: "/money/pos",
  POS_DETAILS: "/money/pos/pos-details",
  POS_SETTINGS: "/money/pos/pos-settings",
  POS_REQUESTED: "/money/pos/pos-requested",
  REQUEST_POS: "/money/pos/request-pos",
  BUY_POS: "/money/pos/buy-pos",
  POS_SURVEY: "/money/pos/survey",
  POS_CARD_DETAILS: "/money/pos/pos-details/:posId",
  POS_REQUEST_DETAILS: "/money/pos/pos-request-details/:posId",

  //REPORTS
  REPORT: "/store/report",
  REPORT_SALES: "/store/report/sales",
  REPORT_EXPENSES: "/store/report/expenses",
  REPORT_INVENTORY: "/store/report/inventory",
  REPORT_STAFF: "/store/report/staff",
  REPORT_INVOICES: "/store/report/invoices",

  // Store
  STORE: "/store",
  CUSTOMERS: "/store/customers",
  CUSTOMERS_DEBTORS: "/store/customers/debtors",
  CUSTOMERS_DEBTORS_DETAILS: "/store/customers/debtors/:businessCustomerId",
  MARKETPLACE: "/store/marketplace",
  CUSTOMER_DETAILS: "/store/customers/:customerId",

  // Referrals
  REFERRAL: "/referral",
  REFERRAL_INFO: "/referral/information",
  REFERRAL_INFO_DETAILS: "/referral/information/:id",
  REFERRAL_SALES: "/referral/sales",
  REFERRAL_SALES_DETAILS: "/referral/sales/:id",

  // Indemnity
  INDEMNITY: "store/indemnity",
  INDEMNITY_FORM: "store/indemnity/form",

  //BOOKS
  CHECKOUT: "/books/checkout",

  // Import Products
  IMPORT_PRODUCTS: "/notification/import",
  EDIT_IMPORTED_PRODUCTS: "/notification/import/edit-product",
  VERIFY_PAYMENT: "/verify-payment",

  // Verify Business
  VERIFY_BUSINESS: "/verify-business-mail",

  // Verify User
  VERIFY_USER: "/verify-user-mail",

  RESET_PASSCODE: "/passcode/reset",

  IMPORTED_FILES: "/imported-files",
};

export const SCREEN_ROUTES = {
  REDIRECT_AUTHENTICATED: APP_ROUTES.DASHBOARD,
  REDIRECT_UNAUTHENTICATED: APP_ROUTES.SIGNIN,
  BUSINESS_REGISTRAION: APP_ROUTES.BUSINESS_REGISTRATION,
};
