import { gql, useQuery } from "urql";
import { CardProps } from "components/common/types";

import { formatNumberToCurrency } from "utils";
import StockValue from "assets/icons/summary/stock-value";
import SalesCount from "assets/icons/summary/sales/sales-count";
import ProfitIcon from "assets/icons/summary/sales/profit";
import GrowthIcon from "assets/icons/summary/sales/last-weeks-growth";
import { StyledInvoiceIconWrapper } from "components/invoices/styles";

export const useGetSalesSummary = ({
  businessId,
  createdAt,
}: {
  businessId: string;
  createdAt?: {
    gte: string;
    lte: string;
  };
}) => {
  return useQuery({
    query: getSalesSummary,
    variables: { businessId, history: "WEEKLY", createdAt },
  });
};

const getSalesSummary = gql`
  query (
    $businessId: String
    $history: TRANSACTIONHISTORY
    $createdAt: RangeInput
  ) {
    getSaleStat(filters: { businessId: $businessId, createdAt: $createdAt }) {
      totalGain
      totalSale
      totalProfit
      totalItemSold
    }

    getSalesOverview(history: $history) {
      upturn
    }
  }
`;

interface GetSalesSummaryAPI {
  getSaleStat: {
    totalGain: string;
    totalSale: string;
    totalProfit: string;
    totalItemSold: string;
  };
  getSalesOverview: {
    upturn: string;
  };
}

export const mapFromSalesSummaryAPI = (
  data: GetSalesSummaryAPI
): CardProps[] => {
  const cards: CardProps[] = [];

  for (const key in data["getSaleStat"]) {
    switch (key) {
      case "totalSale": {
        cards[0] = {
          title: "Total Sales Value",
          amount: formatNumberToCurrency({
            number: data["getSaleStat"]?.totalSale,
          }),
          icon: () => (
            <StyledInvoiceIconWrapper color="#FBECDB">
              <StockValue style={{ fill: "#F1A85A" }} />
            </StyledInvoiceIconWrapper>
          ),
        };
        break;
      }
      case "totalGain": {
        cards[1] = {
          title: "Sales Count",
          amount: formatNumberToCurrency({
            number: data["getSaleStat"]?.totalItemSold,
            removeCurrency: true,
          }),
          icon: () => (
            <StyledInvoiceIconWrapper color="#E8F5F1">
              <SalesCount style={{ fill: "#34A853" }} />
            </StyledInvoiceIconWrapper>
          ),
        };
        break;
      }
      case "totalProfit": {
        cards[2] = {
          title: "Profit",
          amount: formatNumberToCurrency({
            number: data["getSaleStat"]?.totalProfit,
          }),
          icon: () => (
            <StyledInvoiceIconWrapper color="#E8F5F1">
              <ProfitIcon style={{ fill: "#34A853" }} />
            </StyledInvoiceIconWrapper>
          ),
        };
        break;
      }

      default:
        break;
    }
  }

  cards[3] = {
    title: "Last Week's Growth",
    amount: data["getSalesOverview"]?.upturn,
    icon: () => (
      <StyledInvoiceIconWrapper color="#E0EEFF">
        <GrowthIcon style={{ stroke: "#0077FF" }} />
      </StyledInvoiceIconWrapper>
    ),
  };

  return cards;
};
