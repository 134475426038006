import styled from "styled-components/macro";
import { FlexAMixin, FlexJAMixin, stylingUtils } from "styles";

const { rem } = stylingUtils;

export const StyledVBackButton = styled("button")`
  ${FlexAMixin};
  gap: ${rem(10)};
  margin-bottom: ${rem(20)};
  transition: 0.5s all ease;

  h3 {
    transition: 0.5s all ease;
  }

  &:hover {
    h3 {
      transform: translateX(5px);
    }
  }
`;

export const StyledVBackIcon = styled("div")`
  ${FlexJAMixin};
  width: ${rem(40)};
  height: ${rem(40)};
  border-radius: 99999%;
  background-color: white;
`;
