import { omit } from "lodash";
import { useToggle, useOauthCallBackHook, useIndexDb } from "hooks";
import { AppStorage, STORGAGE_KEYS, uploadMedia } from "utils";
import { gql, useMutation } from "urql";
import { handleRequestCall } from "utils/handleRequestCall";
import { useAuthUser } from "store";
import { IAddCustomerBusiness } from "./types";
import { BUSINESS_PLANS } from "components/auth/signup/constants";

const { getFromStore, removeMultipleFromStore } = new AppStorage();

export const useAddCustomerBusiness = () => {
  const [customerBusiness, addCustomerBusiness] = useMutation(
    addCustomerBusinessMutation
  );
  const { checkIsAuthenticated } = useOauthCallBackHook();

  const [imageProcessing, toggleImageProcessing] = useToggle();
  const { userData } = useAuthUser();
  const { removeFromStore: removeFromIndexDB } = useIndexDb();

  const handleAddCustomerBusiness = async (
    data: IAddCustomerBusiness,
    callBack?: (data: any) => void
  ) => {
    toggleImageProcessing();
    const dataWithoutImage = omit(data, ["businessEmblem", "utilityBill"]);
    const rawServiceImage = data?.businessEmblem;
    const utilityBill = data?.utilityBill;
    let processedFileImage: null | string = null;
    let processedUtilityBill: null | string = null;

    try {
      if (rawServiceImage) {
        const uploadedFile = await uploadMedia(rawServiceImage as File);
        if (!uploadedFile) {
          toggleImageProcessing();
          return;
        }
        processedFileImage = uploadedFile[0]?.url;
      }

      if (utilityBill) {
        const uploadedUtilityFile = await uploadMedia(utilityBill as File);
        if (!uploadedUtilityFile) {
          toggleImageProcessing();
          return;
        }
        processedUtilityBill = uploadedUtilityFile[0]?.url;
      }

      toggleImageProcessing();
      const values = {
        ...dataWithoutImage,
        ...(processedFileImage && { businessEmblem: processedFileImage }),
        ...(processedUtilityBill && { utilityBill: processedUtilityBill }),
        businessId: userData?.userId as string,
        businessPlan:
          getFromStore(STORGAGE_KEYS.BUSINESS_PLAN) ?? BUSINESS_PLANS.ESSENTIAL,
      };
      const req = await addCustomerBusiness(values);

      await handleRequestCall({
        request: req,
        callBack: async () => {
          await removeFromIndexDB(STORGAGE_KEYS.BUSINESS_STORE_DETAILS);
          await removeMultipleFromStore([
            STORGAGE_KEYS.BUSINESS_PLAN,
            STORGAGE_KEYS.BILLING_REDIRECT_SUCESSFUL,
          ]);
          await checkIsAuthenticated();
        },
      });
    } catch (e) {}
  };

  return {
    handleAddCustomerBusiness,
    customerBusiness,
    isLoading: imageProcessing || customerBusiness?.fetching,
  };
};

const addCustomerBusinessMutation = gql`
  mutation (
    $customerId: String!
    $firstName: String
    $lastName: String
    $phoneNumber: String
    $businessName: String
    $businessEmail: String
    $businessPhoneNumber: String
    $businessAddress: String
    $businessEmblem: String
    $stateId: String
    $cityId: String
    $stateName: String
    $cityName: String
    $deviceToken: String
    $referredBy: String
    $businessPlan: BusinessPlanCode
    $CACDocument: String
    $businessSectorId: String
    $utilityBill: String
    $CACNumber: String
    $isSubscribed: Boolean
    $NIN: String
  ) {
    addBusiness(
      customerId: $customerId
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      businessName: $businessName
      businessEmail: $businessEmail
      businessPhoneNumber: $businessPhoneNumber
      businessAddress: $businessAddress
      businessEmblem: $businessEmblem
      stateId: $stateId
      cityId: $cityId
      stateName: $stateName
      cityName: $cityName
      deviceToken: $deviceToken
      referredBy: $referredBy
      businessPlan: $businessPlan
      CACDocument: $CACDocument
      businessSectorId: $businessSectorId
      utilityBill: $utilityBill
      CACNumber: $CACNumber
      isSubscribed: $isSubscribed
      NIN: $NIN
    ) {
      _id
      businessName
      businessEmail
      CACNumber
    }
  }
`;
