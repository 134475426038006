import { VBackProps } from "../types";
import { ReactComponent as ChevronLeft } from "assets/icons/chevron-left.svg";
import { useNavigate } from "react-router-dom";
import { StyledVBackButton, StyledVBackIcon } from "./styles";

export const VBack = ({ text, url }: VBackProps) => {
  const navigate = useNavigate();

  return (
    <StyledVBackButton onClick={() => (url ? navigate(url) : navigate(-1))}>
      <StyledVBackIcon>
        <ChevronLeft height={34} />
      </StyledVBackIcon>
      {text && <h3>{text}</h3>}
    </StyledVBackButton>
  );
};
