import { isEmpty } from "lodash";
import {
  IBusinessRolesAndPermission,
  IUserBusinessDetails,
  useGetBusinessRolesAndPermissionsQuery,
  useGetUserBusinessDetails,
} from "api";
import { IBusinessStaffNode } from "api/staff";
import {
  IAddOrEditStaff,
  useAddBusinessStaff,
} from "api/staff/add-business-staff";
import { showToast } from "components/common";
import { ADD_OR_EDIT_STAFF_INPUT_LIST } from "components/staff/constants";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useAuthUser } from "store";
import { convertToOptions, extractFetchedData } from "utils";
import { useAnalytics } from "hooks/useAnalytics";

export type IAddOrEditStaffModalType = "ADD" | "EDIT";

export const useAddOrEditStaffHook = ({
  callback,
  selectedUser,
}: {
  callback?: () => void;
  selectedUser: IBusinessStaffNode | null;
}) => {
  const isEdit = selectedUser !== null && !isEmpty(selectedUser);

  const [logAppEvent, { open_create_staff, submit_created_staff }] =
    useAnalytics();

  if (!isEdit) {
    logAppEvent(open_create_staff, {}).catch(console.log);
  }

  const { handleAddBusinessStaff, isLoading } = useAddBusinessStaff(isEdit);

  const hookForm = useForm<IAddOrEditStaff>();

  const { reset, handleSubmit } = hookForm;

  const { userData } = useAuthUser();

  const [rolesAndPermissionResult] = useGetBusinessRolesAndPermissionsQuery({
    businessId: userData?.businessId ?? "",
  });

  const { extractedData: rolesAndPermission } = extractFetchedData<
    IBusinessRolesAndPermission[]
  >({
    key: "getRoleAndUserAssociated",
    result: rolesAndPermissionResult,
    defaultReturn: [],
  });

  const [userBusinessResult] = useGetUserBusinessDetails({
    businessId: userData?.businessId as string,
  });

  const { extractedData: userBusiness } =
    extractFetchedData<IUserBusinessDetails>({
      key: "getBusinessDetail",
      result: userBusinessResult,
    });

  const cardRef =
    JSON.stringify(rolesAndPermission) || JSON.stringify(userBusiness);

  const userBusinessRef = JSON.stringify(userBusiness);

  const inputLists = useMemo(() => {
    const modifiedRoles = convertToOptions({
      records: rolesAndPermission,
      label: "roleName",
      value: "_id",
    });
    const modifiedUserBusiness = convertToOptions({
      records: [userBusiness],
      label: "businessName",
      value: "_id",
    });
    return ADD_OR_EDIT_STAFF_INPUT_LIST({
      roles: modifiedRoles,
      outlets: modifiedUserBusiness,
      isEdit,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardRef, isEdit]);

  useEffect(() => {
    if (isEdit && selectedUser) {
      const {
        assignedOutlet,
        firstName = "",
        lastName = "",
        email = "",
        roleId = "",
        phoneNumber = " ",
        _id,
        profilePicture,
      } = selectedUser;

      const newPhoneNumber = phoneNumber.replace("+234", "");

      reset({
        assignedOutlet: ((assignedOutlet || [])[0] ?? "")?._id || "",
        firstName,
        lastName,
        email,
        roleId,
        profilePicture,
        phoneNumber:
          newPhoneNumber[0] === "0" ? newPhoneNumber.slice(1) : newPhoneNumber,
        businessStaffId: _id,
      });
    } else if (userBusiness) {
      reset({
        assignedOutlet: userBusiness?._id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userBusinessRef, isEdit]);

  const onSubmit = async (data: IAddOrEditStaff) => {
    logAppEvent(submit_created_staff, {}).catch(console.log);
    await handleAddBusinessStaff(data, () => {
      resetForm();
      showToast({
        subText: isEdit
          ? "Business Staff updated successfully"
          : "Business Staff was added successfully",
      });
      callback?.();
    });
  };

  const resetForm = () => {
    reset({
      firstName: "",
      lastName: "",
      email: "",
      roleId: "",
      phoneNumber: "",
      profilePicture: "",
    });
  };

  const handleFormSubmit = handleSubmit(onSubmit, (error) => {
    console.log({ error });
  });

  return {
    hookForm,
    inputLists,
    isLoading,
    handleFormSubmit,
    resetForm,
  };
};
