export enum SEARCH_TYPE {
  EXPENSE = "EXPENSE",
  SALES = "SALES",
  INVENTORY_PRODUCT = "INVENTORY_PRODUCT",
  INVENTORY_SERVICE = "INVENTORY_SERVICE",
  CUSTOMERS = "CUSTOMERS",
  BILLING = "BILLING",
  STAFF = "STAFF",
  INVOICES = "INVOICES",
  DEFAULT = "",
}
