import styled from "styled-components/macro";
import { FlexColumn, StyledVCardContainer, stylingUtils } from "styles";

const {
  rem,
  mediaQuery: { down },
} = stylingUtils;

export const StyledCheckoutCard = styled(StyledVCardContainer)`
  min-width: ${rem(300)};
  width: 100%;
  flex: auto;
  ${FlexColumn};
  overflow-x: scroll;
`;

export const StyledCheckoutCardHeader = styled("h3")<{
  largeTitle: boolean;
  titleFontSize: string;
}>`
  font-weight: ${({ largeTitle }) => (largeTitle ? 600 : 500)};
  font-size: ${({ largeTitle, titleFontSize }) =>
    titleFontSize ? titleFontSize : largeTitle ? rem(25) : rem(20)};
  display: flex;
  gap: ${rem(10)};
  align-items: center;
  flex: auto;

  ${down("tablet")} {
    margin-bottom: 2rem;
  }
`;

export const StyledCheckoutCardAction = styled("div")`
  justify-self: flex-end;
`;

export const StyledCheckoutCardHeaderWrapper = styled("div")`
  display: flex;
  align-items: center;
  margin-bottom: ${rem(20)};
`;
