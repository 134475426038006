import { useIndexDb, useToggle } from "hooks";
import { useEffect, useState } from "react";
import { STORGAGE_KEYS } from "utils";

export const usePasscodeStoreHook = () => {
  const { getFromStore, addToStore } = useIndexDb();

  const [hasVerifyPasscode, setHasVerifyPasscode] = useState(false);
  const [hasSetPasscode, setHasSetPasscode] = useState(false);
  const [isLoading, toggleLoading] = useToggle();
  const [passcode, setPasscode] = useState("");

  /**
   * Transaction PIN STATES
   */

  const [openTrasactionPinModal, setOpenTrasactionPinModal] = useState(false);

  /**
   * Transaction pin functions
   */

  const toggleTransactionPin = () => setOpenTrasactionPinModal((prev) => !prev);

  const getPassCodeStatus = async () => {
    toggleLoading();
    const getVerifyPasscode = await getFromStore(
      STORGAGE_KEYS.HAS_VERIFY_PASSCODE
    );
    const getSetPasscode = await getFromStore(STORGAGE_KEYS.HAS_SET_PASSCODE);

    setHasSetPasscode(getSetPasscode);
    setHasVerifyPasscode(getVerifyPasscode);
    toggleLoading();
  };

  useEffect(() => {
    getPassCodeStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addSetPasscode = async (value: boolean) => {
    toggleLoading();
    await addToStore(STORGAGE_KEYS.HAS_SET_PASSCODE, value);
    setHasSetPasscode(value);
    toggleLoading();
  };
  const addVerifyPasscode = async (value: boolean) => {
    toggleLoading();
    await addToStore(STORGAGE_KEYS.HAS_VERIFY_PASSCODE, value);
    setHasVerifyPasscode(value);
    toggleLoading();
  };

  return {
    hasVerifyPasscode,
    hasSetPasscode,
    getPassCodeStatus,
    addSetPasscode,
    addVerifyPasscode,
    isLoading,
    passcode,
    setPasscode,
    // transaction pin exports
    openTrasactionPinModal,
    toggleTransactionPin,
  };
};
