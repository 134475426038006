import styled from "styled-components";
import { stylingUtils } from "styles";

const {
  rem,
  mediaQuery: { down },
} = stylingUtils;

export const Nav = styled.div`
  display: flex;
  align-items: center;
`;

export const ChevronArrow = styled.div`
  svg {
    width: 100%;
  }

  border-radius: 50%;
  background-color: #fff;
  padding: 1rem;
  display: inline-block;
  margin: 0 0.8rem;
  width: ${rem(45)};
  box-shadow: 0px 5px 16px -4px rgba(29, 28, 28, 0.1);
  cursor: pointer;
`;

export const Info = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem;
  width: 100%;
  background: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 12px;
  margin: 2rem 0;
  align-items: center;
  ${down("laptop")} {
    padding: 1rem 1rem;
  }
`;

export const Edit = styled.div`
  margin-right: 1rem;
  cursor: pointer;
`;

export const Id = styled.div`
  margin-top: "0.8rem";
`;

export const StyledUtilContainer = styled.div`
  width: 100%;

  @media (max-width: 320px) {
    font-size: ${rem(11)};
  }
`;

export const StyledText = styled.span`
  color: #0077ff;
`;

export const StyledId = styled(StyledText)`
  text-decoration: underline;
`;

export const StyledProperties = styled(StyledUtilContainer)`
  text-align: start;
  margin-top: 1.6rem;
`;

export const StyledPropertyTitle = styled.div`
  color: ${(props) => props.theme.colors.ash03};
`;

export const StyledPropertyValue = styled.p`
  font-weight: ${(props) => props.theme.fonts.weight.bold};
`;

export const StyledPropertySection = styled.div`
  display: flex;
  justify-content: left;
  gap: 4rem;
  font-size: 1rem;

  @media (max-width: 430px) {
    justify-content: center;
  }
`;

export const StyledProductContainer = styled.div`
  position: relative;
  width: 50%;
  background-color: #eee;
  border-radius: ${rem(5)};
`;

export const ContainerStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  width: 100%;
  margin-bottom: 2rem;
  flex: 1;
  gap: 1rem;
  overflow-x: scroll;
`;

export const StyledFilterContainer = styled(StyledUtilContainer)`
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  margin-bottom: 2rem;
`;

export const StyledFilters = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 65%;
`;

export const StyledFilter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #5c5c5c;
  font-family: "Gilmer";
  font-style: normal;
  font-weight: 600;
  font-size: ${rem(16)};
  line-height: ${rem(19)};
  color: #5c5c5c;
`;

export const StyledFirstFilter = styled(StyledFilter)`
  background-color: #ddeaff;
  color: #0077ff;
  padding: 0 1.5rem;
  width: 8rem;
  height: 3rem;
  border-radius: ${rem(30)};
`;

export const StyledButtons = styled(StyledFilters)`
  display: flex;
  justify-content: space-between;
  width: 30%;
`;

export const StyledTable = styled.table`
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;

  td:nth-child(1) {
    width: 40%;
  }
`;

export const StyledTData = styled.td`
  color: #374b58;
  border-bottom: 1px solid #eee;
  text-align: left;
  padding: 8px;
  height: 3.5rem;
`;

export const StyledTitle = styled(StyledTData)`
  color: #666666;
`;

// export const StyledButton = styled.div`
//   display: flex;
//   justify-content: center;
//   width: 100%;
//   margin: ${rem(20)} 0 ${rem(10)} 0;
//   grid-column: auto / span 2 !important;
// `;

export const StyledGroup = styled.div`
  display: flex;
  gap: 6rem;
  align-items: start;
  ${down("laptop")} {
    justify-content: space-between;
    gap: 1rem;
  }
`;

export const StyledLastGroup = styled(StyledGroup)`
  gap: 3rem;
`;

export const StyledItem = styled("div")<{ $position?: string }>`
  display: flex;
  flex-direction: column;
  ${down("laptop")} {
    flex-direction: column;
    text-align: ${({ $position }) => $position};
  }
`;

export const StyledDetailFrame = styled(StyledUtilContainer)`
  display: flex;
  justify-content: left;
  background-color: white;
  padding: 1rem 1rem 2rem 1rem;
  gap: 2rem;
  border-radius: ${rem(12)};
  margin-bottom: 2rem;
  ${down("laptop")} {
    flex-direction: column;
  }
`;

export const StyledProductName = styled.h2`
  margin-bottom: 0.5rem;
  font-family: "Gilmer";
  ${down("laptop")} {
    font-size: ${rem(14)};
  }
`;

export const StyledPropertyGroup = styled.div`
  display: grid;
  grid-gap: 1.5rem;
  ${down("laptop")} {
    width: 100%;
  }
`;

export const StyledOtherPropertyGroup = styled(StyledPropertyGroup)`
  grid-gap: 5rem;
`;
export const StyledButton = styled.div`
  display: flex;
  gap: 6rem;
  align-items: start;
  ${down("laptop")} {
    justify-content: space-between;
    gap: 1rem;
  }
`;

export const StyledFirstGroup = styled(StyledGroup)`
  align-items: center;
  div {
    ${down("laptop")} {
      font-size: ${rem(14)};
    }
  }
`;

export const StyledValue = styled.div`
  font-weight: 600;
  font-size: ${rem(14)};
  line-height: 17px;
  align-items: center;
`;

export const StyledButtonContainer = styled("div")`
  display: flex;
  gap: ${rem(20)};
`;
