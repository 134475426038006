import { ICablePackage } from "./../../components/account/types";
import { useVeedezApiRequest } from "./../../hooks/useApiRequest";

// Custom Components
import { showToast } from "components/common";

// Utils
import { VALUE_ADDED_SERVICES_ROUTES } from "api/api.routes";
import { useState, useCallback } from "react";

// Functionality
export const useGetCablePackages = (cableProvider: string) => {
  const makeRequest = useVeedezApiRequest();
  const [cablePackages, setCablePackages] = useState<ICablePackage[]>([]);

  const getCablePackages = useCallback(async () => {
    try {
      const { data } = await makeRequest.get(
        `${VALUE_ADDED_SERVICES_ROUTES.GET_PACKAGES}?provider=${cableProvider}`
      );
      setCablePackages(data.data);
    } catch (e) {
      showToast({
        type: "error",
        title: "An error occurred",
        subText: "Please try again",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cableProvider]);

  return {
    getCablePackages,
    cablePackages,
  };
};
