import { gql, useQuery } from "urql";

export const useGetStateAndCities = () => {
  return useQuery({
    query: getStateAndCitiesQuery,
  });
};

const getStateAndCitiesQuery = gql`
  query {
    getStateAndCities {
      _id
      stateName
      cities {
        cityName
        _id
      }
    }
  }
`;

export interface IStateAndCities {
  _id: string;
  stateName: string;
  cities: {
    cityName: string;
    _id: string;
  }[];
}
