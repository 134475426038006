import { SidebarSvgProps } from "../../../components/sidebar/types";

const ReportIcon = (props: SidebarSvgProps) => {
  return (
    <svg
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.2787 13.3887C16.9534 13.3887 17.5197 13.9455 17.4166 14.6118C16.8113 18.5318 13.4555 21.4423 9.40815 21.4423C4.93025 21.4423 1.30078 17.8129 1.30078 13.336C1.30078 9.6476 4.10289 6.21181 7.25762 5.43497C7.93552 5.2676 8.63025 5.74445 8.63025 6.44234C8.63025 11.1708 8.7892 12.3939 9.6871 13.0592C10.585 13.7244 11.6408 13.3887 16.2787 13.3887Z"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.6921 9.45121C20.7458 6.41331 17.0142 1.51647 12.4669 1.60068C12.1132 1.607 11.83 1.90173 11.8142 2.25437C11.6995 4.75226 11.8542 7.9891 11.9406 9.45647C11.9669 9.91331 12.3258 10.2723 12.7816 10.2986C14.29 10.3849 17.6448 10.5028 20.1069 10.1302C20.4416 10.0796 20.6869 9.7891 20.6921 9.45121Z"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default ReportIcon;
