import { lazy } from "react";

export * from "./total-sales-graph";
export * from "./dashboard-welcome-tab";
export * from "./frequent-customers-table";
export * from "./top-selling-products-table";
export * from "./constants";
export * from "./styles";

export const DashboardSummaryCards = lazy(
  () => import("./dashboard-summary-cards")
);
export const FrequentCustomersTable = lazy(
  () => import("./frequent-customers-table")
);
export const TopSellingProductsTable = lazy(
  () => import("./top-selling-products-table")
);
