import { lazy } from "react";

export * from "./create-invoice/product-invoice-page";
export * from "./create-invoice/services-invoice-page";

export const InvoicesPage = lazy(() => import("./invoices"));
export const CreateInvoicePage = lazy(() => import("./create-invoice-page"));
export const EditInvoicePage = lazy(
  () => import("./edit-invoice/edit-invoice")
);
