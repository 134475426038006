export const ALERZO_PAY_BASE_URL = "";

export const API_ROUTES = {
  INITIATE_CHARGE: "/card/charge",
  VALIDATE_OTP: "/card/otp",
  VERIFY_CHARGE: "/card/verify",
  CHARGE_WITH_TOKEN: "/card/token",
};

export const PASSCODE_API_ROUTES = {
  VERIFY_PASSCODE: "/auth/verify-passcode",
  SET_PASS_CODE: "/auth/passcode",
};

export const VALUE_ADDED_SERVICES_ROUTES = {
  GET_AIRTIME_PROVIDERS: "/payapi/airtime/providers",
  BUY_AIRTIME: "/payapi/airtime/purchase",
  DISCOS: "payapi/electricity/discos",
  DISCO_CUSTOMER_NAME: ({
    meterNumber,
    disco,
  }: {
    disco: string;
    meterNumber: string;
  }) => `payapi/electricity/resolve?meterNumber=${meterNumber}&disco=${disco}`,
  PURCHASE_ELECTRICITY: "payapi/electricity/purchase",
  GET_NETWORK_PLANS: "/payapi/data/bundles",
  BUY_DATA: "/payapi/data/purchase",
  GET_CABLE_PROVIDERS: "/payapi/cable/providers",
  GET_PACKAGES: "/payapi/cable/packages",
  RESOLVE_PROVIDER_ACCOUNT_NAME: "/payapi/cable/resolve",
  PURCHASE_CABLE: "/payapi/cable/purchase",
};
