import { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { timeZone, countryCodeJson } from "assets/json";
import { CURRENCY_CONVERTER_KEY } from "utils";

const { countries, zones } = timeZone;

export const useCurrencyConverterHook = () => {
  const [amount, setAmount] = useState(1);
  const [fetching, setFetching] = useState(false);

  // get country name
  const timeZoneCityToCountry = useMemo(() => {
    const tzCountry: Record<string, any> = {};
    Object.keys(zones).forEach((zone) => {
      const cityArr = zone.split("/");
      const city = cityArr[cityArr.length - 1];
      tzCountry[city] = (countries as any)[
        (zones as any)[zone].countries[0]
      ].name;
    });
    return tzCountry;
  }, []);

  const getCityCountryAndTimezone = useMemo(() => {
    let userCity;
    let userCountry = {};
    let userTimeZone;

    if (Intl) {
      userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      var tzArr = userTimeZone.split("/");
      userCity = tzArr[tzArr.length - 1];
      userCountry = timeZoneCityToCountry[userCity];
    }

    const getCode = countryCodeJson.find(
      (currentValue) => currentValue.name === userCountry
    );

    const currencyCode = getCode ? getCode.currency.code : "USD";
    const symbol = getCode ? (getCode.currency.symbol as string) : "$";

    return {
      userCity,
      userCountry,
      userTimeZone,
      getCode,
      symbol,
      currencyCode,
    };
  }, [timeZoneCityToCountry]);

  // get local currency code and symbol

  // convert to local currency
  useEffect(() => {
    // // eslint-disable-next-line  no-unused-vars
    const fetchCall = async () => {
      const { currencyCode } = getCityCountryAndTimezone;

      if (currencyCode) {
        try {
          let { data } = await axios.get(
            `https://api.api-ninjas.com/v1/convertcurrency?want=${currencyCode}&have=NGN&amount=${1}&X-Api-Key=${CURRENCY_CONVERTER_KEY}`
          );
          setAmount(data.new_amount);
        } catch (error) {
          console.log(error);
        } finally {
          setFetching(false);
        }
      }
    };
  }, [getCityCountryAndTimezone]);

  return {
    ...getCityCountryAndTimezone,
    // symbol: fetching ? '$' : getCityCountryAndTimezone?.symbol,
    symbol: "₦",
    currencyCode: fetching ? "USD" : getCityCountryAndTimezone?.currencyCode,
    amount,
    fetching,
  };
};
