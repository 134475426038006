import { IUserDetails, useGetUserDetails } from "api";
import { useAuthUser } from "store";
import { extractFetchedData } from "utils";

export const useUserProfileHook = () => {
  const { userData } = useAuthUser();

  const [result, refetchUserProfile] = useGetUserDetails({
    userId: userData?.userId,
  });

  const extractData = extractFetchedData<IUserDetails>({
    key: "getUserDetail",
    result,
  });

  const { firstName, lastName, isEmailVerified } = extractData?.extractedData;

  const fullName = `${firstName} ${lastName}`;

  return {
    ...extractData,
    extractedData: {
      ...extractData?.extractedData,
      password: "************",
      fullName,
      isEmailVerified,
    },
    refetchUserProfile,
  };
};
