const TotalServiceIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="3.66 0.28 16.69 23.44"
      {...props}
    >
      <path
        d="M20.3438 23.25V3.5625C20.3438 3.3 20.1375 3.09375 19.875 3.09375H13.4437C13.65 2.79375 13.7812 2.4375 13.7812 2.0625C13.7812 1.0875 12.975 0.28125 12 0.28125C11.025 0.28125 10.2188 1.0875 10.2188 2.0625C10.2188 2.45625 10.35 2.79375 10.5562 3.09375H4.125C3.8625 3.09375 3.65625 3.3 3.65625 3.5625V23.25C3.65625 23.5125 3.8625 23.7188 4.125 23.7188H19.875C20.1375 23.7188 20.3438 23.5125 20.3438 23.25ZM12 1.21875C12.4688 1.21875 12.8438 1.59375 12.8438 2.0625C12.8438 2.53125 12.4688 2.90625 12 2.90625C11.5312 2.90625 11.1562 2.53125 11.1562 2.0625C11.1562 1.59375 11.5312 1.21875 12 1.21875ZM6.5625 4.03125H8.4375V5.25C8.4375 5.38125 8.49375 5.49375 8.56875 5.5875C8.6625 5.68125 8.775 5.71875 8.90625 5.71875H15.0938C15.3562 5.71875 15.5625 5.5125 15.5625 5.25V4.03125H17.4375V20.8312H6.5625V4.03125ZM9.375 4.03125H14.625V4.78125H9.375V4.03125ZM19.4062 22.7812H4.59375V4.03125H5.625V21.3C5.625 21.5625 5.83125 21.7687 6.09375 21.7687H17.9062C18.1687 21.7687 18.375 21.5625 18.375 21.3V4.03125H19.4062V22.7812Z"
        fill="#EA4336"
      />
      <path
        d="M15.5625 8.15625H12.1875C11.925 8.15625 11.7188 8.3625 11.7188 8.625C11.7188 8.8875 11.925 9.09375 12.1875 9.09375H15.5625C15.825 9.09375 16.0312 8.8875 16.0312 8.625C16.0312 8.3625 15.825 8.15625 15.5625 8.15625ZM15.5625 12.375H12.1875C11.925 12.375 11.7188 12.5813 11.7188 12.8438C11.7188 13.1063 11.925 13.3125 12.1875 13.3125H15.5625C15.825 13.3125 16.0312 13.1063 16.0312 12.8438C16.0312 12.5813 15.825 12.375 15.5625 12.375ZM15.5625 16.5938H12.1875C11.925 16.5938 11.7188 16.8 11.7188 17.0625C11.7188 17.325 11.925 17.5313 12.1875 17.5313H15.5625C15.825 17.5313 16.0312 17.325 16.0312 17.0625C16.0312 16.8 15.825 16.5938 15.5625 16.5938ZM10.95 15.3563C10.7438 15.2063 10.4438 15.2625 10.2938 15.4875L9.16875 17.1938L8.4375 16.2188C8.2875 16.0125 7.9875 15.975 7.78125 16.125C7.575 16.275 7.5375 16.575 7.6875 16.7813L8.79375 18.2625C8.8875 18.375 9.0375 18.45 9.1875 18.45H9.20625C9.35625 18.45 9.50625 18.3563 9.6 18.225L11.0812 15.9938C11.2125 15.7875 11.1563 15.5063 10.95 15.3563ZM9 10.0313C9.76875 10.0313 10.4062 9.39375 10.4062 8.625C10.4062 7.85625 9.76875 7.21875 9 7.21875C8.23125 7.21875 7.59375 7.85625 7.59375 8.625C7.59375 9.39375 8.23125 10.0313 9 10.0313ZM9 8.15625C9.2625 8.15625 9.46875 8.3625 9.46875 8.625C9.46875 8.8875 9.2625 9.09375 9 9.09375C8.7375 9.09375 8.53125 8.8875 8.53125 8.625C8.53125 8.3625 8.7375 8.15625 9 8.15625ZM9.1875 11.3438C8.41875 11.3438 7.78125 11.9813 7.78125 12.75C7.78125 13.5188 8.41875 14.1563 9.1875 14.1563C9.95625 14.1563 10.5938 13.5188 10.5938 12.75C10.5938 11.9813 9.95625 11.3438 9.1875 11.3438ZM9.1875 13.2188C8.925 13.2188 8.71875 13.0125 8.71875 12.75C8.71875 12.4875 8.925 12.2813 9.1875 12.2813C9.45 12.2813 9.65625 12.4875 9.65625 12.75C9.65625 13.0125 9.45 13.2188 9.1875 13.2188Z"
        fill="#EA4336"
      />
    </svg>
  );
};

export default TotalServiceIcon;
