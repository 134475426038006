export interface CreateProductAPIRequest {
  productName: string;
  sellingPrice: number;
  purchasePrice: number;
  availableStock: number;
  productImage: string;
  thresholdLowStock: number;
  categoryId: string;
}

export interface EditProductRequest {
  businessId?: string;
  productId: string;
  productName: string;
  sellingPrice: number;
  purchasePrice?: number;
  quantity: number;
  productImage?: string;
  content: string;
  operation?: OPERATION;
}

interface Batch {
  purchasePrice: number;
  availableStock: string;
  productId: string;
  updatedAt: string;
}

export interface ProductTableData {
  _id: string;
  productName: string;
  sellingPrice: string;
  productImage: string;
  totalStock: number;
  batches: Batch[];
  category: {
    name: string;
  };
  referenceId: string;
  thresholdLowStock: number;
}

export interface ProductDetail {
  getCustomerProduct: {
    _id: string;
    productName: string;
    sellingPrice: string;
    productImage: string;
    batches: Batch[];
    totalStock: number;
    category: {
      name: string;
    };
    referenceId: string;
    createdAt: string;
    thresholdLowStock: number;
  };
}

export interface ProductSummaryAPI {
  getSaleStat: {
    totalItemSold: string;
  };
  getInventoryStatus: {
    totalStock: string;
    currentStockValue: string;
    currentStockCostPrice: string;
    lowStockCount: number;
  };
}

export enum OPERATION {
  INBOUND = "INBOUND",
  OUTBOUND = "OUTBOUND",
}

export interface ServiceDetailAPI {
  _id?: string;
  serviceId?: string;
  businessId?: string;
  serviceName: string;
  sellingPrice: number;
  category: {
    name: string;
  };
  description: string;
  referenceId?: string;
}
