import styled from "styled-components/macro";
import { stylingUtils } from "styles";

const { rem } = stylingUtils;

export const StyledRolesAndPermissionActionHeader = styled.div<{
  isAddPage?: boolean;
}>`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${rem(17)};
  background-color: white;
  border-radius: ${rem(10)};
  padding: ${({ isAddPage }) =>
    isAddPage ? `${rem(15)} ${rem(15)}` : `${rem(8)} ${rem(10)}`};
  align-items: center;
`;

export const StyledRolesAndActionBack = styled("button")`
  display: flex;
  align-items: center;
  gap: ${rem(10)};

  h5 {
    font-weight: 500;
  }
`;
