import { CreatedAtType } from "./../types";
import { useAuthUser } from "store";
import { gql, useQuery } from "urql";

const combinedQuery = gql`
  query (
    $businessId: String
    $bID: String
    $type: String
    $filters: GetExpensesBreakdownInput
    $createdAt: RangeInput
  ) {
    getExpensesBreakdown(
      businessId: $businessId
      type: $type
      filters: $filters
    )
    getExpenseStat(filters: { businessId: $bID, createdAt: $createdAt }) {
      totalExpense
      total
    }
    getExpensesByCategory(createdAt: $createdAt) {
      category
      totalAmount
    }
  }
`;

export const useGetReportExpense = (
  businessId: string,
  {
    type,
    filters,
    createdAt,
  }: {
    type?: string;
    filters?: Record<string, any>;
    createdAt?: CreatedAtType["createdAt"];
  } = {}
) => {
  return useQuery({
    query: combinedQuery,
    variables: { businessId, type, filters, bID: businessId, createdAt },
  });
};
export const useGetReportExpenseHook = ({
  filters,
  createdAt,
}: {
  filters?: Record<string, any>;
  createdAt?: CreatedAtType["createdAt"];
} = {}) => {
  const { userData } = useAuthUser();
  const [result, refetchReportExpense] = useGetReportExpense(
    userData?.businessId as string,
    {
      type: "graph",
      filters,
      createdAt,
    }
  );
  return {
    result,
    refetchReportExpense,
    data: (result?.data || {
      getExpenseStat: {
        total: 0,
        totoalExpense: 0,
      },
      getExpensesBreakdown: {},
      getExpensesByCategory: [],
    }) as IReportExpense,
  };
};

export interface IExpenseState {
  total: number;
  totoalExpense: number;
}
export interface IExpenseByCategory {
  category: string;
  totalAmount: string;
}

export interface IReportExpense {
  getExpenseStat: IExpenseState;
  getExpensesBreakdown: Record<string, any>;
  getExpensesByCategory: IExpenseByCategory[];
}
