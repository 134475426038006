const PremiumPlanIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 55 61.11"
      {...props}
    >
      <mask
        id="mask0_3535_121997"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="55"
        height="55"
      >
        <circle cx="27.5" cy="27.5" r="27.5" fill="#E4E9FF" />
      </mask>
      <g mask="url(#mask0_3535_121997)">
        <circle cx="27.5" cy="27.5" r="27.5" fill="#E4E9FF" />
        <mask
          id="mask1_3535_121997"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="10"
          y="17"
          width="34"
          height="45"
        >
          <rect
            x="10.9998"
            y="17.7227"
            width="33"
            height="43.3889"
            rx="14"
            fill="#4791FF"
          />
        </mask>
        <g mask="url(#mask1_3535_121997)">
          <rect
            x="10.9998"
            y="17.7227"
            width="33"
            height="43.3889"
            rx="14"
            fill="#2A2368"
          />
          <circle
            opacity="0.1"
            cx="27.4999"
            cy="20.1667"
            r="9.16666"
            fill="#0077FF"
          />
        </g>
        <circle cx="27.4999" cy="18.3346" r="9.16666" fill="#E57A1A" />
        <rect
          x="15.8889"
          y="32.3906"
          width="23.2222"
          height="7.94444"
          rx="3.97222"
          fill="#554E90"
        />
        <path
          d="M20.4722 34.8359H31.1666"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          opacity="0.5"
          d="M20.4722 37.8906H26.2777"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <rect
          x="15.8889"
          y="43.3867"
          width="23.2222"
          height="3.05556"
          rx="1.52778"
          fill="#554E90"
        />
        <path
          d="M26.8351 14.9897C27.0444 14.3455 27.9557 14.3455 28.165 14.9897C28.5264 16.1018 29.5628 16.8547 30.7321 16.8547C31.4094 16.8547 31.6911 17.7215 31.1431 18.1196C30.1971 18.8069 29.8012 20.0252 30.1626 21.1374C30.3719 21.7815 29.6346 22.3172 29.0866 21.9191C28.1406 21.2317 26.8596 21.2317 25.9135 21.9191C25.3656 22.3172 24.6283 21.7815 24.8376 21.1374C25.199 20.0252 24.8031 18.8069 23.8571 18.1196C23.3091 17.7215 23.5907 16.8547 24.268 16.8547C25.4374 16.8547 26.4738 16.1018 26.8351 14.9897Z"
          stroke="white"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};

export default PremiumPlanIcon;
