import { useModal } from "hooks";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import {
  IBusinessSectors,
  useGetBusinessSectors,
  useUpdateBusiness,
} from "api";
import { convertToOptions, extractFetchedData } from "utils";
import { COMPLETE_BUSINESS_PROFILE } from "components/manage-business";
import { useAuthUser } from "store";
import { showToast } from "components/common";

export const useUpdateBusinessHook = (callBack?: () => void) => {
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
  const hookForm = useForm<any>();
  const { userData } = useAuthUser();

  const profileBusinessSector = userData?.business?.businessSector;
  const sectorLength: number | any = profileBusinessSector?.length;

  const { handleSubmit, reset } = hookForm;

  const { handleUpdateBusiness, isLoading } = useUpdateBusiness();

  const onSubmit = async (data: any) => {
    const newData = {
      ...data,
      businessId: userData?.businessId,
      businessSectorId: data?.businessSectorId,
    };
    await handleUpdateBusiness(newData, () => {
      reset();
      showToast({
        subText: `Submitted successfully`,
      });
    });
    callBack?.();
  };

  const handleFormSubmit = handleSubmit(onSubmit);

  const { open, toggleModal } = useModal();

  const [result] = useGetBusinessSectors({});

  const { extractedData } = extractFetchedData<IBusinessSectors>({
    result,
    key: "getBusinessSectors",
  });

  const completeBusinessProfileInputList = useMemo(() => {
    const businessSector = convertToOptions({
      records: extractedData?.nodes || [],
      label: "sectorName",
      value: "_id",
    });

    return COMPLETE_BUSINESS_PROFILE({
      businessSector,
    });
  }, [extractedData?.nodes]);

  useEffect(() => {
    if (sectorLength < 1) {
      toggleModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectorLength]);

  return {
    handleFormSubmit,
    hookForm,
    toggleBusinessProfileModal: toggleModal,
    openBusinessProfileModal: open,
    completeBusinessProfileInputList,
    terms: { hasAcceptedTerms, setHasAcceptedTerms },
    isLoading,
  };
};
