import { IPagination } from "./../types";
import { gql, useQuery } from "urql";
import { ICustomerTerminal } from "../types";

type useGetCustomerTerminalsProps = {
  size?: number;
  page?: number;
  status?: string;
};

export interface ICustomerTerminals extends IPagination {
  nodes: ICustomerTerminal[];
}

const getCustomerTerminalsQuery = gql`
  query ($page: Int, $status: CustomerTerminalStatus, $size: Int) {
    getCustomerTerminals(page: $page, status: $status, size: $size) {
      nodes {
        _id
        userId
        terminalId
        status
        terminalName
        tid
        createdAt
        isActive
        terminalChargeBy
        spec {
          _id
          name
          price
        }
      }
      pageInfo {
        currentPage
        size
        totalCount
        hasNextPage
      }
    }
  }
`;

export const useGetCustomerTerminals = ({
  page = 1,
  size,
  status,
}: useGetCustomerTerminalsProps) => {
  return useQuery({
    query: getCustomerTerminalsQuery,
    variables: {
      page,
      size,
      status,
    },
  });
};
