import { VModalProps } from "components/common/types";
import ReactDOM from "react-dom";
import CloseIcon from "assets/icons/close-icon.svg";
import {
  VModalChildren,
  VModalOverlay,
  VModalSubTitle,
  VModalTitle,
  VModdalContainer,
} from "./styles";

export const VModal = ({
  open,
  close,
  title,
  children,
  isDismissable = true,
  subtitle,
  width = 1000,
  textAlign = "left",
  padding = 30,
  alignModalTitle = "left",
  alignModalSubTitle = "left",
  isDrawer = false,
  shouldDismissFromOverlay = false,
  modalTitleColor,
}: VModalProps) => {
  return ReactDOM.createPortal(
    <VModalOverlay
      open={open}
      onClick={shouldDismissFromOverlay ? close : () => {}}
    >
      <VModdalContainer
        padding={padding}
        width={width}
        open={open}
        isDrawer={isDrawer}
      >
        {isDismissable && (
          <div className="modal__close--icon" onClick={close}>
            <img src={CloseIcon} alt="modal-close-icon" />
          </div>
        )}
        <VModalChildren>
          {title && (
            <VModalTitle
              alignModalTitle={alignModalTitle}
              modalTitleColor={modalTitleColor}
            >
              {title}
            </VModalTitle>
          )}
          {subtitle && (
            <VModalSubTitle alignModalSubTitle={alignModalSubTitle}>
              {subtitle}
            </VModalSubTitle>
          )}
          {children}
        </VModalChildren>
      </VModdalContainer>
    </VModalOverlay>,
    document.getElementById("v-modal") as any
  );
};
