import styled, { css } from "styled-components/macro";
import { stylingUtils, FlexColumn } from "styles";

const {
  rem,
  color,
  mediaQuery: { down },
} = stylingUtils;

export const StyledChooseBusinessWrapper = styled("section")`
  ${FlexColumn};
  gap: ${rem(40)};
`;

export const StyledChooseBusinessHeader = styled("header")`
  text-align: center;
  ${FlexColumn};
  gap: ${rem(7)};

  h3 {
    font-size: ${rem(30)};
  }
`;

export const StyledChooseBusinessDesc = styled("section")`
  h5 {
    font-size: ${rem(20)};
    font-weight: 400;
    margin-bottom: ${rem(6)};
  }

  p {
    color: ${color("ash03")};
  }
`;

export const StyledChooseBusinessCardWrapper = styled("label")`
  ${FlexColumn};
  gap: ${rem(35)};
`;

const styledCheckedCard = css`
  background-color: ${color("lightBlue")};
  border: 1px solid transparent;
  color: white;
`;

export const StyledChooseBusinessCard = styled("label")<{ isChecked: boolean }>`
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: ${rem(15)} ${rem(20)};
  gap: ${rem(16)};
  border-radius: 10px;
  border: 1px solid ${color("ashInput")};
  ${({ isChecked }) => {
    if (isChecked) {
      return styledCheckedCard;
    }
  }}

  .card-title {
    flex: auto;

    h5 {
      font-size: ${rem(14)};
      font-weight: 500;
      margin-bottom: ${rem(3)};
    }
    p {
      font-size: ${rem(12)};
    }
  }

  input[type="radio"]:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: white;
    content: "";
    display: inline-block;
    visibility: visible;
    /* border: 2px solid white; */
    outline-offset: 4px;
    outline: 1px solid ${color("ashInput")};
  }

  input[type="radio"]:checked:after {
    background-color: white;
    content: "";
    display: inline-block;
    visibility: visible;
    outline: 1px solid white;
    outline-offset: 3px;
  }
`;

export const VNinRegistrationStyles = styled("div")`
  display: flex;
  justify-content: flex-start;
  gap: ${rem(10)};
  border-radius: ${rem(20)};
  background: #faf3eb;
  width: 200%;
  height: 100%;
  padding: ${rem(10)};
  font-size: ${rem(14)};

  ${down("tablet")} {
    width: 100%;
  }

  h4 {
    font-weight: 500;
    margin-bottom: ${rem(6)};
  }
  p {
    margin-bottom: ${rem(6)};

    &:nth-child(3) {
      margin-bottom: ${rem(10)};
    }
  }
`;
