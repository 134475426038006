import styled from "styled-components/macro";
import { stylingUtils } from "styles";

import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import { StyledStaffNameAvatar } from "components/staff/styles";

const { rem, color } = stylingUtils;

export const TopNavButton = styled.div`
  position: relative;
`;

export const ClearIcon = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  background-color: ${color("ash01")};
  padding: 1rem;
  height: ${rem(30, false)};
  width: ${rem(30, false)};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
`;

export const TopnavWrapper = styled.div`
  background-color: ${stylingUtils.color("white")};
  padding: 0 ${stylingUtils.rem(25)};
  position: relative;
  width: 100%;
  margin-top: 0px;
  z-index: 3;
  box-shadow: 0px 6px 17px rgba(0, 0, 0, 0.08);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${stylingUtils.rem(80)};
  gap: 3rem;
  @media (max-width: 900px) {
    gap: 1rem;
  }
`;

export const Topnavright = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-width: fit-content;
  gap: ${stylingUtils.rem(10)};

  @media (max-width: 900px) {
    min-width: auto;
    justify-content: center;
  }
`;
export const Topnavsearch = styled.div`
  width: 80%;
  @media (max-width: 900px) {
    display: none;
  }
`;

export const Topnavh1txt = styled.h1<{
  $navText?: string;
}>`
  font-family: Gilmer;
  font-style: normal;
  font-size: ${stylingUtils.rem(18)};
  line-height: ${stylingUtils.rem(30)};
  font-weight: 700;
  cursor: ${({ $navText }) =>
    $navText === "MarketPlace" ? "pointer" : "default"};
  white-space: nowrap;
`;

export const IconWrapper = styled.div`
  min-width: ${stylingUtils.rem(35)};
  min-height: ${stylingUtils.rem(35)};
  background: #ffffff;
  box-shadow: 0px 5px 16px -4px rgba(29, 28, 28, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;

  @media (max-width: 600px) {
    display: none;
  }
`;

export const MobileIconWrapper = styled.div`
  min-width: ${stylingUtils.rem(35)};
  min-height: ${stylingUtils.rem(35)};
  background: #ffffff;
  box-shadow: 0px 5px 16px -4px rgba(29, 28, 28, 0.1);
  display: none;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;

  @media (max-width: 762px) {
    display: flex;
  }
`;

export const ProfileWrapper = styled.div`
  background-color: ${stylingUtils.color("white")};
  border-radius: ${stylingUtils.rem(10)};
  box-shadow: 0px 0px 45px rgba(30, 66, 138, 0.1);
  display: flex;
  align-items: center;
  gap: ${stylingUtils.rem(14)};
  padding: ${stylingUtils.rem(6)} ${stylingUtils.rem(8)};
  width: fit-content;
  cursor: pointer;
`;

export const StyledAdminNameAvatar = styled(StyledStaffNameAvatar)`
  width: ${stylingUtils.rem(40)};
  height: ${stylingUtils.rem(40)};
  display: grid;
  place-content: center;
`;

export const TextColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: "Gilmer";

  @media (max-width: 600px) {
    display: none;
  }
`;

export const H3 = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: ${stylingUtils.rem(14)};
  line-height: ${stylingUtils.rem(19)};
  margin-bottom: ${stylingUtils.rem(5)};
`;

export const Span = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: ${stylingUtils.rem(10)};
  line-height: ${stylingUtils.rem(14)};
  color: ${stylingUtils.color("ash03")};
`;

export const Container = styled.div`
  width: 100%;
  position: relative;

  @media (max-width: 600px) {
    display: none;
  }
`;

export const SearchInput = styled.input`
  min-width: ${stylingUtils.rem(257)};
  width: 100%;
  height: ${stylingUtils.rem(35)};
  background: ${stylingUtils.color("ash04")};
  border: 1px solid ${stylingUtils.color("ash05")};
  border-radius: ${stylingUtils.rem(20)};
  font-family: "Gilmer";
  font-weight: 400;
  font-size: ${stylingUtils.rem(12)};
  line-height: ${stylingUtils.rem(17)};
  padding: 0 ${stylingUtils.rem(45)};
  outline: none;

  ::placeholder {
    color: ${stylingUtils.color("ash03")};
  }
`;

export const Icon = styled(SearchIcon)`
  position: absolute;
  top: 50%;
  width: 16px;
  transform: translateY(-50%);
  left: ${stylingUtils.rem(15)};
`;

export const Dropdown = styled("div")`
  height: 50px;
  width: 175px;
  position: absolute;
  top: 70px;
  background-color: ${stylingUtils.color("white")};
  border-radius: ${stylingUtils.rem(10)};
  box-shadow: 0px 0px 45px rgba(30, 66, 138, 0.1);
  display: flex;
  align-items: center;
  cursor: pointer;

  .item {
    display: flex;
    align-items: center;
    padding: ${stylingUtils.rem(5)};
    gap: ${stylingUtils.rem(10)};
    width: 100%;

    &:hover {
      background-color: #eaf2ff;
    }
  }
`;

export const StyledTopNavIcon = styled("button")`
  width: ${rem(35)};
  height: ${rem(35)};
  border-radius: 999999%;
  box-shadow: 0px 5px 16px -4px rgba(29, 28, 28, 0.1);
  background-color: white;
  display: grid;
  place-items: center;
`;
