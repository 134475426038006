import { gql, useMutation } from "urql";
import { TEditedProduct } from "components/Inventory/types";
import { EditProductRequest, OPERATION } from "api/inventory/types";
import { omit } from "lodash";
import { uploadMedia } from "utils";
import { showToast } from "components/common";
import { useToggle } from "hooks";

export const editCustomerProductMutation = gql`
  mutation (
    $productName: String!
    $sellingPrice: Int!
    $productId: String!
    $quantity: Int
    $content: String
    $productImage: String
    $purchasePrice: Int
    $operation: OPERATION
    $thresholdLowStock: Int
  ) {
    updateCustomerProduct(
      productId: $productId
      productName: $productName
      sellingPrice: $sellingPrice
      quantity: $quantity
      content: $content
      productImage: $productImage
      purchasePrice: $purchasePrice
      operation: $operation
      thresholdLowStock: $thresholdLowStock
    ) {
      _id
      productName
    }
  }
`;

export const useEditCustomerProduct = (refetchCustomerProduct?: Function) => {
  const [customerProduct, editCustomerProduct] = useMutation(
    editCustomerProductMutation
  );

  const [imageProcessing, toggleProcessing] = useToggle();

  // makes network request on submit
  const submitEditedProduct = async (
    formData: TEditedProduct,
    closeConfirm: () => void
  ) => {
    const request = await mapToEditProductParams(formData, toggleProcessing);

    // notify the customer if the request was successful or not
    if (request) {
      if (request === "Image Upload Failed") {
        showToast({
          type: "error",
          title: "Error uploading image file",
          subText: "Please try again",
        });
      }

      if (typeof request === "object") {
        editCustomerProduct(request).then((response) => {
          if (response.error) {
            showToast({
              type: "error",
              title: "Network Request Error",
              subText: "Please try again",
            });
          } else {
            showToast({
              type: "success",
              title: `Edited Product with name - ${formData?.productName}`,
              subText: "Successfully Edited  a product!",
            });

            refetchCustomerProduct?.();
            closeConfirm();
          }
        });
      }
    } else {
      showToast({
        type: "error",
        title: "All required fields must be filled",
        subText: "Please try again",
      });
    }
  };

  return {
    editCustomerProduct,
    customerProduct,
    submitEditedProduct,
    imageProcessing,
  };
};

// transforms form entries into query arguments
export const mapToEditProductParams = async (
  formData: TEditedProduct, // values from edit product form entries
  toggleProcessing: () => void // captures loading state for image file upload
): Promise<EditProductRequest | string> => {
  toggleProcessing();

  const dataWithoutImage = omit(formData, ["productImage"]);
  const rawServiceImage = formData?.productImage;
  let processedFileImage: null | string = null;

  // extracts image url from the image File object or return error message if unsuccessful
  if (rawServiceImage) {
    const uploadedFile = await uploadMedia(rawServiceImage as File);
    if (!uploadedFile) {
      toggleProcessing();
      return "Image Upload Failed";
    }
    processedFileImage = uploadedFile[0]?.url;
  }

  toggleProcessing();

  // compose query arguments for edit product api call
  const editedProduct = {
    ...omit(dataWithoutImage, [
      "newPrice",
      "newQuantity",
      "removeQuantity",
      "category",
      "dateTime",
      "costPrice",
      "productID",
      "referenceId",
      "changeQuantity",
    ]),
    ...{ productImage: processedFileImage as string },
    productId: formData?.productID as string,
    purchasePrice:
      Number(formData?.newPrice) ||
      Number(formData?.costPrice?.replaceAll(/₦|,/g, "")),
    sellingPrice: Number(formData.sellingPrice),
    quantity: Number(formData.newQuantity || formData.removeQuantity),
    content: formData?.changeQuantity,
    thresholdLowStock: Number(formData.thresholdLowStock),
    operation:
      formData?.changeQuantity === "add"
        ? OPERATION.INBOUND
        : OPERATION.OUTBOUND,
  };

  return editedProduct;
};
