import { PosCardStyles, Content, Top, Bottom } from "./styles";
import posImage from "assets/images/pngs/vedeex-checkout-pos-small.png";
import VeedezLogo from "assets/images/svgs/Veedez-logo-small";
import { IPosCard } from "../constants";
import { formatNumberToCurrency } from "utils";
import { useAuthUser } from "store";

const PosCard = ({
  _id,
  index,
  terminalName,
  tid,
  amount,
  active,
  action,
}: IPosCard) => {
  const { userData } = useAuthUser();

  const currentUser = userData?.firstName + " " + userData?.lastName;

  return (
    <PosCardStyles onClick={action} active={active}>
      <Content>
        <Top>
          <div className="left">
            <h5>{terminalName ? terminalName : `POS ${index}`}</h5>
            <h2>
              {formatNumberToCurrency({
                number: amount,
              })}
            </h2>
          </div>
          <div className="right">
            <h5>Terminal ID</h5>
            <p>{tid}</p>
          </div>
        </Top>
        <Bottom>
          <div className="left">
            <img src={posImage} alt="" />
            <VeedezLogo style={{ marginBottom: "30px" }} />
          </div>
          <div className="right">
            <h5>Assigned to</h5>
            <p>{currentUser}</p>
          </div>
        </Bottom>
      </Content>
    </PosCardStyles>
  );
};

export default PosCard;
