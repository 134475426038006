import { Link } from "react-router-dom";

// Custom Components
import { showToast, VBadge, VButton } from "components/common";

// Utils
import {
  CardProps,
  CustomTableProps,
  InputList,
  InputListType,
} from "components/common/types";

import {
  formatNumberToCurrency,
  formatUnderScore,
  getFormattedDate,
  numericInputFilter,
} from "utils";

import { APP_ROUTES } from "routes";

// Assets
import DownArrow from "assets/images/svgs/DownArrow";
import WalletIcon from "assets/images/svgs/WalletIcon";
import DocumentIcon from "assets/images/svgs/DocumentIcon";
import CopyIcon from "assets/images/svgs/CopyIcon";
// import Inflow from 'assets/images/svgs/inflow.svg';
// import Outflow from 'assets/images/svgs/outflow.svg';
import { ReactComponent as UssdIcon } from "assets/images/svgs/ussd.svg";
import { ReactComponent as CardIcon } from "assets/images/svgs/card-payment.svg";
import { ReactComponent as BankIcon } from "assets/images/svgs/bank-icon.svg";
import BalanceReveal from "assets/images/svgs/BalanceReveal";
import {
  DiscoType,
  DataPlanType,
  IAccountCardsData,
  ProviderType,
  ICablePackage,
} from "./types";
import AirtimeIcon from "assets/images/svgs/account-icons/airtime-icon.svg";
import BettingIcon from "assets/images/svgs/account-icons/betting-icon.svg";
import DataIcon from "assets/images/svgs/account-icons/data-icon.svg";
import ElectricityIcon from "assets/images/svgs/account-icons/electricity-icon.svg";
import InternetIcon from "assets/images/svgs/account-icons/internet-icon.svg";
import TvIcon from "assets/images/svgs/account-icons/tv-icon.svg";

// Styles
import {
  StyledCardAction,
  StyledCardActionAlt,
  StyledIconWrapper,
  SummaryCardsActionContainer,
  StyledTransactionType,
  StyledProviderContainer,
} from "./styles";
import { stylingUtils } from "styles";
import { IBeneficiery } from "api";
import { Category } from "components/Inventory";

const { rem } = stylingUtils;

export const KOBO_NAIRA_UNIT = 100;

export const ACCOUNT_CARDS_DATA = ({
  values,
  actions,
}: IAccountCardsData): CardProps[] => {
  const {
    accountNumber,
    accountName,
    balance,
    bank,
    // isKycCompleted,
    eyeOpen,
    kycStatus,
    kycLevel,
  } = values;

  const { toggleEye, toggleAddMoneyModal, routeToSendMoney } = actions;
  // Functionality
  const handleCopyLinkClicked = () => {
    navigator.clipboard.writeText(accountNumber);
    showToast({
      type: "success",
      title: "Copied",
      subText: "Account Number to Clipboard",
    });
  };

  const moneyToXs = () => {
    const formattedAmount = formatNumberToCurrency({
      number: balance,
      precision: 2,
    });
    return formattedAmount.replace(/[0-9]/g, "X");
  };

  return [
    {
      title: "Account Balance",
      icon: () => (
        <StyledIconWrapper color="#E0EEFE">
          <WalletIcon />
        </StyledIconWrapper>
      ),
      amount: kycLevel
        ? eyeOpen
          ? formatNumberToCurrency({
              number: balance,
              precision: 2,
            })
          : moneyToXs()
        : formatNumberToCurrency({
            number: Number(0),
            precision: 2,
          }),
      action: (
        <SummaryCardsActionContainer>
          <StyledCardAction disabled={!kycLevel} onClick={toggleAddMoneyModal}>
            <span>Add Money</span>
          </StyledCardAction>
          <StyledCardAction disabled={!kycLevel} onClick={routeToSendMoney}>
            <span>Send Money</span>
          </StyledCardAction>
        </SummaryCardsActionContainer>
      ),
      status: !!kycLevel,
      secondaryAction: (
        <StyledIconWrapper
          wrapperHeight={rem(25)}
          wrapperWidth={rem(40)}
          borderRadius="27px"
          svgHeight={rem(15)}
          svgWidth={rem(15)}
          role="button"
          tabIndex={0}
          onClick={toggleEye}
        >
          <BalanceReveal eyeClose={eyeOpen} style={{ cursor: "pointer" }} />
        </StyledIconWrapper>
      ),
    },
    {
      title: `Account Information`,
      icon: () => (
        <StyledIconWrapper color="#E0EEFE">
          <DocumentIcon style={{ stroke: "#0077FF" }} />
        </StyledIconWrapper>
      ),
      amount: kycLevel ? accountNumber : "",
      label: kycLevel
        ? ""
        : "Complete KYC to generate virtual account information",

      action: !kycLevel ? (
        <StyledCardActionAlt disabled={kycStatus === "pending"}>
          {kycStatus === "pending" ? (
            <p style={{ cursor: "not-allowed" }}>KYC validation pending</p>
          ) : (
            <>
              <Link to={APP_ROUTES.STORES} style={{ color: "#0077FF" }}>
                Go to Manage Business to complete KYC
              </Link>
              <DownArrow />
            </>
          )}
        </StyledCardActionAlt>
      ) : (
        <SummaryCardsActionContainer>
          <p>
            {accountName} <br />
            {formatUnderScore(bank)}
          </p>
        </SummaryCardsActionContainer>
      ),
      status: !!kycLevel,
      secondaryAction: (
        <StyledIconWrapper
          wrapperHeight={rem(25)}
          wrapperWidth={rem(40)}
          borderRadius="27px"
          svgHeight={rem(15)}
          svgWidth={rem(15)}
          role="button"
          tabIndex={0}
          onClick={handleCopyLinkClicked}
        >
          <CopyIcon style={{ cursor: "pointer" }} />
        </StyledIconWrapper>
      ),
    },
  ];
};

export const transactionIcons = {
  Airtime: AirtimeIcon,
  "Sports Betting": BettingIcon,
  "Data Purchase": DataIcon,
  Electricity: ElectricityIcon,
  "Internet Purchase": InternetIcon,
  Cable: TvIcon,
  "Bank Transfer": DataIcon,
};

export type TransactionCategory = keyof typeof transactionIcons;

export const TRANSACTION_COLUMNS = (
  handleTransactionModal: (data: any) => void
): CustomTableProps["columns"] => {
  return [
    {
      headerName: "Transaction Type",
      key: "category.name",
      formatter: (type: TransactionCategory) => {
        const icon = transactionIcons[type];
        return (
          <StyledTransactionType>
            <img src={icon} alt="Transaction Type" />
            <span>{type === "Cable" ? "TV Sub" : type}</span>
          </StyledTransactionType>
        );
      },
    },
    {
      headerName: "Date",
      key: "createdAt",
      formatter: (createdAt) => getFormattedDate(createdAt),
    },
    {
      headerName: "Reference",
      key: "reference",
      isBold: false,
      useZero: false,
    },
    {
      headerName: "Amount",
      key: "amount",
      formatter: (amount) => {
        const Amount = formatNumberToCurrency({
          number: amount ? amount / KOBO_NAIRA_UNIT : 0,
        });
        return Amount;
      },
    },
    {
      headerName: "Status",
      key: "status",
      useZero: false,
      renderCell: (value: string) => <VBadge status={value || ""} />,
    },

    {
      headerName: "Action",
      key: "id",
      useZero: false,
      renderCell: (params: any, data: any) => {
        return (
          <VButton
            text="View more"
            bgColor="transparent"
            textColor="#0077FF"
            style={{ padding: 0, boxShadow: "none" }}
            onClick={() => handleTransactionModal(data)}
          />
        );
      },
    },
  ];
};

export const paymentCards = [
  {
    name: "USSD Code",
    icon: <UssdIcon />,
    value: "USSD",
  },
  {
    name: "Card Payment",
    icon: <CardIcon />,
    value: "Card",
  },
  {
    name: "Bank Transfer",
    icon: <BankIcon />,
    value: "BANK",
  },
];

export const TRANSFER_INPUT_FIELDS = ({
  SELECT_BANKS_OPTIONS,
  bank,
  accountName,
  selectedBeneficiary,
}: {
  SELECT_BANKS_OPTIONS: [];
  bank?: string;
  accountName?: string;
  selectedBeneficiary: IBeneficiery | null;
}): InputListType[] => {
  // functionality

  return [
    {
      name: "bankName",
      label: "Select bank",
      inputType: "select",
      options: SELECT_BANKS_OPTIONS,
      disabled: !!selectedBeneficiary,
      required: true,
      validation: { required: "Please select bank" },
      placeholder: "Select Bank",
    },
    {
      name: "accountNumber",

      label: "Account number",
      type: "text",
      onKeyDown: (
        e:
          | React.KeyboardEvent<HTMLInputElement>
          | React.KeyboardEvent<HTMLTextAreaElement>
      ) => numericInputFilter(e),
      maxLength: 10,
      placeholder: "Enter account number [0-9]",
      disabled: !bank || !!selectedBeneficiary,
      required: true,
      className: "accountNumber",
      validation: { required: "Please enter digits only" },
    },
    {
      name: "accountName",
      className: "accountHolder",
      type: "text",
      readOnly: true,
    },
    {
      name: "amount",
      label: "Enter amount (₦)",
      placeholder: "Enter amount in digits ",
      type: "amount",
      required: true,
      disabled: !accountName,
      validation: { required: "Please enter amount" },
    },
  ];
};

export const VAS_LIST = [
  {
    icon: AirtimeIcon,
    title: "Buy Airtime",
    link: APP_ROUTES.BUY_AIRTIME,
    alt: "buy airtime icon",
  },
  {
    icon: DataIcon,
    title: "Buy Data",
    link: APP_ROUTES.BUY_DATA,
    alt: "buy data icon",
  },
  {
    icon: ElectricityIcon,
    title: "Electricity",
    link: APP_ROUTES.ELECTRICITY,
    alt: "electricity icon",
  },
  // {
  //   icon: InternetIcon,
  //   title: 'Internet Sub',
  //   link: APP_ROUTES.INTERNET_SUB,
  //   alt: 'internet sub icon',
  // },
  // {
  //   icon: BettingIcon,
  //   title: 'Betting',
  //   link: APP_ROUTES.BETTING,
  //   alt: 'betting icon',
  // },
  {
    icon: TvIcon,
    title: "TV sub",
    link: APP_ROUTES.TV_SUB,
    alt: "tv sub icon",
  },
];
export const BUY_AIRTIME_INPUT_FIELDS = ({
  SELECT_PROVIDER_OPTIONS,
}: {
  SELECT_PROVIDER_OPTIONS: ProviderType[];
}): InputListType[] => {
  // functionality

  return [
    {
      name: "network",
      label: "Select provider",
      inputType: "select",
      options: SELECT_PROVIDER_OPTIONS.map((provider) => ({
        label: (
          <StyledProviderContainer>
            <img src={provider.logo} alt={provider.name} />
            {provider.name}
          </StyledProviderContainer>
        ),
        value: provider.slug,
      })),
      required: true,
      validation: { required: "Please select provider" },
      placeholder: "Select Network",
    },
    {
      name: "amount",
      label: "Enter amount (₦)",
      placeholder: "Enter amount in digits ",
      type: "amount",
      required: true,
      validation: { required: "Please enter amount" },
    },
    {
      name: "phoneNumber",
      label: "Phone number",
      type: "tel",
      placeholder: "Enter phone number",
      required: true,
      maxLength: 11,
    },
  ];
};

const meterType: Category[] = [
  {
    key: "prepaid",
    label: "Prepaid",
    value: "prepaid",
  },
  {
    key: "postpaid",
    label: "Postpaid",
    value: "postpaid",
  },
];

export const ELECTRICITY_FORM_INPUT = (DISCOS: DiscoType[]): InputList =>
  [
    {
      name: "Provider",
      label: "Select Provider",
      inputType: "select",
      options: DISCOS.map((disco) => ({
        label: (
          <StyledProviderContainer>
            <img src={disco.logo} alt={disco.name} />
            {disco.name}
          </StyledProviderContainer>
        ),
        value: disco.slug,
      })),
      placeholder: "Choose provider",
      validation: {
        required: "Please select a provider",
      },
    },
    {
      name: "Meter type",
      label: "Select meter type",
      inputType: "select",
      options: meterType,
      placeholder: "Choose meter type",
      validation: {
        required: "Please select a meter type",
      },
    },
    {
      name: "Meter number",
      label: "Meter Number",
      type: "number",
      placeholder: "Enter meter number",
      validation: {
        required: "Please enter a meter number",
      },
    },
    {
      name: "customerName",
      className: "accountHolder",
      type: "text",
      readOnly: true,
    },
    {
      name: "Phone number",
      label: "Phone Number",
      type: "number",
      placeholder: "Enter phone number",
      validation: {
        required: "Please enter a phone number",
      },
    },
    {
      name: "Amount",
      label: "Amount",
      type: "number",
      placeholder: "Enter amount",
      validation: {
        required: "Please enter an amount",
      },
    },
  ] as const;

export const TV_SUB_INPUT_FIELDS = ({
  SELECT_PROVIDER_OPTIONS,
  SELECT_PACKAGE_OPTIONS,
  selectedProvider,
  selectedPlan,
}: {
  SELECT_PROVIDER_OPTIONS: ProviderType[];
  SELECT_PACKAGE_OPTIONS: ICablePackage[];
  selectedProvider?: string;
  selectedPlan?: string;
}): InputListType[] => {
  // functionality

  return [
    {
      name: "provider",
      label: "Select provider",
      inputType: "select",
      options: SELECT_PROVIDER_OPTIONS.map((provider) => ({
        key: provider.slug,
        label: (
          <StyledProviderContainer>
            <img src={provider.logo} alt={provider.name} />
            {provider.name}
          </StyledProviderContainer>
        ),
        value: provider.slug,
      })),
      required: true,
      validation: { required: "Please select a provider" },
      placeholder: "Choose provider",
    },
    {
      name: "plan",
      label: "Select plan",
      inputType: "select",
      options: SELECT_PACKAGE_OPTIONS.map((plan, index) => ({
        key: index,
        label: <StyledProviderContainer>{plan.name}</StyledProviderContainer>,
        value: plan.name,
      })),
      disabled: !selectedProvider,
      required: true,
      validation: { required: "Please select a plan" },
      placeholder: "Choose plan",
    },
    {
      name: "customerId",
      label: "Customer ID",
      placeholder: "Enter customer ID",
      disabled: !selectedPlan,
      required: true,
      validation: { required: "Please enter Customer Id" },
      onKeyDown: (
        e:
          | React.KeyboardEvent<HTMLInputElement>
          | React.KeyboardEvent<HTMLTextAreaElement>
      ) => numericInputFilter(e),
    },
    {
      name: "accountName",
      className: "accountHolder",
      type: "text",
      readOnly: true,
    },
  ];
};

export const BUY_DATA_INPUT_FIELDS = ({
  SELECT_PROVIDER_OPTIONS,
  DATA_PLANS,
  selectedNetwork,
}: {
  SELECT_PROVIDER_OPTIONS: ProviderType[];
  DATA_PLANS: DataPlanType[];
  selectedNetwork: string;
}): InputListType[] => {
  // functionality

  return [
    {
      name: "network",
      label: "Select provider",
      inputType: "select",
      options: SELECT_PROVIDER_OPTIONS?.map((provider) => ({
        label: (
          <StyledProviderContainer>
            <img src={provider.logo} alt={provider.name} />
            {provider.name}
          </StyledProviderContainer>
        ),
        value: provider.slug,
      })),
      required: true,
      validation: { required: "Please select provider" },
      placeholder: "Select Network",
    },
    {
      name: "dataPlan",
      label: "Select plan",
      inputType: "select",
      disabled: !selectedNetwork,
      options: DATA_PLANS?.map((plan) => ({
        label: (
          <StyledProviderContainer>
            <span>
              {formatNumberToCurrency({
                //  Value is in kobo
                number: plan.price / 100,
              })}{" "}
              | {plan.allowance}
            </span>
          </StyledProviderContainer>
        ),
        value: plan.id,
      })),
      required: true,
      validation: { required: "Please select a plan" },
      placeholder: "Select plan",
    },
    {
      name: "phoneNumber",
      label: "Phone number",
      type: "tel",
      placeholder: "Enter phone number",
      required: true,
      maxLength: 11,
    },
  ];
};
