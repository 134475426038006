const InfoIcon = (props: any) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_b_14860_139924)">
        <rect width="30" height="30" rx="15" fill="inherit" />
      </g>
      <path
        d="M15.1616 10.1656C15.9656 10.1656 16.54 9.47638 16.54 8.78719C16.54 8.098 15.9656 7.29395 15.1616 7.29395C14.3575 7.29395 13.7832 7.98313 13.7832 8.67232C13.7832 9.36151 14.3575 10.1656 15.1616 10.1656ZM15.1616 11.8885C14.4724 11.8885 14.0129 12.348 14.0129 13.0372V19.9291C14.0129 20.6183 14.4724 21.0777 15.1616 21.0777C15.8508 21.0777 16.3102 20.6183 16.3102 19.9291V13.0372C16.3102 12.348 15.8508 11.8885 15.1616 11.8885Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_b_14860_139924"
          x="-4"
          y="-4"
          width="38"
          height="38"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_14860_139924"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_14860_139924"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default InfoIcon;
