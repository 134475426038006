import { StyledSalesTable } from "components/expense-sales";
import styled from "styled-components";
import { stylingUtils } from "styles";

const {
  rem,
  color,
  mediaQuery: { down },
} = stylingUtils;
export const StyledStaffColumn = styled(StyledSalesTable)``;

export const StyledStaffNameWrapper = styled("div")`
  display: flex;
  gap: ${rem(20)};
  align-items: center;
`;
export const StyledStaffNameAvatar = styled("div")`
  width: max-content;
  background-color: ${color("transparentBlue2")};
  color: ${color("lightBlue")};
  padding: ${rem(5)} ${rem(5)};
  border-radius: ${rem(5)};
  font-weight: 400;
  ${down("laptop")} {
    display: none;
  }
`;
export const StyledStaffImgAvater = styled("img")`
  width: ${rem(20)};
  height: ${rem(20)};
  border-radius: ${rem(5)};
  object-fit: cover;

  ${down("laptop")} {
    display: none;
  }
`;

export const StyledInput = styled("input")`
  ${down("laptop")} {
    display: none;
  }
`;
