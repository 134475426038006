const MailIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 15 11"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 0H12.5C13.165 0 13.8 0.265 14.27 0.73C14.735 1.2 15 1.835 15 2.5V8.5C15 9.165 14.735 9.8 14.27 10.27C13.8 10.735 13.165 11 12.5 11H2.5C1.835 11 1.2 10.735 0.73 10.27C0.265 9.8 0 9.165 0 8.5V2.5C0 1.835 0.265 1.2 0.73 0.73C1.2 0.265 1.835 0 2.5 0ZM2.5 1H12.5C12.9 1 13.28 1.16 13.56 1.44C13.84 1.72 14 2.1 14 2.5V8.5C14 8.9 13.84 9.28 13.56 9.56C13.28 9.84 12.9 10 12.5 10H2.5C2.1 10 1.72 9.84 1.44 9.56C1.16 9.28 1 8.9 1 8.5V2.5C1 2.1 1.16 1.72 1.44 1.44C1.72 1.16 2.1 1 2.5 1Z"
        fill="#374B58"
      />
      <path
        d="M14.275 2.054C14.395 1.994 14.53 1.984 14.66 2.024C14.785 2.069 14.89 2.159 14.945 2.274C15.005 2.394 15.015 2.529 14.975 2.659C14.93 2.784 14.84 2.889 14.725 2.944L8.61998 5.999C8.61498 5.999 8.60998 6.004 8.60498 6.004C8.25998 6.164 7.87998 6.244 7.49998 6.239C7.11998 6.244 6.73998 6.164 6.39498 6.004C6.38998 6.004 6.38498 5.999 6.37998 5.999L0.274977 2.944C0.159977 2.889 0.0699766 2.784 0.0249766 2.659C-0.0150234 2.529 -0.00502342 2.394 0.0549766 2.274C0.109977 2.159 0.214977 2.069 0.339977 2.024C0.469977 1.984 0.604977 1.994 0.724977 2.054L6.82498 5.099C7.03498 5.199 7.26498 5.244 7.49998 5.239C7.73498 5.244 7.96498 5.199 8.17498 5.099L14.275 2.054ZM4.99998 7.999C5.13498 7.999 5.25998 8.054 5.35498 8.144C5.44498 8.239 5.49998 8.364 5.49998 8.499C5.49998 8.634 5.44498 8.759 5.35498 8.854C5.25998 8.944 5.13498 8.999 4.99998 8.999H2.49998C2.36498 8.999 2.23998 8.944 2.14498 8.854C2.05498 8.759 1.99998 8.634 1.99998 8.499C1.99998 8.364 2.05498 8.239 2.14498 8.144C2.23998 8.054 2.36498 7.999 2.49998 7.999H4.99998Z"
        fill="#374B58"
      />
    </svg>
  );
};

export default MailIcon;
