import { lazy } from "react";
export * from "./constants";
export * from "./styles";

export const AccountSummaryCards = lazy(
  () => import("./account-summary-cards")
);

export const AccountTranascationsTables = lazy(
  () => import("./account-transactions-table")
);

export const SendMoneyBankTransfer = lazy(
  () => import("./send-money-bank-transfer")
);

export const AccountVasList = lazy(() => import("./account-vas-list"));
export const BuyAirtimeForm = lazy(() => import("./buy-airtime-form"));
export const TvSubForm = lazy(() => import("./tv-sub-form"));
export const TransactionSummary = lazy(() => import("./transaction-summary"));
export const BuyDataForm = lazy(() => import("./buy-data-form"));
export const LoanAppCTABanners = lazy(
  () => import("../../veedez_bank/src/components/CTABanners")
);
export const TransactionPinModal = lazy(
  () => import("./transaction-pin-modal")
);
