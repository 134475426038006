import styled from "styled-components/macro";
import { stylingUtils } from "styles";

const {
  color,
  rem,
  mediaQuery: { down },
} = stylingUtils;

export const Cards = styled("div")`
  margin: ${rem(20)} 0;
  position: relative;
  margin-top: 2rem;

  .active {
    border: 2px solid ${color("lightBlue")};
  }
  .inactive {
    border: none;
  }
`;

export const SettingsContainer = styled("div")`
  justify-content: space-between;
  background-color: ${color("white")};
  padding: 2rem;
  padding-bottom: ${rem(40)};
  border-radius: ${rem(20)};

  h3 {
    font-family: "Gilmer";
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #001928;
  }
  hr {
    opacity: 0.7;
    border: 1px solid ${color("ash01")};
    margin: ${rem(18)} 0;
  }

  .settings_form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 ${rem(20)};
    transition: 0.5s all ease;
    ${down("tablet")} {
      grid-template-columns: 1fr;
    }
  }
`;

export const Disable = styled("div")`
  display: flex;
  justify-content: space-between;
`;

export const Charges = styled("div")`
  margin-bottom: ${rem(18)};
  .radio {
    display: flex;
    justify-content: space-between;
    gap: ${rem(16)};
    ${down("laptop")} {
      display: grid;
      grid-template-columns: 1fr;
    }
  }
`;
export const ChargeItem = styled("div")`
  background: #ffffff;
  border: 1px solid rgba(220, 227, 231, 0.5);
  border-radius: 10px;
  max-width: 515px;
  max-height: 95px;
  margin-top: ${rem(20)};
  padding: ${rem(15)};

  .header {
    display: flex;
    align-items: center;
    margin-bottom: ${rem(10)};
  }

  h4 {
    font-family: "Gilmer";
    font-style: normal;
    font-weight: 600;
    font-size: ${rem(14)};
    line-height: ${rem(17)};
    color: #374b58;
  }
  p {
    font-family: "Gilmer";
    font-style: normal;
    font-weight: 400;
    font-size: ${rem(10)};
    line-height: 135%;
    color: #979797;
  }
`;

export const SettingsForms = styled("div")`
  .accordion-header {
    padding-left: ${rem(0)};
    padding-right: ${rem(0)};
    border-bottom: none;

    h4 {
      font-family: "Gilmer";
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #001928;
    }
    button {
      background-color: transparent;

      svg {
        stroke: ${color("ashLabel")};
        fill: ${color("ashLabel")};
        width: 6px;
      }
    }
  }
  .accordion-children {
    background-color: #fff;
    padding-left: ${rem(3)};
    padding-right: ${rem(3)};
  }
`;
export const SaveButton = styled("div")`
  display: flex;
  justify-content: center;
  margin-top: 3rem;
`;
