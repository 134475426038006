import { gql, useMutation } from "urql";
import { handleRequestCall } from "utils/handleRequestCall";
import { IAddBusinessPayment } from "api/types";
import { showToast } from "components/common";

const addBusinessPaymentMutation = gql`
  mutation (
    $bankName: String
    $bankAccountName: String
    $bankAccountNumber: String
  ) {
    addBusinessPayment(
      bankName: $bankName
      bankAccountName: $bankAccountName
      bankAccountNumber: $bankAccountNumber
    ) {
      success
      message
      entityId
    }
  }
`;

export const useAddBusinessPayment = () => {
  const [businessPayment, addBusinessPayment] = useMutation(
    addBusinessPaymentMutation
  );

  const handleAddInvoicePayment = async (
    data: IAddBusinessPayment,
    callBack?: () => void
  ) => {
    try {
      const values = {
        ...data,
        expenseDate: new Date().toISOString(),
      };
      const req = await addBusinessPayment(values);

      await handleRequestCall({ request: req, callBack });
    } catch (e) {
      console.error(e);
      showToast({
        type: "error",
        title: "An error occurred",
        subText: "Please try again",
      });
    }
  };

  return {
    handleAddInvoicePayment,
    businessPayment,
  };
};
