import styled from "styled-components/macro";
import { stylingUtils } from "styles";
import { Link } from "react-router-dom";

const { rem, color } = stylingUtils;

export const Dash = styled.div<{
  isSideBarMinimzed?: boolean;
}>`
  display: flex;
  width: 100%;
  justify-content ${({ isSideBarMinimzed }) =>
    isSideBarMinimzed ? "center" : "flex-start"};
  align-items: center;
  padding: 5%;
  gap: 1rem;
  transition: all 0.5s ease-in-out;
`;
export const IconTextBox = styled.div`
  display: flex;
  align-items: center;
`;
export const H5 = styled.h5`
  margin: 0;
  font-family: Gilmer;
  font-weight: 700;
  color: ${color("grey01")};
  font-size: ${stylingUtils.rem(14)};
  line-height: ${stylingUtils.rem(19)};
`;
export const SidenavHtext = styled.div`
  font-family: Gilmer;
  font-weight: 600;
  font-size: ${stylingUtils.rem(14)};
  line-height: ${stylingUtils.rem(19)};
  color: ${stylingUtils.color("ashText")};
  margin-top: 0;
  margin-bottom: 0;
  white-space: nowrap;
`;
export const MenuLink = styled(Link)<{
  $isSideBarMinimzed: boolean;
}>`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: ${({ $isSideBarMinimzed }) =>
    $isSideBarMinimzed ? `center` : `flex-start`};
  padding: ${({ $isSideBarMinimzed }) =>
    $isSideBarMinimzed
      ? `${rem(7)} 0 ${rem(7)} ${rem(7)}`
      : `${rem(7)} 0 ${rem(7)} ${rem(16)}`};
  transition: all 0.5s ease-in-out;

  &:hover {
    background-color: #eaf2ff;

    .icon__div {
      background-color: #eaf2ff;
    }

    .subtitle__element {
      color: #0077ff;
    }

    .icon__element {
      svg {
        stroke: #0077ff !important;
      }
    }
  }
`;
export const IconDiv = styled.div<{ isActive: boolean }>`
  border-radius: 10px;
  height: ${rem(30)};
  width: ${rem(30)};
  display: grid;
  place-content: center;

  background-color: ${({ isActive }) => isActive && "#eaf2ff"};
`;
export const Subtitle = styled.p<{
  isActive: boolean;
  isSideBarMinimzed: boolean;
}>`
  display: ${({ isSideBarMinimzed }) => (isSideBarMinimzed ? "none" : "flex")};
  font-family: "Gilmer";
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  min-height: 30px;
  justify-content: flex-start;
  align-items: center;

  color: ${({ isActive }) => (isActive ? "#0077ff" : "#515f68")};
`;
