import { VButton } from "components/common";
import {
  StyledRolesAndActionBack,
  StyledRolesAndPermissionActionHeader,
} from "./styles";
import { ReactComponent as ChevronLeft } from "assets/images/svgs/chevron-left.svg";
import { ReactComponent as PlusIcon } from "assets/images/svgs/plus-icon.svg";
import { RolesAndPermissionHeaderProps } from "../types";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { APP_ROUTES } from "routes";
import { useAuthUser } from "store";

export const RolesAndPermissionActionHeader = ({
  backText,
  backAction,
  editRole,
}: RolesAndPermissionHeaderProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const isMutateRolesPage =
    location.pathname.includes("add") || location.pathname.includes("edit");

  const { userData } = useAuthUser();
  const isAdmin = !!userData && userData?.is_admin;

  const { roleId, role } = useParams();

  return (
    <>
      <StyledRolesAndPermissionActionHeader isAddPage={isMutateRolesPage}>
        {backText && (
          <StyledRolesAndActionBack onClick={() => backAction?.()}>
            <ChevronLeft height={15} />
            <h5>{backText}</h5>
          </StyledRolesAndActionBack>
        )}
        {!isMutateRolesPage && isAdmin && (
          <VButton
            text={`${editRole ? "Edit" : "Add"} Roles & Permission`}
            height="30px"
            icon={<PlusIcon />}
            isSlim
            style={{ marginLeft: "auto" }}
            onClick={() => {
              !editRole && navigate(APP_ROUTES.NEW_ROLES_AND_PERMISSIONS);
              editRole &&
                navigate(
                  `${APP_ROUTES.EDIT_ROLES_AND_PERMISSIONS}?roleId=${roleId}&roleName=${role}`
                );
            }}
          />
        )}
      </StyledRolesAndPermissionActionHeader>
    </>
  );
};
