import { VButton, VForm, VModal } from "components/common";
import { VModalProps } from "components/common/types";
import { EDIT_USER_INPUT_LIST } from "components/manage-business/constants";
import { useEditUserModalHook } from "hooks";

export const EditUserModal = ({ open, close }: VModalProps) => {
  const { isUpdatingUser, handleFormSubmit, hookForm } =
    useEditUserModalHook(close);

  return (
    <VModal open={open} close={close} title="Edit User">
      <form onSubmit={handleFormSubmit}>
        <VForm
          inputLists={EDIT_USER_INPUT_LIST}
          hookForm={hookForm}
          vFormClassName="grid__form"
        >
          <div className="form__button">
            <VButton
              isLoading={isUpdatingUser}
              text="Save Changes"
              mobWidth="100%"
            />
          </div>
        </VForm>
      </form>
    </VModal>
  );
};
