import styled from "styled-components/macro";
import { FlexJAMixin, stylingUtils } from "styles";

const {
  rem,
  mediaQuery: { down },
} = stylingUtils;

export const StyledVForm = styled("div")`
  .form__input {
    width: 100%;
  }

  .grid__form {
    display: grid;
    grid-template-columns: 2fr 2fr;
    gap: 0 ${rem(20)};

    ${down("tablet")} {
      display: block;
    }

    &.secondary {
      grid-template-columns: 2fr 1fr !important;
    }
  }

  .col__spanfull {
    grid-column: auto / span 2 !important;
  }

  .form__button {
    width: 100%;
    margin: ${rem(30)} 0;
    grid-column: auto / span 2 !important;
    ${FlexJAMixin};
    flex-direction: column;
    gap: ${rem(20)};
  }
`;
