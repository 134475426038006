import { IUserStore } from "components/manage-business/stores/stores-overview/types";
import { useForm } from "react-hook-form";
import { useEffect, useMemo } from "react";
import { STORE_DETAILS_INPUT_LIST } from "components/manage-business";
import { useAuthUserConstants } from "store";
import { useBillingPlanCardHook } from "hooks/billing";

export const useUserStoreHook = () => {
  const { business } = useAuthUserConstants();

  const billingPlanHook = useBillingPlanCardHook();

  const hookForm = useForm<IUserStore>();

  const { reset } = hookForm;

  const disabledInputListFied = useMemo(() => {
    return [
      ...STORE_DETAILS_INPUT_LIST.map((fields) => ({
        ...fields,
        readOnly: true,
        id: Math.random()?.toString(),
      })),
    ];
  }, []);

  useEffect(() => {
    if (business) {
      reset({
        ...business,
        businessSectorName: (business?.businessSector || [])[0]?.sectorName,
        businessPhoneNumber: business?.businessPhoneNumber?.replace(
          "+234",
          "0"
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    disabledInputListFied,
    hookForm,
    business,
    billingPlanHook,
  };
};
