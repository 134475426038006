import { useCallback } from "react";
import { useLocalSearchParams } from "hooks";
import {
  IBusinessCustomers,
  useGetBusinessCustomers,
  useGetDebtorsTopCards,
} from "api";
import { useMemo, useState } from "react";
import { extractFetchedData } from "utils";
import { useAuthUser } from "store";
import {
  CUSTOMERS_DEBTORS_CARDS_DATA,
  DEBTORS_TABLE_COLUMN,
} from "components/customers";

export const useCustomersDebtorPageHook = () => {
  const { searchParams, addSearchParams } = useLocalSearchParams();
  const { userData } = useAuthUser();

  const { data: topCardData } = useGetDebtorsTopCards();

  const debtorColumns = DEBTORS_TABLE_COLUMN();

  const [pageNumber, setPageNumber] = useState(
    parseInt(searchParams.get("page") || "1")
  );

  const selectedDebtorsOrder = searchParams.get("order") ?? "all";

  const [result, refetchBusinessDebtors] = useGetBusinessCustomers({
    businessId: userData?.businessId as string,
    page: pageNumber,
    ...(selectedDebtorsOrder &&
      selectedDebtorsOrder?.toLowerCase() !== "all" && {
        sortOrder: selectedDebtorsOrder?.toLowerCase() || "",
      }),
    debtorsOnly: true,
  });

  const extract = extractFetchedData<IBusinessCustomers>({
    result,
    key: "getBusinessCustomers",
  });

  const handleChangePage = (pageNumber: number) => {
    setPageNumber(pageNumber);
  };

  const handleOrderFilter = useCallback((status: string) => {
    addSearchParams("order", status);
    addSearchParams("page", "1");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cardDataRef = JSON.stringify(topCardData);

  const cardData = useMemo(() => {
    return CUSTOMERS_DEBTORS_CARDS_DATA(topCardData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardDataRef]);

  return {
    ...extract,
    handleChangePage,
    pageNumber,
    handleOrderFilter,
    debtorColumns,
    cardData,
    selectedDebtorsOrder,
    refetchBusinessDebtors,
  };
};
