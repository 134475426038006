import {
  CustomTableProps,
  InputListType,
  InputList,
} from "components/common/types";
import {
  capitalizeWord,
  formatNumberToCurrency,
  formatUnderScore,
  getFullFormattedDate,
} from "utils";
import { APP_ROUTES } from "routes";
import { allPermissionToStringObject } from "utils/roles_and_permission";
import { VChip } from "components/common";
import FileIcon from "assets/images/svgs/FileIcon";
import BasicPlanIcon from "assets/images/svgs/BasicPlanIcon";
import PremiumPlanIcon from "assets/images/svgs/PremiumPlanIcon";
import EnterprisePlanIcon from "assets/images/svgs/EnterprisePlanIcon";
import { DOCUMENT_VALIDATION } from "../../api/manage-business/stores/get-user-business";
import { BUSINESS_PLANS } from "components/auth/signup/constants";

export const MANAGE_BUSINESS_PAGE_TABS = [
  {
    key: "stores",
    label: "Stores",
    link: APP_ROUTES.STORES,
  },
  {
    key: "roles-and-permissions",
    label: "Roles & Permissions",
    link: APP_ROUTES.ROLES_AND_PERMISSIONS,
  },
  {
    key: "user-profile",
    label: "User Profile",
    link: APP_ROUTES.USER_PROFILE,
    secondaryLink: APP_ROUTES.BUSINESS,
  },
  {
    key: "billing",
    label: "Billing",
    link: APP_ROUTES.BILLING,
  },
];

const PERMISSIONS_CONTENT = {
  ADMIN: "Can manage all features and permissions",
  MANAGER:
    "Create user, create product,create expense, delete product and more",
  STAFF:
    "Create user, can create biller, can delete product, can create product and 5 more ",
};

export const ROLES_AND_PERMISSION_COLUMNS: CustomTableProps["columns"] = [
  {
    key: "roleName",
    headerName: "Role",
    formatter: (value: any) => capitalizeWord(value),
    renderCell: (value) => <p className="lightblue__text">{value}</p>,
  },
  {
    key: "",
    headerName: "Permissions",
    renderCell: (_, data) => {
      const value = data?.roleName;
      const text = (PERMISSIONS_CONTENT as Record<string, string>)[
        value?.toUpperCase()
      ];
      return <div>{text}</div>;
    },
  },
  {
    key: "usersAssociated",
    headerName: "No of Members",
  },
];

export const ROLES_AND_PERMSISION_MOCK_DATA = [
  {
    role: "admin",
    permissions: ["All access user"],
    members: 2,
  },
  {
    role: "staff",
    permissions: Object.values(allPermissionToStringObject).slice(0, 6),
    members: 0,
  },
];

export const USER_DETAILS_TAG = [
  {
    key: "email",
    label: "Email Address",
    value: "tosinfadina@alerzo.com",
  },
  {
    key: "phoneNumber",
    label: "Phone Number",
    value: "08075878891",
  },
  {
    key: "roles",
    label: "Roles",
    value: "Admin",
  },
  {
    key: "password",
    label: "Password",
    value: "**************",
  },
];

export const EDIT_USER_INPUT_LIST: InputListType[] = [
  {
    name: "profilePicture",
    label: "User Avatar",
    inputType: "upload",
    fileUploadType: "avatar",
    className: "col__spanfull",
  },
  {
    name: "fullName",
    label: "Full Name",
    disabled: true,
  },
  {
    name: "email",
    label: "Email",
    type: "email",
    disabled: true,
  },
  {
    name: "phoneNumber",
    label: "Phone Number",
    type: "number",
    required: true,
    validation: {
      required: "Enter your phone number",
      minLength: {
        value: 10,
        message: "Phone number must be 10 numbers without the first zero(0)",
      },
    },
  },
  {
    name: "password",
    label: "Password",
    disabled: true,
  },
];

export const BILLING_TABLE_COLUMNS = ({
  handleDownload,
}: {
  handleDownload?: (path: string) => void;
}): CustomTableProps["columns"] => {
  return [
    {
      headerName: "",
      key: "icon",
      renderCell: (id: any, data: any) => {
        return (
          <div className="icon__file">
            <FileIcon height={14} width={14} />
          </div>
        );
      },
    },
    {
      headerName: "ID",
      key: "subscriptionPaymentId",
      renderCell: (id: any, data: any) => {
        return (
          <div className="underline lightblue__text">
            <p>{id}</p>
          </div>
        );
      },
    },
    {
      headerName: "Billing Date",
      key: "createdAt",
      formatter: (createdAt: string) => getFullFormattedDate(createdAt),
    },
    {
      headerName: "Status",
      key: "status",
      renderCell: (id: any, data: any) => {
        return <VChip key="chip" label="Paid" isSlim type="success" />;
      },
    },
    {
      headerName: "Amount Paid",
      key: "subscriptionPaymentDetail.amountPaid",
      formatter: (value: number) => formatNumberToCurrency({ number: value }),
    },
    {
      headerName: "Cost of Subscription",
      key: "subscriptionPaymentDetail.costOfSubscription",
      formatter: (value: number) => formatNumberToCurrency({ number: value }),
    },
    {
      headerName: "Plan",
      key: "businessPlanDetail.name",
      formatter: (businessPlanName: string) =>
        formatUnderScore(businessPlanName ?? ""),
    },
    {
      headerName: "Remark",
      key: "remark",
    },
  ];
};

export const STORE_DETAILS_INPUT_LIST: InputListType[] = [
  {
    name: "businessName",
    label: "Store Name",
  },
  {
    name: "businessPhoneNumber",
    label: "Phone Number",
    type: "number",
  },
  {
    name: "businessEmail",
    label: "Business Email",
    type: "email",
  },
  {
    name: "businessAddress",
    label: "Address",
  },
  {
    name: "cityName",
    label: "City",
  },
  {
    name: "stateName",
    label: "State",
  },
  {
    name: "businessSectorName",
    label: "Business Sector",
  },
  {
    name: "businessEmblem",
    label: "Business Logo",
    inputType: "upload",
  },
];
export const ADD_STORE_INPUT_LIST: InputListType[] = [
  {
    name: "storeName",
    label: "Store Name",
    required: true,
    validation: { required: "Enter your store name" },
  },
  {
    name: "address",
    label: "Address",
    required: true,
    validation: { required: "Enter your address" },
  },

  {
    name: "city",
    label: "City",
    required: true,
    validation: { required: "Enter your city" },
  },
  {
    name: "state",
    label: "State",
    required: true,
    validation: { required: "Enter your state" },
  },
];

export const PRICING_PLAN_MOCK_DATA = [
  {
    id: BUSINESS_PLANS.BASIC,
    planIcon: BasicPlanIcon,
    planName: "Basic - Free",
    planBilling: "0",
    isFreePlan: true,
    summary:
      "Sell in person, online, over the phone, or out in the field. No setup fees or monthly fees — only pay when you take a payment.",
    buttonText: "Back to Basic",
    buttonLink: "/",
    basicFeatures: ["1 staff license", "1 store", "Inventory management"],
    moreFeatures: [],
    handleMakePayment: (values: Record<string, any>) => {},
    isLoading: false,
    hasLowerPlan: false,
    disabled: false,
  },
  {
    id: BUSINESS_PLANS.ESSENTIAL,
    planIcon: EnterprisePlanIcon,
    planName: "Essential",
    planBilling: "14.99",
    isPaidPlan: true,
    summary:
      "Do more, sell in person, online, over the phone, or out in the field.",
    buttonText: "Upgrade to premium",
    buttonLink: "/",
    basicFeatures: ["5 staff license", "1 store", "Limited customers"],
    moreFeatures: [],
    handleMakePayment: (values: Record<string, any>) => {},
    hasLowerPlan: false,
    disabled: false,
  },
  {
    id: BUSINESS_PLANS.PREMIUM,
    planIcon: PremiumPlanIcon,
    planName: "Premium",
    planBilling: "24.99",
    isPaidPlan: true,
    summary:
      "Get advanced features designed specifically for restaurants, retailers, or service-based businesses.",
    buttonText: "Upgrade to premium",
    buttonLink: "/",
    basicFeatures: ["10 staff license", "3 stores", "Unlimited customers"],
    moreFeatures: [],
    handleMakePayment: (values: Record<string, any>) => {},
    hasLowerPlan: false,
    disabled: false,
  },
  {
    id: BUSINESS_PLANS.ENTERPRISE,
    planIcon: EnterprisePlanIcon,
    planName: "Enterprise",
    planBilling: "Custom solution for large teams",
    isAmount: false,
    summary:
      "Talk to our team to get a tailored plan with custom pricing, additional support, and more.",
    buttonText: "Speak with the Veedez team",
    buttonLink: "/",
    basicFeatures: [
      "Custom staff license",
      "Custom stores",
      "Unlimited customers ",
    ],
    moreFeatures: [],
    handleMakePayment: (values: Record<string, any>) => {},
    hasLowerPlan: false,
    disabled: false,
  },
];

export enum KYC_FIELDS {
  BVN = "BVN",
  SELFIE = "SELFIE",
  TRANSACTION_PIN = "TRANSACTION_PIN",
  REGISTRATION_NUMBER = "REGISTRATION_NUMBER",
  UTILITY_BILL = "UTILITY_BILL",
  PASSCODE = "PASSCODE",
  NIN = "NIN",
  PROOF_OF_ID = "PROOF_OF_ID",
  PROOF_OF_ADDRESS = "PROOF_OF_ADDRESS",
  CAC_NUMBER = "CAC_NUMBER",
}

const successTextCheck = (text: string) => {
  let result = `${text} validation set`;
  switch (text) {
    case "Passcode":
      result = "Passcode is set";
      break;
    case "Transaction PIN":
      result = "PIN is set";
      break;
    default:
      result = `${text} validation set`;
      break;
  }
  return result;
};

const startStageTextCheck = (text: string) => {
  let result = `${text} validation not done`;
  switch (text) {
    case "Transaction PIN":
      result = "Transaction PIN not done";
      break;
    default:
      result = `${text} validation not done`;
      break;
  }
  return result;
};

const generateTexts = (value: string) => {
  return {
    [DOCUMENT_VALIDATION.START]: startStageTextCheck(value),
    [DOCUMENT_VALIDATION.SUCCESS]: successTextCheck(value),
    [DOCUMENT_VALIDATION.FAILED]: `Invalid ${value}`,
    [DOCUMENT_VALIDATION.PROCESS]: `Your ${value} validation is awaiting approval`,
  };
};

export const KYC_VALIDATIONS = {
  LEVEL_ONE: {
    title: "Level 1 KYC Validation - BVN & Selfie",
    fields: {
      [KYC_FIELDS.BVN]: {
        id: KYC_FIELDS.BVN,
        title: "BVN",
        texts: generateTexts("BVN"),
        showBtn: false,
      },
      [KYC_FIELDS.SELFIE]: {
        id: KYC_FIELDS.SELFIE,
        title: "Selfie",
        texts: generateTexts("Selfie"),
        showBtn: false,
      },
      [KYC_FIELDS.PASSCODE]: {
        id: KYC_FIELDS.PASSCODE,
        title: "Passcode",
        texts: generateTexts("Passcode"),
        buttonText: "Change Passcode",
        showBtn: true,
      },
      [KYC_FIELDS.TRANSACTION_PIN]: {
        id: KYC_FIELDS.TRANSACTION_PIN,
        title: "Transaction PIN",
        texts: generateTexts("Transaction PIN"),
        buttonText: "Change Transaction PIN",
        showBtn: true,
      },
    },
  },
  LEVEL_TWO: {
    title: "Level 2 KYC Validation - Proof of ID and Address",
    fields: {
      [KYC_FIELDS.PROOF_OF_ID]: {
        id: KYC_FIELDS.PROOF_OF_ID,
        title: "Proof of ID",
        texts: generateTexts("Proof of ID"),
        showBtn: false,
      },
      [KYC_FIELDS.PROOF_OF_ADDRESS]: {
        id: KYC_FIELDS.PROOF_OF_ADDRESS,
        title: "Proof of Address",
        texts: generateTexts("Proof of Address"),
        showBtn: false,
      },
    },
  },
  LEVEL_THREE: {
    title: "Level 3 KYC Validation - CAC Document",
    fields: {
      [KYC_FIELDS.REGISTRATION_NUMBER]: {
        id: KYC_FIELDS.REGISTRATION_NUMBER,
        title: "CAC Number",
        texts: generateTexts("CAC"),
        showBtn: false,
      },
    },
  },
};

export const COMPLETE_BUSINESS_PROFILE = ({
  businessSector,
}: {
  businessSector: InputListType["options"];
}): InputList => {
  return [
    {
      name: "businessSectorId",
      label: "Your Business Sector",
      inputType: "select",
      options: businessSector,
    },
  ] as const;
};
