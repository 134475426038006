import { useExpensePageHook } from "hooks";
import { gql, useMutation } from "urql";
import { handleRequestCall } from "utils/handleRequestCall";
import { useAuthUser } from "store";
import {
  ICustomerCategories,
  INVENTORY_TYPES,
  useGetCustomerCategories,
} from "api/inventory";
import { extractFetchedData } from "utils";
import { IBusinessExpenseNode } from "./get-business-expense";

const addBusinessExpenseMutation = gql`
  mutation ($paymentType: PAYMENTTYPE, $item: ExpenseItemInput) {
    addBusinessExpense(paymentType: $paymentType, item: $item) {
      _id
      title
      customerId
      title
      amount
      paymentType
      category {
        _id
        name
      }
      createdBy
      createdAt
    }
  }
`;

export type IAddBusinessExpense = {
  amount: string;
  item: string;
  categoryId: string;
  paymentType: string;
};

export const useAddBusinessExpense = () => {
  const [businessExpense, addBusinessExpense] = useMutation(
    addBusinessExpenseMutation
  );

  const [result] = useGetCustomerCategories({
    inventoryType: INVENTORY_TYPES.EXPENSE,
    size: -1,
  });

  const { extractedData: expenseCategories } =
    extractFetchedData<ICustomerCategories>({
      result,
      key: "getCategories",
    });

  const { userData } = useAuthUser();

  const { refechBusinessExpenses } = useExpensePageHook();

  const handleAddBusinessExpense = async (
    data: IBusinessExpenseNode,
    callBack?: () => void
  ) => {
    const { amount, item, categoryId, paymentType } = data;

    try {
      const values = {
        paymentType,
        item: {
          title: item,
          amount: parseFloat(amount as string),
          categoryId,
        },
        businessId: userData?.businessId as string,
      };
      const req = await addBusinessExpense(values);

      refechBusinessExpenses();
      await handleRequestCall({ request: req, callBack });
    } catch (e) {}
  };

  return {
    handleAddBusinessExpense,
    businessExpense,
    expenseCategories: expenseCategories?.nodes || [],
  };
};
