import { useSelectedPeriodHook } from "./useSelectedPeriodHook";
import { useLocalSearchParams } from "hooks";
import { IBusinessSales, useGetBusinessSales } from "api";
import { useState } from "react";
import { extractFetchedData } from "utils";
import { useAuthUser } from "store";

export const useSalesPageHook = () => {
  const { searchParams } = useLocalSearchParams();
  const selectedPeriod = searchParams.get("period");
  const { createdAt, handleFilter: handleSalesFilter } =
    useSelectedPeriodHook();

  const { userData } = useAuthUser();

  const [pageNumber, setPageNumber] = useState(
    parseInt(searchParams.get("page") || "1")
  );

  const [result] = useGetBusinessSales({
    businessId: userData?.businessId as string,
    page: pageNumber,
    ...(selectedPeriod && { createdAt }),
  });

  const extract = extractFetchedData<IBusinessSales>({
    result,
    key: "getBusinessSales",
  });

  const handleChangePage = (pageNumber: number) => {
    setPageNumber(pageNumber);
  };

  const SALES_FILTERS = [
    { value: "all", label: "All", key: "all" },
    { value: "daily", label: "Daily", key: "daily" },
    { value: "weekly", label: "Weekly", key: "weekly" },
    { value: "monthly", label: "Monthly", key: "monthly" },
    { value: "yearly", label: "Yearly", key: "yearly" },
  ];

  return {
    ...extract,
    handleChangePage,
    pageNumber,
    handleSalesFilter,
    SALES_FILTERS,
    selectedPeriod,
    createdAt,
  };
};
