import { css } from 'styled-components';
import stylingUtils from './styling.utils';

const {
  color,
  mediaQuery: { down },
  rem,
} = stylingUtils;

export const REACT_TOAST = css`
  :root {
    --toastify-font-family: Gilmer;
  }
`;

export const TOAST_OVERRIDE = css`
  /* overide toast */
  .Toastify__toast {
    min-height: 65px !important;
  }
  .Toastify__toast-container {
    width: 350px !important;

    ${down('mobileM')} {
      width: 100% !important;
    }
  }

  .Toastify__toast-icon {
    width: ${rem(52)} !important;
    height: ${rem(52)};
    margin-right: 15px;
  }
  .Toastify__toast-body {
    padding: 5px 0 !important;
  }

  .Toastify__toast {
    box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.04) !important;
    border-radius: 16px;
    padding-left: 25px;
  }

  .Toastify__close-button {
    top: ${rem(30)};
    margin-top: ${rem(5)};
    margin-right: ${rem(5)};
  }

  .Toastify__toast--success {
    background-color: ${color('toastBlue')};
  }
  .Toastify__toast--error {
    background-color: ${color('toastRed')};
  }
  /* delete toast */
  .Toastify__toast--warning {
    background-color: ${color('toastPink')};
  }

  .Toastify {
    z-index: 999999;
  }
`;
