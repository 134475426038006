const getActiveTab = (location: string | string[] | undefined) => {
  switch (true) {
    case location?.includes("dashboard"):
      return "Dashboard";
    case location?.includes("checkout"):
      return "Checkout";
    case location?.includes("expense&sales"):
      return "Sales & Expenses";
    case location?.includes("inventory"):
      return "Inventory";
    case location?.includes("account"):
      return "Account";
    case location?.includes("invoices"):
      return "Invoices";
    case location?.includes("pos"):
      return "POS Manager";
    case location?.includes("staff"):
      return "Staff";
    case location?.includes("customers"):
      return "Customers";
    case location?.includes("marketplace"):
      return "MarketPlace";
    case location?.includes("report"):
      return "Report";
    case location?.includes("business"):
      return "Manage Business";
    case location?.includes("notification"):
      return "Notification";
    case location?.includes("imported-files"):
      return "Imported Files";
    case location?.includes("referral") && location?.includes("information"):
      return "Referrals";
    case location?.includes("referral") && location?.includes("sales"):
      return "Referral Sales";
    default:
      return "Dashboard";
  }
};

export default getActiveTab;
