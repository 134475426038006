// Core
import { gql, useQuery } from "urql";

// Utils
import { BANK_PAYMENT_TYPES } from "./constants";

// GraphQL
const getPaymentBanksQuery = gql`
  query ($payment_type: BankPaymentType) {
    getPaymentBanks(payment_type: $payment_type) {
      bankName
      bankCode
      logo
    }
  }
`;
export interface ABanks {
  bankName: string;
  bankCode: string;
  logo: any;
}

// Functionality
export const useGetPaymentBanks = (payment_type: BANK_PAYMENT_TYPES) => {
  return useQuery({
    query: getPaymentBanksQuery,
    variables: { payment_type },
  });
};
