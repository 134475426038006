import { VButton, VForm } from "components/common";
import SignupWrapper from "../signup/signup-wrapper";
import { useResetPasscodeContext } from "./context";

export const EnterResetPassscodPassword = () => {
  const { steps, newPasswordInputLists, hookForm, isLoading, resetPasscode } =
    useResetPasscodeContext();

  return (
    <form onSubmit={resetPasscode}>
      <SignupWrapper
        header={"Reset Passcode"}
        subtitle={"Choose a new passcode"}
        footer={
          <VButton
            text={"Submit"}
            type="submit"
            isLoading={isLoading}
            style={{
              maxWidth: "400px",
              width: "100%",
            }}
            onClick={steps.nextStep}
          />
        }
      >
        <VForm
          vFormClassName="grid__form"
          inputLists={newPasswordInputLists}
          hookForm={hookForm}
        />
      </SignupWrapper>
    </form>
  );
};
