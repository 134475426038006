import { useToggle } from "hooks";
import {
  StyledVAccordion,
  StyledVAccordionBody,
  StyledVAccordionHeader,
} from "./styles";
import { ReactComponent as CheveronLeft } from "assets/images/svgs/chevron-left.svg";
import { VAccordionProps } from "../types";

export const VAccordion = ({
  title,
  children,
  childrenClassName,
  headerClassName,
  titleClassName,
  isDefaultOpen = true,
  backgroundColor = "white",
  buttonBg = "#F4F4F4",
}: VAccordionProps) => {
  const [isOpen, toggle] = useToggle(isDefaultOpen);
  return (
    <StyledVAccordion>
      <StyledVAccordionHeader
        onClick={toggle}
        className={headerClassName || ""}
        isOpen={isOpen}
        backgroundColor={backgroundColor}
        buttonBg={buttonBg}
      >
        <h4 className={titleClassName || ""}>{title}</h4>
        <button type="button">
          <CheveronLeft />
        </button>
      </StyledVAccordionHeader>
      <StyledVAccordionBody className={childrenClassName || ""} isOpen={isOpen}>
        {children}
      </StyledVAccordionBody>
    </StyledVAccordion>
  );
};
