import { createSlice } from "@reduxjs/toolkit";

type TNavbarSwitch = {
  open: boolean;
};

const initialState: TNavbarSwitch = {
  open: false,
};

const navbarSlice = createSlice({
  name: "navbar",
  initialState,
  reducers: {
    toggleNavbarModal: (state) => {
      state.open = !state.open;
    },
  },
});

const navbarReducer = navbarSlice.reducer;
const { toggleNavbarModal } = navbarSlice.actions;

export { navbarReducer, toggleNavbarModal };
