import { extractFetchedData } from "utils";
// Core
import { gql, useQuery } from "urql";
import { BeneficiaryTypes } from "./useAddBusinessBeneficiary";

// GraphQL
const getBusinessBeneficiariesQuery = gql`
  query getBeneficiaries($type: BeneficieryType) {
    getBusinessBeneficieries(
      metadata: { size: 20, page: 1 }
      filters: { type: $type }
    ) {
      nodes {
        _id
        bankName
        bankCode
        accountName
        accountNumber
        name
        code
        type
        detail
      }
      pageInfo {
        currentPage
        size
        totalCount
      }
    }
  }
`;
export interface IBusinessBenficiaries {
  nodes: IBeneficiery[];
}

export interface IBeneficiery {
  _id: string;
  bankName: string;
  bankCode: string;
  accountName: string;
  accountNumber: string;
  name: string;
  code: string;
  type: string;
  detail: { [key: string]: any };
}

// Functionality
export const useGetBusinessBeneficiaries = (
  type: BeneficiaryTypes | undefined
) => {
  const [result, refetchAll] = useQuery({
    query: getBusinessBeneficiariesQuery,
    variables: {
      type,
    },
  });

  const extract = extractFetchedData<IBusinessBenficiaries>({
    result,
    key: "getBusinessBeneficieries",
  });

  return {
    data: { ...extract },
    refetchAll,
  };
};
