import {
  IEditBusinessExpense,
  IBusinessExpenseNode,
  useEditBusinessExpense,
} from "api";
import { useForm } from "react-hook-form";
import { showToast, VButton, VForm, VModal } from "components/common";
import { EDIT_EXPENSE_FORM_INPUT_LIST } from "../constants";
import { StyledEditExpense, StyledExpenseForm } from "./styles";
import { VModalProps } from "components/common/types";
import { useEffect, useMemo } from "react";

const EditExpenseModal = ({
  open,
  close,
  expense,
}: VModalProps & { expense: IBusinessExpenseNode }) => {
  const hookForm = useForm<IEditBusinessExpense>();

  const { handleEditBusinessExpense, businessExpense, expenseCategories } =
    useEditBusinessExpense();

  const { handleSubmit, reset } = hookForm;
  const onSubmit = async (data: IEditBusinessExpense) => {
    await handleEditBusinessExpense(data, () => {
      reset();
      showToast({
        subText: "Your changes have been saved",
      });
      close();
    });
  };

  const { fetching } = businessExpense;

  const editExpenseInputLists = useMemo(() => {
    return EDIT_EXPENSE_FORM_INPUT_LIST({
      categories: expenseCategories,
      expense,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expenseCategories.length, expense]);

  useEffect(() => {
    if (expense) {
      reset({
        ...expense,
        categoryId: expense.categoryId,
        amount: expense.amount?.toString(),
        item: expense.title,
        paymentType: expense.paymentType,
        expenseId: expense._id,
      });
    }
  }, [expense, reset]);
  return (
    <VModal
      open={open}
      close={close}
      title="Edit Expense"
      subtitle="Expense Information"
    >
      <StyledEditExpense>
        <StyledExpenseForm>
          <form onSubmit={handleSubmit(onSubmit)}>
            <VForm
              inputLists={editExpenseInputLists}
              hookForm={hookForm}
              vFormClassName="expense__form"
            />
            <div className="form--button">
              <VButton
                isLoading={fetching}
                text="Save Changes"
                minWidth="295px"
                height="50px"
                mobWidth="100%"
              />
            </div>
          </form>
        </StyledExpenseForm>
      </StyledEditExpense>
    </VModal>
  );
};

export default EditExpenseModal;
