import {
  useGetCableProviders,
  useGetCablePackages,
  useResolveAccountName,
  useAddBusinessBeneficiary,
  usePurchaseCable,
  IPurchaseCable,
} from "api";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useHandleBeneficiaries, useToggle } from "hooks";
import { TV_SUB_INPUT_FIELDS } from "components/account";
import { ICablePackage } from "components/account/types";

export const useTvSubscriptionHook = () => {
  const hookForm = useForm<IPurchaseCable>({
    defaultValues: { saveBeneficiary: false },
  });

  const { watch, getValues, control, setValue, reset } = hookForm;
  const provider = watch("provider");
  const plan = watch("plan");
  const customerId = watch("customerId");

  const [cableProvider, setCableProvider] = useState<string>("");

  const { getCableProviders, cableProviders } = useGetCableProviders();
  const { getCablePackages, cablePackages } =
    useGetCablePackages(cableProvider);

  const { resolveAccountName, setSmartCardNumber, accountName } =
    useResolveAccountName(cableProvider);
  const { selectedBeneficiary, beneficiaries, handleSelectBeneficiary } =
    useHandleBeneficiaries({
      type: "TV_SUBSCRIPTION",
    });
  const { handleAddBeneficiary } = useAddBusinessBeneficiary();

  const { purchaseCable } = usePurchaseCable();

  const [openTransactionPinModal, toggleTransactionPinModal] = useToggle(false);

  const providerInputLists = TV_SUB_INPUT_FIELDS({
    SELECT_PROVIDER_OPTIONS: cableProviders,
    SELECT_PACKAGE_OPTIONS: cablePackages,
    selectedProvider: provider,
    selectedPlan: plan,
  });

  // getting price of plan
  const selectedPackagePrice = useMemo(() => {
    const selectedPackage = cablePackages?.find(
      (item: ICablePackage) => item.name === plan
    );
    return selectedPackage ? selectedPackage.packagePrice : null;
  }, [plan, cablePackages]);

  useEffect(() => {
    getCableProviders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getCablePackages();
    setCableProvider(provider);
  }, [getCablePackages, provider, setCableProvider]);

  useEffect(() => {
    if (customerId) {
      resolveAccountName();
    }
    setSmartCardNumber(customerId);
    setValue("accountName", accountName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resolveAccountName, setSmartCardNumber, customerId, accountName]);

  // Reset values if beneficiary is selected
  useEffect(() => {
    // Don't reset if no beneficiary has been selected
    if (selectedBeneficiary) {
      const { detail } = selectedBeneficiary;
      reset({
        provider: detail.Provider,
        plan: detail.plan,
        customerId: detail.customerId,
        accountName: detail.accountName,
      });
    }
  }, [reset, selectedBeneficiary]);

  const onSubmit = async (pin: string) => {
    const data: IPurchaseCable = getValues();

    await purchaseCable(data, pin);

    if (data.saveBeneficiary) {
      await handleAddBeneficiary({
        name: data.provider,
        code: data.plan,
        type: "TV_SUBSCRIPTION",
        detail: {
          Amount: selectedPackagePrice,
          Provider: cableProvider,
          plan: data.plan,
          customerId: data.customerId,
          accountName: accountName,
        },
      });
    }
  };

  const displayedPrice = selectedPackagePrice
    ? Number(selectedPackagePrice) / 100
    : "";

  const getProviderImage = (provider: string) => {
    return cableProviders.find((item) => item.slug === provider)?.logo;
  };

  return {
    hookForm,
    plan,
    customerId,
    cableProviders,
    selectedPackagePrice,
    providerInputLists,
    accountName,
    selectedBeneficiary,
    beneficiaries,
    handleSelectBeneficiary,
    openTransactionPinModal,
    toggleTransactionPinModal,
    control,
    cableProvider,
    onSubmit,
    getProviderImage,
    displayedPrice,
  };
};
