import {
  useGetTopSellingProducts,
  IFrequentCustomers,
  useGetDashboardSummary,
  useGetFrequentCustomers,
} from "api";
import {
  DASHBAORD_CARDS_DATA,
  DURATION_INPUT_LISTS,
  SECONDARY_DURATION_INPUT_LISTS,
} from "components/dashboard";
import { useMemo, useState } from "react";
import { useAuthUser } from "store";
import { extractFetchedData } from "utils";

export const useDashboardHook = () => {
  // Handle search params for pagination

  const [topSellingDateRange, setTopSellingDateRange] = useState(
    DURATION_INPUT_LISTS[0].value
  );
  const [frequentCustomersDateRange, setFrequentCustomersDateRange] = useState(
    SECONDARY_DURATION_INPUT_LISTS[0].value
  );
  const { userData } = useAuthUser();

  const businessId = userData?.businessId as string;

  const KYC = userData?.isKycCompleted;

  /* 
    DashboardSummary
  */
  // Fetch user businesses expenses
  const [result] = useGetDashboardSummary({
    bid: businessId,
    bIdRequired: businessId,
    bidID: businessId,
  });
  const { data } = result;

  const {
    getExpenseStat,
    getSaleStat,
    getBusinessInvoices,
    getBusinessCustomers,
  } = data || {};

  const newData = {
    totalExpenses: getExpenseStat?.totalExpense || 0,
    totalSales: getSaleStat?.totalSale || 0,
    totalCustomers: getBusinessCustomers?.pageInfo?.totalCount || 0,
    totalInvoices: getBusinessInvoices?.pageInfo?.totalCount || 0,
  };
  const cardDataRef = JSON.stringify(newData);

  const cardData = useMemo(() => {
    return DASHBAORD_CARDS_DATA(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardDataRef]);

  /* 
    TopSelling Products
  */
  const [topSellingProductResult] = useGetTopSellingProducts({
    businessId: userData?.businessId as string,
    dateRange: Number(topSellingDateRange),
  });

  const topSellingProduct = {
    extractedData:
      topSellingProductResult?.data?.getTopSellingProducts?.soldItemData ?? [],
    ...topSellingProductResult,
  };

  /* 
    Frequent customers
  */
  const [frequentCustomersResult] = useGetFrequentCustomers({
    businessId: userData?.businessId as string,
    transactionHistory: frequentCustomersDateRange as string,
  });

  const frequentCustomers = extractFetchedData<IFrequentCustomers[]>({
    key: "getHighestSpendingBusinessCustomers",
    result: frequentCustomersResult,
    defaultReturn: [],
  });

  return {
    cardData,
    topSellingProduct: {
      ...topSellingProduct,
      topSellingDateRange,
      setTopSellingDateRange,
    },
    frequentCustomers: {
      ...frequentCustomers,
      frequentCustomersDateRange,
      setFrequentCustomersDateRange,
    },
    KYC,
  };
};
