import { VEmptyStateProps } from "../types";
import {
  StyledVEmptyStateContainer,
  StyledVEmptyStateContent,
  StyledVEmptyStateTitle,
  StyledVEmptyStateActionText,
} from "./styles";
import RightArrow from "assets/images/svgs/RightArrow";

export const VEmptyState = ({
  title = "No Data",
  subText,
  actionText,
  action,
  children,
  style,
}: VEmptyStateProps) => {
  return (
    <StyledVEmptyStateContainer style={style}>
      <StyledVEmptyStateTitle>{title}</StyledVEmptyStateTitle>
      {subText && (
        <StyledVEmptyStateContent>{subText}</StyledVEmptyStateContent>
      )}
      {actionText && (
        <StyledVEmptyStateActionText onClick={() => action && action?.()}>
          <p>{actionText}</p>
          <RightArrow
            style={{ fill: "#0077FF", height: "1rem", width: "1rem" }}
          />
        </StyledVEmptyStateActionText>
      )}
      {children}
    </StyledVEmptyStateContainer>
  );
};
