import CameraIcon from "assets/images/svgs/CameraIcon";
import CloseIcon from "assets/images/svgs/CloseIcon";
import { FileUploadImageSvg } from "assets/images/svgs/FileUploadImage";
import { ImagePlaceholderSvg } from "assets/images/svgs/ImagePlaceholder";
import { ChangeEvent, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { transformNonEventChange, truncateWord } from "utils";
import { VFileSelectedProps, VFILE_TYPE, VInputProps } from "../types";
import { VInput } from "../VInput";
import { StyledVFormError } from "../VInput/styles";
import { VTooltip } from "../VTooltip";
import {
  StyledFilePreviewName,
  StyledFileUploadContainer,
  StyledVFileAvatarCameraIcon,
  StyledVFileAvatarContainer,
  StyledVFileAvatarUpload,
  StyledVFileCloseButton,
  StyledVFileField,
  StyledVFileImageSelected,
  StyledVFileNameOrPlaceholder,
  StyledVFilePreview,
  StyledVFilePreviewImage,
} from "./styles";

export const VFileUpload = ({
  fullWidth = true,
  fileUploadType = "file-upload",
  disabled = false,
  error,
  readOnly,
  placeholder,
  ...props
}: VInputProps) => {
  const [fileSelected, setFileSelected] = useState<VFileSelectedProps | null>(
    null
  );

  const isAvatarFileUpload = fileUploadType === "avatar";
  const watch = props?.control?._getWatch(props.name);

  useEffect(() => {
    // Handles Reset file
    if (typeof watch === "string") {
      setFileSelected({
        fileType: "image",
        fileUrl: watch,
        name: "profile-picture",
      });
    }
    if (!watch) {
      setFileSelected(null);
    }
  }, [watch]);

  const { control, name, validation, onChange, label, accept } = props;

  const handleImagePick = (
    e: ChangeEvent<HTMLInputElement>,
    handleHookForm: any
  ) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    if (file) {
      const type = file.type.split("/")[0] as VFILE_TYPE;
      setFileSelected({
        fileType: type,
        fileUrl: URL.createObjectURL(file),
        file,
        name: file?.name,
      });
      handleHookForm?.(
        transformNonEventChange({ name: props.name, value: file })
      );
    }
  };

  const isDisabled = !!disabled;

  const fileNameOrPlaceholder = fileSelected
    ? "Change File"
    : placeholder ?? "Click to upload an image";

  const FileUpload = ({ onChange }: { onChange?: any }) => (
    <>
      <StyledFileUploadContainer fullWidth={fullWidth}>
        <VInput
          label={isAvatarFileUpload ? "" : label}
          name={name}
          type="file"
          disabled={isDisabled}
          accept={isAvatarFileUpload ? ".png, .jpg, .jpeg" : accept}
          hidden
          onClick={(e) => {
            if (readOnly) return e.preventDefault();
          }}
          onChange={(e) => {
            handleImagePick(e as ChangeEvent<HTMLInputElement>, onChange);
          }}
        />

        {isAvatarFileUpload ? (
          <StyledVFileAvatarContainer>
            <StyledVFileAvatarUpload htmlFor={props.name}>
              {fileSelected ? (
                <StyledVFileImageSelected
                  src={fileSelected?.fileUrl}
                  alt="uploaded"
                />
              ) : (
                <FileUploadImageSvg width={24} height={24} />
              )}
            </StyledVFileAvatarUpload>
            {isAvatarFileUpload && (
              <StyledVFileAvatarCameraIcon htmlFor={props.name}>
                {!fileSelected ? (
                  <CameraIcon width={20} />
                ) : (
                  <button
                    onClick={() => {
                      onChange(
                        transformNonEventChange({
                          name: props.name,
                          value: null,
                        })
                      );
                      setFileSelected(null);
                    }}
                  >
                    <CloseIcon />
                  </button>
                )}
              </StyledVFileAvatarCameraIcon>
            )}
          </StyledVFileAvatarContainer>
        ) : (
          <StyledVFileField htmlFor={props.name} disabled={isDisabled}>
            <FileUploadImageSvg width={24} height={24} />
            <StyledVFileNameOrPlaceholder>
              {fileNameOrPlaceholder}
            </StyledVFileNameOrPlaceholder>
          </StyledVFileField>
        )}
        {error?.message && (
          <StyledVFormError>{error?.message} </StyledVFormError>
        )}

        {fileSelected && !isAvatarFileUpload && (
          <StyledVFileCloseButton
            onClick={() => {
              onChange(
                transformNonEventChange({ name: props.name, value: null })
              );
              setFileSelected(null);
            }}
          >
            <CloseIcon />
          </StyledVFileCloseButton>
        )}
      </StyledFileUploadContainer>
      {fileSelected && !isAvatarFileUpload && <FilePreview {...fileSelected} />}
    </>
  );

  return (
    <>
      {props?.control ? (
        <Controller
          name={name}
          rules={validation}
          control={control}
          render={({ field }) => {
            return <FileUpload onChange={field?.onChange} />;
          }}
        />
      ) : (
        <FileUpload onChange={onChange} />
      )}
    </>
  );
};

export const FilePreview = ({
  fileType,
  fileUrl,
  name,
}: VFileSelectedProps) => {
  return (
    <StyledVFilePreview>
      {fileType === "image" ? (
        <StyledVFilePreviewImage src={fileUrl} alt="uploaded" />
      ) : (
        <ImagePlaceholderSvg width={120} height={120} />
      )}
      <StyledFilePreviewName>
        <VTooltip
          children={truncateWord(name, { length: 30 })}
          content={name}
        />
      </StyledFilePreviewName>
    </StyledVFilePreview>
  );
};
