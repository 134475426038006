import { VModal } from "components/common/VModal";
import { VModalProps } from "components/common/types";
import { VButton, VFileUpload } from "components/common";
import { VCheckBoxSecondary } from "components/common/VCheckbox/checkbox-secondary";
import {
  StyledIFAPModal,
  StyledImportProductSuccessfulWrapper,
  VStyledNoticeInfo,
} from "./styles";
import InfoIcon from "assets/images/svgs/InfoIcon";
import { ColorCheckMark } from "assets/images/svgs/ColorCheckMark";
import {
  DUPLICATE_HANDLING_LIST,
  useImportProductModalHook,
} from "./hooks/useImportProductModalHook";
import { CUSTOM_VALIDATIONS } from "utils";
import { Link } from "react-router-dom";
import { APP_ROUTES } from "routes";

const ImportProductModal = ({
  title,
  open,
  close,
  openCategoryModal,
}: VModalProps & { openCategoryModal: VoidFunction }) => {
  const {
    importInventoryTemplate,
    hookForm,
    handleAddFile,
    checked,
    setChecked,
    isLoading,
    openSuccessModal,
    toggleSuccessModal,
  } = useImportProductModalHook({ close });

  const {
    control,
    formState: { errors },
  } = hookForm;

  const addCategory = () => {
    close();
    openCategoryModal();
  };

  return (
    <>
      <VModal title={title} open={open} close={close}>
        <StyledIFAPModal>
          <VStyledNoticeInfo>
            <InfoIcon
              style={{ fill: "#F5A346", width: "45px", height: "25px" }}
            />
            <p>
              Make sure to add your categories{" "}
              <span onClick={addCategory} className="text-link">
                here
              </span>{" "}
              first before importing file
            </p>
          </VStyledNoticeInfo>
          <section className="subtitle">
            <h5>
              Download a{" "}
              <a
                href={importInventoryTemplate}
                target="_blank"
                rel="noreferrer"
                className="text-link"
              >
                sample
              </a>{" "}
              file and compare it to your import file to ensure you have the
              file perfect for the import.
            </h5>
          </section>
          <div>
            <form className="form" onSubmit={handleAddFile}>
              <div className="file-upload">
                <VFileUpload
                  name="file"
                  label="Upload File"
                  accept=".csv"
                  placeholder="Choose a file"
                  control={control}
                  validation={{
                    required: true,
                    validate: {
                      lessThan5MB: (file: any) =>
                        CUSTOM_VALIDATIONS.maxFileSize(file, 5),
                      acceptedFormats: (file: any) =>
                        CUSTOM_VALIDATIONS.acceptedFormats({
                          file,
                          fileTypes: ["csv"],
                        }),
                    },
                  }}
                />
                {errors["file"] && (
                  <p className="maximum-size">
                    Maximum file size: 5 MB | File format: CSV
                  </p>
                )}
              </div>
              <div className="duplicate-handling">
                <h5>Duplicate Handling </h5>
                <div className="checkboxes">
                  {DUPLICATE_HANDLING_LIST.map(
                    ({ id, label, content, disbled = false }) => (
                      <VCheckBoxSecondary
                        fontSize="10px"
                        key={id}
                        id={id}
                        value={id}
                        disabled={disbled}
                        checked={id === checked}
                        onClick={(e: any) => {
                          setChecked(e.target.value);
                        }}
                        label={
                          <>
                            <p className="checkbox-label">{label}</p>
                            <p>{content}</p>
                          </>
                        }
                      />
                    )
                  )}
                </div>
              </div>
            </form>
            <div className="btn-submit">
              <VButton
                text="Submit"
                minWidth="250px"
                mobWidth="120px"
                onClick={handleAddFile}
                isLoading={isLoading}
              />
            </div>
          </div>
        </StyledIFAPModal>
      </VModal>

      <VModal open={openSuccessModal} close={toggleSuccessModal} width={500}>
        <StyledImportProductSuccessfulWrapper>
          <div className=" modal-icon-check">
            <ColorCheckMark />
          </div>
          <div className="content">
            <h3 className="modal-title">File Submitted!</h3>
            <p className="modal-text">Please check import page for progress.</p>
          </div>
          <Link to={APP_ROUTES.IMPORTED_FILES} className="modal-button">
            <VButton text="Proceed to Import Page" />
          </Link>
        </StyledImportProductSuccessfulWrapper>
      </VModal>
    </>
  );
};

export default ImportProductModal;
