import styled from "styled-components/macro";
import { FlexJAMixin, stylingUtils } from "styles";

const {
  rem,
  mediaQuery: { down },
} = stylingUtils;

export const StyledExpenseForm = styled("div")`
  .expense__form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 ${rem(20)};
    ${down("tablet")} {
      grid-template-columns: 1fr;
    }
  }
`;

export const StyledDeleteExpense = styled("div")`
  width: 100%;
  > h1 {
    font-family: "Gilmer";
    font-style: normal;
    font-weight: 700;
    font-size: ${stylingUtils.rem(16)};
    line-height: ${stylingUtils.rem(19)};
    margin-top: ${stylingUtils.rem(10)};
    margin-bottom: ${stylingUtils.rem(16)};
    text-align: center;

    color: #001833;
  }

  > p {
    font-family: "Gilmer";
    font-style: normal;
    font-weight: 400;
    font-size: ${stylingUtils.rem(14)};
    line-height: ${stylingUtils.rem(22)};
    /* identical to box height, or 157% */

    text-align: center;

    /* Alerzo grey #1 - text input */

    color: #374b58;
  }

  .form--button {
    gap: ${rem(15)};
    margin: ${rem(30)} 0;
    width: 100%;
    flex-wrap: wrap;
    flex: 1;
    ${FlexJAMixin}
  }
`;
