import { createSlice } from "@reduxjs/toolkit";
import { IBusinessExpenseNode } from "api";

type TViewExpense = {
  open: boolean;
  createdExpense: IBusinessExpenseNode;
};

const initialState: TViewExpense = {
  open: false,
  createdExpense: {
    createdBy: "",
    item: "",
    title: "",
    amount: "",
  },
};

const viewExpenseSlice = createSlice({
  name: "expense",
  initialState,
  reducers: {
    toggleViewExpenseModal: (state) => {
      state.open = !state.open;
    },
    setCreatedExpense: (state, value) => {
      state.createdExpense = value.payload;
    },
  },
});

const viewExpenseReducer = viewExpenseSlice.reducer;
const { toggleViewExpenseModal, setCreatedExpense } = viewExpenseSlice.actions;

export { viewExpenseReducer, toggleViewExpenseModal, setCreatedExpense };
