import { lazy } from "react";

export const BillingOverviewPage = lazy(
  () => import("./billing-overview-page")
);

export const ChangeBillingPlanPage = lazy(
  () => import("./change-billing-plan-page")
);

export const BillingCheckoutPage = lazy(
  () => import("./billing-checkout-page")
);
