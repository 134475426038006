import { REPORT_FILTER_DATE_ENUM } from "components/reports/constants";

const allMonths = {
  "1": { name: "Jan", identifier: "1" },
  "2": { name: "Feb", identifier: "2" },
  "3": { name: "Mar", identifier: "3" },
  "4": { name: "Apr", identifier: "4" },
  "5": { name: "May", identifier: "5" },
  "6": { name: "Jun", identifier: "6" },
  "7": { name: "Jul", identifier: "7" },
  "8": { name: "Aug", identifier: "8" },
  "9": { name: "Sept", identifier: "9" },
  "10": { name: "Oct", identifier: "10" },
  "11": { name: "Nov", identifier: "11" },
  "12": {
    name: "Dec",
    identifier: "12",
  },
};

export type DateMapperProps = {
  [key: string]: {
    name: string;
    identifier: string;
  };
};

export type FinalDateMapperProps = {
  [key: string]: {
    identifier: string;
    name: string;
    [key: string]: any;
  };
};

const userLocale =
  navigator.languages && navigator.languages.length
    ? navigator.languages[0]
    : navigator.language;

export const getDayName = (dateStr: string) => {
  var date = new Date(dateStr);
  return date.toLocaleDateString(userLocale, { weekday: "long" });
};

export const monthMapper = ({
  length = 12,
  start = 1,
}: {
  length: number;
  start: number;
}): DateMapperProps => {
  const months: DateMapperProps = {};

  for (let i = start; i <= length; i++) {
    months[i?.toString()] = allMonths[i?.toString() as keyof typeof allMonths];
  }

  return months;
};

export const dayMapper = ({
  length = 31,
  start = 1,
}: {
  length: number;
  start: number;
}) => {
  const days: DateMapperProps = {};
  for (var i = start; i <= length; i++) {
    const currentDate = new Date();
    const spanDate = new Date(
      currentDate?.getFullYear(),
      currentDate?.getMonth(),
      i + 1
    )
      .toISOString()
      .slice(0, 10);
    const dayName = getDayName(spanDate);
    days[i?.toString()] = { name: dayName, identifier: i?.toString() };
  }

  return days;
};
export const hourMapper = ({
  length = 24,
  start = 1,
}: {
  length: number;
  start: number;
}) => {
  const hours: DateMapperProps = {};
  for (var i = start; i <= length; i++) {
    const newHour = i % 12;
    const timeToRender = newHour ? newHour : 12;
    const hourName =
      i >= 12 && i < 24 ? `${timeToRender}PM` : `${timeToRender}AM`;
    hours[i?.toString()] = { name: hourName, identifier: i?.toString() };
  }

  return hours;
};

export const getMappedDate = (
  type: REPORT_FILTER_DATE_ENUM,
  {
    hourStart = 1,
    hourEnd = 24,
    dayStart = 1,
    dayEnd = 31,
    monthStart = 1,
    monthEnd = 12,
  }: {
    hourStart?: number;
    dayStart?: number;
    hourEnd?: number;
    dayEnd?: number;
    monthStart?: number;
    monthEnd?: number;
  } = {}
) => {
  let generateMapper: DateMapperProps;

  if (type === REPORT_FILTER_DATE_ENUM.TODAY) {
    generateMapper = hourMapper({ length: hourEnd, start: hourStart });
  } else if (type === REPORT_FILTER_DATE_ENUM.WEEKLY) {
    const start = dayStart <= 0 ? 1 : dayStart;

    generateMapper = dayMapper({ length: dayEnd, start });
  } else {
    generateMapper = monthMapper({ length: monthEnd, start: monthStart });
  }

  return Object.keys(generateMapper).reduce((acc, curr: string) => {
    const selectedValue = generateMapper[curr];
    acc[curr] = selectedValue;
    return acc;
  }, {} as FinalDateMapperProps);
};
