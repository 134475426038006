import styled from "styled-components";
import { FlexJAMixin, FlexColumn } from "styles";

export const StyledSignInPage = styled("div")`
  ${FlexJAMixin};
  ${FlexColumn};
  width: 100%;
  height: 100vh;
  background-position: center;
  background-size: cover;
  gap: 1rem;
`;
