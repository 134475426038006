import { lazy } from "react";

export const RolesAndPermissionsOverview = lazy(
  () => import("./roles-and-permission-overview")
);

export const RolesAndPermissionSelection = lazy(
  () => import("./roles-and-permission-selection")
);

export const AddRolesAndPermissions = lazy(
  () => import("./create-roles-and-permissions")
);

export const EditRolesAndPermissions = lazy(
  () => import("./edit-roles-and-permissions")
);
