import { gql, useMutation } from "urql";

const updateUserNotification = gql`
  mutation ($notificationId: String, $isSeen: Boolean) {
    updateUserNotification(isSeen: $isSeen, notificationId: $notificationId) {
      _id
      title
      body
      isSeen
      status
      extraData
      createdAt
    }
  }
`;

export const useUpdateUserNotification = () => {
  const [, updateNotification] = useMutation(updateUserNotification);

  return {
    updateNotification,
  };
};
