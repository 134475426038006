import { ProviderType } from "./../../components/account/types";
import { useVeedezApiRequest } from "./../../hooks/useApiRequest";

// Custom Components
import { showToast } from "components/common";

// Utils
import { VALUE_ADDED_SERVICES_ROUTES } from "api/api.routes";
import { useState } from "react";

// Functionality
export const useGetCableProviders = () => {
  const makeRequest = useVeedezApiRequest();
  const [cableProviders, setCableProviders] = useState<ProviderType[]>([]);

  const getCableProviders = async () => {
    try {
      const { data } = await makeRequest.get(
        VALUE_ADDED_SERVICES_ROUTES.GET_CABLE_PROVIDERS
      );
      setCableProviders(data.data);
    } catch (e) {
      showToast({
        type: "error",
        title: "An error occurred",
        subText: "Please try again",
      });
    }
  };

  return {
    getCableProviders,
    cableProviders,
  };
};
