import styled from "styled-components/macro";
import { stylingUtils, FlexColumn } from "styles";

const {
  rem,
  color,
  mediaQuery: { up, down },
} = stylingUtils;

export const StyleySendMoneyContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
  font-family: "Gilmer";
  position: relative;

  ${down("mobileL")} {
    flex-direction: column;
  }
`;

export const StyledSendMoneyLeftContainer = styled("div")`
  flex: 3;
`;

export const BankDetailsContainer = styled("div")<{
  accountName?: boolean;
}>`
  padding: ${rem(20)};
  background: #ffffff;
  border: 1px solid #ffffff;
  backdrop-filter: blur(2px);
  border-radius: 20px;

  label {
    font-weight: 400;
    line-height: 19px;
    color: #666666;
  }

  h4 {
    padding-bottom: 2rem;
  }
  .accountHolder {
    input,
    input:focus {
      border: none !important;
      margin: -1rem 0;
      text-align: right;
      color: #34a853;
      font-weight: 600;
      outline: none;
    }

    display: ${({ accountName }) => (accountName ? "initial" : "none")};
  }

  .remark {
    min-height: 6rem;
    background: #ffffff;
    border: 1px solid ${color("ashRadio")};
    border-radius: 10px;
    display: block;
    margin-top: 0.5rem;
    width: 100%;
    font-family: "Gilmer";
    padding: 8px;

    ::placeholder {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      font-size: inherit;
      color: #a5b0b7;
    }
  }
  .left > div {
    display: flex;
    gap: 2rem;
    align-items: center;
  }

  .switch-container {
    margin-top: ${rem(15)};
  }
`;

export const TransactionSummaryContainer = styled("div")`
  background: #ffffff;
  border: 1px solid #ffffff;
  align-self: flex-start;
  backdrop-filter: blur(2px);
  min-height: 250px;
  border-radius: 20px;
  padding: ${rem(20)};
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  p {
    width: 100%;
    color: #69778c;
  }

  span {
    font-weight: 600;
    color: #434343;
    float: right;
  }

  .button {
    width: 100%;
    height: 40px;
    margin-top: 2rem;
    border-radius: 10px;

    &:disabled {
      background-color: #0077ff;
      opacity: 0.6;
    }
  }

  ${down("mobileL")} {
    width: 100%;
  }
`;

export const StyledBeneficiaryContainer = styled("div")`
  margin-bottom: ${rem(20)};
  overflow-x: auto;
  max-width: ${rem(300)};
  padding-bottom: ${rem(16)};
  ${FlexColumn};
  gap: ${rem(14)};

  ${up("tablet")} {
    max-width: ${rem(500)};
  }
  ${up("laptop")} {
    max-width: ${rem(500)};
  }
  ${up("laptopL")} {
    max-width: ${rem(800)};
  }
  ${up("desktop")} {
    max-width: ${rem(1700)};
  }

  ${down("mobileL")} {
    max-width: 100%;
  }
`;

export const StyledBeneficiaryCardContainer = styled("div")`
  display: flex;
  gap: ${rem(20)};
  align-items: stretch;
`;

export const StyledBeneficiaryCard = styled("div")<{ isSelected: boolean }>`
  ${FlexColumn};
  gap: ${rem(20)};
  cursor: pointer;
  background-color: ${({ isSelected }) =>
    isSelected ? color("lightBlue") : "white"};
  border-radius: ${rem(15)};
  padding: ${rem(20)} ${rem(10)};
  ${FlexColumn};
  justify-content: center;
  align-items: center;
  min-width: ${rem(125)};
  gap: ${rem(10)};
  color: ${({ isSelected }) => (isSelected ? "white!important" : "")};
  transition: 0.5s all ease;

  .avatar {
    padding: 2px 7px;
    background-color: ${({ isSelected }) =>
      isSelected ? color("green") : color("lightBlue")};
    border-radius: 3px;
    width: max-content;
    font-size: ${rem(10)};
    display: grid;
    place-items: center;
    font-size: bold;
    color: white;
  }

  .label {
    text-align: center;
    h4 {
      font-size: ${rem(12)};
    }

    p {
      font-size: ${rem(10)};
      color: ${({ isSelected }) =>
        isSelected ? "white!important" : color("labelBlue")};
    }
  }

  ${down("mobileL")} {
    min-width: ${rem(200)};
  }
`;
