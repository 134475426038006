const CardPosIcon = (props: any) => {
  return (
    <svg
      viewBox="0 0 10 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.33344 0.666992H1.66678C1.37209 0.666992 1.08947 0.784055 0.881101 0.992429C0.672727 1.2008 0.555664 1.48342 0.555664 1.7781L0.555664 2.14847H9.44455V1.7781C9.44455 1.48342 9.32749 1.2008 9.11912 0.992429C8.91074 0.784055 8.62813 0.666992 8.33344 0.666992ZM0.555664 6.22255C0.555664 6.51723 0.672727 6.79985 0.881101 7.00822C1.08947 7.2166 1.37209 7.33366 1.66678 7.33366H8.33344C8.62813 7.33366 8.91074 7.2166 9.11912 7.00822C9.32749 6.79985 9.44455 6.51723 9.44455 6.22255V2.88921H0.555664V6.22255ZM2.40752 4.3707H3.889C3.98723 4.3707 4.08143 4.40972 4.15089 4.47917C4.22035 4.54863 4.25937 4.64284 4.25937 4.74107C4.25937 4.83929 4.22035 4.9335 4.15089 5.00296C4.08143 5.07242 3.98723 5.11144 3.889 5.11144H2.40752C2.30929 5.11144 2.21508 5.07242 2.14562 5.00296C2.07617 4.9335 2.03715 4.83929 2.03715 4.74107C2.03715 4.64284 2.07617 4.54863 2.14562 4.47917C2.21508 4.40972 2.30929 4.3707 2.40752 4.3707Z"
        fill="inherit"
      />
    </svg>
  );
};
export default CardPosIcon;
