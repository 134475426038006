import { ContainerStyle } from "components/common/VDetails/styles";
import { StyledInvoiceSummaryCards } from "components/invoices/invoice-summary-cards";
import PosTransactionTable from "./details-screen/pos-transaction-table";
import UserDetails from "./details-screen/user-details";
import PosCard from "./details-screen/pos-card";

const PosCardDetails = () => {
  return (
    <div>
      <ContainerStyle>
        <StyledInvoiceSummaryCards>
          <PosCard />
        </StyledInvoiceSummaryCards>
      </ContainerStyle>
      <UserDetails />
      <PosTransactionTable />
    </div>
  );
};

export default PosCardDetails;
