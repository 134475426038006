const EnterprisePlanIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 55 61.11"
      {...props}
    >
      <mask
        id="mask0_3535_122048"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="55"
        height="55"
      >
        <circle cx="27.5" cy="27.5" r="27.5" fill="#E4E9FF" />
      </mask>
      <g mask="url(#mask0_3535_122048)">
        <circle cx="27.5" cy="27.5" r="27.5" fill="#E4E9FF" />
        <mask
          id="mask1_3535_122048"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="11"
          y="17"
          width="33"
          height="45"
        >
          <rect
            x="11"
            y="17.7227"
            width="33"
            height="43.3889"
            rx="14"
            fill="#4791FF"
          />
        </mask>
        <g mask="url(#mask1_3535_122048)">
          <rect
            x="11"
            y="17.7227"
            width="33"
            height="43.3889"
            rx="14"
            fill="#0077FF"
          />
          <circle
            opacity="0.1"
            cx="27.5"
            cy="20.1667"
            r="9.16666"
            fill="#0077FF"
          />
        </g>
        <circle cx="27.5" cy="18.3346" r="9.16666" fill="black" />
        <rect
          x="15.8889"
          y="32.3906"
          width="23.2222"
          height="7.94444"
          rx="3.97222"
          fill="black"
        />
        <path
          d="M20.4722 34.8359H31.1666"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          opacity="0.5"
          d="M20.4722 37.8906H26.2777"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <rect
          x="15.8889"
          y="43.3867"
          width="23.2222"
          height="3.05556"
          rx="1.52778"
          fill="black"
        />
        <path
          d="M26.835 14.9897C27.0443 14.3455 27.9556 14.3455 28.1649 14.9897C28.5263 16.1018 29.5626 16.8547 30.732 16.8547C31.4093 16.8547 31.6909 17.7215 31.143 18.1196C30.1969 18.8069 29.8011 20.0252 30.1624 21.1374C30.3717 21.7815 29.6345 22.3172 29.0865 21.9191C28.1405 21.2317 26.8594 21.2317 25.9134 21.9191C25.3655 22.3172 24.6282 21.7815 24.8375 21.1374C25.1988 20.0252 24.803 18.8069 23.8569 18.1196C23.309 17.7215 23.5906 16.8547 24.2679 16.8547C25.4373 16.8547 26.4736 16.1018 26.835 14.9897Z"
          stroke="white"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};

export default EnterprisePlanIcon;
