import { gql, useMutation } from "urql";
import { handleRequestCall } from "utils/handleRequestCall";
import { showToast } from "components/common";

const deleteInventoryServiceMutation = gql`
  mutation ($serviceId: String!, $reason: String!) {
    deleteCustomerService(serviceId: $serviceId, reason: $reason)
  }
`;

export const useDeleteInventoryService = () => {
  const [inventoryService, deleteInventoryService] = useMutation(
    deleteInventoryServiceMutation
  );

  const handleDeleteInventoryService = async (
    data: { productId: string; reason: String },
    callBack?: () => void
  ) => {
    try {
      const req = await deleteInventoryService(data);
      await handleRequestCall({
        request: req,
        callBack: () => {
          callBack?.();
        },
      });
    } catch (e) {
      showToast({
        type: "error",
        title: "An error occured",
        subText: "Please try again",
      });
      console.error(e);
    }
  };

  return {
    handleDeleteInventoryService,
    inventoryService,
  };
};
