import styled from "styled-components/macro";
import { FlexColumn, StyledVCardContainer, stylingUtils } from "styles";

const { rem, color } = stylingUtils;

export const StyledBillingPlanCard = styled(StyledVCardContainer)`
  ${FlexColumn};
  gap: ${rem(20)};
  justify-content: center;
`;
export const StyledBillingPlanHeader = styled("section")`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledPlanType = styled("div")`
  .billing__summary {
    display: flex;
    align-items: center;
    gap: ${rem(8)};
    font-size: ${rem(16)};
    margin-bottom: ${rem(8)};

    span {
      &:first-child {
        font-weight: bold;
      }
    }
  }

  .billing__subtitle {
    font-size: ${rem(12)};
  }
`;

export const StyledBillingAmount = styled("section")`
  ${FlexColumn};
  gap: ${rem(15)};

  h3 {
    span:first-child {
      font-size: ${rem(30)};
    }

    span {
      font-weight: 300;
    }
  }

  p {
    font-size: ${rem(13)};
  }

  .plan-days {
    margin-top: ${rem(6)};
  }

  .warning {
    color: ${color("red")};
  }
`;

export const StyledBillingAction = styled("button")`
  display: flex;
  align-items: center;
  gap: ${rem(7)};
  font-weight: 500;
  font-size: ${rem(12)};

  &.normal {
    color: ${color("lightBlue")};
  }

  &.warning {
    color: ${color("orange")};
  }
`;
