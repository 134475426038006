import { extractFetchedData } from "utils";
import { gql, useQuery } from "urql";
import { useAuthUser } from "store";
import { useLazyQuery } from "hooks";
import { useEffect, useState } from "react";
import { showToast } from "components/common";

export const useGetSalesReceiptPDF = ({
  businessId,
  saleId,
}: {
  businessId: string;
  saleId: string;
}) => {
  return useQuery({
    query: getSalesReceiptPDFQuery,
    variables: { businessId, saleId },
  });
};
export const useLazyGetSalesReceiptPDF = () => {
  return useLazyQuery({
    query: getSalesReceiptPDFQuery,
  });
};

export const useGetSalesReceiptPDFHook = (cacheable = true) => {
  const [hasDownloaded, setHasDownloaded] = useState(false);

  const { userData } = useAuthUser();
  const [result, downloadPDF] = useLazyGetSalesReceiptPDF();

  const extract = extractFetchedData<string>({
    result,
    key: "getSalePDF",
    defaultReturn: "",
  });

  const { extractedData: pdfUrl } = extract;

  useEffect(() => {
    if (result.error) {
      showToast({
        title: "Error downloading receipt",
        type: "error",
      });
    }
  }, [result.error]);

  const handleDownloadPDF = async (id: string) => {
    // If it has downloaded once, and it is cacheable don't call the endpoint again for non-cacheable
    // Use case, in a single sales detail page
    if (cacheable && hasDownloaded) {
      return setHasDownloaded(false);
    }

    // In a multiple sales item page
    downloadPDF({
      businessId: userData?.businessId as string,
      saleId: id,
    });
    setHasDownloaded(false);
  };

  const downloading = result?.fetching;

  const effectRef = cacheable ? [pdfUrl, hasDownloaded] : [pdfUrl];

  useEffect(() => {
    const canDownload = pdfUrl && !hasDownloaded;

    // Handle the first time download
    if (canDownload) {
      window.open(pdfUrl, "_blank");
      setHasDownloaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...effectRef]);

  return { extract, handleDownloadPDF, downloading };
};

const getSalesReceiptPDFQuery = gql`
  query ($businessId: String!, $saleId: String!) {
    getSalePDF(businessId: $businessId, saleId: $saleId)
  }
`;
