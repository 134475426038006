import styled from "styled-components/macro";
import { stylingUtils } from "styles";

const { rem } = stylingUtils;

export const Container = styled.main<{
  shouldHideSidebar: boolean;
  isSideBarMinimzed?: boolean;
}>`
  display: ${({ shouldHideSidebar }) => (shouldHideSidebar ? "block" : "grid")};
  width: 100%;
  height: 100vh;
  grid-template-columns: ${({ shouldHideSidebar, isSideBarMinimzed }) =>
    !shouldHideSidebar && isSideBarMinimzed ? "0.35fr 5fr" : "1.25fr 5fr"};
  overflow: hidden;

  @media (max-width: 762px) {
    display: block;
  }
`;

export const BodyContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  grid-column: 2fr;
  /* align-items: center; */
  flex-direction: column;
  background-color: ${stylingUtils.color("ash01")};
  height: 100vh;
  width: 100%;
  overflow: hidden;
`;

export const BodyInnerContainer = styled.div<{
  inMarketPlace?: boolean;
}>`
  flex: 1;
  overflow-y: scroll;
  height: calc(100vh - ${stylingUtils.rem(80)});
  --container-max-width: ${stylingUtils.rem(1248)};
  --container-horizontal-padding: ${stylingUtils.rem(12)};
  width: 100%;
  margin: 0 auto;
  background-size: cover;
  background-position: center;
  padding: ${({ inMarketPlace }) =>
    inMarketPlace ? "0" : `${rem(30)} ${rem(25)} ${rem(40)}`};
  transition: all 0.5s ease-in-out;

  @media (max-width: 700px) {
    display: block;
  }
`;

export const BodyInnerBackground = styled("div")`
  background-position: center;
  background-size: cover;
`;
