import CloseIcon from "assets/icons/close-icon.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/setting.svg";
import { StyledNotificationHeader } from "./styles";

export const NotificationHeader = ({ close }: { close: () => void }) => {
  return (
    <StyledNotificationHeader>
      <div className="left">
        <div className="modal__close--icon" onClick={close}>
          <img src={CloseIcon} alt="close-icon" />
        </div>
        <h3>Notifications</h3>
      </div>

      <div className="right">
        <SettingsIcon />
      </div>
    </StyledNotificationHeader>
  );
};
