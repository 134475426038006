import styled from "styled-components";
import { stylingUtils, FlexAMixin, FlexJAMixin } from "styles";
import { StyledStaffNameAvatar } from "components/staff/styles";

const { rem, color } = stylingUtils;

export const Status = styled("div")`
  li {
    padding: ${rem(5)};
    background-color: #fff7ee;
    border-radius: 30px;
    color: #f0a75a;
    width: max-content;
    display: list-item;
    font-family: "Gilmer";
    font-weight: 400;
    line-height: 17px;
  }
  span {
    position: relative;
    left: ${rem(-5)};
  }
`;

export const Contacts = styled("div")`
  ${FlexAMixin}
  gap: ${rem(25)};
  .contact-image {
    background-color: ${color("ash01")};
    ${FlexJAMixin};
    padding: ${rem(8)};
    border-radius: 999%;

    img {
      height: 15px;
      width: 15px;
    }
    flex: none;
  }
`;

export const StyledCustomerIconWrapper = styled("div")<{ color: string }>`
  height: ${rem(40)};
  width: ${rem(40)};
  ${FlexJAMixin};
  border-radius: ${rem(5)};
  background-color: ${({ color }) => color};

  svg {
    width: ${rem(20)}!important;
    height: ${rem(20)}!important;
  }
`;

export const StyledCustomerNameAvatar = styled(StyledStaffNameAvatar)`
  padding: ${rem(10)} ${rem(10)};
`;
