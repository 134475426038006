// Core Components
import OtpInput from "react-otp-input";
import clsx from "clsx";

// Assets
import { VSecretPinProps } from "../types";
import { StyledVSecretPin } from "./styles";

const VSecretPin = ({
  numInputs = 4,
  value = "",
  onChange,
  className,
  hasErrored = false,
  isInputSecure = true,
  disabled = false,
  shouldAutoFocus = true,
}: VSecretPinProps) => {
  return (
    <StyledVSecretPin>
      <OtpInput
        numInputs={numInputs}
        value={value}
        onChange={onChange}
        containerStyle={clsx("otpContainer", className)}
        inputStyle={"otpInput"}
        shouldAutoFocus={shouldAutoFocus}
        isInputNum
        hasErrored={hasErrored}
        isInputSecure={isInputSecure}
        errorStyle={"otpError"}
        isDisabled={disabled}
      />
    </StyledVSecretPin>
  );
};

export default VSecretPin;
