import { MouseEventHandler, ReactNode } from "react";
import ReactDOM from "react-dom";
import { StyledNavbarContainer, StyledNavbarOverlay } from "./styles";

const MobileSidebarModal = ({
  open,
  children,
  isSideBarMinimzed,
  close,
}: {
  open: boolean;
  isSideBarMinimzed: boolean;
  children: ReactNode;
  close: MouseEventHandler;
}) => {
  return ReactDOM.createPortal(
    <StyledNavbarOverlay open={open} onClick={close}>
      <StyledNavbarContainer
        width={isSideBarMinimzed ? 100 : 310}
        open={open}
        padding={8}
        isDrawerLeft={true}
      >
        {children}
      </StyledNavbarContainer>
    </StyledNavbarOverlay>,
    document.getElementById("v-modal") as any
  );
};

export default MobileSidebarModal;
