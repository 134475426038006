import { createSlice } from "@reduxjs/toolkit";

type TTransactionPinStateProps = {
  open: boolean;
  OldPin: string;
  newPin: string;
  passCode: string;
};

const initialState: TTransactionPinStateProps = {
  open: false,
  passCode: "",
  newPin: "",
  OldPin: "",
};

const transactionPinSlice = createSlice({
  name: "transactionPin",
  initialState,
  reducers: {
    togglepinModal: (state) => {
      state.open = !state.open;
    },
    setNewPin: (state, action) => {
      state.newPin = action.payload;
    },
    setOldPin: (state, action) => {
      state.OldPin = action.payload;
    },
    setPassCode: (state, action) => {
      state.passCode = action.payload;
    },
    resetTransactionPin: (state) => {
      state.newPin = "";
      state.OldPin = "";
      state.passCode = "";
    },
  },
});

const transactionPinReducer = transactionPinSlice.reducer;
const {
  togglepinModal,
  setNewPin,
  setOldPin,
  setPassCode,
  resetTransactionPin,
} = transactionPinSlice.actions;

export {
  transactionPinReducer,
  togglepinModal,
  setNewPin,
  setOldPin,
  setPassCode,
  resetTransactionPin,
};
