import { ITotalSales } from "api";
import { BillingCheckoutCard } from "components/manage-business";
import { useGetTotalSalesHook } from "hooks";
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
  Line,
} from "recharts";
import { formatNumberToCurrency } from "utils";
import {
  StyledChartTooltipWrapper,
  StyledTotalSalesGraph,
  StyledTotalSalesGraphWrapper,
} from "./styles";

const CustomTooltip = ({
  payload,
  label,
  active,
}: {
  payload?: Record<string, any>[];
  label?: string;
  active?: boolean;
}) => {
  if (active) {
    const newPayload = (payload || [])[0]?.payload as ITotalSales;
    return (
      <StyledChartTooltipWrapper>
        <p
          style={{
            fontWeight: "bold",
          }}
        >
          {newPayload?.month}
        </p>
        <p
          style={{
            color: (payload || [])[0]?.color || "lightblue",
          }}
        >
          Sales Value:{" "}
          {formatNumberToCurrency({ number: newPayload.totalSales || 0 })}
        </p>
        <p
          style={{
            color: (payload || [])[1]?.color || "orange",
          }}
        >
          Sales Count:{" "}
          {formatNumberToCurrency({
            number: newPayload?.totalSalesCount || 0,
            removeCurrency: true,
          })}
        </p>
      </StyledChartTooltipWrapper>
    );
  }

  return null;
};

const TotalSalesChart = () => {
  const data = useGetTotalSalesHook();

  return (
    <ResponsiveContainer width="95%" height="100%">
      <ComposedChart data={data}>
        <XAxis dataKey="month" />
        <YAxis
          yAxisId="left"
          tickFormatter={(value) =>
            formatNumberToCurrency({ number: value, isShort: true })
          }
        />
        <YAxis yAxisId="right" orientation="right" />
        <CartesianGrid />
        <Tooltip content={<CustomTooltip />} />
        <Legend
          wrapperStyle={{
            top: -30,
          }}
        />
        <Bar
          yAxisId="left"
          dataKey="totalSales"
          barSize={35}
          fill="#80BCFF"
          radius={[7, 7, 7, 7]}
          name="Sales Value"
        />
        <Line
          yAxisId="right"
          type="monotone"
          dataKey="totalSalesCount"
          stroke="#F1A85A"
          name="Sales Count"
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export const TotalSalesGraph = ({
  shouldBeFirst = false,
  height = 400,
  useOrder = true,
}: {
  shouldBeFirst?: boolean;
  height?: number;
  useOrder?: boolean;
}) => {
  const content = (
    <BillingCheckoutCard title={"Total Sales"}>
      <StyledTotalSalesGraph height={height}>
        <TotalSalesChart />
      </StyledTotalSalesGraph>
    </BillingCheckoutCard>
  );

  return useOrder ? (
    <StyledTotalSalesGraphWrapper shouldBeFirst={shouldBeFirst}>
      {content}
    </StyledTotalSalesGraphWrapper>
  ) : (
    content
  );
};
