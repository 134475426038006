import { forwardRef } from "react";
import { Controller } from "react-hook-form";
import {
  StyledVSwitch,
  StyledVSwitchInput,
  StyledVSwitchWrapper,
} from "./styles";

type VSwitchProps = {
  label?: string | JSX.Element;
  labelStyles?: Record<string, any>;
  control?: any;
  validation?: any;
  name: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

const SwitchInputField = ({
  onChange,
  value,
  ...rest
}: {
  onChange?: any;
} & VSwitchProps) => {
  return (
    <StyledVSwitchInput
      type="checkbox"
      onChange={(event) => onChange && onChange?.(event)}
      checked={!!value}
      {...rest}
    />
  );
};

export const VSwitch = forwardRef((props: VSwitchProps, ref: any) => {
  const {
    label,
    style,
    className,
    checked,
    name,
    validation,
    labelStyles = {},
    control,
    ...rest
  } = props;

  const otherProps = { style, className };

  return (
    <StyledVSwitchWrapper {...otherProps}>
      {control ? (
        <Controller
          name={name}
          rules={validation}
          key={name}
          control={control}
          render={({ field }) => {
            return (
              <SwitchInputField
                name={name}
                onChange={field.onChange}
                value={field.value}
                {...rest}
              />
            );
          }}
        />
      ) : (
        <SwitchInputField name={name} onChange={rest?.onChange} {...rest} />
      )}
      <StyledVSwitch />
      {label && (
        <label style={labelStyles} htmlFor={name}>
          {label}
        </label>
      )}
    </StyledVSwitchWrapper>
  );
});
