import { stylingUtils } from "styles";
import styled from "styled-components/macro";

const { rem, color } = stylingUtils;

export const VDropdownContainer = styled("div")`
  position: relative;
`;

export const VDropdonwButton = styled("div")`
  position: relative;
`;

export const VDropdownMenu = styled("div")<{
  width: number;
  isActive: boolean;
  isLeft: boolean;
  isTop: boolean;
}>`
  display: ${({ isActive }) => (isActive ? "flex" : "none")};
  flex-direction: column;
  position: absolute;
  width: ${({ width }) => (width ? rem(width) : rem(160))};
  height: auto;
  background-color: white;
  left: ${({ isLeft }) => !isLeft && "110%"};
  right: ${({ isLeft }) => isLeft && "110%"};
  top: ${({ isTop }) => isTop && "0"};
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.04);
  padding: ${rem(16)} 0;
  border-radius: ${rem(10)};
  z-index: 99;
`;

export const VMobileDropdownMenu = styled.div<{
  isActive: boolean;
  width: number;
}>`
  display: ${({ isActive }) => (isActive ? "flex" : "none")};
  flex-direction: column;
  width: 100%;
  height: auto;
  background-color: white;
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.04);
  padding: ${rem(16)};
  border-radius: ${rem(10)};
  align-items: center;
  justify-content: center;
`;

export const VDropdownMenuItem = styled("button")`
  text-align: left;
  width: 100%;
  padding: ${rem(9)} ${rem(16)};
  transition: 0.5s all ease;

  &:hover {
    background-color: ${color("transparentBlue")};
  }
`;
