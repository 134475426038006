import { useProductPageHook } from "./../../hooks/components/useProductPageHook";
import { gql, useMutation } from "urql";
import { handleRequestCall } from "utils/handleRequestCall";
import { useAuthUser } from "store";
import { useServicePageHook } from "hooks";

const addCategoryMutation = gql`
  mutation (
    $businessId: String!
    $inventoryType: INVENTORYTYPEENUM!
    $category: [CategoryInput]
  ) {
    addCategory(
      businessId: $businessId
      inventoryType: $inventoryType
      category: $category
    ) {
      name
    }
  }
`;

interface CategoryInput {
  name: string;
}

export type IAddCategory = {
  businessId: string;
  inventoryType: string;
  category: CategoryInput[];
};

export const useAddCategory = () => {
  const [category, addCategory] = useMutation(addCategoryMutation);
  const { refetchServiceCategory } = useServicePageHook({});
  const { refetchProductCategories } = useProductPageHook({});

  const { userData } = useAuthUser();

  const handleAddCategory = async (data: any, callBack?: () => void) => {
    const { category, inventoryType } = data;

    try {
      const values = {
        category,
        inventoryType: inventoryType,
        businessId: userData?.businessId as string,
      };
      const req = await addCategory(values);

      await handleRequestCall({
        request: req,
        callBack: () => {
          if (inventoryType === "SERVICE") {
            refetchServiceCategory();
          }
          if (inventoryType === "PRODUCT") {
            refetchProductCategories();
          }
          callBack?.();
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  return {
    handleAddCategory,
    category,
    isLoading: category?.fetching,
  };
};
