import CircledCheckIcon from "assets/images/svgs/CircledCheckIcon";
import { VAccordion, VButton } from "components/common";
import { convertToLocalCurrency } from "utils";
import {
  StyledPlanAmount,
  StyledPricingPlanAccordion,
  StyledPricingPlanBasicFeatures,
  StyledPricingPlanBasicFeaturesContainer,
  StyledPricingPlanCard,
  StyledPricingPlanCardHeader,
  StyledPricingPlanDivider,
  StyledPricingPlanSummary,
} from "./styles";
import { VPricingPlanProps } from "./types";

const PricingPlanCard = (props: VPricingPlanProps) => {
  const {
    planIcon: PlanIcon,
    planName,
    planBilling,
    summary,
    buttonText,
    basicFeatures,
    isAmount = true,
    symbol = "$",
    id,
    isLoading,
    handleMakePayment,
    disabled,
  } = props;

  const planAmount = convertToLocalCurrency({
    amount: planBilling,
    symbol,
    addCurrency: false,
  });

  return (
    <StyledPricingPlanCard>
      <StyledPricingPlanCardHeader>
        <StyledPlanAmount>
          <h4>{planName}</h4>
          {isAmount ? (
            <h5 className="amount__text">
              <span className="currency">{symbol}</span>
              <span className="amount">{planAmount}</span>
              <span className="billing__cycle">/month</span>
            </h5>
          ) : (
            <h5 className="custom__billing">
              <span>{planBilling}</span>
            </h5>
          )}
        </StyledPlanAmount>
        <PlanIcon height={40} />
      </StyledPricingPlanCardHeader>
      <StyledPricingPlanSummary>{summary}</StyledPricingPlanSummary>
      <div>
        <VButton
          disabled={disabled}
          isLoading={isLoading}
          onClick={() => {
            handleMakePayment &&
              handleMakePayment({
                planName,
                planBilling,
                symbol,
                planCode: id,
              });
          }}
          text={buttonText}
          width="100%"
          style={{ marginBottom: "24px" }}
        />
      </div>
      <StyledPricingPlanDivider />
      <StyledPricingPlanBasicFeaturesContainer>
        {basicFeatures.map((option) => (
          <StyledPricingPlanBasicFeatures key={option}>
            <CircledCheckIcon height={13} />
            <p>{option}</p>
          </StyledPricingPlanBasicFeatures>
        ))}
      </StyledPricingPlanBasicFeaturesContainer>
      <StyledPricingPlanAccordion>
        <VAccordion
          headerClassName="ppHeader"
          title="See More Feartures"
          isDefaultOpen={false}
        ></VAccordion>
      </StyledPricingPlanAccordion>
    </StyledPricingPlanCard>
  );
};

export default PricingPlanCard;
