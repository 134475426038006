import { showToast } from "components/common";
import { gql, useMutation } from "urql";
import { handleRequestCall } from "utils/handleRequestCall";

const deleteBusinessCustomerMutation = gql`
  mutation ($businessCustomerId: String) {
    deleteBusinessCustomer(businessCustomerId: $businessCustomerId) {
      success
      message
    }
  }
`;

export const useDeleteBusinessCustomer = ({
  businessCustomerId,
}: {
  businessCustomerId: string;
}) => {
  const [deletedCustomer, deleteCustomer] = useMutation(
    deleteBusinessCustomerMutation
  );

  const handleDeleteBusinessCustomer = async (callBack?: () => void) => {
    try {
      const req = await deleteCustomer({ businessCustomerId });

      await handleRequestCall({
        request: req,
        callBack: () => {
          callBack?.();
        },
      });
    } catch (e) {
      showToast({
        type: "error",
        title: "An error occured",
        subText: "Please try again",
      });
    }
  };

  return {
    isLoading: deletedCustomer?.fetching,
    handleDeleteBusinessCustomer,
  };
};
