import { ReactComponent as Profit } from "assets/icons/summary/sales/profit.svg";
import { ReactComponent as TotalSalesValue } from "assets/icons/summary/sales/total-sales-value.svg";
import { ReactComponent as SalesCount } from "assets/icons/summary/sales/sales-count.svg";
import { ReactComponent as LastWeekGrowth } from "assets/icons/summary/sales/last-week's-growth.svg";
import { CardProps } from "components/common/types";
import { formatNumberToCurrency } from "utils";
import CardPosIcon from "assets/images/svgs/CardPosIcon";
import CashIcon from "assets/images/svgs/CashIcon";
import BankTransferIcon from "assets/images/svgs/BankTransferIcon";
import { ISaleSummaryCards } from "api";

export const SALES_CARD_DATA = ({
  data,
}: {
  data: ISaleSummaryCards;
}): CardProps[] => {
  const {
    totalSale = 0,
    saleCount = 0,
    totalProfit = 0,
    profitMargin = "0%",
  } = data;
  return [
    {
      title: "Total Sales Value",
      icon: () => <TotalSalesValue />,
      amount: formatNumberToCurrency({ number: Number(totalSale) }),
    },
    {
      title: `Sales Count`,
      icon: () => <SalesCount />,
      amount: formatNumberToCurrency({
        number: Number(saleCount),
        removeCurrency: true,
      }),
    },
    {
      title: `Profit`,
      icon: () => <Profit />,
      amount: formatNumberToCurrency({ number: Number(totalProfit) }),
    },
    {
      title: `Profit Margin`,
      icon: () => <LastWeekGrowth />,
      amount: profitMargin as string,
    },
  ];
};

export const SALES_REPORT_GRAPH_MOCK_DATA = [
  {
    name: "Cereals",
    uv: 4000,
  },
  {
    name: "Malt",
    uv: 3000,
  },
  {
    name: "Water",
    uv: 2000,
  },
  {
    name: "Rice",
    uv: 2780,
  },
  {
    name: "Snacks",
    uv: 1890,
  },
  {
    name: "Drinks",
    uv: 2390,
  },
  {
    name: "Beverage",
    uv: 3490,
  },
  {
    name: "Custard",
    uv: 3490,
  },
  {
    name: "Onion",
    uv: 3490,
  },
];

export const PAYMENT_ANALYSIS_DATA = [
  {
    key: "POS",
    label: "Card (POS)",
    color: "#E5F0FD",
    value: "50%",
    icon: (
      <CardPosIcon fill="transparent" width={20} height={20} stroke="white" />
    ),
  },
  {
    key: "CASH",
    label: "Cash",
    color: "#FFF7EF",
    value: "20%",
    icon: <CashIcon width={20} height={20} />,
  },
  {
    key: "BANK",
    label: "Bank Transfer",
    color: "#DCF8E3",
    value: "30%",
    icon: <BankTransferIcon fill="white" width={20} height={20} />,
  },
];
