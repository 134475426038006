import styled from "styled-components/macro";
import { FlexColumn, stylingUtils } from "styles";

const { rem } = stylingUtils;

export const StyledBillingCheckoutContainer = styled("section")`
  display: flex;
  width: 100%;
  flex: 1;
  gap: ${rem(20)};

  & > div {
    width: 50%;
  }
`;

export const StyledBillingCheckoutLeft = styled("section")`
  ${FlexColumn};
  gap: ${rem(20)};
  flex: 1;
`;
