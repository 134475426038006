import { IPagination } from "./../types";
import { gql, useQuery } from "urql";
import { PaginationFragment } from "api/general-queries";

export const AlerzoProductNodeQuery = gql`
  fragment AlerzoProductNodeQuery on AlerzoProduct {
    _id
    businessId
    productName
    sellingPrice
    productImage
    quantity
    purchasePrice
  }
`;

type useGetAlerzoProductsProps = {
  orderId: string;
  page?: number;
  status?: string;
};

export const useGetAlerzoProducts = ({
  orderId,
  page = 1,
  status,
}: useGetAlerzoProductsProps) => {
  return useQuery({
    query: getAlerzoProductsQuery,
    variables: { orderId, page, status },
  });
};

const getAlerzoProductsQuery = gql`
  ${AlerzoProductNodeQuery},
  query ( $page: Int, $status: AlerzoStatusFilter  ) {
    getAlerzoProducts(
    filters: { status: $status}
     metadata: {
        size: 10,
        page: $page,
        sortOrder: "desc"
    }
   ){
   nodes {
      ...AlerzoProductNodeQuery
   }
  , 
   ${PaginationFragment}
  }
  }
`;

export interface IProducts extends IPagination {
  nodes: IProductNode[];
}

export interface IProductNode {
  _id: string;
  businessId: string;
  productName: string;
  sellingPrice: number;
  productImage: string;
  stock: number;
  purchasePrice: number;
  total: number;
  size: number;
  unitsPerPack: number;
  isBreakable: Boolean;
  quantity: number;
  uom: string;
  categoryId: string;
}
