import VerticalDropdown from "assets/icons/vertical-dot.svg";
import {
  CustomTableProps,
  InputList,
  VDropdownProps,
  InputListType,
} from "components/common/types";
import { APP_ROUTES } from "routes";
import {
  capitalizeWord,
  formatNumberToCurrency,
  getFullFormattedDate,
} from "utils";
import { IMAGE_PLACE_HOLDER } from "components/constants";
import { VDropdown } from "components/common";

import { useNavigate } from "react-router-dom";
import { ServiceDetailAPI } from "api/inventory/types";
import { TProduct } from "components/Inventory/types";
import { mapFromTableData } from "api/inventory/get-product-detail";
import theme from "styles/theme";

export const INVENTORY_PRODUCT_COLUMNS = (
  setSelectedProduct: (product: TProduct) => void,
  openEditModal: () => void
): CustomTableProps["columns"] => {
  const navigate = useNavigate();

  const goToProductPage = (productId: string) =>
    navigate(`${APP_ROUTES.PRODUCTS}/${productId}`);

  return [
    {
      headerName: "Product Id",
      key: "referenceId",
      width: 250,
      renderCell: (id: any, data: any) => {
        const { productImage } = data;
        return (
          <div className="flex_productId">
            <div className="flex_productId--img">
              <img
                src={productImage ? productImage : IMAGE_PLACE_HOLDER}
                alt="product"
              />
            </div>
            <p className="productId__title">{id}</p>
          </div>
        );
      },
    },
    {
      headerName: "Name",
      key: "productName",
      formatter: (productName) => capitalizeWord(productName || ""),
    },
    {
      headerName: "Category",
      key: "category.name",
      useZero: false,
    },
    {
      headerName: "Cost price",
      key: "batches",
      formatter: (batches, value) => {
        const parsedValue = JSON.parse(batches) || [];

        const totalPurchasePrice = Array.isArray(parsedValue)
          ? parsedValue[parsedValue.length - 1]?.purchasePrice
          : 0;
        const costPrice = formatNumberToCurrency({
          number: totalPurchasePrice || 0,
        });
        return costPrice;
      },
    },
    {
      headerName: "Selling price",
      key: "sellingPrice",
      formatter: (sellingPrice) =>
        formatNumberToCurrency({ number: sellingPrice }),
    },
    {
      headerName: "Quantity",
      key: "totalStock",
    },
    {
      headerName: "Action",
      key: "dropdown",
      renderCell: (_: any, tableData) => {
        return (
          <VDropdown
            dropdownButton={
              <button className="vertical__drop--down">
                <img src={VerticalDropdown} alt="vertical-dropdown" />
              </button>
            }
            dropdownItems={INVENTORY_PRODUCT_ACTIONS_DROPDOWNS({
              handleView: () => goToProductPage(tableData?._id),
              handleOpenEditModal: () => {
                const product = mapFromTableData(tableData);
                setSelectedProduct(product);
                openEditModal();
              },
            }).map((item) => ({
              ...item,
            }))}
            isLeft={true}
          />
        );
      },
    },
  ];
};

export const INVENTORY_PRODUCT_ACTIONS_DROPDOWNS = ({
  handleView,
  handleOpenEditModal,
}: {
  handleView: () => void;
  handleOpenEditModal: () => void;
}): VDropdownProps["dropdownItems"] => {
  return [
    {
      key: "view",
      label: "View",
      styles: { color: theme.colors.green },
      onClick: () => handleView(),
    },
    {
      key: "edit",
      label: "Edit",
      styles: { color: theme.colors.lightBlue },
      onClick: () => handleOpenEditModal(),
    },
  ] as VDropdownProps["dropdownItems"];
};

export const INVENTORY_SERVICE_COLUMNS = (
  handleView: (customerService: ServiceDetailAPI) => void,
  handleOpenEditModal: (customerService: ServiceDetailAPI) => void
): CustomTableProps["columns"] => {
  return [
    {
      headerName: "Service Id",
      key: "referenceId",
      width: 250,
    },
    {
      headerName: "Name",
      key: "serviceName",
      formatter: (productName) => capitalizeWord(productName),
    },
    {
      headerName: "Service Charge",
      key: "sellingPrice",
      useZero: false,
      formatter: (sellingPrice) =>
        formatNumberToCurrency({ number: sellingPrice }),
    },
    {
      headerName: "Category",
      key: "category.name",
      useZero: false,
    },
    {
      headerName: "Description",
      key: "description",
      useZero: false,
    },
    {
      headerName: "Action",
      key: "dropdown",
      renderCell: (_, tableData: any) => {
        return (
          <VDropdown
            dropdownButton={
              <button className="vertical__drop--down">
                <img src={VerticalDropdown} alt="vertical-dropdown" />
              </button>
            }
            dropdownItems={INVENTORY_SERVICE_ACTIONS_DROPDOWNS({
              handleView: () => handleView(tableData),
              handleEdit: () => handleOpenEditModal(tableData),
            }).map((item) => ({
              ...item,
            }))}
            isLeft={true}
          />
        );
      },
    },
  ];
};

export const INVENTORY_SERVICE_ACTIONS_DROPDOWNS = ({
  handleView,
  handleEdit,
}: {
  handleView: Function;
  handleEdit: Function;
}): VDropdownProps["dropdownItems"] => {
  return [
    {
      key: "view",
      label: "View",
      onClick: () => handleView(),
      styles: { color: theme.colors.green },
    },
    {
      key: "edit",
      label: "Edit",
      onClick: () => handleEdit(),
      styles: { color: theme.colors.lightBlue },
    },
  ] as VDropdownProps["dropdownItems"];
};

export const EXPENSE_DETAILS_ITEMS = [
  {
    key: "amount",
    header: "Amount",
    formatter: (value: any) => formatNumberToCurrency({ number: value }),
  },
  {
    key: "title",
    header: "Item",
  },
  {
    key: "category",
    header: "Category",
  },
  {
    key: "paymentType",
    header: "Payment Channel",
  },
  {
    key: "createdBy",
    header: "Added By",
  },
  {
    key: "createdAt",
    header: "Date & Time",
    formatter: (createdAt: string) => getFullFormattedDate(createdAt),
  },
];

export const VIEW_EXPENSE_DETAILS_ITEMS = [
  {
    key: "amount",
    header: "Amount",
    formatter: (value: any) => formatNumberToCurrency({ number: value }),
  },
  {
    key: "item",
    header: "Item",
  },
  {
    key: "category",
    header: "Category",
  },
  {
    key: "paymentType",
    header: "Payment Channel",
  },
  {
    key: "createdBy",
    header: "Added By",
  },
  {
    key: "createdAt",
    header: "Date & Time",
    formatter: (createdAt: string) => getFullFormattedDate(createdAt),
  },
];

export const INVENTORY_PAGE_TABS = [
  {
    key: "products",
    label: "Products",
    link: `${APP_ROUTES.PRODUCTS}`,
    secondaryLink: APP_ROUTES.INVENTORY,
  },
  {
    key: "services",
    label: "Services",
    link: `${APP_ROUTES.SERVICES}`,
  },
];

export const EDIT_INPUT_LIST = (changeQuantity: string) => {
  const isAddToQuantity = changeQuantity?.toLowerCase() === "add";
  const isRemoveToQuantity = changeQuantity?.toLowerCase() === "remove";

  return [
    {
      name: "productName",
      label: "Product Name",
      inputType: "text",
      placeholder: "Product Name",
      disabled: true,
    },
    {
      name: "category",
      label: "Category",
      inputType: "text",
      disabled: true,
    },
    {
      name: "costPrice",
      label: "Cost Price",
      type: "text",
      placeholder: "₦ 0.00",
      disabled: true,
    },
    {
      name: "sellingPrice",
      label: "Selling Price",
      type: "number",
      inputType: "amount",
      placeholder: "₦ 0.00",
      validation: {
        required: "Selling Price is required",
      },
    },
    {
      name: "quantity",
      label: "Quantity",
      type: "number",
      placeholder: "0",
      disabled: true,
    },
    {
      name: "changeQuantity",
      label: "Change Quantity",
      inputType: "select",
      placeholder: "Add to Quantity",
      options: [
        {
          label: "Add to quantity",
          value: "add",
        },
        {
          label: "Remove from quantity",
          value: "remove",
        },
      ],
      validation: {
        required: "Nature of change in quantity must be specified",
      },
    },
    ...(isAddToQuantity
      ? [
          {
            name: "content",
            label: "Reason for change in quantity",
            inputType: "select",
            placeholder: "Select reason",
            options: [
              {
                label: "Item restocked",
                value: "item restocked",
              },
              {
                label: "Item got stolen",
                value: "item got stolen",
              },
              {
                label: "Item used personally",
                value: "item used personally",
              },
              {
                label: "Item gifted out",
                value: "item gifted out",
              },
              {
                label: "Item broken/expired",
                value: "item broken/expired",
              },
              {
                label: "Other",
                value: "other",
              },
            ],
          },
          {
            name: "newQuantity",
            label: "New Quantity",
            type: "number",
            placeholder: "New Quantity to Add",
          },
          {
            name: "newPrice",
            label: "New Purchase Price",
            type: "number",
            placeholder: "New Price",
          },
        ]
      : []),
    {
      name: "thresholdLowStock",
      label: "Set Threshold for LowStock (optional)",
      type: "number",
      inputType: "amount",
      placeholder: "0",
    },

    ...(isRemoveToQuantity
      ? [
          {
            name: "removeQuantity",
            label: "Removed Quantity",
            type: "number",
            placeholder: "Quantity to remove",
          },
        ]
      : []),
    {
      name: "productImage",
      label: "Product Image (optional)",
      inputType: "upload",
      placeholder: "",
      accept: ".png, .jpg, .jpeg",
    },
  ] as InputList;
};

export type Category = { label: string; value: string; key: string };

export const CREATE_INPUT_LIST = (productCategories: Category[]) => {
  return [
    {
      name: "productName",
      label: "Product Name",
      inputType: "text",
      placeholder: "Enter Product Name",
      validation: {
        required: "Please enter a Product Name",
      },
    },
    {
      name: "category",
      label: "Category",
      inputType: "select",
      placeholder: "Choose Product Category",
      options: productCategories
        .filter((category: Category) => category.label !== "All")
        .map((category: Category) => ({
          label: category.label,
          value: category.value,
        })),
      validation: {
        required: "Please enter a Category",
      },
    },
    {
      name: "costPrice",
      label: "Cost Price",
      type: "amount",
      placeholder: "₦ 0.00",
      validation: {
        required: "Please specify a Cost Price",
      },
    },
    {
      name: "sellingPrice",
      label: "Selling Price",
      type: "amount",
      placeholder: "₦ 0.00",
      validation: {
        required: "Please specify a Selling Price",
      },
    },
    {
      name: "quantity",
      label: "Quantity",
      type: "number",
      placeholder: "0",
      validation: {
        required: "Please specify the quantity",
      },
    },
    {
      name: "thresholdLowStock",
      label: "Set Threshold for Low Stock (optional)",
      type: "number",
      placeholder: "0",
    },
    {
      name: "productImage",
      label: "Product Image (optional)",
      inputType: "upload",
      placeholder: "productExpense.jpg",
      accept: ".png, .jpg, .jpeg",
    },
  ] as const;
};

export const createNewCategory: InputList = [
  {
    name: "category",
    label: "Category",
    inputType: "text",
  },
] as const;

export const SERVICE_FORM_INPUT_LIST = (
  serviceCategories: InputListType["options"] = []
): InputList => {
  return [
    {
      name: "serviceName",
      label: "Service",
      required: true,
      validation: { required: "Please enter service name" },
    },
    {
      name: "sellingPrice",
      label: "Service Charge",
      type: "amount",
      required: true,
      validation: { required: "Enter service charge" },
    },
    {
      name: "categoryId",
      label: "Category",
      inputType: "select",
      options: serviceCategories,
      required: true,
      validation: { required: "Select category" },
    },
    {
      name: "note",
      label: "Note",
      required: true,
      validation: { required: "Please enter description" },
    },
    {
      name: "serviceImage",
      label: "Service Image (optional)",
      inputType: "upload",
      accept: ".png, .jpg, .jpeg",
    },
  ] as const;
};

export const EDIT_SERVICE_FORM_LIST = [
  {
    name: "name",
    label: "Service",
    disabled: true,
  },
  {
    name: "charge",
    label: "Service Charge",
    type: "amount",
    required: true,
    validation: { required: "Enter service charge" },
  },
  {
    name: "category",
    label: "Category",
    inputType: "text",
    disabled: true,
  },
  {
    name: "description",
    label: "Note",
    disabled: true,
  },
] as const;

export const CATEGORY_FORM_INPUT_LIST: string[] = ["first_input"];

export const VMOBILE_PRODUCT_HEADERS = [
  "Product ID",
  "Name",
  "Category",
  "Cost Price",
  "Selling Price",
  "Qty",
  "View",
  "Edit",
];

export const REASONS_OPTIONS = [
  { value: "Item got stolen", label: "Item got stolen" },
  { value: "Item restocked", label: "Item restocked" },
  { value: "Item used personally", label: " Item used personally" },
  { value: "Item gifted out", label: "  Item gifted out" },
  { value: "Item expired/broken", label: "Item expired/broken" },
];

export const ADD_PRODUCT_DELETE_REASON: InputListType[] = [
  {
    name: "reason",
    label: "Reason",
    inputType: "select",
    options: REASONS_OPTIONS,
    placeholder: "Select reason",
    required: true,
    validation: {
      required: "Please select a reason",
    },
  },
];
