import { Bottom, Top } from "components/pos/card/styles";
import { PosCardContainer, CardContent } from "./styles";
import VeedezLogo from "assets/images/svgs/Veedez-logo-small";
import posImage from "assets/images/pngs/vedeex-checkout-pos-small.png";
import { formatNumberToCurrency } from "utils";
import { usePosDetailsHook } from "hooks";
import { useAuthUser } from "store";

const PosCard = () => {
  const { posDetailsData } = usePosDetailsHook();
  const { userData } = useAuthUser();

  const currentUser = userData?.firstName + " " + userData?.lastName;

  return (
    <PosCardContainer>
      <CardContent>
        <Top>
          <div className="left">
            <h5>{posDetailsData?.terminalName}</h5>
            <h2>
              {formatNumberToCurrency({
                number: posDetailsData?.spec?.price,
              })}
            </h2>
          </div>
          <div className="right">
            <h5>Terminal ID</h5>
            <p>{posDetailsData?.tid}</p>
          </div>
        </Top>
        <Bottom>
          <div className="left">
            <img src={posImage} alt="" />
            <VeedezLogo style={{ marginBottom: "30px" }} />
          </div>
          <div className="right">
            <h5>Assigned to</h5>
            <p>{currentUser}</p>
          </div>
        </Bottom>
      </CardContent>
    </PosCardContainer>
  );
};

export default PosCard;
