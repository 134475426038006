import styled from "styled-components/macro";
import { stylingUtils } from "styles";

const { color, rem } = stylingUtils;

export const StyledCustomersTable = styled("div")`
  display: flex;
  flex-direction: column;
  gap: ${rem(16)};

  .flex_productId {
    display: flex;
    align-items: center;
    gap: ${rem(18)};

    &--img {
      width: ${rem(35)};
      height: ${rem(35)};
      border-radius: 6px;
      overflow: hidden;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
    .productId__title {
      color: ${color("lightBlue")}!important;
      text-decoration: underline;
    }
  }
`;
