import { useAddBusinessExpense, IBusinessExpenseNode } from "api";
import { useForm } from "react-hook-form";
import { showToast, VButton, VForm, VModal } from "components/common";
import { EXPENSE_FORM_INPUT_LIST } from "../constants";
import { StyledAddExpense, StyledExpenseForm } from "./styles";
import { VModalProps } from "components/common/types";
import { useMemo } from "react";
import { useExpensePageHook } from "hooks";

const AddExpenseModal = ({ open, close }: VModalProps) => {
  const hookForm = useForm<IBusinessExpenseNode>();

  const { handleOpenOnSubmit } = useExpensePageHook();

  const { handleAddBusinessExpense, businessExpense, expenseCategories } =
    useAddBusinessExpense();

  const closeModal = () => {
    reset({
      amount: "",
      item: "",
    });
    close();
  };

  const { handleSubmit, reset } = hookForm;
  const onSubmit = async (data: IBusinessExpenseNode) => {
    await handleAddBusinessExpense(data, () => {
      showToast({
        subText: "Your Expense was recorded successfully",
        actionText: "View Expense",
        action: () => handleOpenOnSubmit(data),
      });
      closeModal();
    });
  };

  const { fetching } = businessExpense;

  const addExpenseInputLists = useMemo(() => {
    return EXPENSE_FORM_INPUT_LIST({
      categories: expenseCategories,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expenseCategories.length]);

  return (
    <VModal
      open={open}
      close={closeModal}
      title="Record New Expense"
      subtitle="Expense Information"
    >
      <StyledAddExpense>
        <StyledExpenseForm>
          <form onSubmit={handleSubmit(onSubmit)}>
            <VForm
              inputLists={addExpenseInputLists}
              hookForm={hookForm}
              vFormClassName="expense__form"
            />
            <div className="form--button">
              <VButton
                isLoading={fetching}
                text="Record Expense"
                minWidth="295px"
                height="50px"
                mobWidth="100%"
              />
            </div>
          </form>
        </StyledExpenseForm>
      </StyledAddExpense>
    </VModal>
  );
};

export default AddExpenseModal;
