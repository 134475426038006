import {
  mapFromSalesDetailAPI,
  useGetBusinessSalesDetail,
} from "api/expense-sales/get-sales-detail";
import { SalesDetailAPI } from "api/expense-sales/types";
import { extractFetchedData } from "utils";
import { useGetSalesReceiptPDFHook } from "./../../api/expense-sales/get-sales-receipt-pdf";

export const useSalesDetailPageHook = (saleId: string) => {
  const [fetchedData] = useGetBusinessSalesDetail({
    businessSaleId: saleId as string,
  });
  const { handleDownloadPDF, downloading } = useGetSalesReceiptPDFHook();

  const { extractedData, fetching } = extractFetchedData<SalesDetailAPI>({
    result: fetchedData,
    key: "getBusinessSaleDetail",
  });

  const saleItems = mapFromSalesDetailAPI(extractedData || {});

  return {
    handleDownloadPDF,
    downloading,
    fetching,
    saleItems,
    salesDetail: extractedData,
  };
};
