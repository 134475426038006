const UserRolesIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="4.5 0.75 15 22.5"
      {...props}
    >
      <path
        d="M13.5 3.75V0.75H10.5V3.75H4.5V23.25H19.5V3.75H13.5ZM12 1.5C12.0985 1.50002 12.1961 1.51945 12.2871 1.55718C12.3781 1.5949 12.4608 1.65018 12.5304 1.71986C12.6001 1.78954 12.6553 1.87225 12.693 1.96328C12.7306 2.0543 12.75 2.15186 12.75 2.25038C12.75 2.34889 12.7305 2.44644 12.6928 2.53745C12.6551 2.62845 12.5998 2.71114 12.5301 2.78078C12.4605 2.85043 12.3777 2.90566 12.2867 2.94334C12.1957 2.98102 12.0981 3.0004 11.9996 3.00038C11.8007 3.00033 11.6099 2.92124 11.4692 2.78052C11.3286 2.63979 11.2496 2.44896 11.2496 2.25C11.2497 2.05104 11.3288 1.86024 11.4695 1.71959C11.6102 1.57894 11.801 1.49995 12 1.5ZM18.75 22.5H5.25V4.5H10.5V6.75H13.5V4.5H18.75V22.5Z"
        fill="#34A853"
      />
      <path
        d="M12 8.25C9.92887 8.25 8.25 9.92888 8.25 12C8.25 14.0711 9.92887 15.75 12 15.75C14.0711 15.75 15.75 14.0711 15.75 12C15.75 9.92888 14.0711 8.25 12 8.25ZM12 15C10.3459 15 9 13.6541 9 12C9 10.3459 10.3459 9 12 9C13.6541 9 15 10.3459 15 12C15 13.6541 13.6541 15 12 15ZM9 17.25C8.60218 17.25 8.22064 17.408 7.93934 17.6893C7.65804 17.9706 7.5 18.3522 7.5 18.75V20.25H16.5V18.75C16.5 18.3522 16.342 17.9706 16.0607 17.6893C15.7794 17.408 15.3978 17.25 15 17.25H9Z"
        fill="#34A853"
      />
    </svg>
  );
};
export default UserRolesIcon;
