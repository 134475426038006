import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "routes";
import { hardRefresh } from "utils";
import CrashSvg from "../../../assets/images/svgs/app-crash.svg";
import VButton from "../VButton";
import { StyledVCrashScreenWrapper } from "./styles";

const VCrashScreen = () => {
  const navigate = useNavigate();

  const navigateToDashboard = () => {
    navigate(APP_ROUTES.DASHBOARD);
    hardRefresh();
  };

  return (
    <StyledVCrashScreenWrapper>
      <div className="img-container">
        <img src={CrashSvg} alt="crash png" />
      </div>

      <div className="crash-screen-align">
        <h1>Oops</h1>
        <p>Unknown server error</p>
      </div>
      <div className="crash-screen-align">
        <VButton
          onClick={hardRefresh}
          text="Reload Page"
          width="220px"
          mobWidth="220px"
        />
        <VButton
          onClick={navigateToDashboard}
          text="Return to Dashboard"
          isOutline
          width="220px"
          mobWidth="220px"
        />
      </div>
    </StyledVCrashScreenWrapper>
  );
};

export default VCrashScreen;
