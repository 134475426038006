import { IPagination } from "./../types";
import { gql, useQuery } from "urql";
import { useVAppSelector } from "hooks/useStore";
import { SEARCH_TYPE } from "store";
import { useLazyQuery } from "hooks";
import { useMemo } from "react";

type useGetBusinessInvoicesProps = {
  businessCustomerId?: string;
  businessPaymentId?: string;
  page?: number;
  status?: string;
  search?: string;
};

export interface IBusinessInvoices extends IPagination {
  nodes: {
    _id: string;
    businessCustomerId: string;
    businessPaymentId: string;
    clientName: string;
    invoiceStatus: string;
    invoiceTotal: string;
    invoiceNumber: string;
    createdAt: Date;
    transactionType: string;
    amountDue: number;
  }[];
}

const getBusinessInvoicesQuery = gql`
  query (
    $page: Int
    $status: INVOICESTATUS
    $searchQuery: String
    $businessCustomerId: String
  ) {
    getBusinessInvoices(
      filters: {
        status: $status
        searchQuery: $searchQuery
        businessCustomerId: $businessCustomerId
      }
      metadata: { size: 10, page: $page }
    ) {
      nodes {
        _id
        businessPaymentId
        amountDue
        transactionType
        businessCustomerId
        clientName
        invoiceStatus
        createdAt
        transactionType
        invoicePayments {
          amountPaid
        }
        invoiceTotal
        invoiceNumber
      }
      pageInfo {
        currentPage
        size
        totalCount
        hasNextPage
      }
    }
  }
`;

export const useGetBusinessInvoices = ({
  businessPaymentId,
  page = 1,
  status,
  search = "",
  businessCustomerId,
}: useGetBusinessInvoicesProps) => {
  const { page: invoicePage, term: searchTerm } = useVAppSelector(
    (state) => state.search
  );
  return useQuery({
    query: getBusinessInvoicesQuery,
    variables: {
      businessCustomerId,
      businessPaymentId,
      page,
      status,
      searchQuery: invoicePage === SEARCH_TYPE.INVOICES ? searchTerm : search,
    },
  });
};

export const useLazyGetBusinessInvoices = () => {
  const context = useMemo(() => ({ additionalTypenames: ["lazyInvoice"] }), []);
  return useLazyQuery({
    query: getBusinessInvoicesQuery,
    requestPolicy: "network-only",
    context,
  });
};
