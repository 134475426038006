import { showToast } from "components/common";
import { gql, useMutation } from "urql";
import { handleRequestCall } from "utils/handleRequestCall";
import { useInvoicePageHook } from "hooks";

const payForInvoiceMutation = gql`
  mutation ($invoiceId: String, $payments: [PaymentInformation]) {
    payForInvoice(invoiceId: $invoiceId, payments: $payments) {
      data {
        _id
      }
    }
  }
`;

export type IPayForInvoice = {
  invoiceId?: string;
  payments: Payment;
};
export interface Payment {
  paymentType: PAYMENTTYPE | string;
  paymentAmount: number;
  paymentStatus: PAYMENTSTATUS | string;
}
export enum PAYMENTTYPE {
  CASH,
  POS,
  BANK,
}
export enum PAYMENTSTATUS {
  FULL,
  PART,
}

export const usePayForInvoice = (invoiceId: string) => {
  const [invoice, payForInvoice] = useMutation(payForInvoiceMutation);
  const { refetchBusinessInvoices, refetchInvoiceSummary } =
    useInvoicePageHook();

  const handlePayForInvoice = async (
    { payments }: IPayForInvoice,
    callBack?: () => void
  ) => {
    try {
      const values = {
        invoiceId,
        payments: payments,
      };
      const req = await payForInvoice(values);

      await handleRequestCall({
        request: req,
        callBack: () => {
          refetchBusinessInvoices();
          refetchInvoiceSummary();
          callBack?.();
        },
      });
    } catch (e) {
      console.error(e);
      showToast({
        type: "error",
        title: "An error occurred",
        subText: "Please try again",
      });
    }
  };

  return {
    handlePayForInvoice,
    invoice,
    isLoading: invoice?.fetching,
  };
};
