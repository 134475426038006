const ExportIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0.5 0 11.7 13"
      {...props}
    >
      <path
        d="M12.148 8.853C12.1151 8.77423 12.0689 8.70168 12.0115 8.6385L10.0615 6.6885C9.9391 6.5661 9.7731 6.49734 9.6 6.49734C9.4269 6.49734 9.2609 6.5661 9.1385 6.6885C9.0161 6.8109 8.94734 6.9769 8.94734 7.15C8.94734 7.3231 9.0161 7.4891 9.1385 7.6115L9.9835 8.45H6.35C6.17761 8.45 6.01228 8.51848 5.89038 8.64038C5.76848 8.76228 5.7 8.92761 5.7 9.1C5.7 9.27239 5.76848 9.43772 5.89038 9.55962C6.01228 9.68152 6.17761 9.75 6.35 9.75H9.9835L9.1385 10.5885C9.07758 10.6489 9.02922 10.7208 8.99622 10.8C8.96322 10.8792 8.94623 10.9642 8.94623 11.05C8.94623 11.1358 8.96322 11.2208 8.99622 11.3C9.02922 11.3792 9.07758 11.4511 9.1385 11.5115C9.19893 11.5724 9.27082 11.6208 9.35003 11.6538C9.42923 11.6868 9.51419 11.7038 9.6 11.7038C9.68581 11.7038 9.77077 11.6868 9.84997 11.6538C9.92918 11.6208 10.0011 11.5724 10.0615 11.5115L12.0115 9.5615C12.0717 9.50048 12.1182 9.42737 12.148 9.347C12.213 9.18875 12.213 9.01125 12.148 8.853ZM7.65 11.7H2.45C2.27761 11.7 2.11228 11.6315 1.99038 11.5096C1.86848 11.3877 1.8 11.2224 1.8 11.05V1.95C1.8 1.77761 1.86848 1.61228 1.99038 1.49038C2.11228 1.36848 2.27761 1.3 2.45 1.3H5.7V3.25C5.7 3.76717 5.90545 4.26316 6.27114 4.62886C6.63684 4.99455 7.13283 5.2 7.65 5.2H10.25C10.3783 5.19936 10.5036 5.16075 10.61 5.08903C10.7164 5.01731 10.7992 4.9157 10.848 4.797C10.8978 4.67863 10.9114 4.54816 10.8871 4.42207C10.8628 4.29598 10.8017 4.17991 10.7115 4.0885L6.8115 0.1885C6.75777 0.137941 6.69633 0.0962526 6.6295 0.0649999H6.571L6.389 0H2.45C1.93283 0 1.43684 0.205446 1.07114 0.571142C0.705446 0.936838 0.5 1.43283 0.5 1.95V11.05C0.5 11.5672 0.705446 12.0632 1.07114 12.4289C1.43684 12.7946 1.93283 13 2.45 13H7.65C7.82239 13 7.98772 12.9315 8.10962 12.8096C8.23152 12.6877 8.3 12.5224 8.3 12.35C8.3 12.1776 8.23152 12.0123 8.10962 11.8904C7.98772 11.7685 7.82239 11.7 7.65 11.7ZM7 2.2165L8.6835 3.9H7.65C7.47761 3.9 7.31228 3.83152 7.19038 3.70962C7.06848 3.58772 7 3.42239 7 3.25V2.2165Z"
        fill="#E57A1A"
      />
    </svg>
  );
};

export default ExportIcon;
