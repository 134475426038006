import { IPagination } from "../types";
import { gql, useQuery } from "urql";

export type UseGetImportFilesAndStatsType = { page: number; status?: string };

export const useGetImportedFilesAndStats = ({
  page,
  status,
}: UseGetImportFilesAndStatsType) => {
  return useQuery({
    query: combinedQuery,
    variables: {
      page,
      status,
      size: 10,
    },
  });
};

const combinedQuery = gql`
  query ($size: Int, $page: Int, $status: ImportStatus) {
    getImportStats {
      status
      count
    }
    getImports(size: $size, page: $page, status: $status) {
      nodes {
        _id
        status
        importFile
        uploadCount
        failedCount
        uploadErrors
        duplicateHandling
        type
        createdAt
      }
      pageInfo {
        currentPage
        size
        totalCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const useGetImportedFilesAndStatsHook = ({
  page,
  status,
}: UseGetImportFilesAndStatsType) => {
  const [result, refetchAll] = useGetImportedFilesAndStats({ page, status });
  return {
    result,
    refetchAll,
    data: (result?.data || {
      getImportStats: [],
      getImports: {},
    }) as IImportFileAndStats,
  };
};

export interface IImportStats {
  status: string;
  count: number;
}

type IImportFile = {
  _id: string;
  status: string;
  importFile: string;
  uploadCount: number;
  failedCount: number;
  uploadErros: string;
  duplicateHandling: string;
  type: string;
  createdAt: string;
};

export interface IImportsFile extends IPagination {
  nodes: IImportFile[];
}

export interface IImportFileAndStats {
  getImportStats: IImportStats[];
  getImports: IImportsFile;
}
