import { useLocalSearchParams } from "hooks";
import { POS_REQUESTED_COLUMNS } from "components/pos";
import { ICustomerTerminals, useGetCustomerTerminals } from "api";
import { useMemo, useState } from "react";
import { extractFetchedData } from "utils";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "routes";

export const usePosRequestedHook = () => {
  const navigate = useNavigate();
  const { searchParams } = useLocalSearchParams();
  const [posId, setPosId] = useState<string>("");

  // pos request details
  const handleViewPosRequestDetails = (id: string) => {
    setPosId(id);
    navigate(`${APP_ROUTES.POS}/pos-request-details/${id}`);
  };
  const posRequestedColumns = useMemo(() => {
    return POS_REQUESTED_COLUMNS({
      handleViewPosRequestDetails: (id: string) =>
        handleViewPosRequestDetails(id),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [pageNumber, setPageNumber] = useState(
    parseInt(searchParams.get("page") || "1")
  );

  const [result, refetchRequestedPos] = useGetCustomerTerminals({
    size: 10,
    page: pageNumber,
    // status: 'REQUESTED',
  });

  // Handle pagination change
  const handleChangePage = (pageNumber: number) => {
    setPageNumber(pageNumber);
  };

  const extract = extractFetchedData<ICustomerTerminals>({
    result,
    key: "getCustomerTerminals",
  });

  return {
    ...extract,
    pageNumber,
    handleChangePage,
    refetchRequestedPos,
    posRequestedColumns,
    posId,
  };
};
