import { gql, useQuery } from "urql";

const getCustomerSummaryCardsQuery = gql`
  {
    getCustomerSummaryCards
  }
`;

export const useGetCustomerSummaryCards = () => {
  return useQuery({
    query: getCustomerSummaryCardsQuery,
  });
};
