import { SidebarSvgProps } from "../../../components/sidebar/types";

const BagIcon = (props: SidebarSvgProps) => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_2934_108445"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="4"
        width="18"
        height="16"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 4.38184H18V20.0001H0V4.38184Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_2934_108445)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.33475 5.8291C3.92946 5.8291 2.57481 6.0009 2.18606 8.20539L1.47657 13.9965C1.2459 15.6209 1.42419 16.7965 2.00777 17.5078C2.584 18.2105 3.61515 18.5522 5.15912 18.5522H12.8312C13.7943 18.5522 15.1095 18.3505 15.9035 17.3872C16.5339 16.6237 16.7508 15.4867 16.5487 14.0071L15.8327 8.16582C15.5276 6.72672 14.7225 5.8291 13.6896 5.8291H4.33475ZM12.8301 20.0005H5.15808C3.1886 20.0005 1.81649 19.4937 0.962711 18.4523C0.105257 17.408 -0.18148 15.8415 0.110771 13.7972L0.823938 7.98101C1.29264 5.31421 3.00663 4.38184 4.33371 4.38184H13.6885C15.0211 4.38184 16.6413 5.31131 17.1882 7.91828L17.9133 13.8127C18.1734 15.715 17.848 17.241 16.9446 18.3355C16.0458 19.4243 14.6232 20.0005 12.8301 20.0005Z"
          fill="#374B58"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9557 5.61738C12.5753 5.61738 12.2665 5.29308 12.2665 4.89349C12.2665 2.99304 10.7942 1.44778 8.98553 1.44778H8.97175C8.10694 1.44778 7.26419 1.81358 6.65304 2.45157C6.03913 3.09342 5.68714 3.98429 5.68714 4.89349C5.68714 5.29308 5.37834 5.61738 4.99787 5.61738C4.61739 5.61738 4.30859 5.29308 4.30859 4.89349C4.30859 3.60111 4.80855 2.33768 5.67979 1.42558C6.54827 0.51927 7.74576 0 8.96899 0H8.98829C11.5551 0 13.645 2.19483 13.645 4.89349C13.645 5.29308 13.3362 5.61738 12.9557 5.61738Z"
        fill="#374B58"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.712 9.96438H11.6697C11.2893 9.96438 10.9805 9.64008 10.9805 9.24049C10.9805 8.8409 11.2893 8.5166 11.6697 8.5166C12.0502 8.5166 12.3802 8.8409 12.3802 9.24049C12.3802 9.64008 12.0925 9.96438 11.712 9.96438Z"
        fill="#374B58"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.35172 9.96438H6.31037C5.92989 9.96438 5.62109 9.64008 5.62109 9.24049C5.62109 8.8409 5.92989 8.5166 6.31037 8.5166C6.69084 8.5166 7.02078 8.8409 7.02078 9.24049C7.02078 9.64008 6.7322 9.96438 6.35172 9.96438Z"
        fill="#374B58"
      />
    </svg>
  );
};
export default BagIcon;
