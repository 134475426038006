const AirPodsIcon = (props: any) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.14 2.75H6.86C4.59011 2.75 2.75 4.59011 2.75 6.86V17.14C2.75 19.4099 4.59011 21.25 6.86 21.25H17.14C19.4099 21.25 21.25 19.4099 21.25 17.14V6.86C21.25 4.59011 19.4099 2.75 17.14 2.75Z"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.8791 8.92004H3.11914"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9996 17.65C11.7278 17.65 11.467 17.542 11.2748 17.3498C11.0826 17.1575 10.9746 16.8968 10.9746 16.625C10.9746 16.3531 11.0826 16.0924 11.2748 15.9002C11.467 15.708 11.7278 15.6 11.9996 15.6C12.2715 15.6 12.5322 15.708 12.7244 15.9002C12.9166 16.0924 13.0246 16.3531 13.0246 16.625C13.0246 16.8968 12.9166 17.1575 12.7244 17.3498C12.5322 17.542 12.2715 17.65 11.9996 17.65Z"
        fill="#515F68"
      />
      <path
        d="M15.0842 6.67766H10.4208C10.2904 6.67766 10.1845 6.57175 10.1845 6.4413V5.64309C10.1845 5.46975 10.1166 5.30635 9.99322 5.18319C9.87885 5.07004 9.72999 5.01006 9.57378 5C9.25151 5.03875 9 5.31077 9 5.64332V7.64523H15.0842V6.67766Z"
        fill="#515F68"
      />
    </svg>
  );
};
export default AirPodsIcon;
