import { gql, useMutation } from "urql";
import { uploadMedia } from "utils";
import { showToast } from "components/common";
import { useToggle } from "hooks";
import { handleRequestCall } from "utils/handleRequestCall";

export const importFileProductMutation = gql`
  mutation (
    $type: ProductType
    $file: String
    $duplicateHandling: DuplicateHandlingType
  ) {
    importFile(
      type: $type
      file: $file
      duplicateHandling: $duplicateHandling
    ) {
      success
      data
    }
  }
`;

export const useImportFileProductApi = () => {
  const [customerFileProducts, addCustomerFileProoducts] = useMutation(
    importFileProductMutation
  );

  const [imageProcessing, toggleImageProcessing] = useToggle();

  const handleAddCustomerFileProducts = async (
    data: { file: File; duplicateHandling: string },
    callBack?: (data: Record<string, any>) => void
  ) => {
    const { file: rawFile, duplicateHandling } = data;
    toggleImageProcessing();
    let processedFile: null | string = null;

    if (!rawFile) {
      showToast({
        type: "error",
        title: "Please select a file to upload",
        subText: "Select a file and try again",
      });
    }

    try {
      const uploadedFile = await uploadMedia(rawFile as File);

      if (!uploadedFile) {
        toggleImageProcessing();
        showToast({
          type: "error",
          title: "Please select a file to upload",
          subText: "Select a file and try again",
        });
        return;
      }

      processedFile = uploadedFile[0]?.url;
      toggleImageProcessing();

      const values = {
        type: "PRODUCT",
        duplicateHandling,
        file: processedFile,
      };
      const req = await addCustomerFileProoducts(values);

      await handleRequestCall({ request: req, callBack });
    } catch (e) {
      showToast({
        type: "error",
        title: "An error occured",
        subText: "Please try again",
      });
      console.error(e);
    }
  };

  return {
    handleAddCustomerFileProducts,
    isLoading: imageProcessing || customerFileProducts.fetching,
  };
};
