import { useForm } from "react-hook-form";
import { useBusinessRegistrationHook } from "hooks";
import { useMemo } from "react";
import { ADDRESS_FORM } from "components/pos";
import { useAuthUser } from "store";
import { useUpdateBusiness } from "api";
// import { showToast } from 'components/common';
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "routes";

export const usePosSurveyHook = () => {
  const { userData } = useAuthUser();
  const hookForm = useForm();
  const navigate = useNavigate();

  const { statesAndCities } = useBusinessRegistrationHook();

  const { handleSubmit, reset, watch } = hookForm;

  const stateId = (watch("stateId") || "") as string;

  const { handleUpdateBusiness, isLoading } = useUpdateBusiness();

  const onSubmitSurvey = async (data: any) => {
    const state = statesAndCities[Number(stateId)];
    const city = state?.cities[Number(data.cityId)];

    const newData = {
      ...data,
      businessId: userData?.businessId as string,
      state: state?.stateName,
      city: city?.cityName,
      posRequired: Number(data?.posRequired),
    };

    const addressData = {
      address: data?.deliveryAddress,
      city: city?.cityName,
      state: state?.stateName,
    };

    await handleUpdateBusiness(newData, () => {
      reset({
        avgMonthlyRevenue: "",
        dailySale: "",
        posRequired: "",
        deliveryAddress: "",
        state: "",
        city: "",
      });
      // showToast({
      //   subText: `Submitted successfully`,
      // });
      navigate(APP_ROUTES.REQUEST_POS, { state: addressData });
    });
  };

  //State and cities
  const statesAndCitiesList = useMemo(() => {
    return ADDRESS_FORM({
      statesAndCities,
      stateId,
    });
  }, [statesAndCities, stateId]);

  return {
    hookForm,
    statesAndCitiesList,
    handleSubmit,
    onSubmitSurvey,
    isLoading,
  };
};
