import styled from "styled-components/macro";
import { stylingUtils } from "styles";

const { rem, color } = stylingUtils;

export const StyledVProgressIndicatorContainer = styled("div")`
  height: ${rem(12)};
  width: 100%;
  border-radius: ${rem(20)};
  background-color: ${color("ash01")};
  position: relative;
`;

export const StyledVProgressIndicator = styled("div")`
  position: absolute;
  height: 100%;
  left: 0;
  border-radius: ${rem(20)};
  background-color: ${color("lightBlue")};
  max-width: 100%;
`;
