import styled from "styled-components";

export const StyledCompleteKycModal = styled("div")<{ proofOfIdType?: any }>`
  .button {
    height: 40px;
    border-radius: 10px;
    margin: 0 auto;
    margin-top: 2rem;
    &:disabled {
      background-color: #0077ff;
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .label {
    font-weight: 400;
    line-height: 19px;
    color: #666666;
    margin: 1rem 0;
  }

  .proof-of-id {
    margin-bottom: 1.2rem;
    display: ${({ proofOfIdType }) => (proofOfIdType ? "block" : "none")};
  }
  /* .instructions {
    display: ${({ proofOfIdType }) =>
    proofOfIdType === "VNIN" ? "block" : "none"};
  } */
`;
