import { VButton } from "components/common";
import { EmptyCardStyles, EmptyPosStyles, OrderPosCard } from "./styles";
import RightArrow from "assets/images/svgs/RightArrow";
import { Link } from "react-router-dom";
import { APP_ROUTES } from "routes";

const EmptyPosCard = () => {
  return (
    <EmptyPosStyles>
      <EmptyCardStyles>
        <div>
          <p>No Smart POS Terminal</p>
        </div>
      </EmptyCardStyles>
      <EmptyCardStyles>
        <div>
          <p>No MPOS Terminal</p>
        </div>
      </EmptyCardStyles>
      <OrderPosCard>
        <div>
          <h4>Veedez POS</h4>
          <h2>
            Collect digital payment <br /> with Veedez POS
          </h2>
          <p>
            Collect digital payment with <br /> alerzo pay
          </p>
          <Link to={APP_ROUTES.POS_SURVEY}>
            <VButton
              text="Order a smart POS"
              className="relative"
              align="left"
              isSlim
              bgColor="#fff"
              textColor="#001928"
              icon={<RightArrow className="icon" />}
            />
          </Link>
        </div>
      </OrderPosCard>
    </EmptyPosStyles>
  );
};

export default EmptyPosCard;
