import styled from "styled-components/macro";
import { FlexJAMixin, StyledVCardContainer, stylingUtils } from "styles";

const { rem } = stylingUtils;

export const StyledRolesAndPermissionSelectionContainer = styled(
  StyledVCardContainer
)``;

export const StyledPermissionModuleContainer = styled("div")`
  display: flex;
  gap: ${rem(20)};
  align-items: flex-start;
`;

export const StyledAddRolesModal = styled("div")`
  flex-direction: column;
  ${FlexJAMixin};
  gap: ${rem(10)};
`;
