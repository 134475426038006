import { lazy } from "react";

export * from "./constants";
export const SalesReportGraph = lazy(() => import("./sales-report-graph"));
export const SalesReportCustomers = lazy(
  () => import("./sales-report-customers")
);
export const SalesReportTopSellingCustomers = lazy(
  () => import("./sales-report-top-selling-customers")
);
export const SalesReportPaymentAnalysis = lazy(
  () => import("./sales-report-payment-analysis")
);

export const ProfitAndLossGraph = lazy(() => import("./profit-loss-graph"));
