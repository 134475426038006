import styled from "styled-components";
import { stylingUtils } from "styles";

const {
  rem,
  mediaQuery: { down },
} = stylingUtils;

export const PaymentChannel = styled("div")`
  .cards {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
  }

  .card {
    width: 120px;
    height: 100px;
    //   selectedCard ? ' #E5F0FD' : '#e4e3e6'};
    // background-color: #e4e3e6;
    border-radius: 12px;
    display: grid;
    place-content: center;
    cursor: pointer;

    // &:hover {
    //   outline: blue;
    // }
  }

  .card-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${rem(6)};
    text-align: center;
    font-size: ${rem(14)};
  }

  h4 {
    font-family: "Gilmer";
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #434343;
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .icon-div {
    background-color: #130f26;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    display: grid;
    place-content: center;
  }
`;

export const StyledModalForm = styled("div")`
  .modal__form {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0 ${rem(20)};
  }
`;

export const StyledModalContainer = styled("div")`
  .form--button {
    display: flex;
    justify-content: center;
    margin-top: ${rem(20)};
  }
  .button {
    height: ${rem(50)};
    min-width: ${rem(141)};
    ${down("laptop")} {
      width: 100%;
      border-radius: ${rem(10)};
      font-size: ${rem(16)};
      line-height: ${rem(22)};
    }
  }
`;
