import { gql, useQuery } from "urql";
import { BusinessStaffNodeQuery } from "./get-business-staffs";

type UseGetBusinessStaffDetailProps = {
  businessStaffId: string;
  pause?: boolean;
};

export const useGetBusinessStaffDetail = ({
  businessStaffId,
  pause = false,
}: UseGetBusinessStaffDetailProps) => {
  return useQuery({
    query: getBusinessStaffDetailQuery,
    variables: { businessStaffId },
    pause,
  });
};

const getBusinessStaffDetailQuery = gql`
  ${BusinessStaffNodeQuery}
  query ($businessStaffId: String) {
    getBusinessStaffDetail(filters: { businessStaffId: $businessStaffId }) {
      ...BusinessStaffNodeQuery
    }
  }
`;
