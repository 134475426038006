// Core
import { gql, useMutation } from "urql";

// Custom Components
import { showToast } from "components/common";

// Assets
import { handleRequestCall } from "utils/handleRequestCall";
import { uploadMedia } from "utils";

// types
export type KycLevelTwoProps = {
  proofOfIdType: any;
  proofOfId: string;
  proofOfAddressType: any;
  proofOfAddress: string;
  passcode: string;
};

// GraphQL
const completeBusinessLevelTwoMutation = gql`
  mutation (
    $proofOfIdType: ProofOfIdType
    $proofOfId: String
    $proofOfAddressType: ProofOfAddressType
    $proofOfAddress: String
    $passcode: String
  ) {
    completeBusinessLevelTwo(
      proofOfIdType: $proofOfIdType
      proofOfId: $proofOfId
      proofOfAddressType: $proofOfAddressType
      proofOfAddress: $proofOfAddress
      passcode: $passcode
    ) {
      success
    }
  }
`;

// Functionality
export const useCompleteBusinessLevelTwo = () => {
  const [completeBusinessLevelTwo, setCompleteBusinessLevelTwo] = useMutation(
    completeBusinessLevelTwoMutation
  );

  const handleCompleteBusinessLevelTwo = async (
    data: KycLevelTwoProps,
    callBack?: (data: Record<string, any>) => void
  ) => {
    try {
      const {
        proofOfIdType,
        proofOfId,
        proofOfAddressType,
        proofOfAddress,
        passcode,
      } = data;

      let processedProofOfId: string | null = null;

      let processedProofOfAddress: string | null = null;

      if (proofOfId && typeof proofOfId !== "string") {
        const uploadedProofOfId = await uploadMedia(proofOfId);
        if (!uploadedProofOfId) {
          showToast({
            type: "error",
            title: "Image upload failed",
            subText: "Please try again",
          });
          return;
        }
        processedProofOfId = uploadedProofOfId?.[0]?.url;
      }

      if (proofOfAddress) {
        const uploadedProofOfAddress = await uploadMedia(proofOfAddress);
        if (!uploadedProofOfAddress) {
          showToast({
            type: "error",
            title: "Image upload failed",
            subText: "Please try again",
          });
          return;
        }
        processedProofOfAddress = uploadedProofOfAddress?.[0]?.url;
      }

      const requestData = {
        proofOfIdType,
        proofOfId: processedProofOfId || proofOfId,
        proofOfAddressType,
        proofOfAddress: processedProofOfAddress,
        passcode,
      };

      const req = await setCompleteBusinessLevelTwo(requestData);
      await handleRequestCall({ request: req, callBack });
    } catch (e) {
      showToast({
        type: "error",
        title: "An error occurred",
        subText: "Please try again",
      });
      console.error(e);
    }
  };

  return {
    completeBusinessLevelTwo,
    handleCompleteBusinessLevelTwo,
  };
};
