import { INVENTORY_TYPES } from "./constants";
import { IPagination } from "../types";
import { gql, useQuery } from "urql";
import { useAuthUser } from "store";

type UseGetCustomerCategories = {
  businessId?: string;
  inventoryType: INVENTORY_TYPES;
  size?: number;
  pause?: boolean;
};

export interface ICustomerCategories extends IPagination {
  nodes: {
    _id: string;
    name: string;
    businessId: string;
  }[];
}

const getCustomerCategoriesQuery = gql`
  query ($inventoryType: INVENTORYTYPEENUM!, $businessId: String!) {
    getCategories(
      filter: { inventoryType: $inventoryType, businessId: $businessId }
      metadata: { size: -1 }
    ) {
      nodes {
        _id
        name
        businessId
      }
      pageInfo {
        size
        totalCount
      }
    }
  }
`;

export const useGetCustomerCategories = ({
  businessId,
  inventoryType,
  size = 50,
  pause = false,
}: UseGetCustomerCategories) => {
  const { userData } = useAuthUser();
  const userBusinessId = userData?.business?._id;

  return useQuery({
    query: getCustomerCategoriesQuery,
    variables: {
      businessId: userBusinessId ?? businessId,
      inventoryType,
      size,
    },
    pause,
  });
};
