import { APP_ROUTES } from "routes";
import {
  formatNumberToCurrency,
  convertToOptions,
  getShortMonthDateFormat,
} from "utils";
// import { SliderArrow } from './pos-details/styles';
import RightArrow from "assets/images/svgs/RightArrow";
import {
  InputList,
  InputListType,
  CustomTableProps,
} from "components/common/types";
import { IStateAndCities } from "api";
import { VBadge, VButton } from "components/common";
import { useRequestedPosDetailsHook } from "hooks";
import { SliderArrow } from "./styles";

export interface IPosCard {
  _id: string;
  index: number;
  terminalName: string;
  tid: string;
  amount: number;
  spec?: any;
  active?: any;
  action?: () => void;
}
export interface IErrorModal {
  imageSrc: string;
  imageAlt: string;
  title: string;
  subTitle: string;
  buttonText: string;
  buttonLink: string;
  buttonDisabled?: boolean;
}
export interface IPosStatus {
  done: boolean;
  title: string;
  date: string;
  rejected: boolean;
  index: number;
}

export const POS_PAGE_TABS = [
  {
    key: "pos-details",
    label: "Terminal Details",
    link: `${APP_ROUTES.POS_DETAILS}`,
    secondaryLink: APP_ROUTES.POS_DETAILS,
  },
  {
    key: "pos-settings",
    label: "Terminal Settings",
    link: `${APP_ROUTES.POS_SETTINGS}`,
  },
  {
    key: "pos-requested",
    label: "Terminal Requested",
    link: `${APP_ROUTES.POS_REQUESTED}`,
  },
];

// terminal transactions table
export const TERMINAL_TRANSACTIONS_COLUMN = (): CustomTableProps["columns"] => {
  return [
    {
      headerName: "S/N",
      key: "Sn",
      width: 150,
    },
    {
      headerName: "Terminal ID",
      key: "tId",
    },
    {
      headerName: "POS No",
      key: "terminalName",
    },
    {
      headerName: "Amount (NGN)",
      key: "transactionAmount",
      formatter: (value: number) =>
        formatNumberToCurrency({ number: value || 0 }),
    },
    {
      headerName: "Transaction Type",
      key: "transactionType",
    },
    {
      headerName: "Status",
      key: "status",
      useZero: false,
      renderCell: (value: string) => <VBadge status={"Completed"} />,
    },
  ];
};

// react slick slider settings
export const settings = {
  infinite: false,
  speed: 700,
  slidesToShow: 3,
  centerPadding: "-10px",
  mobileFirst: true,
  arrows: false,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        arrows: false,
        centerPadding: "10px",
        slidesToShow: 2.2,
      },
    },
    {
      breakpoint: 1100,
      settings: {
        arrows: false,
        centerPadding: "20px",
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 830,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: "5px",
        slidesToShow: 1.2,
      },
    },
    {
      breakpoint: 776,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: "10px",
        slidesToShow: 1.2,
      },
    },
    {
      breakpoint: 580,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: "20px",
        slidesToShow: 1,
      },
    },
  ],
};

// custom arrows
export const renderArrows = (sliderRef: any) => {
  return (
    <SliderArrow className="slider-arrow">
      <button
        className="arrow-btn prev"
        onClick={() => sliderRef.current.slickPrev()}
      >
        <RightArrow style={{ transform: "rotate(180deg)", fill: "#fff" }} />
      </button>
      <button
        className="arrow-btn next"
        onClick={() => sliderRef.current.slickNext()}
      >
        <RightArrow style={{ fill: "#fff" }} />
      </button>
    </SliderArrow>
  );
};

export const tableDetails = [
  {
    item: "Android Mobile",
    quantity: 2,
    amount: 25000,
  },
];

export const REVENUE_OPTIONS = [
  { value: "Above ₦ 1,000,000", label: "Above ₦ 1,000,000" },
  { value: "₦ 700,000 - ₦ 1,000,000", label: "₦ 700,000 - ₦ 1,000,000" },
  { value: "₦ 500,000 - ₦ 700,000", label: "₦ 500,000 - ₦ 700,000" },
  { value: "₦ 300,000 - ₦ 500,000", label: "₦ 300,000 - ₦ 500,000" },
];
export const SALES_OPTIONS = [
  { value: "Above 1000 sales", label: "Above 1000 sales" },
  { value: "100 - 1000 sales", label: "100 - 1000 sales" },
  { value: "50 - 100 sales", label: "50 - 100 sales" },
  { value: "10 - 50 sales", label: "10 - 50 sales" },
];

export const POS_SURVEY_FORM: InputListType[] = [
  {
    name: "avgMonthlyRevenue",
    label: "What is your average monthly revenue?",
    inputType: "select",
    options: REVENUE_OPTIONS,
    placeholder: "Select your monthly revenue",
    required: true,
    validation: { required: "Please select monthly revenue" },
  },
  {
    name: "dailySale",
    label: "How many sales do you make daily?",
    inputType: "select",
    options: SALES_OPTIONS,
    placeholder: "Select sales option",
    required: true,
    validation: { required: "Please select sales option" },
  },
  {
    name: "posRequired",
    label: "How many Terminal devices do you need?",
    type: "number",
    placeholder: "Enter quantity",
    required: true,
    validation: { required: "Please enter quantity" },
  },
  {
    name: "deliveryAddress",
    label: "What store should we deliver the Terminal to?",
    inputType: "text",
    placeholder: "Enter Address",
    required: true,
    validation: { required: "Please enter email address" },
  },
];

export const ADDRESS_FORM = ({
  statesAndCities,
  stateId,
}: {
  statesAndCities: IStateAndCities[];
  stateId: string;
}): InputList => {
  const states = convertToOptions({
    records: statesAndCities,
    label: "stateName",
    value: "_id",
    useIndex: true,
  });

  const selectedCities = statesAndCities[Number(stateId)]?.cities || [];

  const cities = convertToOptions({
    records: selectedCities,
    label: "cityName",
    value: "_id",
    useIndex: true,
  });

  return [
    {
      name: "stateId",
      label: "State",
      inputType: "select",
      options: states,
      placeholder: "Select state",
      required: true,
      validation: { required: "Please select state" },
    },
    {
      name: "cityId",
      label: "City",
      inputType: "select",
      options: cities,
      placeholder: "Select city",
      required: true,
      validation: { required: "Please select city" },
    },
  ] as const;
};

export const RECURRING_POS_MOCK_DATA = Array.from({ length: 8 }).map(
  (p, id) => ({
    Sn: id + 1,
    terminalId: "#759474938",
    posNo: "POS 1",
    amount: 10900,
    transactionType: "Withdrawal",
    issuerBank: "Guaranty Bank",
    status: "Successful",
  })
);

export const RECURRING_POS_COLUMNS = (): CustomTableProps["columns"] => {
  return [
    {
      headerName: "S/N",
      key: "Sn",
      width: 150,
    },
    {
      headerName: "Terminal ID",
      key: "terminalId",
    },
    {
      headerName: "POS No",
      key: "posNo",
    },
    {
      headerName: "Amount (NGN)",
      key: "amount",
      formatter: (value: number) =>
        formatNumberToCurrency({ number: value || 0 }),
    },
    {
      headerName: "Transaction Type",
      key: "transactionType",
    },
    {
      headerName: "Issuer Bank",
      key: "issuerBank",
    },
    {
      headerName: "Status",
      key: "status",
      useZero: false,
      renderCell: (value: string) => <VBadge status={value || ""} />,
    },
  ];
};

// card details
export const CARD_DETAILS = [
  {
    index: 1,
    terminalId: "#759474938",
    amount: 102380,
    assignedTo: "Chinoso Kaodiechi",
  },
  {
    index: 2,
    terminalId: "#759474938",
    amount: 62900,
    assignedTo: "Chinoso Kaodiechi",
  },
  {
    index: 3,
    terminalId: "#759474938",
    amount: 90500,
    assignedTo: "Chinoso Kaodiechi",
  },
];

export const POS_CHARGES = [
  {
    id: "1",
    value: "BUSINESS",
    header: "Business Owner",
    text: "Business owner bears the cost of POS charges related to transactions (e.g transfers, deposits & withdrawals)",
  },
  {
    id: "2",
    header: "Customer",
    value: "CUSTOMER",
    text: "Customer bears the cost of POS charges related to transactions (e.g transfers, deposits & withdrawals)",
  },
];

export const POS_TRANSACTION_COLUMN = (): CustomTableProps["columns"] => {
  return [
    {
      headerName: "S/N",
      key: "Sn",
      width: 150,
    },
    {
      headerName: "Terminal ID",
      key: "tId",
    },
    {
      headerName: "POS",
      key: "terminalName",
    },
    {
      headerName: "Amount",
      key: "transactionAmount",
      formatter: (value: number) =>
        formatNumberToCurrency({ number: value || 0 }),
    },
    {
      headerName: "Transaction Type",
      key: "transactionType",
    },
    {
      headerName: "Status",
      key: "status",
      useZero: false,
      renderCell: (value: string) => <VBadge status={"Completed"} />,
    },
  ];
};

export const POS_REQUESTED_COLUMNS = ({
  handleViewPosRequestDetails,
}: {
  handleViewPosRequestDetails: (_id: string) => void;
}): CustomTableProps["columns"] => {
  return [
    {
      headerName: "S/N",
      key: "",
      width: 150,
    },
    {
      headerName: "Date",
      key: "createdAt",
      formatter: (value: string) => {
        return getShortMonthDateFormat(value);
      },
    },
    {
      headerName: "Activity",
      key: "acitivty",
      renderCell: () => {
        return <p>Terminal Request</p>;
      },
    },
    {
      headerName: "POS Type",
      key: "spec.name",
      renderCell: (value: string) =>
        value === "Veedez Maxi"
          ? "Android POS Terminal"
          : value === "Veedez Mini"
          ? "MPOS Terminal"
          : null,
    },
    {
      headerName: "Amount (NGN)",
      key: "spec.price",
      formatter: (value: number) => {
        return formatNumberToCurrency({ number: value || 0 });
      },
    },
    {
      headerName: "Status",
      key: "status",
      useZero: false,
      renderCell: (value: string) => <VBadge status={value || ""} />,
    },
    {
      headerName: "",
      key: "_id",
      width: 80,
      useZero: false,
      renderCell: (_id) => (
        <VButton
          text="View"
          isSlim
          bgColor="transparent"
          textColor="#0077FF"
          onClick={() => handleViewPosRequestDetails(_id)}
        />
      ),
    },
  ];
};

export const POS_STATUS_CARDS = () => {
  const { requestDate, status } = useRequestedPosDetailsHook();

  return [
    {
      index: 1,
      title: "Terminal Requested",
      date: getShortMonthDateFormat(requestDate),
      done: true,
      rejected: false,
    },
    {
      index: 2,
      title: status === "REJECTED" ? "Request Rejected" : "Request Approved",
      date: "",
      done: ["APPROVED", "ASSIGNED", "DISPATCH", "DELIVERED"].includes(status),
      rejected: status === "REJECTED",
    },
    {
      index: 3,
      title: "Terminal Mapped",
      date: "",
      done: ["ASSIGNED", "DISPATCH", "DELIVERED"].includes(status),
      rejected: false,
    },
    {
      index: 4,
      title: "Terminal Dispatched",
      date: "",
      done: ["DISPATCH", "DELIVERED"].includes(status),
      rejected: false,
    },
    {
      index: 5,
      title: "Terminal Delivered",
      date: "",
      done: status === "DELIVERED",
      rejected: false,
    },
  ];
};
