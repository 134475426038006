import styled from "styled-components";
import { FlexJAMixin, stylingUtils } from "styles";

const {
  rem,
  mediaQuery: { down },
} = stylingUtils;

export const StyledDashboardSummaryCards = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  width: 100%;
  gap: 1rem;

  ${down("laptop")} {
    flex-wrap: wrap;
  }

  @media (max-width: 600px) {
    flex-wrap: nowrap;
    overflow-x: scroll;
  }
`;

export const StyledDashboardIconWrapper = styled("div")<{ color: string }>`
  height: ${rem(40)};
  width: ${rem(40)};
  ${FlexJAMixin};
  border-radius: ${rem(5)};
  background-color: ${({ color }) => color};

  svg {
    width: ${rem(20)}!important;
    height: ${rem(20)}!important;
  }
`;

export const StyledDashboardCardAction = styled("button")`
  display: flex;
  align-items: center;
  gap: ${rem(5)};

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  p {
    text-align: left;
  }

  svg {
    width: ${rem(10)}!important;
    height: ${rem(10)}!important;
    transform: rotate(-90deg);
  }
`;
