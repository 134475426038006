import { showToast } from "components/common";
import { useForm } from "react-hook-form";
import { useDeleteInventoryService } from "api/inventory/delete-inventory-service";
import { useServicePageHook } from "../useServicePageHook";
import { ServiceDetailAPI } from "api/inventory/types";

export const useDeleteInventoryServiceHook = (
  selectedService: ServiceDetailAPI | null,
  callBack: Function
) => {
  const hookForm = useForm();

  const { handleSubmit, reset } = hookForm;

  const { handleDeleteInventoryService, inventoryService } =
    useDeleteInventoryService();

  const { refetchCustomerService } = useServicePageHook({});

  const onDeleteService = async (data: any) => {
    await handleDeleteInventoryService(
      {
        ...data,
        serviceId: selectedService?._id,
      },
      () => {
        reset();
        showToast({
          type: "delete",
          subText: `Service Inventory - ${selectedService?.referenceId} was deleted`,
        });
        callBack?.();
        refetchCustomerService();
      }
    );
  };

  return {
    onDeleteService,
    isLoading: inventoryService?.fetching,
    handleSubmit,
    hookForm,
  };
};
