import { useGetUserKycDetails } from "api";
import { useAuthUser } from "store";

export const useGetKycDetailsHook = () => {
  const { userData } = useAuthUser();

  const [kycDetailsResult, refetchKycDetails] = useGetUserKycDetails({
    businessId: userData?.businessId as string,
  });

  return {
    kycDetailsResult,
    refetchKycDetails,
  };
};
