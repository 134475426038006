import styled from "styled-components/macro";
import { stylingUtils } from "styles";

const {
  rem,
  color,
  mediaQuery: { down },
} = stylingUtils;

export const StyledVEmptyStateContainer = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${rem(300)};
  flex-direction: column;
  background-color: ${color("white")};
  border-radius: ${rem(12)};
`;

export const StyledVEmptyStateTitle = styled("h3")`
  font-weight: 700;
  font-size: ${rem(18)};
  margin-bottom: ${rem(12)};
`;

export const StyledVEmptyStateContent = styled("p")`
  color: #515f68;
  font-size: ${rem(16)};

  ${down("mobileS")} {
    font-size: ${rem(14)};
    padding: ${rem(4)};
  }
`;

export const StyledVEmptyStateActionText = styled("div")`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  margin-top: ${rem(12)};
  p {
    font-weight: 600;
    font-size: ${rem(14)};
    line-height: ${rem(17)};
    color: ${color("lightBlue")};
  }
`;
