import { useAddCategory } from "api";
import { showToast } from "components/common";
import { useState } from "react";
import { CATEGORY_FORM_INPUT_LIST } from "components/Inventory";

type InventoryTypes = "PRODUCT" | "SERVICE";

export const useAddCategoryHook = (callBack?: Function, req?: any) => {
  const { handleAddCategory, isLoading } = useAddCategory();

  const [inputArray, setInputArray] = useState(CATEGORY_FORM_INPUT_LIST);

  const submitCategories = async (e: any, inventoryType?: InventoryTypes) => {
    e.preventDefault();

    const payload = new FormData(e.target).getAll("categories[]");
    let newArr = [];

    for (let i = 0; i < payload.length; i++) {
      let payloadItem = { name: payload[i] };
      newArr.push(payloadItem);
    }

    await handleAddCategory({ category: newArr, inventoryType }, () => {
      showToast({
        subText: "You have successfully created a new inventory category",
      });
      callBack?.();
    });

    setInputArray([(Math.random() * 500).toString(20)]);
  };

  // add input
  const handleAddInput = (e: any) => {
    const id = (Math.random() * 500).toString(20);
    const copArr = [...inputArray];
    copArr.unshift(id);
    setInputArray(copArr);
  };

  // delete input
  const handleRemoveInput = (id: string) => {
    const result = inputArray.filter((item) => item !== id);
    setInputArray(result);
  };

  return {
    submitCategories,
    isLoading,
    handleRemoveInput,
    handleAddInput,
    inputArray,
    setInputArray,
  };
};
