// Core
import { gql, useMutation } from "urql";

// Custom Components
import { showToast } from "components/common";

// Assets
import { handleRequestCall } from "utils/handleRequestCall";

// GraphQL
const validateBankInfoMutation = gql`
  mutation ($bankCode: String, $accountNumber: String) {
    validateBankInfo(bankCode: $bankCode, accountNumber: $accountNumber) {
      success
      data
    }
  }
`;

// Functionality
export const useValidateBankInfo = () => {
  const [validatedBankInfo, getvalidatedBankInfo] = useMutation(
    validateBankInfoMutation
  );

  const handleValidateBankInfo = async (
    variables: { bankCode: string; accountNumber: string },
    callBack?: (data: Record<string, any>) => void
  ) => {
    try {
      const req = await getvalidatedBankInfo(variables);

      await handleRequestCall({ request: req, callBack });
    } catch (e) {
      showToast({
        type: "error",
        title: "An error ocucured",
        subText: "Please try again",
      });
      console.error(e);
    }
  };

  return {
    validatedBankInfo,
    handleValidateBankInfo,
  };
};
