import styled from "styled-components";
import { stylingUtils } from "styles";

const {
  rem,
  color,
  mediaQuery: { down },
} = stylingUtils;

export const StyledIFAPModal = styled("div")`
  .subtitle {
    margin-bottom: ${rem(20)};
    margin-top: ${rem(20)};
  }

  h5 {
    font-size: ${rem(14)};
    font-weight: normal;
  }

  .btn-submit {
    display: flex;
    justify-content: center;
    margin-top: ${rem(40)};
  }

  .file-upload {
    flex: none;
  }

  .maximum-size {
    color: ${color("orange")};
    margin-top: ${rem(10)};
  }

  .duplicate-handling {
    display: flex;
    flex-direction: column;
    gap: ${rem(10)};

    .handling-title {
      margin-bottom: ${rem(10)};
    }

    .checkboxes {
      display: flex;
      flex-direction: column;
      gap: ${rem(15)};
    }
  }

  .form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: ${rem(20)};

    ${down("mobileL")} {
      grid-template-columns: 1fr;
    }
  }

  .checkbox-label {
    font-weight: bold;
  }
`;

export const VStyledNoticeInfo = styled("div")`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: ${rem(0)};
  border-radius: ${rem(20)};
  background: #faf3eb;
  height: 100%;
  padding: ${rem(10)};
  font-size: ${rem(14)};

  ${down("tablet")} {
    width: 100%;
  }
`;

export const StyledImportProductSuccessfulWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .modal-icon-check {
    width: ${rem(120)};
    height: ${rem(120)};
    display: grid;
    place-items: center;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: ${rem(10)};
  }

  .modal-title {
    font-size: ${rem(20)};
    font-weight: 500;
    margin-top: ${rem(10)};
  }

  .modal-text {
    max-width: ${rem(280)};
    text-align: center;
    color: ${color("ash03")};
  }

  .modal-button {
    margin: ${rem(30)} 0;
  }
`;
