import Driver from 'driver.js';
import KnowPlatform from 'assets/images/svgs/know-platform.svg';
import { AppCookies } from './appCookies';

const { setCookie, getCookie } = new AppCookies();

const walkthroughDriver = new Driver({
  prevBtnText: null,
  keyboardControl: true,
  position: 'right',
  allowClose: false,
});

const WALKTHROUGH_TITLE = `<div class="walkthrough-title"><img src=${KnowPlatform}  width={24} height={24} />  Know your platform</div>`;

export const handleWalkThrough = () => {
  const hasWalkThrough = getCookie('walkthrough');

  walkthroughDriver.defineSteps([
    {
      element: `#${WALKTHROUGH_IDS.SALES_AND_EXPENSES}`,
      popover: {
        className: 'custom-walkthrough',
        title: WALKTHROUGH_TITLE,
        description:
          '<strong>Sales & Expenses:</strong> Record and monitor all sales, purchases and expenses of your business on the platform.',
      },
    },
    {
      element: `#${WALKTHROUGH_IDS.INVENTORY}`,
      popover: {
        className: 'custom-walkthrough',
        title: WALKTHROUGH_TITLE,
        description:
          '<strong>Inventory:</strong>  Keep track of your stock levels in real-time across multiple sales channels.',
      },
    },
    {
      element: `#${WALKTHROUGH_IDS.MANAGE_BUSINESS}`,
      popover: {
        className: 'custom-walkthrough',
        title: WALKTHROUGH_TITLE,
        description:
          '<strong>Manage Business:</strong> Manage all your features on the platform, including staff permissions. ',
      },
    },
  ]);

  if (!hasWalkThrough) {
    setCookie('walkthrough', 'walkthrough', 20);
    return walkthroughDriver.start();
  }
};

export const WALKTHROUGH_IDS = {
  SALES_AND_EXPENSES: 'sales-and-expenses',
  INVENTORY: 'inventory',
  MANAGE_BUSINESS: 'manage-business',
};
