import { useForm } from "react-hook-form";
import { useCompleteBusinessLevelTwo, KycLevelTwoProps } from "api";
import { showToast } from "components/common";
import { useGetKycDetailsHook } from "./useGetKycDetailsHook";

export const useCompleteBusinessLevelTwoHook = (callBack?: Function) => {
  const hookForm = useForm<any>();
  const { handleSubmit, reset, watch } = hookForm;

  const { handleCompleteBusinessLevelTwo } = useCompleteBusinessLevelTwo();

  const { refetchKycDetails } = useGetKycDetailsHook();

  const submitKycLevelTwo = async (data: KycLevelTwoProps) => {
    await handleCompleteBusinessLevelTwo(data, () => {
      reset({
        proofOfIdType: "",
        proofOfId: "",
        proofOfAddressType: "",
        proofOfAddress: "",
      });
      showToast({
        subText: "Your KYC information has been submitted",
      });
    });
    refetchKycDetails();
  };

  return {
    hookForm,
    handleSubmit,
    reset,
    watch,
    submitKycLevelTwo,
  };
};
