import { IPagination } from "./../types";
import { gql, useQuery } from "urql";
import { useVAppSelector } from "hooks/useStore";
import { SEARCH_TYPE } from "store";

type UseGetCustomerProductProps = {
  businessId: string;
  page?: number;
  pause?: boolean;
  categoryId?: string;
  search?: string;
  size?: number;
};

export interface ICustomerProducts extends IPagination {
  nodes: {
    _id: string;
    productName: string;
    totalStock: number;
    sellingPrice: number;
    productImage: string;
    referenceId: string;
  }[];
}

const getCustomerProductQuery = gql`
  query (
    $businessId: String
    $page: Int
    $categoryId: String
    $searchQuery: String
    $size: Int
  ) {
    getCustomerProducts(
      businessId: $businessId
      filters: { categoryId: $categoryId }
      searchQuery: $searchQuery
      metadata: { size: $size, page: $page, sortFields: { sortOrder: desc } }
    ) {
      nodes {
        _id
        productName
        totalStock
        totalStockValue
        sellingPrice
        productImage
        batches {
          purchasePrice
        }
        referenceId
        category {
          _id
          name
        }
        thresholdLowStock
      }
      pageInfo {
        currentPage
        size
        totalCount
        hasNextPage
      }
    }
  }
`;

export const useGetCustomerProduct = ({
  businessId,
  page = 1,
  categoryId,
  search = "",
  pause = false,
  size = 10,
}: UseGetCustomerProductProps) => {
  const { page: inventoryPage, term: searchTerm } = useVAppSelector(
    (state) => state.search
  );

  return useQuery({
    query: getCustomerProductQuery,
    variables: {
      businessId,
      page,
      size,
      categoryId,
      searchQuery:
        inventoryPage === SEARCH_TYPE.INVENTORY_PRODUCT ? searchTerm : search,
    },
    pause,
  });
};
