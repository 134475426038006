import { VChip } from "components/common";
import {
  StyledBillingAction,
  StyledBillingAmount,
  StyledPlanType,
} from "../billing-overview";
import { ReactComponent as ChevronRight } from "assets/images/svgs/chevron-left.svg";
import {
  StyledChoosePricingPlan,
  StyledChoosePricingPlanContainer,
  StyledCurrentBillignLeftDetails,
  StyledCurrentPlanBillingAmount,
  StyledCurrentPlanDetails,
} from "./styles";
import EssentialPlanIcon from "assets/images/svgs/EssentialPlanIcon";
import { useChangeBillingPlanPageHook } from "hooks";

export const ChoosePricingPlan = ({
  planDetails,
}: {
  planDetails: ReturnType<
    typeof useChangeBillingPlanPageHook
  >["choosePricingPlan"];
}) => {
  const { planName, planSubtitle, planAmount } = planDetails;

  return (
    <StyledChoosePricingPlanContainer>
      <StyledChoosePricingPlan>
        <div>
          <h3>Choose pricing plan</h3>
          <p>No suprise fees. Cancel anytime</p>
        </div>
        {/* <Link to={APP_ROUTES.CHECKOUT_BILLING_PLAN}>
          <VButton text="Bill Monthly" isSlim />
        </Link> */}
      </StyledChoosePricingPlan>
      <StyledCurrentPlanDetails>
        <StyledCurrentBillignLeftDetails>
          <StyledPlanType>
            <p className="billing__summary">
              <span>Billing</span>
              <span>{planName}</span>
              <VChip key="free" label={planSubtitle} isActive isSlim />
            </p>
            <p className="billing__subtitle">
              Manage your billing and payment details
            </p>
          </StyledPlanType>
          <StyledBillingAction className="normal">
            <span>See plan features</span>
            <ChevronRight
              style={{
                transform: "rotate(-90deg)",
                height: "12px",
                stroke: "#0077FF",
              }}
            />
          </StyledBillingAction>
        </StyledCurrentBillignLeftDetails>
        <StyledCurrentPlanBillingAmount>
          <StyledBillingAmount>
            <h3>
              <span>{planAmount}</span>
              <span>/ month</span>
            </h3>
          </StyledBillingAmount>
          <EssentialPlanIcon height={40} />
        </StyledCurrentPlanBillingAmount>
      </StyledCurrentPlanDetails>
    </StyledChoosePricingPlanContainer>
  );
};
