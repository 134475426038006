import { Colors } from "veedez_bank/src/styles/colors";
import { ErrorHandler } from "../types/Error";
import { isServerSide, LOAN_STATUS_ENUM } from "./constants";

export const getAppBaseRoute = () => process.env.REACT_APP_BANKING_APP_BASE_DIR;

export const getLoanApiUrl = process.env.REACT_APP_LOAN_API_BASE_URL_DEV;
export const baseUrl = getLoanApiUrl;
type FormatterArgs = {
  style?: string;
  currency?: string;
  format?: string;
};
const formatter = (args?: FormatterArgs): Intl.NumberFormat => {
  const { style = "currency", currency = "NGN", format = "en-NG" } = args || {};
  return new Intl.NumberFormat(format, {
    style,
    currency,
  });
};

export const formatCurrency = (value: number): string => {
  let valueToFormat = value;
  try {
    if (Number.isNaN(value)) {
      valueToFormat = 0;
    }
  } catch (error) {
    valueToFormat = 0;
  }

  return formatter().format(valueToFormat);
};
export const formatAmount = (
  amount: number | string,
  toWholeNumber: boolean = false
): string => {
  if (toWholeNumber) {
    const value = Math.round(Number(amount));
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  let formattedAmount = (+amount || 0).toFixed(2).toString();
  if (!formattedAmount.includes(".")) {
    formattedAmount = `${amount}.00`;
  }
  return formattedAmount.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const calculateHoursLeft = (givenTime: string): string => {
  const currentTime = new Date();
  const givenDate = new Date(givenTime);
  const difference = givenDate.getTime() - currentTime.getTime();

  if (difference <= 0) {
    return "00:00 hrs";
  }
  const hoursLeft = Math.floor(difference / (1000 * 60 * 60));
  const minutesLeft = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
  const hoursLeftFormatted = hoursLeft.toString().padStart(2, "0");
  const minutesLeftFormatted = minutesLeft.toString().padStart(2, "0");

  return `${hoursLeftFormatted}:${minutesLeftFormatted} hrs`;
};

export const returnLoanBadgeColor = (loan: string) => {
  switch (loan) {
    case LOAN_STATUS_ENUM.ACTIVE:
      return {
        border: Colors.lightBlue10,
        bg: Colors.lightBlue10,
        color: Colors.lightBlue,
      };

    case LOAN_STATUS_ENUM.CLOSED:
      return {
        bg: Colors.lightGreen7,
        color: Colors.green,
      };
    case LOAN_STATUS_ENUM.PENDING_APPROVAL:
      return {
        bg: Colors.lightGreen7,
        color: Colors.green,
      };
    case LOAN_STATUS_ENUM.UNRESOLVED_PROVIDER_STATUS:
      return {
        bg: Colors.lightGreen7,
        color: Colors.green,
      };
    case LOAN_STATUS_ENUM.DECLINED:
      return {
        bg: Colors.lightGreen7,
        color: Colors.green,
      };
    case LOAN_STATUS_ENUM.APPROVED:
      return {
        bg: Colors.lightGreen7,
        color: Colors.green,
      };
    case LOAN_STATUS_ENUM.AWAITING_SIGNATURE:
      return {
        bg: Colors.lightGreen7,
        color: Colors.green,
      };
    case LOAN_STATUS_ENUM.AWAITING_DISBURSEMENT:
      return {
        bg: Colors.lightGreen7,
        color: Colors.green,
      };
    default:
      break;
  }
};

type getLoanTagColorsProps = {
  loanStatus: string;
  loanDate?: string;
  isPendingSubmission?: boolean;
};

export const getLoanTagColors = ({
  loanStatus,
  loanDate,
  isPendingSubmission,
}: getLoanTagColorsProps) => {
  let isLastDay = false;
  if (loanDate) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    // Define the date string to be checked
    const dateString = new Date(loanDate); // replace with your date string
    // Parse the date string into a Date object
    const dateToCheck = new Date(dateString);
    // Set the time of the date to be checked to 00:00:00
    dateToCheck.setHours(0, 0, 0, 0);
    isLastDay = today.getTime() === dateToCheck.getTime();
  }

  const finalPayment = {
    textColor: "#ea4336",
    backgroundColor: "#fbd9d7",
    tagText: "Last Payment Day",
  };

  const dataPendingSubmission = {
    textColor: "#f47200",
    backgroundColor: "#fef1e6",
    tagText: "Pending",
  };

  const statusColors = {
    [LOAN_STATUS_ENUM.ACTIVE]: {
      textColor: "#0177ff",
      backgroundColor: "#e6f1ff",
      tagText: "Active",
    },
    [LOAN_STATUS_ENUM.CLOSED]: {
      textColor: "#34a853",
      backgroundColor: "#e5f4e9",
      tagText: "Closed",
    },
    // [LOAN_STATUS_ENUM.APPROVED]: {
    //   textColor: '',
    //   backgroundColor: '',
    //   tagText: 'Approved',
    // },
    [LOAN_STATUS_ENUM.PENDING_APPROVAL]: {
      textColor: "#ffffff",
      backgroundColor: "#f1a85a",
      tagText: "Pending Approval",
    },
    // [LOAN_STATUS_ENUM.AWAITING_DISBURSEMENT]: {
    //   textColor: '',
    //   backgroundColor: '',
    //   tagText: '',
    // },
    [LOAN_STATUS_ENUM.DECLINED]: {
      textColor: finalPayment.textColor,
      backgroundColor: finalPayment.backgroundColor,
      tagText: "Declined",
    },
    [LOAN_STATUS_ENUM.AWAITING_SIGNATURE]: {
      textColor: "#ffffff",
      backgroundColor: "#374b58",
      tagText: "Awaiting Signature",
    },
    // [LOAN_STATUS_ENUM.UNRESOLVED_PROVIDER_STATUS]: {
    //   textColor: '',
    //   backgroundColor: '',
    //   tagText: '',
    // },
  };

  if (isPendingSubmission) {
    return dataPendingSubmission;
  }

  if (isLastDay && loanStatus === LOAN_STATUS_ENUM.ACTIVE) {
    return finalPayment;
  }

  return statusColors[loanStatus];
};

export const safelyStoreLSData = (key: string, value: string | object) => {
  let result;
  try {
    if (typeof value === "object") {
      const jsonValue = JSON.stringify(value);
      localStorage.setItem(key, jsonValue);
    } else {
      localStorage.setItem(key, value);
    }
    result = { error: false, message: "Operation Successful" };
  } catch (error) {
    const typedError = error as ErrorHandler;
    result = { error: true, message: typedError.message };
  }

  return result;
};

export const safelyGetLSData = (key: string) => {
  let result;

  try {
    if (isServerSide) {
      throw new Error("Cannot access localStorage while server-side rendering");
    }

    const data = localStorage.getItem(key);
    const parsedData = data ? JSON.parse(data) : null;
    result = {
      error: false,
      message: "Operation Successful",
      parsedData,
    };
  } catch (error) {
    const typedError = error as ErrorHandler;
    result = { error: true, message: typedError.message, data: null };
  }

  return result;
};

export const parseDateTime = (date: Date) => {
  try {
    const dateInstance = new Date(date);
    const parseDateItem = (item: number) => item.toString().padStart(2, "0");
    const year = parseDateItem(dateInstance.getUTCFullYear());
    const month = parseDateItem(dateInstance.getMonth() + 1);
    const day = parseDateItem(dateInstance.getUTCDate());
    const hours = parseDateItem(dateInstance.getUTCHours());
    const minutes = parseDateItem(dateInstance.getUTCMinutes());

    return {
      date: `${day}/${month}/${year}`,
      time: `${hours}:${minutes}`,
    };
  } catch (error) {
    return { date: "", time: "" };
  }
};
