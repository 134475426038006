import { Suspense, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { APP_ROUTES } from "routes";
import backgroundImage from "../assets/images/pngs/background-image.png";

// style import
import {
  Container,
  BodyContainer,
  BodyInnerContainer,
  BodyInnerBackground,
} from "./App.styled";

// components
import { Sidebar } from "../components/sidebar/Sidebar";
import ErrorBoundary from "../components/common/ErrorBoundary";
import ScrollToTop from "../utils/ScrollTo";
import TopNav from "components/top-nav/TopNav";
import { handleWalkThrough } from "utils/walkthrough";

// Notification
import { NotificationModal } from "components/notification/NotificationModal";
import { NotificationList } from "components/notification/NotificationList";
import { useNotificationHook } from "hooks/components/useNotificationHook";
import { useSideBarHook } from "hooks/components/useSideBarHook";
import MobileSidebarModal from "components/mobile-sidebar";
import { TransactionPinModal } from "components/account";

const AppRoutes = () => {
  // NOTIFICATION
  const { shouldOpenNotification, toggleNotificationModal, notifications } =
    useNotificationHook();

  // SIDEBAR
  const {
    shouldHideSidebar,
    isSideBarMinimzed,
    routes,
    setIsSideBarMinimzed,
    permissibleMenuList,
    inMarketPlace,
    isMobile,
    open,
    closeMobileSidebar,
  } = useSideBarHook();

  useEffect(() => {
    const timer = setTimeout(() => {
      handleWalkThrough();
    }, 1500);
    return () => clearTimeout(timer);
  }, [permissibleMenuList]);

  return (
    <Container
      shouldHideSidebar={shouldHideSidebar}
      isSideBarMinimzed={isSideBarMinimzed}
    >
      {!shouldHideSidebar && !isMobile && (
        <Sidebar
          sideBarData={permissibleMenuList}
          setIsSideBarMinimzed={setIsSideBarMinimzed}
          isSideBarMinimzed={isSideBarMinimzed}
        />
      )}

      {isMobile && (
        <MobileSidebarModal
          open={open}
          isSideBarMinimzed={isSideBarMinimzed}
          close={closeMobileSidebar}
        >
          {!shouldHideSidebar && (
            <Sidebar
              sideBarData={permissibleMenuList}
              setIsSideBarMinimzed={setIsSideBarMinimzed}
              isSideBarMinimzed={isSideBarMinimzed}
            />
          )}
        </MobileSidebarModal>
      )}

      <ScrollToTop />
      <BodyContainer>
        <TopNav toggleNotificationModal={toggleNotificationModal} />
        <BodyInnerBackground
          style={{ backgroundImage: `url(${backgroundImage})` }}
        >
          <ErrorBoundary>
            <BodyInnerContainer inMarketPlace={inMarketPlace}>
              <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                  <Route
                    path="/"
                    element={<Navigate to={APP_ROUTES.DASHBOARD} replace />}
                  />
                  {routes}
                </Routes>
              </Suspense>
            </BodyInnerContainer>
          </ErrorBoundary>
        </BodyInnerBackground>
      </BodyContainer>

      <NotificationModal
        close={toggleNotificationModal}
        open={shouldOpenNotification}
      >
        <NotificationList
          notifications={notifications}
          toggleNotificationModal={toggleNotificationModal}
        />
      </NotificationModal>
      <TransactionPinModal />
    </Container>
  );
};

export default AppRoutes;
