import { useNavigate } from "react-router-dom";
import { showToast } from "components/common";
import { useForm } from "react-hook-form";
import { useDeleteInventoryProduct } from "api/inventory/delete-inventory-product";
import { useProductPageHook } from "../useProductPageHook";
import { APP_ROUTES } from "routes";

export const useDeleteInventoryProductHook = (
  productId: string | any,
  callBack: Function
) => {
  // hooks
  const hookForm = useForm();
  const navigate = useNavigate();

  const { handleSubmit, reset } = hookForm;

  const { handleDeleteInventoryProduct, inventoryProduct } =
    useDeleteInventoryProduct();

  const { refetchCustomerProducts } = useProductPageHook({});

  const onDeleteProduct = async (data: any) => {
    await handleDeleteInventoryProduct(
      {
        ...data,
        productId: productId,
        status: "INACTIVE",
      },
      () => {
        reset();
        showToast({
          type: "delete",
          subText: `You have successfully deleted this product`,
        });
        callBack?.();
        refetchCustomerProducts();
        navigate(APP_ROUTES.PRODUCTS);
      }
    );
  };

  return {
    onDeleteProduct,
    isDeleting: inventoryProduct?.fetching,
    handleDeleteProductSubmit: handleSubmit,
    deleteProductHookForm: hookForm,
  };
};
