import { IPagination } from "./../types";
import { gql, useQuery } from "urql";
import { PaginationFragment } from "api/general-queries";
import { useVAppSelector } from "hooks/useStore";
import { SEARCH_TYPE } from "store";

type UseGetBusinessExpenseProps = {
  businessId: string;
  page?: number;
  search?: string;
  createdAt?: {
    lte: string;
    gte: string;
  };
};

export const useGetBusinessExpense = ({
  businessId,
  page = 1,
  search = "",
  createdAt,
}: UseGetBusinessExpenseProps) => {
  const { page: expensePage, term: searchTerm } = useVAppSelector(
    (state) => state.search
  );
  return useQuery({
    query: getBusinessExpenseQuery,
    variables: {
      businessId,
      page,
      ...(createdAt && { createdAt }),
      searchQuery: expensePage === SEARCH_TYPE.EXPENSE ? searchTerm : search,
    },
  });
};

const getBusinessExpenseQuery = gql`
  query ($businessId: String, $page: Int, $searchQuery: String, $createdAt: RangeInput) {
    getBusinessExpenses(
    filters: {businessId: $businessId, searchQuery: $searchQuery, createdAt: $createdAt}
     metadata: {
        size: 10,
        page: $page,
        sortOrder: "desc"
    }
   ){
    nodes{
      _id
     amount
     category {
      name
     }
     title
     categoryId
     paymentType
     createdAt
     createdBy
    }, 
   ${PaginationFragment}
  }
  }
`;

export interface IBusinessExpense extends IPagination {
  nodes: IBusinessExpenseNode[];
}

export interface IBusinessExpenseNode {
  _id?: string;
  amount: number | string;
  category?: {
    name: string;
  };
  item?: string;
  categoryId?: string;
  title?: string;
  paymentType?: string;
  createdAt?: string;
  createdBy?: string;
}
