import { EXPENSE_DETAILS_ITEMS } from "components/Inventory";
import { ModalComponentProps } from "components/common/types";
import { StyledExpenseDetailsContaienr, StyledExpenseItem } from "./styles";
import { IBusinessExpenseNode } from "api";
import { useExpenseDetailsHook } from "hooks";
import { VModal } from "components/common";

const ExpenseDetails = ({
  title = "Expense Details",
  open,
  close,
  expense,
}: ModalComponentProps & { expense: IBusinessExpenseNode }) => {
  const { newExpense } = useExpenseDetailsHook({ expense });

  return (
    <VModal open={open} close={close} title={title} width={576}>
      {newExpense && (
        <StyledExpenseDetailsContaienr>
          {EXPENSE_DETAILS_ITEMS.map(({ header, key, formatter }) => {
            let value =
              (key === "category"
                ? (newExpense as Record<string, any>)?.[key]?.name
                : (newExpense as Record<string, any>)?.[key]) ?? "N/A";

            if (formatter) {
              value = formatter(value as any);
            }
            return (
              <StyledExpenseItem key={key}>
                <h3 className="expense__item--header">{header}</h3>
                <p>{value}</p>
              </StyledExpenseItem>
            );
          })}
        </StyledExpenseDetailsContaienr>
      )}
    </VModal>
  );
};

export default ExpenseDetails;
