import { Label, Pie, PieChart, ResponsiveContainer } from "recharts";
import theme from "styles/theme";
import { formatNumberToCurrency } from "utils";
import { StyledVGuageItem } from "./styles";

type VGaugeItemProps = {
  percentage: number;
  color?: string;
  width?: string;
  data?: {
    increaseValue?: number;
    label?: string;
    value?: number | string;
  };
};

export const VGaugeItem = ({
  percentage = 20,
  color = theme.colors.lightBlue,
  width = "150px",
  data,
}: VGaugeItemProps) => {
  const value = (percentage / 100) * 360;

  const remainder = 360 - value;

  const vgType = (data?.increaseValue ?? 0) > 0 ? "increase" : "decrease";
  const vgSign = (data?.increaseValue ?? 0) >= 0 ? "+" : "-";

  return (
    <StyledVGuageItem>
      <div
        style={{
          height: "170px",
          minWidth: "100px",
          width,
        }}
      >
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Label />
            <Pie
              data={[{ name: "A1", value: remainder }]}
              dataKey="value"
              innerRadius={40}
              outerRadius={60}
              fill="#D9EBFF"
            />
            <Pie
              data={[{ name: "Group A", value }]}
              dataKey="value"
              endAngle={value}
              outerRadius={70}
              innerRadius={40}
              fill={color}
            >
              <Label
                value={`${percentage?.toString()}%`}
                position="center"
                style={{
                  fontSize: "20px",
                }}
              />
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
      {data && (
        <div>
          <p className="vg-label">{data?.label}</p>
          <div className="vg-desc">
            <h3 className="vg-value">
              {formatNumberToCurrency({ number: data?.value || 0 })}
            </h3>
            {data?.increaseValue && (
              <p className={`vg-${vgType}`}>
                {vgSign}
                {data?.increaseValue}
                {"%"}
              </p>
            )}
          </div>
        </div>
      )}
    </StyledVGuageItem>
  );
};
