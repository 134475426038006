import { useToggle, useUserProfileHook } from "hooks";
import { omit } from "lodash";
import { gql, useMutation } from "urql";
import { uploadMedia } from "utils";
import { handleRequestCall } from "utils/handleRequestCall";

const updateUserMutation = gql`
  mutation (
    $phoneNumber: String
    $userId: String
    $profilePicture: String
    $NIN: String
  ) {
    updateUser(
      phoneNumber: $phoneNumber
      userId: $userId
      profilePicture: $profilePicture
      NIN: $NIN
    ) {
      _id
      customerId
      phoneNumber
      profilePicture
      NIN
    }
  }
`;

export type IUpdateUser = {
  phoneNumber?: string;
  userId: string;
  profilePicture?: string;
  NIN?: string;
};

export const useUpdateuser = () => {
  const [userDetails, updateUser] = useMutation(updateUserMutation);
  const [imageProcessing, toggleImageProcessing] = useToggle();

  const { refetchUserProfile } = useUserProfileHook();

  const handleUpdateUser = async (data: IUpdateUser, callBack?: () => void) => {
    toggleImageProcessing();
    const dataWithoutImage = omit(data, ["profilePicture", ""]);
    const rawProfilePicture = data?.profilePicture;

    let processedFileImage: null | string = null;

    try {
      if (rawProfilePicture) {
        const uploadedFile = await uploadMedia(rawProfilePicture, "profile");
        if (!uploadedFile) {
          toggleImageProcessing();
          return;
        }
        processedFileImage = uploadedFile[0]?.url;
      }
      toggleImageProcessing();
      const req = await updateUser({
        ...dataWithoutImage,
        ...(processedFileImage && { profilePicture: processedFileImage }),
      });
      await handleRequestCall({
        request: req,
        callBack: () => {
          refetchUserProfile();
          callBack?.();
        },
      });
    } catch (e) {
      console.error("Error in updateUser: ", e);
    }
  };

  return {
    handleUpdateUser,
    userDetails,
    isLoading: imageProcessing || userDetails.fetching,
  };
};
