import { IService } from "components/Inventory/types";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useAuthUser } from "store";

export const useEditServiceModalHook = (
  service: IService,
  openConfirmEditModal: Function,
  setUpdatedService: Function,
  toggleEditServiceModal: Function
) => {
  const hookForm = useForm<IService>();
  const { handleSubmit, reset } = hookForm;
  const { userData } = useAuthUser();

  useEffect(() => {
    if (service) {
      reset({
        ...service,
        charge:
          service?.charge?.includes("₦") || service?.charge?.includes(",")
            ? service?.charge?.replaceAll(/₦|,/g, "")
            : service?.charge,
      });
    }
  }, [reset, service]);

  const onSubmit = (data: IService) => {
    const updateServiceRequest = {
      serviceName: data?.name,
      sellingPrice: Number(data?.charge),
      serviceId: data?._id,
      businessId: userData?.businessId,
    };
    setUpdatedService(updateServiceRequest);
    toggleEditServiceModal();
    openConfirmEditModal();
  };

  return {
    handleSubmit,
    onSubmit,
    hookForm,
  };
};
