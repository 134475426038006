import {
  IBusinessStaffNode,
  useGetBusinessStaffDetail,
  useUpdateBusinessStaffStatus,
} from "api/staff";
import { showToast } from "components/common";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { extractFetchedData } from "utils";

export enum ESTAFFSTATUS {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export const useStaffDetailPageHook = () => {
  const { businessStaffId } = useParams();

  const { businessStaffStatus, handleUpdateBusinessStatffStatus } =
    useUpdateBusinessStaffStatus();

  const [staffStatus, setStaffStatus] = useState(ESTAFFSTATUS.INACTIVE);

  const [fetchData, refetchBusinessStaffDetails] = useGetBusinessStaffDetail({
    businessStaffId: businessStaffId ?? "",
  });

  const extract = extractFetchedData<IBusinessStaffNode>({
    result: fetchData,
    key: "getBusinessStaffDetail",
  });

  const staffData: IBusinessStaffNode = extract?.extractedData;
  const staffRef = JSON.stringify(staffData);

  const toggleStaffStaus = (status: string) => {
    if (status === ESTAFFSTATUS.ACTIVE) {
      setStaffStatus(ESTAFFSTATUS.ACTIVE);
    } else {
      setStaffStatus(ESTAFFSTATUS.INACTIVE);
    }
  };

  useEffect(() => {
    if (staffData && !isEmpty(staffData)) {
      toggleStaffStaus(staffData.status);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staffRef]);

  useEffect(() => {
    if (fetchData.error) {
      showToast({
        type: "error",
        title: "An error occured",
        subText: "Please refresh",
      });
    }
  }, [fetchData.error]);

  const updateBusinessStaff = () => {
    if (businessStaffId) {
      handleUpdateBusinessStatffStatus(
        { businessStaffId },
        (data: Record<string, any>) => {
          const isActiveCustomer = staffStatus === ESTAFFSTATUS.ACTIVE;

          // Get the new status from state
          const newStatus = data?.updateBusinessStaffStatus?.status;

          // Toggle the customer status
          toggleStaffStaus(newStatus);

          showToast({
            title: isActiveCustomer
              ? "Business staff deactivated"
              : "Business staff activated",
          });
        }
      );
    }
  };

  return {
    ...extract,
    staffStatus,
    updateBusinessStaff,
    refetchBusinessStaffDetails,
    updatingBusinesStaffStatus: businessStaffStatus.fetching,
  };
};
