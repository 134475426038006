import { useOutsideClick, useWindowSize } from "hooks";
import { useRef, useState } from "react";
import { VDropdownProps } from "../types";
import {
  VDropdonwButton,
  VDropdownContainer,
  VDropdownMenu,
  VMobileDropdownMenu,
  VDropdownMenuItem,
} from "./styles";
import { VModal } from "../VModal";

export const VDropdown = ({
  width = 160,
  isLeft = false,
  isTop = true,
  styles,
  dropdownButton,
  dropdownItems,
}: VDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [shouldOpenAddProductModal, setShouldOpenAddProductModal] =
    useState(false);

  const { width: windowSize } = useWindowSize();
  const isMobile = (windowSize as number) <= 500;

  const dropdownRef = useRef(null);

  useOutsideClick(dropdownRef, (e: any) =>
    setIsOpen(e && e.target === dropdownRef.current)
  );

  return (
    <VDropdownContainer ref={dropdownRef}>
      {/* Add Product on mobile screen */}
      {isMobile && (
        <VDropdonwButton>
          <div
            className="menu"
            onClick={() =>
              setShouldOpenAddProductModal((prevValue) => !prevValue)
            }
          >
            {dropdownButton}
          </div>
          <VModal
            open={shouldOpenAddProductModal}
            close={() =>
              setShouldOpenAddProductModal((prevValue) => !prevValue)
            }
          >
            <VMobileDropdownMenu
              width={width}
              isActive={shouldOpenAddProductModal}
            >
              {dropdownItems.map(({ key, label, onClick }) => {
                return (
                  <VDropdownMenuItem
                    key={key}
                    onClick={() => {
                      onClick?.();
                      setShouldOpenAddProductModal((prevValue) => !prevValue);
                    }}
                  >
                    {label}
                  </VDropdownMenuItem>
                );
              })}
            </VMobileDropdownMenu>
          </VModal>
        </VDropdonwButton>
      )}

      {/* Add Product on large screens */}
      {!isMobile && (
        <VDropdonwButton>
          <div className="menu" onClick={() => setIsOpen(!isOpen)}>
            {dropdownButton}
          </div>
          <VDropdownMenu
            width={width}
            isActive={isOpen}
            isLeft={isLeft}
            isTop={isTop}
            style={styles}
          >
            {dropdownItems.map(({ key, label, onClick }) => {
              return (
                <VDropdownMenuItem key={key} onClick={() => onClick?.()}>
                  {label}
                </VDropdownMenuItem>
              );
            })}
          </VDropdownMenu>
        </VDropdonwButton>
      )}
    </VDropdownContainer>
  );
};
