import { useEffect, useState } from "react";
import { useModalProps } from "./types";

const useModal = (): useModalProps => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const toggleModal = () => setOpen(!open);

  useEffect(() => {
    const closeOnEscapeKey = (e: any) =>
      e.key === "Escape" ? handleClose() : null;
    document.body.addEventListener("keydown", closeOnEscapeKey);
    return () => {
      document.body.removeEventListener("keydown", closeOnEscapeKey);
    };
  }, []);

  return { open, handleOpen, handleClose, toggleModal };
};

export default useModal;
