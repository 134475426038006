import { Wrapper, Radio, Label } from "./styles";
import { RadioProps } from "../types";
import { useForm } from "react-hook-form";

export const VRadio = ({
  label,
  id,
  value,
  onClick,
  changed,
  isSelected,
  className,
  ...rest
}: RadioProps) => {
  const hookForm = useForm();

  return (
    <Wrapper>
      <Radio
        type="radio"
        id={id}
        hookForm={hookForm}
        value={value}
        onClick={onClick}
        onChange={changed}
        checked={isSelected}
        className={className}
        {...rest}
      />
      <Label htmlFor={id}>{label}</Label>
    </Wrapper>
  );
};
