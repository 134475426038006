import { useExpensePageHook } from "hooks";
import { gql, useMutation } from "urql";
import { handleRequestCall } from "utils/handleRequestCall";
import { useAuthUser } from "store";
import {
  ICustomerCategories,
  INVENTORY_TYPES,
  useGetCustomerCategories,
} from "api/inventory";
import { extractFetchedData } from "utils";
import { showToast } from "components/common";

const editBusinessExpenseMutation = gql`
  mutation (
    $paymentType: PAYMENTTYPE
    $item: EditExpenseItem
    $businessId: String
    $expenseId: ID!
  ) {
    updateBusinessExpense(
      paymentType: $paymentType
      item: $item
      businessId: $businessId
      expenseId: $expenseId
    ) {
      _id
      title
      customerId
      title
      amount
      paymentType
      category {
        _id
        name
      }
      createdBy
      createdAt
    }
  }
`;

export type IEditBusinessExpense = {
  amount: string;
  item: string;
  categoryId: string;
  paymentType: string;
  expenseId: String;
};

export const useEditBusinessExpense = () => {
  const [businessExpense, editBusinessExpense] = useMutation(
    editBusinessExpenseMutation
  );

  const [result] = useGetCustomerCategories({
    inventoryType: INVENTORY_TYPES.EXPENSE,
    size: -1,
  });

  const { extractedData: expenseCategories } =
    extractFetchedData<ICustomerCategories>({
      result,
      key: "getCategories",
    });

  const { userData } = useAuthUser();

  const { refechBusinessExpenses } = useExpensePageHook();

  const handleEditBusinessExpense = async (
    data: IEditBusinessExpense,
    callBack?: () => void
  ) => {
    const { amount, item, categoryId, paymentType, expenseId } = data;

    try {
      const values = {
        paymentType,
        item: {
          title: item,
          amount: parseFloat(amount),
          categoryId,
        },
        expenseId,
        businessId: userData?.businessId as string,
      };
      const req = await editBusinessExpense(values);

      refechBusinessExpenses();
      await handleRequestCall({ request: req, callBack });
    } catch (e) {
      showToast({
        type: "error",
        title: "An error occurred",
        subText: "Please try again",
      });
    }
  };

  return {
    handleEditBusinessExpense,
    businessExpense,
    expenseCategories: expenseCategories?.nodes || [],
  };
};
