import styled from "styled-components/macro";
import { stylingUtils } from "styles";
import { EditCustomerFrame } from "components/invoices/edit-invoice/styles";

const {
  rem,
  color,
  mediaQuery: { down },
} = stylingUtils;

export const RequestPosFrame = styled(EditCustomerFrame)`
  padding: 3rem;
  h3 {
    font-size: ${rem(18)};
    line-height: ${rem(24)};
    padding: ${rem(10)} 0;
    font-weight: 700;
    color: ${color("black01")};
  }

  @media (max-width: 400px) {
    padding: ${rem(10)};
  }
`;
export const OptionFlex = styled("div")`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: ${rem(20)};
  padding-bottom: ${rem(120)};

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }

  ${down("laptop")} {
  }
`;
export const Option = styled("div")<{ selectedPos: any }>`
  display: flex;
  align-items: flex-start;
  gap: 2rem;
  margin-top: ${rem(25)};
  max-width: ${rem(407)};
  padding: ${rem(25)};
  background: ${({ selectedPos }) => (selectedPos ? "#EFF6FF" : "#fff")};
  border: ${({ selectedPos }) =>
    selectedPos ? "1px solid #80BCFF" : "1px solid #fff"};
  border-radius: 10px;

  h3 {
    font-weight: 700;
    font-size: ${rem(18)};
    line-height: ${rem(24)};
    color: ${color("black01")};
    margin-top: ${rem(-15)};
  }
  p {
    font-weight: 600;
    font-size: ${rem(16)};
    line-height: ${rem(22)};
    color: ${color("black01")};
  }
  .bottom-flex {
    display: flex;
    gap: ${rem(50)};
    align-items: center;
    margin-top: ${rem(25)};
  }
  ul {
    margin-bottom: ${rem(20)};
  }
  li {
    font-weight: 400;
    font-size: 14px;
    line-height: 27px;
    color: ${color("ashText")};
  }
  img {
    height: 100%;
  }
  .features {
    display: flex;
    align-items: center;
    gap: 0.8rem;

    & > img {
      width: 19.26px;
    }
    & > img:last-of-type {
      width: 30px;
    }
  }

  @media (max-width: 790px) {
    .bottom-flex {
      flex-direction: column;
    }
  }
`;
export const ButtonDiv = styled("div")`
  display: flex;
  justify-content: center;
  padding-bottom: ${rem(30)};
`;

export const OptionStyles = styled("div")`
  display: flex;
  align-items: center;
  gap: 10rem;
  margin-top: ${rem(20)};
  margin-bottom: ${rem(50)};

  .left {
    display: flex;
    gap: 2rem;
    align-items: flex-start;
  }
  h3 {
    font-weight: 700;
    font-size: ${rem(18)};
    line-height: ${rem(24)};
    color: ${color("black01")};
    margin-top: ${rem(-15)};
  }
  p {
    color: ${color("black01")};
    font-weight: 600;
    line-height: ${rem(22)};
    font-size: ${rem(16)};
  }
  .bottom-flex {
    display: flex;
    gap: ${rem(50)};
    align-items: center;
    margin-top: ${rem(25)};
  }
  ul {
    margin-bottom: ${rem(20)};
  }
  li {
    font-weight: 400;
    font-size: 14px;
    line-height: 27px;
    color: ${color("ashText")};
  }
  img {
    height: 100%;
  }
  .features {
    display: flex;
    align-items: center;
    gap: 0.8rem;

    & > img {
      width: 19.26px;
    }
    & > img:last-of-type {
      width: 30px;
    }
  }
`;
export const Terms = styled("div")`
  display: flex;
  justify-content: center;
  margin: ${rem(25)} 0;

  .col__spanfull {
    grid-column: auto / span 2 !important;
  }

  p {
    font-size: ${rem(14)};
  }
`;
