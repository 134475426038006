import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useInvoiceDetailsHook } from "hooks";
import { getModernDateFormat } from "utils";
import { BusinessInvoice, useUpdateBusinessInvoice } from "api";
import { showToast } from "components/common";
import { APP_ROUTES } from "routes";

export const useEditInvoicePageHook = () => {
  const hookForm = useForm();
  const { invoiceId } = useParams();

  const { invoiceDetails } = useInvoiceDetailsHook(invoiceId as string);

  const navigate = useNavigate();

  const { reset, handleSubmit } = hookForm;

  const { handleUpdateBusinessInvoice, isLoading } = useUpdateBusinessInvoice();

  const invoiceDataRef = JSON.stringify(invoiceDetails);

  const businessCustomerId = invoiceDetails?.businessCustomerId;

  const submitHandler = async (invoiceData: BusinessInvoice) => {
    await handleUpdateBusinessInvoice({ ...invoiceData }, invoiceId, () => {
      showToast({
        subText: `Your invoice has been updated successfully`,
      });
    });
    navigate(APP_ROUTES.INVOICES);
  };

  // submit form
  const onSubmit = (invoiceData: BusinessInvoice) => {
    if (invoiceData?.transactionType === "PRODUCT") {
      submitHandler({
        invoiceId: invoiceId,
        businessCustomerId: businessCustomerId,
        items: [
          ...invoiceData?.invoiceItems?.map(({ id, ...rest }: any) => ({
            productName: rest.productName,
            customerProductId: rest.customerProductId,
            purchasePrice: Number(rest.purchasePrice),
            quantity: Number(rest.quantity),
            sellingPrice: Number(rest.sellingPrice),
          })),
        ],
        deliveryFee: Number(invoiceData.deliveryFee),
        discount: Number(invoiceData.discount),
      });
    } else if (invoiceData?.transactionType === "SERVICE") {
      submitHandler({
        invoiceId: invoiceId,
        businessCustomerId: businessCustomerId,
        services: [
          ...invoiceData?.invoiceItems?.map(({ id, ...rest }: any) => ({
            serviceName: rest.serviceName,
            customerServiceId: rest.customerServiceId,
            quantity: Number(rest.quantity),
            sellingPrice: Number(rest.sellingPrice),
          })),
        ],
        deliveryFee: Number(invoiceData.deliveryFee),
        discount: Number(invoiceData.discount),
      });
    }
  };

  useEffect(() => {
    if (invoiceDetails) {
      const { invoiceItems = [] } = invoiceDetails;
      const defaultValueInvoiceItems = invoiceItems.map(
        (invoice: Record<string, any>) => ({
          ...invoice,
          productName: invoice?.customerProductName,
          serviceName: invoice?.customerServiceName,
          purchasePrice: invoice?.purchasePrice,
        })
      );

      reset({
        ...invoiceDetails,
        issueDate: getModernDateFormat(invoiceDetails?.issueDate),
        dueDate: getModernDateFormat(invoiceDetails?.dueDate),
        invoiceItems: defaultValueInvoiceItems,
        includeTax: !!invoiceDetails?.totalTax ? "7.5%" : "0%",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceDataRef]);

  const itemArray = invoiceDetails?.invoiceItems;

  return {
    hookForm,
    invoiceDetails,
    itemArray,
    isLoading,
    handleSubmit,
    onSubmit,
  };
};
