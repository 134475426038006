import { IPagination } from "./../types";
import { gql, useQuery } from "urql";
import { useVAppSelector } from "hooks/useStore";
import { SEARCH_TYPE } from "store";

type useGetCustomerServiceProps = {
  businessId: string;
  page?: number;
  pause?: boolean;
  categoryId?: string;
  search?: string;
  size?: number;
};

export interface ICustomerService extends IPagination {
  nodes: {
    _id: string;
    businessId: string;
    serviceName: number;
    sellingPrice: number;
    serviceImage: string;
    createdAt: string;
    referenceId: string;
    description: string;
  }[];
}

const getCustomerServiceQuery = gql`
  query (
    $businessId: String
    $page: Int
    $size: Int
    $categoryId: String
    $searchQuery: String
  ) {
    getCustomerServices(
      businessId: $businessId
      filters: { categoryId: $categoryId }
      searchQuery: $searchQuery
      metadata: { size: $size, page: $page, sortFields: { sortOrder: desc } }
    ) {
      nodes {
        _id
        businessId
        serviceName
        sellingPrice
        sellingPrice
        createdAt
        referenceId
        category {
          _id
          name
        }
        description
      }
      pageInfo {
        currentPage
        size
        totalCount
        hasNextPage
      }
    }
  }
`;

export const useGetCustomerService = ({
  businessId,
  page = 1,
  size = 10,
  categoryId,
  search = "",
  pause = false,
}: useGetCustomerServiceProps) => {
  const { page: inventoryPage, term: searchTerm } = useVAppSelector(
    (state) => state.search
  );

  return useQuery({
    query: getCustomerServiceQuery,
    variables: {
      businessId,
      page,
      categoryId,
      size,
      searchQuery:
        inventoryPage === SEARCH_TYPE.INVENTORY_SERVICE ? searchTerm : search,
    },
    pause,
  });
};
