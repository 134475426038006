import { truncateWord } from "utils";
import { VToastProps } from "components/common/types";
import { showToast } from "components/common";
import { OperationResult } from "urql";

export const handleRequestCall = async ({
  request,
  callBack,
  erroCallback,
  error = {
    type: "error",
    title: "Error",
    subText: "Please try again",
  },
}: {
  request: OperationResult<any, object>;
  callBack?: Function;
  error?: VToastProps;
  erroCallback?: Function;
}) => {
  if (request.error) {
    const newError =
      request?.error?.message?.replace("[GraphQL]", "")?.trim() ||
      error.title ||
      "An error occured";
    showToast({
      ...error,
      title: truncateWord(newError, { length: 24 }),
    });
    erroCallback?.(request?.data);
  } else if (request.data) {
    callBack?.(request?.data);
  }
};
