import { NotificationHeader } from "components/notification/NotificationHeader";
import ReactDOM from "react-dom";
import {
  StyledNotificationModalContainer,
  StyledNotificationOverlay,
} from "./styles";

export const NotificationModal = ({
  close,
  open,
  children,
}: {
  close: () => void;
  open: boolean;
  children: React.ReactNode;
}) => {
  return ReactDOM.createPortal(
    <StyledNotificationOverlay open={open}>
      <StyledNotificationModalContainer
        width={500}
        open={open}
        padding={8}
        isDrawer={true}
      >
        <NotificationHeader close={close} />
        {children}
      </StyledNotificationModalContainer>
    </StyledNotificationOverlay>,
    document.getElementById("v-modal") as any
  );
};
