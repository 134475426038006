import { BodyContainer, BodyInnerBackground } from "app/App.styled";
import EnterPasscode from "components/auth/signup/enter-passcode";
import { VLoader } from "components/common";
import TopNav from "components/top-nav/TopNav";
import { usePasscodeContext } from "context";
import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";

import backgroundImage from "../assets/images/pngs/background-image.png";
import { APP_ROUTES } from "./constants";

export const PasscodeAuthenticatedRoute = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { isLoading, hasVerifyPasscode, hasSetPasscode } = usePasscodeContext();

  const navigate = useNavigate();

  const callback = async () => {
    navigate(APP_ROUTES.DASHBOARD);
  };

  if (isLoading) return <VLoader text="Preparing Your Business" />;

  if (!hasVerifyPasscode) {
    return (
      <BodyContainer>
        <TopNav />
        <BodyInnerBackground
          style={{
            backgroundImage: `url(${backgroundImage})`,
            height: "100%",
            alignItems: "center",
            paddingTop: "5rem",
          }}
        >
          {hasSetPasscode ? (
            <EnterPasscode
              hasSetPassCode={hasSetPasscode}
              type="LOGIN"
              callback={callback}
            />
          ) : (
            <EnterPasscode
              hasSetPassCode={hasSetPasscode}
              type="SIGNUP"
              callback={callback}
            />
          )}
        </BodyInnerBackground>
      </BodyContainer>
    );
  }

  return <div>{children}</div>;
};
