import { InputListType } from "components/common/types";
import { useAddInvoicePageHook } from "hooks";
import { CUSTOM_VALIDATIONS, getReversedDate, numericInputFilter } from "utils";

export const VAT_OPTIONS = [
  { value: true, label: "7.5%" },
  { value: false, label: "0%" },
];

export const ADD_INVOICE_BASIC_INFO = () => {
  const today = new Date();
  return [
    {
      name: "clientPhone",
      label: "Phone number",
      type: "tel",
      disabled: true,
    },
    {
      name: "issueDate",
      label: "Issue date",
      type: "date",
      disabled: true,
      required: true,
      validation: { required: "Please enter issue date" },
    },
    {
      name: "dueDate",
      label: "Due date",
      type: "date",
      required: true,
      validation: { required: "Please enter due date" },
      min: getReversedDate(today as any),
    },
  ];
};

export const ADD_INVOICE_VAT: InputListType[] = [
  {
    name: "includeTax",
    label: "VAT",
    inputType: "select",
    options: VAT_OPTIONS,
  },
  {
    name: "discount",
    label: "Discount (NGN)",
    type: "number",
  },
];

export const ADD_PRODUCT_INPUT_LIST = () => {
  const { SELECT_PRODUCT_OPTIONS } = useAddInvoicePageHook();
  return [
    {
      name: "productName",
      label: "Product name",
      inputType: "select",
      options: SELECT_PRODUCT_OPTIONS,
      required: true,
      validation: { required: "Please enter product name" },
    },
    {
      name: "quantity",
      label: "Quantity",
      type: "number",
      required: true,
      validation: { required: "Please enter quantity" },
    },
    {
      name: "purchasePrice",
      label: "Cost price (NGN)",
      type: "number",
      disabled: true,
      required: true,
      validation: { required: "Please enter cost price" },
    },
    {
      name: "sellingPrice",
      label: "Selling price (NGN)",
      type: "number",
      disabled: true,
      required: true,
      validation: { required: "Please enter selling price" },
    },
  ];
};

export const ADD_SERVICE_INPUT_LIST = () => {
  const { SELECT_SERVICE_OPTIONS } = useAddInvoicePageHook();
  return [
    {
      name: "serviceName",
      label: "Service name",
      inputType: "select",
      options: SELECT_SERVICE_OPTIONS,
      required: true,
      validation: { required: "Please enter service name" },
    },
    {
      name: "quantity",
      label: "Quantity",
      type: "number",
      required: true,
      validation: { required: "Please enter quantity" },
    },
    {
      name: "sellingPrice",
      label: "Service price (NGN)",
      type: "number",
      disabled: true,
      required: true,
      validation: { required: "Please enter service price" },
    },
  ];
};

export const ADD_DELIVERY_INPUT: InputListType[] = [
  {
    name: "deliveryFee",
    label: "Delivery fee (NGN)",
    type: "number",
  },
];

export const ADD_PAYMENT_INPUT_LIST = ({
  SELECT_BANKS_OPTIONS,
  bank,
}: {
  SELECT_BANKS_OPTIONS: [];
  bank?: string;
}): InputListType[] => {
  return [
    {
      name: "bankName",
      label: "Bank name",
      inputType: "select",
      options: SELECT_BANKS_OPTIONS,
      required: true,
      validation: { required: "Please select bank" },
      placeholder: "Select Bank",
    },
    {
      name: "bankAccountNumber",
      label: "Account number",
      type: "number",
      required: true,
      disabled: !bank,
      validation: {
        required: "Enter your account number",
        ...CUSTOM_VALIDATIONS.accountNumber,
      },
    },
    {
      name: "bankAccountName",
      label: "Account name",
      required: true,
      onKeyDown: (
        e:
          | React.KeyboardEvent<HTMLInputElement>
          | React.KeyboardEvent<HTMLTextAreaElement>
      ) => numericInputFilter(e),
      validation: {
        required: "Enter your account name",
        ...CUSTOM_VALIDATIONS.accountName,
      },
      disabled: true,
    },
  ];
};
