import { IInvoiceStat } from "./../../api/report/get-invoice-report";
import { INVOICES_CARD_DATA } from "./../../components/reports/report-invoices/constants";
import { useMemo } from "react";
import useReportFilterHook from "./report/useReportFilterHook";
import { useGetInvoiceReportHook } from "api";
import { FinalDateMapperProps, getMappedDate } from "utils/dateMapper";

const initialInvoiceStat = {
  PAID: {
    _id: "PAID",
    count: 0,
    invoiceValue: 0,
  },
  PART_PAYMENT: {
    _id: "PART_PAYMENT",
    count: 0,
    invoiceValue: 0,
  },
  OVERDUE: {
    _id: "OVERDUE",
    count: 0,
    invoiceValue: 0,
  },
  UNPAID: {
    _id: "UNPAID",
    count: 0,
    invoiceValue: 0,
  },
};

export const useReportInvoicePageHook = () => {
  const { dateFilter, date } = useReportFilterHook();

  const { data } = useGetInvoiceReportHook({
    createdAt: dateFilter?.createdAt,
    invoiceGraphType: date,
  });

  const { getInvoicesStat, getInvoiceByTime } = data;

  const cardRef = JSON.stringify(getInvoicesStat);
  const cardInvoiceRef = JSON.stringify(getInvoiceByTime);

  const mappedInvoiceStat = useMemo(() => {
    const invoiceStat = getInvoicesStat?.reduce(
      (acc: Record<string, IInvoiceStat>, curr) => {
        const currValue = acc[curr?._id?.toUpperCase()];
        acc[curr?._id?.toUpperCase()] = curr
          ? {
              ...curr,
              count: currValue?.count + curr?.count,
              invoiceValue: currValue?.invoiceValue + curr?.invoiceValue,
            }
          : curr;

        return acc;
      },
      { ...initialInvoiceStat }
    );

    const allValues = Object.values(invoiceStat);

    const invoicesIssuedValue = allValues.reduce(
      (acc, curr) => (acc += curr?.invoiceValue),
      0
    );
    const invoicesIssuedCount = allValues.reduce(
      (acc, curr) => (acc += curr?.count),
      0
    );
    const paidInvoiceCount = invoiceStat?.PAID?.count || 0;
    const partlyPaidInvoiceCount = invoiceStat?.PART_PAYMENT?.count || 0;

    return {
      invoiceStat,
      invoicesIssuedCount,
      invoicesIssuedValue,
      paidInvoiceCount,
      partlyPaidInvoiceCount,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardRef]);

  const aggregateInvoiceGraph = useMemo(() => {
    const currentDate = new Date();
    const currentDay = currentDate?.getDate();
    const currentHour = currentDate?.getHours();
    const mappedDate = getMappedDate(date, {
      hourEnd: currentHour + 1,
      hourStart: currentHour - 7 + 2,
      dayEnd: currentDay,
      dayStart: currentDay - 7 + 1,
    });

    if (!getInvoiceByTime.length) {
      return Object.values(mappedDate) as FinalDateMapperProps[];
    }
    const newData = { ...mappedDate };

    getInvoiceByTime.forEach((data) => {
      const { identifier } = data;
      const value = (newData as Record<string, any>)[identifier];

      if (value) {
        (newData as Record<string, any>)[identifier] = {
          ...value,
          total: data?.invoiceTotal || 0,
        };
      }
    });

    return Object.values(newData) as FinalDateMapperProps[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardInvoiceRef]);

  const cardData = useMemo(() => {
    const {
      invoicesIssuedCount,
      invoicesIssuedValue,
      paidInvoiceCount,
      partlyPaidInvoiceCount,
    } = mappedInvoiceStat;

    return INVOICES_CARD_DATA({
      data: {
        invoicesIssuedCount,
        invoicesIssuedValue,
        paidInvoiceCount,
        partlyPaidInvoiceCount,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(mappedInvoiceStat)]);

  return {
    cardData,
    aggregateInvoiceGraph,
    mappedInvoiceStat,
  };
};
