import styled from "styled-components/macro";
import { FlexColumn, FlexJAMixin, stylingUtils } from "styles";

const { rem, color } = stylingUtils;

export const StyledOrderSummaryList = styled("ul")`
  ${FlexColumn};
  gap: ${rem(15)};
  padding-bottom: ${rem(20)};
  border-bottom: 1px solid ${color("ash04")};
`;
export const StyledOrderSummaryListContainer = styled("ul")`
  ${FlexColumn};
  gap: ${rem(25)};
`;

export const StyledOrderSummaryItem = styled("li")`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledOrderSummarySecuredPayment = styled("button")`
  width: 100%;
  ${FlexJAMixin};
  gap: ${rem(8)};
  color: ${color("ash03")};
  margin-top: ${rem(10)};
`;
