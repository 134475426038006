const TimerIcon = (props: any) => {
  return (
    <svg
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.4995 2.5H7.49951C6.74951 2.5 6.24951 2 6.24951 1.25C6.24951 0.5 6.74951 0 7.49951 0H12.4995C13.2495 0 13.7495 0.5 13.7495 1.25C13.7495 2 13.2495 2.5 12.4995 2.5Z"
        fill="#374B58"
      />
      <path
        d="M9.99951 14.9995C9.24951 14.9995 8.74951 14.4995 8.74951 13.7495V9.99951C8.74951 9.24951 9.24951 8.74951 9.99951 8.74951C10.7495 8.74951 11.2495 9.24951 11.2495 9.99951V13.7495C11.2495 14.4995 10.7495 14.9995 9.99951 14.9995Z"
        fill="#F9FAFC"
      />
      <path
        d="M10 3.75C4.5 3.75 0 8.25 0 13.75C0 19.25 4.5 23.75 10 23.75C15.5 23.75 20 19.25 20 13.75C20 8.25 15.5 3.75 10 3.75ZM11.25 13.75C11.25 14.5 10.75 15 10 15C9.25 15 8.75 14.5 8.75 13.75V10C8.75 9.25 9.25 8.75 10 8.75C10.75 8.75 11.25 9.25 11.25 10V13.75Z"
        fill="#C1CACF"
      />
      <path
        d="M2.12451 7.625C2.62451 7 3.24951 6.375 3.87451 5.875L2.74951 4.75C2.24951 4.25 1.49951 4.25 0.999512 4.75C0.499512 5.25 0.499512 6 0.999512 6.5L2.12451 7.625ZM17.8745 7.625L18.9995 6.5C19.4995 6 19.4995 5.25 18.9995 4.75C18.4995 4.25 17.7495 4.25 17.2495 4.75L16.1245 5.875C16.7495 6.375 17.3745 7 17.8745 7.625Z"
        fill="#374B58"
      />
      <path
        d="M9.99951 15.625C11.035 15.625 11.8745 14.7855 11.8745 13.75C11.8745 12.7145 11.035 11.875 9.99951 11.875C8.96398 11.875 8.12451 12.7145 8.12451 13.75C8.12451 14.7855 8.96398 15.625 9.99951 15.625Z"
        fill="#374B58"
      />
    </svg>
  );
};

export default TimerIcon;
