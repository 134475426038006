import {
  IAddCustomerService,
  ICustomerCategories,
  INVENTORY_TYPES,
  useAddCustomerService,
  useGetCustomerCategories,
} from "api";
import { showToast } from "components/common";
import { SERVICE_FORM_INPUT_LIST } from "components/Inventory";
import { useAnalytics } from "hooks/useAnalytics";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useAuthUser } from "store";
import { extractFetchedData } from "utils";

export const useAddServiceModalHook = (callBack?: Function) => {
  const hookForm = useForm<IAddCustomerService>();

  const [
    logAppEvent,
    {
      add_service_screen_opened,
      add_service_successfully,
      submitted_added_service,
    },
  ] = useAnalytics();
  logAppEvent(add_service_screen_opened, {});

  const { userData } = useAuthUser();

  const [serviceCategories] = useGetCustomerCategories({
    businessId: userData?.businessId as string,
    inventoryType: INVENTORY_TYPES.SERVICE,
  });

  const inputLists = useMemo(() => {
    const { extractedData } = extractFetchedData<ICustomerCategories>({
      key: "getCategories",
      result: serviceCategories,
    });

    const options = (extractedData?.nodes || []).map((data) => ({
      value: data?._id,
      label: data?.name,
    }));

    return SERVICE_FORM_INPUT_LIST(options);
  }, [serviceCategories]);

  const { handleAddCustomerService, isLoading } = useAddCustomerService();

  const { handleSubmit, reset } = hookForm;
  const onSubmit = async (data: IAddCustomerService) => {
    logAppEvent(submitted_added_service, {});

    await handleAddCustomerService(data, () => {
      reset();
      logAppEvent(add_service_successfully, {});
      showToast({
        subText: "Service was added successfully",
      });
      callBack?.();
    });
  };

  return {
    inputLists,
    handleSubmit,
    isLoading,
    onSubmit,
    hookForm,
  };
};
