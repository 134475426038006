import { APP_ROUTES } from "routes";

import ActivityIcon from "assets/images/svgs/ActivityIcon";
import DocumentIcon from "assets/images/svgs/DocumentIcon";
import { CardProps } from "components/common/types";
import { formatNumberToCurrency } from "utils";
import { StyledInvoiceIconWrapper } from "components/invoices/styles";

export const EXPENSE_SALES_PAGE_TABS = [
  {
    key: "sales",
    label: "Sales",
    link: `${APP_ROUTES.SALES}`,
    secondaryLink: `${APP_ROUTES.EXPENSE_AND_SALES}`,
  },
  {
    key: "expense",
    label: "Expenses",
    link: `${APP_ROUTES.EXPENSE}`,
  },
];

export const EXPENSE_CARDS_DATA = ({
  totalExpenseValue,
  expenseCount,
}: {
  totalExpenseValue: number;
  expenseCount: number;
}): CardProps[] => {
  return [
    {
      title: `Total Expense Value`,
      icon: () => (
        <StyledInvoiceIconWrapper color="#FBECDB">
          <ActivityIcon style={{ stroke: "#F1A85A" }} />
        </StyledInvoiceIconWrapper>
      ),
      amount: formatNumberToCurrency({
        number: Number(totalExpenseValue),
      }),
    },
    {
      title: `Expense Count`,
      icon: () => (
        <StyledInvoiceIconWrapper color="#E8F5F1">
          <DocumentIcon style={{ stroke: "#34A853" }} />
        </StyledInvoiceIconWrapper>
      ),
      amount: formatNumberToCurrency({
        number: Number(expenseCount),
        removeCurrency: true,
      }),
    },
  ];
};
