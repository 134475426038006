import { useNavigate } from "react-router-dom";
import { useVeedezApiRequest } from "./../../hooks/useApiRequest";

// Custom Components
import { showToast } from "components/common";

// Utils
import { VALUE_ADDED_SERVICES_ROUTES } from "api/api.routes";
import { APP_ROUTES } from "routes";
// import transaction listing hooks here when api available

export type IPurchaseCable = {
  accountName: string;
  customerId: number;
  plan: string;
  saveBeneficiary: boolean;
  provider: string;
};

// Functionality
export const usePurchaseCable = () => {
  const makeRequest = useVeedezApiRequest();
  const navigate = useNavigate();

  const purchaseCable = async (data: IPurchaseCable, pin: string) => {
    try {
      await makeRequest.post(
        VALUE_ADDED_SERVICES_ROUTES.PURCHASE_CABLE,
        {
          customerName: data.accountName,
          smartCardNumber: data.customerId,
          packageName: data.plan,
          isBoxOffice: false,
        },
        {
          headers: {
            "x-transaction-pin": pin,
          },
        }
      );

      showToast({
        title: "Successful!",
        subText: "Your transaction was successful",
      });
      navigate(`${APP_ROUTES.ACCOUNT}`);
    } catch (e: any) {
      showToast({
        type: "error",
        title: "An error occurred",
        subText: e?.response?.data?.message || "Please try again",
      });
    }
  };

  return {
    purchaseCable,
  };
};
