import clsx from "clsx";
import { VFormProps } from "components/common/types";
import { VInput, VSelect, VFileUpload, VSwitch } from "components/common";
import { StyledVForm } from "./styles";
import VSecretPin from "../VSecretPin";
import { Controller } from "react-hook-form";
import {
  StyledRequiredDecorator,
  StyledVFormError,
  StyledVFormInput,
  StyledVFormLabel,
} from "../VInput/styles";
import { Fragment } from "react";

export const VForm = ({
  inputLists,
  vFormClassName = "",
  children,
  hookForm,
}: VFormProps) => {
  const {
    control,
    formState: { errors },
  } = hookForm;

  return (
    <StyledVForm>
      <div className={clsx(vFormClassName)}>
        {inputLists.map(
          ({
            inputType,
            className = "",
            options = [],
            name,
            validation,
            children,
            onChange,
            ...rest
          }) => {
            if (inputType === "select") {
              return (
                <div key={name} className={clsx(className, "form__input")}>
                  <VSelect
                    control={control}
                    name={name}
                    onChange={onChange}
                    options={options}
                    error={errors[name]}
                    validation={validation}
                    {...rest}
                  />
                </div>
              );
            }
            if (inputType === "upload") {
              return (
                <div key={name} className={clsx(className, "form__input")}>
                  <VFileUpload
                    control={control}
                    name={name}
                    error={errors[name]}
                    validation={validation}
                    {...rest}
                  />
                </div>
              );
            }

            if (inputType === "switch") {
              return <VSwitch name={name} {...rest} />;
            }
            if (inputType === "otp") {
              return (
                <div key={name} className={clsx(className, "form__input")}>
                  <StyledVFormInput type={rest?.type || "text"}>
                    {rest?.label && (
                      <StyledVFormLabel htmlFor={name}>
                        {rest?.label}{" "}
                        {rest?.required && (
                          <StyledRequiredDecorator>*</StyledRequiredDecorator>
                        )}
                      </StyledVFormLabel>
                    )}
                    <Controller
                      control={control}
                      rules={validation}
                      name={name}
                      render={({ field }) => {
                        const { onChange, value } = field;
                        return (
                          <>
                            <VSecretPin
                              onChange={onChange}
                              value={value}
                              isInputSecure
                              numInputs={rest?.numInputs || 4}
                              hasErrored={!!errors[name]?.message}
                              shouldAutoFocus={rest?.shouldAutoFocus}
                            />
                            <StyledVFormError>
                              {errors[name]?.message}{" "}
                            </StyledVFormError>
                          </>
                        );
                      }}
                    />
                  </StyledVFormInput>
                </div>
              );
            }

            if (inputType === "element") {
              return <Fragment key={name}>{children}</Fragment>;
            }

            return (
              <div key={name} className={clsx(className, "form__input")}>
                <VInput
                  control={control}
                  name={name}
                  error={errors[name]}
                  validation={validation}
                  {...(rest.type === "tel" && { maxLength: 10 })}
                  {...rest}
                />
              </div>
            );
          }
        )}
      </div>
      {children && <>{children}</>}
    </StyledVForm>
  );
};

export default VForm;
