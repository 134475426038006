// Core
import { gql, useMutation } from "urql";

// Custom Components
import { showToast } from "components/common";

// Assets
import { handleRequestCall } from "utils/handleRequestCall";

// types
export type KycLevelThreeProps = {
  cac: string;
  passcode: string;
};

// GraphQL
const completeBusinessLevelThreeMutation = gql`
  mutation ($cac: String, $passcode: String) {
    completeBusinessLevelThree(cac: $cac, passcode: $passcode) {
      success
    }
  }
`;

// Functionality
export const useCompleteBusinessLevelThree = () => {
  const [completeBusinessLevelThree, setCompleteBusinessLevelThree] =
    useMutation(completeBusinessLevelThreeMutation);

  const handleCompleteBusinessLevelThree = async (
    data: KycLevelThreeProps,
    callBack?: (data: Record<string, any>) => void
  ) => {
    try {
      // console.log(data);

      const req = await setCompleteBusinessLevelThree(data);
      await handleRequestCall({ request: req, callBack });
    } catch (e) {
      showToast({
        type: "error",
        title: "An error ocucured",
        subText: "Please try again",
      });
      console.error(e);
    }
  };

  return {
    completeBusinessLevelThree,
    handleCompleteBusinessLevelThree,
  };
};
