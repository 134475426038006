import { CreatedAtType } from "../types";
import { gql, useQuery } from "urql";
import { useAuthUser } from "store";

type GetInvoiceReportType = {
  businessId: string;
  createdAt: CreatedAtType["createdAt"];
  invoiceGraphType: string;
};

export const useGetInvoiceReport = ({
  createdAt,
  businessId,
  invoiceGraphType,
}: GetInvoiceReportType) => {
  return useQuery({
    query: combinedQuery,
    variables: {
      createdAt,
      invoiceGraphType,
    },
  });
};

const combinedQuery = gql`
  query (
    $createdAt: CreatedAtInputSale
    $invoiceGraphType: InvoiceGroupingTypeInput
  ) {
    getInvoicesStat(filter: { createdAt: $createdAt })
    getInvoiceByTime(type: $invoiceGraphType) {
      identifier
      type
      invoiceTotal
    }
  }
`;

export const useGetInvoiceReportHook = ({
  createdAt,
  invoiceGraphType,
}: {
  createdAt?: CreatedAtType["createdAt"];
  invoiceGraphType: string;
}) => {
  const { userData } = useAuthUser();
  const [result, refetchAll] = useGetInvoiceReport({
    createdAt: createdAt,
    businessId: userData?.businessId as string,
    invoiceGraphType,
  });

  return {
    result,
    refetchAll,
    data: (result?.data || {
      getInvoicesStat: [],
      getInvoiceByTime: [],
    }) as IInvoicesReport,
  };
};

export interface IInvoiceStat {
  _id: string;
  count: number;
  invoiceValue: number;
}

export interface IInvoiceByTime {
  identifier: string;
  type: string;
  invoiceTotal: number;
}

export interface IInvoicesReport {
  getInvoicesStat: IInvoiceStat[];
  getInvoiceByTime: IInvoiceByTime[];
}
