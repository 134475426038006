import styled from "styled-components";
import { stylingUtils } from "styles";

const {
  mediaQuery: { down },
} = stylingUtils;

export const StyledInvoiceSummaryCards = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  width: 100%;
  gap: 1rem;

  ${down("laptop")} {
    overflow-x: scroll;
  }
`;
