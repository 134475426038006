export const Colors = {
  defaultBlue: "#2D77F1",
  lightBlue: "#0077FF",
  lightBlue2: "#80BBFF",
  lightBlue3: "#475E80",
  lightBlue4: "#5976A1",
  lightBlue5: "#F2F8FF",
  lightBlue6: "#7890B5",
  lightBlue7: "#EBF4FF",
  lightBlue8: "#CEEAFF",
  lightBlue9: "#E7F3FF",
  lightBlue10: "#0077ff1a",
  lightGreen1: "#F8DAC0",
  lightGreen2: "#DBECE7",
  lightGrey2: "#69778C",
  lightGrey3: "#E8EBEE",
  lightGrey4: "#DCE3E7",
  lightGrey5: "#6F6E70",
  lightGrey6: "#C1CACF",
  lightGreen7: "#E5F4E9",
  darkGrey: "#374B58",
  darkGrey2: "#101010",
  darkGrey3: "#19181A",
  darkGrey4: "#001928",
  SB: "#D4DEED",
  WB: "#EAEAEA",
  gray: "#798892",
  lightBlack: "#434343",
  lightGray: "#F4F4F4",
  badgeGreen: "#1dc5751a",
  // NEW COLORS
  black: "#000",
  white: "#fff",
  offWhite: "#F7FBFE",
  offWhite2: "#f9fafc",
  textLight: "#5976a1",
  faintBlue: "#f2f8ff",
  paleBlue: "#dfefff",
  blueBorder: "#d7eeff",
  textBlue: "#0177ff",
  mediumBlue: "#cee9ff",
  danger: "#ea4336",
  borderFaintGray: "#f3f4f5",
  borderFaintGray2: "#e7ebee",
  buttonGray: "#f1f1f1",
  faintBlueBackground: "#eef8ff",
  mediumBlueBackground: "#daefff",
  iconGray: "#9ca8af",
  fadedGreen: "#e8f9f1",
  green: "#34A853",
  lightGreen: "#D6EEDD",
  lightGreen3: "#D2F3E3",
  disabledBlue: "#80bbff",
  textWarning: "#f47200",
  fadedOrange: "#fef1e6",
  offWhiteBackground: "#f4f8fb",
  bannerBlue: "#0074ff",
  // borderG
  // LEGACY
  whiteOverlay: "#ffffffBB",
  alerzoLightBlue: "#EBF4FF",
  alerzoLightOrange: "#fbd5ad",
  alerzoFadedBlue: "#C7E1FF",
  alerzoBlack: "#001928",
  alerzoGreen: "#001C35",
  alerzoOrange: "#F1A85A",
  alerzoOrangeLight: "rgba(241, 168, 90, 0.1)",
  alerzoOrangeLight2: "#FDF3E7",
  alerzoOrangeDark: "#001c1f",
  alerzoGreyFive: "#C1CACF",
  alerzoBlueTwo: "#7890B5",
  alerzoBlueThree: "#007BFF",
  header: "#3B3C40",
  headerBackground: "#e8f4fe",
  greyTwo: "#A5B0B7",
  greyTwoDark: "#E9E9EA",
  transparent: "transparent",
  //  lightBlue: '#004CA3',
  lightYellow: "#F1EFDD",
  yellow: "#DCBC57",
  transpaymentGreen: "rgba(52, 168, 83, 0.2)",
  transparentLine: "rgba(193, 202, 207, 0.5)",
  fadeGrey: "#5976A1",
  grey4: "#F0F2F5",
  fadeGrey3: "#8B9FBD",
  grey: "#DCE3E7",
  grey2: "#EEF1F7",
  ash: "#DCE2E8",
  deepAsh: "#4F657B",
  alerzoRed: "#EA4336",
  alerzoLightRed: "#FCF2F3",
  alerzoLightRed2: "#FFF1F1",
  background: "#F8FCFF",
  deepGrey: "rgba(55, 75, 88, 0.7)",
  backgroundTHREE: "#F2F8FF",
  lightBlueTwo: "#D8EEFF",
  lightBlueThree: "#D8EEFF",
  lightBlueFive: "#E8F5FF",
  whiteOne: "#B7D8FF",
  greyFour: "#66757E",
  greyFive: "#C1CACF",
  pink: "#e8c7c4",
  orange: "#FD7B2F",
  lightBlueFourDark: "#001933",
  shadow: "#879AA5",
  shadow2: "#91999D",
  headerBody: "#BFCBD2",
  overLay: "rgba(0, 0, 0, 0.7)",
  overLay2: "rgba(0, 0, 0, 0.3)",
  overLay3: "rgba(0, 0, 0, 0.03)",
  textInputGrey: "#EAEEF0",
  addBank: "#F7F4EF",
  addBankDark: "#181a1c",
  contactsBackground: "#80BCFF",
  opacity: "#d4ebff",
  transparentBlue: "#EBF4FF",
  activeCard: "#E0EFFF",
  activeCardBorder: "#288CFF",
  listBorder: "#F3F3F6",
  lightBlueSix: "#D9EBFF",
  businessbackground: "#F0F9FF",
  accountNumber: "#5976A1",
  alerzoDark: "#000C19",
  alerzolightRed: "rgba(234, 67, 54, 0.1);",
  darkLightblue: "#EBEBEB",
  lightgrey: "#E8EBEE",
  shade: "#f2f8fe",
  greyOne: "#CCCCCC",
  sweetGreen: "rgba(52, 168, 83, 0.2)",
  cardDark: "#001833",
  webView: "#1a202c",
  pinBorder: "#E8EBEE",
  dropDown: "#9CA8AF",
  lightBg: "rgba(0, 119, 255, 0.2)",
  inputBorder: "#1A2F47",
  cardBackground: "#001833",
  InputBg: "rgba(165, 176, 183, 0.1)",
  smallBackground: "#00244C",
  backDrop: "rgba(55, 75, 88, 0.7)",
  mainBackground: "rgba(216, 238, 255, 0.51)",
  mainBackground2: "rgb(249, 249, 251)",
  blueColor: "#005FCB",
  tranferMoneybg: "rgba(0, 119, 255, 0.1)",
  airtimebg: "rgba(0, 149, 76, 0.1)",
  bgColor: "#F6FAFD",
  green1: "#1DC575",
};
