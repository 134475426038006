import { IPagination } from "./../types";
import { gql, useQuery } from "urql";

type useGetTerminalTransactionsProps = {
  size: number;
  page?: number;
  terminalId?: string;
};

export interface ITerminalTransactions extends IPagination {
  nodes: {
    userId: string;
    terminalId: string;
    tId: string;
    terminalName: string;
    transactionType: string;
    transactionAmount: number;
  }[];
}

const getTerminalTransactions = gql`
  query ($page: Int, $size: Int, $terminalId: String) {
    getTerminalTransactions(page: $page, size: $size, terminalId: $terminalId) {
      nodes {
        userId
        terminalId
        tId
        terminalName
        transactionType
        transactionAmount
      }
      pageInfo {
        currentPage
        size
        totalCount
        hasNextPage
      }
    }
  }
`;

export const useGetTerminalTransactions = ({
  page = 1,
  size,
  terminalId,
}: useGetTerminalTransactionsProps) => {
  return useQuery({
    query: getTerminalTransactions,
    variables: {
      page,
      size,
      terminalId,
    },
  });
};
