import clsx from "clsx";
import { memo } from "react";
import { accessDeepObject, truncateWord } from "utils";
import { CustomTableProps } from "../types";
import { VEmptyState } from "../VEmptyState";
import { VLoader } from "../VLoader";
import { VTooltip } from "../VTooltip";
import {
  StyledVTableCard,
  StyledVTableCardsElement,
  StyledVTableCardsWrapper,
} from "./vtable-cards.styles";

type CustomTableCardProps = Omit<CustomTableProps, "pages">;

const TableCardValue = ({ value }: { value: any }) => {
  const isStringValue = ["string", "number"].includes(
    (typeof value)?.toLowerCase()
  );
  return (
    <VTooltip
      children={
        <p
          style={{
            wordWrap: "break-word",
            inlineSize: "80px",
            width: "100%",
          }}
        >
          {" "}
          {isStringValue ? truncateWord(value, { length: 15 }) : value}
        </p>
      }
      content={isStringValue ? truncateWord(value, { length: 50 }) : " "}
    />
  );
};

const VTableCards = (props: CustomTableCardProps) => {
  const { columns, data, handleClick, useId, isLoading, emptyContent } = props;

  if (!data?.length) return <VEmptyState {...emptyContent} />;
  if (isLoading) return <VLoader />;

  return (
    <StyledVTableCardsWrapper>
      {data.map((tableData, rowIndex) => {
        const { id = "" } = tableData;

        return (
          <StyledVTableCard
            onClick={() => {
              if (handleClick) {
                handleClick(tableData);
              }
            }}
            key={`card-data-${id || rowIndex}`}
          >
            <div className="card-element">
              {columns.map((rowConfig, index) => {
                const {
                  renderCell,
                  key = "",
                  headerName,
                  width,
                  formatter,
                  isBold = false,
                } = rowConfig;

                let rowValue: JSX.Element | string = <div></div>;

                if (key === "action") {
                  rowValue = (
                    <div style={{ ...(width && { width: width }) }} key={index}>
                      {}
                      {tableData[key]}
                    </div>
                  );
                }

                rowValue = accessDeepObject({ key, data: tableData });

                if (useId && !rowValue) {
                  rowValue = (rowIndex + 1).toString();
                }

                if (formatter) {
                  rowValue = formatter(rowValue, tableData);
                }

                if (renderCell) {
                  rowValue = (
                    <div key={index}>
                      {renderCell(rowValue, tableData) ?? "N/A"}
                    </div>
                  );
                }

                const isDropdown = ["dropdown", "action"].includes(
                  key?.toLowerCase()
                );

                if (isDropdown) {
                  if (renderCell) {
                    const baseElement = renderCell(rowValue, tableData) as any;
                    const elements = baseElement?.props?.dropdownItems ?? [];

                    if (elements.length) {
                      rowValue = (
                        <div key={index} className="grid-action">
                          {elements.map((el: any) => (
                            <div
                              style={el?.styles}
                              onClick={() => el?.onClick()}
                              key={el.key}
                            >
                              {el?.label}
                            </div>
                          ))}
                        </div>
                      );
                    } else {
                      rowValue = (
                        <div key={index} className="grid-action">
                          {baseElement}
                        </div>
                      );
                    }
                  }
                }

                return (
                  <StyledVTableCardsElement
                    key={`card-element-${index}`}
                    className={clsx(
                      "body__data",
                      isBold && "bold__text",
                      isDropdown && "col-span-full"
                    )}
                  >
                    {!isDropdown && (
                      <p className="vtable-card-label">{headerName}</p>
                    )}

                    <p className="vtable-card-value">
                      {/* <TableCardValue value={rowValue} /> */}
                      {isDropdown ? (
                        rowValue
                      ) : (
                        <TableCardValue value={rowValue ?? "N/A"} />
                      )}
                      {/* {rowValue ?? 'N/A'} */}
                    </p>
                  </StyledVTableCardsElement>
                );
              })}
            </div>
          </StyledVTableCard>
        );
      })}
    </StyledVTableCardsWrapper>
  );
};

export default memo(VTableCards);
