import React from "react";
import { IPosStatus } from "../constants";
import { StatusCardStyles } from "./styles";
import BlueCheck from "assets/images/svgs/BlueCheck";
import RedCheck from "assets/images/svgs/RedCheck";
import GreyPin from "assets/images/svgs/GreyPin";

const StatusCard: React.FC<IPosStatus> = ({
  done,
  title,
  date,
  rejected,
  index,
}) => {
  return (
    <>
      <StatusCardStyles done={done} rejected={rejected}>
        <div className="text">
          <h5>{title}</h5>
          <p>{date}</p>
        </div>

        {done ? <BlueCheck /> : rejected ? <RedCheck /> : null}
      </StatusCardStyles>
      {index !== 5 && (
        <div className="pin">
          <GreyPin />
        </div>
      )}
    </>
  );
};

export default StatusCard;
