import { useGetSalesReportHoook } from "api/report";
import { SALES_CARD_DATA } from "components/reports";
import { REPORT_FILTER_DATE_ENUM } from "components/reports/constants";

import { useMemo } from "react";
import { FinalDateMapperProps, getMappedDate } from "utils/dateMapper";
import useReportFilterHook from "./useReportFilterHook";

export const useSalesReportPageHook = () => {
  const { dateFilter, date } = useReportFilterHook();

  const { data } = useGetSalesReportHoook({
    createdAt: dateFilter.createdAt,
    profitLossType: date,
  });

  const { getSaleStat, getProfitAndLoss } = data;

  const cardRef = JSON.stringify(getSaleStat);

  const profitAndLossRef = JSON.stringify(getProfitAndLoss);

  const cardData = useMemo(() => {
    return SALES_CARD_DATA({ data: getSaleStat });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardRef, date]);

  const aggregateProfitAndLoss = useMemo(() => {
    const currentDate = new Date();
    const currentDay = currentDate?.getDate();
    const currentHour = currentDate?.getHours();
    const currentMonth = currentDate?.getMonth() + 1;

    const mappedDate = getMappedDate(date as REPORT_FILTER_DATE_ENUM, {
      hourEnd: currentHour,
      hourStart: currentHour - 3 + 1,
      dayEnd: currentDay,
      dayStart: currentDay - 3 + 1,
      monthEnd: currentMonth,
      monthStart: currentMonth - 3 + 1,
    });

    if (!getProfitAndLoss.length) {
      return Object.values(mappedDate) as FinalDateMapperProps[];
    }
    const newData = { ...mappedDate };

    getProfitAndLoss.forEach((data) => {
      const { identifier } = data;
      const value = (newData as Record<string, any>)[identifier];

      if (value) {
        (newData as Record<string, any>)[identifier] = {
          ...value,
          totalLoss: Math.abs(data?.totalLoss || 0),
          totalProfit: Math.abs(data?.totalSaleProfit || 0),
        };
      }
    });

    return Object.values(newData) as FinalDateMapperProps[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profitAndLossRef, date]);

  return {
    cardData,
    data,
    aggregateProfitAndLoss,
  };
};
