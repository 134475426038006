import { useEffect } from "react";

function useOutsideClick(ref: any, callBack?: any) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        callBack && callBack();
      }
    }

    ["mousedown", "touchend"].forEach((e) => {
      document.addEventListener(e, handleClickOutside);
    });
    // Bind the event listener
    return () => {
      ["mousedown", "touchend"].forEach((e) => {
        document.removeEventListener(e, handleClickOutside);
      });
      // Unbind the event listener on clean up
    };
  }, [ref, callBack]);
}

export default useOutsideClick;
