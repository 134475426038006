import { StyledPermissionModuleCard } from "components/manage-business/roles-and-permissions";
import styled from "styled-components/macro";
import { StyledVCardContainer, stylingUtils } from "styles";

const { rem } = stylingUtils;

export const StyledUserProfileCard = styled(StyledVCardContainer)``;

export const StyledUserDetailsTag = styled(StyledPermissionModuleCard)`
  display: grid;
  grid-template-columns: 1fr 4fr;
  align-items: center;

  span {
    font-size: ${rem(14)};
    color: #374b58;
    display: flex;
    align-items: center;
    gap: 2rem;
  }
`;
