import ExportIcon from "assets/images/svgs/ExportIcon";
import { VButton, VChip, VChipGroup } from "components/common";
import { useLocalSearchParams, useWindowSize } from "hooks";
import { DayJs, getUrlQueryEntries } from "utils";
import { REPORT_FILTER_CHIPS } from "./constants";
import Pdf from "react-to-pdf";
import { StyledReportFilter, FilterModal } from "./styles";
import { useCallback, useEffect, useState } from "react";
import FilterIcon from "assets/images/svgs/FilterIcon";

export const ReportFilter = ({
  exportRef,
  fileName = "report-filter",
  exportOptions = {},
}: {
  exportRef?: React.RefObject<HTMLDivElement>;
  fileName?: string;
  exportOptions?: {
    options?: { orientation: string };
    scale?: number;
  };
}) => {
  const { date = "today" } = getUrlQueryEntries();
  const { addSearchParams } = useLocalSearchParams();
  const [dropdown, setDropdown] = useState<any>(false);
  const [selectedChip, setSelectedChip] = useState<string>(date);

  const { width } = useWindowSize();

  const isMobile = width && width <= 500;

  useEffect(() => {
    setSelectedChip(date || REPORT_FILTER_CHIPS[0]?.key);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [REPORT_FILTER_CHIPS?.length, date]);

  const handleReportFilter = (date: string) => {
    addSearchParams("date", date);
  };

  const handleChipClick = useCallback(
    (chipKey: string) => {
      setSelectedChip(chipKey);
      handleReportFilter(chipKey);
      setDropdown(!dropdown);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const newFileName = `${fileName}-${DayJs()
    ?.toISOString()
    .substring(0, 10)}.pdf`;

  const allExportOptions = { scale: 0.7, ...exportOptions };

  return (
    <div style={{ position: "relative" }}>
      <VChipGroup
        activeKey={date}
        getCurrentChipKey={(date) => handleReportFilter(date)}
        chips={REPORT_FILTER_CHIPS}
      >
        <>
          <Pdf
            targetRef={exportRef}
            filename={newFileName}
            {...allExportOptions}
          >
            {({
              toPdf,
            }: {
              toPdf: React.MouseEventHandler<HTMLButtonElement>;
            }) => (
              <VButton
                onClick={toPdf}
                text="Export Report"
                isOutline
                height="30px"
                isSlim
                bgColor="#E57A1A"
                noborder={false}
                icon={<ExportIcon width={20} height={20} />}
              />
            )}
          </Pdf>
          <StyledReportFilter>
            <FilterIcon onClick={() => setDropdown(!dropdown)} />
          </StyledReportFilter>
        </>
        {dropdown && isMobile && (
          <FilterModal>
            {REPORT_FILTER_CHIPS?.map(({ key, label }: any) => {
              return (
                <VChip
                  chipClassName={"chip"}
                  isActive={selectedChip === key}
                  key={key}
                  label={label}
                  onClick={() => handleChipClick(key)}
                />
              );
            })}
          </FilterModal>
        )}
      </VChipGroup>
    </div>
  );
};
