import { showToast } from "components/common";
import { gql, useMutation } from "urql";
import { handleRequestCall } from "utils/handleRequestCall";

export type UseAddRolesAndPermissionsMutation = {
  roleName: string;
  permissions: IRolesAndPermission[];
};

export interface IRolesAndPermission {
  permission: string;
  module: string;
}

const addRolesAndPermissionsMutation = gql`
  mutation ($roleName: String, $permissions: [PermissionInput]) {
    saveRoleAndPermission(roleName: $roleName, permissions: $permissions) {
      _id
      roleName
      permissions {
        _id
        permission
        permissionDescription
        module
      }
    }
  }
`;

export const useAddRolesAndPermissionsMutation = () => {
  const [newRoleAndPermissions, addNewRoleAndPermissions] = useMutation(
    addRolesAndPermissionsMutation
  );

  const handleAddNewRoleAndPermissions = async (
    data: UseAddRolesAndPermissionsMutation,
    callBack?: () => void
  ) => {
    try {
      const req = await addNewRoleAndPermissions(data);
      await handleRequestCall({ request: req, callBack });
    } catch (e) {
      console.error(e);
      showToast({
        type: "error",
        title: "An error occurred",
        subText: "Please try again",
      });
    }
  };

  return {
    handleAddNewRoleAndPermissions,
    newRoleAndPermissions,
  };
};
