import { formatUnderScore } from "utils";
import {
  StyledSDCAvatar,
  StyledSDCAvatarWrapper,
  StyledSDCStatus,
  StyledStaffData,
  StyledStaffDataWrapper,
  StyledStaffDetailsCard,
  StyledStaffDetailsCardHeaderWrapper,
  StyledStaffDivider,
  StyledStaffInfo,
  StyledStaffLabelAndValue,
  StyledStaffName,
  StyledMobileStaffName,
} from "components/staff/styles";
import { DEBTOR_DETAILS_HEADER } from "./constants";
import { ICustomerDetails } from "api/customers/types";

export const DebtorDetailsCardHeader = ({
  debtorDetails,
}: {
  debtorDetails: ICustomerDetails;
}) => {
  const { customerName = "", status = "" } = debtorDetails;

  const mockedAvatar = `${customerName?.slice(0, 2)}`;

  const renderStaffDetails = ({
    key,
    formatter,
    label,
  }: {
    key: string;
    formatter?: Function;
    label: string;
  }) => {
    let value = ({ ...debtorDetails } as Record<string, any>)[key] ?? "N/A";

    const canFormat = formatter && value && value !== "N/A";

    if (canFormat) {
      value = formatter(value);
    }

    return (
      <StyledStaffLabelAndValue key={key}>
        <h3>{value ?? "N/A"}</h3>
        <p>{label}</p>
      </StyledStaffLabelAndValue>
    );
  };

  const isActiveCustomer = (status as string)?.toLowerCase() === "active";

  return (
    <StyledStaffDetailsCard>
      <StyledStaffDetailsCardHeaderWrapper>
        <StyledStaffInfo>
          <StyledSDCAvatarWrapper>
            <StyledSDCAvatar isActive={isActiveCustomer}>
              <h3>{mockedAvatar}</h3>
            </StyledSDCAvatar>
            <StyledSDCStatus isActive={isActiveCustomer}>
              {" "}
              {status}
            </StyledSDCStatus>
            <StyledMobileStaffName>
              <span>{formatUnderScore(customerName)}</span>
            </StyledMobileStaffName>
          </StyledSDCAvatarWrapper>
          <StyledStaffDataWrapper>
            <StyledStaffData className="grid-fill">
              <StyledStaffName>
                <span>{formatUnderScore(customerName)}</span>
              </StyledStaffName>
              {DEBTOR_DETAILS_HEADER.TOP.map((data) =>
                renderStaffDetails(data)
              )}
            </StyledStaffData>
            <StyledStaffDivider />
            <StyledStaffData className="grid-fill">
              {DEBTOR_DETAILS_HEADER.BOTTOM.map((data) =>
                renderStaffDetails(data)
              )}
            </StyledStaffData>
          </StyledStaffDataWrapper>
        </StyledStaffInfo>
      </StyledStaffDetailsCardHeaderWrapper>
    </StyledStaffDetailsCard>
  );
};
