import { useUpdateUserPayment } from "api/business-registration/update-user-payment";
import { useEffect, useState } from "react";
import { AppStorage, STORGAGE_KEYS } from "utils";
import { useAlerzoPayBilling } from "./useAlerzoPayBilling";

export enum AUTHORIZATION_MODE {
  OTP = "otp",
  PIN = "pin",
  REDIRECT = "redirect",
}

const { addToStore } = new AppStorage();

export const useBillingModalHook = ({
  userBillingData,
  billingResponse,
  generalCallBack,
  isRegistration,
}: {
  userBillingData: Record<string, any>;
  billingResponse: Record<string, any>;
  generalCallBack?: () => void;
  isRegistration?: boolean;
}) => {
  const [authorizationMode, setAuthorizationMode] =
    useState<null | AUTHORIZATION_MODE>(null);
  const [response, setResponse] = useState<Record<string, any> | null>();
  const { isLoading, initiateCharge, verifyOtp } = useAlerzoPayBilling();

  const { handleUpdateUserPayment } = useUpdateUserPayment();

  useEffect(() => {
    setResponse(billingResponse);
    if (billingResponse?.mode === AUTHORIZATION_MODE.REDIRECT) {
      setAuthorizationMode(AUTHORIZATION_MODE.REDIRECT);
    }
  }, [billingResponse]);

  // For pin second step, confirm the opt
  const handleConfirmOtp = ({
    otp,
    callback,
  }: {
    otp: string;
    callback?: (response?: Record<string, any>) => void;
  }) => {
    verifyOtp({
      data: {
        reference: response?.flw_ref,
        otp,
      },
      callback: async (response) => {
        if (response?.error) {
          callback?.({ error: true });
        } else if (response?.message === "Charge completed") {
          generalCallBack?.();
          await handleUpdateUserPayment(response?.data?.tx_ref);
          callback?.({ error: false });
        }
      },
    });
  };

  // For avs, pin => reinitiate the chare
  const reinitiateCharge = (newData: Record<string, any>) => {
    initiateCharge({
      data: {
        ...userBillingData,
        authorization: {
          ...newData,
          mode: billingResponse?.mode,
        },
      },
      callback: (response) => {
        const responseMode = response?.data?.mode;
        const responseData = response?.data;

        if (responseMode === AUTHORIZATION_MODE.OTP) {
          setAuthorizationMode(AUTHORIZATION_MODE.OTP);
          setResponse(responseData);
        } else {
          if (responseMode === AUTHORIZATION_MODE.REDIRECT) {
            if (isRegistration) {
              addToStore(STORGAGE_KEYS.REDIRECT_BILLING_REGISTRATION, true);
            }
            window.open(responseData?.redirect, "_parent");
          }
        }
      },
    });
  };

  return {
    handleConfirmOtp,
    reinitiateCharge,
    authorizationMode,
    isLoading,
  };
};
