import { SidebarSvgProps } from "../../../components/sidebar/types";

const PaperIcon = (props: SidebarSvgProps) => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7378 0.761748H5.0848C3.0048 0.753748 1.2998 2.41175 1.2508 4.49075V15.2037C1.2048 17.3167 2.8798 19.0677 4.9928 19.1147C5.0238 19.1147 5.0538 19.1157 5.0848 19.1147H13.0738C15.1678 19.0297 16.8178 17.2997 16.8028 15.2037V6.03775L11.7378 0.761748Z"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.4751 0.75V3.659C11.4751 5.079 12.6231 6.23 14.0431 6.234H16.7981"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.2881 13.3594H5.88806"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.24321 9.60547H5.88721"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default PaperIcon;
