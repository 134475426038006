// import { useLazyQuery } from 'hooks';
import { gql, useQuery } from "urql";
// import { IKycDetails } from 'api/types';

type UseGetUserBusinessDetails = {
  businessId: string | null;
};

export const useGetUserKycDetails = ({
  businessId,
}: UseGetUserBusinessDetails) => {
  return useQuery({
    query: useGetKycDetail,
    variables: { businessId },
    pause: businessId === null || businessId === undefined,
  });
};
export const useLazyGetKycDetails = () => {
  return useQuery({
    query: useGetKycDetail,
  });
};

export const useGetKycDetail = gql`
  query GET_KYC_DETAIL($businessId: String) {
    getKycDetail(businessId: $businessId) {
      kycLevel
      kyc {
        level
        bvn {
          value
          status
          type
        }
        selfie {
          value
          status
          type
        }
        cac {
          value
          status
          type
        }
        nin {
          status
          value
          type
        }
        proofOfId {
          value
          status
          type
        }
        proofOfAddress {
          value
          status
          type
        }
        indemnity {
          value
          status
          type
          rejectionNote
          requiresManualVerification
          metamapStatus
          support {
            firstName
            email
          }
        }
      }
    }
  }
`;
