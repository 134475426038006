import styled from "styled-components";
import { stylingUtils } from "styles";

const {
  mediaQuery: { down },
} = stylingUtils;

export const StyledVSecretPin = styled("div")`
  .otpContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    ${down("laptop")} {
      justify-content: space-evenly;
      flex-wrap: wrap;
      gap: 0.5rem;
      margin: 1rem 0;
    }
    ${down("mobileL")} {
      gap: 0rem;
    }
  }

  .otpInput {
    width: 62px !important;
    height: 58px;
    font-size: 1.5rem;
    font-weight: 500;
    border: 1px solid #dce3e7;
    border-radius: 10px;

    ${down("mobileL")} {
      width: 42px !important;
      height: 38px;
    }
  }

  .otpError {
    border: 1px solid #ea4336;
  }
`;
