import React, { useMemo } from "react";
import {
  StyledVFormInput,
  StyledVFormLabel,
  StyledRequiredDecorator,
  StyledVFormError,
} from "components/common/VInput/styles";
import Select from "react-select";
import { VSelectProps } from "components/common/types";
import { Controller } from "react-hook-form";

const SelectField = ({
  onChange,
  onBlur,
  value,
  isSlim,
  name,
  disabled,
  options,
  ...rest
}: {
  onChange: any;
  onBlur?: any;
  value?: any;
} & VSelectProps) => {
  // Memoized the styles
  const memoizedStyles = useMemo(() => {
    return {
      option: (provided: any, state: any) => ({
        ...provided,
        color: state.isSelected ? "#fff" : "black",
        backgroundColor: state.isSelected ? "rgb(0, 119, 255)" : "white",
      }),
      control: (provided: any) => ({
        ...provided,
        backgroundColor: "#ffffff",
        border: "1px solid rgba(193, 202, 207, 0.5)",
        boxSizing: "border-box",
        borderRadius: "10px",
        padding: "0px",
        fontSize: "12px",
        ...(isSlim && { minHeight: 30 }),
      }),
      ...(isSlim && slimStyle),
    };
  }, [isSlim]);

  // Memoised the value
  const computedValue = useMemo(() => {
    return options.filter((option) => {
      if (Array.isArray(value)) {
        const newValue = value?.find((item) => item.value === option.value);
        return newValue;
      } else {
        return option.value === value;
      }
    });
  }, [value, options]);

  return (
    <Select
      {...rest}
      name={name}
      onChange={(options) => {
        if (onChange) {
          if (Array.isArray(options)) {
            onChange(options?.map((option) => option.value));
          } else {
            onChange(options?.value);
          }
        }
      }}
      components={{
        IndicatorSeparator: () => null,
        ...(disabled && {
          MenuListener: () => null,
          Menu: () => null,
        }),
      }}
      value={computedValue}
      onBlur={onBlur}
      styles={memoizedStyles}
      options={options}
    />
  );
};

export const VSelect = React.forwardRef((props: VSelectProps, ref) => {
  const {
    options,
    name,
    label,
    control,
    validation,
    error,
    inputWrapperStyle,
    isSlim,
    className,
    optionValue,
    disabled,
    ...rest
  } = props;

  return (
    <StyledVFormInput
      type={rest?.type || ""}
      style={inputWrapperStyle}
      className={className}
    >
      {label && (
        <StyledVFormLabel htmlFor="item">
          {" "}
          {label}{" "}
          {rest?.required && (
            <StyledRequiredDecorator>*</StyledRequiredDecorator>
          )}
        </StyledVFormLabel>
      )}

      {control ? (
        <Controller
          name={name}
          rules={validation}
          control={control}
          render={({ field: { value, onChange, onBlur } }) => {
            return (
              <SelectField
                {...props}
                onChange={onChange}
                value={value}
                onBlur={onBlur}
              />
            );
          }}
        />
      ) : (
        <SelectField {...props} onChange={rest?.onChange} value={optionValue} />
      )}
      <StyledVFormError>{error?.message} </StyledVFormError>
    </StyledVFormInput>
  );
});

const slimStyle = {
  dropdownIndicator: (base: any) => ({
    ...base,
    padding: 4,
  }),
  clearIndicator: (base: any) => ({
    ...base,
    padding: 4,
  }),
  multiValue: (base: any) => ({
    ...base,
  }),
  valueContainer: (base: any) => ({
    ...base,
    padding: "0px 6px",
  }),
  input: (base: any) => ({
    ...base,
    margin: 0,
    padding: 0,
  }),
};
