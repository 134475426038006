import { useParams } from "react-router-dom";
import { useGetCustomerTerminal } from "api";
import { showToast } from "components/common";
import { useEffect, useState } from "react";

export const useRequestedPosDetailsHook = () => {
  const { posId } = useParams();
  const [terminalName, setTerminalName] = useState("");

  const [result] = useGetCustomerTerminal({
    terminalId: posId,
  });

  const terminalRequestData = result?.data?.getCustomerTerminal;

  const requestDate = terminalRequestData?.previousStatus[0]?.createdAt;

  const status: any = terminalRequestData?.status;

  const getTerminalType = () => {
    if (
      terminalRequestData &&
      terminalRequestData?.spec?.name === "Veedez Mini"
    ) {
      return "MPOS Terminal";
    } else if (
      terminalRequestData &&
      terminalRequestData?.spec?.name === "Veedez Maxi"
    ) {
      return "Android POS Terminal";
    } else {
      return "";
    }
  };

  useEffect(() => {
    const terminalType = getTerminalType();
    setTerminalName(terminalType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [terminalRequestData]);

  useEffect(() => {
    if (status === "REJECTED") {
      showToast({
        type: "error",
        title: "Request Rejected",
        subText:
          "Your request for terminal POS was declined because of multiple request. Kindly contact our support team at support@veedez.com.",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    terminalRequestData,
    requestDate,
    status,
    terminalName,
  };
};
