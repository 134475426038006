import styled from "styled-components";
import { FlexColumn, FlexJAMixin, stylingUtils } from "styles";
import theme from "styles/theme";

const {
  rem,
  color,
  mediaQuery: { down },
} = stylingUtils;

export const StyledStaffDetailsCard = styled("section")`
  background-color: white;
  border-radius: ${rem(15)};
  padding: ${rem(50)};
  ${FlexColumn};
  gap: ${rem(50)};

  // ${down("mobileL")} {
  //   padding: ${rem(20)} ${rem(15)};
  // }
  ${down("laptop")} {
    padding: ${rem(30)} ${rem(20)}};
  }
`;

export const StyledStaffDetailsCardHeaderWrapper = styled("section")`
  ${FlexColumn};
  gap: ${rem(30)};
`;

export const StyledStaffInfo = styled("section")`
  display: flex;
  gap: ${rem(40)};

  ${down("mobileL")} {
    flex-direction: column;
    // min-width: fit-content;
  }
  ${down("laptop")} {
    flex-direction: column;
  }
`;

export const StyledSDCAvatarWrapper = styled("div")`
  ${FlexColumn};
  gap: ${rem(10)};
  justify-content: center;
  text-align: center;
  position: relative;
  // ${down("laptop")} {
  //   width: fit-content;
  // }

  p {
    color: ${color("green")};
  }

  // ${down("mobileL")} {
  //   p {
  //     display: flex;
  //     justify-content: flex-start;
  //     margin-left: 12px;
  //   }
  // }

  // ${down("mobileS")} {
  //   p {
  //     margin-left: 5px;
  //   }
  }
`;

export const StyledSDCAvatar = styled("div")<{ isActive: boolean }>`
  width: ${rem(90)};
  height: ${rem(90)};
  border-radius: 9999%;
  background-color: ${color("transparentBlue2")};
  ${FlexJAMixin};
  position: relative;
  text-transform: uppercase;
  font-size: ${rem(25)};
  color: ${color("lightBlue")};

  &:after {
    content: "";
    width: ${rem(16)};
    height: ${rem(16)};
    border-radius: 9999%;
    background-color: ${({ isActive }) =>
      isActive ? theme.colors.green : theme.colors.toastRed};
    border: 1px solid white;
    position: absolute;
    bottom: ${rem(3)};
    right: ${rem(5)};
  }

  h3 {
    font-weight: 400;
  }
`;

export const StyledStaffDataWrapper = styled("section")`
  flex: 1;
  ${FlexColumn};
  gap: ${rem(20)};

  ${down("mobileL")} {
    flex-direction: row;
    width: 100%;
    gap: 0;
  }

  ${down("mobileM")} {
    justify-content: center;
  }
  // ${down("laptop")} {
  //   flex-direction: row;
  // }
`;

export const StyledStaffData = styled("section")`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  padding: 0 ${rem(40)};
  gap: ${rem(20)};
  ${down("laptop")} {
    grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));
    width: 50%;
    padding: 0;
  }

  &.grid-fill {
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  }

  // ${down("mobileL")} {
  //   min-width: fit-content;
  //   padding: 0;
  // }

  // ${down("mobileM")} {
  //   &.grid-fill {
  //     grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  //   }
  }
`;

export const StyledStaffName = styled("h3")`
  font-weight: 300;
  span {
    font-weight: 500;
    color: ${color("lightBlue")};
    font-size: ${rem(16)};
  }

  ${down("mobileL")} {
    display: none;
  }
`;

export const StyledMobileStaffName = styled("div")`
  display: none;

  ${down("mobileL")} {
    display: block;
    position: absolute;
    top: 1.5rem;
    left: 10rem;
    font-weight: 300;

    span {
      font-weight: 500;
      color: ${color("lightBlue")};
      font-size: ${rem(16)};
    }
  }

  @media only screen and (min-width: 320px) {
    left: 8rem;
  }
`;

export const StyledStaffDivider = styled("section")`
  height: 1px;
  background-color: ${color("ash01")};
`;

export const StyledStaffLabelAndValue = styled("div")`
  h3 {
    font-size: ${rem(14)};
    font-weight: 400;
  }
  p {
    font-size: ${rem(10)};
    margin-top: ${rem(5)};
    color: ${color("ash03")};
  }
`;

export const StyledStaffDetailsHeaderAction = styled("div")`
  display: flex;
  align-items: center;
  gap: ${rem(20)};
`;

export const StyledSDCStatus = styled("p")<{ isActive: boolean }>`
  ${down("laptopL")} {
    text-align: left;
  }
  color: ${({ isActive }) =>
    isActive ? theme.colors.green : theme.colors.toastRed}!important;
`;
