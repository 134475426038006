import { gql, useQuery } from "urql";

const getExpensesBreakdownQuery = gql`
  query ($businessId: String) {
    getExpensesBreakdown(businessId: $businessId, type: "")
  }
`;

export const useGetExpensesBreakdown = (businessId: string) => {
  return useQuery({
    query: getExpensesBreakdownQuery,
    variables: { businessId },
  });
};

const getExpensesStatQuery = gql`
  query ($businessId: String, $createdAt: RangeInput) {
    getExpenseStat(
      filters: { businessId: $businessId, createdAt: $createdAt }
    ) {
      totalExpense
      total
    }
  }
`;

export const useGetExpenseStat = ({
  businessId,
  createdAt,
}: {
  businessId: string;
  createdAt?: { lte: string; gte: string };
}) => {
  return useQuery({
    query: getExpensesStatQuery,
    variables: { businessId, createdAt },
  });
};
