import { useLocalSearchParams } from "hooks";
import { useCallback, useMemo } from "react";

export const useSelectedPeriodHook = () => {
  const { searchParams, addSearchParams } = useLocalSearchParams();
  const selectedPeriod = searchParams.get("period");

  const getDateForPeriod = (selectedPeriod: string): string => {
    const currentDate = new Date();

    switch (selectedPeriod) {
      case "daily":
        currentDate.setDate(currentDate.getDate());
        return currentDate.toISOString();
      case "weekly":
        currentDate.setDate(currentDate.getDate() - 7);
        return currentDate.toISOString();
      case "monthly":
        currentDate.setDate(currentDate.getDate() - 31);
        return currentDate.toISOString();
      case "yearly":
        currentDate.setDate(currentDate.getDate() - 365);
        return currentDate.toISOString();
      default:
        break;
    }

    return "";
  };

  const currentTimestamp = useMemo(() => {
    return new Date().toISOString();
  }, []);

  const periodTimestamp = useMemo(() => {
    return getDateForPeriod(selectedPeriod as string);
  }, [selectedPeriod]);

  const createdAt = {
    lte: currentTimestamp,
    gte: periodTimestamp,
  };

  const handleFilter = useCallback(
    (selectedPeriod: string) => {
      addSearchParams("period", selectedPeriod);
      addSearchParams("page", "1");
    },
    [addSearchParams]
  );

  return {
    createdAt,
    handleFilter,
  };
};
