import EditPenIcon from "assets/images/svgs/EditPenIcon";
import { VButton } from "components/common";
import { useModal } from "hooks";
import { EditUserModal } from "../edit-user";
import { StyledUserProfileHeader } from "./styles";

export const UserProfileHeader = () => {
  const { open, toggleModal } = useModal();

  return (
    <>
      <StyledUserProfileHeader>
        <VButton
          text={`Edit User Detail`}
          height="30px"
          icon={<EditPenIcon width={18} />}
          isSlim
          style={{ marginLeft: "auto" }}
          onClick={toggleModal}
        />
      </StyledUserProfileHeader>
      <EditUserModal open={open} close={toggleModal} />
    </>
  );
};
