import { useState } from "react";
import { Link } from "react-router-dom";

// components
import { SubMenu } from "./SubMenu";
import appStore from "assets/images/svgs/app_store.svg";
import playStore from "assets/images/svgs/play_store.svg";
// import Logo from 'assets/images/svgs/VeedezLogo';
import SettingIcon from "assets/images/svgs/SettingIcon";
import VeedezIcon from "assets/images/svgs/veedezIcon";
import VeedezProLogo from "assets/images/svgs/VeedezProLogo";

// styles
import { SideBarItemProps } from "./types";
import { APP_ROUTES } from "routes";
import {
  Card,
  H1,
  H2,
  H3,
  H4,
  Download,
  Buttons,
  ButtonImg,
  SideNav,
  NavbarsectionTop,
  CompanyWrapper,
  NavListWrapper,
  Line,
  NotActive,
  Dash,
  NavbarsectionBottom,
  Navbarsection,
} from "./Sidebar.styled";
import { WALKTHROUGH_IDS } from "utils/walkthrough";

export const Sidebar = ({
  sideBarData,
  setIsSideBarMinimzed,
  isSideBarMinimzed = false,
}: {
  sideBarData: SideBarItemProps[];
  setIsSideBarMinimzed?: React.Dispatch<React.SetStateAction<boolean>>;
  isSideBarMinimzed?: boolean;
}) => {
  const [activeTab, setActiveTab] = useState(
    sideBarData.map((data) => data.title?.toLowerCase())
  );

  const handleTabClick = (newTab: string) => {
    const isInTab = activeTab.includes(newTab?.toLowerCase());

    if (isInTab) {
      handleRemoveTab(newTab);
    } else {
      setActiveTab([...activeTab, newTab?.toLowerCase()]);
    }
  };

  const handleRemoveTab = (newTab: string) => {
    const removeTab = activeTab.filter((tab) => tab !== newTab?.toLowerCase());
    setActiveTab(removeTab);
  };

  const handleMininizeSideBar = () => {
    if (setIsSideBarMinimzed) {
      setIsSideBarMinimzed(!isSideBarMinimzed);
    }
  };

  return (
    <SideNav isSideBarMinimzed={isSideBarMinimzed}>
      <NavbarsectionTop isSideBarMinimzed={isSideBarMinimzed}>
        <CompanyWrapper
          onClick={handleMininizeSideBar}
          isSideBarMinimzed={isSideBarMinimzed}
        >
          {isSideBarMinimzed ? <VeedezIcon /> : <VeedezProLogo />}
        </CompanyWrapper>
        <NavListWrapper isSideBarMinimzed={isSideBarMinimzed}>
          {sideBarData
            ?.filter((c) => c !== null)
            ?.map((item, index) => {
              return (
                <SubMenu
                  id={item.id || item?.title}
                  item={item}
                  key={index}
                  handleTabClick={handleTabClick}
                  isSideBarMinimzed={isSideBarMinimzed}
                />
              );
            })}
        </NavListWrapper>
        <Line />
        <NotActive>
          <Dash
            id={WALKTHROUGH_IDS.MANAGE_BUSINESS}
            isSideBarMinimzed={isSideBarMinimzed}
          >
            <SettingIcon />
            {!isSideBarMinimzed && (
              <Link to={APP_ROUTES.STORES}>
                <H4>Manage Business</H4>
              </Link>
            )}
          </Dash>
        </NotActive>
      </NavbarsectionTop>
      {!isSideBarMinimzed && (
        <NavbarsectionBottom>
          <Navbarsection>
            <Card>
              <H2>
                Do more with your Veedez <br /> business manager
              </H2>
              <H3>Upgrade plan</H3>
            </Card>
            <Download>
              <H1>
                Use Veedez on mobile, <br />
                download now.
              </H1>
              <Buttons>
                <ButtonImg src={appStore} alt="app store" />
                <ButtonImg src={playStore} alt="play store" />
              </Buttons>
            </Download>
          </Navbarsection>
        </NavbarsectionBottom>
      )}
    </SideNav>
  );
};
