import styled from "styled-components";
import { FlexColumn, stylingUtils } from "styles";
import { rotate } from "../VButton/styles";

const { rem } = stylingUtils;

export const StyledVLoader = styled("div")`
  ${FlexColumn};
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  gap: 20px;
  background-position: center;
  background-size: cover;

  p {
    font-size: ${rem(19)};
  }
`;

export const StyledVLoaderIcon = styled("div")`
  animation: ${rotate} 2s linear infinite;
`;
