import { useLocation } from "react-router-dom";
import { SubMenuProps } from "./types";

// Styles
import {
  Dash,
  IconTextBox,
  SidenavHtext,
  H5,
  MenuLink,
  IconDiv,
  Subtitle,
} from "./SubMenu.styled";

export const SubMenu = ({
  item,
  isSideBarMinimzed,
  handleTabClick,
  id,
}: SubMenuProps) => {
  const location = useLocation();

  const handleActiveTab = () => {
    handleTabClick(item.title?.toLowerCase());
  };

  return (
    <>
      <Dash onClick={handleActiveTab} isSideBarMinimzed={isSideBarMinimzed}>
        <IconTextBox>
          <SidenavHtext>
            <H5>{item?.title}</H5>
          </SidenavHtext>
        </IconTextBox>
      </Dash>
      <div style={{ width: "100%" }}>
        {item.subNav
          ?.filter((t: any) => t !== null)
          .map((item, index) => {
            const { Icon, onClick } = item;
            const isActivePath = location.pathname.includes(item.path);

            return (
              <MenuLink
                id={item.id}
                to={item.path}
                $isSideBarMinimzed={isSideBarMinimzed}
                key={index}
                target={item.external ? "_blank" : "_self"}
                rel={item.external ? "noopener noreferrer" : "next"}
                onClick={() => {
                  onClick?.();
                }}
              >
                <IconDiv isActive={isActivePath} className="icon__element">
                  {Icon && (
                    <Icon
                      className="icon__element"
                      style={{
                        stroke: isActivePath ? "#0077FF" : "#130F26",
                        width: 15,
                      }}
                    />
                  )}
                </IconDiv>
                <Subtitle
                  isActive={isActivePath}
                  isSideBarMinimzed={isSideBarMinimzed}
                  className="subtitle__element"
                >
                  {item.title}
                </Subtitle>
              </MenuLink>
            );
          })}
      </div>
    </>
  );
};
