// import { ArrowIcon } from "assets/svgs";
import clsx from "clsx";
import { Fragment, useCallback, useState } from "react";
import { abbreviateText, accessDeepObject } from "utils";

import { CustomTableProps } from "../types";
import { StyledVTable } from "./styles";
import { ReactComponent as CheveronArrow } from "assets/icons/chevron-left.svg";
import { VEmptyState } from "../VEmptyState";
import { useWindowSize } from "hooks";
import VtableCards from "./vtable-cards";
import { omit } from "lodash";

export const VTable = (props: CustomTableProps) => {
  const {
    handleClick,
    isLoading = false,
    columns = [],
    data = [],
    useId,
    renderChildren,
    showHeader = true,
    checkCollapsible,
    collapsible = false,
    isSelectable,
    emptyContent,
    transparentHeader = false,
    smallPadding = false,
    ...rest
  } = props;

  const { width } = useWindowSize();

  const isMobile = width && width <= 767;

  const allHeaders = columns.map(({ key, headerName, width }) => ({
    key,
    headerName,
    width,
  }));

  const emptyState = !data?.length && !isLoading;

  const [expandedItems, setExpandedItems] = useState<any[]>([]);

  const isExpandedItem = useCallback(
    (key: any) => {
      return expandedItems.includes(key);
    },
    [expandedItems]
  );

  const toggleExpandedItem = useCallback(
    (key: any) => {
      if (isExpandedItem(key)) {
        setExpandedItems(expandedItems.filter((item) => item !== key));
      } else {
        setExpandedItems([...expandedItems, key]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [expandedItems]
  );

  let collapsibleSpanLength =
    allHeaders.length + Number(!!isSelectable) + Number(!!collapsible);

  if (emptyState) return <VEmptyState {...emptyContent} />;

  if (isMobile) {
    return <VtableCards {...omit(props)} />;
  }

  return (
    <div style={{ overflowX: "auto" }}>
      <StyledVTable
        smallPadding={smallPadding}
        cellSpacing="0"
        cellPadding="0"
        transparentHeader={transparentHeader}
        className={isLoading ? "is-loading" : ""}
        {...rest}
      >
        {showHeader && (
          <thead className="head__class">
            {
              <tr className="head__row">
                {isSelectable && (
                  <th className={clsx("head__body")}>
                    <input type="checkbox" className="table__radio" />
                  </th>
                )}
                {allHeaders.map(({ key = "", headerName = "", width }) => (
                  <th
                    key={key}
                    className="head__body"
                    style={{
                      ...(width && { width: width }),
                    }}
                  >
                    {headerName}
                  </th>
                ))}
                {collapsible && <th className={clsx("head__body")}></th>}
              </tr>
            }
          </thead>
        )}

        <tbody className="relative">
          {isLoading && (
            <tr className="loader">
              <td className="loader__element"></td>
            </tr>
          )}

          {data.map((tableData, rowIndex) => {
            const { id = "" } = tableData || {};
            const isCollapsible = renderChildren?.(tableData) || false;

            return (
              <Fragment key={id || rowIndex}>
                <tr
                  onClick={() => {
                    if (handleClick) {
                      handleClick(tableData);
                    }
                  }}
                  key={id || rowIndex}
                  className={clsx(
                    "body__row",
                    isLoading && "opacity-50 animate-pulse"
                  )}
                >
                  {isSelectable && (
                    <td style={{ width: 90 }} className={clsx("body__data")}>
                      <input className="table__radio" type="checkbox" />
                    </td>
                  )}
                  {columns.map((rowConfig, index) => {
                    const {
                      renderCell,
                      key = "",
                      width,
                      formatter,
                      useZero = true,
                    } = rowConfig;

                    if (key === "action") {
                      return (
                        <td
                          style={{ ...(width && { width: width }) }}
                          key={index}
                          className={clsx("body__data")}
                        >
                          {tableData[key]}
                        </td>
                      );
                    }

                    if (key === "description") {
                      return (
                        <td
                          style={{ ...(width && { width: width }) }}
                          key={index}
                          className={clsx("body__data")}
                          title={tableData[key]}
                        >
                          {abbreviateText(tableData[key], 50)}
                        </td>
                      );
                    }

                    let value = accessDeepObject({
                      key,
                      data: tableData,
                      useZero,
                    });

                    if (useId && !value) {
                      value = (rowIndex + 1).toString();
                    }

                    if (formatter) {
                      value = formatter(value, tableData);
                    }

                    if (renderCell) {
                      return (
                        <td
                          style={{ ...(width && { width: width }) }}
                          key={index}
                          className={clsx("body__data")}
                        >
                          {renderCell(value, tableData) ?? "N/A"}
                        </td>
                      );
                    }

                    return (
                      <td
                        style={{ ...(width && { width: width }) }}
                        key={index}
                        className={clsx("body__data")}
                      >
                        {value ?? "N/A"}
                      </td>
                    );
                  })}

                  {isCollapsible ? (
                    <td className={clsx("body__data")}>
                      <button
                        className={clsx(
                          "expandable__icon",
                          isExpandedItem(id || rowIndex) && "isExpanded"
                        )}
                        onClick={() => {
                          toggleExpandedItem(id || rowIndex);
                        }}
                      >
                        <CheveronArrow height={10} />
                      </button>
                    </td>
                  ) : (
                    <td />
                  )}
                </tr>
                <tr>
                  <td
                    className={clsx(
                      "table__children",
                      isExpandedItem(id || rowIndex)
                        ? "expanded__table--container"
                        : "collapsed__table--container"
                    )}
                    colSpan={collapsibleSpanLength}
                  >
                    {renderChildren &&
                      isExpandedItem(id || rowIndex) &&
                      renderChildren(tableData)}
                  </td>
                </tr>
              </Fragment>
            );
          })}
        </tbody>
      </StyledVTable>
    </div>
  );
};

export default VTable;
