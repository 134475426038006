import { gql, useQuery } from "urql";

export enum InvoiceSaleCheckType {
  INVENTORY = "INVENTORY",
  BUSINESS_CUSTOMER = "BUSINESS_CUSTOMER",
}

export interface ICheckInvoiceOrSales {
  type: InvoiceSaleCheckType;
  productId?: string;
  serviceId?: string;
}

const checkInvoiceOrSalesQuery = gql`
  query ($type: InvoiceSaleCheckType!, $productId: String, $serviceId: String) {
    hasInvoiceOrSales(type: $type, productId: $productId, serviceId: $serviceId)
  }
`;

export const useCheckInvoiceOrSales = ({
  type,
  productId,
  serviceId,
}: ICheckInvoiceOrSales) => {
  return useQuery({
    query: checkInvoiceOrSalesQuery,
    variables: { type, productId, serviceId },
  });
};
