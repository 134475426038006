import { lazy } from "react";

export const ReportExpensesSummary = lazy(
  () => import("./report-expenses-summary")
);
export const ReportExpenseNetWorthGraph = lazy(
  () => import("./report-expense-net-worth-graph")
);
export const ReportExpenseRatioGraph = lazy(
  () => import("./report-expense-ratio-graph")
);
