import { useOauthCallBackHook } from "hooks";
import { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuthUserConstants } from "store";
import { APP_ROUTES, SCREEN_ROUTES } from "./constants";
import { PasscodeAuthenticatedRoute } from "./PasscodeAuthenticatedRoute";
import { BaseRouteProps } from "./types";

/** Route layout provider */
const BaseRoute = ({
  isAuthenticatedRoute,
  children,
  title,
  wrapWithLayout = false,
}: {
  isAuthenticatedRoute?: boolean;
  children: JSX.Element;
  title: BaseRouteProps["title"];
  wrapWithLayout?: boolean;
}): JSX.Element => {
  const {
    isAuthenticated = false,
    status: { hasNotFinishBusinessRegistration },
  } = useAuthUserConstants();

  const location = useLocation();

  const resetPasscodePage = location?.pathname?.includes(
    APP_ROUTES.RESET_PASSCODE
  );

  const registeredBusinessPage = location?.pathname?.includes(
    "business-registration"
  );

  const isSpecialRoute = [resetPasscodePage, registeredBusinessPage].some(
    (c) => c
  );

  const { checkIsAuthenticated } = useOauthCallBackHook();

  const goToRegistrationPage =
    hasNotFinishBusinessRegistration && !isSpecialRoute;

  const goToAuthenticatedPage =
    !hasNotFinishBusinessRegistration && registeredBusinessPage;

  useEffect(() => {
    checkIsAuthenticated(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle navigation for authenticated and unathenticated
  if (isAuthenticatedRoute) {
    if (isAuthenticated) {
      // If the user has not registered business and is not in registered business page
      // Redirect to registered
      if (goToRegistrationPage) {
        return (
          <Navigate
            to={SCREEN_ROUTES.BUSINESS_REGISTRAION}
            state={{ from: location }}
          />
        );
      } else if (goToAuthenticatedPage) {
        return (
          <Navigate
            to={SCREEN_ROUTES.REDIRECT_AUTHENTICATED}
            state={{ from: location }}
          />
        );
      } else {
        return (
          <>
            {isSpecialRoute ? (
              children
            ) : (
              <PasscodeAuthenticatedRoute>
                {children}
              </PasscodeAuthenticatedRoute>
            )}
          </>
        );
      }
    } else {
      return (
        <Navigate
          to={SCREEN_ROUTES.REDIRECT_UNAUTHENTICATED}
          state={{ from: location }}
        />
      );
    }
  } else if (isAuthenticated) {
    return (
      <Navigate
        to={
          (location as any)?.state?.from?.pathname ||
          SCREEN_ROUTES.REDIRECT_AUTHENTICATED
        }
        state={{ from: location }}
      />
    );
  }

  return <>{children}</>;
};

export default BaseRoute;
