import { VButton, VForm, VModal } from "components/common";
import { StyledAddService, StyledAddServiceForm } from "./styles";
import { VModalProps } from "components/common/types";
import { useAddServiceModalHook } from "hooks";

const AddServiceModal = ({ open, close }: VModalProps) => {
  const { handleSubmit, onSubmit, inputLists, isLoading, hookForm } =
    useAddServiceModalHook(close);

  return (
    <VModal open={open} close={close} title="Add Service">
      <StyledAddService>
        <StyledAddServiceForm>
          <form onSubmit={handleSubmit(onSubmit)}>
            <VForm
              inputLists={inputLists}
              hookForm={hookForm}
              vFormClassName="expense__form"
            />
            <div className="form--button">
              <VButton
                isLoading={isLoading}
                text="Add Service"
                minWidth="295px"
                mobWidth="100%"
                height="50px"
              />
            </div>
          </form>
        </StyledAddServiceForm>
      </StyledAddService>
    </VModal>
  );
};

export default AddServiceModal;
