import {
  Expense,
  ExpenseSalesPage,
  InventoryPage,
  ProductPage,
  SalesPage,
  SalesDetailPage,
  ProductDetailsPage,
  ServicesPage,
  ManageBusiness,
  RolesAndPermissionsOverview,
  RolesAndPermissionSelection,
  AddRolesAndPermissions,
  EditRolesAndPermissions,
  UserProfileOverview,
  BillingOverviewPage,
  StoreOverviewPage,
  ChangeBillingPlanPage,
  BillingCheckoutPage,
  InvoicesPage,
  AccountPage,
  CreateInvoicePage,
  ProductsInvoicePage,
  ServicesInvoicePage,
  BusinessRegistrationPage,
  DashboardPage,
  ReportPage,
  ReportInventoryPage,
  ReportInvoicesPage,
  ReportStaffPage,
  ReportExpensePage,
  SalesReportPage,
  StaffPage,
  StaffDetailsPage,
  CustomersPage,
  CheckoutPage,
  EditInvoicePage,
  MarketPlace,
  SendMoneyPage,
  CustomerDetailsPage,
  ImportProductsPage,
  EditImportedProductsPage,
  PosPage,
  RequestPosPage,
  BuyPosPage,
  PosSurveyPage,
  CustomersPageOutlet,
  DebtorsPage,
  DebtorDetailsPage,
  PosDetailsPage,
  PosCardDetailsPage,
  PosRequestedPage,
  PosRequestDetailsPage,
  PosSettingsPage,
  ResetPasscodePage,
  ImportedFilesPage,
  LoanApp,
  IndemnityPage,
  IndemnityFormPage,
  BuyAirtimePage,
  ElectricityPage,
  BuyDataPage,
  TvSubPage,
  ReferralInfoPage,
  ReferralInfoDetailsPage,
  ReferralSalesPage,
  ReferralSalesDetailsPage,
} from "pages";
import NotFound from "pages/not-found-page";
import { getAppBaseRoute } from "veedez_bank/src/helpers/utils";
import { APP_ROUTES } from "./constants";
import { BaseRouteProps } from "./types";

export const AUTHENTICATED_ROUTES: BaseRouteProps[] = [
  {
    key: "dashboard",
    name: "Dashboard",
    path: APP_ROUTES.DASHBOARD,
    element: DashboardPage,
  },
  {
    key: "imported-file-page",
    name: "Imported Files",
    path: APP_ROUTES.IMPORTED_FILES,
    element: ImportedFilesPage,
  },
  {
    key: "expense-and-sales",
    name: "Expense & Sales",
    isAuthenticatedRoute: true,
    routes: [
      {
        key: "expense-sales-wraper",
        name: "Expense & Sales",
        element: ExpenseSalesPage,
        path: APP_ROUTES.EXPENSE_AND_SALES,
        isAuthenticatedRoute: true,

        wrapWithLayout: true,
        routes: [
          {
            key: "expense-sales-index",
            name: "Sales",
            path: APP_ROUTES.EXPENSE_AND_SALES,
            isAuthenticatedRoute: true,

            element: SalesPage,
            index: true,
          },
          {
            key: "sales",
            name: "Sales",
            path: APP_ROUTES.SALES,
            isAuthenticatedRoute: true,
            element: SalesPage,
          },
          {
            key: "expense",
            name: "Expense",
            path: APP_ROUTES.EXPENSE,
            isAuthenticatedRoute: true,
            element: Expense,
          },
        ],
      },
      {
        key: "sales",
        name: "Sales",
        wrapWithLayout: true,
        path: APP_ROUTES.SALES_DETAIL,
        isAuthenticatedRoute: true,
        element: SalesDetailPage,
      },
    ],
  },
  {
    key: "inventory",
    name: "Inventory",
    isAuthenticatedRoute: true,
    routes: [
      {
        key: "main-inventory-page",
        name: "Inventory",
        path: APP_ROUTES.INVENTORY,
        wrapWithLayout: true,
        element: InventoryPage,
        isAuthenticatedRoute: true,

        routes: [
          {
            key: "inventory-index",
            name: "Inventory",
            path: APP_ROUTES.INVENTORY,
            element: ProductPage,
            index: true,
            isAuthenticatedRoute: true,
          },
          {
            key: "products",
            name: "Products",
            path: APP_ROUTES.PRODUCTS,
            element: ProductPage,
            isAuthenticatedRoute: true,
          },
          {
            key: "services",
            name: "Services",
            path: APP_ROUTES.SERVICES,
            element: ServicesPage,
            isAuthenticatedRoute: true,
          },
        ],
      },
      {
        key: "product",
        name: "Product",
        path: APP_ROUTES.PRODUCT_DETAILS,
        wrapWithLayout: true,
        element: ProductDetailsPage,
        isAuthenticatedRoute: true,
      },
    ],
  },
  {
    key: "manage-business",
    name: "Manage Business",
    isAuthenticatedRoute: true,
    routes: [
      {
        key: "main-business-page",
        name: "Manage Business",
        path: APP_ROUTES.BUSINESS,
        element: ManageBusiness,
        wrapWithLayout: true,
        isAuthenticatedRoute: true,
        routes: [
          {
            // Roles and permissions
            key: "roles-permission-overview",
            name: "Roles & Permissions",
            path: APP_ROUTES.ROLES_AND_PERMISSIONS,
            element: RolesAndPermissionsOverview,
            isAuthenticatedRoute: true,
          },
          {
            key: "roles-permission-add",
            name: "Roles & Permissions Add",
            path: APP_ROUTES.NEW_ROLES_AND_PERMISSIONS,
            element: AddRolesAndPermissions,
            isAuthenticatedRoute: true,
          },
          {
            key: "roles-permission-module-select",
            name: "Roles & Permissions Module",
            path: APP_ROUTES.ROLES_AND_PERMISSIONS_SELECTION,
            element: RolesAndPermissionSelection,
            isAuthenticatedRoute: true,
          },
          {
            key: "roles-permission-edit",
            name: "Roles & Permissions Edit",
            path: APP_ROUTES.EDIT_ROLES_AND_PERMISSIONS,
            element: EditRolesAndPermissions,
            isAuthenticatedRoute: true,
          },
          {
            key: "manage-business-index",
            name: "User Business",
            path: APP_ROUTES.BUSINESS,
            element: UserProfileOverview,
            isAuthenticatedRoute: true,
          },
          // User Profile
          {
            key: "user-profile",
            name: "Store",
            path: APP_ROUTES.USER_PROFILE,
            element: UserProfileOverview,
            isAuthenticatedRoute: true,
          },
          // Billing Page
          {
            key: "billing-page",
            name: "Billing",
            path: APP_ROUTES.BILLING,
            element: BillingOverviewPage,
            isAuthenticatedRoute: true,
          },
          {
            key: "change-billing-plan",
            name: "Change Billing",
            path: APP_ROUTES.CHANGE_BILLING_PLAN,
            element: ChangeBillingPlanPage,
            isAuthenticatedRoute: true,
          },
          {
            key: "checkout-billing-plan",
            name: "Checkout Billing",
            path: APP_ROUTES.CHECKOUT_BILLING_PLAN,
            element: BillingCheckoutPage,
            // disabled: true,
            isAuthenticatedRoute: true,
          },
          {
            key: "store-page",
            name: "Store",
            path: APP_ROUTES.STORES,
            element: StoreOverviewPage,
            isAuthenticatedRoute: true,
            index: true,
          },
          {
            key: "indemnity-page",
            name: "Indemnity",
            path: APP_ROUTES.INDEMNITY,
            element: IndemnityPage,
            isAuthenticatedRoute: true,
            index: true,
          },
          {
            key: "indemnity-form-page",
            name: "Indemnity Form",
            path: APP_ROUTES.INDEMNITY_FORM,
            element: IndemnityFormPage,
            isAuthenticatedRoute: true,
            index: true,
          },
        ],
      },

      {
        key: "products",
        name: "Products",
        path: APP_ROUTES.PRODUCT_DETAILS,
        element: ProductDetailsPage,
        isAuthenticatedRoute: true,
      },
    ],
  },
  {
    key: "invoices",
    name: "Invoices",
    isAuthenticatedRoute: true,
    routes: [
      {
        key: "products-services-wrapper",
        name: "Products & Services",
        path: APP_ROUTES.CREATE_INVOICE,
        element: CreateInvoicePage,
        routes: [
          {
            key: "create-invoice-index",
            name: "Create Invoice",
            path: APP_ROUTES.CREATE_INVOICE,
            element: ProductsInvoicePage,
            index: true,
          },
          {
            key: "products",
            name: "products",
            path: APP_ROUTES.INVOICE_PRODUCTS,
            element: ProductsInvoicePage,
          },
          {
            key: "services",
            name: "Services",
            path: APP_ROUTES.INVOICE_SERVICES,
            element: ServicesInvoicePage,
          },
        ],
      },
      {
        key: "invoices",
        name: "Invoices",
        path: APP_ROUTES.INVOICES,
        element: InvoicesPage,
      },
      {
        key: "edit-invoice",
        name: "edit-invoice",
        path: APP_ROUTES.EDIT_INVOICE,
        element: EditInvoicePage,
      },
    ],
  },

  {
    key: "account",
    name: "Account",
    isAuthenticatedRoute: true,
    routes: [
      {
        key: "account",
        name: "Account",
        path: APP_ROUTES.ACCOUNT,
        element: AccountPage,
      },
      {
        key: "send-money",
        name: "Send Money",
        path: APP_ROUTES.SEND_MONEY,
        element: SendMoneyPage,
        isAuthenticatedRoute: true,
      },
      {
        key: "buy-airtime",
        name: "Buy Airtime",
        path: APP_ROUTES.BUY_AIRTIME,
        element: BuyAirtimePage,
        isAuthenticatedRoute: true,
      },
      {
        key: "electricity",
        name: "Electricity",
        path: APP_ROUTES.ELECTRICITY,
        wrapWithLayout: true,
        element: ElectricityPage,
      },
      {
        key: "buy-data",
        name: "Buy Data",
        path: APP_ROUTES.BUY_DATA,
        element: BuyDataPage,
        isAuthenticatedRoute: true,
      },
      {
        key: "tv-sub",
        name: "Tv Subscription",
        path: APP_ROUTES.TV_SUB,
        element: TvSubPage,
        isAuthenticatedRoute: true,
      },
    ],
  },
  {
    key: "pos-manager",
    name: "POS Manager",
    isAuthenticatedRoute: true,
    routes: [
      {
        key: "pos-manager-wrapper",
        name: "POS manager",
        path: APP_ROUTES.POS,
        element: PosPage,
        routes: [
          {
            key: "pos-manager-index",
            name: "POS Manager",
            path: APP_ROUTES.POS,
            element: PosDetailsPage,
            index: true,
          },
          {
            // pos details page
            key: "pos-details",
            name: "POS Details",
            path: APP_ROUTES.POS_DETAILS,
            element: PosDetailsPage,
          },
          {
            // pos settings page
            key: "pos-settings",
            name: "POS Settings",
            path: APP_ROUTES.POS_SETTINGS,
            element: PosSettingsPage,
          },
          {
            // pos requested page
            key: "pos-requested",
            name: "POS Requested",
            path: APP_ROUTES.POS_REQUESTED,
            element: PosRequestedPage,
          },
        ],
      },
      {
        // pos request details
        key: "pos-request-details",
        name: "POS Request Details",
        path: APP_ROUTES.POS_REQUEST_DETAILS,
        element: PosRequestDetailsPage,
      },
      {
        // request pos page
        key: "request-pos",
        name: "Request Pos",
        path: APP_ROUTES.REQUEST_POS,
        element: RequestPosPage,
      },
      {
        // pos survey page
        key: "pos-survey",
        name: "Pos Survey",
        path: APP_ROUTES.POS_SURVEY,
        element: PosSurveyPage,
      },
      {
        // request pos page
        key: "buy-pos",
        name: "Buy Pos",
        path: APP_ROUTES.BUY_POS,
        element: BuyPosPage,
      },
      {
        // pos request details
        key: "pos-card-details",
        name: "POS Card Details",
        path: APP_ROUTES.POS_CARD_DETAILS,
        element: PosCardDetailsPage,
      },
    ],
  },
  {
    key: "statff",
    name: "Staff",
    isAuthenticatedRoute: true,
    routes: [
      {
        key: "staff",
        name: "Staff",
        path: APP_ROUTES.STAFF,
        wrapWithLayout: true,
        element: StaffPage,
        isAuthenticatedRoute: true,
      },
      {
        key: "staff-details",
        name: "Staff Details",
        path: APP_ROUTES.STAFF_DETAILS,
        wrapWithLayout: true,
        element: StaffDetailsPage,
        isAuthenticatedRoute: true,
      },
    ],
  },

  {
    key: "customers",
    name: "Customers",
    isAuthenticatedRoute: true,
    routes: [
      {
        key: "customers",
        name: "Customers",
        path: APP_ROUTES.CUSTOMERS,
        element: CustomersPageOutlet,
        routes: [
          {
            key: "customers",
            name: "Customers",
            path: APP_ROUTES.CUSTOMERS,
            element: CustomersPage,
            index: true,
          },
          {
            key: "debtors",
            name: "Debtors",
            path: APP_ROUTES.CUSTOMERS_DEBTORS,
            element: DebtorsPage,
          },
        ],
      },
      {
        key: "customers",
        name: "Customers",
        path: APP_ROUTES.CUSTOMER_DETAILS,
        wrapWithLayout: true,
        element: CustomerDetailsPage,
        isAuthenticatedRoute: true,
      },
      {
        key: "debtor-details",
        name: "Debtor",
        path: APP_ROUTES.CUSTOMERS_DEBTORS_DETAILS,
        wrapWithLayout: true,
        element: DebtorDetailsPage,
        isAuthenticatedRoute: true,
      },
    ],
  },
  {
    key: "marketplace",
    name: "Marketplace",
    isAuthenticatedRoute: true,
    routes: [
      {
        key: "marketplace",
        name: "Marketplace",
        path: APP_ROUTES.MARKETPLACE,
        element: MarketPlace,
      },
    ],
  },
  {
    key: "business-registration",
    name: "Business Registration",
    path: APP_ROUTES.BUSINESS_REGISTRATION,
    element: BusinessRegistrationPage,
    wrapWithLayout: true,
  },
  {
    key: "checkout-page",
    name: "Checkout Page",
    path: APP_ROUTES.CHECKOUT,
    element: CheckoutPage,
    wrapWithLayout: true,
  },
  {
    key: "reports",
    name: "Reports",
    isAuthenticatedRoute: true,
    routes: [
      {
        key: "main-report-page",
        name: "Report",
        path: APP_ROUTES.REPORT,
        element: ReportPage,
        wrapWithLayout: true,
        isAuthenticatedRoute: true,
        routes: [
          {
            // Sales Report Page
            key: "report-overview",
            name: "Report Overview",
            path: APP_ROUTES.REPORT,
            element: SalesReportPage,
            isAuthenticatedRoute: true,
            index: true,
          },
          {
            // Sales Report Page
            key: "sales-report",
            name: "Sales Report",
            path: APP_ROUTES.REPORT_SALES,
            element: SalesReportPage,
            isAuthenticatedRoute: true,
          },

          {
            // Reports expense page
            key: "report-expense-page",
            name: "Report Expense Page",
            path: APP_ROUTES.REPORT_EXPENSES,
            element: ReportExpensePage,
          },
          {
            // Report staff-page
            key: "report-staff-page",
            name: "Report Staff Page",
            path: APP_ROUTES.REPORT_STAFF,
            element: ReportStaffPage,
            isAuthenticatedRoute: true,
          },
          {
            // Report invoice page
            key: "report-invoice-page",
            name: "Report Invoices Page",
            path: APP_ROUTES.REPORT_INVOICES,
            element: ReportInvoicesPage,
            isAuthenticatedRoute: true,
          },
          {
            //Report inventory page
            key: "report-inventory-page",
            name: "Report Inventory Page",
            path: APP_ROUTES.REPORT_INVENTORY,
            element: ReportInventoryPage,
            isAuthenticatedRoute: true,
          },
        ],
      },
    ],
  },
  {
    key: "import-products",
    name: "Product Inventory",
    isAuthenticatedRoute: true,
    routes: [
      {
        key: "import-products",
        name: "Import Products",
        path: APP_ROUTES.IMPORT_PRODUCTS,
        wrapWithLayout: true,
        element: ImportProductsPage,
        isAuthenticatedRoute: true,
      },
      {
        key: "edit-imported-product",
        name: "Edit Imported Products",
        path: APP_ROUTES.EDIT_IMPORTED_PRODUCTS,
        wrapWithLayout: true,
        element: EditImportedProductsPage,
        isAuthenticatedRoute: true,
      },
    ],
  },
  {
    key: "reset-passcode",
    name: "Reset Passcode",
    path: APP_ROUTES.RESET_PASSCODE,
    element: ResetPasscodePage,
  },
  {
    key: "loan_app",
    name: "loan_app",
    isAuthenticatedRoute: true,
    element: LoanApp,
    path: `${getAppBaseRoute()}/*`,
  },
  {
    key: "referral",
    name: "Referral",
    isAuthenticatedRoute: true,
    routes: [
      {
        key: "referral",
        name: "Referral",
        path: APP_ROUTES.REFERRAL_INFO,
        wrapWithLayout: true,
        element: ReferralInfoPage,
        isAuthenticatedRoute: true,
      },
      {
        key: "referral-details",
        name: "Referral Info Details",
        path: APP_ROUTES.REFERRAL_INFO_DETAILS,
        wrapWithLayout: true,
        element: ReferralInfoDetailsPage,
        isAuthenticatedRoute: true,
      },
      {
        key: "referral-sales",
        name: "Referral Sales",
        path: APP_ROUTES.REFERRAL_SALES,
        wrapWithLayout: true,
        element: ReferralSalesPage,
        isAuthenticatedRoute: true,
      },
      {
        key: "referral-sales-details",
        name: "Referral Sales Details",
        path: APP_ROUTES.REFERRAL_SALES_DETAILS,
        wrapWithLayout: true,
        element: ReferralSalesDetailsPage,
        isAuthenticatedRoute: true,
      },
    ],
  },
  {
    key: "not-found",
    name: "Dashboard",
    path: "*",
    element: NotFound,
    wrapWithLayout: true,
  },
];
