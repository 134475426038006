import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAuthUser } from "store";
import { useForm } from "react-hook-form";
import {
  useAddBusinessInvoice,
  useGetBusinessCustomers,
  useGetCustomerService,
  useGetCustomerProduct,
} from "api";
import { IProducts, IServices } from "api/invoices/types";
import { IAddBusinessInvoice } from "api/types";
import { showToast } from "components/common";
import {
  ProductServiceModalProps,
  CustomerProps,
} from "pages/invoices-page/create-invoice/types";
import { useModal } from "hooks";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "routes";
import { getReversedDate } from "utils";
import { PaymentModalProps } from "pages/invoices-page/create-invoice/types";
import { useAnalytics } from "hooks/useAnalytics";

export const useAddInvoicePageHook = (
  transactionType?: string,
  callBack?: Function
) => {
  const { userData } = useAuthUser();
  const location = useLocation();
  const [productForm, setProductForm] = useState<ProductServiceModalProps[]>(
    []
  );
  const [selectedProduct, setSelectedProduct] =
    useState<ProductServiceModalProps | null>(null);

  const [selectedCustomer, setSelectedCustomer] = useState<string | any>("");

  const [checkedAccount, setCheckedAccount] = useState<PaymentModalProps | any>(
    {}
  );

  const { open: openEditModal, toggleModal: toggleEditModal } = useModal();

  const hookForm = useForm<IAddBusinessInvoice>();

  const isProductPage = location.pathname.includes("product");

  const navigate = useNavigate();

  // default issue date to current date
  const today = new Date();

  useEffect(() => {
    reset({
      issueDate: getReversedDate(today as any),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { handleSubmit, reset } = hookForm;

  const [customerNameList] = useGetBusinessCustomers({
    businessId: userData?.businessId as string,
    size: 50,
  });

  const [serviceNameList] = useGetCustomerService({
    businessId: userData?.businessId as string,
    pause: isProductPage,
    size: -1,
  });

  const [productNameList] = useGetCustomerProduct({
    businessId: userData?.businessId as string,
    pause: !isProductPage,
    size: -1,
  });

  const { handleAddBusinessInvoice, isLoading } = useAddBusinessInvoice();
  const [
    logAppEvent,
    { submit_created_invoice, invoice_created_successfully },
  ] = useAnalytics();

  const submitHandler = async ({
    invoiceData,
  }: {
    invoiceData: IAddBusinessInvoice;
  }) => {
    logAppEvent(submit_created_invoice, {}).catch(console.log);

    const businessPaymentId = checkedAccount?._id;
    const businessCustomerId = selectedCustomerId;
    await handleAddBusinessInvoice(
      { ...invoiceData, businessCustomerId, businessPaymentId },
      () => {
        logAppEvent(invoice_created_successfully, {}).catch(console.log);
        showToast({
          subText: "Invoice was created successfully",
        });
        navigate(APP_ROUTES.INVOICES);
        setProductForm([]);
        callBack?.();
        reset({
          issueDate: "",
          dueDate: "",
          discount: 0,
          deliveryFee: 0,
        });
      }
    );
  };

  // delete product/service
  const removeItem = (id: any) => {
    const newList = productForm.filter((item) => item.id !== id);

    setProductForm(newList);
  };

  // edit product/service
  const updateItem = (product: ProductServiceModalProps) => {
    const newData = [...productForm]?.map((item) => {
      const selectedItem = product.id === item.id;
      if (selectedItem) return product;
      return item;
    });
    setProductForm(newData);
  };

  const handleCloseEditModal = () => {
    setSelectedProduct(null);
    toggleEditModal();
  };

  // populate customer name dropdown
  const { data, fetching, error } = customerNameList;

  const SELECT_NAME_OPTIONS = error
    ? console.error(error)
    : !fetching &&
      data?.getBusinessCustomers?.nodes.map(
        ({ customerName, phoneNumber, _id }: CustomerProps) => {
          return {
            value: customerName,
            label: customerName,
            phone: phoneNumber,
            businessCustomerId: _id,
          };
        }
      );

  // get selected businessCustomerId
  const selectedCustomerId =
    SELECT_NAME_OPTIONS &&
    SELECT_NAME_OPTIONS?.find((item: any) => item?.value === selectedCustomer)
      ?.businessCustomerId;

  // populate service name dropdown
  const {
    data: serviceData,
    fetching: fetchingService,
    error: serviceError,
  } = serviceNameList;

  const SELECT_SERVICE_OPTIONS = serviceError
    ? console.error(error)
    : !fetchingService &&
      serviceData?.getCustomerServices?.nodes.map(
        ({ _id, serviceName, sellingPrice }: IServices) => {
          return { value: _id, label: serviceName, sellingPrice: sellingPrice };
        }
      );

  // populate product name dropdown
  const {
    data: productData,
    fetching: fetchingProduct,
    error: productError,
  } = productNameList;

  const SELECT_PRODUCT_OPTIONS = productError
    ? console.error(error)
    : !fetchingProduct &&
      productData?.getCustomerProducts?.nodes.map(
        ({
          _id,
          productName,
          sellingPrice,
          totalStockValue,
          totalStock,
          batches,
        }: IProducts) => {
          return {
            value: _id,
            label: productName,
            sellingPrice: sellingPrice,
            totalStockValue: totalStockValue,
            totalStock: totalStock,
            batches: batches[0]?.purchasePrice,
          };
        }
      );

  // submit form
  const onSubmit = (data: any) => {
    if (productForm.length > 0) {
      if (transactionType === "PRODUCT") {
        submitHandler({
          invoiceData: {
            // businessCustomerId: selectedCustomerId,
            items: [
              ...productForm.map(({ id, ...rest }) => ({
                customerProductId: rest.productName,
                purchasePrice: Number(rest.purchasePrice),
                quantity: Number(rest.quantity),
                sellingPrice: Number(rest.sellingPrice),
              })),
            ],
            ...data,
            deliveryFee: Number(data.deliveryFee),
            discount: Number(data.discount),
            transactionType,
          },
        });
      } else if (transactionType === "SERVICE") {
        submitHandler({
          invoiceData: {
            services: [
              ...productForm.map(({ id, ...rest }) => ({
                customerServiceId: rest.serviceName,
                quantity: Number(rest.quantity),
                sellingPrice: Number(rest.sellingPrice),
              })),
            ],
            ...data,
            deliveryFee: Number(data.deliveryFee),
            discount: Number(data.discount),
            transactionType,
          },
        });
      }
    } else {
      showToast({
        subText:
          transactionType === "PRODUCT"
            ? "Please add product details"
            : "Please add service details",
        type: "error",
      });
    }
  };

  return {
    handleSubmit,
    hookForm,
    removeItem,
    productForm,
    setProductForm,
    SELECT_NAME_OPTIONS,
    fetching,
    SELECT_SERVICE_OPTIONS,
    fetchingService,
    SELECT_PRODUCT_OPTIONS,
    fetchingProduct,
    onSubmit,
    isLoading,
    serviceData,
    openEditModal,
    toggleEditModal,
    updateItem,
    selectedProduct,
    setSelectedProduct,
    handleCloseEditModal,
    setSelectedCustomer,
    checkedAccount,
    setCheckedAccount,
  };
};
