import styled from "styled-components";
import { stylingUtils } from "styles";

const { rem } = stylingUtils;

export const StyledConfirm = styled.div`
  text-align: center;
  width: 100%;
`;

export const StyledConfirmButtons = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
  width: 100%;

  @media only screen and (max-width: 768px) {
    button.confirm-button {
      min-width: 50%;
    }
  }
`;

export const StyledConfirmtText = styled.h4`
  font-family: "Gilmer";
  font-style: normal;
  font-weight: 400;
  font-size: ${rem(16)};
  line-height: ${rem(22)};
  color: ${(props) => props.theme.colors.ash09};
  margin: 4rem 0;
`;

export const StyledPasscode = styled("div")`
  padding: 2rem;

  button {
    margin: 0 2rem;
  }
`;
