import { FlexJAMixin, stylingUtils } from "styles";
import styled from "styled-components/macro";

const { rem, color } = stylingUtils;

export const StyledVAccordion = styled("article")`
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
`;

export const StyledVAccordionHeader = styled("div")<{
  isOpen: boolean;
  backgroundColor: string;
  buttonBg: string;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${color("ash01")};
  border-bottom: 1px solid ${color("ash01")};
  padding: ${rem(10)} ${rem(20)};
  cursor: pointer;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : "transparent"};

  h4 {
    margin-bottom: 0px !important;
  }

  button {
    ${FlexJAMixin};
    width: ${rem(28)};
    height: ${rem(28)};
    border-radius: 9999%;
    background-color: ${({ buttonBg }) =>
      buttonBg ? buttonBg : color("ash01")};
    transform: ${({ isOpen }) => (isOpen ? "rotate(-90deg)" : "rotate(90deg)")};
    transition: 0.5s all ease;
  }
`;
export const StyledVAccordionBody = styled("div")<{ isOpen: boolean }>`
  padding: ${({ isOpen }) => (isOpen ? ` ${rem(20)} ` : `0 ${rem(20)}`)};
  background-color: ${color("ash04")};
  max-height: ${({ isOpen }) => (isOpen ? "100vh" : 0)};
  transition: 0.5s all ease;
`;
