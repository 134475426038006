import { gql, useQuery } from "urql";

type useGetBusinessInvoiceDetailsProps = {
  businessInvoiceId: string;
};

export interface InvoiceItems {
  customerProductName: string;
  customerServiceName: string;
  quantity: number;
  sellingPrice: number | string;
  purchasePrice: number | string;
}

interface InvoicePayments {
  amountPaid: number | string;
}

export interface IBusinessInvoiceDetails {
  _id: string;
  businessId?: string;
  clientName?: string;
  clientPhone?: string;
  amountDue?: number;
  invoiceStatus?: string;
  issueDate?: Date;
  dueDate?: Date;
  discount?: string;
  totalTax?: string;
  invoiceURL?: string;
  invoiceTotal?: string;
  invoiceSubTotal?: number;
  invoiceItems?: InvoiceItems;
  invoicePayments?: InvoicePayments;
  businessCustomerId?: string;
}

const useGetBusinessInvoiceDetailsQuery = gql`
  query ($businessInvoiceId: String) {
    getBusinessInvoiceDetail(businessInvoiceId: $businessInvoiceId) {
      _id
      businessId
      businessCustomerId
      clientName
      clientPhone
      amountDue
      issueDate
      dueDate
      invoiceItems {
        customerProductId
        customerServiceId
        customerProductName
        customerServiceName
        quantity
        sellingPrice
        purchasePrice
      }
      invoicePayments {
        amountPaid
      }
      invoiceStatus
      invoiceURL
      invoiceTotal
      totalTax
      discount
      invoiceNumber
      invoiceSubTotal
      businessPayment {
        bankName
        bankAccountName
        bankAccountNumber
      }
      transactionType
      deliveryFee
    }
  }
`;

export const useGetBusinessInvoiceDetails = ({
  businessInvoiceId,
}: useGetBusinessInvoiceDetailsProps) => {
  return useQuery({
    query: useGetBusinessInvoiceDetailsQuery,
    variables: { businessInvoiceId },
    pause: !businessInvoiceId,
  });
};
