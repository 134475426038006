import { formatNumberToCurrency } from "utils";
import { Total, Table } from "./styles";
import {
  DetailsProps,
  ITEM_TABLE_HEAD,
  ItemDetails,
  INVOICE_COLOR,
} from "../constants";

const DetailsModal = (props: DetailsProps) => {
  return (
    <>
      <Table>
        <thead
          style={{
            backgroundColor:
              INVOICE_COLOR[props.invoiceStatusBg ?? "PART_PAYMENT"]?.bgColor,
          }}
        >
          <tr>
            {ITEM_TABLE_HEAD?.map((head: string, i: number) => (
              <th key={i}>{head}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {props.tableDetails?.map((detail: ItemDetails, i: number) => (
            <tr key={i}>
              <td>{i + 1}</td>
              <td>
                {detail.item ||
                  detail.customerProductName ||
                  detail.customerServiceName}
              </td>
              <td>{detail.quantity}</td>
              <td>
                {formatNumberToCurrency({
                  number: detail.amount || detail.sellingPrice,
                })}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Total>
        <table>
          <tbody>
            {props.invoiceStatusBg !== "POS" && (
              <>
                <tr>
                  <td>Sub-total</td>
                  <td>{formatNumberToCurrency({ number: props.subTotal })}</td>
                </tr>
                <tr>
                  <td>Discount</td>
                  <td>
                    {formatNumberToCurrency({
                      number: props.discount ? props?.discount : 0,
                    })}
                  </td>
                </tr>
                <tr>
                  <td>VAT</td>
                  <td>
                    {formatNumberToCurrency({
                      number: props.vat ? props.vat : 0,
                    })}
                  </td>
                </tr>
                <tr>
                  <td>Delivery fee</td>
                  <td>
                    {formatNumberToCurrency({
                      number: props.deliveryFee ? props.deliveryFee : 0,
                    })}
                  </td>
                </tr>
              </>
            )}
            {props.invoiceStatusBg === "PART_PAYMENT" && (
              <tr>
                <td>Amount paid</td>
                <td>
                  {formatNumberToCurrency({
                    number: props.invoicePartlyPayments
                      ? props?.invoicePartlyPayments
                      : 0,
                  })}
                </td>
              </tr>
            )}
            <tr>
              <td>Total</td>
              <td>{formatNumberToCurrency({ number: props.total })}</td>
            </tr>
          </tbody>
        </table>
      </Total>
    </>
  );
};

export default DetailsModal;
