import styled, { css } from "styled-components/macro";
import stylingUtils from "styles/styling.utils";

const {
  color,
  rem,
  mediaQuery: { down, up },
} = stylingUtils;

const drawerModal = css<{ open: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  border-radius: 0;
  transform: translateX(${({ open }) => (open ? 0 : 500)}%);

  ${up("laptop")} {
    max-height: 100vh !important;
  }
`;

const drawerLeftModal = css<{ open: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  border-radius: 0;
  transform: translateX(${({ open }) => (open ? 0 : -500)}%);

  ${up("laptop")} {
    max-height: 100vh !important;
  }
`;

export const VModalOverlay = styled.div<{ open: boolean }>`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: ${(props) => (props.open ? 1 : 0)};
  visibility: ${(props) => (props.open ? "visible" : "hidden")};
  transition: 0.5s all ease;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${(props) => (props.open ? 999 : -10)};
`;

export const VModdalContainer = styled("div")<{
  width: number;
  open: boolean;
  padding: number;
  isDrawer?: boolean;
  isDrawerLeft?: boolean;
}>`
  position: relative;
  padding: ${({ padding }) => rem(padding)};
  background-color: ${color("white")};
  width: ${({ width }) => rem(width) || "fit-content"};
  transform: scale(${(props) => (props.open ? 100 : 0)}%);
  border-radius: ${rem(10)};
  max-height: 100vh;
  overflow-y: auto;
  opacity: 1;
  visibility: visible;
  transition: 0.5s all ease-in-out;

  ${({ isDrawer }) => {
    if (isDrawer) return drawerModal;
  }};

  ${({ isDrawerLeft }) => {
    if (isDrawerLeft) return drawerLeftModal;
  }};

  ${up("laptop")} {
    max-width: ${rem(760)};
    max-height: 90vh;
  }
  ${down("tablet")} {
    max-width: ${rem(400)};
    padding: ${rem(20)};
  }

  .modal__back--button {
    margin-top: -3px;
  }

  .modal__close--icon {
    position: absolute;
    background-color: ${color("ash01")};
    padding: 1rem;
    height: ${rem(30, false)};
    width: ${rem(30, false)};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    top: ${rem(15)};
    right: ${rem(15)};
    cursor: pointer;
  }
`;

export const VModalChildren = styled("div")`
  width: 100%;
`;

export const VModalTitle = styled("h3")<{
  alignModalTitle: string;
  modalTitleColor?: string;
}>`
  font-size: ${rem(16)};
  color: ${({ modalTitleColor }) =>
    modalTitleColor ? modalTitleColor : color("lightBlue")};
  margin-bottom: ${rem(24)};
  text-align: ${({ alignModalTitle }) => alignModalTitle};
  ${down("laptop")} {
    max-width: 90%;
  }
`;
export const VModalSubTitle = styled("h3")<{ alignModalSubTitle: string }>`
  font-size: ${rem(14)};
  color: ${color("ashText")};
  font-weight: bold;
  margin-bottom: ${rem(24)};
  text-align: ${({ alignModalSubTitle }) => alignModalSubTitle};
`;
