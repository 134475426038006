import { CreatedAtType } from "../types";
import { gql, useQuery } from "urql";

type GetStaffReportProps = {
  createdAt: CreatedAtType["createdAt"];
};

const combinedQuery = gql`
  query ($createdAt: RangeInput, $type: STAFFSUMMARYCARDTYPE) {
    getStaffSummaryCards(createdAt: $createdAt, type: $type) {
      totalStaff
      activeStaffCount
      inactiveStaffCount
      userRoles
      staffActivities {
        identifier
        count
        value
      }
      performers {
        userId
        key
        maxVal
        user {
          firstName
          lastName
        }
      }
    }

    getSalesByStaff(createdAt: $createdAt) {
      userId
      totalSaleCount
      totalSaleValue
      user {
        firstName
        lastName
      }
    }
  }
`;

export const useGetStaffReport = ({ createdAt }: GetStaffReportProps) => {
  return useQuery({
    query: combinedQuery,
    variables: {
      createdAt,
      type: "REPORT",
    },
  });
};

export const useGetStaffReportHook = ({ createdAt }: CreatedAtType) => {
  const [result, refetchAll] = useGetStaffReport({
    createdAt: createdAt,
  });

  return {
    result,
    refetchAll,
    data: (result?.data || {
      getStaffSummaryCards: {
        totalStaff: 0,
        activeStaffCount: 0,
        inactiveStaffCount: 0,
        userRoles: 0,
        performers: [],
        staffActivities: [],
      },
      getSalesByStaff: [],
    }) as IStaffReport,
    fetching: result?.fetching,
  };
};

export interface IStaffSummaryCards {
  totalStaff: number;
  activeStaffCount: number;
  inactiveStaffCount: number;
  userRoles: number;
  performers: {
    userId: string;
    key: string;
    maxVal: string;
  }[];
  staffActivities: {
    identifier: string;
    count: number;
    value: number;
  }[];
}

export interface ISaleByStaff {
  userId: string;
  totalSaleCount: number;
  totalSaleValue: number;
  firstName?: string;
  lastName?: string;
  user: {
    firstName: string;
    lastName: string;
  };
}

export interface IStaffReport {
  getStaffSummaryCards: IStaffSummaryCards;
  getSalesByStaff: ISaleByStaff[];
}
