import { useRequestedPosDetailsHook } from "hooks";
import { formatNumberToCurrency } from "utils";
import StatusCard from "./status-card";
import { VButton, VRadio } from "components/common";
import { POS_STATUS_CARDS } from "../constants";
import {
  RequestDetailsStyles,
  StatusStyles,
  OptionStyles,
  ButtonStyles,
} from "./styles";

const RequestDetails = () => {
  const { terminalRequestData, status, terminalName } =
    useRequestedPosDetailsHook();

  return (
    <RequestDetailsStyles>
      <h4>POS Request - Stages</h4>
      <StatusStyles>
        {POS_STATUS_CARDS().map((item) => (
          <StatusCard
            key={item.index}
            index={item.index}
            date={item.date}
            title={item.title}
            done={item.done}
            rejected={item.rejected}
          />
        ))}
      </StatusStyles>
      <OptionStyles>
        {/* <div className="left">
          <VRadio
            id={terminalRequestData?._id}
            value={terminalRequestData}
            checked
          />
          <div>
            <h3>{terminalRequestData?.spec?.name}</h3>
            <p>
              {formatNumberToCurrency({
                number: terminalRequestData?.spec?.price,
              })}
            </p>
          </div>
        </div> */}
        <div>
          <div className="bottom-flex">
            <img
              src={terminalRequestData?.spec?.imageUrl}
              alt=""
              style={{ width: "95px" }}
            />
            <div>
              <div className="left">
                <VRadio
                  id={terminalRequestData?._id}
                  value={terminalRequestData}
                  checked
                />
                <div>
                  <h3>{terminalName}</h3>
                  <p>
                    {formatNumberToCurrency({
                      number: terminalRequestData?.spec?.price,
                    })}
                  </p>
                </div>
              </div>
              <ul>
                {terminalRequestData?.spec?.benefits?.map(
                  (items: string, i: number) => (
                    <li key={i}>{items}</li>
                  )
                )}
              </ul>
              <div className="features">
                {terminalRequestData?.spec?.features.map(
                  (picture: string, i: number) => (
                    <img src={picture} alt="" key={i} />
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </OptionStyles>
      {status === "DELIVERED" && (
        <ButtonStyles>
          <VButton
            bgColor="#0077FF"
            textColor="#FFF"
            height="50px"
            width="350px"
            text="View order details"
          />
        </ButtonStyles>
      )}
    </RequestDetailsStyles>
  );
};

export default RequestDetails;
