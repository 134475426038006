// Core
import { gql, useMutation } from "urql";

// Custom Components
import { showToast } from "components/common";

// Assets
import { handleRequestCall } from "utils/handleRequestCall";

// GraphQL
const sendResetPasscodeMutation = gql`
  mutation {
    resetPasscode {
      success
      data
    }
  }
`;
const verifyResetOtpMutation = gql`
  mutation ($code: String) {
    verifyResetPasscode(code: $code) {
      resetToken
    }
  }
`;
const confirmResetPasscode = gql`
  mutation ($resetToken: String, $newPasscode: String) {
    confirmResetPasscode(resetToken: $resetToken, newPasscode: $newPasscode) {
      success
      data
    }
  }
`;

// Functionality
export const useResetPasscodeApi = () => {
  const [resetOtpStatus, sendResetOtp] = useMutation(sendResetPasscodeMutation);
  const [verifyUserOtpStatus, verifyUserOtp] = useMutation(
    verifyResetOtpMutation
  );
  const [resetPasscodeStatus, resetPasscode] =
    useMutation(confirmResetPasscode);
  const handleSendOtp = async (
    callBack?: (data: Record<string, any>) => void
  ) => {
    try {
      const req = await sendResetOtp({});
      await handleRequestCall({
        request: req,
        callBack: (data: Record<string, any>) => {
          showToast({
            title: "Otp sent successfully",
            subText: "Check your phone number for otp sent",
          });
          callBack?.(data);
        },
        error: {
          title: "Error Sending otp",
          subText: "Error sending otp",
          type: "error",
        },
      });
    } catch (e) {
      showToast({
        type: "error",
        title: "An error occurred",
        subText: "Please try again",
      });
      console.error(e);
    }
  };

  const handleVerifyOtp = async (
    variables: { code: string },
    callBack?: (data: Record<string, any>) => void
  ) => {
    try {
      const req = await verifyUserOtp(variables);
      await handleRequestCall({
        request: req,
        callBack: (data: any) => {
          showToast({
            title: "Otp verified",
            subText: "Otp successfully verified",
          });
          callBack?.(data);
        },
        error: {
          title: "Invalid Otp",
          subText: "Enter a valid otp",
          type: "error",
        },
      });
    } catch (e) {
      showToast({
        type: "error",
        title: "An error ocucured",
        subText: "Please try again",
      });
      console.error(e);
    }
  };

  const handleResetPasscode = async (
    variables: { resetToken: string; newPasscode: string },
    callBack?: (data: Record<string, any>) => void
  ) => {
    try {
      const req = await resetPasscode(variables);
      await handleRequestCall({
        request: req,
        callBack: (data: any) => {
          showToast({
            title: "Passcode reset successfully",
            subText: "Your passcode has been reset",
          });
          callBack?.(data);
        },
        error: {
          title: "Invalid Otp",
          subText: "Enter a valid otp",
          type: "error",
        },
      });
    } catch (e) {
      showToast({
        type: "error",
        title: "An error ocucured",
        subText: "Please try again",
      });
      console.error(e);
    }
  };

  return {
    resetOtpStatus,
    verifyUserOtpStatus,
    resetPasscodeStatus,
    handleResetPasscode,
    handleVerifyOtp,
    handleSendOtp,
    isLoading:
      resetOtpStatus?.fetching ||
      verifyUserOtpStatus?.fetching ||
      resetPasscodeStatus?.fetching,
  };
};
