import React, { createContext, ReactNode } from "react";
import { usePasscodeStoreHook } from "./usePasscodeStoreHook";

type PasscodeContextProviderProps = ReturnType<
  typeof usePasscodeStoreHook
> & {};

const PasscodeContext = createContext<PasscodeContextProviderProps>(
  {} as PasscodeContextProviderProps
);

export const PasscodeContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const passcodeHook = usePasscodeStoreHook();

  return (
    <PasscodeContext.Provider value={{ ...passcodeHook }}>
      {children}
    </PasscodeContext.Provider>
  );
};

export const usePasscodeContext = (): PasscodeContextProviderProps => {
  const context = React.useContext(PasscodeContext);
  if (context === undefined) {
    throw new Error("This must be used within a provider");
  }
  return context;
};
