const CopyIcon = (props: any) => {
  return (
    <svg
      width="17"
      height="18"
      {...props}
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.9875 0.875H5.7625C5.32871 0.876646 4.91317 1.0497 4.60643 1.35643C4.2997 1.66317 4.12665 2.07871 4.125 2.5125V4.625H2.0125C1.57871 4.62665 1.16317 4.7997 0.856431 5.10643C0.549697 5.41317 0.376646 5.82871 0.375 6.2625V15.4875C0.376646 15.9213 0.549697 16.3368 0.856431 16.6436C1.16317 16.9503 1.57871 17.1234 2.0125 17.125H11.2375C11.6713 17.1234 12.0868 16.9503 12.3936 16.6436C12.7003 16.3368 12.8734 15.9213 12.875 15.4875V13.375H14.9875C15.4213 13.3734 15.8368 13.2003 16.1436 12.8936C16.4503 12.5868 16.6234 12.1713 16.625 11.7375V2.5125C16.6234 2.07871 16.4503 1.66317 16.1436 1.35643C15.8368 1.0497 15.4213 0.876646 14.9875 0.875V0.875ZM11.625 15.4875C11.625 15.5903 11.5842 15.6888 11.5115 15.7615C11.4388 15.8342 11.3403 15.875 11.2375 15.875H2.0125C1.90973 15.875 1.81117 15.8342 1.7385 15.7615C1.66583 15.6888 1.625 15.5903 1.625 15.4875V6.2625C1.625 6.15973 1.66583 6.06117 1.7385 5.9885C1.81117 5.91583 1.90973 5.875 2.0125 5.875H11.2375C11.3403 5.875 11.4388 5.91583 11.5115 5.9885C11.5842 6.06117 11.625 6.15973 11.625 6.2625V15.4875ZM15.375 11.7375C15.375 11.8403 15.3342 11.9388 15.2615 12.0115C15.1888 12.0842 15.0903 12.125 14.9875 12.125H12.875V6.2625C12.8734 5.82871 12.7003 5.41317 12.3936 5.10643C12.0868 4.7997 11.6713 4.62665 11.2375 4.625H5.375V2.5125C5.375 2.40973 5.41583 2.31117 5.4885 2.2385C5.56117 2.16583 5.65973 2.125 5.7625 2.125H14.9875C15.0903 2.125 15.1888 2.16583 15.2615 2.2385C15.3342 2.31117 15.375 2.40973 15.375 2.5125V11.7375Z"
        fill="#0077FF"
      />
    </svg>
  );
};

export default CopyIcon;
