import { OauthCallBackPage, SignIn } from "pages";
import BluSalt from "pages/auth-page/blusalt";
import { Route, Routes } from "react-router-dom";
import BaseRoute from "routes/BaseRoutes";

const AuthRoutes = () => {
  return (
    <Routes>
      <Route
        index
        element={
          <BaseRoute title="OauthCallback">
            <OauthCallBackPage />
          </BaseRoute>
        }
      />
      <Route
        path="/signin"
        element={
          <BaseRoute title="OauthCallback">
            <SignIn />
          </BaseRoute>
        }
      />
      <Route
        path="/blusalt"
        element={
          <BaseRoute title="OauthCallback">
            <BluSalt />
          </BaseRoute>
        }
      />
    </Routes>
  );
};

export default AuthRoutes;
