import { useEditCustomerProduct } from "api/inventory/edit-customer-product";
import { TEditedProduct, TProduct } from "components/Inventory/types";
import useModal from "hooks/useModal";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useAuthUser } from "store";

export const useProductFormHook = (refetchCustomerProduct?: Function) => {
  const { userData } = useAuthUser();
  const [selectedProduct, setSelectedProduct] = useState<TProduct | null>(null);
  const { open: shouldOpenEditFormModal, toggleModal: toggleEditFormModal } =
    useModal();
  const {
    open: shouldOpenDeleteFormModal,
    toggleModal: toggleDeleteFormModal,
    handleClose: closeDeleteModal,
  } = useModal();
  const {
    open: shouldOpenConfirmModal,
    handleClose: closeConfirmModal,
    handleOpen: openConfirmModal,
  } = useModal();

  const [formData, setFormData] = useState<TProduct>();
  const { customerProduct, submitEditedProduct, imageProcessing } =
    useEditCustomerProduct(refetchCustomerProduct);

  const handleEditProductSubmit = () =>
    submitEditedProduct(formData as TEditedProduct, closeConfirmModal);

  const editProductHookForm = useForm<TEditedProduct>();
  const createProductHookForm = useForm<TEditedProduct>();
  const { watch, reset } = editProductHookForm;
  const { reset: createProductReset } = createProductHookForm;
  const changeQuantity = watch("changeQuantity") || "";

  const handleReset = (title: string) => {
    const EMPTY_PRODUCT = {
      productID: "",
      productName: "",
      category: "",
      costPrice: "",
      sellingPrice: "",
      quantity: "",
      thresholdLowStock: "",
      productImage: "",
      dateTime: "",
    };

    if (title === "Edit") {
      reset({ ...EMPTY_PRODUCT });
    }

    if (title === "Create") {
      createProductReset({ ...EMPTY_PRODUCT });
    }

    setSelectedProduct(null);
  };

  const selectedProductRef = JSON.stringify(selectedProduct);

  useEffect(() => {
    if (selectedProduct) {
      reset({ ...selectedProduct });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProductRef]);

  return {
    formData,
    setFormData,
    handleEditProductSubmit,
    openConfirmModal,
    shouldOpenConfirmModal,
    closeConfirmModal,
    customerProduct,
    submitEditedProduct,
    imageProcessing,
    userData,
    changeQuantity,
    editProductHookForm,
    createProductHookForm,
    shouldOpenEditFormModal,
    toggleEditFormModal,
    handleReset,
    selectedProduct,
    setSelectedProduct,
    shouldOpenDeleteFormModal,
    toggleDeleteFormModal,
    closeDeleteModal,
  };
};
