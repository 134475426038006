import { css } from 'styled-components';
import stylingUtils from './styling.utils';

const { color, rem } = stylingUtils;

export const DRIVERJS_OVERRIDE = css`
  .custom-walkthrough {
    font-family: Gilmer !important;
    padding: ${rem(30)}!important;
    max-width: ${rem(460)}!important;
    width: 100% !important;

    .driver-popover-title {
      font-family: Gilmer !important;

      color: ${color('lightBlue')}!important;
      font-size: ${rem(17)}!important;
      font-weight: 500 !important;
    }

    .driver-next-btn,
    .driver-close-btn {
      background-color: transparent !important;
      border: 1px solid ${color('lightBlue')}!important;
      color: ${color('lightBlue')}!important;
      padding: ${rem(8)} ${rem(26)}!important;
      border-radius: ${rem(5)}!important;
      font-size: ${rem(14)}!important;
      font-family: Gilmer !important;
    }
    .driver-prev-btn {
      display: none !important;
    }
    .driver-popover-description {
      margin-bottom: ${rem(40)}!important;
      color: #666666 !important;
      font-family: Gilmer !important;
    }

    .walkthrough-title {
      display: flex;
      align-items: center;
      gap: ${rem(10)};
      margin-bottom: ${rem(20)};
      font-family: Gilmer !important;
    }
  }
`;
