import theme from "styles/theme";
import { ReactComponent as TotalSalesValue } from "assets/icons/summary/sales/total-sales-value.svg";
import { formatNumberToCurrency } from "utils";
import { CardProps } from "components/common/types";
import { StyledDashboardIconWrapper } from "components/dashboard";
import TotalSalesValueIcon from "assets/images/svgs/TotalSalesValueIcon";

import ChartAnalysisIcon from "assets/images/svgs/ChartAnalysisIcon";
import { ReactComponent as MetricUp } from "assets/icons/summary/metric-up.svg";
import { ReactComponent as MetricDown } from "assets/icons/summary/metric-down.svg";
import ActivityIcon from "assets/images/svgs/ActivityIcon";

export const INVOICES_CARD_DATA = ({
  data,
}: {
  data: Record<string, any>;
}): CardProps[] => {
  const {
    invoicesIssuedCount = 0,
    invoicesIssuedValue = 0,
    paidInvoiceCount = 0,
    partlyPaidInvoiceCount = 0,
  } = data;
  return [
    {
      title: "Value of Invoices Issued",
      icon: () => <TotalSalesValue />,
      amount: formatNumberToCurrency({ number: Number(invoicesIssuedValue) }),
    },
    {
      title: `Number of Invoices Issued`,
      icon: () => (
        <StyledDashboardIconWrapper color="#E3F0FE">
          <ChartAnalysisIcon width={20} height={20} />
        </StyledDashboardIconWrapper>
      ),
      amount: formatNumberToCurrency({
        number: Number(invoicesIssuedCount),
        removeCurrency: true,
      }),
    },
    {
      title: `Paid Invoices`,
      icon: () => (
        <StyledDashboardIconWrapper color="#E8F5F1">
          <ChartAnalysisIcon width={20} height={20} stroke="#34A853" />
        </StyledDashboardIconWrapper>
      ),
      amount: formatNumberToCurrency({
        number: Number(paidInvoiceCount),
        removeCurrency: true,
      }),
    },
    {
      title: `Partly-paid`,
      icon: () => (
        <TotalSalesValueIcon
          width={24}
          height={24}
          fill={theme.colors.toastRed}
          backcolor={"#FFE0DE"}
        />
      ),
      amount: formatNumberToCurrency({
        number: Number(partlyPaidInvoiceCount),
        removeCurrency: true,
      }),
    },
  ];
};

export const INVENTORY_SUMMARY_DATA = [
  {
    label: "Total Paid Invoice",
    increaseValue: 41,
    value: 331520,
    key: "PAID",
    color: theme.colors.lightBlue,
    percentage: 30,
  },
  {
    label: "Part-Payment",
    increaseValue: 23,
    value: 100000,
    color: "#046AB5",
    key: "PART_PAYMENT",
    percentage: 66,
  },
  {
    label: "Not Paid",
    increaseValue: 18.9,
    key: "UNPAID",
    value: 99520,
    color: theme.colors.orange01,
    percentage: 20,
  },
];

export const REPORT_INVOICE_PIE_MOCK_DATA = [
  { name: "Paid", value: 400 },
  { name: "Not Paid", value: 300 },
  { name: "Part Payment", value: 300 },
  { name: "Sent", value: 200 },
];

export const REPORT_INVOICE_PIE_CHART_COLORS = [
  "#34A853",
  "#CC2E2E",
  "#FDD0B2",
  "#FCB017",
];

export const EXPENSE_CARD_DATA = (data: Record<string, any>) => {
  return [
    {
      title: "Total Expense Value",
      icon: () => (
        <StyledDashboardIconWrapper color="#FBECDB">
          <ActivityIcon width={20} height={20} stroke={theme.colors.orange01} />
        </StyledDashboardIconWrapper>
      ),
      amount: formatNumberToCurrency({
        number: data?.totalExpenses || data?.totalExpense,
      }),
      metric: MetricDown,
    },
    {
      title: "Expense Count",
      icon: () => (
        <StyledDashboardIconWrapper color="#E8F5F1">
          <ChartAnalysisIcon width={20} height={20} stroke="#34A853" />
        </StyledDashboardIconWrapper>
      ),
      amount: data?.totalCount || data?.total,
      metric: MetricUp,
    },
  ];
};
