import styled from "styled-components/macro";
import { FlexAMixin, stylingUtils } from "styles";

const {
  rem,
  mediaQuery: { down },
} = stylingUtils;

export const StyledPermissionSelectionHeader = styled("div")`
  ${FlexAMixin};
  margin-bottom: ${rem(30)};
  gap: ${rem(25)};
  ${down("laptop")} {
    flex-direction: column;
    gap: ${rem(15)};
  }
`;

export const StyledPermissionSelectionAvatar = styled("div")`
  width: ${rem(60)};
  height: ${rem(60)};
  border-radius: 9999%;
  border: 8px solid #f2f8ff;
  overflow: hidden;

  img {
    object-fit: cover;
  }
`;

export const StyledPermissionSelectionHeaderLeft = styled("div")`
  ${FlexAMixin};
  gap: ${rem(20)};
  min-width: ${rem(300)};
  ${down("laptop")} {
    width: 100%;
  }
`;
