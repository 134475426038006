import { useEffect } from "react";
import { useGetTerminalSpecs } from "api";
import { extractFetchedData } from "utils";
import { showToast } from "components/common";

export const useGetTerminalSpecsHook = () => {
  const [fetchTerminalSpecs] = useGetTerminalSpecs();

  const { data: terminalData } = extractFetchedData<any>({
    result: fetchTerminalSpecs,
    key: "getTerminalSpecs",
  });

  useEffect(() => {
    if (fetchTerminalSpecs.error) {
      showToast({
        subText: "Could not get terminals. Please try again",
        type: "error",
      });
    }
  }, [fetchTerminalSpecs.error]);

  const TERMINAL_SPECS = terminalData?.getTerminalSpecs;

  return {
    TERMINAL_SPECS,
  };
};
