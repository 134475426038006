const RedCheck = (props: any) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_8797_151226)">
        <path
          d="M10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10C20 4.5 15.5 0 10 0ZM10 18C5.6 18 2 14.4 2 10C2 5.6 5.6 2 10 2C14.4 2 18 5.6 18 10C18 14.4 14.4 18 10 18Z"
          fill="#EA4336"
        />
        <path
          d="M11.1317 9.99999L13.769 7.36319C13.9176 7.21272 14.0007 7.00957 14 6.7981C13.9993 6.58663 13.915 6.38402 13.7654 6.23449C13.6159 6.08496 13.4132 6.00066 13.2017 6C12.9902 5.99934 12.787 6.08237 12.6365 6.23096L9.99921 8.86771L7.36189 6.23096C7.21126 6.08306 7.0083 6.00062 6.79717 6.00157C6.58604 6.00253 6.38384 6.08681 6.23455 6.23607C6.08526 6.38533 6.00096 6.58749 6.00001 6.79858C5.99905 7.00966 6.08151 7.21258 6.22944 7.36319L8.86671 9.99999L6.22944 12.6368C6.08151 12.7874 5.99905 12.9903 6.00001 13.2014C6.00096 13.4125 6.08526 13.6146 6.23455 13.7639C6.38384 13.9132 6.58604 13.9974 6.79717 13.9984C7.0083 13.9994 7.21126 13.9169 7.36189 13.769L9.99921 11.1323L12.6365 13.7691C12.787 13.9176 12.9902 14.0007 13.2017 14C13.4132 13.9993 13.6159 13.915 13.7654 13.7655C13.915 13.616 13.9993 13.4133 14 13.2019C14.0006 12.9904 13.9176 12.7873 13.769 12.6368L11.1317 9.99999Z"
          fill="#EA4336"
        />
      </g>
      <defs>
        <clipPath id="clip0_8797_151226">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RedCheck;
