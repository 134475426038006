import { VModal } from "components/common";
import styled from "styled-components/macro";
import { stylingUtils } from "styles";

const { color, rem } = stylingUtils;

export const TransactionModal = styled(VModal)``;

export const StyledModalContainer = styled("div")`
  display: grid;
  place-content: center;
  width: 100%;
  .issue {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    color: #5976a1;
  }
`;

export const TransactionDetails = styled("div")<{
  status?: string;
}>`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  place-content: center;
  grid-gap: ${rem(20)};
  padding: ${rem(20)} 0;
  border-bottom: 1px dashed #e8ebee;

  .item {
    display: grid;
    grid-gap: ${rem(11)};
    margin-bottom: ${rem(15)};
  }

  .status {
    color: ${({ status }) =>
      status === "successful" ? color("green") : color("toastRed")};
  }

  .amount {
    color: ${color("lightBlue")};
    font-weight: 500;
  }

  h4,
  p {
    font-family: "Gilmer";
    font-weight: 500;
    font-size: ${rem(14)};
    line-height: ${rem(17)};
    color: ${color("darkGrey")};
  }

  p {
    color: ${color("lightGrey")};
    font-weight: 400;
  }
`;
