import styled from "styled-components";
import { stylingUtils } from "styles";

const { color, rem } = stylingUtils;

export const StyledVCheckBox = styled("div")`
  display: flex;
  align-items: center;
  gap: ${rem(5)};
`;

export const StyledVCheckBoxWrapper = styled("div")`
  display: grid;
  place-items: center;
  INPUT[type="checkbox"]:focus {
  }

  INPUT[type="checkbox"] {
    background-color: white;
    border-radius: 2px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 15px;
    height: 15px;
    cursor: pointer;
    position: relative;
    border: 1px solid ${color("lightBlue")};
    outline-offset: 1px;
  }

  INPUT[type="checkbox"]:checked::after {
    background-color: ${color("lightBlue")};
    position: absolute;
    left: 2px;
    right: 2px;
    content: "";
    top: 2px;
    bottom: 2px;
  }
`;
export const StyledVCheckBoxInput = styled("input")``;

export const StyledVCheckBoxSecondary = styled.label<{
  isReverse: boolean;
  fontSize: string;
  disabled: boolean;
}>`
  display: flex;
  gap: ${rem(15)};
  align-items: center;
  flex-direction: ${({ isReverse }) => (isReverse ? "row-reverse" : "")};
  cursor: pointer !important;

  opacity: ${({ disabled }) => (disabled ? "0.5" : 1)};

  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .container {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: ${({ fontSize }) => fontSize ?? "12px"};
    user-select: none;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: relative;
    top: 0;
    left: 0;
    height: 1.3em;
    width: 1.3em;
    background-color: white;
    border-radius: 50%;
    outline: 1px solid ${color("lightBlue")};
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: ${color("lightBlue")};
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 0.45em;
    top: 0.25em;
    width: 0.25em;
    height: 0.5em;
    border: solid white;
    border-width: 0 0.15em 0.15em 0;
    transform: rotate(45deg);
  }
`;
