const VeedezProLogo = () => {
  return (
    <svg
      width="190"
      height="30"
      viewBox="0 0 190 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_16613_96314)">
        <path
          d="M13.9267 16.542L10.0325 26.6105L0.0373234 0.807217C0.00293841 0.71563 -0.00790759 0.617502 0.00569725 0.521081C0.0206975 0.426218 0.0593686 0.336186 0.118451 0.258573C0.178474 0.178476 0.257675 0.113227 0.349459 0.0682563C0.441473 0.0235543 0.543225 0.000169339 0.64647 1.37746e-06H7.07207C7.20598 -0.000268681 7.33668 0.0391771 7.44607 0.112882C7.55566 0.184393 7.6385 0.28738 7.68259 0.40689L13.9267 16.542Z"
          fill="#007BFF"
        />
        <path
          d="M17.8288 26.6116H10.0254L13.9278 16.543L17.8288 26.6116Z"
          fill="#E57A1A"
        />
        <path
          d="M15.0726 13.5743L13.9258 16.5407L17.8199 26.6093L24.9041 8.32416C24.9382 8.23203 24.9486 8.13345 24.9345 8.0367C24.9195 7.94181 24.8809 7.85176 24.8216 7.7742C24.7616 7.69448 24.6823 7.62968 24.5907 7.5852C24.4986 7.54055 24.397 7.51673 24.2936 7.51562C22.2843 7.51629 20.3225 8.09841 18.667 9.18519C17.0241 10.2602 15.7688 11.793 15.0726 13.5743Z"
          fill="#F0A75A"
        />
        <path
          d="M22.8919 6.9073C23.6028 6.89619 24.2946 6.68478 24.8801 6.29963C25.4657 5.91449 25.9189 5.37279 26.183 4.74257C26.4469 4.11233 26.51 3.42168 26.3643 2.75735C26.2184 2.09302 25.8701 1.48462 25.3634 1.00858C24.8564 0.532543 24.2135 0.210082 23.5151 0.0816999C22.8169 -0.0466819 22.0946 0.0247394 21.4387 0.286994C20.7828 0.549247 20.2228 0.990639 19.8289 1.55573C19.435 2.12082 19.2248 2.78443 19.2246 3.46318C19.2326 4.38407 19.6235 5.26421 20.3112 5.91008C20.9989 6.55595 21.9271 6.91465 22.8919 6.9073Z"
          fill="#E57A1A"
        />
        <path
          d="M88.3553 0.21383C87.6775 -0.165496 85.9807 0.451399 85.8005 0.552465C84.4255 1.32293 83.7462 2.4911 83.698 3.99265C83.6623 5.11224 83.6692 6.23186 83.6582 7.35277C83.6582 7.49583 83.6582 7.63889 83.6582 7.78197C83.7201 8.19193 83.7151 8.60861 83.643 9.01707C82.6605 8.51281 81.6212 8.11652 80.5451 7.83578C79.1424 7.65464 77.7426 7.62052 76.3483 7.90535C72.7733 8.81232 70.2844 10.8914 69.1267 14.2607C67.4917 19.0226 70.1126 24.1547 75.016 25.9503C75.3158 26.0592 75.6237 26.1471 75.9276 26.2455C77.6271 26.7032 79.4253 26.7067 81.1267 26.2561C81.9601 26.0263 82.7635 25.7075 83.5221 25.3058C83.8149 25.5853 83.6458 25.899 83.6926 26.1759C83.8878 26.3624 84.0198 26.6157 84.3691 26.6104C85.5448 26.5959 86.7203 26.6104 87.896 26.5881C88.0569 26.5881 88.2151 26.4569 88.3746 26.3859C88.3898 25.9397 88.5369 2.15508 88.3553 0.21383ZM78.5719 22.0676C77.2003 22.0705 75.8835 21.5539 74.9103 20.6312C73.9373 19.7084 73.3873 18.4549 73.381 17.1456C73.3816 16.1743 73.6835 15.2249 74.2485 14.417C74.8135 13.6091 75.6164 12.979 76.556 12.6061C77.4955 12.2331 78.5298 12.134 79.5283 12.3213C80.5267 12.5085 81.4449 12.9738 82.1667 13.6583C82.8887 14.3429 83.3823 15.2161 83.5849 16.168C83.7878 17.1199 83.6908 18.1076 83.3064 19.0071C82.9219 19.9065 82.2673 20.6769 81.4246 21.2217C80.5821 21.7663 79.5894 22.0607 78.5719 22.0676Z"
          fill="#037DFF"
        />
        <path
          d="M111.306 8.34119C111.295 9.19304 111.298 10.0475 111.306 10.8967C111.306 12.1056 111.383 12.1843 112.621 12.187C115.192 12.187 117.763 12.187 120.335 12.187C120.676 12.187 121.022 12.141 121.483 12.3038C121.16 12.6398 120.916 12.9141 120.658 13.1674C117.756 15.9404 114.851 18.709 111.94 21.4732C111.503 21.8866 111.269 22.3015 111.302 22.9013C111.365 24.0577 111.25 25.218 111.371 26.373C111.697 26.6552 112.098 26.6026 112.487 26.6026C117.402 26.6026 122.317 26.6026 127.232 26.6026C127.705 26.6026 128.17 26.6368 128.427 26.1485C128.427 25.1602 128.435 24.1797 128.427 23.1848C128.419 22.1898 128.299 22.0901 127.235 22.0862C124.622 22.0795 122.022 22.0862 119.416 22.0862H118.29C118.466 21.569 118.801 21.3604 119.063 21.1097C121.959 18.335 124.861 15.5641 127.768 12.7973C128.094 12.4849 128.446 12.204 128.435 11.6882C128.41 10.5647 128.445 9.43979 128.412 8.31757C128.399 7.86605 128.431 7.58911 128.383 7.58911L126.748 7.57993C121.6 7.5458 116.472 7.56811 111.324 7.59305C111.308 7.59961 111.313 7.8923 111.306 8.34119Z"
          fill="#037DFF"
        />
        <path
          d="M33.2643 7.92193C34.8277 7.58182 36.4503 7.57554 38.0164 7.90355C39.6877 8.29834 41.2253 9.09496 42.4827 10.2176C44.9123 12.4056 45.7964 15.1488 45.6452 18.2608C45.6219 18.7452 45.3702 19.0208 44.8423 19.0181C44.5934 19.0181 44.343 19.0181 44.0928 19.0181C40.0593 19.0181 36.0259 19.0181 31.9925 19.0181C31.6623 19.0181 31.3048 18.9223 30.9514 19.1494C31.6157 20.5132 32.6469 21.3925 34.0687 21.8466C35.4191 22.2786 36.728 22.1093 38.0316 21.5933C38.8032 21.2702 39.6427 21.1213 40.4844 21.159C41.3262 21.1965 42.1475 21.4193 42.8841 21.81C43.815 22.2811 43.8687 22.5975 43.1728 23.3693C41.9064 24.7803 40.3018 25.6938 38.4634 26.2568C36.6162 26.7351 34.6677 26.7256 32.8257 26.2294C32.5919 26.1467 32.3568 26.0666 32.123 25.9812C30.8282 25.5102 29.6512 24.7849 28.6685 23.8524C27.6857 22.9198 26.9191 21.8008 26.418 20.5675C25.9169 19.3343 25.6927 18.0145 25.7596 16.6932C25.8268 15.3721 26.1835 14.079 26.8071 12.8978C28.1739 10.3199 30.3768 8.71075 33.2643 7.92193ZM40.3623 15.2512C39.6555 13.2246 37.5035 12.0184 35.1371 12.2257C33.1709 12.3977 31.3048 13.789 31.0862 15.2512H40.3623Z"
          fill="#037DFF"
        />
        <path
          d="M54.776 7.92193C56.3394 7.58182 57.9621 7.57554 59.5281 7.90355C61.2006 8.29825 62.7396 9.09486 63.9985 10.2176C66.4281 12.4056 67.3124 15.1488 67.1612 18.2608C67.1378 18.7452 66.8862 19.0208 66.3581 19.0181C66.1078 19.0181 65.8589 19.0181 65.6087 19.0181C61.5753 19.0181 57.5417 19.0181 53.5083 19.0181C53.1796 19.0181 52.8208 18.9223 52.4674 19.1494C53.1315 20.5132 54.1628 21.3925 55.5846 21.8466C56.9362 22.2786 58.2439 22.1093 59.5474 21.5933C60.319 21.2702 61.1585 21.1213 62.0003 21.159C62.8421 21.1965 63.6633 21.4193 64.4001 21.81C65.331 22.2811 65.386 22.5975 64.6901 23.3693C63.4224 24.7803 61.819 25.6938 59.9806 26.2568C58.1333 26.7349 56.1849 26.7254 54.343 26.2294C54.1092 26.1467 53.874 26.0666 53.6417 25.9812C52.3469 25.5104 51.1701 24.7854 50.1872 23.8532C49.2046 22.921 48.4376 21.8021 47.9364 20.5692C47.4351 19.3363 47.2105 18.0167 47.2771 16.6957C47.3437 15.3747 47.6999 14.0817 48.323 12.9004C49.6842 10.3199 51.8871 8.71075 54.776 7.92193ZM61.8726 15.2512C61.1672 13.2246 59.0153 12.0184 56.6474 12.2257C54.6812 12.3977 52.8139 13.789 52.5953 15.2512H61.8726Z"
          fill="#037DFF"
        />
        <path
          d="M97.929 7.92193C99.4922 7.58182 101.115 7.57554 102.681 7.90355C104.352 8.29861 105.89 9.0952 107.147 10.2176C109.577 12.4056 110.461 15.1488 110.31 18.2608C110.287 18.7452 110.035 19.0208 109.507 19.0181C109.258 19.0181 109.008 19.0181 108.757 19.0181C104.724 19.0181 100.69 19.0181 96.6571 19.0181C96.3285 19.0181 95.9696 18.9223 95.6174 19.1494C96.2803 20.5132 97.313 21.3925 98.7333 21.8466C100.085 22.2786 101.393 22.1093 102.696 21.5933C103.468 21.27 104.308 21.1213 105.15 21.159C105.992 21.1965 106.813 21.4193 107.55 21.81C108.481 22.2811 108.535 22.5975 107.839 23.3693C106.571 24.7803 104.968 25.6938 103.129 26.2568C101.282 26.7351 99.3337 26.7256 97.4917 26.2294C97.2565 26.1467 97.0228 26.0666 96.789 25.9812C95.4942 25.5102 94.3172 24.7849 93.3346 23.8524C92.3517 22.9198 91.5851 21.8008 91.084 20.5675C90.583 19.3343 90.3587 18.0145 90.4256 16.6932C90.4928 15.3721 90.8496 14.079 91.4731 12.8978C92.8372 10.3199 95.0399 8.71075 97.929 7.92193ZM105.027 15.2512C104.32 13.2246 102.168 12.0184 99.8017 12.2257C97.8355 12.3977 95.9681 13.789 95.7508 15.2512H105.027Z"
          fill="#037DFF"
        />
        <path
          d="M178.571 0H146.606C140.294 0 135.178 4.88417 135.178 10.9091V19.0909C135.178 25.1158 140.294 30 146.606 30H178.571C184.882 30 189.999 25.1158 189.999 19.0909V10.9091C189.999 4.88417 184.882 0 178.571 0Z"
          fill="#E57A1A"
        />
        <path
          d="M154.124 9.06445C152.551 9.06445 151.332 9.72147 150.584 10.7351V9.30848H148.52V22.6929H150.584V17.324C151.332 18.3377 152.551 18.9948 154.124 18.9948C156.917 18.9948 159.06 16.8736 159.06 14.0202C159.06 11.1857 156.917 9.06445 154.124 9.06445ZM153.79 17.2303C151.981 17.2303 150.525 15.8786 150.525 14.0202C150.525 12.1806 151.981 10.829 153.79 10.829C155.678 10.829 157.015 12.1618 157.015 14.0202C157.015 15.8786 155.678 17.2303 153.79 17.2303ZM162.65 10.8102V9.30848H160.586V18.7508H162.65V14.4895C162.65 11.7488 163.929 10.7351 166.033 11.0355V9.19586C164.401 8.98937 163.24 9.66515 162.65 10.8102ZM171.32 18.9948C174.349 18.9948 176.571 16.8548 176.571 14.0202C176.571 11.2044 174.349 9.06445 171.32 9.06445C168.292 9.06445 166.069 11.2044 166.069 14.0202C166.069 16.8548 168.292 18.9948 171.32 18.9948ZM171.32 17.2114C169.432 17.2114 168.075 15.8411 168.075 14.039C168.075 12.2181 169.432 10.8478 171.32 10.8478C173.208 10.8478 174.565 12.2181 174.565 14.039C174.565 15.8411 173.208 17.2114 171.32 17.2114Z"
          fill="#FDFDFE"
        />
      </g>
      <defs>
        <clipPath id="clip0_16613_96314">
          <rect width="190" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default VeedezProLogo;
