import styled from "styled-components";
import { stylingUtils } from "styles";

const { rem, color } = stylingUtils;

export const StyledAddMoneyNotice = styled("div")`
  border-radius: ${rem(4)};
  background-color: ${color("ash01")};
  margin-bottom: ${rem(20)};
  padding: ${rem(10)};

  span {
    font-weight: 700;
  }
`;

export const StyledAddMoneyModal = styled("div")`
  .button {
    width: 100%;
    height: 40px;
    margin-bottom: 2rem;
    border-radius: 10px;

    &:disabled {
      background-color: #0077ff;
      opacity: 0.6;
      cursor: not-allowed;
    }
  }
  .accountInfoContainer {
    border: 1px solid rgba(220, 227, 231, 0.7);
    border-radius: 10px;
    min-height: ${rem(230)};
    margin-bottom: 2rem;
  }
  .title {
    background: #0077ff;
    border-radius: 10px 10px 0px 0px;
    padding: 1rem;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.2px;
    color: #ffffff;
  }
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .col {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem;

    .section {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      width: 100%;
    }
    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #a5aeb5;
    }
    p {
      font-size: 14px;
      line-height: 17px;
      color: #374b58;
      padding-bottom: 0.5rem;
    }

    .section p {
      border-bottom: 1px solid #f8f8f8;
    }
  }
  .code {
    font-style: normal;
    font-weight: 400;
    color: #69778c;

    span {
      float: right;
      display: flex;
      color: #434343;
      gap: 0.5rem;
    }
  }
`;

export const PaymentChannel = styled("div")`
  padding-bottom: 1rem;

  .cards {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    overflow-x: scroll;
  }

  .card {
    width: 120px;
    height: 100px;
    border-radius: 12px;
    display: grid;
    place-content: center;
    cursor: pointer;
    min-width: 150px;
  }

  .card-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${rem(6)};
  }

  h4 {
    font-family: "Gilmer";
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #434343;
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .icon-div {
    background-color: #130f26;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    display: grid;
    place-content: center;
  }
`;
