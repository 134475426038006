import styled from "styled-components/macro";
import { FlexJAMixin, stylingUtils } from "styles";

const { rem, color } = stylingUtils;

export const StyledFileUploadContainer = styled("div")<{ fullWidth: boolean }>`
  max-width: ${({ fullWidth }) => (fullWidth ? "100%" : `${rem(400)}`)};
  position: relative;
`;

export const StyledVFileAvatarContainer = styled("div")`
  margin-bottom: ${rem(30)};
  ${FlexJAMixin};
  position: relative;
`;

export const StyledVFileAvatarUpload = styled("label")`
  ${FlexJAMixin};
  width: ${rem(80)}!important;
  height: ${rem(80)}!important;
  border-radius: 9999%;
  background-color: ${color("ash01")};
  outline: 8px solid #eff5ff;
  overflow: hidden;
  cursor: pointer;
`;

export const StyledVFileImageSelected = styled("img")`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

export const StyledVFileAvatarCameraIcon = styled("label")`
  position: absolute;
  bottom: -10px;
  z-index: 999;
  background-color: ${color("white")};
  width: ${rem(36)};
  height: ${rem(36)};
  transform: translateX(35px);
  box-shadow: 0px 5px 16px -4px rgba(29, 28, 28, 0.1);
  ${FlexJAMixin};
  border-radius: 9999%;
  cursor: pointer;
`;

export const StyledVFileField = styled("label")<{ disabled: boolean }>`
  height: ${rem(38.5)}!important;
  border: 1px solid ${color("inputBorder")};
  padding: ${rem(10)} ${rem(12)};
  border-radius: 12px;
  background-color: ${({ disabled }) => (disabled ? color("ash01") : "white")};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  opacity: ${({ disabled }) => disabled && 0.5};
  gap: ${rem(15)};
  display: flex;
  align-items: center;
  margin-top: -4px !important;

  &:hover {
    border: 1px solid ${color("inputBorderHover")} !important;
  }
`;

export const StyledVFileNameOrPlaceholder = styled("p")`
  flex: auto;
  margin-left: 1rem;
`;

export const StyledVFileCloseButton = styled("button")`
  position: absolute;
  right: ${rem(20)};
  bottom: ${rem(10)};
  flex: none;
`;

export const StyledVFilePreview = styled("div")`
  background-color: white;
  cursor: pointer;
  padding: ${rem(15)} ${rem(15)}!important;
  border-radius: 12px;
  border: 1px solid rgba(220, 227, 231, 0.5);
  width: 100%;
  height: ${rem(200)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${rem(15)};
  margin-top: 1.5rem;
`;

export const StyledVFilePreviewImage = styled("img")`
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 12px;
`;

export const StyledFilePreviewName = styled("p")`
  display: block;
`;
