import styled from "styled-components/macro";
import { stylingUtils } from "styles";

const {
  mediaQuery: { down },
} = stylingUtils;

export const StyledPagination = styled.section`
  display: flex;
  align-items: center;
  margin-top: ${stylingUtils.rem(30)};
  justify-content: center;
  gap: 1.5rem;

  .pagination__container {
    background-color: ${stylingUtils.color("ash01")};
    padding: ${stylingUtils.rem(5)} ${stylingUtils.rem(25)};
    border-radius: ${stylingUtils.rem(30)};
    display: flex;
    justify-content: center;
    gap: ${stylingUtils.rem(15)};

    ${down("tablet")} {
      flex-wrap: wrap;
    }
  }

  .pagination__pill {
    transition: 0.5s ease;
    flex: none;
    height: ${stylingUtils.rem(30)};
    width: ${stylingUtils.rem(30)};
    font-size: ${stylingUtils.rem(14)};
    color: ${stylingUtils.color("ash03")}!important;
    display: flex;
    justify-content: center;
    align-items: center;
    display: inline-block;
    border-radius: 9999%;
    background-color: ${stylingUtils.color("ash01")};

    &:hover {
      background-color: ${stylingUtils.color("ash03")};
      color: white !important;
    }
    svg {
      margin-top: 4px;
    }
  }

  .next__icon {
    transform: rotate(180deg);
  }

  .active__pill {
    background-color: ${stylingUtils.color("lightBlue")}!important;
    color: ${stylingUtils.color("white")}!important;
    fill: white !important;
  }
`;
