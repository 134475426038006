import { useNavigate, useParams } from "react-router-dom";
import { FormEvent, useCallback, useState, useMemo, ChangeEvent } from "react";
import {
  IBusinessRolesAndPermission,
  useGetBusinessRolesAndPermissionsQuery,
} from "api";
import { capitalizeWord, extractFetchedData, formatUnderScore } from "utils";
import { useAuthUser } from "store";
import {
  IRolesAndPermission,
  useAddRolesAndPermissionsMutation,
} from "api/manage-business/roles-and-permissions/add-roles-and-permission";
import { showToast } from "components/common";
import { APP_ROUTES } from "routes";

export const useRolesAndPermissionSelectionHook = (roleName: string = "") => {
  const navigate = useNavigate();

  const { userData } = useAuthUser();
  const admin = !!userData && userData?.is_admin ? "Admin" : "";

  const { role = "" } = useParams();

  const [editRole, setEditRole] = useState(false);
  const [newRoleName, setNewRoleName] = useState("");
  const [selectedPermissions, setSelectedPermissions] = useState<
    IRolesAndPermission[]
  >([]);
  const [selectedPermissionGroup, setSelectedPermissionGroup] =
    useState<string>("");

  const { handleAddNewRoleAndPermissions, newRoleAndPermissions } =
    useAddRolesAndPermissionsMutation();

  const [result] = useGetBusinessRolesAndPermissionsQuery({
    businessId: userData?.businessId ?? "",
  });

  const { extractedData } = extractFetchedData<IBusinessRolesAndPermission[]>({
    key: "getRoleAndUserAssociated",
    result,
  });

  const handleNewRoleName = (event: ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    setNewRoleName(target.value);
  };

  const handleChangePermission = (event: ChangeEvent<HTMLInputElement>) => {
    // check if permission is a permission module
    const isParentPermission = Object.keys(UI_ADD_PERMISSIONS).includes(
      event.target.name
    );

    if (isParentPermission && event.target.checked) {
      // retrieve all permissions within the permission module that is checked
      const childrenPermissions = UI_ADD_PERMISSIONS[event.target.name];

      for (let permission of childrenPermissions) {
        let checkbox = document.getElementById(permission);
        if (checkbox) {
          checkbox.setAttribute("checked", "checked");

          // retrieve the permission object to be sent to the server on submit
          const permissionDetail = roleGroup.permissions.find(
            (group: Record<string, string>) =>
              formatUnderScore(group.permission) ===
              checkbox?.getAttribute("name")
          );

          if (permissionDetail) {
            setSelectedPermissions((prevValue) => [
              ...prevValue,
              {
                module: permissionDetail.module,
                permission: permissionDetail.permission,
              },
            ]);
          }
        }
      }
    } else if (!isParentPermission && event.target.checked) {
      // retrieve the permission within the permission module that is checked
      const permissionDetail = roleGroup.permissions.find(
        (group: Record<string, string>) =>
          formatUnderScore(group.permission) === event.target.name
      );

      if (permissionDetail) {
        setSelectedPermissions((prevValue) => [
          ...prevValue,
          {
            module: permissionDetail.module,
            permission: permissionDetail.permission,
          },
        ]);
      }
    }

    if (isParentPermission && !event.target.checked) {
      // retrieve all permissions within the permission module that is unchecked for removal
      const childrenPermissions = UI_ADD_PERMISSIONS[event.target.name];

      for (let permission of childrenPermissions) {
        let checkbox = document.getElementById(permission);
        if (checkbox) {
          checkbox.removeAttribute("checked");

          // retrieve the permission object already added to the permissions list for removal
          const permissionDetail = roleGroup.permissions.find(
            (group: Record<string, string>) =>
              formatUnderScore(group.permission) ===
              checkbox?.getAttribute("name")
          );

          if (permissionDetail) {
            setSelectedPermissions((prevValue) =>
              prevValue.filter(
                (value) => formatUnderScore(value.permission) !== permission
              )
            );
          }
        }
      }
    } else if (!isParentPermission && !event.target.checked) {
      // retrieve the permission object already added to the permissions list for removal
      const permissionDetail = roleGroup.permissions.find(
        (group: Record<string, string>) =>
          formatUnderScore(group.permission) === event.target.name
      );

      if (permissionDetail) {
        setSelectedPermissions((prevValue) =>
          prevValue.filter(
            (value) => formatUnderScore(value.permission) !== event.target.name
          )
        );
      }
    }
  };

  const handleSubmitRoleAndPermissions = async (
    event: FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();

    // make API request
    await handleAddNewRoleAndPermissions(
      {
        roleName: newRoleName,
        permissions: selectedPermissions,
      },
      () => {
        showToast({
          type: "success",
          title: "Role Added Successfully!",
        });
      }
    ).then(() => navigate(APP_ROUTES.ROLES_AND_PERMISSIONS));

    setNewRoleName("");
    setSelectedPermissions([]);
  };

  const allRoles = useMemo(() => {
    return Array.isArray(extractedData) ? extractedData : [];
  }, [extractedData]);

  const mappedRoles = useMemo(() => {
    const newRoles = allRoles;
    const getMappedRoles = newRoles.reduce((acc, curr) => {
      acc[curr?.roleName?.toUpperCase()] = curr;
      return acc;
    }, {} as Record<string, IBusinessRolesAndPermission>);

    return getMappedRoles;
  }, [allRoles]);

  const permissionModules = useMemo(() => {
    const roleGroup = mappedRoles[(role || roleName || admin).toUpperCase()];
    const permissions = roleGroup?.permissions || [];
    const modules = permissions.reduce((acc, curr) => {
      const key = curr?.module?.toUpperCase();
      acc[key] = acc[key] ? [...acc[key], curr] : [curr];
      return acc;
    }, {} as Record<string, IBusinessRolesAndPermission["permissions"]>);
    return { modules, roleGroup };
  }, [mappedRoles, role, roleName, admin]);

  const roleGroup = permissionModules?.roleGroup;
  const modules = permissionModules?.modules;

  const UI_PERMISSIONS_LIST = useMemo(() => {
    const UPL = Object.keys(modules).map((module) => ({
      [capitalizeWord(module.toLowerCase())]: modules[module].map(
        (permission: Record<string, string>) =>
          formatUnderScore(permission.permission)
      ),
    }));
    return UPL;
  }, [modules]);

  // cached key,value pairs of permission modules and nested permissions as required by the UI
  const UI_ADD_PERMISSIONS = useMemo(() => {
    const UP = UI_PERMISSIONS_LIST.reduce((acc, curr) => {
      const key = Object.keys(curr)[0];
      acc[key] = curr[key];
      return acc;
    }, {} as Record<string, string[]>);
    return UP;
  }, [UI_PERMISSIONS_LIST]);

  // split permissions list into two parts for easy rendering in both sections of the UI
  const permissionsKeys = Object.keys(UI_ADD_PERMISSIONS);
  const lengthOfPermissions = permissionsKeys.length;
  const halfPoint = Math.ceil(lengthOfPermissions / 2);

  const UI_ADD_PERMISSIONS_LEFT: Record<string, string[]> = {};
  const UI_ADD_PERMISSIONS_RIGHT: Record<string, string[]> = {};

  for (let i = 0; i < halfPoint; i++) {
    UI_ADD_PERMISSIONS_RIGHT[permissionsKeys[i]] =
      UI_ADD_PERMISSIONS[permissionsKeys[i]];
  }

  for (let i = halfPoint; i < lengthOfPermissions; i++) {
    UI_ADD_PERMISSIONS_LEFT[permissionsKeys[i]] =
      UI_ADD_PERMISSIONS[permissionsKeys[i]];
  }

  const permissionGroupchildren = useMemo(() => {
    return permissionModules?.modules?.[selectedPermissionGroup] || [];
  }, [selectedPermissionGroup, permissionModules]);

  const handleSelectedPermissionGroup = useCallback((permission: string) => {
    setSelectedPermissionGroup(permission?.toUpperCase());
  }, []);

  const goBack = () => navigate(-1);

  return {
    selectedPermissionGroup,
    handleSelectedPermissionGroup,
    permissionGroupchildren,
    permissionModules,
    goBack,
    mappedRoles,
    allRoles,
    isFetching: result?.fetching,
    isSubmitting: newRoleAndPermissions?.fetching,
    extractedData,
    newRoleName,
    handleNewRoleName,
    handleSubmitRoleAndPermissions,
    handleChangePermission,
    UI_ADD_PERMISSIONS_RIGHT,
    UI_ADD_PERMISSIONS_LEFT,
    UI_ADD_PERMISSIONS,
    editRole,
    setEditRole,
    lengthOfPermissions: selectedPermissions.length,
  };
};
