import clsx from "clsx";
import { useLocalSearchParams, usePagination, useWindowSize } from "hooks";
import { StyledPagination } from "./styles";
import { ReactComponent as CheveronIcon } from "assets/icons/chevron-left.svg";
import { VPaginationProps } from "components/common/types";

import { useMemo } from "react";
import { E_DOT_TYPE } from "hooks/usePagination";

export const VPagination = ({ pageInfo, getPageNumber }: VPaginationProps) => {
  const { totalCount, size, pages } = (pageInfo || {}) as any;

  const { searchParams, addSearchParams } = useLocalSearchParams();

  const { width } = useWindowSize();

  const isMobile = width && width <= 767;

  const pageNumber = parseInt(searchParams.get("page") ?? "1");

  const totalPageCount = useMemo(() => {
    let pageCount = Math.ceil((totalCount || 1) / (size || 1));
    pageCount = pageCount <= 1 ? 0 : pageCount;
    return pageCount === 0 && pages > 0 ? pages : pageCount;
  }, [totalCount, size, pages]);

  const isLastPage = pageNumber >= totalPageCount;

  const isFirstpage = pageNumber <= 1;

  const { pagination } = usePagination({
    totalPageCount,
    currentPage: pageNumber,
    ...(isMobile && {
      siblingCount: 1,
      customItemCount: 3,
    }),
  });

  const handlePageChange = async (page: number) => {
    if (getPageNumber) {
      addSearchParams("page", page?.toString());
      getPageNumber(page);
    }
  };

  if (!pagination.length) {
    return null;
  }

  return (
    <StyledPagination>
      {!isFirstpage && (
        <button
          className={clsx("pagination__pill", "active__pill")}
          onClick={() => handlePageChange(pageNumber - 1)}
        >
          <CheveronIcon height={10} />
        </button>
      )}

      <div className="pagination__container">
        {[...pagination].map((page, index) => {
          const isActive = pageNumber === page;
          return (
            <button
              onClick={() => {
                if (page.number) {
                  const { type } = page;
                  if (type === E_DOT_TYPE.LEFT) {
                    handlePageChange(pageNumber - 2);
                  } else {
                    handlePageChange(pageNumber <= 4 ? 5 : pageNumber + 2);
                  }
                } else {
                  handlePageChange(page);
                }
              }}
              key={index}
              className={clsx("pagination__pill", isActive && "active__pill")}
            >
              {page?.number ? page.number : page}
            </button>
          );
        })}
      </div>
      {!isLastPage && (
        <button
          onClick={() => handlePageChange(pageNumber + 1)}
          className={clsx("pagination__pill", "active__pill", "next__icon")}
        >
          <CheveronIcon height={10} />

          {/* <img src={ChevronLeft} alt="chevron-right" /> */}
        </button>
      )}
    </StyledPagination>
  );
};
