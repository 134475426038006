import clsx from "clsx";
import { VButtonProps } from "components/common/types";
import { StyledVButton, VStyledButtonLoader } from "./styles";

export const VButton = ({
  bgColor = "#0077FF",
  textColor = "white",
  align = "center",
  minWidth,
  mobWidth,
  width,
  height = "35px",
  noborder = true,
  event = "pointer",
  icon,
  isOutline = false,
  isLoading = false,
  text,
  showOutline = false,
  isSlim = false,
  makeSlim,
  vButtonClassName = "",
  disabled,
  ...rest
}: VButtonProps) => {
  if (makeSlim) {
    height = "30px";
    noborder = false;
    isSlim = true;
  }

  return (
    <StyledVButton
      isSlim={isSlim}
      showOutline={showOutline}
      isOutline={isOutline}
      bgColor={bgColor}
      textColor={textColor}
      align={align}
      width={width}
      height={height}
      noborder={noborder}
      minWidth={minWidth}
      mobWidth={mobWidth}
      event={event}
      disabled={disabled}
      {...rest}
    >
      <div className={clsx(vButtonClassName)}>
        {isLoading ? (
          <VStyledButtonLoader width={18} height={18} />
        ) : (
          <>
            {icon && icon}
            {text}
          </>
        )}
      </div>
    </StyledVButton>
  );
};

export default VButton;
