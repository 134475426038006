import React, { useState } from "react";
import { StyledVToolTip, StyledVTooltipWraper } from "./styles";

type VToolTipProps = {
  children: string | JSX.Element | JSX.Element[];
  content?: string | JSX.Element | JSX.Element[];
  direction?: "top" | "bottom" | "right" | "left";
  delay?: number;
};

export const VTooltip = ({
  children,
  content,
  direction = "top",
  delay = 400,
}: VToolTipProps) => {
  let timeout: any;
  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, delay || 400);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  return (
    <StyledVTooltipWraper
      // className={styles.tooltipWrapper}
      // When to show the tooltip
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
    >
      {/* Wrapping */}
      {children}
      {active && !!content && (
        <StyledVToolTip
          className={`${direction}`}
          // className={[styles.tooltipTip, styles[props.direction], styles.top].join(" ")}
        >
          {/* Content */}
          {content}
        </StyledVToolTip>
      )}
    </StyledVTooltipWraper>
  );
};

export const checkForNull = (value: string, useDefault = "") => {
  const hasNoValue = !value?.trim();

  if (hasNoValue) {
    if (useDefault) return;
    else return "N/A";
  } else {
    return value;
  }
};
