/* eslint-disable no-param-reassign */
import axios, { AxiosInstance } from "axios";
import { AppStorage, getEnvKey, STORGAGE_KEYS } from "utils";

const API_KEY = "";

const { getFromStore } = new AppStorage();

/** Make an api request call */
export const useApiRequest = ({
  baseURL,
  token,
}: {
  baseURL: string;
  token?: string;
}): AxiosInstance => {
  // Resolve request type

  const request = axios.create({
    baseURL,
  });

  // Add a request interceptor
  request.interceptors.request.use(
    async (config: any) => {
      // Get user token

      // If the method requested is not a get request attach the idempotent key

      // If no token, do nothing else and return config
      if (!token) {
        return config;
      }

      // If none of above match then modify the headers appropriately
      config.headers.Authorization = `Bearer ${token}`;

      return config;
    },
    (error) => Promise.reject(error)
  );

  return request;
};

export const useVeedezApiRequest = (): AxiosInstance => {
  // Resolve request type
  const request = axios.create({
    baseURL: getEnvKey("REACT_APP_API_REST_URL"),
  });

  // Add a request interceptor
  request.interceptors.request.use(
    async (config: any) => {
      // Get user token

      // If the method requested is not a get request attach the idempotent key

      // If no token, do nothing else and return config

      const token = getFromStore(STORGAGE_KEYS.USER_TOKEN);
      // If none of above match then modify the headers appropriately
      config.headers.Authorization = `Bearer ${token}`;

      return config;
    },
    (error) => Promise.reject(error)
  );

  return request;
};

/** Make an api request call */
export const useAlerzoPayApiRequest = (): AxiosInstance => {
  // Resolve request type

  const request = axios.create({
    baseURL: getEnvKey("REACT_APP_ALERZOPAY_BASE_URL"),
  });

  // Add a request interceptor
  request.interceptors.request.use(
    async (config: any) => {
      config.headers = {
        Authorization: API_KEY,
        "Content-Type": "application/json",
      };

      return config;
    },
    (error) => Promise.reject(error)
  );

  return request;
};
