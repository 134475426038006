import TodayCustomersIcon from "assets/images/svgs/TodayCustomersIcon";
import {
  CardProps,
  CustomTableProps,
  InputList,
  VDropdownProps,
} from "components/common/types";
import { APP_ROUTES } from "routes";
import {
  CUSTOM_VALIDATIONS,
  formatNumberToCurrency,
  formatUnderScore,
  truncateWord,
} from "utils";
import { Contacts, StyledCustomerIconWrapper } from "../customers";
import PurchasesIcon from "assets/images/svgs/TodaySalesIcon";
import { IBusinessCustomer } from "../customers/edit-customer-modal";
import emailIcon from "assets/images/svgs/email-icon.svg";
import phoneIcon from "assets/images/svgs/phone-icon.svg";
import { IDebtorsTopCards } from "api";
import { VBadge, VDropdown } from "components/common";
import VerticalDropdown from "assets/icons/vertical-dot.svg";
import { useNavigate } from "react-router-dom";

export const CUSTOMER_PAGE_TABS = [
  {
    key: "customers",
    label: "Customers",
    link: `${APP_ROUTES.CUSTOMERS}`,
  },
  {
    key: "debotrs",
    label: "Debtors",
    link: `${APP_ROUTES.CUSTOMERS_DEBTORS}`,
  },
];

export const CUSTOMERS_DEBTORS_CARDS_DATA = ({
  getBusinessCustomersTotalDebt,
  getTotalBusinessCustomersCount,
}: IDebtorsTopCards): CardProps[] => {
  return [
    {
      title: "Total Debtors",
      icon: () => (
        <StyledCustomerIconWrapper color="#FBECDB">
          <TodayCustomersIcon />
        </StyledCustomerIconWrapper>
      ),
      amount: formatNumberToCurrency({
        number: Number(getTotalBusinessCustomersCount),
        removeCurrency: true,
      }),
    },
    {
      title: `Total Amount Owed`,
      icon: () => (
        <StyledCustomerIconWrapper color="#E8F5F1">
          <PurchasesIcon />
        </StyledCustomerIconWrapper>
      ),
      amount: formatNumberToCurrency({
        number: Number(getBusinessCustomersTotalDebt),
      }),
    },
  ];
};

export const DEBTORS_TABLE_COLUMN = (): CustomTableProps["columns"] => {
  const navigate = useNavigate();

  return [
    {
      headerName: "Customer ID",
      key: "referenceId",
    },
    {
      headerName: "Name",
      key: "customerName",
      formatter: (customerName: string) => formatUnderScore(customerName || ""),
      renderCell: (value: string) => (
        <p>{truncateWord(value, { length: 25 })}</p>
      ),
    },
    {
      headerName: "Amount Owed",
      key: "totalDebt",
      useZero: false,
      renderCell: (amountOwed: string) => (
        <p className="lightblue__text">
          {formatNumberToCurrency({ number: amountOwed || 0 })}
        </p>
      ),
    },

    {
      headerName: "Contact",
      key: "email",
      width: 50,
      renderCell: (email: string, data: any) => (
        <>
          <Contacts>
            {email ? (
              <div className="contact-image">
                <a href={`mailto:${email}`} target="_blank" rel="noreferrer">
                  <img src={emailIcon} alt="card" />
                </a>
              </div>
            ) : null}
            {data.phoneNumber ? (
              <div className="contact-image">
                <a href={`tel:${data.phoneNumber}`}>
                  <img src={phoneIcon} alt="card" />
                </a>
              </div>
            ) : null}
          </Contacts>
        </>
      ),
    },
    {
      headerName: "Action",
      key: "dropdown",
      renderCell: (_: any, tableData: IBusinessCustomer) => {
        const dropdownItems = [
          ...DEBTORS_ACTIONS_DROPDOWNS({
            handleViewDebtorDetails: () =>
              navigate(`${APP_ROUTES.CUSTOMERS_DEBTORS}/${tableData?._id}`),
          }),
        ].map((item) => ({
          ...item,
        }));

        return (
          <VDropdown
            dropdownButton={
              <button className="vertical__drop--down">
                <img src={VerticalDropdown} alt="vertical-dropdown" />
              </button>
            }
            dropdownItems={dropdownItems}
            isLeft={true}
          />
        );
      },
    },
  ];
};

export const DEBTORS_ACTIONS_DROPDOWNS = ({
  handleViewDebtorDetails,
}: {
  handleViewDebtorDetails: () => void;
}): VDropdownProps["dropdownItems"] => {
  return [
    {
      key: "view",
      label: "View",
      styles: { color: "rgb(0, 119, 255)" },
      onClick: () => handleViewDebtorDetails(),
    },
  ] as VDropdownProps["dropdownItems"];
};

export const DEBTORS_DETAILS_INVOICE_TABLE_COLUMN = ({
  handleViewInvoiceModal,
}: {
  handleViewInvoiceModal: (invoiceId: string) => void;
}): CustomTableProps["columns"] => {
  return [
    {
      headerName: "Invoice Id",
      key: "invoiceNumber",
    },
    {
      headerName: "Amount Owed",
      key: "amountDue",
      formatter: (amountOwed: string) =>
        formatNumberToCurrency({ number: amountOwed || 0 }),
    },
    {
      headerName: "Invoice Type",
      key: "transactionType",
      formatter: (transactionType: string) => formatUnderScore(transactionType),
    },
    {
      headerName: "Status",
      key: "invoiceStatus",
      renderCell: (invoiceStatus: string) => (
        <VBadge
          status={invoiceStatus}
          label={formatUnderScore(invoiceStatus)}
        />
      ),
    },
    {
      headerName: "",
      key: "_id",
      renderCell: (value: any, tableData: IBusinessCustomer) => {
        return (
          <button
            className="lightblue__text"
            onClick={() => {
              handleViewInvoiceModal(value);
            }}
          >
            View
          </button>
        );
      },
    },
  ];
};

export const DEBTORS_STATUS_CHIPS = [
  {
    key: "all",
    label: "All",
  },

  {
    key: "asc",
    label: "Ascending",
  },
  {
    key: "desc",
    label: "Descending",
  },
];

export const ADD_DEBOTR_FORM_INPUT_LIST = (): InputList => {
  return [
    {
      name: "debtorName",
      label: `Debtor's Name`,
      required: true,
      validation: { required: "Enter debtor name" },
    },
    {
      name: "phoneNumber",
      label: "Phone Number",
      type: "tel",
      required: true,
      validation: {
        required: "Enter your phone number",
        ...CUSTOM_VALIDATIONS.phoneNumber,
      },
    },
    {
      name: "email",
      label: "Email",
      type: "email",
      required: true,
      validation: {
        ...CUSTOM_VALIDATIONS.email,
      },
    },
    {
      name: "productBought",
      label: "Product Bought",
      required: true,
      validation: { required: "Enter product bought" },
    },
    {
      name: "amountOwned",
      label: "Amount Owned",
      inputType: "amount",
      required: true,
      type: "amount",
    },
    {
      name: "dueDate",
      label: "Due date",
      type: "date",
      required: true,
      validation: { required: "Enter due date" },
    },
  ];
};

export const DEBTOR_DETAILS_HEADER = {
  TOP: [
    {
      label: "Customer I.D",
      key: "referenceId",
    },
    {
      label: "Total Amount Owed",
      key: "totalDebt",
      formatter: (value: number) => formatNumberToCurrency({ number: value }),
    },
  ],
  BOTTOM: [
    {
      label: "Phone Number",
      key: "phoneNumber",
    },
    {
      label: "Official Email",
      key: "email",
    },
    {
      label: "Status",
      key: "status",
    },
  ],
};
