const EssentialPlanIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 55 61.11"
      {...props}
    >
      <mask
        id="mask0_3535_122104"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="55"
        height="55"
      >
        <circle cx="27.5" cy="27.5" r="27.5" fill="#E4E9FF" />
      </mask>
      <g mask="url(#mask0_3535_122104)">
        <circle cx="27.5" cy="27.5" r="27.5" fill="#E4E9FF" />
        <mask
          id="mask1_3535_122104"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="10"
          y="17"
          width="34"
          height="45"
        >
          <rect
            x="10.9999"
            y="17.7227"
            width="33"
            height="43.3889"
            rx="14"
            fill="#4791FF"
          />
        </mask>
        <g mask="url(#mask1_3535_122104)">
          <rect
            x="10.9999"
            y="17.7227"
            width="33"
            height="43.3889"
            rx="14"
            fill="#2A2368"
          />
          <circle
            opacity="0.1"
            cx="27.5003"
            cy="20.1667"
            r="9.16666"
            fill="#0077FF"
          />
        </g>
        <circle cx="27.5003" cy="18.0651" r="9.16666" fill="#4791FF" />
        <rect
          x="15.889"
          y="32.3906"
          width="23.2222"
          height="7.94444"
          rx="3.97222"
          fill="#554E90"
        />
        <path
          d="M20.4723 34.8359H31.1667"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          opacity="0.5"
          d="M20.4723 37.8906H26.2778"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <rect
          x="15.889"
          y="43.3867"
          width="23.2222"
          height="3.05556"
          rx="1.52778"
          fill="#554E90"
        />
        <path
          d="M27.2964 16.1484C26.3908 16.1484 25.6539 16.8853 25.6539 17.791C25.6539 18.6966 26.3908 19.4335 27.2964 19.4335C28.2021 19.4335 28.939 18.6966 28.939 17.791C28.939 16.8853 28.2021 16.1484 27.2964 16.1484ZM27.2964 18.3301C27.156 18.3264 27.0225 18.2679 26.9245 18.1673C26.8265 18.0666 26.7716 17.9317 26.7716 17.7911C26.7716 17.6506 26.8265 17.5157 26.9245 17.415C27.0225 17.3143 27.156 17.2559 27.2964 17.2522C27.4369 17.2559 27.5704 17.3143 27.6684 17.415C27.7664 17.5157 27.8213 17.6506 27.8213 17.7911C27.8213 17.9317 27.7664 18.0666 27.6684 18.1673C27.5704 18.2679 27.4369 18.3264 27.2964 18.3301Z"
          fill="white"
        />
        <path
          d="M27.296 14.1211C25.2714 14.1211 23.6235 15.7686 23.6235 17.7932C23.6235 18.5612 23.86 19.2988 24.3075 19.9273C24.3483 19.9894 24.4011 20.0427 24.4629 20.0839C24.5247 20.1251 24.5942 20.1534 24.6672 20.1671C24.7402 20.1808 24.8152 20.1796 24.8877 20.1637C24.9603 20.1477 25.0288 20.1173 25.0893 20.0742C25.1498 20.0312 25.201 19.9763 25.2398 19.913C25.2786 19.8497 25.3043 19.7792 25.3152 19.7057C25.3261 19.6323 25.3221 19.5574 25.3034 19.4855C25.2847 19.4136 25.2517 19.3462 25.2064 19.2874C24.8945 18.8516 24.7269 18.3291 24.7273 17.7932C24.7273 16.3773 25.8801 15.2252 27.296 15.2252C28.7119 15.2252 29.8643 16.3773 29.8643 17.7932C29.8643 18.3304 29.699 18.8468 29.3853 19.2874C29.3004 19.4067 29.2664 19.5547 29.2907 19.699C29.315 19.8434 29.3957 19.9721 29.5149 20.057C29.6342 20.1418 29.7822 20.1758 29.9266 20.1515C30.0709 20.1272 30.1996 20.0466 30.2845 19.9273C30.7298 19.3049 30.9689 18.5585 30.9681 17.7932C30.9681 15.7686 29.3206 14.1211 27.296 14.1211Z"
          fill="white"
        />
        <path
          d="M27.2968 12.0898C24.1521 12.0898 21.5936 14.6487 21.5936 17.793C21.5936 18.985 21.9613 20.1302 22.6567 21.1051C22.6988 21.1641 22.7521 21.2142 22.8136 21.2526C22.875 21.291 22.9434 21.3169 23.0149 21.3288C23.0864 21.3408 23.1595 21.3385 23.2301 21.3222C23.3007 21.3059 23.3675 21.2759 23.4264 21.2338C23.4854 21.1917 23.5356 21.1384 23.574 21.0769C23.6123 21.0155 23.6383 20.947 23.6502 20.8756C23.6622 20.8041 23.6599 20.7309 23.6436 20.6603C23.6273 20.5897 23.5972 20.523 23.5551 20.464C22.9967 19.6854 22.6967 18.7512 22.6974 17.793C22.6974 15.257 24.7608 13.194 27.2968 13.194C29.8328 13.194 31.8962 15.257 31.8962 17.793C31.8962 18.7538 31.5997 19.6774 31.0385 20.464C30.9964 20.523 30.9663 20.5897 30.95 20.6603C30.9337 20.7309 30.9315 20.8041 30.9434 20.8756C30.9554 20.947 30.9813 21.0155 31.0197 21.0769C31.0581 21.1384 31.1082 21.1917 31.1672 21.2338C31.2863 21.3188 31.4344 21.353 31.5787 21.3288C31.6502 21.3169 31.7186 21.291 31.7801 21.2526C31.8415 21.2142 31.8948 21.1641 31.9369 21.1051C32.6294 20.1398 33.0012 18.9814 33 17.7934C33 14.6483 30.4416 12.0902 27.2968 12.0902V12.0898Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default EssentialPlanIcon;
