import { IUserDetails } from "api";
import { BaseRouteProps } from "routes/types";
import { pick } from "lodash";
import { SideBarItemProps } from "components/sidebar/types";

export enum AdminRoles {
  ADMIN = "ADMIN",
  STAFF = "STAFF",
}

export const ALL_PERMISSIONS = {
  ADMIN_PERMISSION: {
    ACCESS_ADMIN: "ACCESS_ADMIN",
  },
  DASHBOARD_PERMISSION: {
    VIEW_DASHBOARD: "VIEW_DASHBOARD",
  },
  PRODUCT_AND_SERVICES_PERMISSION: {
    CREATE_PRODUCT: "CREATE_PRODUCT",
    VIEW_PRODUCT: "VIEW_PRODUCT",
    EDIT_PRODUCT: "EDIT_PRODUCT",
    CREATE_SERVICE: "CREATE_SERVICE",
    DELETE_PRODUCT: "DELETE_PRODUCT",
    VIEW_SERVICE: "VIEW_SERVICE",
    EDIT_SERVICE: "EDIT_SERVICE",
    DELETE_SERVICE: "DELETE_SERVICE",
    ADD_INVENTORY_CATEGORY: "ADD_INVENTORY_CATEGORY",
  },
  SALES_PERMISSION: {
    CHECKOUT_SALE: "CHECKOUT_SALE",
    VIEW_SALE: "VIEW_SALE",
    DELETE_SALE: "DELETE_SALE",
    PRINT_RECEIPT: "PRINT_RECEIPT",
  },
  EXPENSE_PERMISSION: {
    CREATE_EXPENSE: "CREATE_EXPENSE",
    EDIT_EXPENSE: "EDIT_EXPENSE",
  },
  PAYMENT_PERMISSION: {
    ADD_MONEY: "ADD_MONEY",
    SEND_MONEY: "SEND_MONEY",
    REQUEST_POS: "REQUEST_POS",
    COMPLETE_KYC: "COMPLETE_KYC",
  },
  INVOICES_PERMISSION: {
    CREAT_INVOICE: "CREAT_INVOICE",
    VIEW_INVOICE: "VIEW_INVOICE",
    EDIT_INVOICE: "EDIT_INVOICE",
    DELETE_INVOICE: "DELETE_INVOICE",
  },
  CUSTOMER_PERMISSION: {
    CREATE_CUSTOMER: "CREATE_CUSTOMER",
    VIEW_CUSTOMER: "VIEW_CUSTOMER",
    EDIT_CUSTOMER: "EDIT_CUSTOMER",
    DELETE_CUSTOMER: "DELETE_CUSTOMER",
    EXPORT_CUSTOMERS_LIST: "EXPORT_CUSTOMERS_LIST",
    VIEW_DEBTORS: "VIEW_DEBTORS",
  },
  STAFF_PERMISSION: {
    ADD_STAFF: "ADD_STAFF",
    EDIT_STAFF: "EDIT_STAFF",
    VIEW_STAFF: "VIEW_STAFF",
    DEACTIVATE_STAFF: "DEACTIVATE_STAFF",
  },
  STORE_PERMISSIONS: {
    ADD_STORE: "ADD_STORE",
    VIEW_STORE: "VIEW_STORE",
    DEACTIVATE_STORE: "DEACTIVATE_STORE",
    EDIT_STORE: "EDIT_STORE",
  },
  REPORT_PERMISSION: {
    VIEW_REPORT: "VIEW_REPORT",
    EXPORT_REPORT: "EXPORT_REPORT",
  },
  USER_PROFILE_PERMISSION: {
    EDIT_USER_DETAILS: "EDIT_USER_DETAILS",
  },
  DISPUTE_PERMISSION: {
    LODGE_DISPUTE: "LODGE_DISPUTE",
  },
};

/* 
    @returns
    {
        permission1: permission1;
        permission2: permisson 2
    }
*/
export const flattedPermissionsToObject = (permissions: Record<string, any>) =>
  Object.values(permissions).reduce((acc, curr) => {
    for (const key in curr) {
      acc[key] = (curr as any)[key];
    }
    return acc;
  }, {} as Record<string, any>);

/* 
    @returns
    {
        permission1: [permission1, permission2];
        permission2: [permisson 2, permission ]
    }
*/
export const flattedPermissionsToArray = (permissions: Record<string, any>) =>
  Object.keys(permissions).reduce((acc, curr) => {
    const values = (permissions as any)[curr];

    acc[curr] = Object.values(values);

    return acc;
  }, [] as any);

export const allPermissionToStringObject: Record<string, string> =
  flattedPermissionsToObject(ALL_PERMISSIONS);
export const allPermissionToArray: Record<string, string[]> =
  flattedPermissionsToArray(ALL_PERMISSIONS);

export const STAFF_PERMISSIONS: Record<string, string> = pick(
  flattedPermissionsToObject,
  [
    "CHECKOUT_SALE",
    "VIEW_SALE",
    "PRINT_RECEIPT",
    "CREAT_INVOICE",
    "VIEW_INVOICE",
    "CREATE_CUSTOMER",
    "VIEW_CUSTOMER",
    "VIEW_DEBTORS",
    "EDIT_USER_DETAILS",
    "LODGE_DISPUTE",
  ]
);

export const checkPermission = (permissions: string[], isAdmin = false) => {
  const hasPermission = isAdmin
    ? true
    : permissions.find(
        (permission) => STAFF_PERMISSIONS[permission?.toUpperCase()]
      );
  return {
    hasPermission,
  };
};

const getPermissibleRoutes = (
  routes: BaseRouteProps[],
  role: string
): any[] => {
  return routes
    .map(({ routes: groupElement, ...rest }) => ({
      ...rest,
      routes: groupElement && getPermissibleRoutes(groupElement, role),
    }))
    .filter(({ permittedRoles }) =>
      !permittedRoles
        ? true
        : permittedRoles.includes(role?.toUpperCase() as any)
    );
};

const getPermissibleMenu = (
  sidebarItems: SideBarItemProps[],
  role: string
): any[] => {
  return sidebarItems
    .map(({ subNav, ...rest }) => ({
      ...rest,
      subNav: subNav && getPermissibleMenu(subNav, role),
    }))
    .filter(({ permittedRoles }) =>
      !permittedRoles
        ? true
        : permittedRoles.includes(role?.toUpperCase() as any)
    );
};

export const generateUserLayout = ({
  user,
  allRoutes,
  sideBarData,
}: {
  user: IUserDetails;
  allRoutes: BaseRouteProps[];
  sideBarData: SideBarItemProps[];
}) => {
  const { is_admin: isAdmin } = user;

  const permissibleRoutes: BaseRouteProps[] = isAdmin
    ? allRoutes
    : getPermissibleRoutes(allRoutes, AdminRoles.ADMIN);

  const permissibleMenuList: SideBarItemProps[] = isAdmin
    ? sideBarData
    : getPermissibleMenu(sideBarData, AdminRoles.ADMIN);

  return { permissibleRoutes, permissibleMenuList };
};
