const GrowthIcon = (props: any) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_4040_126160)">
        <path
          d="M19.875 21.375H22.125V8.25H19.875V21.375Z"
          stroke="inherit"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.375 21.375H17.625V12.375H15.375V21.375Z"
          stroke="inherit"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.875 21.375H13.125V15H10.875V21.375Z"
          stroke="inherit"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.375 21.375H8.625V16.5H6.375V21.375Z"
          stroke="inherit"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.875 21.375H4.125L4.125 17.25H1.875L1.875 21.375Z"
          stroke="inherit"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.33594 14.7188C9.74969 15.75 18.7497 10.875 19.4997 4.5"
          stroke="inherit"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18 4.4925L21 4.5075L19.5075 2.625L18 4.4925Z"
          stroke="inherit"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4040_126160">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GrowthIcon;
