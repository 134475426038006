import { createSlice } from "@reduxjs/toolkit";
import { DOCUMENT_VALIDATION, IUserBusinessDetails, IUserDetails } from "api";
import { KYC_FIELDS } from "components/manage-business";
import { BUSINESS_PLANS } from "components/auth/signup/constants";
import { useSelector } from "react-redux";
import { IRootState } from "store/store";
import { AppStorage } from "utils";
import { omit } from "lodash";

const { removeFromStore } = new AppStorage();

export type AuthProps = {
  userToken: null | string;
  isAuthenticated: boolean;
  userData: null | IUserDetails;
};

const initialState: AuthProps = {
  userToken: null,
  isAuthenticated: false,
  userData: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setUserData: (state, { payload }) => {
      const { userToken, isAuthenticated, userData } = payload;
      state.userToken = userToken ?? state.userToken;
      state.isAuthenticated = isAuthenticated ?? state.isAuthenticated;
      if (userData) {
        state.userData = state.userData
          ? { ...state.userData, ...userData }
          : userData;
      }
    },
    updateUserData: (state, { payload }) => {
      const { business = {}, userDetail = {} } = payload;

      state.userData = {
        ...state.userData,
        ...userDetail,
        business: {
          ...state.userData?.business,
          ...business,
        },
      };
    },
  },
});

const { setUserData, updateUserData } = authSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
const useAuthUser = () => useSelector((state: IRootState) => state.auth);
const useAuthUserConstants = () =>
  useSelector((state: IRootState) => {
    const user = state.auth;
    const { userData } = user;

    const business = userData?.business;

    const hasRegisteredBusiness = !!business?._id;

    const hasBusinessPlan = !!business?.businessPlan;

    const isSubscribed = !!business?.isSubscribed;

    const cardDetails = omit(userData?.cardDetails, ["__typename"]);

    const hasCardDetails = !!Object.values(cardDetails ?? {})?.filter(Boolean)
      .length;

    // Check if the user has a billing and does not have a card details
    const hasPaidBusinessPlan =
      hasBusinessPlan &&
      [
        BUSINESS_PLANS.ESSENTIAL,
        BUSINESS_PLANS.PREMIUM,
        BUSINESS_PLANS.ENTERPRISE,
      ].includes(business?.businessPlan);
    // const hasBillingCard = !!cardDetails && !!cardDetails?.first_6digits;

    const hasNotFinishBusinessRegistration = hasPaidBusinessPlan
      ? !hasRegisteredBusiness
      : !hasRegisteredBusiness;

    const hasRegisteredButRequiresBilling =
      hasRegisteredBusiness && hasPaidBusinessPlan && !isSubscribed;

    if (!hasNotFinishBusinessRegistration) {
      removeFromStore("plan");
    }

    return {
      status: {
        hasNotFinishBusinessRegistration,
        hasRegisteredBusiness,
        hasPaidBusinessPlan,
        hasRegisteredButRequiresBilling,
        hasCardDetails,
        isSubscribed,
      },
      cardDetails,
      business: userData?.business,
      user: user?.userData,
      isAuthenticated: user?.isAuthenticated,
    };
  });

const useUserKycStatus = ({
  hasSetPasscode = false,
}: { hasSetPasscode?: boolean } = {}): {
  [key in KYC_FIELDS]?: string;
} =>
  useSelector((state: IRootState) => {
    const user = state.auth;
    const { userData } = user;

    const business = userData?.business || ({} as IUserBusinessDetails);
    const {
      CACNumber = "",
      isCacValid,
      isBvnValid,
      utilityBill,
      isNINValid,
    } = business;

    const hasCac = !!CACNumber;

    const hasBVN = userData?.isKycCompleted;

    const hasUtilityBill = !!utilityBill;

    const hasNIN = isNINValid === "PROCESS" || isNINValid === "SUCCESS";

    return {
      [KYC_FIELDS.REGISTRATION_NUMBER]: hasCac
        ? isCacValid ?? DOCUMENT_VALIDATION.START
        : DOCUMENT_VALIDATION.START,
      [KYC_FIELDS.BVN]: hasBVN
        ? isBvnValid ?? DOCUMENT_VALIDATION.START
        : DOCUMENT_VALIDATION.START,
      [KYC_FIELDS.UTILITY_BILL]: hasUtilityBill
        ? DOCUMENT_VALIDATION.SUCCESS
        : DOCUMENT_VALIDATION.START,
      [KYC_FIELDS.PASSCODE]: hasSetPasscode
        ? DOCUMENT_VALIDATION.SUCCESS
        : DOCUMENT_VALIDATION.START,
      [KYC_FIELDS.NIN]: hasNIN
        ? isNINValid ?? DOCUMENT_VALIDATION.START
        : DOCUMENT_VALIDATION.START,
    };
  });

const authReducer = authSlice.reducer;

export {
  authReducer,
  useAuthUser,
  setUserData,
  useUserKycStatus,
  updateUserData,
  useAuthUserConstants,
};
