const BlueCheck = (props: any) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_8797_151194)">
        <path
          d="M10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10C20 4.5 15.5 0 10 0ZM10 18C5.6 18 2 14.4 2 10C2 5.6 5.6 2 10 2C14.4 2 18 5.6 18 10C18 14.4 14.4 18 10 18Z"
          fill="#0077FF"
        />
        <path
          d="M8.9 11.5004L6.4 9.00039L5 10.4004L8.9 14.4004L15 8.30039L13.6 6.90039L8.9 11.5004Z"
          fill="#0077FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_8797_151194">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BlueCheck;
