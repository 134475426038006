import { lazy } from "react";

export * from "./constants";

export const ReportInventoryValueGraph = lazy(
  () => import("./report-inventory-value-graph")
);
export const ReportInventoryChart = lazy(
  () => import("./report-inventory-chart")
);
