import styled from "styled-components/macro";
import { StyledVCardContainer, stylingUtils } from "styles";

const {
  rem,
  color,
  mediaQuery: { down },
} = stylingUtils;

export const StyledPermissionSelectionContainer = styled(StyledVCardContainer)`
  border: 1px solid ${color("ash01")};
  ${down("laptop")} {
    transform: translateX(-9rem);
  }

  h5 {
    font-size: ${rem(14)};
    margin-bottom: ${rem(10)};
  }

  p {
    font-size: ${rem(12)};
  }
`;

export const StyledPermissionCheckBoxContainer = styled("div")`
  margin-top: ${rem(20)};
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${rem(20)} 0;
  ${down("laptop")} {
    grid-template-columns: 1fr;
  }
`;

export const StyledPermissionCheckBox = styled("div")`
  cursor: pointer !important;
  display: flex;
  align-items: center;
  gap: ${rem(10)};
  font-size: ${rem(12)};
  ${down("laptop")} {
    white-space: nowrap;
  }
  label {
    cursor: pointer !important;
    margin-bottom: ${rem(4)};
  }
`;

export const StyledPermissionGroupButtons = styled("div")`
  display: flex;
  align-items: center;

  justify-content: center;
  gap: ${rem(10)};
  margin-top: ${rem(50)};
`;

export const StyledCheckBox = styled("div")`
  INPUT[type="checkbox"]:focus {
  }

  INPUT[type="checkbox"] {
    background-color: white;
    border-radius: 2px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 15px;
    height: 15px;
    cursor: pointer;
    position: relative;
    border: 1px solid ${color("lightBlue")};
    outline-offset: 1px;
  }

  INPUT[type="checkbox"]:checked::after {
    background-color: ${color("lightBlue")};
    position: absolute;
    left: 2px;
    right: 2px;
    content: "";
    top: 2px;
    bottom: 2px;
  }
`;
export const StyledCheckeRadio = styled("input").attrs({ type: "radio" })`
  &:focus {
  }

  background-color: ${color("ash06")};
  border-radius: 50%;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 15px;
  height: 15px;
  cursor: pointer;
  position: relative;
  border: 1px solid ${color("ashRadio")};
  outline-offset: 1px;

  &:checked {
    background-color: white;
    border: 1px solid ${color("lightBlue")};
  }

  &:checked::after {
    background-color: ${color("lightBlue")};
    position: absolute;
    border-radius: 50%;
    left: 2px;
    right: 2px;
    content: "";
    top: 2px;
    bottom: 2px;
  }
`;
