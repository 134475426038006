const NotificationIcon = (props: any) => {
  return (
    <svg
      onClick={props.onClick}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 17.8476C17.6392 17.8476 20.2481 17.1242 20.5 14.2205C20.5 11.3188 18.6812 11.5054 18.6812 7.94511C18.6812 5.16414 16.0452 2 12 2C7.95477 2 5.31885 5.16414 5.31885 7.94511C5.31885 11.5054 3.5 11.3188 3.5 14.2205C3.75295 17.1352 6.36177 17.8476 12 17.8476Z"
        stroke="#798892"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.3887 20.8574C13.0246 22.3721 10.8966 22.3901 9.51941 20.8574"
        stroke="#798892"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="17" cy="7" r="7" fill="#CC2E2E" />
      <path
        d="M14.848 4.4L13.184 5.264L13.336 5.816L14.744 5.136V10H15.384V4.4H14.848ZM20.8221 8.184H20.0541V6.768H19.4221V8.184H17.2621L19.3021 4.4H18.6061L16.5181 8.28V8.76H19.4221V10H20.0541V8.76H20.8221V8.184Z"
        fill="white"
      />
    </svg>
  );
};
export default NotificationIcon;
