import { gql, useQuery } from "urql";

export enum TYPE {
  BUSINESS_CUSTOMER = "BUSINESS_CUSTOMER",
}

const checkInvoiceOrSalesQuery = gql`
  query ($businessCustomerId: String, $type: InvoiceSaleCheckType!) {
    hasInvoiceOrSales(businessCustomerId: $businessCustomerId, type: $type)
  }
`;

export const useCheckCustomerHasSalesAndInvoices = ({
  businessCustomerId,
  type,
}: {
  businessCustomerId: string;
  type: TYPE;
}) => {
  return useQuery({
    query: checkInvoiceOrSalesQuery,
    variables: { businessCustomerId, type },
  });
};
