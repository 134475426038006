import styled from "styled-components/macro";
import { stylingUtils } from "styles";

const { color, rem } = stylingUtils;

export const StyledVInputDiv = styled("div")`
  display: flex;
  gap: ${rem(8)};

  .country {
    font-size: ${rem(12)};
    padding: ${rem(10)} ${rem(12)};
    height: ${rem(38.5)}!important;
    border-radius: 12px;
    border: 1px solid ${color("inputBorder")};
    background-color: white;
    display: flex;
    gap: ${rem(8)};
    align-items: center;
  }
`;

export const StyledVInputIcons = styled.div`
  .pre__icon {
    top: 0;
    position: absolute;
    left: 13px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .post__icon {
    top: 0;
    position: absolute;
    right: 13px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const StyledRequiredDecorator = styled("span")`
  color: red;
`;

export const StyledVInputWrapper = styled("div")`
  display: flex;
  gap: ${rem(15)};

  .country-selector {
    height: ${rem(38.5)};
    width: ${rem(110)};
  }
`;

export const StyledVFlagWrapper = styled("div")`
  display: flex;
  align-items: center;
  gap: ${rem(5)};

  .flag-container {
    font-size: 18px;
  }
`;

export const StyledVFormInput = styled("div")<{ type: string }>`
  padding-bottom: ${({ type }) => (type === "file" ? rem(3) : rem(10))};
  position: relative;

  .required {
    color: red;
    margin-left: 0.1rem;
  }
`;

export const StyledVFormLabel = styled("label")`
  display: block;
  color: ${color("ashLabel")};
  margin-bottom: ${rem(6)};
  font-size: ${rem(14)};
`;

export const StyledVInput = styled("input")<{
  preIcon: boolean;
  postIcon: boolean;
  invalid?: boolean;
}>`
  position: relative;
  color: ${color("ashText")};
  font-size: ${rem(12)};
  padding: ${rem(10)} ${rem(12)};
  height: ${rem(38.5)}!important;
  border-radius: 12px;
  border: 1px solid ${color("inputBorder")};
  width: 100%;
  background-color: white;
  transition: 0.5s ease;
  padding-left: ${({ preIcon }) => preIcon && rem(40)};
  padding-right: ${({ postIcon }) => postIcon && rem(40)};

  &.telephone-input {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &: [type= "number" ] {
      -moz-appearance: textfield;
    }
  }

  &:hover {
    border: 1px solid ${color("inputBorderHover")};
  }

  &:focus {
    outline: ${({ invalid }) =>
      invalid
        ? `3px solid ${color("toastRed")}`
        : `3px solid ${color("lightBlue")}`};
  }

  &:disabled {
    background-color: ${color("ash01")};
    cursor: not-allowed;
    opacity: 0.5;
  }

  &[type="file"] {
    opacity: 0; /* make transparent */
    z-index: -1; /* move under anything else */
    position: absolute; /* don't let it take up space */
  }
  &[type="file"]:focus + label {
    outline: 2px solid; /* example focus style */
  }
`;

export const StyledVFormError = styled.p`
  margin-top: ${rem(10)};
  color: red;
`;
