import {
  PosDetailsModal,
  StyledModalContainer,
  PosDetails,
  Address,
  Button,
} from "./styles";
import { VModalProps } from "components/common/types";
import PosOrderIcon from "assets/images/svgs/PosOrderIcon";
import { VButton } from "components/common";
import DetailsModal from "components/invoices/view-invoice-modal/DetailsModal";
import { getFullFormattedDate } from "utils";
import { useAuthUser } from "store";
import { Link } from "react-router-dom";
import { APP_ROUTES } from "routes";

const today = new Date().toISOString();
const requestDate = getFullFormattedDate(today).slice(0, -9);

const PosOrderDetails = ({
  open,
  close,
  purchaseData,
}: VModalProps & { purchaseData: any }) => {
  const { userData } = useAuthUser();

  const { posName, quantity, amount, deliveryAddress, orderId } = purchaseData;

  const tableDetails = [
    {
      item: posName,
      quantity: quantity,
      amount: amount,
    },
  ];

  const { address, city, state } = deliveryAddress || {};

  return (
    <PosDetailsModal
      open={open}
      close={close}
      title={`POS Order Details`}
      width={700}
      alignModalTitle="center"
      titleIcon={<PosOrderIcon />}
      className="modal-title"
    >
      <StyledModalContainer>
        <PosDetails>
          <div className="item">
            <h4>Request Date:</h4>
            <p>{requestDate}</p>
          </div>
          <div className="item">
            <h4>Name:</h4>
            <p>
              {userData?.firstName} {userData?.lastName}{" "}
            </p>
          </div>
          <div className="item">
            <h4>Business Name:</h4>
            <p>{userData?.business?.businessName}</p>
          </div>
          <div className="item">
            <h4>Order ID:</h4>
            <p>{orderId}</p>
          </div>
          <div className="item">
            <h4>Status:</h4>
            <p>Processing</p>
          </div>
        </PosDetails>
      </StyledModalContainer>

      <DetailsModal
        subTotal={amount}
        total={amount}
        invoiceStatusBg={"POS"}
        tableDetails={tableDetails}
      />

      <Address>
        <div>
          <p>Delivery Address</p>
          <h5>
            {address}, {city}, {state}
          </h5>
        </div>
      </Address>
      <Button>
        <Link to={APP_ROUTES.POS_REQUESTED}>
          <VButton text="View Request Details" width="350px" height="50px" />
        </Link>
      </Button>
    </PosDetailsModal>
  );
};

export default PosOrderDetails;
