import styled from "styled-components/macro";
import { stylingUtils } from "styles";

const { color, rem } = stylingUtils;

export const SelectedPosStyles = styled("div")`
  display: grid;
  grid-template-columns: 1.9fr 1.1fr;
  grid-gap: ${rem(20)};
  font-family: "Gilmer";

  hr {
    border: 0.5px solid #e2e4f0;
  }
`;
export const LeftContainer = styled("div")`
  justify-content: space-between;
  background-color: ${color("white")};
  padding: 2rem;
  border-radius: ${rem(20)};

  .item-flex {
    margin-bottom: ${rem(25)};
    display: flex;
    justify-content: space-between;
    margin-top: ${rem(30)};
  }

  .quantity {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${rem(25)};
  }

  .counter {
    display: flex;
    align-items: center;

    .number {
      width: 32px;
      height: 32px;
      display: grid;
      place-content: center;
    }
  }

  .icon-div {
    background: #dfecfd;
    border-radius: ${rem(10)};
    width: 32px;
    height: 32px;
    display: grid;
    place-content: center;
    cursor: pointer;
  }

  .price {
    font-style: normal;
    font-weight: 700;
    font-size: ${rem(16)};
    line-height: ${rem(19)};
    color: ${color("green")};
  }

  .item {
    display: flex;
    gap: ${rem(20)};

    & > img {
      width: 72px;
    }
  }

  h3 {
    font-style: normal;
    font-weight: 500;
    font-size: ${rem(20)};
    line-height: ${rem(24)};
    color: ${color("black02")};
  }
  h4 {
    font-style: normal;
    font-weight: 500;
    font-size: ${rem(18)};
    line-height: ${rem(22)};
    color: ${color("black02")};
    margin-bottom: ${rem(10)};
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: ${rem(14)};
    line-height: ${rem(17)};
    color: ${color("ash12")};
    margin-bottom: ${rem(10)};
  }
  .remove {
    color: ${color("red")};
    cursor: pointer;
  }
`;
export const RightContainer = styled("div")`
  display: grid;
  grid-gap: ${rem(30)};

  .card {
    justify-content: space-between;
    background-color: ${color("white")};
    padding: 2rem;
    border-radius: ${rem(20)};
  }

  h5 {
    font-style: normal;
    font-weight: 600;
    font-size: ${rem(16)};
    line-height: ${rem(19)};
    text-align: center;
    color: #000000;
    margin-bottom: ${rem(20)};
  }
  h4 {
    font-style: normal;
    font-weight: 700;
    font-size: ${rem(16)};
    line-height: ${rem(19)};
    color: ${color("black02")};
  }
  .items {
    display: flex;
    flex-direction: column;
    gap: ${rem(20)};
    padding: ${rem(20)} 0;
  }
  .item {
    display: flex;
    justify-content: space-between;

    p {
      font-style: normal;
      font-weight: 400;
      font-size: ${rem(16)};
      line-height: ${rem(19)};
      color: ${color("black02")};
    }

    p:nth-child(1) {
      color: ${color("ash12")};
    }
  }
  .total {
    padding: ${rem(25)} 0 ${rem(50)} 0;
    display: flex;
    justify-content: space-between;

    div {
      display: flex;
      gap: ${rem(7)};
      align-items: center;
    }

    span {
      font-style: normal;
      font-weight: 400;
      font-size: ${rem(12)};
      line-height: ${rem(14)};
      color: ${color("ash12")};
    }

    p {
      font-style: normal;
      font-weight: 600;
      font-size: ${rem(16)};
      line-height: ${rem(19)};
      color: ${color("black02")};
    }
  }
`;
