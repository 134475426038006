import { gql, useQuery } from "urql";

type useGetCustomerTerminalProps = {
  terminalId?: string;
};

const getCustomerTerminalQuery = gql`
  query ($terminalId: String) {
    getCustomerTerminal(terminalId: $terminalId) {
      _id
      terminalId
      terminalName
      tid
      updatedAt
      previousStatus {
        status
        description
        createdAt
      }
      status
      spec {
        name
        imageUrl
        price
        benefits
        features
      }
    }
  }
`;

export const useGetCustomerTerminal = ({
  terminalId,
}: useGetCustomerTerminalProps) => {
  return useQuery({
    query: getCustomerTerminalQuery,
    variables: {
      terminalId,
    },
  });
};
