import { omit } from "lodash";
import { useStaffPageHook, useToggle } from "hooks";
import { uploadMedia } from "utils";
import { gql, useMutation } from "urql";
import { handleRequestCall } from "utils/handleRequestCall";
import { useAuthUser } from "store";
import { showToast } from "components/common";

const addBusinessStaffMutation = gql`
  mutation (
    $businessId: String!
    $firstName: String
    $lastName: String
    $phoneNumber: String
    $email: String
    $profilePicture: String
    $roleId: String
    $assignedOutlet: String
  ) {
    createBusinessStaff(
      businessId: $businessId
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      email: $email
      profilePicture: $profilePicture
      roleId: $roleId
      assignedOutlet: $assignedOutlet
    ) {
      _id
      customerId
    }
  }
`;
const updatedBusinessMutation = gql`
  mutation (
    $businessStaffId: String!
    $firstName: String
    $lastName: String
    $phoneNumber: String
    $email: String
    $profilePicture: String
    $roleId: String
    $assignedOutlet: String
  ) {
    updateBusinessStaff(
      businessStaffId: $businessStaffId
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      email: $email
      profilePicture: $profilePicture
      roleId: $roleId
      assignedOutlet: $assignedOutlet
    ) {
      _id
      customerId
    }
  }
`;

export interface IAddOrEditStaff {
  businessStaffId?: string;
  profilePicture?: File | string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  roleId: string;
  assignedOutlet: string;
}

export const useAddBusinessStaff = (isEdit = false) => {
  const [businessStaff, addBusinessStaff] = useMutation(
    isEdit ? updatedBusinessMutation : addBusinessStaffMutation
  );

  const [imageProcessing, toggleImageProcessing] = useToggle();
  const { userData } = useAuthUser();

  const {
    staffTable: { refetchBusinessStaffs },
  } = useStaffPageHook();

  const handleAddBusinessStaff = async (
    data: IAddOrEditStaff,
    callBack?: () => void
  ) => {
    toggleImageProcessing();
    const dataWithoutImage = omit(data, ["profilePicture"]);
    const rawServiceImage = data?.profilePicture;
    let processedFileImage;

    try {
      if (rawServiceImage) {
        const uploadedFile = await uploadMedia(rawServiceImage);
        if (!uploadedFile) {
          toggleImageProcessing();
          showToast({
            type: "error",
            title: "Error uploading file",
            subText: "Please try again",
          });
          return;
        }
        processedFileImage = uploadedFile[0]?.url;
      }
      toggleImageProcessing();

      const values = {
        ...dataWithoutImage,
        phoneNumber: `0${data?.phoneNumber}`,
        ...(processedFileImage && { profilePicture: processedFileImage }),
        businessId: userData?.businessId as string,
      };
      const req = await addBusinessStaff(values);

      await handleRequestCall({
        request: req,
        callBack: () => {
          refetchBusinessStaffs();
          callBack?.();
        },
      });
    } catch (e) {
      showToast({
        type: "error",
        title: "An error occured",
        subText: "Please try again",
      });
      console.error(e);
    }
  };

  return {
    handleAddBusinessStaff,
    businessStaff,
    isLoading: imageProcessing || businessStaff?.fetching,
  };
};
