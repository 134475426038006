import { VIEW_EXPENSE_DETAILS_ITEMS } from "components/Inventory";
import { ModalComponentProps } from "components/common/types";
import { StyledExpenseDetailsContainer, StyledExpenseItem } from "./styles";
import { IBusinessExpenseNode } from "api";
import { useExpenseDetailsHook } from "hooks";
import { VModal } from "components/common";
import { getFullFormattedDate } from "utils";

const ViewExpenseDetails = ({
  title = "Expense Details",
  open,
  close,
  expense,
}: ModalComponentProps & { expense: IBusinessExpenseNode }) => {
  const { newExpense, createdBy } = useExpenseDetailsHook({
    expense,
  });

  return (
    <VModal open={open} close={close} title={title} width={576}>
      {newExpense && (
        <StyledExpenseDetailsContainer>
          {VIEW_EXPENSE_DETAILS_ITEMS.map(({ header, key, formatter }) => {
            let value =
              (key === "category"
                ? (newExpense as Record<string, any>)?.[key]?.name
                : (newExpense as Record<string, any>)?.[key]) ?? "N/A";

            if (key === "createdAt") {
              value = getFullFormattedDate(new Date(Date.now()).toString());
            }

            if (formatter) {
              value = formatter(value as any);
            }
            if (key === "createdBy") {
              value = createdBy;
            }

            return (
              <StyledExpenseItem key={key}>
                <h3 className="expense__item--header">{header}</h3>
                <p>{value}</p>
              </StyledExpenseItem>
            );
          })}
        </StyledExpenseDetailsContainer>
      )}
    </VModal>
  );
};

export default ViewExpenseDetails;
