import { IPagination } from "./../types";
import { gql, useQuery } from "urql";
import { PaginationFragment } from "api/general-queries";
import { useVAppSelector } from "hooks/useStore";
import { SEARCH_TYPE } from "store";

type useGetBusinessCustomersProps = {
  businessId: string;
  page?: number;
  size?: number;
  status?: string;
  search?: string;
  sortOrder?: string;
  debtorsOnly?: boolean;
};

export const useGetBusinessCustomers = ({
  businessId,
  page = 1,
  size = 10,
  status,
  search = "",
  sortOrder = "desc",
  debtorsOnly,
}: useGetBusinessCustomersProps) => {
  const { page: customerPage, term: searchTerm } = useVAppSelector(
    (state) => state.search
  );
  return useQuery({
    query: getBusinessCustomersQuery,
    variables: {
      businessId,
      page,
      size,
      status,
      searchQuery: customerPage === SEARCH_TYPE.CUSTOMERS ? searchTerm : search,
      sortOrder,
      ...(typeof debtorsOnly !== "undefined" &&
        debtorsOnly !== null && {
          debtorsOnly,
        }),
    },
  });
};

const getBusinessCustomersQuery = gql`
  query ($businessId: String!, $page: Int, $size: Int, $status: CustomerActivityStatus, $searchQuery: String, $sortOrder: String, $debtorsOnly: Boolean ) {
    getBusinessCustomers(
    filters: {
      businessId: $businessId,
      status: $status,
      searchQuery: $searchQuery,
      debtorsOnly: $debtorsOnly
    }
     metadata: {
        size: $size,
        page: $page,
        sortOrder: $sortOrder
    }
   ){
     nodes{
        _id
        customerName
        amountSpent
        customerStatus
        email
        phoneNumber
        status
        referenceId
        totalDebt
    }, 
   ${PaginationFragment}
  }
  }
`;

export interface IBusinessCustomers extends IPagination {
  nodes: {
    _id: string;
    referenceId: string;
    customerName: string;
    status: CustomerActivityStatus;
    email: string;
    phoneNumber: string;
    totalDebt: number;
  }[];
}

enum CustomerActivityStatus {
  ACTIVE,
  INACTIVE,
}
