import styled from "styled-components/macro";
import { stylingUtils } from "styles";
import zigzag from "assets/images/pngs/zigzagPattern.png";
import fullZigzag from "assets/images/pngs/FullZigzagPattern.png";
import slantingPos from "assets/images/pngs/slanting-pos.png";

const { color, rem } = stylingUtils;

export const PosCardStyles = styled("div")<{ active: boolean }>`
  width: ${rem(359)};
  height: ${rem(200)};
  border-radius: ${rem(14)};
  background-color: ${color("white")};
  background-image: url(${zigzag});
  background-repeat: no-repeat;
  background-size: 100%;
  color: #000;
  cursor: pointer;
  transition: 0.3s;

  border: ${({ active }) => (active ? `2px solid #0077FF` : "none")};

  &:hover {
    border: 2px solid ${color("lightBlue")};
  }
  /* &:active {
    border: 2px solid ${color("lightBlue")};
  }

  .inactive {
    border: none;
  } */
`;
export const Content = styled("div")`
  padding: ${rem(23)};

  display: grid;
  grid-gap: ${rem(12)};

  .right {
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    text-align: right;

    h5 {
      color: #798892;
    }
  }
  h5 {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #374b58;
  }
`;
export const Top = styled("div")`
  display: flex;
  justify-content: space-between;

  .left,
  .right {
    display: flex;
    flex-direction: column;
    gap: ${rem(10)};
  }

  h2 {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #374b58;
  }
`;
export const Bottom = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  .left {
    display: flex;
    align-items: flex-end;
    gap: ${rem(10)};
  }

  .right {
    display: flex;
    flex-direction: column;
    gap: ${rem(10)};
  }
`;
export const EmptyPosStyles = styled("div")`
  overflow: auto;
  white-space: nowrap;
  margin-bottom: 2rem;
`;
export const EmptyCardStyles = styled("div")`
  width: ${rem(359)};
  height: ${rem(200)};
  border-radius: ${rem(14)};
  background-color: ${color("white")};
  background-image: url(${fullZigzag});
  background-repeat: no-repeat;
  color: #000;
  margin-right: 0.7rem;
  display: inline-block;
  vertical-align: middle;

  & > div {
    display: grid;
    place-content: center;
    height: 100%;
  }

  p {
    font-family: "Gilmer";
    font-weight: 600;
    font-size: ${rem(12)};
    line-height: ${rem(14)};
    color: ${color("ashText")};
  }
`;
export const OrderPosCard = styled("div")`
  width: ${rem(359)};
  height: ${rem(200)};
  border-radius: ${rem(14)};
  background-color: ${color("darkBlue")};
  background-image: url(${slantingPos});
  background-repeat: no-repeat;
  background-position: bottom right;
  padding: 1.5rem 2rem;
  display: inline-block;
  vertical-align: middle;

  & > div {
    display: grid;
    grid-gap: 1rem;
  }

  h4 {
    font-weight: 400;
    font-size: ${rem(12)};
    line-height: ${rem(14)};
    color: ${color("ashInput")};
  }
  h2 {
    font-weight: 700;
    font-size: ${rem(14)};
    line-height: 20px;
    color: #f8fcff;
  }
  p {
    font-weight: 400;
    font-size: ${rem(10)};
    line-height: ${rem(12)};
    color: #c1cacf;
  }
  .relative {
    position: relative;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #001928;
    width: 170px;
  }
  .icon {
    position: absolute;
    right: 8px;
    align-self: center;
    fill: #001928;
    width: ${rem(9)}!important;
    height: ${rem(18)}!important;
  }
`;
