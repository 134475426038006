import {
  StyledCheckoutCard,
  StyledCheckoutCardAction,
  StyledCheckoutCardHeader,
  StyledCheckoutCardHeaderWrapper,
} from "./styles";

export type BillingCheckoutCardProps = {
  children: JSX.Element | JSX.Element[];
  title?: string | JSX.Element | JSX.Element[];
  largeTitle?: boolean;
  action?: JSX.Element;
  titleFontSize?: string;
} & Omit<React.HTMLAttributes<HTMLDivElement>, "title">;
export const BillingCheckoutCard = ({
  children,
  title,
  largeTitle = false,
  action,
  titleFontSize = "",
  ...rest
}: BillingCheckoutCardProps) => {
  return (
    <StyledCheckoutCard {...rest}>
      <StyledCheckoutCardHeaderWrapper>
        {title && (
          <StyledCheckoutCardHeader
            largeTitle={largeTitle}
            titleFontSize={titleFontSize}
          >
            {title}
          </StyledCheckoutCardHeader>
        )}
        {action && (
          <StyledCheckoutCardAction>{action}</StyledCheckoutCardAction>
        )}
      </StyledCheckoutCardHeaderWrapper>
      <>{children}</>
    </StyledCheckoutCard>
  );
};
