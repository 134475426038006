import styled from "styled-components";
import { FlexColumn, stylingUtils } from "styles";

const {
  rem,
  color,
  mediaQuery: { down },
} = stylingUtils;

export const StyledStaffActivitiesContainer = styled("section")`
  padding: ${rem(20)};
  background-color: white;
  border: 1px solid ${color("ash01")};
  border-radius: ${rem(15)};
  ${FlexColumn};
  gap: ${rem(25)};
`;

export const StyledStaffActivitiesRow = styled("div")<{ isBold: boolean }>`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  padding: 0 ${rem(40)};
  font-weight: ${({ isBold }) => isBold && "600"};
`;

export const StyledStaffActivitiesHeader = styled("header")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${rem(20)};
  ${down("laptop")} {
    gap: ${rem(20)};
  }

  h3 {
    font-weight: 400;
    color: #515f68;
    ${down("laptop")} {
      font-size: ${rem(14)};
      white-space: nowrap;
    }
  }

  .location-details {
    display: flex;
    align-items: center;
    gap: ${rem(8)};
    padding-right: ${rem(40)};
    ${down("laptop")} {
      padding-right: 0;
    }

    p {
      ${down("laptop")} {
        display: flex;
        flex-direction: column;
      }
    }
    span {
      font-weight: 500;
    }
  }
`;
