import { ReactNode } from "react";
import {
  StyledChooseBusinessHeader,
  StyledChooseBusinessWrapper,
  StyledSignOuterWrapper,
  StyledSignupContent,
  StyledSignupWrapper,
} from "./styles";

const SignupWrapper = ({
  header,
  subtitle,
  children,
  footer,
}: {
  header: string;
  subtitle: string;
  children: ReactNode;
  footer?: ReactNode;
}) => {
  return (
    <StyledSignOuterWrapper>
      <StyledSignupWrapper>
        <StyledSignupContent maxWidth={700}>
          <StyledChooseBusinessWrapper>
            <StyledChooseBusinessHeader>
              <h3>{header}</h3>
              <p>{subtitle}</p>
            </StyledChooseBusinessHeader>
            {children}
          </StyledChooseBusinessWrapper>
        </StyledSignupContent>
      </StyledSignupWrapper>
      {footer}
    </StyledSignOuterWrapper>
  );
};

export default SignupWrapper;
