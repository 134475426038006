import { FlexColumn } from "./../../../../../styles/global";
import styled from "styled-components/macro";
import { StyledVCardContainer, stylingUtils } from "styles";

const { rem, color } = stylingUtils;

export const StyledPricingPlanCard = styled(StyledVCardContainer)`
  background-color: #edf4ff;
  max-width: ${rem(280)};
  cursor: pointer;
  transition: 0.5s all ease;
  flex: none;

  &:hover {
    transform: scale(1.1);
  }
`;

export const StyledPricingPlanCardHeader = styled("header")`
  display: flex;
  justify-content: space-between;
  min-height: ${rem(70)};
`;

export const StyledPlanAmount = styled(`div`)`
  padding-top: ${rem(10)};
  h4 {
    margin-bottom: 2px;
    text-align: left;
  }

  .custom__billing {
    font-size: ${rem(18)};
  }

  .amount__text {
    .currency {
      font-size: ${rem(16)};
      font-weight: 600;
    }
    .amount {
      font-size: ${rem(30)};
      font-weight: 600;
      margin: 0 ${rem(3)};
    }
    .billing__cycle {
      font-weight: 200;
    }
  }
`;

export const StyledPricingPlanSummary = styled("p")`
  color: #798892;
  font-size: ${rem(12)};
  margin: ${rem(20)} 0 0;
  min-height: ${rem(80)};
`;

export const StyledPricingPlanDivider = styled("div")`
  width: 100%;
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: ${rem(20)};
`;

export const StyledPricingPlanBasicFeaturesContainer = styled("ul")`
  ${FlexColumn};
  gap: ${rem(10)};
  margin-bottom: ${rem(20)};
`;

export const StyledPricingPlanBasicFeatures = styled("li")`
  display: flex;
  gap: ${rem(10)};
  align-items: center;
`;

export const StyledPricingPlanAccordion = styled("div")`
  .ppHeader {
    background-color: #e0e9fa;
    padding-top: ${rem(5)};
    padding-bottom: ${rem(5)};
    border-radius: ${rem(10)};

    h4 {
      font-weight: 400 !important;
    }

    button {
      background-color: transparent;

      svg {
        stroke: ${color("lightBlue")};
      }
    }
  }
`;
