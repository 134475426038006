import { VLoader } from "components/common";
import { useOauthCallBackHook } from "hooks";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { APP_ROUTES } from "routes";
import { IRootState } from "store";

const OauthCallBackPage = () => {
  const { isLoading, lazyUseGetToken } = useOauthCallBackHook();
  const { isAuthenticated } = useSelector((state: IRootState) => state.auth);
  const location = useLocation();

  useEffect(() => {
    lazyUseGetToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isAuthenticated) {
    return (
      <Navigate
        to={(location as any)?.state?.from?.pathname || APP_ROUTES.BUSINESS}
        state={{ from: location }}
      />
    );
  }

  return isLoading ? (
    <VLoader text="Preparing Your Business" />
  ) : (
    <VLoader text="Preparing Your Business" />
  );
};

export default OauthCallBackPage;
