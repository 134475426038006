import { useAnalytics } from "hooks/useAnalytics";
import { gql, useQuery } from "urql";

type useGetInvoicePdfProps = {
  businessId: string;
  invoiceId?: string;
  pause?: boolean;
};

const getInvoicePdfQuery = gql`
  query ($businessId: String!, $invoiceId: String!) {
    getInvoicePDF(businessId: $businessId, invoiceId: $invoiceId)
  }
`;

export const useGetInvoicePdf = ({
  businessId,
  invoiceId,
  pause = false,
}: useGetInvoicePdfProps) => {
  const [logAppEvent, { view_invoice_pdf }] = useAnalytics();
  logAppEvent(view_invoice_pdf, {}).catch(console.log);

  return useQuery({
    query: getInvoicePdfQuery,
    variables: { businessId, invoiceId },
    pause,
  });
};
