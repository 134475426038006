import { useEffect, useState } from "react";
import { VButton, VForm, VModal } from "components/common";
import {
  StyledModalContainer,
  StyledModalForm,
  PaymentChannel,
} from "./styles";
import { VModalProps } from "components/common/types";
import { recordPayment, paymentCards } from "../constants";
import { useForm } from "react-hook-form";
import { usePayForInvoiceHook, useInvoiceDetailsHook } from "hooks";
import { Payment } from "api";
import { ReactComponent as CheckIcon } from "assets/images/svgs/blue-check.svg";

const RecordPaymentModal = ({
  open,
  close,
  invoiceId,
  callback,
}: VModalProps & { invoiceId: string; callback?: () => void }) => {
  const { invoiceDetails } = useInvoiceDetailsHook(invoiceId);

  const hookForm = useForm();

  const [selectedCard, setSelectedCard] = useState("");
  const { setValue, watch, getValues, reset } = hookForm;

  const paymentAmount = watch("paymentAmount");

  const totalAmount = getValues("amount");

  const amountRemaining = getValues("amountRemaining");

  const paymentStatus =
    Number(totalAmount) === amountRemaining ? "FULL" : "PART";

  const payments: Payment = {
    paymentType: selectedCard,
    paymentAmount: Number(paymentAmount),
    paymentStatus: paymentStatus,
  };

  const closeForm = async (refetch = false) => {
    close();
    setSelectedCard("");
    reset({
      paymentAmount: "",
    });
    if (refetch) {
      await callback?.();
    }
  };

  const invoiceHookCallback = () => closeForm(true);

  const { isLoading, handleSubmit, onSubmit } = usePayForInvoiceHook(
    invoiceId,
    payments,
    invoiceHookCallback,
    setSelectedCard,
    hookForm,
    reset,
    selectedCard,
    paymentAmount
  );

  useEffect(() => {
    setValue("amount", 0 || invoiceDetails?.amountDue);
  });

  useEffect(() => {
    const remaining = Number(totalAmount || 0) - Number(paymentAmount);

    if (remaining >= 0) {
      setValue("amountRemaining", remaining);
    } else if (paymentAmount > remaining) {
      setValue("amountRemaining", 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentAmount]);

  return (
    <VModal
      open={open}
      close={closeForm}
      title="Record balance"
      width={500}
      alignModalTitle="center"
    >
      <StyledModalContainer>
        <StyledModalForm>
          <form onSubmit={handleSubmit(onSubmit)}>
            <VForm
              inputLists={recordPayment}
              hookForm={hookForm}
              vFormClassName="modal__form"
            />
            <PaymentChannel>
              <h4>Choose payment channel</h4>
              <div className="cards">
                {paymentCards.map((card, index) => (
                  <div
                    className="card"
                    key={index}
                    onClick={() => setSelectedCard(card.value)}
                    style={{
                      backgroundColor:
                        selectedCard === card.value ? "#E5F0FD" : "#e4e3e6",
                    }}
                  >
                    <div className="card-items">
                      <div className="icon-div">
                        {selectedCard === card.value ? (
                          <CheckIcon />
                        ) : (
                          card.icon
                        )}
                      </div>
                      <p>{card.name}</p>
                    </div>
                  </div>
                ))}
              </div>
            </PaymentChannel>
            <div className="form--button">
              <VButton
                text="Record"
                className="button"
                type="submit"
                isLoading={isLoading}
              />
            </div>
          </form>
        </StyledModalForm>
      </StyledModalContainer>
    </VModal>
  );
};

export default RecordPaymentModal;
