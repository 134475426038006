import { useNavigate } from "react-router-dom";
import { useLocalSearchParams } from "hooks";
import { useMemo, useState } from "react";

import { useAuthUser } from "store";

import { extractFetchedData } from "utils";
import {
  IProductNode,
  IProducts,
  useGetAlerzoProducts,
} from "api/import-products";
import { PRODUCT_TABLE_COLUMNS } from "components/import-products/constants";
import { showToast } from "components/common";
import { useImportAlerzoProducts } from "api/import-products/import-alerzo-products";
import { APP_ROUTES } from "routes";
import {
  ICustomerCategories,
  INVENTORY_TYPES,
  useGetCustomerCategories,
} from "api";

export const useImportProductsHook = () => {
  // Memoize the expense column
  const { searchParams } = useLocalSearchParams();
  const navigate = useNavigate();

  const [pageNumber, setPageNumber] = useState(
    parseInt(searchParams.get("page") || "1")
  );

  const { userData } = useAuthUser();

  const [result, refetchAlerzoProducts] = useGetAlerzoProducts({
    orderId: userData?.businessId as string,
    page: pageNumber,
    status: "DRAFT",
  });

  const extract = extractFetchedData<IProducts>({
    result,
    key: "getAlerzoProducts",
  });

  // Handle pagination change
  const handleChangePage = (pageNumber: number) => {
    setPageNumber(pageNumber);
  };

  // State for updating products
  const [productsDataState, setProductsDataState] = useState<any>({});

  const changeSellingPrice = ({ id, value }: { id: string; value: string }) => {
    setProductsDataState({
      ...productsDataState,
      [id]: {
        ...productsDataState[id],
        sellingPrice: Number(value),
      },
    });
  };
  const changeCategoryId = ({ id, value }: { id: string; value: string }) => {
    setProductsDataState({
      ...productsDataState,
      [id]: {
        ...productsDataState[id],
        categoryId: value,
      },
    });
  };

  const updateCheckedValue = ({
    id,
    value,
  }: {
    id: string;
    value: boolean;
  }) => {
    setProductsDataState({
      ...productsDataState,
      [id]: {
        ...productsDataState[id],
        checked: value,
      },
    });
  };

  const [allSelected, setAllSelected] = useState<boolean>(false);

  const toggleAllSelect = () => {
    setProductsDataState(
      Object.values(productsDataState as []).reduce(
        (initial, product: IProductNode) => ({
          ...initial,
          [product._id]: { ...product, checked: allSelected ? false : true },
        }),
        {}
      )
    );
    setAllSelected(!allSelected);
  };

  const checkIfSelected = () => {
    const selected = Object.values(productsDataState as [])?.filter(
      (product: IProductNode & { checked: boolean }) => product.checked === true
    );
    return selected?.length > 0;
  };

  // Form validation

  const checkForSellingPriceInput = () => {
    const notEntered = Object.values(productsDataState as [])?.filter(
      (product: IProductNode & { checked: boolean }) =>
        product.checked && !product.sellingPrice
    );
    if (notEntered && notEntered.length > 0) {
      return showToast({
        title: "Incomplete Fields",
        subText: "Please enter selling price for all selected products",
        type: "error",
      });
    }
    return false;
  };

  const checkForCategoryInput = () => {
    const notEntered = Object.values(productsDataState as [])?.filter(
      (product: IProductNode & { checked: boolean }) =>
        product.checked && !product.categoryId
    );
    if (notEntered && notEntered.length > 0) {
      return showToast({
        title: "Incomplete Fields",
        subText: "Please select a category for all selected products",
        type: "error",
      });
    }
    return false;
  };

  const checkForProfit = () => {
    const lossFound = Object.values(productsDataState as [])?.filter(
      (product: IProductNode & { checked: boolean }) =>
        product.checked &&
        Number(product.sellingPrice || 0) < Number(product.purchasePrice || 0)
    );

    if (lossFound && lossFound.length > 0) {
      return showToast({
        title: "Invalid Selling Price",
        subText:
          "Selling price must be greater than Cost price for all selected products",
        type: "error",
      });
    }
    return false;
  };

  const [productCategoriesResult] = useGetCustomerCategories({
    businessId: userData?.businessId as string,
    inventoryType: INVENTORY_TYPES.PRODUCT,
    size: -1,
  });

  const productCategories = useMemo(() => {
    if (productCategoriesResult.data) {
      const { extractedData } = extractFetchedData<ICustomerCategories>({
        key: "getCategories",
        result: productCategoriesResult,
      });
      const options = (extractedData?.nodes || []).map((data) => ({
        value: data?._id,
        label: data?.name,
        key: data?._id,
      }));

      return options;
    } else return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productCategoriesResult.fetching]);

  const productColumns = PRODUCT_TABLE_COLUMNS({
    changeSellingPrice,
    updateCheckedValue,
    toggleAllSelect,
    allSelected,
    productCategories,
    changeCategoryId,
  });

  // Get selected products
  const selectedProducts: {
    productId: string;
    sellingPrice: number;
    categoryId: string;
  }[] = Object.values(productsDataState as [])
    ?.filter((item: IProductNode & { checked: boolean }) => !!item.checked)
    ?.map((item: IProductNode) => ({
      productId: item._id,
      sellingPrice: item.sellingPrice,
      categoryId: item.categoryId,
    }));

  const { isLoading, handleImportProducts } = useImportAlerzoProducts();

  const importProducts = async () => {
    await handleImportProducts(selectedProducts, () => {
      showToast({
        type: "success",
        title: "Successful!",
        subText:
          "Your products from Alerzoshop has been imported to your inventory",
      });
      navigate(APP_ROUTES.INVENTORY);
    });
  };

  return {
    productTable: {
      ...extract,
      handleChangePage,
      pageNumber,
      productColumns,
      productsDataState,
      setProductsDataState,
      refetchAlerzoProducts,
      checkIfSelected,
      checkForSellingPriceInput,
      checkForProfit,
      selectedProducts,
      importProducts,
      isLoading,
      checkForCategoryInput,
      productCategories,
    },
  };
};
