import { IPagination } from "../../types";
import { gql, useQuery } from "urql";
import { PaginationFragment } from "api/general-queries";
import { useVAppSelector } from "hooks/useStore";
import { SEARCH_TYPE, useAuthUserConstants } from "store";
import { extractFetchedData } from "utils";

type SubscriptionLogsProps = {
  page?: number;
  size?: number;
  status?: string;
  search?: string;
  sortOrder?: string;
};

export const useGetSubscriptionLogsHook = ({
  page = 1,
  size = 10,
  search = "",
  sortOrder = "desc",
}: SubscriptionLogsProps = {}) => {
  const { page: customerPage, term: searchTerm } = useVAppSelector(
    (state) => state.search
  );

  const { business } = useAuthUserConstants();

  const [result, refetchAll] = useQuery({
    query: getSubscriptionLogsQuery,
    variables: {
      businessId: business?._id as string,
      page,
      size,
      searchQuery: customerPage === SEARCH_TYPE.BILLING ? searchTerm : search,
      sortOrder,
    },
  });

  const extractedData = extractFetchedData<ISubscriptionLogs>({
    result,
    key: "getSubscriptionLogs",
    defaultReturn: [],
  });

  return {
    ...extractedData,
    refetchAll,
  };
};

const getSubscriptionLogsQuery = gql`
  query ($businessId: String, $page: Int, $size: Int, $searchQuery: String, $sortOrder: String, ) {
    getSubscriptionLogs(
    filters: {
      businessId: $businessId,
      searchQuery: $searchQuery,
    }
     metadata: {
        size: $size,
        page: $page,
        sortOrder: $sortOrder
    }
   ){
     nodes{
        _id
        userId
        businessId
        businessPlan
        subscriptionPaymentId
        businessPlanDetail{
          name
        }
        subscriptionPaymentDetail {
          amountPaid
          costOfSubscription
        }
        remark
        expiresOn
        createdAt
    }, 
   ${PaginationFragment}
  }
  }
`;

export interface ISubscriptionLogs extends IPagination {
  nodes: {
    _id: string;
    userId: string;
    businessId: string;
    subscriptionPaymentId: string;
    businessPlan: string;
    businessPlanDetail: Record<string, any>;
    subscriptionPaymentDetail: Record<string, any>;
    remark: string;
    expiresOn: string;
    createdAt: string;
  }[];
}
