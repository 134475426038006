import React from "react";
import ReactDOM from "react-dom/client";
import App from "./app/App";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "driver.js/dist/driver.min.css";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
// import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "styled-components";
import theme from "styles/theme";
import GlobalStyle from "styles/global";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import { Provider as UrqlProvider } from "urql";

import { store } from "store";
import { urqlClient } from "api";
import { PasscodeContextProvider } from "context";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <>
    <ToastContainer
      icon
      autoClose={5000}
      hideProgressBar={true}
      newestOnTop={true}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      limit={2}
      pauseOnHover
    />
    <UrqlProvider value={urqlClient}>
      <Provider store={store}>
        <BrowserRouter>
          <PasscodeContextProvider>
            <ThemeProvider theme={theme}>
              <GlobalStyle />
              <App />
            </ThemeProvider>
          </PasscodeContextProvider>
        </BrowserRouter>
      </Provider>
    </UrqlProvider>
  </>
);

if (["PRODUCTION"].includes(process.env.REACT_APP_ENVIRONMENT as string)) {
  serviceWorkerRegistration.register();
} else {
  serviceWorkerRegistration.unregister();
}
