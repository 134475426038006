import { useLocalSearchParams, useModal } from "hooks";
import { INVOICE_COLUMNS, INVOICE_CARDS_DATA } from "components/invoices";
import {
  IBusinessInvoices,
  useDeleteInvoiceHook,
  useGetBusinessInvoices,
  useGetInvoiceBreakdown,
} from "api";
import { useMemo, useState, useCallback, useEffect } from "react";
import { extractFetchedData } from "utils";
import { useAuthUser } from "store";
import { showToast } from "components/common";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "routes";

export const useInvoicePageHook = () => {
  const { searchParams, addSearchParams } = useLocalSearchParams();
  const { open, toggleModal } = useModal();
  const { open: openDeleteModal, toggleModal: toggleDeleteModal } = useModal();
  const [invoiceId, setInvoiceId] = useState<string>("");

  const { handleDeleteInovice: deleteInvoice, isLoading: isDeleteingInvoice } =
    useDeleteInvoiceHook();

  const { userData } = useAuthUser();

  const navigate = useNavigate();

  // invoice summary
  const [fetchSummaryData, refetchInvoiceSummary] = useGetInvoiceBreakdown();

  const { data } = extractFetchedData<any>({
    result: fetchSummaryData,
    key: "getInvoiceSummary",
  });

  useEffect(() => {
    if (fetchSummaryData.error) {
      showToast({
        subText: "Could not get summary data. Please try again",
        type: "error",
      });
    }
  }, [fetchSummaryData.error]);

  const invoiceSummaryData = data?.getInvoicesStat;

  const mappedValues = invoiceSummaryData?.reduce((acc: any, curr: any) => {
    const currValue = acc[curr._id?.toUpperCase()];
    acc[curr._id?.toUpperCase()] = currValue
      ? { ...currValue, value: currValue?.value + curr.value }
      : curr;

    return acc;
  }, {});

  const newData = {
    paidValue: mappedValues?.PAID?.count || 0,
    unpaidValue: mappedValues?.UNPAID?.count || 0,
    partlyPaidValue: mappedValues?.PART_PAYMENT?.count || 0,
    overdueValue: mappedValues?.OVERDUE?.count || 0,
  };

  const cardDataRef = JSON.stringify(newData);

  const cardData = useMemo(() => {
    return INVOICE_CARDS_DATA(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardDataRef]);

  const handleInvoiceModalOpen = (id: string) => {
    setInvoiceId(id);
    toggleModal();
  };
  const handleInvoiceDeleteModal = (id: string) => {
    setInvoiceId(id);
    toggleDeleteModal();
  };

  const handleViewEditInvoice = (id: string) => {
    setInvoiceId(id);
    navigate(`${APP_ROUTES.INVOICES}/edit-invoice/${id}`);
  };

  const invoiceColumns = useMemo(() => {
    return INVOICE_COLUMNS({
      handleInvoiceModalOpen: (id: string) => handleInvoiceModalOpen(id),
      handleInvoiceDeleteModal: (id: string) => handleInvoiceDeleteModal(id),
      handleViewEditInvoice: (id: string) => handleViewEditInvoice(id),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [pageNumber, setPageNumber] = useState(
    parseInt(searchParams.get("page") || "1")
  );

  const selectedInvoiceStatus = searchParams.get("status");

  const [result, refetchBusinessInvoices] = useGetBusinessInvoices({
    businessPaymentId: userData?.businessId as string,
    page: pageNumber,
    ...(selectedInvoiceStatus &&
      selectedInvoiceStatus?.toLowerCase() !== "all" && {
        status: selectedInvoiceStatus?.toUpperCase() || "",
      }),
  });

  // Handle pagination change
  const handleChangePage = (pageNumber: number) => {
    setPageNumber(pageNumber);
  };

  const handleStatusFilter = useCallback((status: string) => {
    addSearchParams("status", status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const extract = extractFetchedData<IBusinessInvoices>({
    result,
    key: "getBusinessInvoices",
  });

  const handleDeleteInovice = () => {
    deleteInvoice(invoiceId, () => {
      toggleDeleteModal();
      showToast({
        title: "Successful!",
        subText: "You have successfully deleted the invoice",
      });
      refetchBusinessInvoices();
    });
  };

  return {
    ...extract,
    pageNumber,
    handleChangePage,
    refetchBusinessInvoices,
    invoiceColumns,
    handleStatusFilter,
    selectedInvoiceStatus,
    toggleInvoiceModal: toggleModal,
    openInvoiceModal: open,
    invoiceId,
    cardData,
    refetchInvoiceSummary,
    openDeleteModal,
    toggleDeleteModal,
    handleDeleteInovice,
    isDeleteingInvoice,
  };
};
