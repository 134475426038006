import { APP_ROUTES } from "routes";
import DashboardIcon from "../../assets/images/svgs/DashboadIcon";
import { SideBarItemProps } from "./types";
import BagIcon from "../../assets/images/svgs/BagIcon";
import PaperIcon from "../../assets/images/svgs/PaperIcon";
import ActivityIcon from "../../assets/images/svgs/ActivityIcon";
import DocumentIcon from "../../assets/images/svgs/DocumentIcon";
import ChartIcon from "../../assets/images/svgs/ChartIcon";
import ProfileIcon from "../../assets/images/svgs/ProfileIcon";
import UsersIcon from "../../assets/images/svgs/UsersIcon";
// import StoreIcon from "../../assets/images/svgs/StoreIcon";
import ReportIcon from "assets/images/svgs/ReportIcon";
import AirPodsIcon from "assets/images/svgs/side-bar/AirPodsIcon";
import { WALKTHROUGH_IDS } from "utils/walkthrough";
import useCheckPermissions from "hooks/useCheckPermissions";

const SideBarList = (
  partnerId?: string
): { SidebarData: SideBarItemProps[] } => {
  const canViewReferrals = useCheckPermissions(["view_referrals"]) && partnerId;

  const SidebarData = [
    {
      id: "0",
      title: "Home",
      path: APP_ROUTES.DASHBOARD,
      subNav: [
        {
          title: "Dashboard",
          path: APP_ROUTES.DASHBOARD,
          Icon: DashboardIcon,
        },
      ],
    },
    {
      id: "1",
      title: "Books",
      path: "/books",
      subNav: [
        {
          title: "Checkout",
          path: APP_ROUTES.CHECKOUT,
          Icon: BagIcon,
        },
        {
          title: "Sales & Expenses",
          path: APP_ROUTES.SALES,
          Icon: ActivityIcon,
          id: WALKTHROUGH_IDS.SALES_AND_EXPENSES,
        },
        {
          title: "Inventory",
          path: APP_ROUTES.PRODUCTS,
          Icon: PaperIcon,
          id: WALKTHROUGH_IDS.INVENTORY,
        },
      ],
    },
    {
      id: "2",
      title: "Money",
      path: APP_ROUTES.MONEY,
      subNav: [
        {
          title: "Account",
          path: APP_ROUTES.ACCOUNT,
          Icon: DocumentIcon,
          onClick: () => {
            localStorage.removeItem("vedeez-pwa-pagination");
          },
        },
        {
          title: "Invoices",
          path: APP_ROUTES.INVOICES,
          Icon: ChartIcon,
        },
        {
          title: "POS Manager",
          path: APP_ROUTES.POS_DETAILS,
          Icon: AirPodsIcon,
        },
      ],
    },
    {
      id: "3",
      title: "Store",
      path: APP_ROUTES.STORE,
      subNav: [
        {
          title: "Staff",
          path: APP_ROUTES.STAFF,
          Icon: ProfileIcon,
        },
        {
          title: "Customers",
          path: APP_ROUTES.CUSTOMERS,
          Icon: UsersIcon,
        },
        // {
        //   title: "Marketplace",
        //   path: APP_ROUTES.MARKETPLACE,
        //   Icon: StoreIcon,
        // },
        {
          title: "Report",
          path: APP_ROUTES.REPORT,
          Icon: ReportIcon,
        },
      ],
    },
    ...(canViewReferrals
      ? [
          {
            id: "4",
            title: "Referral",
            path: APP_ROUTES.REFERRAL,
            subNav: [
              {
                title: "Referral Information",
                path: APP_ROUTES.REFERRAL_INFO,
                Icon: ActivityIcon,
              },
              {
                title: "Referral Sales",
                path: APP_ROUTES.REFERRAL_SALES,
                Icon: ActivityIcon,
              },
            ],
          },
        ]
      : []),
  ] as SideBarItemProps[];

  return { SidebarData };
};

export default SideBarList;
