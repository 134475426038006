import styled from "styled-components/macro";
import { FlexJAMixin, stylingUtils } from "styles";

const { rem, color } = stylingUtils;

export const StyledBillingTable = styled("section")`
  .icon__file {
    ${FlexJAMixin}
    width: ${rem(29)};
    height: ${rem(29)};
    border-radius: 999%;
    background-color: ${color("transparentBlue")};
    svg {
      transform: translateX(1px);
    }
  }
`;
