import { CustomTableProps } from "components/common/types";
import CardPos from "assets/icons/card-pos.svg";
import {
  capitalizeWord,
  formatNumberToCurrency,
  formatUnderScore,
  getFormattedDate,
} from "utils";
import { IMAGE_PLACE_HOLDER } from "components/constants";
import { Link } from "react-router-dom";
import { APP_ROUTES } from "routes";
import { useGetSalesReceiptPDFHook } from "api/expense-sales/get-sales-receipt-pdf";

export const SALAES_TABLE_COLUMN = (): CustomTableProps["columns"] => {
  const { handleDownloadPDF } = useGetSalesReceiptPDFHook(false);

  return [
    {
      headerName: "Sales ID",
      key: "referenceId",
      width: 120,
      renderCell: (value: string) => <p className="lightblue__text">{value}</p>,
    },
    {
      headerName: "Date",
      key: "saleDate",
      formatter: (value: any) => getFormattedDate(value),
    },
    {
      headerName: "Customer",
      key: "clientName",
      useZero: false,
      formatter: (value) => capitalizeWord(value || ""),
    },
    {
      headerName: "Payment Method",
      key: "salesPayment",
      renderCell: (value, data) => {
        const { salePayments = [] } = data;
        return salePayments
          .map((payment: Record<string, any>) =>
            formatUnderScore(payment?.paymentType || "")
          )
          ?.join(", ");
      },
    },
    {
      headerName: "Amount Of Sale",
      key: "totalSale",
      formatter: (totalSale: any) =>
        formatNumberToCurrency({ number: totalSale || 0 }),
    },
    {
      headerName: "Quantity",
      key: "totalItems",
      formatter: (saleItems: any) => saleItems || 0,
    },
    {
      headerName: "Action",
      key: "dropdown",
      renderCell: (_, salesData) => (
        <Link to={`${APP_ROUTES.SALES}/${salesData._id}`} state={salesData}>
          <button className="lightblue__text">View</button>
        </Link>
      ),
    },
    {
      headerName: "",
      key: "print",
      renderCell: (_, salesData) => (
        <button
          onClick={() => handleDownloadPDF(salesData?._id)}
          className="success__text"
        >
          Print
        </button>
      ),
    },
  ];
};

export const EXPANDED_SALES_ITEM: CustomTableProps["columns"] = [
  {
    headerName: "imgae",
    key: "image",
    width: 150,
    renderCell: (value: any, data) => {
      const { customerProduct } = data;

      return (
        <div className="flex_productId">
          <div className="flex_productId--img">
            <img
              src={
                customerProduct?.productImage
                  ? customerProduct.productImage
                  : IMAGE_PLACE_HOLDER
              }
              alt="product"
            />
          </div>
        </div>
      );
    },
  },
  {
    headerName: "Product Id",
    key: "productId",
    width: 250,
    renderCell: (value: any, data) => {
      const { customerProduct, customerProductName } = data;
      return (
        <div className="sales__expanded">
          <p className="sales__expanded--header">{customerProductName}</p>
          <p>{customerProduct?._id}</p>
        </div>
      );
    },
  },
  {
    headerName: "category",
    key: "category",
    width: 250,
    renderCell: (category: any) => {
      return (
        <div className="sales__expanded">
          <p>Category</p>
          <p className="sales__expanded--header">{category || "N/A"}</p>
        </div>
      );
    },
  },

  {
    headerName: "Quantity",
    key: "quantity",
    renderCell: (quantity: any) => {
      return (
        <div className="sales__expanded">
          <p>Quantity</p>
          <p className="sales__expanded--header">{quantity}</p>
        </div>
      );
    },
  },
  {
    headerName: "Amount",
    key: "sellingPrice",
    renderCell: (value: any) => {
      return (
        <div className="sales__expanded">
          <p>Amount</p>
          <p className="sales__expanded--header">
            {formatNumberToCurrency({ number: value ?? 0 })}
          </p>
        </div>
      );
    },
  },
];

export const getPaymentType = (paymentType: string) => {
  if (!paymentType) return <p>N/A</p>;
  return (
    <div className="payment__method">
      <div className="payment__method--image">
        <img src={CardPos} alt="card" />
      </div>
      <p>{paymentType}</p>
    </div>
  );
};
