import { NotificationItem } from "./NotificationItem";
import { StyledNotificationArea } from "./styles";
import { UINotification } from "../../api/notification/types";

export const NotificationList = ({
  notifications,
  toggleNotificationModal,
}: {
  notifications: UINotification[];
  toggleNotificationModal: Function;
}) => {
  return (
    <StyledNotificationArea>
      <div className="list">
        {notifications.map(
          ({ body, title, time, isSeen, id, extraData }: UINotification) =>
            !isSeen && (
              <NotificationItem
                key={id}
                body={body}
                title={title}
                time={time}
                id={id}
                isSeen={isSeen}
                extraData={extraData}
                toggleNotificationModal={toggleNotificationModal}
              />
            )
        )}
      </div>
    </StyledNotificationArea>
  );
};
