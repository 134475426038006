import { VButton } from "components/common";
import {
  InvoiceModal,
  StyledModalContainer,
  ButtonsDiv,
  InvoiceDetails,
} from "./styles";
import { VModalProps } from "components/common/types";
import { useInvoiceDetailsHook } from "hooks/components/useInvoiceDetailsHook";
import { getModernDateFormat, capitalizeWord } from "utils";
import { RecordPaymentModal } from "../record-payment";
import { useModal } from "hooks";
import DetailsModal from "./DetailsModal";

const ViewInvoiceModal = ({
  open,
  close,
  invoiceId,
  isLoading,
  callback,
}: VModalProps & { invoiceId: string; callback?: () => void }) => {
  const { invoiceDetails, pdfUrl } = useInvoiceDetailsHook(invoiceId);

  const { open: openRecordModal, toggleModal: toggleRecordModal } = useModal();

  const openRecordPaymentModal = () => {
    close();
    toggleRecordModal();
  };

  const subTotal = !invoiceDetails?.invoiceSubTotal
    ? 0
    : Number(invoiceDetails?.invoiceSubTotal);

  const invoicePartlyPayments = (invoiceDetails?.invoicePayments || []).reduce(
    (acc: number, curr: { amountPaid: number }) => acc + curr?.amountPaid || 0,
    0
  );

  const total = Number(invoiceDetails?.invoiceTotal);

  return (
    <>
      <InvoiceModal
        open={open}
        close={close}
        title={`View Invoice`}
        width={700}
        alignModalTitle="center"
        isLoading={isLoading}
      >
        <StyledModalContainer>
          <InvoiceDetails invoiceStatusText={invoiceDetails?.invoiceStatus}>
            <div className="item">
              <h4>Invoice Number:</h4>
              <p>{invoiceDetails?.invoiceNumber}</p>
            </div>
            <div className="item">
              <h4>Name</h4>
              <p>{invoiceDetails?.clientName}</p>
            </div>
            <div className="item">
              <h4>Status</h4>
              <p className="status">
                {invoiceDetails?.invoiceStatus === "PART_PAYMENT"
                  ? "Partly paid"
                  : capitalizeWord(invoiceDetails?.invoiceStatus)}
              </p>
            </div>
            <div className="item">
              <h4>Issue Date:</h4>
              <p>{getModernDateFormat(invoiceDetails?.issueDate)}</p>
            </div>
            <div className="item">
              <h4>Due Date:</h4>
              <p>{getModernDateFormat(invoiceDetails?.dueDate)}</p>
            </div>
          </InvoiceDetails>
          <DetailsModal
            discount={invoiceDetails?.discount || ""}
            vat={invoiceDetails?.totalTax}
            deliveryFee={invoiceDetails?.deliveryFee}
            subTotal={subTotal}
            total={total - invoicePartlyPayments}
            invoiceStatusBg={invoiceDetails?.invoiceStatus}
            tableDetails={invoiceDetails?.invoiceItems}
            invoicePartlyPayments={invoicePartlyPayments}
          />

          <ButtonsDiv>
            <VButton
              width="300px"
              text={
                invoiceDetails?.invoiceStatus === "PAID"
                  ? "Edit Invoice"
                  : "Record Payment"
              }
              disabled={invoiceDetails?.invoiceStatus === "PAID"}
              isSlim
              bgColor="transparent"
              noborder={false}
              textColor="#2D77F1"
              style={{ border: "1px solid #2D77F1" }}
              onClick={openRecordPaymentModal}
            />
            <a
              href={pdfUrl}
              download="invoice.pdf"
              target="_blank"
              rel="noreferrer"
            >
              <VButton
                text={
                  invoiceDetails?.invoiceStatus === "PAID"
                    ? "Share Receipt"
                    : "Share Invoice"
                }
                isSlim
                width={"300px"}
              />
            </a>
          </ButtonsDiv>
        </StyledModalContainer>
      </InvoiceModal>
      {openRecordModal && (
        <RecordPaymentModal
          open={openRecordModal}
          close={toggleRecordModal}
          invoiceId={invoiceId}
          callback={callback}
        />
      )}
    </>
  );
};

export default ViewInvoiceModal;
