import { IBusinessRolesAndPermission } from "api";
import { formatUnderScore } from "utils";
import {
  StyledCheckBox,
  StyledPermissionCheckBox,
  StyledPermissionCheckBoxContainer,
  StyledPermissionGroupButtons,
  StyledPermissionSelectionContainer,
} from "./styles";

export type PermissionSelectionProps = {
  permissionGroupchildren: IBusinessRolesAndPermission["permissions"];
};

export const PermissionSelection = ({
  permissionGroupchildren,
}: PermissionSelectionProps) => {
  return (
    <StyledPermissionSelectionContainer>
      <h5>Sales & Checkout Permissions</h5>
      <p>
        Tick the box to select the permission you want to assign to this role
      </p>
      <StyledPermissionCheckBoxContainer>
        {permissionGroupchildren.map(({ permission }) => (
          <StyledPermissionCheckBox key={permission}>
            <StyledCheckBox>
              <input
                type="checkbox"
                checked={true}
                name={permission}
                id={permission}
              />
            </StyledCheckBox>
            <label htmlFor={permission}>{formatUnderScore(permission)}</label>
          </StyledPermissionCheckBox>
        ))}
      </StyledPermissionCheckBoxContainer>
      <StyledPermissionGroupButtons></StyledPermissionGroupButtons>
    </StyledPermissionSelectionContainer>
  );
};
