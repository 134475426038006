import { IUserBusinessDetails } from "api/manage-business";
import useLazyQuery from "hooks/useLazyQuery";
import { gql, useQuery } from "urql";

type UseGetUserDetailsProps = {
  userId?: string;
  isLazy?: boolean;
};

export const useGetUserDetails = ({
  userId,
  isLazy,
}: UseGetUserDetailsProps) => {
  return useQuery({
    query: getUserDetailsQuery,
    variables: { userId },
    pause: isLazy || userId === null || userId === undefined,
  });
};

export const useLazyGetUserDetails = () => {
  return useLazyQuery({
    query: getUserDetailsQuery,
  });
};

const getUserDetailsQuery = gql`
  query ($userId: String) {
    getUserDetail(userId: $userId) {
      _id
      firstName
      lastName
      roleId
      email
      phoneNumber
      customerId
      createdAt
      businessRegistered
      accountNumber
      accountName
      balance
      bank
      isKycCompleted
      isPinSet
      profilePicture
      kycStatus
      isEmailVerified
      partnerId
      kycDetail {
        bvn
        bvn
        firstName
        lastName
        middleName
        gender
        phone
        dateOfBirth
        photo
      }
      cardDetails {
        first_6digits
        last_4digits
        issuer
        country
        type
        expiry
      }
      status
      businessId
    }
  }
`;

export interface IUserDetails {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  roleId: string;
  permissions?: any[];
  phoneNumber: string;
  customerId: string;
  createdAt: string;
  businessRegistered: string;
  businessId: string;
  is_admin: boolean;
  userId: string;
  businessName?: string;
  business?: IUserBusinessDetails;
  accountName?: string;
  balance?: string;
  bank?: string;
  isKycCompleted?: boolean;
  accountNumber?: string;
  profilePicture?: string;
  kycStatus: string;
  isEmailVerified?: boolean;
  isPinSet?: boolean;
  partnerId?: string;
  kycDetail?: {
    bvn: string;
    firstName: string;
    lastName: string;
    middleName: string;
    gender: string;
    phone: string;
    dateOfBirth: string;
    photo: string;
  };
  cardDetails?: {
    first_6digits: string;
    last_4digits: string;
    issuer: string;
    country: string;
    type: string;
    expiry: string;
  };
  status: string;
}
