const ActivityIcon = (props: any) => {
  return (
    <svg
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.24463 13.7815L8.23776 9.89137L11.652 12.5733L14.581 8.79297"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="17.9954"
        cy="3.20052"
        r="1.9222"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9243 2.12012H5.65655C2.64511 2.12012 0.777832 4.25284 0.777832 7.26428V15.3467C0.777832 18.3581 2.6085 20.4817 5.65655 20.4817H14.2607C17.2721 20.4817 19.1394 18.3581 19.1394 15.3467V8.30776"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default ActivityIcon;
