import styled from "styled-components";
import { stylingUtils } from "styles";

const { color, rem } = stylingUtils;

export const StyledTransactionsTable = styled("div")`
  display: flex;
  flex-direction: column;
  gap: ${rem(16)};
  position: relative;

  .flex_productId {
    display: flex;
    align-items: center;
    gap: ${rem(18)};

    &--img {
      width: ${rem(35)};
      height: ${rem(35)};
      border-radius: 6px;
      overflow: hidden;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
    .productId__title {
      color: ${color("lightBlue")}!important;
      text-decoration: underline;
    }
  }
`;

export const StyledTransactionType = styled("div")`
  display: flex;
  align-items: center;
  img {
    padding: 3px;
    /* padding-top: 10px;
    padding-bottom: 10px; */
    width: 35px;
    height: 35px;
  }

  span {
    margin-left: 8px;
  }
`;
