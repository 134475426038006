import { gql, useQuery } from "urql";

type UseGetFrequentCustomers = {
  businessId: string;
  transactionHistory?: string;
};

const getFrequentCustomersQuery = gql`
  query ($businessId: String, $transactionHistory: TRANSACTIONHISTORY) {
    getHighestSpendingBusinessCustomers(
      filters: { businessId: $businessId }
      transactionHistory: $transactionHistory
    ) {
      _id
      customerName
      amountSpent
      email
      phoneNumber
    }
  }
`;

export const useGetFrequentCustomers = ({
  businessId,
  transactionHistory,
}: UseGetFrequentCustomers) => {
  return useQuery({
    query: getFrequentCustomersQuery,
    variables: { businessId, transactionHistory },
  });
};

export interface IFrequentCustomers {
  customerName: string;
  amountSpent: number;
}
