import { IGetBusinessPlans } from "api/manage-business/billing";
import {
  BUSINESS_PLANS,
  BUSINESS_PLANS_PRICE_INDEX,
} from "components/auth/signup/constants";
import { PRICING_PLAN_MOCK_DATA } from "components/manage-business";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "routes";
import { useAuthUserConstants } from "store";
import {
  AppStorage,
  convertToLocalCurrency,
  formatUnderScore,
  hardRefresh,
  STORGAGE_KEYS,
} from "utils";
import { useBusinessRegistrationHook } from "../useBusinessRegistrationHook";
import { useBillingOverviewPageHook } from "./useBillingOverviewPageHook";

const { addToStore } = new AppStorage();

export const useChangeBillingPlanPageHook = () => {
  const { userBusinessPlan, buinessPlansHash, currencyconverter } =
    useBillingOverviewPageHook();

  const { handleUpgradeBusinessPlanCallback, updgradingBusiness } =
    useBusinessRegistrationHook();

  const {
    status: { hasPaidBusinessPlan },
  } = useAuthUserConstants();

  const navigate = useNavigate();

  const { name = "Basic Plan", price = "0" } = (userBusinessPlan ||
    {}) as IGetBusinessPlans;

  const planSubtitle = hasPaidBusinessPlan ? "Paid" : "Free";

  const { amount, symbol, fetching } = currencyconverter;
  const { business } = useAuthUserConstants();

  const businessPlan = business?.businessPlan || BUSINESS_PLANS.BASIC;

  const memoizedPricingPlan = useMemo(() => {
    return PRICING_PLAN_MOCK_DATA.filter(
      (item) => item.id !== business?.businessPlan
    ).map((item) => {
      const isFreePeriod = !!business?.subscriptionDetails?.freeTrial;
      const userBusinessPlanIndex =
        BUSINESS_PLANS_PRICE_INDEX.indexOf(businessPlan);
      const currentPlanIndex = BUSINESS_PLANS_PRICE_INDEX.indexOf(item.id);
      const hasLowerPlan = userBusinessPlanIndex < currentPlanIndex;

      const isEnterpise = item.id === BUSINESS_PLANS.ENTERPRISE;

      const apiBusinessPlan = buinessPlansHash[item.id];

      if (!isEnterpise)
        item = {
          ...item,
          buttonText: hasLowerPlan
            ? `Upgrade to ${item.planName}`
            : `Back to ${item.planName}`,
          planBilling: (
            amount * Number(apiBusinessPlan?.price ?? 0)
          ).toString(),
          hasLowerPlan,
        };

      if (item.isPaidPlan) {
        item = {
          ...item,
          handleMakePayment: (values: Record<string, any>) => {
            addToStore(STORGAGE_KEYS.BILLING_PLAN, values);
            navigate(APP_ROUTES.CHECKOUT_BILLING_PLAN);
          },
        };
      }

      if (item.isFreePlan) {
        item = {
          ...item,
          handleMakePayment: async () => {
            await handleUpgradeBusinessPlanCallback({
              data: {
                businessPlan: item.id,
                businessId: business?._id ?? "",
              },
              shouldShowModal: false,
              callback: () => hardRefresh(),
            });
          },
          isLoading: updgradingBusiness,
        };
      }

      if (isFreePeriod && !item.isFreePlan) {
        item = {
          ...item,
          disabled: true,
        };
      }

      return {
        ...item,
        symbol,
        billingPlan: apiBusinessPlan,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessPlan, fetching, buinessPlansHash, updgradingBusiness]);

  return {
    currencyconverter,
    memoizedPricingPlan,
    choosePricingPlan: {
      planName: formatUnderScore(name),
      planAmount: convertToLocalCurrency({
        amount: Number(price),
        symbol,
      }),
      planSubtitle,
    },
  };
};
