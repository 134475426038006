import { useEditBusinessCustomer } from "api";
import { showToast } from "components/common";
import { IBusinessCustomer } from "components/customers/customers/edit-customer-modal";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { convertToNigeriaPhoneNumber } from "utils";
import { useCustomersPageHook } from "./useCustomersPageHook";

export const useEditBusinessCustomerHook = (
  selectedCustomer: IBusinessCustomer | null,
  callBack?: () => void
) => {
  const hookForm = useForm<IBusinessCustomer>({});

  const { modal } = useCustomersPageHook();

  const { setSelectedCustomer } = modal;

  const { isUpdatingCustomer, handleEditBusinessCustomer } =
    useEditBusinessCustomer();

  const { handleSubmit, reset } = hookForm;

  const resetForm = () => {
    reset({
      email: "",
      phoneNumber: "",
    });
    setSelectedCustomer(null);
  };

  const onSubmit = async (data: IBusinessCustomer) => {
    await handleEditBusinessCustomer(
      {
        ...data,
        phoneNumber: convertToNigeriaPhoneNumber(data.phoneNumber as string),
      },
      callBack
    );
    showToast({
      subText: `Changes have been saved`,
    });

    resetForm();
  };

  const handleFormSubmit = handleSubmit(onSubmit);

  const customerDataRef = JSON.stringify(selectedCustomer);

  useEffect(() => {
    if (selectedCustomer) {
      const { customerName, email = "", phoneNumber = " " } = selectedCustomer;
      const newPhoneNumber = phoneNumber.replace("+234", "");
      reset({
        customerName,
        email: email ? email : "",
        phoneNumber:
          newPhoneNumber[0] === "0" ? newPhoneNumber.slice(1) : newPhoneNumber,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerDataRef]);

  return {
    isUpdatingCustomer,
    handleFormSubmit,
    hookForm,
    reset,
    resetForm,
  };
};
