const InactiveStaffIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0.75 0 17.25 19"
      {...props}
    >
      <path
        d="M0.75 3L2.03 1.75L11.26 11L18 17.72L16.73 19L13.73 16H2V13C2 11.14 4.61 9.92 7.09 9.36L0.75 3ZM18 13V15.18L16.1 13.28V13C16.1 12.74 15.6 12.35 14.8 12L12 9.18C14.71 9.63 18 10.91 18 13ZM3.9 13V14.1H11.83L8.72 11C6.19 11.3 3.9 12.45 3.9 13ZM10 0C11.0609 0 12.0783 0.421427 12.8284 1.17157C13.5786 1.92172 14 2.93913 14 4C14 5.95 12.6 7.58 10.75 7.93L6.07 3.25C6.42 1.4 8.05 0 10 0ZM10 2C9.46957 2 8.96086 2.21071 8.58579 2.58579C8.21071 2.96086 8 3.46957 8 4C8 4.53043 8.21071 5.03914 8.58579 5.41421C8.96086 5.78929 9.46957 6 10 6C10.5304 6 11.0391 5.78929 11.4142 5.41421C11.7893 5.03914 12 4.53043 12 4C12 3.46957 11.7893 2.96086 11.4142 2.58579C11.0391 2.21071 10.5304 2 10 2Z"
        fill="#CC2E2E"
      />
    </svg>
  );
};
export default InactiveStaffIcon;
