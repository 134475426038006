import { gql, useMutation } from "urql";
import { handleRequestCall } from "utils/handleRequestCall";
import { useAuthUser } from "store";
import { showToast } from "components/common";

const addBusinessCustomerMutation = gql`
  mutation (
    $businessId: String
    $customerName: String
    $phoneNumber: String
    $email: String
  ) {
    createBusinessCustomer(
      businessId: $businessId
      customerName: $customerName
      phoneNumber: $phoneNumber
      email: $email
    ) {
      _id
      customerName
    }
  }
`;

export type IAddBusinessCustomer = {
  businessId?: string;
  customerName: string;
  phoneNumber?: string;
  email?: string;
};

export const useAddBusinessCustomer = () => {
  const [businessCustomer, addBusinessCustomer] = useMutation(
    addBusinessCustomerMutation
  );

  const { userData } = useAuthUser();

  const handleAddBusinessCustomer = async (
    data: Record<string, any>,
    callBack?: () => void
  ) => {
    const { customerName, phoneNumber, email } = data;

    try {
      const values = {
        customerName,
        phoneNumber,
        email,
        businessId: userData?.businessId as string,
      };
      const req = await addBusinessCustomer(values);

      await handleRequestCall({ request: req, callBack });
    } catch (e) {
      console.error(e);
      showToast({
        type: "error",
        title: "An error occurred when creating a user",
        subText: "Please try again",
      });
    }
  };

  return {
    handleAddBusinessCustomer,
    businessCustomer,
    isLoading: businessCustomer?.fetching,
  };
};

export const useEditBusinessCustomer = () => {
  const [businessCustomer, addBusinessCustomer] = useMutation(
    addBusinessCustomerMutation
  );

  const { userData } = useAuthUser();

  const handleEditBusinessCustomer = async (
    data: Record<string, any>,
    callBack?: () => void
  ) => {
    const { customerName, phoneNumber, email } = data;

    try {
      const values = {
        customerName,
        phoneNumber,
        email,
        businessId: userData?.businessId as string,
      };
      const req = await addBusinessCustomer(values);

      await handleRequestCall({ request: req, callBack });
    } catch (e) {
      console.error(e);
      showToast({
        type: "error",
        title: "An error occurred when editing user",
        subText: "Please try again",
      });
    }
  };

  return {
    handleEditBusinessCustomer,
    businessCustomer,
    isUpdatingCustomer: businessCustomer?.fetching,
  };
};
