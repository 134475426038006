import { fetchSearchConfig } from "components/constants";
import { useVAppDispatch } from "hooks/useStore";
import { ChangeEvent, KeyboardEventHandler, MouseEventHandler } from "react";
import { useSearchParams } from "react-router-dom";
import { setSearchTerm, setSearchURL } from "store";

export const useTopNavSearchHook = ({ location }: { location: string }) => {
  const [search, setSearch] = useSearchParams();
  const dispatch = useVAppDispatch();

  let shouldSearch = false;
  const isCategoryParam = search.get("category");
  const isPageParam = search.get("page");
  if (isCategoryParam || isPageParam) {
    shouldSearch = true;
  }

  const { isSearchable, searchPlaceholder, searchType } = fetchSearchConfig(
    shouldSearch,
    location
  );

  const handleClear: MouseEventHandler<HTMLButtonElement> = (_) => {
    setSearch({});
    dispatch(
      setSearchTerm({
        url: location,
        page: searchType,
        term: "",
      })
    );
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length === 0) {
      setSearch({});

      return dispatch(
        setSearchTerm({
          url: location,
          page: searchType,
          term: "",
        })
      );
    }
    setSearch((prevValue) => ({ ...prevValue, search: event.target.value }));
    dispatch(
      setSearchURL({
        url: `${location}?${search}`,
      })
    );
  };

  const handleEnterKeyPress: KeyboardEventHandler<HTMLInputElement> = (
    event
  ) => {
    if (event.key === "Enter") {
      dispatch(
        setSearchTerm({
          url: location,
          page: searchType,
          term: search.get("search"),
        })
      );
    }
  };

  return {
    search,
    isSearchable,
    searchPlaceholder,
    handleClear,
    handleChange,
    handleEnterKeyPress,
  };
};
