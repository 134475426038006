import { Fragment } from "react";
import { VForm, VButton } from "components/common";
import { EditCustomerFrame, FormTitle, Message } from "./styles";
import {
  INVOICE_BASIC_INFO,
  PRODUCT_INPUT_LIST,
  SERVICE_INPUT_LIST,
  ADD_INVOICE_VAT,
} from "../constants";
import {
  Payment,
  SubmitButton,
} from "pages/invoices-page/create-invoice/invoice-products-services/styles";
import { useEditInvoicePageHook } from "hooks";
import alerzoPay from "assets/images/svgs/alerzoPay.svg";
import { InvoiceItems } from "api";

const EditInvoice = () => {
  const {
    hookForm,
    itemArray,
    isLoading,
    onSubmit,
    handleSubmit,
    invoiceDetails,
  } = useEditInvoicePageHook();

  const payment = invoiceDetails?.businessPayment;

  return (
    <>
      <EditCustomerFrame>
        <form onSubmit={handleSubmit(onSubmit)} id="editform">
          <FormTitle>
            <h5 className="title">Basic Information</h5>
          </FormTitle>
          <VForm
            inputLists={INVOICE_BASIC_INFO()}
            hookForm={hookForm}
            vFormClassName="expense__form"
          />
          {itemArray?.map((item: InvoiceItems, index: number) => {
            const fieldName = `invoiceItems[${[index]}]`;
            return (
              <Fragment key={index}>
                {invoiceDetails?.transactionType === "PRODUCT" ? (
                  <>
                    <FormTitle>
                      <h5 className="title">Product Information {index + 1}</h5>
                    </FormTitle>
                    <VForm
                      inputLists={PRODUCT_INPUT_LIST().map((productInput) => ({
                        ...productInput,
                        name: `${fieldName}.${productInput.name}`,
                      }))}
                      hookForm={hookForm}
                      vFormClassName="expense__form"
                    />
                  </>
                ) : (
                  <>
                    <FormTitle>
                      <h5 className="title">Service Information {index + 1}</h5>
                    </FormTitle>
                    <VForm
                      inputLists={SERVICE_INPUT_LIST().map((serviceInput) => ({
                        ...serviceInput,
                        name: `${fieldName}.${serviceInput.name}`,
                      }))}
                      hookForm={hookForm}
                      vFormClassName="expense__form"
                    />
                  </>
                )}
              </Fragment>
            );
          })}
          <FormTitle>
            <h5 className="title">VAT & Discount Information (Optional)</h5>
          </FormTitle>
          <VForm
            inputLists={ADD_INVOICE_VAT}
            hookForm={hookForm}
            vFormClassName="expense__form"
          />
          <FormTitle>
            <h5 className="title">Payment Information (Optional)</h5>
          </FormTitle>
          {!!payment?.bankAccountName ? (
            <Payment>
              <div className="pay">
                <img src={alerzoPay} alt="alerzo pay" />
                <div className="details">
                  <h6>
                    {payment.bankAccountNumber} - {payment.bankAccountName}
                  </h6>
                  <p>{payment.bankName}</p>
                </div>
              </div>
            </Payment>
          ) : (
            <Message style={{ textAlign: "center" }}>
              No payment information
            </Message>
          )}
        </form>
      </EditCustomerFrame>
      <SubmitButton>
        <VButton
          text="Save changes"
          width="320px"
          height="45px"
          form="editform"
          minWidth={"295px"}
          mobWidth={"100%"}
          isLoading={isLoading}
        />
      </SubmitButton>
    </>
  );
};

export default EditInvoice;
