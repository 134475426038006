import { gql, useQuery } from "urql";

const getAccountDetail = gql`
  query ($businessId: String) {
    getAccountDetail(businessId: $businessId)
  }
`;

export const useGetAccountDetail = (businessId?: string) => {
  return useQuery({
    query: getAccountDetail,
    variables: { businessId },
  });
};
