import { useParams } from "react-router-dom";
import { useGetCustomerTerminal } from "api";
import { useModal } from "hooks";
import { useEffect } from "react";
import { useAuthUser } from "store";

export const usePosDetailsHook = () => {
  const { posId } = useParams();
  const { userData } = useAuthUser();
  const { open: openKycModal, toggleModal: toggleKycModal } = useModal();

  const KYC = userData?.isKycCompleted;

  const [result] = useGetCustomerTerminal({
    terminalId: posId,
  });

  const posDetailsData = result?.data?.getCustomerTerminal;

  useEffect(() => {
    if (!KYC) {
      toggleKycModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [KYC]);

  return {
    posDetailsData,
    posId,
    openKycModal,
    toggleKycModal,
    KYC,
  };
};
