import styled from "styled-components";
import { stylingUtils } from "styles";

const { rem } = stylingUtils;

export const StyledVCrashScreenWrapper = styled("section")`
  max-width: ${rem(400)};
  width: 90%;
  margin: 0 auto;
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${rem(20)};

  .img-container {
    position: relative;
    max-width: ${rem(400)};
    width: 100%;

    img {
      width: 100%;
      object-fit: contain;
    }
  }

  .crash-screen-align {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${rem(10)};
  }
`;
