import { VEmptyState, VPagination, VTable } from "components/common";
import { EXPANDED_SALES_ITEM, SALAES_TABLE_COLUMN } from "../constants";
import { StyledSalesTable } from "./styles";
import { IBusinessSales } from "api";
import { CombinedError } from "urql";

interface SalesTableProps {
  fetching: boolean;
  extractedData: IBusinessSales;
  pageNumber: number;
  handleChangePage: (pageNumber: number) => void;
  error: CombinedError | undefined;
}

const SalesTable = ({
  fetching,
  extractedData,
  pageNumber,
  handleChangePage,
  error,
}: SalesTableProps) => {
  if (error) {
    return (
      <VEmptyState
        title="Error Fetching Data!"
        subText="An error occur getting data"
      />
    );
  }

  const { nodes: businessSales, pageInfo } = extractedData;

  const salesTableColumn = SALAES_TABLE_COLUMN();

  return (
    <StyledSalesTable>
      <VTable
        columns={salesTableColumn}
        data={businessSales}
        collapsible
        emptyContent={{
          title: "No Sale Added Yet!",
          subText: "Your history will appear here when you have one",
        }}
        isLoading={fetching}
        renderChildren={(data) => {
          if (data.saleItems.length > 0) {
            return (
              <VTable
                showHeader={false}
                columns={EXPANDED_SALES_ITEM}
                data={data.saleItems}
              />
            );
          } else return null;
        }}
      />
      <VPagination
        pageInfo={pageInfo}
        pageNumber={pageNumber}
        getPageNumber={(pageNumber) => handleChangePage(pageNumber)}
      />
    </StyledSalesTable>
  );
};

export default SalesTable;
