import styled from "styled-components/macro";
import { stylingUtils } from "styles";
import { VModal } from "components/common";

const {
  color,
  rem,
  mediaQuery: { down },
} = stylingUtils;

export const InvoiceModal = styled(VModal)``;

export const StyledModalContainer = styled("div")`
  display: grid;
  place-content: center;
  width: 100%;
  ${down("laptop")} {
    display: flex;
    flex-direction: column;
  }
`;

export const ButtonsDiv = styled("div")`
  display: flex;
  justify-content: center;
  gap: ${rem(12)};
  margin-top: ${rem(30)};

  ${down("tablet")} {
    margin: ${rem(30)} auto !important;
    flex-direction: column;
    width: 100%;

    & button {
      width: 100% !important;
    }
  }
`;

export const InvoiceDetails = styled("div")<{
  invoiceStatusText: string;
}>`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  place-content: center;
  grid-gap: ${rem(20)};
  padding: ${rem(20)} 0;
  ${down("laptop")} {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .item {
      display: grid;
      grid-gap: ${rem(11)};

      &:nth-child(3) {
        display: grid;
        justify-content: center;
        ${down("laptop")} {
          justify-content: flex-start;
        }
      }
  }

  .status {
    color: ${({ invoiceStatusText }) =>
      invoiceStatusText === "PAID"
        ? color("green")
        : invoiceStatusText === "UNPAID"
        ? color("orange01")
        : invoiceStatusText === "PART_PAYMENT"
        ? color("ashText")
        : invoiceStatusText === "OVERDUE"
        ? color("toastRed")
        : ""};
  }
  }

  h4,
  p {
    font-family: "Gilmer";
    font-weight: 500;
    font-size: ${rem(14)};
    line-height: ${rem(17)};
    color: ${color("darkGrey")};
  }

  p {
    color: ${color("lightGrey")};
    font-weight: 400;
  }

  ${down("tablet")} {
    grid-gap: ${rem(5)};
    width: 100%;
    margin: 0 auto;
  }
`;

export const Table = styled("table")`
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: ${rem(30)};
  display: table;

  td,
  th {
    text-align: left;
    padding: ${rem(20)};
  }
  td:last-of-type,
  th:last-of-type {
    text-align: right;
  }

  tr:nth-last-child(1) td {
    border-bottom: 1px solid ${color("ash07")};
  }

  ${down("tablet")} {
    width: 100%;
    margin: auto;
  }
`;

export const Total = styled("div")`
  display: flex;
  justify-content: flex-end;

  table {
    border-collapse: collapse;
    ${down("laptop")} {
      width: 80%;
    }
  }

  td {
    text-align: left;
    padding: ${rem(8)};
  }

  td: nth-child(2) {
    text-align: right;
  }

  tr: nth-last-child(2) {
    border-bottom: 1px solid ${color("ash07")};
  }

  tr: last-of-type > td: last-of-type {
    color: ${color("lightBlue")};
    font-weight: 600;
  }

  ${down("tablet")} {
    width: 100%;
    margin: auto;
  }
`;
