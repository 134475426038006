import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { urqlClient } from "api";

import { Provider } from "urql";

import AuthRoutes from "./AuthRoutes";
import AppRoutes from "./AppRoutes";
import { useOauthCallBackHook } from "hooks";
import { VLoader } from "components/common";
import BaseRoute from "routes/BaseRoutes";
import { APP_ROUTES } from "routes";
import VerifyPayment from "components/manage-business/billing/verify-payment";
import { VerifyBusinessPage } from "pages";
import { VerifyUserPage } from "pages/verify-user";

function App() {
  const { checkIsAuthenticated, isAuthenticating } = useOauthCallBackHook();

  useEffect(() => {
    checkIsAuthenticated();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Provider value={urqlClient}>
      {isAuthenticating ? (
        <div>
          <VLoader text="Preparing Your Business" />
        </div>
      ) : (
        <Routes>
          <Route path="/auth/*" element={<AuthRoutes />} />
          <Route
            path="/*"
            element={
              <BaseRoute title="main-app" isAuthenticatedRoute={true}>
                <AppRoutes />
              </BaseRoute>
            }
          />
          <Route
            path={APP_ROUTES.VERIFY_BUSINESS}
            element={<VerifyBusinessPage />}
          />
          <Route path={APP_ROUTES.VERIFY_USER} element={<VerifyUserPage />} />
          <Route path={APP_ROUTES.VERIFY_PAYMENT} element={<VerifyPayment />} />
          <Route path="*" element={<div>Not found</div>} />
        </Routes>
      )}
    </Provider>
  );
}

export default App;
