import { gql, useQuery } from "urql";
import { DayJs } from "utils";

type UseGetDashboardSummaryProps = {
  bid: string;
  bidID: string;
  bIdRequired: string;
  dateRange?: number;
};

export const useGetDashboardSummary = ({
  bIdRequired,
  bid,
  bidID,
}: UseGetDashboardSummaryProps) => {
  const today = DayJs().startOf("day").add(1, "hour")?.toISOString();

  return useQuery({
    query: getDashboardSummaryQuery,
    variables: {
      bid,
      bidID,
      bIdRequired,
      createdAt: { gte: today },
    },
  });
};

const getDashboardSummaryQuery = gql`
  query ($bid: String, $bidID: String, $createdAt: RangeInput) {
    getSaleStat(filters: { businessId: $bid, createdAt: $createdAt }) {
      totalSale
    }

    getExpenseStat(filters: { businessId: $bidID, createdAt: $createdAt }) {
      totalExpense
    }

    getBusinessInvoices(filters: { businessId: $bid, createdAt: $createdAt }) {
      pageInfo {
        totalCount
      }
    }
    getBusinessCustomers(filters: { businessId: $bid, createdAt: $createdAt }) {
      pageInfo {
        totalCount
      }
    }
  }
`;
