import { lazy } from "react";

export const ReferralInfoPage = lazy(() => import("./referral-info"));
export const ReferralSalesPage = lazy(() => import("./referral-sales"));
export const ReferralSalesDetailsPage = lazy(
  () => import("./referral-sales-details")
);
export const ReferralInfoDetailsPage = lazy(
  () => import("./referral-info-details")
);
