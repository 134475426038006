// Core
import { gql, useMutation } from "urql";

// Custom Components
import { showToast } from "components/common";

// Assets
import { handleRequestCall } from "utils/handleRequestCall";

// GraphQL
const completeKycLevelOneMutation = gql`
  mutation ($bvn: String!) {
    completeKycLevelOne(bvn: $bvn) {
      success
      data
    }
  }
`;

// Functionality
export const useCompleteKycLevelOne = () => {
  const [completeKycLevelOne, setCompleteKycLevelOne] = useMutation(
    completeKycLevelOneMutation
  );

  const handleCompleteKycLevelOne = async (
    variables: { bvn: string },
    callBack?: (data: Record<string, any>) => void
  ) => {
    try {
      const req = await setCompleteKycLevelOne(variables);
      await handleRequestCall({ request: req, callBack });
    } catch (e) {
      showToast({
        type: "error",
        title: "An error ocucured",
        subText: "Please try again",
      });
      console.error(e);
    }
  };

  return {
    completeKycLevelOne,
    handleCompleteKycLevelOne,
  };
};
