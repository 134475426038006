import styled from "styled-components/macro";
import { stylingUtils } from "styles";
import { VModal } from "components/common";
import { ButtonsDiv } from "components/invoices/view-invoice-modal";

const { color, rem } = stylingUtils;

export const PosDetailsModal = styled(VModal)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${rem(14)};
`;

export const StyledModalContainer = styled("div")`
  display: grid;
  //   place-content: center;
  width: 100%;
`;

export const PosDetails = styled("div")`
display: grid;
grid-template-columns: repeat(3, 1fr);
place-content: center;
grid-gap: ${rem(20)};
padding: ${rem(20)} 0;

.item {
    display: grid;
    grid-gap: ${rem(11)};

    &:nth-child(2), &:nth-child(5) {
      display: grid;
      justify-content: left;
      padding-left: ${rem(35)};
    }
    &:nth-child(3) {
        display: grid;
        justify-content: center;
      }
      &:nth-child(5) {
        p {
          color: ${color("orange")};
        }
      }
}
}

h4,
p {
  font-family: "Gilmer";
  font-weight: 500;
  font-size: ${rem(14)};
  line-height: ${rem(17)};
  color: ${color("darkGrey")};
}

p {
  color: ${color("lightGrey")};
  font-weight: 400;
}
`;

export const Address = styled("div")`
  display: flex;
  justify-content: flex-end;
  margin-top: ${rem(20)};

  div {
    display: flex;
    flex-direction: column;
    max-width: ${rem(249)};
    margin-bottom: ${rem(20)};
  }

  p {
    font-family: "Gilmer";
    font-style: normal;
    font-weight: 600;
    font-size: ${rem(14)};
    line-height: ${rem(17)};
    color: ${color("ashBlue")};
    text-align: left;
    margin-bottom: ${rem(15)};
  }
  h5 {
    font-family: "Gilmer";
    font-style: normal;
    font-weight: 400;
    font-size: ${rem(14)};
    line-height: ${rem(17)};
    color: ${color("ashBlue")};
  }
`;

export const Button = styled(ButtonsDiv)``;
