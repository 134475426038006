import { useLocalSearchParams } from "hooks";
import {
  ICustomerCategories,
  ICustomerService,
  INVENTORY_TYPES,
  useGetCustomerCategories,
  useGetCustomerService,
} from "api";
import { useCallback, useMemo, useState } from "react";
import { useAuthUser } from "store";
import { addAllToOptionsList, extractFetchedData } from "utils";

export const useServicePageHook = ({
  pauseCategory = false,
}: {
  pauseCategory?: boolean;
}) => {
  // Handle search params for pagination
  const { searchParams, addSearchParams } = useLocalSearchParams();

  const { userData } = useAuthUser();

  const [pageNumber, setPageNumber] = useState(
    parseInt(searchParams.get("page") || "1")
  );
  const selectedServiceCategory = searchParams.get("category");

  const [result, refetchCustomerService] = useGetCustomerService({
    businessId: userData?.businessId as string,
    page: pageNumber,
    ...(selectedServiceCategory &&
      selectedServiceCategory?.toLowerCase() !== "all" && {
        categoryId: selectedServiceCategory || "",
      }),
  });

  const [serviceCategoriesResult, refetchServiceCategory] =
    useGetCustomerCategories({
      businessId: userData?.businessId as string,
      inventoryType: INVENTORY_TYPES.SERVICE,
      pause: pauseCategory,
    });

  const serviceCategories = useMemo(() => {
    if (serviceCategoriesResult.data) {
      const { extractedData } = extractFetchedData<ICustomerCategories>({
        key: "getCategories",
        result: serviceCategoriesResult,
      });
      const options = (extractedData?.nodes.slice(0, 8) || []).map((data) => ({
        value: data?._id,
        label: data?.name,
        key: data?._id,
      }));

      return addAllToOptionsList(options);
    } else return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceCategoriesResult.fetching, serviceCategoriesResult.data]);

  const { fetching, error, extractedData } =
    extractFetchedData<ICustomerService>({
      result,
      key: "getCustomerServices",
    });

  // Handle pagination change
  const handleChangePage = (pageNumber: number) => {
    setPageNumber(pageNumber);
  };

  const handleCategoryFilter = useCallback((categoryId: string) => {
    addSearchParams("category", categoryId);
    addSearchParams("page", "1");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    result,
    pageNumber,
    refetchCustomerService,
    handleChangePage,
    fetching,
    error,
    extractedData,
    serviceCategories,
    selectedServiceCategory,
    handleCategoryFilter,
    refetchServiceCategory,
  };
};
