import { UserDetailsStyles } from "../styles";
import { useAuthUser } from "store";
import { usePosDetailsHook } from "hooks";
import { getShortMonthDateFormat } from "utils";

const UserDetails = () => {
  const { userData } = useAuthUser();
  const { posDetailsData } = usePosDetailsHook();
  return (
    <UserDetailsStyles>
      <div>
        <p>Outlet Address:</p>
        <h3>{userData?.business?.businessAddress}</h3>
      </div>
      <div>
        <p>Phone Number:</p>
        <h3>{userData?.phoneNumber}</h3>
      </div>
      <div>
        <p>Email:</p>
        <h3>{userData?.email}</h3>
      </div>
      <div>
        <p>Date Assigned:</p>
        <h3>{getShortMonthDateFormat(posDetailsData?.updatedAt)}</h3>
      </div>
      <div>
        <p>Bank Assigned:</p>
        <h3>{userData?.bank}</h3>
      </div>
      <div>
        <p>Account Number:</p>
        <h3>{userData?.accountNumber}</h3>
      </div>
    </UserDetailsStyles>
  );
};

export default UserDetails;
