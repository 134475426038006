import { IUserDetails } from "api";
import {
  mapToUINotifications,
  useGetUserNotification,
} from "api/notification/get-user-notification";
import { Notification } from "api/notification/types";
import useModal from "hooks/useModal";
import { useEffect, useMemo } from "react";
import { useAuthUser } from "store";
import { extractFetchedData } from "utils";

const TWO_MINS = 120000;

export const useNotificationHook = () => {
  const { userData } = useAuthUser();

  const [data, refetchNotifications] = useGetUserNotification({
    userId: (userData as IUserDetails)._id,
    includeSeen: false,
  });

  const { extractedData, fetching } = extractFetchedData<Notification[]>({
    result: data,
    key: "getUserNotification",
  });

  const { toggleModal: toggleNotificationModal, open: shouldOpenNotification } =
    useModal();

  const notifications = useMemo(() => {
    return Array.isArray(extractedData)
      ? mapToUINotifications(extractedData)
      : [];
  }, [extractedData]);

  useEffect(() => {
    if (fetching) return;

    const timerId = setTimeout(() => {
      refetchNotifications({ requestPolicy: "network-only" });
    }, TWO_MINS);

    return () => clearTimeout(timerId);
  }, [
    fetching,
    refetchNotifications,
    toggleNotificationModal,
    notifications,
    shouldOpenNotification,
  ]);

  return {
    toggleNotificationModal,
    shouldOpenNotification,
    notifications,
  };
};
