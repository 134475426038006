import { BILLING_TABLE_COLUMNS } from "components/manage-business";
import { useLocalSearchParams } from "hooks";
import { useCallback, useState } from "react";
import { useGetSubscriptionLogsHook } from "../../api/manage-business/billing/get-subscription-logs";

export const useBillingTableBHook = () => {
  const billingColumns = BILLING_TABLE_COLUMNS({});

  const { searchParams } = useLocalSearchParams();

  const [pageNumber, setPageNumber] = useState(
    parseInt(searchParams.get("page") || "1")
  );

  const { extractedData } = useGetSubscriptionLogsHook({
    page: pageNumber,
  });
  const { nodes: billingSubscriptions, pageInfo } = extractedData;

  const handleChangePage = useCallback(
    (pageNumber: number) => {
      setPageNumber(pageNumber);
    },

    []
  );

  return {
    pageInfo,
    billingSubscriptions,
    pageNumber,
    billingColumns,
    handleChangePage,
  };
};
