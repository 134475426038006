import { showToast } from "./../../components/common/VToast/index";
import { gql, useMutation } from "urql";
import { handleRequestCall } from "utils/handleRequestCall";
import { useToggle } from "hooks";
import { uploadMedia } from "utils";

const updateBusinessMutation = gql`
  mutation (
    $businessId: String
    $avgMonthlyRevenue: String
    $dailySale: String
    $posRequired: Int
    $deliveryAddress: String
    $state: String
    $city: String
    $CACNumber: String
    $utilityBill: String
    $businessSectorId: String
    $businessPlan: String
    $isSubscribed: Boolean
  ) {
    updateBusiness(
      businessId: $businessId
      avgMonthlyRevenue: $avgMonthlyRevenue
      dailySale: $dailySale
      posRequired: $posRequired
      deliveryAddress: $deliveryAddress
      state: $state
      city: $city
      CACNumber: $CACNumber
      utilityBill: $utilityBill
      businessSectorId: $businessSectorId
      businessPlan: $businessPlan
      isSubscribed: $isSubscribed
    ) {
      _id
      businessPlan
      isSubscribed
    }
  }
`;

export const useUpdateBusiness = () => {
  const [business, updateBusiness] = useMutation(updateBusinessMutation);
  const [imageProcessing, toggleImageProcessing] = useToggle();

  const handleUpdateBusiness = async (
    data: Record<string, any>,
    callBack?: (data: Record<string, any>) => void
  ) => {
    toggleImageProcessing();
    let processedUtilityBill: null | string = null;
    const utilityBill = data?.utilityBill;
    try {
      if (utilityBill) {
        const uploadedUtilityFile = await uploadMedia(utilityBill as File);
        if (!uploadedUtilityFile) {
          toggleImageProcessing();
          return;
        }
        processedUtilityBill = uploadedUtilityFile[0]?.url;
      }
      toggleImageProcessing();

      const values = {
        ...data,
        ...(processedUtilityBill && { utilityBill: processedUtilityBill }),
      };
      const req = await updateBusiness(values);

      await handleRequestCall({ request: req, callBack });
    } catch (e) {
      showToast({
        type: "error",
        title: "An error occured",
        subText: "Please try again",
      });
      console.error(e);
    }
  };

  return {
    handleUpdateBusiness,
    business,
    isLoading: imageProcessing || business?.fetching,
  };
};
