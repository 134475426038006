import styled from "styled-components/macro";
import { stylingUtils } from "styles";

const {
  rem,
  color,
  mediaQuery: { down },
} = stylingUtils;

export const StyledTransactionContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-top: 1rem;
  flex-wrap: wrap;

  ${down("tablet")} {
    flex-direction: column;
  }
`;

export const StyledTransactionFormContent = styled("div")<{
  accountName?: boolean;
}>`
  flex: 3;
  background: #ffffff;
  border: 1px solid #ffffff;
  backdrop-filter: blur(2px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: ${rem(20)};
  padding: ${rem(24)};
  padding-bottom: ${rem(45)};

  > h1 {
    font-family: "Gilmer";
    font-style: normal;
    font-weight: 600;
    font-size: ${rem(16)};
    line-height: 19px;

    color: #434343;
    margin-bottom: 20px;
  }

  .accountHolder {
    input,
    input:focus {
      border: none !important;
      margin: -1rem 0;
      text-align: right;
      color: #34a853;
      font-weight: 600;
      outline: none;
    }

    display: ${({ accountName }) => (accountName ? "initial" : "none")};
  }

  .remark {
    min-height: 6rem;
    background: #ffffff;
    border: 1px solid ${color("ashRadio")};
    border-radius: 10px;
    display: block;
    margin-top: 0.5rem;
    width: 100%;
    font-family: "Gilmer";
    padding: 8px;

    ::placeholder {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      font-size: inherit;
      color: #a5b0b7;
    }
  }

  .switch-container {
    margin-top: ${rem(20)};
  }

  ${down("tablet")} {
    flex: 1;
  }
`;

export const StyledTransactionSummaryContent = styled.div`
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid #ffffff;
  backdrop-filter: blur(2px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 20px;
  padding-top: ${rem(18)};
  padding-bottom: ${rem(29)};
  padding-inline: ${rem(20)};
  height: max-content;

  > h1 {
    font-family: "Gilmer";
    font-style: normal;
    font-weight: 600;
    font-size: ${rem(18)};
    line-height: 22px;
    color: #434343;
    margin-bottom: ${rem(17)};
  }

  .button {
    width: 100%;
    height: 40px;
    margin-top: 2rem;
    border-radius: 10px;

    &:disabled {
      background-color: #0077ff;
      opacity: 0.6;
    }
  }

  .summary-container {
    display: flex;
    flex-direction: column;
    gap: ${rem(18)};
  }
  .item-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
  }
  .item {
    font-family: "Gilmer";
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 19px;
    display: flex;
    align-items: center;
    gap: 6px;
    color: #69778c;
  }

  .amount-item {
    font-family: "Gilmer";
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 19px;
    text-align: right;

    /* Alerzo Light Blue #0077FF */

    color: #0077ff;
  }
`;

export const StyledSummaryContainer = styled.div`
  flex: 2;

  .button {
    width: 100%;
    height: 50px;
    margin-top: 1rem;
    border-radius: 10px;

    &:disabled {
      background-color: #2d77f1;
      color: #fff;
      opacity: 0.3;
    }
  }

  ${down("tablet")} {
    flex: 1;
  }
`;

export const StyledProviderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${rem(10)};

  > img {
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 15px;
  }
`;
