import { showToast } from "components/common";
import { gql, useMutation } from "urql";
import { handleRequestCall } from "utils/handleRequestCall";

const IMPORT_PRODUCTS = gql`
  mutation ($products: [AlerzoImportProduct]) {
    addCustomerProductsFromShop(products: $products) {
      success
    }
  }
`;

export const useImportAlerzoProducts = () => {
  const [importedProducts, importProducts] = useMutation(IMPORT_PRODUCTS);

  const handleImportProducts = async (
    products: {
      productId: string;
      sellingPrice: number;
      categoryId: string;
    }[],
    callBack?: () => void
  ) => {
    try {
      const req = await importProducts({ products });

      await handleRequestCall({
        request: req,
        callBack: () => {
          callBack?.();
        },
      });
    } catch (e) {
      showToast({
        type: "error",
        title: "An error occurred",
        subText: "Please try again",
      });
    }
  };

  return {
    handleImportProducts,
    importedProducts,
    isLoading: importedProducts?.fetching,
  };
};
