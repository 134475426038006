import FileUploadImage from "./file-upload-image.svg";

const FileUploadImageSvg = (props: any) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 26 43 35.89"
    >
      <path
        d="M33.558 45.0074C31.1011 42.6299 27.2028 42.6245 24.7394 44.9952L21.4302 48.284C20.6152 49.0942 20.6113 50.4116 21.4215 51.2266C21.4223 51.2275 21.4232 51.2284 21.4241 51.2293L31.1544 60.9799L31.518 61.8946H35.3864C38.8335 61.8909 41.6269 59.0974 41.6307 55.6504V53.9226C41.6305 53.3696 41.4104 52.8394 41.0188 52.4489L33.558 45.0074Z"
        fill="#F2F2F2"
      />
      <path
        d="M6.2443 61.8939H35.3843C36.1233 61.8882 36.8551 61.7498 37.5452 61.4856L16.9045 40.8438C14.4471 38.4648 10.5467 38.4604 8.08385 40.8337L0.611941 48.2854C0.220382 48.6758 0.000208143 49.206 0 49.759V55.6496C0.00376739 59.0967 2.79722 61.8902 6.2443 61.8939Z"
        fill="#B3B9C2"
      />
      <path
        d="M35.3843 28.5908H6.2443C2.79717 28.5946 0.00380902 31.388 0 34.8351V49.7588C0.000249772 49.2058 0.220424 48.6757 0.611962 48.2851L8.08385 40.8335C10.5468 38.4601 14.4471 38.4647 16.9045 40.8436L22.8904 46.8299L24.7373 44.9943C27.2008 42.6236 31.0991 42.6289 33.556 45.0065L41.0168 52.448C41.4083 52.8385 41.6285 53.3687 41.6286 53.9217V34.8351C41.6248 31.388 38.8315 28.5946 35.3843 28.5908Z"
        fill="#DADADA"
      />
      <g filter="url(#filter0_d_2736_114689)">
        <circle cx="38.3384" cy="30.6627" r="4.66265" fill="#F1F1F1" />
      </g>
      <path
        d="M38.7769 30.6838L39.8005 29.6453C39.8582 29.586 39.8904 29.506 39.8902 29.4227C39.8899 29.3395 39.8572 29.2596 39.7991 29.2008C39.7411 29.1419 39.6624 29.1087 39.5803 29.1084C39.4982 29.1081 39.4194 29.1408 39.361 29.1994L38.3373 30.2379L37.3137 29.1994C37.2553 29.1411 37.1765 29.1086 37.0946 29.109C37.0126 29.1094 36.9341 29.1426 36.8762 29.2014C36.8182 29.2602 36.7855 29.3398 36.7852 29.4229C36.7848 29.5061 36.8168 29.586 36.8742 29.6453L37.8978 30.6838L36.8742 31.7224C36.8168 31.7817 36.7848 31.8616 36.7852 31.9447C36.7855 32.0279 36.8182 32.1075 36.8762 32.1663C36.9341 32.2251 37.0126 32.2583 37.0946 32.2586C37.1765 32.259 37.2553 32.2266 37.3137 32.1683L38.3373 31.1298L39.361 32.1683C39.4194 32.2268 39.4982 32.2595 39.5803 32.2593C39.6624 32.259 39.7411 32.2258 39.7991 32.1669C39.8572 32.108 39.8899 32.0282 39.8901 31.9449C39.8904 31.8616 39.8582 31.7816 39.8005 31.7224L38.7769 30.6838Z"
        fill="#69778C"
      />
      <defs>
        <filter
          id="filter0_d_2736_114689"
          x="3.67578"
          y="0"
          width="69.3252"
          height="69.3252"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="15" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2736_114689"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2736_114689"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export { FileUploadImage, FileUploadImageSvg };
