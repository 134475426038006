import { useStaffPageHook } from "hooks";
import { gql, useMutation } from "urql";
import { handleRequestCall } from "utils/handleRequestCall";
import { showToast } from "components/common";

const deleteBusinessStatffMutation = gql`
  mutation ($businessStaffId: String!) {
    deleteBusinessStaff(businessStaffId: $businessStaffId)
  }
`;

export const useDeleteBusinessStaff = () => {
  const [businessStaff, deleteBusinessStaff] = useMutation(
    deleteBusinessStatffMutation
  );

  const {
    staffTable: { refetchBusinessStaffs },
  } = useStaffPageHook();

  const handleDeleteBusinessStaff = async (
    data: { businessStaffId: string },
    callBack?: () => void
  ) => {
    try {
      const req = await deleteBusinessStaff(data);
      await handleRequestCall({
        request: req,
        callBack: () => {
          refetchBusinessStaffs();
          callBack?.();
        },
      });
    } catch (e) {
      showToast({
        type: "error",
        title: "An error occured",
        subText: "Please try again",
      });
      console.error(e);
    }
  };

  return {
    handleDeleteBusinessStaff,
    businessStaff,
  };
};
