import { useExpensePageHook } from "hooks";
import { gql, useMutation } from "urql";
import { handleRequestCall } from "utils/handleRequestCall";
import {
  ICustomerCategories,
  INVENTORY_TYPES,
  useGetCustomerCategories,
} from "api/inventory";
import { extractFetchedData } from "utils";
import { showToast } from "components/common";

const deleteBusinessExpenseMutation = gql`
  mutation ($expenseId: String) {
    deleteBusinessExpense(expenseId: $expenseId)
  }
`;

export type IDeleteBusinessExpense = {
  expenseId: string;
};

export const useDeleteBusinessExpense = () => {
  const [businessExpense, deleteBusinessExpense] = useMutation(
    deleteBusinessExpenseMutation
  );

  const [result] = useGetCustomerCategories({
    inventoryType: INVENTORY_TYPES.EXPENSE,
    size: -1,
  });

  const { extractedData: expenseCategories } =
    extractFetchedData<ICustomerCategories>({
      result,
      key: "getCategories",
    });

  const { refechBusinessExpenses } = useExpensePageHook();

  const handleDeleteBusinessExpense = async (
    data: IDeleteBusinessExpense,
    callBack?: () => void
  ) => {
    const { expenseId } = data;

    try {
      const values = {
        expenseId,
      };
      const req = await deleteBusinessExpense(values);

      refechBusinessExpenses();
      await handleRequestCall({ request: req, callBack });
    } catch (e) {
      showToast({
        type: "error",
        title: "An error occurred",
        subText: "Please try again",
      });
    }
  };

  return {
    handleDeleteBusinessExpense,
    businessExpense,
    expenseCategories: expenseCategories?.nodes || [],
  };
};
