import { useAuthUser } from "store";
import { StyledIcon, StyledItem } from "./styles";
import ImportIcon from "assets/icons/import-product.svg";
import { timeSince } from "utils";
import { useUpdateUserNotification } from "api/notification/update-user-notification";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "routes";

export const NotificationItem = ({
  id: notificationId,
  title,
  body,
  time,
  isSeen,
  toggleNotificationModal,
  extraData,
}: {
  id: string;
  title: string;
  body: string;
  time: string;
  isSeen: boolean;
  extraData: {
    productId?: string | null;
  };
  toggleNotificationModal: Function;
}) => {
  const { userData } = useAuthUser();
  const navigate = useNavigate();
  const { updateNotification } = useUpdateUserNotification();

  const INTERVAL = timeSince(new Date(time));

  const ICON =
    title === "Order Delivered!"
      ? ImportIcon
      : (userData?.profilePicture as string) || ImportIcon;

  const TITLE =
    title === "Order Delivered!"
      ? "Import products from Alerzoshop"
      : title === "Low Stock"
      ? "Edit Inventory"
      : title === "Account Updated!"
      ? "Credit Alert"
      : title;

  const navigateUser = (title: string, productId?: string | null) => {
    switch (title) {
      case "Low Stock":
        return `${APP_ROUTES.PRODUCTS}/${productId}`;
      case "Welcome to Veedez 💫":
        return `${APP_ROUTES.PRODUCTS}`;
      case "Order Delivered!":
        return `${APP_ROUTES.IMPORT_PRODUCTS}`;
      case "Yipeee 🥳 first product uploaded.":
        return APP_ROUTES.INVOICE_PRODUCTS;
      case "Account Updated!":
        return APP_ROUTES.ACCOUNT;
      default:
        return "";
    }
  };

  const handleClickNotification = () => {
    const productId = extraData?.productId || null;
    updateNotification({ notificationId, isSeen: !isSeen });
    toggleNotificationModal();

    const redirectRoute = navigateUser(title, productId);
    redirectRoute && navigate(redirectRoute);
  };

  return (
    <StyledItem onClick={handleClickNotification}>
      <div className="item">
        <div>
          <StyledIcon icon={ICON} />
        </div>
        <div className="textarea">
          <h3 className="title">{TITLE}</h3>
          <p className="body">{body}</p>
          <p className="time">{INTERVAL} ago</p>
        </div>
      </div>
    </StyledItem>
  );
};
