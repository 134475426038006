import { APP_ROUTES } from "routes";
import { VChipProps } from "components/common/types";
import { DayJs } from "utils";

export const REPORTS_PAGE_TABS = [
  {
    key: "sales",
    label: "Sales",
    link: `${APP_ROUTES.REPORT_SALES}`,
    secondaryLink: APP_ROUTES.REPORT,
  },
  {
    key: "expenses",
    label: "Expenses",
    link: `${APP_ROUTES.REPORT_EXPENSES}`,
  },
  {
    key: "inventory",
    label: "Inventory",
    link: `${APP_ROUTES.REPORT_INVENTORY}`,
  },
  {
    key: "invoices",
    label: "Invoices",
    link: `${APP_ROUTES.REPORT_INVOICES}`,
  },
  {
    key: "staff",
    label: "Staff",
    link: `${APP_ROUTES.REPORT_STAFF}`,
  },
];

export enum REPORT_FILTER_DATE_ENUM {
  TODAY = "today",
  WEEKLY = "weekly",
  MONTHLY = "monthly",
}

export const REPORT_FILTER_CHIPS: VChipProps[] = [
  {
    key: REPORT_FILTER_DATE_ENUM.TODAY,
    label: "Today",
  },
  {
    key: REPORT_FILTER_DATE_ENUM.WEEKLY,
    label: "Weekly",
  },
  {
    key: REPORT_FILTER_DATE_ENUM.MONTHLY,
    label: "Monthly",
  },
];

export const MAPPED_REPORT_FILTER_DATE_ENUM = {
  [REPORT_FILTER_DATE_ENUM.TODAY]: DayJs()
    .startOf("day")
    .add(1, "hour")
    ?.toISOString(),
  [REPORT_FILTER_DATE_ENUM.WEEKLY]: DayJs().subtract(1, "week")?.toISOString(),
  [REPORT_FILTER_DATE_ENUM.MONTHLY]: DayJs()
    .subtract(1, "month")
    ?.toISOString(),
};

export const GRAPH_STYLE_PROPS: Record<string, any> = {
  margin: { top: 0, left: 0, right: 0, bottom: 0 },
};
