import styled from "styled-components";
import { stylingUtils } from "styles";
import {
  StyledStaffInfo,
  StyledStaffActivitiesHeader,
} from "components/staff/styles";

const {
  color,
  rem,
  mediaQuery: { down },
} = stylingUtils;

export const StyledCustomerInfo = styled(StyledStaffInfo)<{
  customerStatus: string;
}>`
  .active {
    color: ${({ customerStatus }) =>
      customerStatus === "ACTIVE"
        ? color("green")
        : customerStatus === "INACTIVE"
        ? color("orange01")
        : ""};
    font-weight: 500;
  }

  .dot {
    position: absolute;
    bottom: 10px;
    right: 10px;
    height: 20px;
    width: 20px;
    border: 3px solid #fff;
    border-radius: 50%;
    background-color: ${({ customerStatus }) =>
      customerStatus === "ACTIVE"
        ? color("green")
        : customerStatus === "INACTIVE"
        ? color("orange01")
        : ""};
  }
`;

export const StyledCustomerTransactionsHeader = styled(
  StyledStaffActivitiesHeader
)`
  margin-top: ${rem(30)};
`;

export const CustomerFrame = styled("div")`
  justify-content: space-between;
  background-color: ${color("white")};
  padding: 3rem;
  border-radius: ${rem(20)};

  h4 {
    font-family: "Gilmer";
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #434343;
    margin-bottom: 1rem;
  }

  .expense__form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 ${rem(20)};
    transition: 0.5s all ease;
    ${down("tablet")} {
      grid-template-columns: 1fr;
    }
  }

  hr {
    border: 0.5px solid #f4f4f4;
  }
  .recent {
    margin-top: ${rem(30)};
    margin-bottom: ${rem(20)};
  }
`;

export const UserInfo = styled("div")`
  table {
    border-collapse: collapse;
    display: table;
  }
  td {
    text-align: left;
    padding: ${rem(8)} ${rem(16)};
  }
  tr:nth-last-child(3) td {
    border-bottom: 1px solid ${color("ash07")};
  }
  .name {
    color: ${color("lightBlue")};
  }
  h4 {
    color: ${color("ash10")};
    font-size: ${rem(15)};
  }
  h5 {
    color: ${color("ash10")};
    font-size: ${rem(14)};
  }
  p {
    color: ${color("ash03")};
    font-size: ${rem(11)};
  }
`;

export const User = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  position: relative;
  ${down("mobileL")} {
    flex-direction: row;
  }

  .MobileStaffName {
    display: none;
    ${down("mobileL")} {
      display: flex;
      font-weight: 300;

      span {
        font-weight: 500;
        color: ${color("lightBlue")};
        font-size: ${rem(16)};
      }
    }
  }

  .name_placeholder {
    height: 120px;
    width: 120px;
    border-radius: 50%;
    background-color: ${color("transparentBlue2")};
    display: grid;
    place-content: center;
    position: relative;
  }

  h3 {
    font-size: ${rem(35)};
    color: ${color("lightBlue")};
    font-weight: 500;
  }
  p {
    color: ${color("green")};
    font-weight: 400;
    font-size: 10px;
    font-size: ${rem(10)};
  }
`;
export const Buttons = styled("div")`
  padding-top: ${rem(50)};
  padding-bottom: ${rem(20)};
  display: flex;
  gap: ${rem(30)};
`;

export const TableContainer = styled("div")`
  display: flex;
  justify-content: center;
`;

export const Table = styled("table")`
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: ${rem(30)};
  display: table;

  td {
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.2px;
    color: ${color("ash10")};
  }

  th {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.2px;
  }

  td,
  th {
    text-align: left;
    padding: ${rem(20)};
  }

  td,
  th {
    border-bottom: 1px solid ${color("ash01")};
    border-top: 1px solid ${color("ash01")};
  }
`;
