import { showToast } from "./../../components/common/VToast/index";
import { gql, useMutation } from "urql";
import { handleRequestCall } from "utils/handleRequestCall";

const requestTerminalMutation = gql`
  mutation (
    $userId: String
    $terminalSpecId: String
    $quantity: Int
    $passcode: String
  ) {
    requestTerminal(
      userId: $userId
      terminalSpecId: $terminalSpecId
      quantity: $quantity
      passcode: $passcode
    ) {
      success
      data
    }
  }
`;

export const useRequestTerminal = () => {
  const [terminal, requestTerminal] = useMutation(requestTerminalMutation);

  const handleRequestTerminal = async (
    data: {
      userId: string;
      terminalSpecId: string;
      quantity: number;
      passcode: string;
    },
    callBack?: (data: Record<string, any>) => void
  ) => {
    try {
      const req = await requestTerminal(data);

      await handleRequestCall({ request: req, callBack });

      const responseData = req.data?.requestTerminal.data;

      return responseData;
    } catch (e) {
      showToast({
        type: "error",
        title: "An error occured while requesting pos",
        subText: "Please try again",
      });
      console.error(e);
    }
  };

  return {
    handleRequestTerminal,
    terminal,
    isLoading: terminal?.fetching,
  };
};
