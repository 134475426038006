import { IBusinessExpenseNode, ICustomerCategories } from "api";
import {
  capitalizeWord,
  formatNumberToCurrency,
  getFormattedDate,
  convertArrayToOptions,
  formatUnderScore,
} from "utils";
import { getPaymentType } from "../sales/constants";
import {
  InputListType,
  CustomTableProps,
  VDropdownProps,
} from "components/common/types";
import { VDropdown } from "components/common";
import VerticalDropdown from "assets/icons/vertical-dot.svg";

export const DASHBOARD_MOCK_DATA = Array.from({ length: 5 }).map(
  (x, index) => ({
    id: index,
    productId: "AF-329489",
    name: "Spaghetti",
    category: "cereal",
    costPrice: 1000,
    sellingPrice: 2000,
    stock: 89,
  })
);

export const SELECT_OPTIONS = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

export const PAYMENT_TYPE = ["CASH", "POS", "BANK"];

export const ADD_EXPENSE_CATEGORY_INPUT_LIST: InputListType[] = [
  {
    name: "category",
    label: "Category",
    inputType: "select",
    options: SELECT_OPTIONS,
  },
];

export const EXPENSE_TABLE_COLUMN = (
  handleOpenViewModal: (data: IBusinessExpenseNode) => void,
  handleOpenEditModal: (data: IBusinessExpenseNode) => void,
  handleOpenDeleteModal: (data: IBusinessExpenseNode) => void
): CustomTableProps["columns"] => {
  return [
    {
      headerName: "Date",
      key: "createdAt",
      width: 160,
      formatter: (value: any) => getFormattedDate(value),
    },
    {
      headerName: "Amount",
      key: "amount",
      formatter: (value: any) => formatNumberToCurrency({ number: value }),
    },
    {
      headerName: "Category",
      key: "category.name",
      formatter: (value: any) => formatUnderScore(value),
    },
    {
      headerName: "Item",
      key: "title",
      useZero: false,
      formatter: (value) => capitalizeWord(value || ""),
    },
    {
      headerName: "Payment Method",
      key: "paymentType",
      renderCell: (value) => getPaymentType(value || ""),
    },
    {
      headerName: "Action",
      key: "dropdown",
      renderCell: (_: any, tableData) => (
        <VDropdown
          dropdownButton={
            <button className="vertical__drop--down">
              <img src={VerticalDropdown} alt="vertical-dropdown" />
            </button>
          }
          dropdownItems={INVENTORY_PRODUCT_ACTIONS_DROPDOWNS({
            handleOpenViewModal: () => handleOpenViewModal(tableData),
            handleOpenEditModal: () => handleOpenEditModal(tableData),
            handleOpenDeleteModal: () => handleOpenDeleteModal(tableData),
          }).map((item) => ({
            ...item,
          }))}
          isLeft={true}
        />
      ),
    },
  ];
};

export const INVENTORY_PRODUCT_ACTIONS_DROPDOWNS = ({
  handleOpenViewModal,
  handleOpenEditModal,
  handleOpenDeleteModal,
}: {
  handleOpenViewModal: () => void;
  handleOpenEditModal: () => void;
  handleOpenDeleteModal: () => void;
}): VDropdownProps["dropdownItems"] => {
  return [
    {
      key: "view",
      label: "View",
      onClick: () => handleOpenViewModal(),
    },
    {
      key: "edit",
      label: "Edit",
      onClick: () => handleOpenEditModal(),
    },
    {
      key: "delete",
      label: "Delete",
      onClick: () => handleOpenDeleteModal(),
    },
  ] as VDropdownProps["dropdownItems"];
};

const PAYMENT_METHOD = convertArrayToOptions(PAYMENT_TYPE);

export const EXPENSE_FORM_INPUT_LIST = ({
  categories,
}: {
  categories: ICustomerCategories["nodes"];
}): InputListType[] => {
  return [
    {
      name: "amount",
      type: "amount",
      label: "Amount",
      required: true,
      validation: { required: "Please enter amount" },
    },
    {
      label: "Item",
      name: "item",
      required: true,
      validation: { required: "Enter Item" },
    },
    {
      name: "categoryId",
      label: "Category",
      inputType: "select",
      options: categories.map((category) => ({
        label: category.name,
        value: category._id,
      })),
      required: true,
      validation: { required: "Select category" },
    },
    {
      name: "paymentType",
      label: "Payment Method",
      inputType: "select",
      options: PAYMENT_METHOD,
      required: true,
      validation: { required: "Select payment method" },
    },
  ];
};

export const EDIT_EXPENSE_FORM_INPUT_LIST = ({
  categories,
  expense,
}: {
  categories: ICustomerCategories["nodes"];
  expense: IBusinessExpenseNode;
}): InputListType[] => {
  return [
    {
      name: "amount",
      type: "amount",
      label: "Amount",
      required: true,
      validation: { required: "Please enter amount" },
      disabled: true,
    },
    {
      label: "Item",
      name: "item",
      required: true,
      validation: { required: "Enter Item" },
      defaultValue: expense?.title,
    },
    {
      name: "categoryId",
      label: "Category",
      inputType: "select",
      options: categories.map((category) => ({
        label: category.name,
        value: category._id,
      })),
      required: true,
      validation: { required: "Select category" },
      value: expense?.category?.name,
    },
    {
      name: "paymentType",
      label: "Payment Method",
      inputType: "select",
      options: PAYMENT_METHOD,
      required: true,
      validation: { required: "Select payment method" },
      value: expense?.paymentType,
    },
  ];
};

export const DELETE_EXPENSE_FORM_INPUT_LIST = (): InputListType[] => {
  return [
    {
      name: "expenseId",
      required: true,
      disabled: true,
      hidden: true,
    },
  ];
};
