import styled from "styled-components/macro";
import { stylingUtils } from "styles";

const { rem } = stylingUtils;
export const StyledVToastTitle = styled("h4")<{
  toastType: "success" | "error" | "delete";
}>`
  color: ${({ toastType }) =>
    toastType === "success"
      ? "#001833"
      : toastType === "delete"
      ? "#001833"
      : "white"};
  font-size: ${rem(16)};
  font-weight: 500;
  margin-bottom: ${rem(4)};
`;

export const StyledVToastSubText = styled("p")<{
  toastType: "success" | "error" | "delete";
}>`
  color: ${({ toastType }) =>
    toastType === "success"
      ? "#575A65"
      : toastType === "delete"
      ? "rgba(87, 90, 101, 0.8)"
      : "white"};
`;

export const StyledVToastActionText = styled("button")<{
  toastType: "success" | "error" | "delete";
}>`
  color: ${({ toastType }) =>
    toastType === "success"
      ? "#2D77F1"
      : toastType === "delete"
      ? "rgba(87, 90, 101, 0.8)"
      : "white"};
  text-decoration: underline;
  font-size: ${rem(12)};
`;

export const StyledVToastContainer = styled("div")`
  display: flex;
  gap: ${rem(5)};
  font-size: ${rem(12)};
  flex-wrap: wrap;
`;
