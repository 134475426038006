import { useMemo } from "react";
import { ITotalSales, useGetTotalSales } from "api";
import { useAuthUser } from "store";
import { extractFetchedData } from "utils";

export const emptyData = {
  "1": { month: "Jan", totalSales: 0, totalSalesCount: 0, identifier: "1" },
  "2": { month: "Feb", totalSales: 0, totalSalesCount: 0, identifier: "2" },
  "3": { month: "Mar", totalSales: 0, totalSalesCount: 0, identifier: "3" },
  "4": { month: "Apr", totalSales: 0, totalSalesCount: 0, identifier: "4" },
  "5": { month: "May", totalSales: 0, totalSalesCount: 0, identifier: "5" },
  "6": { month: "Jun", totalSales: 0, totalSalesCount: 0, identifier: "6" },
  "7": { month: "Jul", totalSales: 0, totalSalesCount: 0, identifier: "7" },
  "8": { month: "Aug", totalSales: 0, totalSalesCount: 0, identifier: "8" },
  "9": { month: "Sept", totalSales: 0, totalSalesCount: 0, identifier: "9" },
  "10": { month: "Oct", totalSales: 0, totalSalesCount: 0, identifier: "10" },
  "11": { month: "Nov", totalSales: 0, totalSalesCount: 0, identifier: "11" },
  "12": { month: "Dec", totalSales: 0, totalSalesCount: 0, identifier: "12" },
};

export const useGetTotalSalesHook = () => {
  const { userData } = useAuthUser();

  const [result] = useGetTotalSales({
    businessId: userData?.businessId as string,
  });

  const { extractedData } = extractFetchedData<ITotalSales[]>({
    result,
    key: "getSaleBreakdown",
    defaultReturn: [],
  });

  const aggregateLoanData = useMemo(() => {
    if (!extractedData.length) {
      return Object.values(emptyData);
    }
    const newData = { ...emptyData };

    extractedData.forEach((data) => {
      const { identifier } = data;
      const value = (newData as Record<string, any>)[identifier];

      if (value) {
        (newData as Record<string, any>)[identifier] = {
          ...value,
          ...data,
        };
      }
    });

    return Object.values(newData);
  }, [extractedData]);

  return aggregateLoanData;
};
