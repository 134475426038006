import styled from "styled-components";
import { FlexColumn, FlexJAMixin, stylingUtils } from "styles";

const {
  rem,
  mediaQuery: { down },
} = stylingUtils;

export const SummaryCardsActionContainer = styled.div`
  display: flex;
  align-items: center;
  color: white;
  width: 100%;
  gap: 1rem;
  ${down("laptop")} {
    flex-wrap: wrap;
  }
`;

export const StyledIconWrapper = styled("div")<{
  color?: string;
  wrapperWidth?: any;
  wrapperHeight?: any;
  svgWidth?: any;
  svgHeight?: any;
  borderRadius?: any;
}>`
  height: ${({ wrapperHeight }) => wrapperHeight || rem(40)};
  width: ${({ wrapperWidth }) => wrapperWidth || rem(40)};
  ${FlexJAMixin};
  border-radius: ${({ borderRadius }) => borderRadius || rem(5)};
  background: ${({ color }) => color || "#F1F1F1"};
  svg {
    width: ${({ svgWidth }) => svgWidth || rem(20)};
    height: ${({ svgHeight }) => svgHeight || rem(20)};
  }
`;

export const StyledCardAction = styled("button")`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 17px;
  color: #0077ff;
  border: 1px solid #2d77f1;
  border-radius: 10px;
  padding: 0.5rem;
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  &:hover {
    background-color: #2d77f1;
    color: #fff;
  }
`;
export const StyledCardActionAlt = styled("button")`
  display: flex;
  align-items: center;
  gap: ${rem(5)};
  font-size: 14px;
  p {
    text-align: center;
    color: #0077ff;
  }

  svg {
    width: ${rem(10)}!important;
    height: ${rem(10)}!important;
    transform: rotate(-90deg);
    stroke: #0077ff;
  }
`;

export const StyledWrapper = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  width: 100%;
  background: #001833;
  flex: 1 0 370px;
  height: 166px;
  border-radius: 0.8rem;
`;

export const StyledPOS = styled("img")`
  height: 250px;
  width: 40%;
  transform: rotate(17deg);
  margin-top: 9rem;
  margin-right: 2rem;
`;

export const StyledRight = styled("section")`
  ${FlexColumn};
  gap: ${rem(25)};
  align-items: flex-start;
  p {
    margin-left: 10px;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #f8fcff;
    width: 10rem;
  }
  .relative {
    position: relative;
  }

  .icon {
    position: absolute;
    transform: rotate(-90deg);
    right: -8px;
    align-self: center;
    width: ${rem(10)}!important;
    height: ${rem(10)}!important;
  }

  .icon path {
    fill: white;
  }
`;
