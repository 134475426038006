import { VStyledSummaryCard, CardSection } from "./styles";
import { CardProps } from "../types";

export const VSummaryCard = ({ card }: { card: CardProps }) => {
  return (
    <VStyledSummaryCard>
      {card?.title && (
        <CardSection>
          {card?.icon && <card.icon />}
          <h4>{card.title}</h4>
        </CardSection>
      )}
      {card?.status ? (
        <CardSection jspace="space-between">
          <h3 style={{ padding: 0 }}> {card.amount}</h3>
          {card.secondaryAction}
        </CardSection>
      ) : (
        card?.amount && <h3> {card.amount}</h3>
      )}
      {card?.label && <h4>{card.label}</h4>}

      {card.percentage && (
        <CardSection>
          <div className="metric">{card.metric && <card.metric />}</div>
          <p>{card.percentage}</p>
        </CardSection>
      )}
      {card?.action && card.action}
    </VStyledSummaryCard>
  );
};

export default VSummaryCard;
