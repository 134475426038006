import { VButton } from "components/common";
import { SelectedPosStyles, LeftContainer, RightContainer } from "./styles";
import MinusIcon from "assets/images/svgs/MinusIcon";
import Plus from "assets/images/svgs/Plus";
import { formatNumberToCurrency } from "utils";
import { useEffect, useState } from "react";
import { useRequestPosHook } from "hooks";
import PosOrderDetails from "../pos-order-details/pos-order-details";
import { useModal } from "hooks";
import { useLocation } from "react-router-dom";
import PosErrorModal from "components/pos/pos-error-modal/pos-error-modal";
import blueWallet from "assets/images/pngs/blue-wallet.png";
import { APP_ROUTES } from "routes";
import { useNavigate } from "react-router-dom";
import { useGetAccountDetail } from "api/account/get-account-details";
import ValidateTransactionPinModal from "components/account/validate-transaction-pin-modal";

const SelectedPos = () => {
  const [counter, setCounter] = useState(1);
  const [transactionPin, setTransactionPin] = useState("");
  const [terminalName, setTerminalName] = useState("");
  const { open, toggleModal } = useModal();
  const location = useLocation();
  const navigate = useNavigate();
  const { open: openPasscodeModal, toggleModal: togglePasscodeModal } =
    useModal();

  const { state } = location;

  const {
    openPosDetailsModal,
    togglePosDetailsModal,
    onTerminalRequest,
    isLoading,
    handleSubmit,
    orderId,
  } = useRequestPosHook(state?.selectedPos?._id, counter, transactionPin);

  const [result] = useGetAccountDetail();

  const wallet = result?.data?.getAccountDetail?.wallets;

  const accountBalance = wallet
    ?.filter((wallet: { type: string }) => wallet?.type === "main")
    .map((wallet: { balance: any }) => wallet.balance);

  const getTerminalType = () => {
    if (state && state?.selectedPos?.name === "Veedez Mini") {
      return "MPOS Terminal";
    } else if (state && state?.selectedPos?.name === "Veedez Maxi") {
      return "Android POS Terminal";
    } else {
      return "";
    }
  };

  useEffect(() => {
    const terminalType = getTerminalType();
    setTerminalName(terminalType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const increment = () => {
    setCounter(counter + 1);
  };

  const decrement = () => {
    if (counter > 1) {
      setCounter(counter - 1);
    }
  };
  const livePrice = formatNumberToCurrency({
    number: state?.selectedPos?.price * counter,
  });

  const purchaseDetails = {
    posName: terminalName,
    quantity: counter,
    amount: state?.selectedPos?.price * counter,
    deliveryAddress: state?.addressData,
    orderId,
  };

  const userAccountBalance = Number(accountBalance?.[0]) / 100;
  const totalCost = state?.selectedPos?.price * counter;

  const sufficientFunds = accountBalance && userAccountBalance >= totalCost;

  const handleInsufficientFunds = (e: any) => {
    e.preventDefault();
    toggleModal();
  };

  const handleTransactionPinChange = (pin: string) => {
    setTransactionPin(pin);
  };

  const toggle = (e?: any) => {
    if (e) {
      e.preventDefault();
    }

    togglePasscodeModal();
  };

  return (
    <>
      <SelectedPosStyles>
        <LeftContainer>
          <h3>
            You’ve selected {counter} {counter > 1 ? "Items" : "Item"}
          </h3>
          <div className="item-flex">
            <div className="item">
              <img src={state?.selectedPos?.imageUrl} alt="" />
              <div className="details">
                <h4>{terminalName}</h4>
                <p>
                  {formatNumberToCurrency({
                    number: state?.selectedPos?.price,
                  })}
                </p>
                <p
                  className="remove"
                  onClick={() => navigate(APP_ROUTES.REQUEST_POS)}
                >
                  Remove
                </p>
              </div>
            </div>
            <div className="quantity">
              <div className="counter">
                <div className="icon-div" onClick={decrement}>
                  <MinusIcon
                    style={{ fill: counter > 1 ? "#1A51A3" : "#8991AC" }}
                  />
                </div>
                <div className="number">{counter}</div>
                <div className="icon-div" onClick={increment}>
                  <Plus />
                </div>
              </div>
              <div className="price">{livePrice}</div>
            </div>
          </div>
          <hr />
        </LeftContainer>
        <RightContainer>
          <form
            id="requestForm"
            onSubmit={sufficientFunds ? toggle : handleInsufficientFunds}
          >
            <div className="card">
              <h5>Payment Summary</h5>
              <h4>Order Summary</h4>
              <div className="items">
                <div className="item">
                  <p>
                    {counter} Total {counter > 1 ? "Items" : "Item"}
                  </p>
                  <p>{livePrice}</p>
                </div>
              </div>
              <hr />
              <div className="total">
                <div>
                  <h4>Order Total</h4>
                  <span>(inclusive all taxes)</span>
                </div>
                <p>{livePrice}</p>
              </div>
            </div>
          </form>
          <VButton
            text={`Buy now for ${livePrice}`}
            height="50px"
            width="100%"
            isLoading={isLoading}
            type={"submit"}
            form="requestForm"
          />
        </RightContainer>
      </SelectedPosStyles>
      <PosOrderDetails
        open={openPosDetailsModal}
        close={togglePosDetailsModal}
        purchaseData={purchaseDetails}
      />
      <PosErrorModal
        open={open}
        close={toggleModal}
        imageSrc={blueWallet}
        imageAlt={"blue wallet"}
        title={"Insufficient Funds"}
        subTitle={
          "Your Veedez wallet could not be charged. Please fund your wallet and try again."
        }
        buttonText={"Fund Card"}
        // buttonDisabled={true}
        buttonLink={APP_ROUTES.ACCOUNT}
      />
      {openPasscodeModal && (
        <ValidateTransactionPinModal
          open={openPasscodeModal}
          close={togglePasscodeModal}
          btnText={"Submit"}
          subtitle={"Enter your passcode to continue"}
          onTransactionPinChange={handleTransactionPinChange}
          onSubmitWithPasscode={handleSubmit(onTerminalRequest)}
        />
      )}
    </>
  );
};

export default SelectedPos;
