import LocationColoredIcon from "assets/images/svgs/LocationColoredIcon";
import {
  getShortDateFormat,
  formatNumberToCurrency,
  formatUnderScore,
} from "utils";
import { ITEM_TABLE_HEADINGS } from "../constants";
import {
  StyledCustomerTransactionsHeader,
  TableContainer,
  Table,
} from "./styles";

const CustomerDetailsTransactions = ({
  salesError,
  customerSales,
  businessAddress,
}: any) => {
  const Message = ({ message }: any) => (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <h4 style={{ textAlign: "center" }}>{message}</h4>
    </div>
  );

  return (
    <>
      <StyledCustomerTransactionsHeader>
        <h3>Recent Activities</h3>
        <div className="location-details">
          <LocationColoredIcon height={20} width={20} />
          <p>
            Location: <span>{businessAddress || "N/A"}</span>
          </p>
        </div>
      </StyledCustomerTransactionsHeader>

      {!!salesError && (
        <Message message="An error occurred while getting recent transactions" />
      )}
      {!salesError && customerSales?.length > 0 ? (
        <TableContainer>
          <Table>
            <thead>
              <tr>
                {ITEM_TABLE_HEADINGS.map((item: string, i: number) => (
                  <th key={i}>{item}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {customerSales?.map((item: any, i: number) => (
                <tr key={item._id}>
                  <td>{getShortDateFormat(item.saleDate)}</td>
                  <td>
                    {item.saleItems.length > 0 &&
                      item.saleItems?.length +
                        " " +
                        item.saleItems[0]?.customerProductName}
                  </td>
                  <td>
                    {formatNumberToCurrency({
                      number: item.salePayments[0]?.paidAmount,
                    })}
                  </td>
                  <td>{formatUnderScore(item.salePayments[0]?.paymentType)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </TableContainer>
      ) : (
        <Message message="No recent Transactions" />
      )}
    </>
  );
};

export default CustomerDetailsTransactions;
