import { showToast } from "components/common";
import { gql, useMutation } from "urql";
import { handleRequestCall } from "utils/handleRequestCall";

const DELETE_INVOICE_MUTATION = gql`
  mutation ($invoiceId: String) {
    deleteBusinessInvoice(invoiceId: $invoiceId) {
      success
    }
  }
`;

export const useDeleteInvoiceHook = () => {
  const [deletedInvoice, deleteInvoice] = useMutation(DELETE_INVOICE_MUTATION);

  const handleDeleteInovice = async (
    invoiceId: string,
    callBack?: (data: any) => void
  ) => {
    try {
      const req = await deleteInvoice({ invoiceId });

      await handleRequestCall({
        request: req,
        callBack,
      });
    } catch (e) {
      showToast({
        type: "error",
        title: "An error occurred",
        subText: "Error deleting invoice. Please try again",
      });
    }
  };

  return {
    handleDeleteInovice,
    isLoading: deletedInvoice?.fetching,
  };
};
