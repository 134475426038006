const Logo = () => {
  return (
    <svg
      width="130"
      height="29"
      viewBox="0 0 130 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9273 17.5833L10.0778 28.146C9.97976 28.0726 9.90435 27.973 9.8602 27.8588L0.0373481 0.875317C0.0027034 0.777342 -0.00805344 0.672512 0.00597652 0.569544C0.0200065 0.466575 0.0584273 0.368443 0.118022 0.283308C0.177616 0.198174 0.256673 0.128498 0.34862 0.0800709C0.440566 0.0316441 0.542734 0.00586588 0.646649 0.00488281H7.06176C7.1957 0.0061542 7.326 0.0485981 7.435 0.126454C7.544 0.20431 7.62641 0.31381 7.67106 0.4401L13.9273 17.5833Z"
        fill="#007BFF"
      />
      <path
        d="M24.8889 8.85863L17.9711 27.8624C17.9285 27.9763 17.8527 28.0748 17.7535 28.1453L13.9258 17.5847L15.0639 14.4359V14.4251C15.7532 12.5359 17.0067 10.9044 18.6543 9.75141C20.302 8.59848 22.2643 7.9799 24.2752 7.97949C24.3806 7.97928 24.4844 8.00456 24.5778 8.05317C24.6713 8.10178 24.7516 8.17229 24.8119 8.25866C24.8722 8.34503 24.9107 8.44471 24.9241 8.54919C24.9375 8.65366 24.9254 8.75983 24.8889 8.85863Z"
        fill="#F0A75A"
      />
      <path
        d="M23.6628 12.2314L18.8928 25.338H18.8906L23.6628 12.2314Z"
        fill="#F0A75A"
      />
      <path
        d="M22.875 7.34086C24.8965 7.34086 26.5352 5.70214 26.5352 3.68068C26.5352 1.65922 24.8965 0.0205078 22.875 0.0205078C20.8536 0.0205078 19.2148 1.65922 19.2148 3.68068C19.2148 5.70214 20.8536 7.34086 22.875 7.34086Z"
        fill="#E57A1A"
      />
      <path
        d="M17.7602 28.1465C17.6473 28.2392 17.5058 28.29 17.3597 28.2901H10.4724C10.3279 28.289 10.1879 28.24 10.0742 28.1508L13.9237 17.5859L17.7602 28.1465Z"
        fill="#E57A1A"
      />
      <path
        d="M84.6451 4.18244V9.62265C83.123 8.68997 81.3799 8.17985 79.5951 8.14484C77.8103 8.10983 76.0484 8.55118 74.4909 9.42345C72.9334 10.2957 71.6366 11.5674 70.7339 13.1075C69.8313 14.6476 69.3555 16.4004 69.3555 18.1855C69.3555 19.9707 69.8313 21.7235 70.7339 23.2636C71.6366 24.8037 72.9334 26.0754 74.4909 26.9476C76.0484 27.8199 77.8103 28.2612 79.5951 28.2262C81.3799 28.1912 83.123 27.6811 84.6451 26.7484V27.6493C84.6451 27.8132 84.7102 27.9704 84.8261 28.0863C84.942 28.2022 85.0992 28.2673 85.2631 28.2673H88.8297C88.9933 28.2668 89.1499 28.2014 89.2653 28.0856C89.3808 27.9697 89.4456 27.8129 89.4456 27.6493V0.615833C89.4456 0.452504 89.3807 0.295864 89.2652 0.180374C89.1497 0.0648827 88.9931 5.66157e-07 88.8297 5.66157e-07C88.2803 -0.000285297 87.7362 0.107685 87.2285 0.317744C86.7208 0.527803 86.2595 0.835834 85.8709 1.22424C85.4823 1.61264 85.174 2.0738 84.9637 2.58138C84.7534 3.08896 84.6451 3.63301 84.6451 4.18244ZM79.4029 23.4256C78.366 23.4256 77.3524 23.118 76.4903 22.5419C75.6282 21.9658 74.9563 21.147 74.5596 20.189C74.1629 19.2309 74.0592 18.1768 74.2616 17.1599C74.4641 16.1429 74.9636 15.2089 75.6969 14.4758C76.4303 13.7428 77.3646 13.2437 78.3816 13.0416C79.3986 12.8396 80.4527 12.9437 81.4106 13.3408C82.3684 13.7379 83.187 14.4102 83.7627 15.2725C84.3385 16.1349 84.6456 17.1486 84.6451 18.1855C84.6434 19.5759 84.0899 20.9087 83.1061 21.8912C82.1224 22.8737 80.7889 23.4256 79.3986 23.4256H79.4029Z"
        fill="#007BFF"
      />
      <path
        d="M100.313 8.16122C98.6452 8.30931 97.04 8.87061 95.643 9.79443C94.2459 10.7182 93.101 11.9755 92.3116 13.4526C91.5221 14.9298 91.113 16.5803 91.1212 18.2551C91.1294 19.93 91.5547 21.5764 92.3586 23.0457C93.1625 24.5151 94.3197 25.761 95.7257 26.6711C97.1317 27.5812 98.7423 28.1267 100.412 28.2584C102.082 28.3902 103.758 28.1039 105.289 27.4256C106.821 26.7472 108.159 25.6982 109.183 24.3731C109.234 24.3059 109.27 24.2292 109.29 24.1477C109.31 24.0661 109.313 23.9813 109.299 23.8985C109.285 23.8157 109.255 23.7366 109.209 23.6659C109.164 23.5952 109.105 23.5345 109.035 23.4874C108.225 22.9294 107.285 22.5903 106.306 22.503C105.326 22.4157 104.341 22.5831 103.445 22.9891C102.797 23.2924 102.094 23.4609 101.378 23.4843C100.663 23.5077 99.9506 23.3856 99.2839 23.1253C98.6173 22.8651 98.0105 22.4721 97.5003 21.9702C96.9901 21.4684 96.5872 20.8681 96.316 20.2059H110.678C110.842 20.2059 110.999 20.141 111.114 20.0256C111.23 19.9102 111.296 19.7535 111.296 19.59V18.206C111.296 16.8059 111.005 15.4212 110.44 14.1399C109.876 12.8586 109.051 11.7089 108.018 10.764C106.985 9.81907 105.766 9.09964 104.44 8.65153C103.113 8.20342 101.708 8.03644 100.313 8.16122ZM96.316 16.2127C96.7132 15.2427 97.3901 14.4128 98.2604 13.8287C99.1308 13.2446 100.155 12.9327 101.203 12.9327C102.252 12.9327 103.276 13.2446 104.147 13.8287C105.017 14.4128 105.694 15.2427 106.091 16.2127H96.316Z"
        fill="#007BFF"
      />
      <path
        d="M129.381 8.10254H113.26C113.097 8.10311 112.94 8.16848 112.825 8.28432C112.709 8.40015 112.645 8.55702 112.645 8.72055V12.2871C112.645 12.4507 112.709 12.6075 112.825 12.7234C112.94 12.8392 113.097 12.9046 113.26 12.9052H123.205L112.825 23.2873C112.71 23.4027 112.645 23.5592 112.645 23.7225V27.6503C112.645 27.8138 112.709 27.9707 112.825 28.0865C112.94 28.2024 113.097 28.2677 113.26 28.2683H129.381C129.545 28.2683 129.702 28.2032 129.818 28.0873C129.934 27.9714 129.999 27.8142 129.999 27.6503V24.0859C129.999 23.922 129.934 23.7648 129.818 23.6489C129.702 23.533 129.545 23.4679 129.381 23.4679H119.434L129.816 13.0858C129.932 12.9706 129.998 12.8141 129.999 12.6506V8.7336C130.001 8.65135 129.986 8.56958 129.956 8.49309C129.925 8.4166 129.88 8.34692 129.822 8.28814C129.765 8.22936 129.696 8.18267 129.62 8.1508C129.545 8.11893 129.463 8.10252 129.381 8.10254Z"
        fill="#007BFF"
      />
      <path
        d="M35.0205 8.16045C33.3522 8.30854 31.747 8.86983 30.35 9.79365C28.953 10.7175 27.808 11.9747 27.0186 13.4519C26.2291 14.929 25.82 16.5795 25.8282 18.2544C25.8365 19.9292 26.2617 21.5756 27.0656 23.045C27.8695 24.5143 29.0267 25.7603 30.4327 26.6703C31.8388 27.5804 33.4494 28.126 35.119 28.2577C36.7887 28.3894 38.4649 28.1032 39.9963 27.4248C41.5276 26.7465 42.8659 25.6974 43.8902 24.3723C43.9406 24.3051 43.9769 24.2285 43.9968 24.1469C44.0167 24.0653 44.0198 23.9806 44.006 23.8977C43.9921 23.8149 43.9616 23.7358 43.9163 23.6651C43.8709 23.5945 43.8117 23.5337 43.7423 23.4866C42.9325 22.9286 41.9921 22.5895 41.0126 22.5022C40.0331 22.4149 39.0476 22.5823 38.1519 22.9883C37.5037 23.2916 36.8007 23.4601 36.0854 23.4835C35.3702 23.5069 34.6576 23.3848 33.991 23.1246C33.3243 22.8643 32.7175 22.4713 32.2073 21.9695C31.6972 21.4676 31.2942 20.8673 31.023 20.2051H45.3852C45.5487 20.2051 45.7056 20.1403 45.8214 20.0248C45.9373 19.9094 46.0026 19.7528 46.0032 19.5892V18.2053C46.0032 16.806 45.712 15.422 45.1481 14.1413C44.5842 12.8607 43.76 11.7114 42.7278 10.7667C41.6956 9.82188 40.4781 9.10226 39.1527 8.65357C37.8273 8.20488 36.423 8.03695 35.0292 8.16045H35.0205ZM31.023 16.212C31.4202 15.2419 32.0971 14.412 32.9675 13.8279C33.8378 13.2438 34.8623 12.9319 35.9105 12.9319C36.9587 12.9319 37.9832 13.2438 38.8536 13.8279C39.7239 14.412 40.4008 15.2419 40.798 16.212H31.023Z"
        fill="#007BFF"
      />
      <path
        d="M56.79 8.15947C55.1217 8.30755 53.5166 8.86885 52.1195 9.79267C50.7225 10.7165 49.5775 11.9737 48.7881 13.4509C47.9987 14.928 47.5896 16.5785 47.5978 18.2534C47.606 19.9282 48.0312 21.5747 48.8351 23.044C49.639 24.5133 50.7962 25.7593 52.2023 26.6694C53.6083 27.5794 55.2189 28.125 56.8886 28.2567C58.5583 28.3884 60.2344 28.1022 61.7658 27.4238C63.2971 26.7455 64.6354 25.6964 65.6598 24.3713C65.7108 24.3044 65.7475 24.2277 65.7678 24.1461C65.788 24.0644 65.7913 23.9794 65.7774 23.8964C65.7636 23.8134 65.7328 23.7342 65.6871 23.6635C65.6415 23.5929 65.5818 23.5323 65.5118 23.4856C64.7024 22.9277 63.7624 22.5885 62.7833 22.5012C61.8041 22.4139 60.8189 22.5813 59.9236 22.9873C59.2754 23.2906 58.5724 23.4591 57.8571 23.4825C57.1419 23.506 56.4293 23.3838 55.7627 23.1236C55.096 22.8633 54.4892 22.4703 53.979 21.9685C53.4689 21.4666 53.0659 20.8664 52.7947 20.2041H67.1569C67.3204 20.2041 67.4773 20.1393 67.5931 20.0239C67.709 19.9084 67.7743 19.7518 67.7749 19.5883V18.2043C67.7751 16.804 67.4836 15.419 66.919 14.1376C66.3545 12.8561 65.5292 11.7063 64.4958 10.7614C63.4625 9.81641 62.2436 9.09704 60.9169 8.64907C59.5902 8.20111 58.1847 8.03436 56.79 8.15947ZM52.7947 16.211C53.1919 15.241 53.8688 14.4111 54.7392 13.8269C55.6095 13.2428 56.634 12.9309 57.6822 12.9309C58.7304 12.9309 59.7549 13.2428 60.6253 13.8269C61.4956 14.4111 62.1725 15.241 62.5697 16.211H52.7947Z"
        fill="#007BFF"
      />
    </svg>
  );
};
export default Logo;
