import { STAFF_SUMMARY_CARDS_DATA } from "components/staff/constants";
import useGetStaffSummaryHook from "hooks/queryHooks/staffs/useGetStaffSummaryHook";
import { useMemo } from "react";

const useStaffSummaryCardData = () => {
  const { extractedData: staffSummaryCards } = useGetStaffSummaryHook();

  const summaryCardRef = JSON.stringify(staffSummaryCards);

  const cardData = useMemo(() => {
    return STAFF_SUMMARY_CARDS_DATA(staffSummaryCards);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [summaryCardRef]);

  return {
    cardData,
  };
};

export default useStaffSummaryCardData;
