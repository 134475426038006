import { BeneficiaryTypes } from "./../../../api/account/useAddBusinessBeneficiary";
import { IBeneficiery, useGetBusinessBeneficiaries } from "api";
import { useState } from "react";

export const useHandleBeneficiaries = ({
  type,
}: {
  type: BeneficiaryTypes | undefined;
}) => {
  const {
    data: { extractedData },
  } = useGetBusinessBeneficiaries(type);

  const beneficiaries = extractedData?.nodes || [];
  const [selectedBeneficiary, setSelectedBeneficiary] =
    useState<IBeneficiery | null>(null);

  const handleSelectBeneficiary = (beneficiary: IBeneficiery) => {
    const isTheSameBeneficiary =
      JSON.stringify(beneficiary) === JSON.stringify(selectedBeneficiary);

    if (isTheSameBeneficiary) {
      return setSelectedBeneficiary(null);
    }
    setSelectedBeneficiary(beneficiary);
  };

  return {
    beneficiaries,
    selectedBeneficiary,
    handleSelectBeneficiary,
  };
};
