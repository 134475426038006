import styled from "styled-components/macro";
import { stylingUtils } from "styles";

const {
  color,
  rem,
  mediaQuery: { down },
} = stylingUtils;

export const StyledRolesAndPermissionTable = styled("div")`
  background-color: ${color("white")};
  padding: ${rem(20)} ${rem(20)} ${rem(60)};
  border-radius: ${rem(10)};

  ${down("laptop")} {
    padding: 0;
  }
`;
