import { showToast } from "./../../components/common/VToast/index";
import { gql, useMutation } from "urql";
import { handleRequestCall } from "utils/handleRequestCall";

export interface ICustomerTerminalSettings {
  terminalRequestId: string;
  isActive: boolean;
  terminalChargeBy: any;
  staffId: string;
}

const updateCustomerTerminalMutation = gql`
  mutation (
    $terminalRequestId: String
    $staffId: String
    $isActive: Boolean
    $terminalChargeBy: ChargeByType
  ) {
    updateCustomerTerminal(
      terminalRequestId: $terminalRequestId
      staffId: $staffId
      isActive: $isActive
      terminalChargeBy: $terminalChargeBy
    ) {
      success
    }
  }
`;

export const useUpdateCustomerTerminal = () => {
  const [customerTerminal, updateCustomerTerminal] = useMutation(
    updateCustomerTerminalMutation
  );

  const handleUpdateCustomerTerminal = async (
    data: ICustomerTerminalSettings,
    callBack?: (data: Record<string, any>) => void
  ) => {
    try {
      const req = await updateCustomerTerminal(data);

      await handleRequestCall({ request: req, callBack });
    } catch (e) {
      showToast({
        type: "error",
        title: "An error occured while updating POS settings",
        subText: "Please try again",
      });
      console.error(e);
    }
  };

  return {
    handleUpdateCustomerTerminal,
    customerTerminal,
    isLoading: customerTerminal?.fetching,
  };
};
