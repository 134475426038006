import { lazy } from "react";
export { default as OauthFramePage } from "./oauth-iframe-page";
export { default as OauthCallBackPage } from "./oauth-callback-page";

export const SignIn = lazy(() => import("./sign-in"));
export const BusinessRegistrationPage = lazy(
  () => import("./business-registration-page")
);

export const ResetPasscodePage = lazy(() => import("./reset-passcode"));
