// Core
import { gql, useMutation } from "urql";
import { handleRequestCall } from "utils/handleRequestCall";
import { showToast } from "components/common";

export type BeneficiaryTypes =
  | "DATA"
  | "AIRTIME"
  | "ELECTRICITY"
  | "INTERNET_SUBSCRIPTION"
  | "BETTING"
  | "TV_SUBSCRIPTION"
  | "INTERNAL";

// GraphQL
const addBeneficiaryMutation = gql`
  mutation (
    $name: String
    $type: BeneficieryType
    $code: String
    $detail: JSONObject
  ) {
    addBusinessBeneficieries(
      name: $name
      type: $type
      code: $code
      detail: $detail
    ) {
      success
      message
    }
  }
`;

interface IBeneficiery {
  name: String | any;
  type: BeneficiaryTypes;
  code: String | null;
  detail: { [key: string]: any };
}

export const useAddBusinessBeneficiary = () => {
  const [beneficiaryResponse, initiateAddBeneficiary] = useMutation(
    addBeneficiaryMutation
  );

  // destructure refetch transaction listings here when api is ready

  const handleAddBeneficiary = async (
    data: IBeneficiery,
    callBack?: () => void
  ) => {
    try {
      const req = await initiateAddBeneficiary(data);

      await handleRequestCall({ request: req, callBack });
    } catch (e) {
      showToast({
        type: "error",
        title: "An error occurred",
        subText: "Please try again",
      });
    }
  };

  return {
    handleAddBeneficiary,
    beneficiaryResponse,
  };
};
