import { useVeedezApiRequest } from "./../../hooks/useApiRequest";

// Custom Components
import { showToast } from "components/common";

// Utils
import { VALUE_ADDED_SERVICES_ROUTES } from "api/api.routes";
import { useState, useCallback } from "react";

// Functionality
export const useResolveAccountName = (cableProvider: string) => {
  const makeRequest = useVeedezApiRequest();
  const [smartCardNumber, setSmartCardNumber] = useState<number | null>(null);
  const [accountName, setAccountName] = useState<string>("");

  const resolveAccountName = useCallback(async () => {
    try {
      const { data } = await makeRequest.get(
        `${VALUE_ADDED_SERVICES_ROUTES.RESOLVE_PROVIDER_ACCOUNT_NAME}?provider=${cableProvider}&smartCardNumber=${smartCardNumber}`
      );
      setAccountName(data.data);
    } catch (e) {
      showToast({
        type: "error",
        title: "An error occurred",
        subText: "Please try again",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cableProvider, smartCardNumber]);

  return {
    resolveAccountName,
    accountName,
    setSmartCardNumber,
  };
};
