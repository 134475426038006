import {
  showToast,
  VChip,
  VLoader,
  VProgressIndicator,
} from "components/common";
import {
  StyledBillingAction,
  StyledBillingAmount,
  StyledBillingPlanCard,
  StyledBillingPlanHeader,
  StyledPlanType,
} from "./styles";
import { ReactComponent as ChevronRight } from "assets/images/svgs/chevron-left.svg";
import { Link } from "react-router-dom";
import { APP_ROUTES } from "routes";
import { useBillingPlanCardHook } from "hooks";
import { IGetBusinessPlans } from "api/manage-business/billing";
import { formatUnderScore, hardRefresh } from "utils";
import { BUSINESS_PLANS } from "components/auth/signup/constants";

export const BillingPlan = () => {
  const {
    planSubtitle,
    businessConstants,
    userBusinessPlan,
    handleUpgradeBusinessPlan,
    convertedPrice,
    business,
    fetchingBusinessPlan,
    nextBusinessPlan,
  } = useBillingPlanCardHook();

  const {
    isFreeBusinessPlan,
    totalDays,
    daysUsedOnPlan,
    daysUsedPercentage,
    hasNextBusinessPlanHasFree,
    hasDowngraded,
    nextFormattedPaymentDate,
  } = businessConstants;

  const { name = "Basic Plan" } = (userBusinessPlan || {}) as IGetBusinessPlans;

  return (
    <StyledBillingPlanCard>
      {fetchingBusinessPlan ? (
        <VLoader style={{ height: "100px" }} />
      ) : (
        <>
          <StyledBillingPlanHeader>
            <StyledPlanType>
              <p className="billing__summary">
                <span>Billing</span>
                <span>{formatUnderScore(name)}</span>
                <VChip key="free" label={planSubtitle} isActive isSlim />
              </p>
              <p className="billing__subtitle">
                Manage your billing and payment details
              </p>
            </StyledPlanType>
            <StyledBillingAction
              className="warning"
              onClick={() => {
                handleUpgradeBusinessPlan({
                  data: {
                    businessPlan: BUSINESS_PLANS.BASIC,
                    businessId: business?._id ?? "",
                  },
                  callBack: (data) => {
                    const extractedData = data?.upgradeBusinessPlan;
                    if (
                      extractedData?.status?.toLowerCase().includes("success")
                    ) {
                      showToast({
                        title: "Successfully cancelled plan",
                      });
                      hardRefresh();
                    }
                  },
                });
              }}
            >
              Cancel Plan
            </StyledBillingAction>
          </StyledBillingPlanHeader>
          <StyledBillingAmount>
            <h3>
              <span>{convertedPrice}</span>
              <span>/ month</span>
            </h3>
            {!isFreeBusinessPlan && (
              <div>
                <VProgressIndicator progress={daysUsedPercentage} />
                <p className="plan-days">
                  Your plan (days): {daysUsedOnPlan} / {totalDays}
                </p>
                {hasDowngraded && (
                  <p className="plan-days warning">
                    {hasNextBusinessPlanHasFree
                      ? `Your plan has been cancelled.`
                      : `Your plan has been downgradded to ${formatUnderScore(
                          nextBusinessPlan?.name
                        )}.`}
                    . You will stop enjoying your current plan by{" "}
                    {nextFormattedPaymentDate}
                  </p>
                )}
              </div>
            )}
          </StyledBillingAmount>
          <Link to={APP_ROUTES.CHANGE_BILLING_PLAN}>
            <StyledBillingAction className="normal">
              <span>Change Plan</span>
              <ChevronRight
                style={{
                  transform: "rotate(180deg)",
                  height: "12px",
                  stroke: "#0077FF",
                }}
              />
            </StyledBillingAction>
          </Link>
        </>
      )}
    </StyledBillingPlanCard>
  );
};
