import { RadioProps } from "../types";
import { Label } from "../VRadio/styles";
import { StyledVCheckBoxSecondary } from "./styles";

export const VCheckBoxSecondary = ({
  label,
  id,
  value,
  onClick,
  changed,
  fontSize = "",
  isSelected,
  isReverse = false,
  icon,
  ...rest
}: RadioProps & {
  fontSize?: string;
}) => {
  return (
    <>
      <StyledVCheckBoxSecondary
        className="cntr"
        isReverse={isReverse}
        htmlFor={id}
        fontSize={fontSize}
        disabled={rest?.disabled ?? false}
      >
        <label className="container">
          <input
            className="checked"
            type="checkbox"
            id={id}
            value={value}
            onChange={changed}
            onClick={onClick}
            checked={isSelected}
            {...rest}
          />
          <div className="checkmark"></div>
        </label>
        <Label htmlFor={id}>{label}</Label>
        {icon && icon}
      </StyledVCheckBoxSecondary>
    </>
  );
};
