import { StyledExpenseTable } from "./styles";
import { useExpensePageHook } from "hooks";
import { ExpenseDetails } from "../expense-details";
import { IBusinessExpenseNode } from "api";
import { VEmptyState, VPagination, VTable } from "components/common";
import { EditExpenseModal } from "../edit-expense-modal";
import { DeleteExpenseModal } from "../delete-expense-modal";
import { ViewExpenseDetails } from "../view-expese-details";

const ExpenseTable = () => {
  const {
    error,
    extractedData,
    pageNumber,
    handleChangePage,
    fetching,
    expenseColumn,
    selectedExpense,
    viewModalState,
    handleCloseViewModal,
    handleCloseEditModal,
    editModalState,
    deleteModalState,
    handleCloseDeleteModal,
    handleCloseOnSubmit,
    createdExpense,
    shouldOpenViewExpenseModal,
  } = useExpensePageHook();

  if (error)
    return (
      <VEmptyState
        title="Error Fetching Data!"
        subText="An error occur getting data"
      />
    );

  const { nodes: businessExpense, pageInfo } = extractedData;

  return (
    <>
      <StyledExpenseTable>
        <VTable
          columns={expenseColumn}
          data={businessExpense}
          isLoading={fetching}
          emptyContent={{
            title: "No Expense Added Yet!",
            subText: "Your history will appear here when you have one",
          }}
        />
        <VPagination
          pageInfo={pageInfo}
          pageNumber={pageNumber}
          getPageNumber={(pageNumber) => handleChangePage(pageNumber)}
        />
      </StyledExpenseTable>

      <ExpenseDetails
        open={viewModalState}
        close={handleCloseViewModal}
        expense={selectedExpense as IBusinessExpenseNode}
      />
      <EditExpenseModal
        open={editModalState}
        close={handleCloseEditModal}
        expense={selectedExpense as IBusinessExpenseNode}
      />
      <DeleteExpenseModal
        open={deleteModalState}
        close={handleCloseDeleteModal}
        expense={selectedExpense as IBusinessExpenseNode}
      />
      <ViewExpenseDetails
        open={shouldOpenViewExpenseModal}
        close={handleCloseOnSubmit}
        expense={createdExpense}
      />
    </>
  );
};

export default ExpenseTable;
