import styled from "styled-components";
import { FlexJAMixin, stylingUtils } from "styles";

const { rem } = stylingUtils;

export const Status = styled("div")`
  display: flex;
  justify-content: space-between;

  li {
    padding: ${rem(5)};
    background-color: #fff7ee;
    border-radius: 30px;
    color: #f0a75a;
    width: max-content;
    display: list-item;
    font-family: "Gilmer";
    font-weight: 400;
    line-height: 17px;
  }
  span {
    position: relative;
    left: ${rem(-5)};
  }
`;

export const StyledInvoiceIconWrapper = styled("div")<{ color: string }>`
  height: ${rem(40)};
  width: ${rem(40)};
  ${FlexJAMixin};
  border-radius: ${rem(5)};
  background-color: ${({ color }) => color};

  svg {
    width: ${rem(20)}!important;
    height: ${rem(20)}!important;
  }
`;
