import { gql, useQuery } from "urql";

export const useGetStaffSummaryCards = () => {
  return useQuery({
    query: getStaffSummaryCardsQuery,
  });
};

const getStaffSummaryCardsQuery = gql`
  query {
    getStaffSummaryCards {
      totalStaff
      activeStaffCount
      inactiveStaffCount
      userRoles
      staffActivities {
        identifier
        count
        value
      }
      performers {
        userId
        key
        maxVal
        user {
          firstName
          lastName
        }
      }
    }
  }
`;

export interface IStaffSummaryCard {
  activeStaffCount: number;
  inactiveStaffCount: number;
  totalStaff: number;
  userRoles: number;
}
