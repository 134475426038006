import styled from "styled-components/macro";
import { stylingUtils } from "styles";

const {
  color,
  rem,
  mediaQuery: { down },
} = stylingUtils;

export const EditCustomerFrame = styled.div`
  justify-content: space-between;
  background-color: ${color("white")};
  padding: 2rem;
  border-radius: ${rem(20)};

  h4 {
    font-family: "Gilmer";
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #434343;
    margin-bottom: 1rem;
  }

  .expense__form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 ${rem(20)};
    ${down("tablet")} {
      grid-template-columns: 1fr;
    }
  }

  .blue-text {
    color: #0077ff;
    cursor: pointer;
  }
`;

export const FormTitle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${rem(10)};
  padding-right: ${rem(10)};
  padding-left: 0;

  .title {
    font-family: "Gilmer";
    font-weight: 500;
    font-size: ${rem(15)};
    line-height: ${rem(22)};
    display: flex;
    align-items: center;
    color: #434343;
  }
`;

export const Message = styled("h3")`
  text-align: center;
  margin: ${rem(30)} 0;
  font-weight: 400;
`;
