import { gql, useMutation } from "urql";
import { handleRequestCall } from "utils/handleRequestCall";

export const useUpdateUserPayment = () => {
  const [userPaymentInfo, updateUserPayment] = useMutation(
    updateUserPaymentMutation
  );

  const handleUpdateUserPayment = async ({
    transactionRef,
    callBack,
    erroCallback,
  }: {
    transactionRef: string | number;
    callBack?: (data: any) => void;
    erroCallback?: (data: any) => void;
  }) => {
    try {
      const req = await updateUserPayment({ transactionRef });

      await handleRequestCall({
        request: req,
        callBack,
        erroCallback,
      });
    } catch (e) {}
  };

  return {
    handleUpdateUserPayment,
    isLoading: userPaymentInfo?.fetching,
  };
};

const updateUserPaymentMutation = gql`
  mutation ($transactionRef: String) {
    updateUserPayment(transactionRef: $transactionRef) {
      success
      data
    }
  }
`;
