import styled from "styled-components/macro";
import { FlexColumn, stylingUtils } from "styles";

const {
  rem,
  mediaQuery: { down },
} = stylingUtils;

export const StyledBillingOverviewContainer = styled("div")`
  ${FlexColumn};
  gap: ${rem(35)};
`;
export const StyledBillingOverviewTopSection = styled("div")`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${rem(20)};
  ${down("laptop")} {
    grid-template-columns: 1fr;
  }
`;
