import { VButton, VSelect, VTable } from "components/common";
import { BillingCheckoutCard } from "components/manage-business";
import { useDashboardHook } from "hooks";
import {
  MOST_FREQUENT_CUSTOMERS_COLUMN,
  SECONDARY_DURATION_INPUT_LISTS,
} from "./constants";
const FrequentCustomersTable = ({
  frequentCustomers,
}: {
  frequentCustomers: ReturnType<typeof useDashboardHook>["frequentCustomers"];
}) => {
  const {
    extractedData,
    fetching,
    frequentCustomersDateRange,
    setFrequentCustomersDateRange,
  } = frequentCustomers;

  return (
    <BillingCheckoutCard
      title={"Most Frequent Customers"}
      titleFontSize="14px"
      action={
        <VSelect
          inputWrapperStyle={{
            height: "35px",
          }}
          isSlim
          name="duration"
          optionValue={frequentCustomersDateRange}
          options={SECONDARY_DURATION_INPUT_LISTS}
          onChange={(value: string) => {
            setFrequentCustomersDateRange(value);
          }}
        />
      }
    >
      <VTable
        smallPadding
        isLoading={fetching}
        columns={MOST_FREQUENT_CUSTOMERS_COLUMN}
        data={extractedData.slice(0, 10) || []}
        emptyContent={{
          title: "No Frequent Customers Yet!",
          style: {
            height: "200px",
          },
          children: (
            <VButton disabled text="Add Customers" isOutline noborder={false} />
          ),
        }}
      />
    </BillingCheckoutCard>
  );
};

export default FrequentCustomersTable;
