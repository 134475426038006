import { CreatedAtType } from "./../types";
import { useAuthUser } from "store";
import { gql, useQuery } from "urql";
import { extractFetchedData } from "utils";

type UseGetTopSellingProductsProps = {
  businessId: string;
  dateRange?: number;
  type?: string;
} & CreatedAtType;

export const useGetTopSellingProducts = ({
  businessId,
  dateRange,
  createdAt,
  type,
}: UseGetTopSellingProductsProps) => {
  return useQuery({
    query: getTopSellingProductsQuery,
    variables: { businessId, dateRange, createdAt, type },
  });
};

export const useGetTopSellingProductsHook = ({
  createdAt,
  type,
}: { type?: string } & Omit<UseGetTopSellingProductsProps, "businessId">) => {
  const { userData } = useAuthUser();

  const [result, refetchTopSellignProducts] = useGetTopSellingProducts({
    businessId: userData?.businessId as string,
    createdAt,
    type,
  });

  const extract = extractFetchedData<ITopSellingProducts[]>({
    result,
    key: "getTopSellingProducts",
    defaultReturn: [],
  });

  return { extract, refetchTopSellignProducts };
};

export const getTopSellingProductsQuery = gql`
  query (
    $businessId: String
    $dateRange: Int
    $type: SALESUMMARYCARDTYPE
    $createdAt: CreatedAtInputSale
  ) {
    getTopSellingProducts(
      businessId: $businessId
      dateRange: $dateRange
      type: $type
      createdAt: $createdAt
    )
  }
`;
export interface ITopSellingProducts {
  count: number;
  soldItemData: {
    _id: string;
    businessId: string;
    customerProduct: {
      productName: string;
      category: Record<string, any>;
    };
    customerService: {
      serviceName: string;
    };
    customerProductName: string;
    customerServiceName: string;
    productImage: string;
    sellingPrice: string;
    quantitySold: string;
    quantity: number;
  }[];
}
