import { gql, useQuery } from "urql";
import { Sale } from "components/expense-sales/sales/types";
import { SalesDetailAPI, SaleItem } from "api/expense-sales/types";

export const useGetBusinessSalesDetail = ({
  businessSaleId,
}: {
  businessSaleId: string;
}) => {
  return useQuery({
    query: getBusinessSalesDetail,
    variables: { businessSaleId },
  });
};

const getBusinessSalesDetail = gql`
  query ($businessSaleId: String!) {
    getBusinessSaleDetail(businessSaleId: $businessSaleId) {
      _id
      businessCustomerId
      clientName
      user {
        firstName
      }
      salePayments {
        paymentType
        customerId
        paidAmount
      }
      saleItems {
        quantity
        customerProduct {
          category {
            name
          }
          productName
          productImage
        }
        sellingPrice
      }
      saleDate
      referenceId
    }
  }
`;

export const mapFromSalesDetailAPI = (data: SalesDetailAPI): Sale[] => {
  const saleItems: Sale[] = [];

  data.saleItems?.forEach((sale: SaleItem, idx: number) => {
    saleItems.push({
      salesID: data?.referenceId,
      productName: sale?.customerProduct?.productName,
      category: sale?.customerProduct?.category[0]?.name,
      customerName: data?.clientName,
      customerID: data?.businessCustomerId,
      paymentMethod: data?.salePayments[idx]?.paymentType,
      salesBy: data?.user?.firstName,
      amount: sale?.sellingPrice.toString(),
      quantity: sale?.quantity,
      productImage: sale?.customerProduct?.productImage,
      saleDate: new Date(data?.saleDate)?.toLocaleDateString(),
    });
  });

  return saleItems;
};
