import { formatUnderScore } from "utils";
import { VStyledBadge } from "./styles";

const COLOR_SCHEME = {
  RED: {
    color: "#EA4336",
    backgroundColor: "#FFF7EE",
  },
  GREEN: {
    color: "#34A853",
    backgroundColor: "#D2EEDF",
  },
  ORANGE: {
    color: "#F0A75A",
    backgroundColor: "#FFF7EE",
  },
  LIGHTBLUE: {
    color: "#374B58",
    backgroundColor: "#E8E8E8",
  },
};

export const BADGE_COLOR_SCHEME: Record<
  string,
  { color: string; backgroundColor: string }
> = {
  START: COLOR_SCHEME.RED,
  FAILED: COLOR_SCHEME.RED,
  OVERDUE: COLOR_SCHEME.RED,
  INACTIVE: COLOR_SCHEME.RED,
  REJECTED: COLOR_SCHEME.RED,
  PAID: COLOR_SCHEME.GREEN,
  SUCCESS: COLOR_SCHEME.GREEN,
  SUCCESSFUL: COLOR_SCHEME.GREEN,
  COMPLETED: COLOR_SCHEME.GREEN,
  DELIVERED: COLOR_SCHEME.GREEN,
  ACTIVE: COLOR_SCHEME.GREEN,
  REQUESTED: COLOR_SCHEME.GREEN,
  APPROVED: COLOR_SCHEME.GREEN,
  ASSIGNED: COLOR_SCHEME.GREEN,
  DISPATCH: COLOR_SCHEME.GREEN,
  UNPAID: COLOR_SCHEME.ORANGE,
  PROCESS: COLOR_SCHEME.ORANGE,
  IN_PROGESS: COLOR_SCHEME.ORANGE,
  PART_PAYMENT: COLOR_SCHEME.LIGHTBLUE,
};

export const VBadge = ({
  status,
  label,
}: {
  status: string;
  label?: string;
}) => {
  const customStyles =
    BADGE_COLOR_SCHEME[status?.toUpperCase()] ?? BADGE_COLOR_SCHEME.START;
  return (
    <VStyledBadge {...customStyles}>
      <span></span>
      <p>{formatUnderScore(label ?? status)}</p>
    </VStyledBadge>
  );
};
