import {
  IDeleteBusinessExpense,
  IBusinessExpenseNode,
  useDeleteBusinessExpense,
} from "api";
import { useForm } from "react-hook-form";
import { showToast, VButton, VForm, VModal } from "components/common";
import { DELETE_EXPENSE_FORM_INPUT_LIST } from "../constants";
import { StyledDeleteExpense } from "./styles";
import { VModalProps } from "components/common/types";
import { useEffect, useMemo } from "react";
import { useExpensePageHook } from "hooks";

const DeleteExpenseModal = ({
  open,
  close,
  expense,
}: VModalProps & { expense: IBusinessExpenseNode }) => {
  const hookForm = useForm<IDeleteBusinessExpense>();

  const { handleDeleteBusinessExpense, businessExpense, expenseCategories } =
    useDeleteBusinessExpense();

  const { refetchSummaryData } = useExpensePageHook();

  const { handleSubmit, reset } = hookForm;
  const onSubmit = async (data: IDeleteBusinessExpense) => {
    await handleDeleteBusinessExpense(data, () => {
      reset();
      showToast({
        subText: "You have successfully deleted this expense",
        type: "delete",
        title: "Successful",
      });
      refetchSummaryData();
      close();
    });
  };

  const { fetching } = businessExpense;

  const deleteExpenseInputLists = useMemo(() => {
    return DELETE_EXPENSE_FORM_INPUT_LIST();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expenseCategories.length, expense]);

  useEffect(() => {
    if (expense) {
      reset({
        ...expense,
        expenseId: expense._id,
      });
    }
  }, [expense, reset]);
  return (
    <VModal open={open} close={close} width={597}>
      <StyledDeleteExpense>
        <h1>Delete Expense</h1>
        <p>Are you sure you want to delete this expense?</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <VForm
            inputLists={deleteExpenseInputLists}
            hookForm={hookForm}
            vFormClassName="expense__form"
          />
          <div className="form--button">
            <VButton
              text="No, Don't Delete"
              height="50px"
              width="209px"
              mobWidth="100%"
              type="button"
              onClick={close}
              bgColor="transparent"
              textColor="#A5B0B7"
              style={{
                border: "1px solid #A5B0B7",
              }}
            />
            <VButton
              isLoading={fetching}
              text="Yes, Delete"
              height="50px"
              width="209px"
              mobWidth="100%"
              bgColor="#EA4336"
            />
          </div>
        </form>
      </StyledDeleteExpense>
    </VModal>
  );
};

export default DeleteExpenseModal;
