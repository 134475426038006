import { isJsonString } from "./libs";

const STORE_KEY = "vedeez-pwa";

export class AppStorage {
  getFromStore = (key: string) => {
    const result = localStorage.getItem(`${STORE_KEY}-${key}`);
    if (!result) return result;
    const isJson = isJsonString(result);
    return isJson ? JSON.parse(result) : result;
  };
  addToStore = (key: string, value: any) => {
    localStorage.setItem(
      `${STORE_KEY}-${key}`,
      typeof value === "object" ? JSON.stringify(value) : value
    );
    return true;
  };
  removeFromStore = (key: string) => {
    localStorage.removeItem(`${STORE_KEY}-${key}`);
    return true;
  };

  removeMultipleFromStore = (records: string[]) => {
    for (const key of records) {
      this.removeFromStore(key);
    }
  };

  addMultipleToStore = (records: Record<string, any>) => {
    const recordKeys = Object.keys(records);
    for (const key of recordKeys) {
      const value = records[key];
      this.addToStore(key, value);
    }

    return true;
  };

  getMultipleFromStore = (records: string[]) => {
    const result = [];
    for (const key of records) {
      const value = this.getFromStore(key);
      result.push(value);
    }

    return result;
  };
  clearStore = () => {
    return localStorage.clear();
  };
}
