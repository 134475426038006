import { LoaderIcon } from "assets/icons";
import { StyledVLoader, StyledVLoaderIcon } from "./styles";
import backgroundImage from "assets/images/pngs/background-image.png";

export const VLoader = ({
  text = "Loading...",
  height = 80,
  ...rest
}: {
  text?: string;
  height?: number;
} & React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <StyledVLoader
      style={{ backgroundImage: `url(${backgroundImage})` }}
      {...rest}
    >
      <StyledVLoaderIcon>
        <LoaderIcon height={height} width={80} />
      </StyledVLoaderIcon>
      <p>{text}</p>
    </StyledVLoader>
  );
};
