import React from "react";

const BusinessTypeIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 60 60"
      {...props}
    >
      <circle cx="30" cy="30" r="30" fill="#E0EEFF" />
      <path
        d="M18.9414 25.5293H40.1767V41.1764H29.5591H18.9414V25.5293Z"
        fill="#0077FF"
      />
      <path
        d="M35.7062 29.9996V20.4996C35.7062 17.1047 32.9541 14.3525 29.5592 14.3525V14.3525C26.1642 14.3525 23.4121 17.1047 23.4121 20.4996V29.9996"
        stroke="#0077FF"
        strokeWidth="3.35294"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g filter="url(#filter0_b_1605_47799)">
        <path
          d="M16.4039 29.579C15.9754 26.8655 18.0726 24.4111 20.8197 24.4111H38.2972C41.0443 24.4111 43.1415 26.8655 42.7131 29.579L40.7719 41.8731C40.4288 44.046 38.5559 45.6464 36.356 45.6464H22.7609C20.5611 45.6464 18.6881 44.046 18.345 41.8731L16.4039 29.579Z"
          fill="url(#paint0_linear_1605_47799)"
        />
        <path
          d="M16.4039 29.579C15.9754 26.8655 18.0726 24.4111 20.8197 24.4111H38.2972C41.0443 24.4111 43.1415 26.8655 42.7131 29.579L40.7719 41.8731C40.4288 44.046 38.5559 45.6464 36.356 45.6464H22.7609C20.5611 45.6464 18.6881 44.046 18.345 41.8731L16.4039 29.579Z"
          stroke="white"
          strokeOpacity="0.6"
          strokeWidth="0.335294"
          strokeLinejoin="round"
        />
      </g>
      <path
        d="M23.4121 28.8823C23.4121 29.4992 23.9128 29.9999 24.5298 29.9999C25.1467 29.9999 25.6474 29.4992 25.6474 28.8823C25.6474 28.2654 25.1467 27.7646 24.5298 27.7646C23.9128 27.7646 23.4121 28.2654 23.4121 28.8823Z"
        fill="white"
      />
      <path
        d="M31.3418 40.4846L35.4983 34.0022C35.8023 33.525 35.7051 32.8801 35.2525 32.5202C34.7674 32.1369 34.0644 32.2185 33.6799 32.7024L29.8319 37.5552L27.4166 35.8095C26.9819 35.4943 26.3716 35.5289 25.9749 35.9168C25.5323 36.3504 25.5245 37.0601 25.9581 37.5027L29.1378 40.7495C29.7514 41.3686 30.7998 41.3295 31.3418 40.4846Z"
        fill="white"
      />
      <path
        d="M34.5886 28.8823C34.5886 29.4992 35.0893 29.9999 35.7062 29.9999C36.3232 29.9999 36.8239 29.4992 36.8239 28.8823C36.8239 28.2654 36.3232 27.7646 35.7062 27.7646C35.0893 27.7646 34.5886 28.2654 34.5886 28.8823Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_b_1605_47799"
          x="10.5915"
          y="18.6549"
          width="37.9323"
          height="32.7478"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImage" stdDeviation="2.79412" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_1605_47799"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_1605_47799"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1605_47799"
          x1="11.1761"
          y1="32.7029"
          x2="44.7151"
          y2="49.1047"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#74C88A" stopOpacity="0.16" />
          <stop offset="1" stopColor="#34A853" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default BusinessTypeIcon;
