import styled from "styled-components/macro";
import { FlexColumn, stylingUtils } from "styles";

const { rem, color } = stylingUtils;

export const StyledDisabledGraph = styled("div")`
  ${FlexColumn};
  justify-content: center;
  align-items: center;
`;

export const StyledReportFilter = styled.div`
  svg {
    display: none;
  }

  @media (max-width: 500px) {
    svg {
      display: flex;
    }
  }
`;

export const FilterModal = styled("div")`
  position: absolute;
  top: ${rem(60)};
  right: 0;
  width: ${rem(153)};
  height: 112px;
  background: ${color("white")};
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.04);
  border-radius: ${rem(12)};
  padding: ${rem(15)};

  .chip {
    width: 100%;
    border: none;
    text-align: left;
    border-radius: ${rem(2)};
  }
`;
