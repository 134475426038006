import {
  closeWelcomeTab,
  openWelcomeTab,
  useAuthUser,
  useDashboardSlice,
} from "store";
import { calculatePercentage } from "./../../utils/libs";
import { useCallback, useMemo } from "react";
import { useServicePageHook } from "./useServicePageHook";
import { useProductPageHook } from "./useProductPageHook";
import { useSalesPageHook } from "./useSalesPageHook";
import { useExpensePageHook } from "./useExpensePageHook";
import { useDispatch } from "react-redux";
import { DOCUMENT_VALIDATION } from "api";

export const useWelcomTabHook = () => {
  const { userData } = useAuthUser();

  const { hasClosedWelcomeTabs } = useDashboardSlice();

  const dispatch = useDispatch();

  const firstName = userData?.firstName || "Customer";

  const {
    extractedData: { nodes: customerProduct = [] },
    fetching: productFetching,
  } = useProductPageHook({ pauseCategory: true });
  const {
    extractedData: { nodes: customerServices = [] },
    fetching: serviceFetching,
  } = useServicePageHook({ pauseCategory: true });

  const {
    extractedData: { nodes: customerSales = [] },
    fetching: salesFetching,
  } = useSalesPageHook();
  const {
    extractedData: { nodes: customerExpenses = [] },
    fetching: expenseFetching,
  } = useExpensePageHook();

  const dependencies = [
    productFetching,
    salesFetching,
    serviceFetching,
    expenseFetching,
  ];

  const welcomTabsCheck = useMemo(() => {
    let percentageValue = 0;
    const hasAddedProduct =
      !!customerProduct.length || !!customerServices.length;
    const hasAddedSalesOrExpense =
      !!customerSales.length || !!customerExpenses.length;
    const hasCompletedKyc =
      userData?.business?.isBvnValid === DOCUMENT_VALIDATION.SUCCESS;

    if (hasAddedProduct) {
      percentageValue += 1;
    }

    if (hasAddedSalesOrExpense) {
      percentageValue += 1;
    }
    if (hasCompletedKyc) {
      percentageValue += 1;
    }

    const percentage = calculatePercentage(percentageValue, 3);
    const allFinishedFetching = dependencies.every((value) => !value);

    if (percentage < 100 && allFinishedFetching) {
      dispatch(openWelcomeTab());
    }

    return {
      hasAddedProduct,
      hasAddedSalesOrExpense,
      hasCompletedKyc,
      percentage: Math.ceil(percentage) > 100 ? 100 : Math.ceil(percentage),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);

  const handleCloseWelcomeTab = useCallback(() => {
    dispatch(closeWelcomeTab());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    welcomTabsCheck,
    firstName,
    hasClosedWelcomeTabs,
    handleCloseWelcomeTab,
  };
};
