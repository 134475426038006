import {
  VButton,
  VChipGroup,
  VEmptyState,
  VTable,
  VPagination,
} from "components/common";
import { INVOICES_STATUS_CHIPS } from "components/constants";
import { ReactComponent as PlusIcon } from "assets/images/svgs/plus-icon.svg";
import { useInvoicePageHook } from "hooks";
import { StyledInventoryTable } from "./styles";
import { Link } from "react-router-dom";
import ViewInvoiceModal from "../view-invoice-modal/view-invoice-modal";
import { APP_ROUTES } from "routes";
import { DeleteInvoiceModal } from "../view-invoice-modal";

const InvoicesTable = () => {
  const {
    extractedData,
    error,
    invoiceColumns,
    fetching,
    pageNumber,
    handleChangePage,
    handleStatusFilter,
    selectedInvoiceStatus,
    openInvoiceModal,
    toggleInvoiceModal,
    invoiceId,
    openDeleteModal,
    toggleDeleteModal,
    isDeleteingInvoice,
    handleDeleteInovice,
  } = useInvoicePageHook();

  if (error)
    return (
      <VEmptyState
        title="Error Fetching Data!"
        subText="An error occur getting data"
      />
    );

  const { nodes: customerProducts, pageInfo } = extractedData;

  return (
    <>
      <StyledInventoryTable>
        <VChipGroup
          chips={INVOICES_STATUS_CHIPS}
          getCurrentChipKey={(status) => handleStatusFilter(status)}
          activeKey={selectedInvoiceStatus || "all"}
        >
          <Link to={APP_ROUTES.INVOICE_PRODUCTS}>
            <VButton
              text="Add New Invoice"
              height="30px"
              icon={<PlusIcon />}
              isSlim
            />
          </Link>
        </VChipGroup>
        <VTable
          isLoading={fetching}
          columns={invoiceColumns}
          data={customerProducts}
          emptyContent={{
            title: "No Invoice Recorded Yet!",
            subText: "Your invoice will appear here when you have recorded one",
          }}
        />
        <VPagination
          pageInfo={pageInfo}
          pageNumber={pageNumber}
          getPageNumber={(pageNumber) => handleChangePage(pageNumber)}
        />
      </StyledInventoryTable>
      <ViewInvoiceModal
        open={openInvoiceModal}
        close={toggleInvoiceModal}
        invoiceId={invoiceId}
      />
      <DeleteInvoiceModal
        open={openDeleteModal}
        close={toggleDeleteModal}
        isDeleteingInvoice={isDeleteingInvoice}
        handleDeleteInovice={handleDeleteInovice}
      />
    </>
  );
};

export default InvoicesTable;
