import { gql, useMutation } from "urql";
import { handleRequestCall } from "utils/handleRequestCall";
import { IAddBusinessInvoice } from "api/types";
import { showToast } from "components/common";

const addBusinessInvoiceMutation = gql`
  mutation (
    $businessPaymentId: String
    $businessCustomerId: String
    $clientName: String
    $clientPhone: String
    $issueDate: String
    $dueDate: String
    $items: [SaleItemInput]
    $services: [SaleServiceInput]
    $transactionType: TRANSACTIONTYPEENUM
    $discount: Int
    $includeTax: Boolean
    $deliveryFee: Int
  ) {
    addBusinessInvoice(
      businessPaymentId: $businessPaymentId
      businessCustomerId: $businessCustomerId
      clientName: $clientName
      clientPhone: $clientPhone
      issueDate: $issueDate
      dueDate: $dueDate
      items: $items
      services: $services
      transactionType: $transactionType
      discount: $discount
      includeTax: $includeTax
      deliveryFee: $deliveryFee
    ) {
      success
      message
      entityId
    }
  }
`;

export const useAddBusinessInvoice = () => {
  const [businessInvoice, addBusinessInvoice] = useMutation(
    addBusinessInvoiceMutation
  );

  const handleAddBusinessInvoice = async (
    data: IAddBusinessInvoice,
    callBack?: () => void
  ) => {
    const {
      clientName,
      clientPhone,
      issueDate,
      dueDate,
      items,
      services,
      transactionType,
      discount,
      includeTax,
      deliveryFee,
      businessCustomerId,
      businessPaymentId = "",
    } = data;

    try {
      const values = {
        clientName,
        clientPhone,
        issueDate: new Date(issueDate).toISOString(),
        dueDate: new Date(dueDate).toISOString(),
        items,
        services,
        transactionType,
        discount,
        includeTax,
        deliveryFee,
        expenseDate: new Date().toISOString(),
        businessCustomerId,
        businessPaymentId,
      };

      const req = await addBusinessInvoice(values);

      const invoiceTotalError = req?.error?.message?.includes("invoiceTotal");

      if (invoiceTotalError) {
        showToast({
          type: "error",
          title: "An error occurred",
          subText: "Discount cannot be more than invoice total",
        });
      } else {
        await handleRequestCall({ request: req, callBack });
      }
    } catch (e) {
      console.error(e);
      showToast({
        type: "error",
        title: "An error occurred",
        subText: "Please try again",
      });
    }
  };

  return {
    handleAddBusinessInvoice,
    businessInvoice,
    isLoading: businessInvoice?.fetching,
  };
};
