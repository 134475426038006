export interface IPagination {
  pageInfo: {
    currentPage: number;
    size: number;
    totalCount: number;
    hasNextPage: boolean;
  };
}

export type CreatedAtType = {
  createdAt?: {
    gte?: string;
    lte?: string;
  };
};
// create invoice types
export enum TRANSACTIONTYPEENUM {
  PRODUCT = "PRODUCT",
  SERVICE = "SERVICE",
}

type SaleItemInput = {
  customerProductId: string;
  productName: string;
  purchasePrice: number;
  sellingPrice: number;
  quantity: number;
};

type SaleServiceInput = {
  customerServiceId: string;
  serviceName: string;
  sellingPrice: number;
  quantity: number;
};

export type IAddBusinessInvoice = {
  businessPaymentId?: string;
  businessCustomerId?: string;
  clientName: string;
  clientPhone: string;
  issueDate: string;
  dueDate: string;
  items: [SaleItemInput];
  services: [SaleServiceInput];
  transactionType: TRANSACTIONTYPEENUM;
  discount: number;
  includeTax: boolean;
  deliveryFee: number;
};

export type IAddBusinessPayment = {
  businessId?: string;
  bankName?: string;
  bankAccountName?: string;
  bankAccountNumber?: string;
};

type InvoiceItems = {
  productName?: string;
  customerProductId: string;
  purchasePrice?: number;
  quantity: number;
  sellingPrice: number;
};

export interface BusinessInvoice {
  invoiceId?: string;
  clientName?: string;
  clientPhone?: string;
  amountDue?: number;
  invoiceStatus?: string;
  issueDate?: Date;
  dueDate?: Date;
  discount?: number;
  totalTax?: string;
  invoiceURL?: string;
  invoiceTotal?: string;
  invoiceItems?: Record<string, any>;
  deliveryFee?: number;
  transactionType?: string;
  items?: InvoiceItems[];
  services?: InvoiceItems[];
  businessCustomerId?: string;
}

export interface ICustomerTerminal {
  _id: string;
  userId?: string;
  terminalId?: string;
  terminalName?: string;
  previousStatus?: string;
  status?: string;
  spec?: any;
}

export interface IKycDetails {
  kycLevel: number;
  kyc?: {
    level: string;
    bvn: {
      value: string;
      status: string;
      type: string;
    };
    selfie: {
      value: string;
      status: string;
      type: string;
    };
    cac: {
      value: string;
      status: string;
      type: string;
    };
    proofOfId: {
      value: string;
      status: string;
      type: string;
    };
    proofOfAddress: {
      value: string;
      status: string;
      type: string;
    };
    indemnity: {
      value: string;
      status: string;
    };
  };
}
