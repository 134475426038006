import { IStaffSummaryCard, useGetStaffSummaryCards } from "api";
import { extractFetchedData } from "utils";

const useGetStaffSummaryHook = () => {
  const [staffSummaryResult] = useGetStaffSummaryCards();

  const extract = extractFetchedData<IStaffSummaryCard>({
    result: staffSummaryResult,
    key: "getStaffSummaryCards",
  });

  return {
    ...extract,
  };
};

export default useGetStaffSummaryHook;
