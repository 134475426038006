import { lazy } from "react";

export const PosPage = lazy(() => import("./pos"));
export const RequestPosPage = lazy(() => import("./request-pos-page"));
export const BuyPosPage = lazy(() => import("./buy-pos-page"));
export const PosSurveyPage = lazy(() => import("./pos-survey-page"));
export const PosRequestedPage = lazy(() => import("./pos-requested-page"));
export const PosRequestDetailsPage = lazy(
  () => import("./pos-request-details-page")
);
export const PosDetailsPage = lazy(() => import("./pos-details-page"));
export const PosCardDetailsPage = lazy(() => import("./pos-card-details-page"));
export const PosSettingsPage = lazy(() => import("./pos-setings-page"));
