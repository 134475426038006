import { useAuthUser } from "store";
import { gql, useQuery } from "urql";
import { extractFetchedData } from "utils";
import { ICustomerDetails } from "./types";

type useGetBusinessCustomerDetailProps = {
  businessId: string;
  businessCustomerId: string;
};

export const useGetBusinessCustomerDetail = ({
  businessId,
  businessCustomerId,
}: useGetBusinessCustomerDetailProps) => {
  return useQuery({
    query: getBusinessCustomerDetailQuery,
    variables: { businessId, businessCustomerId },
  });
};

export const useGetBusinessCustomerDetailHook = ({
  businessCustomerId,
}: {
  businessCustomerId: string;
}) => {
  const { userData } = useAuthUser();
  const businessId = userData?.businessId || "";

  const [result] = useGetBusinessCustomerDetail({
    businessId,
    businessCustomerId,
  });

  const extract = extractFetchedData<ICustomerDetails>({
    result,
    key: "getBusinessCustomerDetail",
  });

  return {
    ...extract,
  };
};

export const useGetBusinessSalesApi = ({
  businessId,
  businessCustomerId,
}: useGetBusinessCustomerDetailProps) => {
  return useQuery({
    query: getBusinessSalesQuery,
    variables: { businessId, businessCustomerId },
  });
};

const getBusinessCustomerDetailQuery = gql`
  query ($businessId: String!, $businessCustomerId: String) {
    getBusinessCustomerDetail(
      businessId: $businessId
      businessCustomerId: $businessCustomerId
    ) {
      _id
      customerName
      phoneNumber
      email
      businessId
      customerStatus
      status
      productsBought
      amountSpent
      totalDebt
      createdAt
      updatedAt
      referenceId
    }
  }
`;

const getBusinessSalesQuery = gql`
  query ($businessId: String!, $businessCustomerId: String) {
    getBusinessSales(
      filters: {
        businessId: $businessId
        businessCustomerId: $businessCustomerId
      }
      metadata: { sortBy: "desc" }
    ) {
      nodes {
        _id
        clientName
        saleItems {
          _id
          customerProductName
          customerServiceName
        }
        salePayments {
          paymentType
          paidAmount
        }
        createdAt
        saleDate
      }
    }
  }
`;
