import { IMAGE_PLACE_HOLDER } from "components/constants";
import { BusinessHeaderCardProps } from "../types";
import {
  StyledPermissionSelectionAvatar,
  StyledPermissionSelectionHeader,
  StyledPermissionSelectionHeaderLeft,
} from "./styles";

export const BusinessHeaderCard = ({
  img,
  children,
  title,
}: BusinessHeaderCardProps) => {
  return (
    <StyledPermissionSelectionHeader>
      <StyledPermissionSelectionHeaderLeft>
        <StyledPermissionSelectionAvatar>
          <img src={img ? img : IMAGE_PLACE_HOLDER} alt="" />
        </StyledPermissionSelectionAvatar>
        {title && <h4>{title}</h4>}
      </StyledPermissionSelectionHeaderLeft>
      {children}
    </StyledPermissionSelectionHeader>
  );
};
