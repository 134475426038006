import { UseFormReturn } from "react-hook-form";
import { VModal } from "components/common/VModal";
import { showToast, VButton, VForm } from "components/common";
import { InputList } from "components/common/types";
import { TProduct, TEditedProduct } from "components/Inventory/types";
import { Dispatch } from "react";
import { useAddCustomerProduct } from "api/inventory/add-customer-product";
import { useAnalytics } from "hooks/useAnalytics";

const ProductModal = ({
  title,
  open,
  handleClose,
  inputList,
  openConfirm,
  setFormData,
  hookForm,
  handleReset,
  type,
}: {
  title: string;
  open: boolean;
  handleClose: () => void;
  inputList: InputList;
  openConfirm?: () => void;
  setFormData?: Dispatch<TProduct>;
  hookForm: UseFormReturn<TEditedProduct, any>;
  handleReset: (title: string) => void;
  type: "edit" | "add";
}) => {
  const { handleSubmit } = hookForm;

  const { customerProduct, submitNewCustomerProduct, imageProcessing } =
    useAddCustomerProduct();

  const [
    logAppEvent,
    {
      add_product_screen_opened,
      edit_product_screen_opened,
      submitted_added_product,
    },
  ] = useAnalytics();

  if (title === "Create Product") {
    logAppEvent(add_product_screen_opened, {}).catch(console.log);
  } else {
    logAppEvent(edit_product_screen_opened, {}).catch(console.log);
  }

  const closeAction = () => {
    handleClose();
    handleReset(title === "Create Product" ? "Create" : "Edit");
  };

  const onSubmit = (formData: TEditedProduct) => {
    if (type === "edit") {
      if (Number(formData.newPrice) > Number(formData.sellingPrice)) {
        showToast({
          type: "error",
          title: "Selling Price must be greater than Cost Price",
          subText: "Please try again",
        });
      } else if (
        Number(formData.thresholdLowStock) > Number(formData.newQuantity)
      ) {
        showToast({
          type: "error",
          title: "New Quantity must be greater than Low Stock Threshold",
          subText: "Please try again",
        });
      } else {
        setFormData?.(formData);
        handleClose();
        handleReset("Edit");
        openConfirm?.();
      }
    }

    if (type === "add") {
      submitNewCustomerProduct(formData, handleClose);
      logAppEvent(submitted_added_product, {}).catch(console.log);
      handleReset("Create");
    }
  };

  const handleCreateProduct = handleSubmit(onSubmit);

  return (
    <VModal title={title} open={open} close={closeAction}>
      <form onSubmit={handleCreateProduct}>
        <VForm
          vFormClassName={"grid__form"}
          inputLists={inputList}
          hookForm={hookForm}
        >
          <div className="form__button">
            <VButton
              isLoading={imageProcessing || customerProduct.fetching}
              text="Save"
              minWidth="295px"
              mobWidth="100%"
              height="50px"
            />
          </div>
        </VForm>
      </form>
    </VModal>
  );
};

export default ProductModal;
