import CloseIcon from "assets/images/svgs/CloseIcon";
import DownArrow from "assets/images/svgs/DownArrow";
import { VProgressIndicator } from "components/common";
import {
  BillingCheckoutCard,
  StyledCheckeRadio,
} from "components/manage-business";
import { WELCOME_TABS_INFO } from "./constants";
import {
  StyledDashboardTabCard,
  StyledDashboardTabCardWrapper,
  StyledDashboardWelcomeTabs,
  StyledUserWelcomeProgress,
  StyledWelcomeTabAction,
} from "./styles";
import { DashboardTabCardProps } from "./types";
import { useWelcomTabHook } from "hooks";
import { useNavigate } from "react-router-dom";

export const DashboardWelcomeTab = ({
  welcomeTabHook,
}: {
  welcomeTabHook: ReturnType<typeof useWelcomTabHook>;
}) => {
  const {
    welcomTabsCheck,
    firstName,
    handleCloseWelcomeTab,
    hasClosedWelcomeTabs,
  } = welcomeTabHook;

  const { percentage } = welcomTabsCheck;

  if (percentage >= 100 || hasClosedWelcomeTabs) {
    return <></>;
  }

  return (
    <StyledDashboardWelcomeTabs>
      <BillingCheckoutCard
        title={`Welcome ${firstName}, continue setting up.`}
        largeTitle
        action={
          <StyledWelcomeTabAction onClick={handleCloseWelcomeTab}>
            <CloseIcon />
          </StyledWelcomeTabAction>
        }
      >
        <div className="wrapper">
          <p className="subtitle">
            Take the next steps to help you get on track with Veedez. Once you
            are done, you’ll have access to all necessary metrics to help you
            grow your business.
          </p>
          <StyledUserWelcomeProgress>
            <VProgressIndicator progress={percentage} />
            <p>{percentage}% Complete</p>
          </StyledUserWelcomeProgress>
          <StyledDashboardTabCardWrapper>
            {WELCOME_TABS_INFO.map((tab, index) => {
              const isActive =
                (welcomTabsCheck as Record<string, any>)[tab?.id] || false;
              return (
                <DashboardTabCard
                  key={tab.id}
                  isFirstIndex={index === 0}
                  isLastIndex={index === WELCOME_TABS_INFO.length - 1}
                  {...tab}
                  checked={isActive}
                  isActive={isActive}
                />
              );
            })}
          </StyledDashboardTabCardWrapper>
        </div>
      </BillingCheckoutCard>
    </StyledDashboardWelcomeTabs>
  );
};

export const DashboardTabCard = ({
  title,
  number,
  description,
  isFirstIndex,
  isLastIndex,
  id,
  link,
  checked = false,
  isActive = false,
}: DashboardTabCardProps) => {
  const navigate = useNavigate();

  return (
    <StyledDashboardTabCard
      onClick={() => {
        link && !isActive && navigate(link);
      }}
      isActive={isActive}
    >
      <div className="radio-checked">
        {!isFirstIndex && <div className="rod" />}
        <StyledCheckeRadio type="radio" name={id} id={id} checked={isActive} />
        {!isLastIndex && <div className="rod rod-end" />}
      </div>
      <div className="content">
        <h5>
          {number}. {title}
        </h5>
        <p>{description}</p>
      </div>
      {!isActive && (
        <div className="right-arrow">
          <DownArrow height={15} width={15} />
        </div>
      )}
    </StyledDashboardTabCard>
  );
};
