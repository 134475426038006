import { FlexColumn, stylingUtils } from "styles";
import styled from "styled-components/macro";

const { rem } = stylingUtils;

export const StyledUpgradePlanModal = styled("section")`
  ${FlexColumn};
  max-width: ${rem(500)};
  margin: 0 auto;
  text-align: center;
  gap: ${rem(30)};

  h3 {
    margin-bottom: ${rem(10)};
  }

  p {
    line-height: ${rem(20)};
  }
`;
