import styled from "styled-components";
import { stylingUtils } from "styles";

const { color, rem } = stylingUtils;

export const StyledForm = styled("div")`
  iframe {
    width: 100%;
    height: 100%;
  }

  display: flex;
  flex-direction: column;
  gap: ${rem(10)};
`;

export const StyledFormField = styled("div")`
  height: ${rem(38.5)}!important;
  border: 1px solid ${color("inputBorder")};
  background-color: white;
  transition: 0.5s ease;
  height: ${rem(38.5)}!important;
  color: ${color("ashText")};
  padding: ${rem(10)} ${rem(12)};
  width: 100%;
  border-radius: 10px;
`;

export const StyledFormFieldGroup = styled("div")`
  display: flex;
  gap: ${rem(15)};

  & > div:first-child {
    flex: auto;
  }

  & > div:last-child {
    width: ${rem(130)}!important;
  }
`;

export const StyledFormButton = styled("button")`
  border: 1px solid #1f8ab0;
  background-color: #3b495c;
  border-color: #3b495c;
  color: #ced5e0;
  font-family: inherit;
  border-radius: 4px;
  font-size: 16px;
  height: 35px;
  width: 100%;
`;
