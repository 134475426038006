import { useGetAppConfigHook, useImportFileProductApi } from "api";
import { useModal } from "hooks";
import { useState } from "react";
import { useForm } from "react-hook-form";

export const DUPLICATE_HANDLING_LIST = [
  {
    id: "Ignore",
    label: "Skip",
    content:
      "Retains the records in Veedez and does not sync the identical records from the import file.",
  },
  {
    id: "Overwrite",
    label: "Overwrite",
    content:
      "Overwrite existing records in Veedez with the identical records from the import file.",
    disbled: true,
  },
];

export const useImportProductModalHook = ({
  close,
}: { close?: VoidFunction } = {}) => {
  const [checked, setChecked] = useState(DUPLICATE_HANDLING_LIST[0].id);

  const { open: openSuccessModal, toggleModal: toggleSuccessModal } =
    useModal();

  const { handleAddCustomerFileProducts, isLoading } =
    useImportFileProductApi();

  const {
    data: { importInventoryTemplate },
  } = useGetAppConfigHook();

  const hookForm = useForm();
  const { handleSubmit } = hookForm;

  const onSubmit = async (values: any) => {
    await handleAddCustomerFileProducts(
      { file: values.file, duplicateHandling: checked },
      (data: any) => {
        const isSuccessful = data?.importFile?.success;
        if (isSuccessful) {
          close?.();
          toggleSuccessModal();
        }
      }
    );
  };

  const handleAddFile = handleSubmit(onSubmit);

  return {
    importInventoryTemplate,
    handleAddFile,
    hookForm,
    checked,
    setChecked,
    isLoading,
    openSuccessModal,
    toggleSuccessModal,
  };
};
