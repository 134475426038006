import {
  CustomTableProps,
  VDropdownProps,
  CardProps,
} from "components/common/types";
import {
  CUSTOM_VALIDATIONS,
  formatNumberToCurrency,
  formatUnderScore,
  truncateWord,
} from "utils";
import { VDropdown } from "components/common";
import {
  Status,
  Contacts,
  StyledCustomerIconWrapper,
  StyledCustomerNameAvatar,
} from "./styles";

import emailIcon from "assets/images/svgs/email-icon.svg";
import phoneIcon from "assets/images/svgs/phone-icon.svg";
import { InputListType } from "components/common/types";
import VerticalDropdown from "assets/icons/vertical-dot.svg";
import { IBusinessCustomer } from "./edit-customer-modal";

import CustomersIcon from "assets/images/svgs/TodayCustomersIcon";
import PurchasesIcon from "assets/images/svgs/TodaySalesIcon";
import { ReactComponent as AccountOffIcon } from "assets/images/svgs/account-off-outline.svg";
import { ReactComponent as BluePersonIcon } from "assets/images/svgs/blue-person.svg";
import { StyledStaffNameWrapper, StyledInput } from "components/staff/styles";
import theme from "styles/theme";
import { useWindowSize } from "hooks";

export const ADD_CUSTOMER_INPUT_LIST: InputListType[] = [
  {
    name: "customerName",
    label: "Customer Name",
    required: true,
    validation: { required: "Please enter name" },
  },
  {
    name: "phoneNumber",
    label: "Phone Number",
    type: "number",
    validation: {
      required: "Enter your phone number",
      ...CUSTOM_VALIDATIONS.phoneNumber,
    },
  },
  {
    name: "email",
    label: "Email (Optional)",
    type: "email",
  },
];

export const CUSTOMERS_TABLE_COLUMN = ({
  handleViewCustomerDetails,
  handleEditCustomer,
}: {
  handleViewCustomerDetails: (_id: string) => void;
  handleEditCustomer: (customer: IBusinessCustomer) => void;
}): CustomTableProps["columns"] => {
  const { width } = useWindowSize();

  const isMobile = width && width <= 767;

  if (isMobile) {
    return [
      {
        headerName: "Customer ID",
        key: "referenceId",
        renderCell: (id: string, data: IBusinessCustomer) => {
          const { customerName } = data;
          const mockAvatar = `${
            customerName ? formatUnderScore(customerName[0]) : ""
          }`;
          return (
            <StyledStaffNameWrapper>
              <StyledCustomerNameAvatar>{mockAvatar}</StyledCustomerNameAvatar>
              <p>{id}</p>
            </StyledStaffNameWrapper>
          );
        },
      },
      {
        headerName: "Name",
        key: "customerName",
        formatter: (customerName: string) =>
          formatUnderScore(customerName || ""),
        renderCell: (value: string) => (
          <p>{truncateWord(value, { length: 25 })}</p>
        ),
      },
      {
        headerName: "Total Purchase",
        key: "amountSpent",
        useZero: false,
        formatter: (amountSpent: any) =>
          formatNumberToCurrency({ number: amountSpent || 0 }),
        renderCell: (value: string) => (
          <p className="lightblue__text">{value}</p>
        ),
      },
      {
        headerName: "Status",
        key: "status",
        formatter: (status) => {
          let customeStyles = {
            color:
              status === "ACTIVE"
                ? "#34A853"
                : status === "INACTIVE"
                ? "#F0A75A"
                : "",
            backgroundColor:
              status === "ACTIVE"
                ? "#D2EEDF"
                : status === "INACTIVE"
                ? "#FFF7EE"
                : "",
          };
          return (
            <Status>
              <li style={customeStyles}>
                <span>{formatUnderScore(status || "")}</span>
              </li>
            </Status>
          );
        },
      },
      {
        headerName: "Contact",
        key: "email",
        width: 50,
        renderCell: (email: string, data: any) => (
          <>
            <Contacts>
              {email ? (
                <div className="contact-image">
                  <a href={`mailto:${email}`} target="_blank" rel="noreferrer">
                    <img src={emailIcon} alt="card" />
                  </a>
                </div>
              ) : null}
              {data.phoneNumber ? (
                <div className="contact-image">
                  <a href={`tel:${data.phoneNumber}`}>
                    <img src={phoneIcon} alt="card" />
                  </a>
                </div>
              ) : null}
            </Contacts>
          </>
        ),
      },
      {
        headerName: "",
        key: "dropdown",
        renderCell: (value: any, tableData: IBusinessCustomer) => {
          const dropdownItems = [
            ...CUSTOMERS_ACTIONS_DROPDOWNS({
              handleEdit: () => handleEditCustomer(tableData),
              viewCustomeDetails: () => {
                if (tableData?._id) {
                  handleViewCustomerDetails(tableData?._id);
                }
              },
            }),
          ].map((item) => ({
            ...item,
          }));

          return (
            <VDropdown
              dropdownButton={
                <button className="vertical__drop--down">
                  <img src={VerticalDropdown} alt="vertical-dropdown" />
                </button>
              }
              dropdownItems={dropdownItems}
              isLeft={true}
            />
          );
        },
      },
    ];
  }
  return [
    {
      headerName: <StyledInput type="checkbox" />,
      key: "checkbox",
      width: 80,
      renderCell: () => {
        return <StyledInput type="checkbox" />;
      },
    },
    {
      headerName: "Customer ID",
      key: "referenceId",
      renderCell: (id: string, data: IBusinessCustomer) => {
        const { customerName } = data;
        const mockAvatar = `${
          customerName ? formatUnderScore(customerName[0]) : ""
        }`;
        return (
          <StyledStaffNameWrapper>
            <StyledCustomerNameAvatar>{mockAvatar}</StyledCustomerNameAvatar>
            <p>{id}</p>
          </StyledStaffNameWrapper>
        );
      },
    },
    {
      headerName: "Name",
      key: "customerName",
      formatter: (customerName: string) => formatUnderScore(customerName || ""),
      renderCell: (value: string) => (
        <p>{truncateWord(value, { length: 25 })}</p>
      ),
    },
    {
      headerName: "Total Purchase",
      key: "amountSpent",
      useZero: false,
      formatter: (amountSpent: any) =>
        formatNumberToCurrency({ number: amountSpent || 0 }),
      renderCell: (value: string) => <p className="lightblue__text">{value}</p>,
    },
    {
      headerName: "Status",
      key: "status",
      formatter: (status) => {
        let customeStyles = {
          color:
            status === "ACTIVE"
              ? "#34A853"
              : status === "INACTIVE"
              ? "#F0A75A"
              : "",
          backgroundColor:
            status === "ACTIVE"
              ? "#D2EEDF"
              : status === "INACTIVE"
              ? "#FFF7EE"
              : "",
        };
        return (
          <Status>
            <li style={customeStyles}>
              <span>{formatUnderScore(status || "")}</span>
            </li>
          </Status>
        );
      },
    },
    {
      headerName: "Contact",
      key: "email",
      width: 50,
      renderCell: (email: string, data: any) => (
        <>
          <Contacts>
            {email ? (
              <div className="contact-image">
                <a href={`mailto:${email}`} target="_blank" rel="noreferrer">
                  <img src={emailIcon} alt="card" />
                </a>
              </div>
            ) : null}
            {data.phoneNumber ? (
              <div className="contact-image">
                <a href={`tel:${data.phoneNumber}`}>
                  <img src={phoneIcon} alt="card" />
                </a>
              </div>
            ) : null}
          </Contacts>
        </>
      ),
    },
    {
      headerName: "",
      key: "dropdown",
      renderCell: (value: any, tableData: IBusinessCustomer) => {
        const dropdownItems = [
          ...CUSTOMERS_ACTIONS_DROPDOWNS({
            handleEdit: () => handleEditCustomer(tableData),
            viewCustomeDetails: () => {
              if (tableData?._id) {
                handleViewCustomerDetails(tableData?._id);
              }
            },
          }),
        ].map((item) => ({
          ...item,
        }));

        return (
          <VDropdown
            dropdownButton={
              <button className="vertical__drop--down">
                <img src={VerticalDropdown} alt="vertical-dropdown" />
              </button>
            }
            dropdownItems={dropdownItems}
            isLeft={true}
          />
        );
      },
    },
  ];
};

export const CUSTOMERS_ACTIONS_DROPDOWNS = ({
  viewCustomeDetails,
  handleEdit,
}: {
  viewCustomeDetails: () => void;
  handleEdit: () => void;
}): VDropdownProps["dropdownItems"] => {
  return [
    {
      key: "view",
      label: "View",
      styles: { color: theme.colors.green },
      onClick: () => viewCustomeDetails(),
    },
    {
      key: "edit",
      label: "Edit",
      styles: { color: theme.colors.lightBlue },
      onClick: () => handleEdit(),
    },
  ] as VDropdownProps["dropdownItems"];
};

export const CUSTOMERS_CARDS_DATA = ({
  totalCustomers,
  totalPurchases,
  totalInactiveCustomers,
  totalActiveCustomers,
}: {
  totalCustomers: number;
  totalPurchases: number;
  totalInactiveCustomers: number;
  totalActiveCustomers: number;
}): CardProps[] => {
  return [
    {
      title: "Total Customers",
      icon: () => (
        <StyledCustomerIconWrapper color="#FBECDB">
          <CustomersIcon />
        </StyledCustomerIconWrapper>
      ),
      amount: formatNumberToCurrency({
        number: Number(totalCustomers),
        removeCurrency: true,
      }),
    },
    {
      title: `Total Purchases`,
      icon: () => (
        <StyledCustomerIconWrapper color="#E8F5F1">
          <PurchasesIcon />
        </StyledCustomerIconWrapper>
      ),
      amount: formatNumberToCurrency({
        number: Number(totalPurchases),
      }),
    },
    {
      title: `Inactive Customer`,
      icon: () => (
        <StyledCustomerIconWrapper color="#FFE0DE">
          <AccountOffIcon />
        </StyledCustomerIconWrapper>
      ),
      amount: formatNumberToCurrency({
        number: Number(totalInactiveCustomers),
        removeCurrency: true,
      }),
    },
    {
      title: `Active Customer`,
      icon: () => (
        <StyledCustomerIconWrapper color="#E0EEFF">
          <BluePersonIcon />
        </StyledCustomerIconWrapper>
      ),
      amount: formatNumberToCurrency({
        number: Number(totalActiveCustomers),
        removeCurrency: true,
      }),
    },
  ];
};

export const ITEM_TABLE_HEADINGS = ["Date", "Item", "Amount", "Payment Method"];

export const CUSTOMER_DETAILS_HEADER = {
  TOP: [
    {
      value: "AHY 74938",
      label: "Customer I.D",
      key: "referenceId",
    },
    {
      value: "₦0",
      label: "Total Purchase",
      key: "amountSpent",
      formatter: (value: string) => formatNumberToCurrency({ number: value }),
    },
  ],
  BOTTOM: [
    {
      value: "0812346781",
      label: "Phone Number",
      key: "phoneNumber",
    },
    {
      value: "nancy.idris@me.com",
      label: "Official Email",
      key: "email",
    },
    {
      value: "Inactive",
      label: "Status",
      key: "status",
      formatter: (value: string) => formatUnderScore(value || ""),
      className: "active",
    },
  ],
};

export const EDIT_CUSTOMER_INPUT_LIST: InputListType[] = [
  {
    name: "customerName",
    label: "Customer Name",
    disabled: true,
  },
  {
    name: "phoneNumber",
    label: "Phone Number",
    type: "number",
    validation: {
      required: "Enter your phone number",
      ...CUSTOM_VALIDATIONS.phoneNumber,
    },
  },
  {
    name: "email",
    label: "Email (Optional)",
    type: "email",
  },
];

export const CUSTOMER_STATUS = [
  { label: "Active", value: "ACTIVE" },
  { label: "Inactive", value: "INACTIVE" },
];

export const CUSTOMER_FILTER_CHIPS = [
  { label: "All", key: "all" },
  ...CUSTOMER_STATUS.map((customer) => ({
    ...customer,
    key: customer.value,
  })),
];
