const BankTransferIcon = (props: any) => {
  return (
    <svg
      viewBox="0 0 9 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.84447 3.30273C7.89869 3.30275 7.95239 3.29208 8.00248 3.27133C8.05258 3.25059 8.0981 3.22018 8.13644 3.18184C8.17478 3.1435 8.20519 3.09798 8.22594 3.04788C8.24668 2.99778 8.25735 2.94409 8.25734 2.88986V1.65126C8.25737 1.56464 8.23013 1.48019 8.17947 1.40992C8.12881 1.33965 8.0573 1.28711 7.9751 1.25977L4.2593 0.0211674C4.1745 -0.00705581 4.08284 -0.00705581 3.99803 0.0211674L0.282232 1.25977C0.200031 1.28711 0.128529 1.33965 0.0778691 1.40992C0.0272092 1.48019 -3.55609e-05 1.56464 3.48353e-08 1.65126V2.88986C-1.40685e-05 2.94409 0.0106555 2.99778 0.031399 3.04788C0.0521425 3.09798 0.0825535 3.1435 0.120894 3.18184C0.159236 3.22018 0.204755 3.25059 0.254853 3.27133C0.304951 3.29208 0.358644 3.30275 0.412867 3.30273H0.825734V6.26888C0.584924 6.3537 0.376272 6.51098 0.228426 6.71913C0.080579 6.92728 0.000785468 7.17609 3.48353e-08 7.4314V8.25713C-1.40685e-05 8.31136 0.0106555 8.36505 0.031399 8.41515C0.0521425 8.46525 0.0825535 8.51077 0.120894 8.54911C0.159236 8.58745 0.204755 8.61786 0.254853 8.6386C0.304951 8.65934 0.358644 8.67001 0.412867 8.67H7.84447C7.89869 8.67001 7.95239 8.65934 8.00248 8.6386C8.05258 8.61786 8.0981 8.58745 8.13644 8.54911C8.17478 8.51077 8.20519 8.46525 8.22594 8.41515C8.24668 8.36505 8.25735 8.31136 8.25734 8.25713V7.4314C8.25655 7.17609 8.17676 6.92728 8.02891 6.71913C7.88106 6.51098 7.67241 6.3537 7.4316 6.26888V3.30273H7.84447ZM7.4316 7.84427H0.825734V7.4314C0.825843 7.32193 0.869376 7.21698 0.94678 7.13958C1.02418 7.06218 1.12913 7.01864 1.2386 7.01853H7.01873C7.1282 7.01864 7.23315 7.06218 7.31055 7.13958C7.38796 7.21698 7.43149 7.32193 7.4316 7.4314V7.84427ZM1.65147 6.1928V3.30273H2.4772V6.1928H1.65147ZM3.30293 6.1928V3.30273H4.9544V6.1928H3.30293ZM5.78013 6.1928V3.30273H6.60587V6.1928H5.78013ZM0.825734 2.477V1.94882L4.12867 0.847702L7.4316 1.94882V2.477H0.825734Z"
        fill="inherit"
      />
    </svg>
  );
};
export default BankTransferIcon;
